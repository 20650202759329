import React, { FC, useState, memo, useEffect } from 'react';
import { Form, Radio, Slider, Image, Upload, Input, Tag, Tooltip, Select, Button, message } from 'antd';
import { PlusOutlined, FormOutlined } from '@ant-design/icons';
import '../style/popup.less';
import holderImg from '../../../../assets/images/public/holder.jpg';
import { uploadImage } from "utils/file-utils";
const { Option } = Select;
export interface Props {
    dataField: {
        nav: any[]
        [key: string]: any
    }
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const Popup: FC<Props> = ({ dataField }) => {
    return (
        <div className={`popup-container ${dataField.preview ? 'show' : ''}`} >
            <div className="title">
                <Tag color="success">弹窗</Tag>
                {dataField.name || '弹窗'}

            </div>
        </div>
    )
}

export const PopupSet: FC<SetProps> = memo(({ dataField, id, methods, selectedInfo, otherInfo }) => {
    const [identity] = useState([
        {
            label: '所有用户',
            value: 'all',
        },
        {
            label: '未登录用户',
            value: 'nologin',
        },
        {
            label: '已登录用户',
            value: 'login',
        },
        {
            label: '仅蓝卡',
            value: 'blueCardOnly',
        },
        {
            label: '蓝卡以上用户',
            value: 'blueCard',
        },
        {
            label: '红卡用户',
            value: 'redCard',
        },
    ])
    const [platform] = useState([
        {
            label: '所有平台',
            value: 'all',
        },
        {
            label: '仅APP',
            value: 'app',
        },
        {
            label: '仅小程序',
            value: 'wxapp',
        },
    ])
    // all  nologin 未登录用户 logined 所有登录用户 blueCard 蓝卡及以上 redCard 红卡
    const uploadProps = {
        multiple: false,
        showUploadList: false,
        customRequest(e: any) {
            let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`
            uploadImage(e.file, 'minipage/' + imageName).then(async res => {
                // updateModuleDataPrivate({
                //     ...data,
                //     'img':res
                // }, index, '')
                methods.updateModuleData({
                    ...dataField,
                    img: res
                }, id)
            }).catch(() => {message.error('图片上传失败')});
        },
    };
    const OpenType: FC<{ defaultValue: string }> = ({ defaultValue }) => {
        return (
            <Select defaultValue={defaultValue} onChange={
                (e) => {
                    dataField.openType = e
                    methods.updateModuleData(dataField, id)
                }
            } className="select-after">
                <Option value="navigateTo">新窗口</Option>
                <Option value="switchTab">TAB切换</Option>
                <Option value="redirectTo">重定向</Option>
                <Option value="back">返回</Option>
            </Select>
        );
    }
    useEffect(
        () => {
            // console.log('selectInfo Change popup', selectedInfo,)
            if (!selectedInfo || !selectedInfo.path || otherInfo.settingModuleId !== id) return
            methods.updateModuleData({
                ...dataField,
                openType: selectedInfo.openType,
                link: selectedInfo.path,
            }, id)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedInfo]
    )
    return (
        <div className="popup-set" >
            {
                dataField.img && <div className="img">
                    <Upload {...uploadProps}>
                        <div className="change-img" >
                            <Tooltip title="更换">
                                <FormOutlined />
                            </Tooltip>
                        </div>
                    </Upload>
                    <Button className="preview" type="primary" onClick={
                        (e) => {

                            e.preventDefault()
                            e.stopPropagation()
                            methods.updateMoreState({
                                popupData: dataField
                            })
                        }
                    } style={
                        {
                            marginLeft: '10px'
                        }
                    }>查看效果</Button>
                    <Image src={dataField.img ? dataField.img + '?imageView2/2/w/600' : holderImg} />
                </div>
            }

            {
                !dataField.img && <div className="dragger-con">
                    <Upload {...uploadProps}>

                        <div className="add-area">
                            <p className="desc">
                                <PlusOutlined className="icon" />
                            </p>
                            <p className="desc">点击选择图片</p>
                        </div>
                    </Upload>
                </div>
            }

            <Form className="custom-form" initialValues={
                {
                    ...dataField
                }
            } onValuesChange={
                (change: any, all: any) => {
                    methods.updateModuleData({
                        ...dataField,
                        ...all
                    }, id)
                }
            }>
                <Form.Item label="弹窗名称" name="name">
                    <Input placeholder="请输入弹窗名称" />
                </Form.Item>
                <Form.Item label="链接地址">
                    <Input.Group compact className="input-item">
                        <Button type="primary" onClick={
                            () => {
                                methods.setShowLinkSelectionPrivate(true,)
                            }
                        }>选择</Button>
                        <Input addonAfter={<OpenType defaultValue={dataField.openType || 'navigateTo'} />} placeholder="请输入/选择链接地址" onChange={(e) => {
                            dataField.link = e.target.value;
                            methods.updateModuleData(dataField, id)
                        }} value={dataField.link} />

                    </Input.Group>
                </Form.Item>

                <Form.Item label="弹出延时(s)" name="delay">
                    <Slider min={0} max={10} step={0.5} />
                </Form.Item>
                <Form.Item label="下次显示间隔(h)" name="nextInterval">
                    <Slider min={1} max={100} step={1} />
                </Form.Item>
                <Form.Item label="可见用户" name="identity">

                    <Radio.Group value={dataField.identity}>
                        {
                            identity.map((v: any, i: number) => {
                                return <Radio value={v.value} key={i}>{v.label}</Radio>
                            })
                        }
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="可见平台" name="platform">

                    <Radio.Group value={dataField.platform}>
                        {
                            platform.map((v: any, i: number) => {
                                return <Radio value={v.value} key={i}>{v.label}</Radio>
                            })
                        }
                    </Radio.Group>
                </Form.Item>

            </Form>



        </div>
    )
})

export default Popup