import { BaseModel } from "../base.model";
import { Dept } from "./dept.model";

export interface User extends BaseModel {
    id?: string;
    loginType?: string[];
    createUser?: string;
    createDept?: string;
    createTime?: string;
    updateUser?: string;
    updateTime?: string;
    status?: number;
    deleted?: boolean;
    isDeleted?: number;
    tenantId?: string;
    code?: string;
    account?: string;
    name?: string;
    realName?: string;
    avatar?: string;
    email?: string;
    phone?: string;
    birthday?: string;
    sex?: number;
    tstUid?: string;
    isAnchor?: number;
    tstUserGrade?: number;
    tstUserCode?: string;
    tstBindTime?: string;
    notAllocateBonus?: boolean;
    tenantName?: string;
    sexName?: string;
    backendEnabled?: boolean;
    frontEnabled?: boolean;
    password?: string;
    depts?: Dept[];
    postIds?: string[];
    roleIds?: string[];
    roleNames?: string[];
    postNames?: string[];
    bgList?: any
}

export const defaultUser: Readonly<User> = {
};