import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import AuthFlowDetails from './authFlow-details';
import AuthFlowEdit from './authFlow-edit';
import AuthFlowList from './authFlow-list';

const AuthFlow: React.FC<RouteComponentProps> = () => (
  <Router>
    <AuthFlowList path="/" />
    <AuthFlowDetails path=":id" />
    <AuthFlowEdit path=":id/edit" />
    <AuthFlowEdit path="new" />
  </Router>
);
export default AuthFlow;
