import { FC, ReactNode } from "react";
import { Form, Input, Tag } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./course-details";
import { deleteEntity, getEntities, getEntity } from "./course.reducer";
import { RouteComponentProps } from "@reach/router";
import { ProFormText } from "@ant-design/pro-form";
import PostEdit from "./course-edit";
import './index.less'

export interface PostListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

export const columns: ProColumns[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 140
  },
  {
    title: '课程标题',
    dataIndex: 'title',
    valueType: 'text',
    width: 150,
    ellipsis: true
  },

  {
    title: '课程描述',
    dataIndex: 'content',
    valueType: 'text',
    width: 150,
    ellipsis: true
  },

  // {
  //   title: '课程封面',
  //   dataIndex: 'frontCoverUrl',
  //   width: 80,
  //   render: ((res, record) => {
  //     const picture = record.coverImg && <Image alt='' style={{ width: '40px', height: 'auto' }} src={record.coverImg} />;
  //     const video = record.introductionVideoUrl && <Image alt='' style={{ width: '40px', height: 'auto' }} src={record.frontCoverUrl} />;
  //     return picture || video
  //   })
  // },

  {
    title: '及格分数',
    dataIndex: 'passScore',
    valueType: 'text',
    width: 150,
    ellipsis: true
  },
  {
    title: '是否需要考试',
    dataIndex: 'isExamination',
    width: 120,
    render: (dom, record) => {
      return <Tag color={record.isExamination === 1 ? '#1890ff' : '#f50'}>{record.isExamination === 1 ? '需要' : '不需要'}</Tag>
    }
  }, {
    title: '状态',
    dataIndex: 'state',
    width: 120,
    render: (dom, record) => {
      return <Tag color={record.state === 1 ? '#1890ff' : '#f50'}>{record.state === 1 ? '使用中' : '闲置'}</Tag>
    }
  }];


const PostList: FC<PostListProps> = (props) => {
  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormText
          name={['title.contains']}
          placeholder="课程名称"
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage
      key={'id'}
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      sort='lastModifiedDate,desc'
      actionColumnWidth={70}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      showResetButton={false}
      createButtonText='添加课程'
      editPage={(params) => <PostEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ course }: RootState) => ({
  entity: course.entity,
  entities: course.entities,
  loading: course.loading,
  updateSuccess: course.updateSuccess,
  createSuccess: course.createSuccess,
  total: course.total
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostList);