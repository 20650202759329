import { FC, ReactNode, useState } from "react";
import { Form, Input, Image, Button, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ComplaintDetails from "./complaint-details";
import { deleteEntity, getEntities, getEntity, handleComplaint } from "./complaint.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ComplaintEdit from "./complaint-edit";
import { Complaint, departmentArr, handleStatus, typeArr } from "model/dealer/complaint.model";
import ProForm, { ModalForm, ProFormDateTimePicker, ProFormSelect, ProFormTextArea } from "@ant-design/pro-form";
import UploadImgComplex from "components/upload-img/upload-img-complex";

export interface ComplaintListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Complaint>[] = [{
  title: '投诉单号',
  dataIndex: 'complainNo',
  width:130,
}, {
  title: '授权号',
  dataIndex: 'code',
  width:100,
  ellipsis:true,
}, {
  title: '用户姓名',
  dataIndex: 'customerName',
  width:120,
}, {
  title: '联系电话',
  dataIndex: 'customerPhone',
  width:100,
  ellipsis:true,
}, {
  title: '投诉部门',
  dataIndex: 'department',
  width:100,
  ellipsis:true,
}, {
  title: '投诉类型',
  dataIndex: 'type',
  width:100,
  ellipsis:true,
}, {
  title: '投诉描述',
  dataIndex: 'complainText',
  width:100,
  ellipsis:true,
}, {
  title: '投诉凭证',
  dataIndex: 'complainImgList',
  width:300,
  render: (dom, record) => {
    return record.complainImgList ? <>{record.complainImgList.map(e => <Image style={{padding:'5px',boxSizing:'border-box'}} height={80} width={80} src={e} />)}</> : <></>
  }
}, {
  title: '投诉时间',
  dataIndex: 'complainTime',
  width:100,
}, {
  title: '处理状态',
  dataIndex: 'state',
  width:100,
  ellipsis:true,
  render: (dom, record) => record.state === 1 ? '待处理' : '已处理'
}];


const ComplaintList: FC<ComplaintListProps> = (props) => {
  const [detailsId,SetDetailsId] = useState(0);
  const [refreshFlag, setRefreshFlag] = useState(0);
  const searchFields = [{
    label: '投诉单号',
    name: 'complainNo.equals',
  }, {
    label: '授权号',
    name: 'code.equals',
  }, {
    label: '用户姓名',
    name: 'name.equals',
  }, {
    label: '联系电话',
    name: 'phone.equals',
  },];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <ProFormDateTimePicker
          name="complainTime.greaterThanOrEqual"
          placeholder="投诉开始时间"
        />
        <ProFormDateTimePicker
          name="complainTime.lessThanOrEqual"
          placeholder="投诉结束时间"
        />
        <ProFormSelect
          options={Object.entries<string>(handleStatus).map(([value, label]) => ({ value: Number(value), label }))}
          name={'state.equals'}
          placeholder="处理状态"
        />
        <ProFormSelect
          options={Object.entries<string>(departmentArr).map(([value, label]) => ({ value: (value), label }))}
          name={'department.equals'}
          placeholder="投诉部门"
        />
        <ProFormSelect
          options={Object.entries<string>(typeArr).map(([value, label]) => ({ value: (value), label }))}
          name={'type.equals'}
          placeholder="投诉类型"
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: any, record: any) => {
    return <ModalForm
      title={'处理'}
      trigger={<Button disabled={record.state === 2} type="link" size="small" >处理</Button>}
      modalProps={{
        destroyOnClose: true,
      }}
      disabled={record.state === 2}
      preserve={false}
      onFinish={async (data: any) => {
        const params = {
          ...data,
          id: record.id,
          examineImg: data.examineImg.concat().map((e: any) => e.url),
        }
        return new Promise<boolean>((resolve, reject) => {
          handleComplaint(params).then(e => {
            message.success('处理成功');
            setRefreshFlag(new Date().getTime());
            resolve(true);
          }).catch(error => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <ProFormTextArea
        width="md"
        name="examineText"
        label="处理回复"
      />
      <ProForm.Item name="examineImg" label="相关图片">
        <UploadImgComplex
          listType="picture-card"
          extra="建议尺寸：800*800像素，最多上传6张, 不超过2M."
          title="上传"
          Immediate={true}
          fileType='img'
          maxCount={30}
        />
      </ProForm.Item>
    </ModalForm>
  }]

  return (
    <ListViewPage<Complaint>
      columns={columns}
      hideCreateButton
      hideDeleteButton
      refreshFlag={refreshFlag}
      hideEditButton
      quickFilterPanel={quickFilterPanel}
      rowActions={rowActions}
      clickRowHandle={(id) => SetDetailsId(id)}
      detailPage={<ComplaintDetails entityData={props.entities} detailsId={detailsId} navigate={props.navigate} columns={columns} parentMethod={()=> {
        setRefreshFlag(new Date().getTime());
      }} />}
      detailsIndex={0}
      editPage={(params: any) => <ComplaintEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ complaint }: RootState) => ({
  entity: complaint.entity,
  entities: complaint.entities,
  loading: complaint.loading,
  updateSuccess: complaint.updateSuccess,
  createSuccess: complaint.createSuccess,
  total: complaint.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintList);