import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { Button, Form } from "antd";
import { useEnumValue } from "common/enum/use-enum-value";
import SearchBox, { SearchField } from "components/search-box";
import { filterTime, getDate } from "components/tool";
import { useEffect, useState } from "react";

export const searchFields: SearchField[] = [{
  label: '订单编号',
  name: 'orderNo.equals',
}, {
  label: '快递单号',
  name: 'expressNo.equals',
}, {
  label: '商品名称',
  name: 'productName.contains',
}, {
  label: 'SPU编码',
  name: 'productCode.equals',
}, {
  label: '商品Id',
  name: 'productId.equals',
  dataType: 'number'
}, {
  label: '收货人手机号',
  name: 'mobile.equals',
  dataType: 'number'
}, {
  label: '收货人',
  name: 'consigneeName.equals',
}, {
  label: '购买人id',
  name: 'buyerUserId',
}];

const SearchFor = (props: any) => {
  let { onSearch } = props
  let [key, setkey]: any = useState(0);
  const { convertEnumToOptionType } = useEnumValue();
  const [form] = Form.useForm();
  const pathName: any = window.location.pathname;

  useEffect(() => {
    form.setFieldsValue({
      'type.equals': 'WholesaleOrder'
    });
     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathName]);

  const onFinish = (values: any) => {
    if (values.search) {
      Object.keys(values.search).map((item) => {
        if (!values.search[item]) {
          delete values.search[item];
        }
        return true;
      });
    }
    if (values['bookTime.lessThanOrEqual']) {
      values['bookTime.lessThanOrEqual'] = filterTime(values['bookTime.lessThanOrEqual'])
    }
    if (values['bookTime.greaterThanOrEqual']) {
      values['bookTime.greaterThanOrEqual'] = filterTime(values['bookTime.greaterThanOrEqual'])
    }
    values = {
      ...values,
      ...values.search
    }
    delete values.search
    onSearch(values)
  };




  return (
    <div className="searchFor-wrapper">
      <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
        <ProFormDateTimePicker
          name="bookTime.greaterThanOrEqual"
          placeholder="订单开始时间"
          initialValue={getDate(-30)}
        />
        <ProFormDateTimePicker
          name="bookTime.lessThanOrEqual"
          placeholder="订单结束时间"
        />
        <ProFormSelect
          options={convertEnumToOptionType('purchaseOrderType').concat().filter(e => e.value === 'WholesaleOrder') || []}
          name="type.equals"
          placeholder={'订单类型'}
          disabled
        />
        <SearchBox
          key={key}
          width={300}
          fields={searchFields}
        />
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit">
            搜索
          </Button>
          <Button
            className="ml10"
            onClick={() => {
              form.resetFields();
              onFinish({})
              setkey(++key)
            }}
          >
            重置
          </Button>
        </Form.Item>

      </Form>
    </div>
  )
}

export default SearchFor