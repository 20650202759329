import { FC, ReactNode, useState } from "react";
import { Form, Input, Image, message, Tooltip, Space, Tabs, Button, Col, Row } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import LifePavilionDetails from "./lifePavilion-details";
import { LifeStatus, auditRefuse, deleteEntity, getEntities, getEntity } from "./lifePavilion.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ToExamineDialog from '../toExamineDialog'
import { ModalForm, ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { downgrading, statusList, statusMap } from "../excellentStore/excellentStore.reducer";
import { fetchStoreList } from "pages/store/store/store.reducer";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import { LifePavilion } from "model/dealerStore/lifePavilion.model";


export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}


const columns: ProColumns<LifePavilion,'storeLable'>[] = [{
  title: 'ID',
  dataIndex: 'id',
  width: 100,
  ellipsis: true
},
{
  title: '姓名',
  dataIndex: 'userName',
  width: 100
}, {
  title: '授权码',
  dataIndex: 'authorizationCode',
  width: 100
},

{
  title: '联系电话',
  dataIndex: 'phone',
  width: 120
},
{
  title: '降级标记',
  dataIndex: 'storeLabel',
  width: 100,
  valueType:'storeLable'
},
{
  title: '上级6S公司',
  dataIndex: 'parentSixsCompany',
  width: 120,
  ellipsis: true
},
{
  title: '店铺地址',
  dataIndex: 'address',
  ellipsis: true,
  width: 120,
  render: (text, record: any) => {
    return record.address?.provinceName + record.address?.cityName + record.address?.districtName + record.address?.address
  }

},
{
  title: '地盘位置',
  dataIndex: 'territory',
  width: 100,
  render: (text, record: any) => {
    return record.territory === 'Street' ? '临街' : '商场'
  }
},
{
  title: '面积',
  dataIndex: 'storeArea',
  width: 60
},
{
  title: '楼层层高',
  dataIndex: 'buildHeight',
  width: 80
},
{
  title: '身份证正面',
  dataIndex: 'idCardFrontPictureUrl',
  width: 100,
  hideInTable: true,
  render: ((res, record) => {
    const picture = record.idCardFrontPictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.idCardFrontPictureUrl} />;
    return picture
  })
},
{
  title: '身份证反面',
  dataIndex: 'idCardOppositePictureUrl',
  width: 100,
  hideInTable: true,
  render: ((res, record) => {
    const picture = record.idCardOppositePictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.idCardOppositePictureUrl} />;
    return picture || '-'
  })
},
{
  title: '个体营业执照或租房协议',
  dataIndex: 'licPictureUrl',
  ellipsis: true,
  hideInTable: true,
  render: ((res, record) => {
    const picture = record.licPictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.licPictureUrl} />;
    return picture || '-'
  })
},
{
  title: '平面尺寸图',
  width: 100,
  dataIndex: 'planeSizePictureUrl',
  hideInTable: true,
  render: ((res, record) => {
    const picture = record.planeSizePictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.planeSizePictureUrl} />;
    return picture || '-'
  })
},
{
  title: '门头照片',
  dataIndex: 'doorHeadPictureUrl',
  hideInTable: true,
  width: 100,
  render: ((res, record) => {
    const picture = record.doorHeadPictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.doorHeadPictureUrl} />;
    return picture || '-'
  })
},
{
  title: '店内正拍照片',
  dataIndex: 'inStorePictureUrl',
  width: 120,
  hideInTable: true,
  render: ((res, record) => {
    const picture = record.inStorePictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.inStorePictureUrl} />;
    return picture
  })
},
{
  title: '店内侧拍照片',
  dataIndex: 'insideStorePictureUrl',
  width: 120,
  hideInTable: true,
  render: ((res, record) => {
    const picture = record.insideStorePictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.insideStorePictureUrl} />;
    return picture || '-'
  })
}, {
  title: '申请时间',
  dataIndex: 'applyTime',
  width: 150,
  ellipsis: true
},
{
  title: '状态',
  dataIndex: 'status',
  width: 120,
  valueEnum: statusMap,
}];

const ExamineColumns: ProColumns<LifePavilion,'storeLable'>[] = [
  {
    title: '审核人',
    dataIndex: 'checkUserName',
    width: 80,
    ellipsis: true
  },
  {
    title: '审核时间',
    dataIndex: 'checkTime',
    width: 150,
    ellipsis: true
  }, {
    title: '审核备注',
    dataIndex: 'checkComments',
    width: 120,
    ellipsis: true
  }
]

const CheckColumns: ProColumns<LifePavilion,'storeLable'>[] = [
  {
    title: '验收人',
    dataIndex: 'checkUserName',
    width: 120
  },
  {
    title: '验收时间',
    dataIndex: 'checkTime',
    width: 150,
    ellipsis: true
  }, {
    title: '验收备注',
    dataIndex: 'checkComments',
    width: 120,
    ellipsis: true
  }
]

const LevelList: FC<LevelListProps> = (props) => {
  const { entities } = props;
  let [key, setKey] = useState<any>(0);
  const [refreshFlag, setRefreshFlag] = useState(0);
  const searchFields: SearchField[] = [{
    label: '用户名',
    name: 'userName',
    operator: 'equals',
  }, {
    label: '手机号',
    name: 'phone',
    operator: 'equals',
  }, {
    label: '授权码',
    name: 'authorizationCode',
    operator: 'equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          request={async () => {
            const data: any = await fetchStoreList();
            return data.map((item: any) => ({
              value: item.id,
              label: item.name
            }))
          }}
          name={['storeId.equals']}
          placeholder="店铺"
        />
        <div className="wd10" />
        <ProFormSelect
          name="status.equals"
          options={statusList}
          placeholder="状态"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="applyTime.greaterThanOrEqual"
          placeholder="申请时间开始时间"
        />
        <ProFormDateTimePicker
          name="applyTime.lessThanOrEqual"
          placeholder="申请时间结束时间"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="auditTime.greaterThanOrEqual"
          placeholder="审核开始时间"
        />
        <ProFormDateTimePicker
          name="auditTime.lessThanOrEqual"
          placeholder="审核结束时间"
        />
        <div className="wd10"></div>
        <SearchBox fields={searchFields} />
      </Input.Group>
    </Form.Item>
  );
  const [open, setOpen] = useState(false);
  const [record, setRecord]: any = useState({});

  //打开审核弹框
  const showModal = async (record: any) => {
    setRecord({ ...record })
    setOpen(true);
  };

  //审核
  const toExamineButtonRender: any = (dom: ReactNode, record: any): ReactNode => {
    return (
      record.status === 'Created' || record.status === 'Checked' ? <Tooltip title="审核">
        <Button size="small" type='link' onClick={() => showModal(record)} >审核</Button>
      </Tooltip> : ''
    )
  };

  //关闭审核弹框
  const hideModal = () => {
    setOpen(false);
    setRecord({})
  };
  const [status, setStatus] = useState<string>('Examine');
  const additionActions = [
    <Space className="status-filter" style={{ top: '6px' }}>
      <Tabs defaultActiveKey={status} onChange={status => setStatus(status)} activeKey={status}>
        {Object.entries<string>(LifeStatus).map(([value, label]) => <TabPane tab={label} key={value}></TabPane>)}
      </Tabs>
    </Space>,
  ];

  const exportData = (data: LifePavilion[]) => {
    let entities: LifePavilion[] = [];
    data.forEach((e: any) => {
      const address = e.address?.provinceName + e.address?.cityName + e.address?.districtName + e.address?.address;
      let arr = {
        ...e,
        address,
      };
      entities.push(arr);
    });
    return entities;
  };

  const tableAlertOptionRender = (res: any) => {
    const ids = res.selectedRowKeys;
    return (
      <ModalForm
        trigger={<Button className="operation-btn" type="primary">添加降级标记</Button>}
        title='添加降级标记'
        modalProps={{
          destroyOnClose: true,
        }}
        onFinish={async (e) => {
          res.onCleanSelected();
          return new Promise<boolean>((resolve, reject) => {
            resolve(false)
            downgrading({
              isOut: e.isOut === 'true' ? true : false,
              ids,
              "storeType": "LifePavilion"
            },'life-pavilion-apply').then((data: any) => {
              message.success('操作成功');
              setRefreshFlag(new Date().getTime());
              resolve(true);
            }).catch(err => {
              message.error(err.response.data.title);
              reject(false);
            })
          });
        }}
      >
        <Row>
          <Col span="12">
            <ProFormSelect
              label="降级标记"
              rules={[{ required: true }]}
              name={'isOut'}
              options={[{
                label: '降级',
                value: 'true'
              }, {
                label: '无',
                value: 'false'
              }]}
            />
          </Col>
        </Row>
      </ModalForm>
    );
  }


  return (<>
    <ListViewPage<LifePavilion,'storeLable'>
      scrollConfig={{ x: 1200 }}
      columns={columns.concat(status === 'Examine' ? ExamineColumns : CheckColumns)}
      filters={{ 'stage.equals': status }}
      actions={additionActions}
      hideDeleteButton
      permanentParams={{
        'applyType.equals': 'LifePavilion'
      }}
      tableAlertOptionRender={tableAlertOptionRender}
      rowSelection={{
        type: 'checkbox',
      }}
      showDownload
      downloadAllEntities={exportData}
      downloadColumns={columns.concat().map(e => {
        return {
          ...e,
          render: () => ''
        }
      })}
      refreshFlag={refreshFlag}
      downloadEntities={exportData(entities)}
      key={key}
      rowActions={[toExamineButtonRender]}
      hideCreateButton
      hideEditButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<LifePavilionDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      {...props}
    >
      {props.children}
    </ListViewPage>


    {/* 审核 */}
    <ToExamineDialog visible={open} navigate={props.navigate} entity={record} columns={columns} record={record} onCancel={() => {
      hideModal();
    }} onRecharge={(data: any) => {
      auditRefuse({ ...data, stage: record.stage }).then((e: any) => {
        message.success('操作成功');
        setKey(++key)
        hideModal();
        return true;
      }).catch((e: any) => {
        message.error(e.response.data.message);
        return false
      })
    }} />
  </>
  );
};

const mapStateToProps = ({ lifePavilion }: RootState) => ({
  entity: lifePavilion.entity,
  entities: lifePavilion.entities,
  loading: lifePavilion.loading,
  updateSuccess: lifePavilion.updateSuccess,
  createSuccess: lifePavilion.createSuccess,
  total: lifePavilion.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelList);