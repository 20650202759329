import {BaseModel} from "../base.model";

export interface Assort extends BaseModel {
    customerId?: any;
    idCardFrontUrl?: any;
    idCardBackUrl?: any;
    creditCodeUrl?: any;
    articleNum?: number;
}

export const defaultAssort:  Readonly<Assort> = {};