import axios from 'axios';
import { Address, defaultAddress } from 'model/store/address.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'address';
const apiUrl = '/api/yumei-mall-store/seller/address';
const standardReducerImpl = new StandardReducerImpl<Address>(entity, defaultAddress, apiUrl);
export type AddressState = StandardState<Address>;
export const address = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

//退货地址
export const fetchAddressList= async (sellerId: number | string) => {
    const requestUrl = `/api/yumei-mall-store/seller/address?sellerId.equals=${sellerId}`;
    const res = await axios.get(requestUrl);
    return res.data;
  }