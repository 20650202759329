import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import BrandLevelDetails from "./brandLevel-details";
import { deleteEntity, getEntities, getEntity } from "./brandLevel.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import BrandLevelEdit from "./brandLevel-edit";
import { BrandLevel } from "model/dealer/brandLevel.model";
import { internationalList } from "components/IntelFormText";

export interface BrandLevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const columns: ProColumns<BrandLevel>[] = [{
  title: '等级名称',
  dataIndex: 'levelName',
  render: (dom, record) => internationalList(record.levelName || '')
},
{
  title: '等级编码',
  dataIndex: 'levelNo',
},
{
  title: '品牌ID',
  dataIndex: 'brandId',
},
{
  title: '经销商名称',
  dataIndex: 'customerName',
},
{
  title: '经销商ID',
  dataIndex: 'customerId',
},
{
  title: '经销商授权码',
  dataIndex: 'code',
},
{
  title: '经销商用户ID',
  dataIndex: 'userId',
},
];


const BrandLevelList: FC<BrandLevelListProps> = (props) => {
  const searchFields = [{
    label: '经销商名称',
    name: 'customerName.contains',
  }, {
    label: '经销商授权码',
    name: 'code.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<BrandLevel>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<BrandLevelDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <BrandLevelEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ dealerBrandLevel }: RootState) => ({
  entity: dealerBrandLevel.entity,
  entities: dealerBrandLevel.entities,
  loading: dealerBrandLevel.loading,
  updateSuccess: dealerBrandLevel.updateSuccess,
  createSuccess: dealerBrandLevel.createSuccess,
  total: dealerBrandLevel.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrandLevelList);