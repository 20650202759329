import { FC, Key, ReactElement, useState } from 'react'
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { ModalForm } from "@ant-design/pro-form";
import { fetchCustomerList } from './unit.reducer';

export const columns: ProColumns<any>[] = [ {
  title: '客户名称',
  dataIndex: 'name',
  valueType: 'text',
  hideInSearch: true,
  render: (text, record: any) => record.name && record.name !== '' ? record.name : '匿名用户',
},{
  title: '用户昵称',
  dataIndex: 'name',
  hideInSearch: true,
},  {
  title: '用户昵称',
  dataIndex: 'name.contains',
  hideInTable: true,
},{
  title: '用户userId',
  dataIndex: 'userId',
  hideInSearch: true,
}, {
  title: '用户userId',
  dataIndex: 'userId.equals',
  hideInTable: true,
}, {
  title: '用户code',
  dataIndex: 'account',
  valueType: 'text',
  hideInDescriptions: true,
  hideInSearch: true,
},{
  title: '用户code',
  dataIndex: 'account.equals',
  hideInTable: true,
}];

export interface ProductSelectionFormProps {
  trigger?: string;
  triggerRender?: ReactElement;
  name?: string;
  multiple?: boolean;
  productIds?: string[];
  requestParams?: any;
  visible?: boolean;
  onFinish: (productIds: string[], products: any[]) => void;
  onVisibleChange?:any
}

const CustomerSelectionForm: FC<ProductSelectionFormProps> = (props) => {
  const {
    visible,
    onFinish,
    multiple = true,
    requestParams = {},
    onVisibleChange
  } = props;

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  return (
    <ModalForm<any[]>
      title="选择客户"
      width={1000}
      visible={visible}
      onVisibleChange={(data)=> {
        onVisibleChange(data)
      }}
      onFinish={async () => {
        onFinish((selectedRowKeys?.map(item => item.toString())) || [], selectedRows);
        return true;
      }}
    >
      <ProTable<any, Record<string, any>>
        rowKey="id"
        search={{
          filterType: 'light',
        }}
        dateFormatter="string"
        columns={columns}
        rowSelection={{
          type: multiple ? 'checkbox' : 'radio',
          onChange: (selectedRowKeys: Key[], selectedRows) => {
            setSelectedRowKeys(selectedRowKeys);
            setSelectedRows(selectedRows);
          }
        }}
        pagination={{
          showQuickJumper: true,
        }}
        params={{}}
        request={async (params) => {
          const result = await fetchCustomerList({
            page: (params?.current && params?.current - 1) || 0,
            size: params?.pageSize || 20,
            sort: 'id',
            ...requestParams,
            ...params
          });
          return {
            success: true,
            total: result.headers?.['x-total-count'],
            data: result.data,
          };
        }}
      />
    </ModalForm>
  );
};

export default CustomerSelectionForm;