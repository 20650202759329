import { Publisher, defaultPublisher } from 'model/video/Publisher.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'publisher';
const apiUrl = '/api/yumei-mall-post/material-publisher';
const standardReducerImpl = new StandardReducerImpl<Publisher>(entity, defaultPublisher, apiUrl);
export type PublisherState = StandardState<Publisher>;
export const publisher = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export {
  apiUrl,
};
