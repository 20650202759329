import { FC, ReactNode, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ModuleDetails from "./power-module-details";
import { deleteEntity, getEntities, getEntity } from "./power-module.reducer";
import { RouteComponentProps } from "@reach/router";
import { Button, Form, Input, Popconfirm, Tooltip } from "antd";
import { PowerModule } from "model/system/power-module.model";
import SearchBox from "components/search-box";
import PowerModuleEdit from "./power-module-edit";
import ListViewPage from "layouts/page-wraper/list-view-page";
import Authorized from "common/auth/authorized";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";


export interface ModuleListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
export const columns: ProColumns<PowerModule>[] = [{
  title: '模块编码',
  dataIndex: 'code',
  valueType: 'text',
}, {
  title: '模块名称',
  dataIndex: 'name',
  valueType: 'text',
}]

const ModuleList: FC<ModuleListProps> = (props) => {
  const {deleteEntity} = props;
  const [editModal,setEditModal] = useState<any>({text: '', id: 0});
  const editPage = (params: any) => <PowerModuleEdit id={params.id} text={params.text} />;
  const searchFields = [{
    label: '模块名称',
    name: 'name.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: ReactNode, record: any) => {
    return <Authorized authority='edit'>
      <Tooltip title="编辑">
        <Button type="link" onClick={() => {
          setEditModal({ id: record.code, text: String(new Date()) })
        }} icon={<EditOutlined />} />
      </Tooltip>
    </Authorized>
  }, (dom: ReactNode, record: any) => {
    return <Authorized authority='delete'>
      <Popconfirm
        title="确定要删除吗"
        onConfirm={() => deleteEntity(record.code)}
        okText="确定"
        cancelText="取消"
      >
        <Tooltip title="删除">
          <Button danger type="link" icon={<DeleteOutlined />} />
        </Tooltip>
      </Popconfirm>
    </Authorized>
  }];

  return (
    <ListViewPage<PowerModule>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ModuleDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false} 
      rowActions={rowActions}
      hideEditButton
      hideDeleteButton
      editPage={(params: any) => <PowerModuleEdit id={params.id} text={params.text} />}
      sort=""
      {...props}
    >
      {props.children}
      {editPage(editModal)}
    </ListViewPage>
  );
};

const mapStateToProps = ({ powerModule }: RootState) => ({
  entity: powerModule.entity,
  entities: powerModule.entities,
  loading: powerModule.loading,
  updateSuccess: powerModule.updateSuccess,
  createSuccess: powerModule.createSuccess,
  total: powerModule.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ModuleList);