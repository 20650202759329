import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./pay.reducer";
import { Col, Row } from "antd";
import { Pay } from "model/system/pay.model";
import { ProFormDependency, ProFormDigit, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import Title from "antd/lib/typography/Title";
export interface PayEditProps extends EditStateAndDispatchProps<Pay>, StateProps, DispatchProps, RouteComponentProps {
}

const payCodeOptions = [{
  label: 'AlldebitConfig',
  value: 'AlldebitConfig',
}, {
  label: 'IPay88Config',
  value: 'IPay88Config',
}, {
  label: 'McpConfig',
  value: 'McpConfig',
}, {
  label: 'SgePayConfig',
  value: 'SgePayConfig',
}, {
  label: 'TapPay',
  value: 'TapPayConfig',
}, {
  label: '支付宝',
  value: 'AliPayConfig',
}, {
  label: '微信',
  value: 'WechatPayConfig',
}, {
  label: '银联',
  value: 'UnionPayConfig',
  disabled: true,
}];

const AliPayConfig = [{
  label: '支付宝公钥',
  value: 'aliPayPublicKey'
}, {
  label: '支付宝应用id',
  value: 'appId'
}, {
  label: '通知地址前缀',
  value: 'notifyUrlPrefix'
}, {
  label: 'pId',
  value: 'pId'
}, {
  label: '应用私钥',
  value: 'privateKey'
}, {
  label: '应用公钥',
  value: 'publicKey'
}, {
  label: '签名算法',
  value: 'signType'
}];

const TapPayConfig = [{
  label: '绑定 Portal 帐户的验证密钥',
  value: 'partnerKey'
}, {
  label: 'Portal 登录商家时所产生的识别码',
  value: 'merchantId'
}, {
  label: 'Prime方式地址',
  value: 'payByPrimeUrl'
}, {
  label: 'card token方式地址',
  value: 'payByTokenUrl'
}, {
  label: '记录地址',
  value: 'recordUrl'
}, {
  label: '退款地址',
  value: 'refundUrl'
}, {
  label: '通知地址前缀',
  value: 'notifyUrlPrefix'
}];

const SgePayConfig = [{
  label: '商家id',
  value: 'merchantId'
}, {
  label: '请求Id',
  value: 'requestId'
}, {
  label: '加签方式地址',
  value: 'signUrl'
}, {
  label: '查询地址',
  value: 'queryUrl'
}, {
  label: '通知地址前缀',
  value: 'notifyUrlPrefix'
}];

const McpConfig = [{
  label: '商家id',
  value: 'merchantId'
}, {
  label: 'merchantSecretKey',
  value: 'merchantSecretKey'
}, {
  label: '加签方式地址',
  value: 'checkUrl'
}, {
  label: '查询地址',
  value: 'queryUrl'
}, {
  label: '通知地址前缀',
  value: 'notifyUrlPrefix'
}];

const IPay88Config = [{
  label: '商家key',
  value: 'merchantKey'
}, {
  label: '商家code',
  value: 'merchantCode'
}, {
  label: '加签方式地址',
  value: 'signUrl'
}, {
  label: '查询地址',
  value: 'queryUrl'
}, {
  label: '通知地址前缀',
  value: 'notifyUrlPrefix'
}];

const AlldebitConfig = [{
  label: 'md5Key',
  value: 'md5Key'
}, {
  label: '商家code',
  value: 'merchantCode'
}, {
  label: '加签方式地址',
  value: 'signUrl'
}, {
  label: '查询地址',
  value: 'queryUrl'
}, {
  label: '通知地址前缀',
  value: 'notifyUrlPrefix'
}];

const WechatPayConfig = [{
  label: '应用ID',
  value: 'appId'
}, {
  label: '应用密钥',
  value: 'appSecret'
}, {
  label: '商户证书路径',
  value: 'keyPath'
}, {
  label: '商户ID',
  value: 'mchId'
}, {
  label: '商户密钥',
  value: 'mchKey'
}, {
  label: '通知地址前缀',
  value: 'notifyUrlPrefix'
}];

const OverseasSurcharges = [{
  label: '币种',
  value: 'currency'
}, {
  label: '支付回调页面地址',
  value: 'callBackUrl'
}];

const PayEdit: FC<PayEditProps> = props => {

  return (
    <EditPage<Pay>
      title="支付方式"
      {...props}
    >
      <Row>
        <Col span="7">
          <ProFormText
            name="payName"
            label="支付方式名称"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span="1" />
        <Col span="7">
          <ProFormText
            name="payCode"
            label="支付方式编码"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span="1" />
        <Col span={7}>
          <ProFormDigit
            name="sort"
            label="排序"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span="7">
          <ProFormText
            name="tradeType"
            label="调用方式"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span="1" />
        <Col span="7">
          <ProFormText
            name="strategyBeanId"
            label="执行策略类名"
            rules={[{ required: true }]}
          />
        </Col>

        <Col span={24}><Title level={5}>配置内容</Title></Col>
        <Col span="7">
          <ProFormSelect
            name={["config", 'payConfigType']}
            label="支付方式选择"
            options={payCodeOptions}
            rules={[{ required: true }]}
          />
        </Col>
        <Col span="1" />
        <ProFormDependency name={["config", 'payConfigType']}>
          {({ config }) => {
            return <>
              <Col span="7">
                <ProFormSwitch
                  name={['config', 'dev']}
                  label={'开发环境'}
                  initialValue={false}
                  fieldProps={{
                    checkedChildren: '启用',
                    unCheckedChildren: '禁用'
                  }}
                />
              </Col>
              <Col span="1" />
              {config && config.payConfigType === 'AliPayConfig' && <>{AliPayConfig.map(e => <><Col span="11"><ProFormTextArea
                name={['config', e.value]}
                label={e.label}
                rules={[{ required: true }]}
              /></Col><Col span="1" /></>)}</>}
              {config && config.payConfigType === 'WechatPayConfig' && <>{WechatPayConfig.map(e => <><Col span="11"><ProFormTextArea
                name={['config', e.value]}
                label={e.label}
                rules={[{ required: true }]}
              /></Col><Col span="1" /></>)}</>}
              {config && config.payConfigType === 'TapPayConfig' && <>{TapPayConfig.concat(OverseasSurcharges).map(e => <><Col span="11"><ProFormTextArea
                name={['config', e.value]}
                label={e.label}
                rules={[{ required: true }]}
              /></Col><Col span="1" /></>)}</>}
              {config && config.payConfigType === 'SgePayConfig' && <>{SgePayConfig.concat(OverseasSurcharges).map(e => <><Col span="11"><ProFormTextArea
                name={['config', e.value]}
                label={e.label}
                rules={[{ required: true }]}
              /></Col><Col span="1" /></>)}</>}
              {config && config.payConfigType === 'McpConfig' && <>{McpConfig.concat(OverseasSurcharges).map(e => <><Col span="11"><ProFormTextArea
                name={['config', e.value]}
                label={e.label}
                rules={[{ required: true }]}
              /></Col><Col span="1" /></>)}</>}
              {config && config.payConfigType === 'IPay88Config' && <>{IPay88Config.concat(OverseasSurcharges).map(e => <><Col span="11"><ProFormTextArea
                name={['config', e.value]}
                label={e.label}
                rules={[{ required: true }]}
              /></Col><Col span="1" /></>)}</>}
              {config && config.payConfigType === 'AlldebitConfig' && <>{AlldebitConfig.map(e => <><Col span="11"><ProFormTextArea
                name={['config', e.value]}
                label={e.label}
                rules={[{ required: true }]}
              /></Col><Col span="1" /></>)}</>}
            </>
          }}
        </ProFormDependency>
        <Col span="24" style={{ height: '0px', overflow: 'hidden' }}>
          <ProFormSwitch
            name={['enabled']}
            label={'启用状态'}
            initialValue={false}
            fieldProps={{
              checkedChildren: '启用',
              unCheckedChildren: '禁用'
            }}
          />
          <ProFormText
            name="sellerId"
            label="商家"
            initialValue={'0'}
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ pay }: RootState) => ({
  entity: pay.entity,
  updateSuccess: pay.updateSuccess,
  updating: pay.updating,
  createSuccess: pay.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PayEdit);
