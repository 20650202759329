import { BaseModel } from "../base.model";

export interface Complaint extends BaseModel {
    complainImgList?: string[];
    state?: number;
    complainNo?: any
}

export const handleStatus = {
    1: '待处理',
    2: '已处理'
}

export const departmentArr = {
    "客服部":"客服部",
    "技术部":"技术部",
    "财务部":"财务部",
    "维权部":"维权部",
    "仓储部":"仓储部",
    "采购部":"采购部",
    "会务部":"会务部",
}

export const typeArr = {
    '工作延误':'工作延误',
    '工作失误':'工作失误',
    '解决问题及时性':'解决问题及时性',
    '工作态度':'工作态度',
    '其他':'其他',
}

export const defaultComplaint: Readonly<Complaint> = {};