import React, { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { createEntity, getEntity, reset, updateEntity } from './stock.reducer';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDependency, ProFormDigit, ProFormText } from "@ant-design/pro-form";
import { Col, Form, Radio, Row } from "antd";
import { Stock } from "model/product/stock.model";


export interface StockEditProps extends EditStateAndDispatchProps<Stock>, StateProps, DispatchProps, RouteComponentProps {
}

const StockEdit: FC<StockEditProps> = (props) => {

  const { entity } = props;
  const [quantityModify, setQuantityModify] = useState(0);

  useEffect(() => {
    entity.quantity && setQuantityModify(entity.quantity);
  }, [entity])

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<Stock>((resolve, reject) => {
      if (res) {
        res.adjustmentType === 'Related' && (res.quantity = res.quantityModify);
        resolve(res);
      } else {
        reject();
      }
    })
  };


  return (
    <EditPage<Stock>
      title={'库存信息'}
      beforeUpdateEntity={beforeUpdateEntity}
      {...props}
    >
      <Row>
        <Col span="7">
          <ProFormText
            name={["sku", "id"]}
            label="SkuId"
            width="sm"
            disabled={entity.id ? true : false}
            rules={[{ required: true }]}
          />
        </Col>
        <Col span="1"></Col>
        {entity.id && <Col span="7">
          <Form.Item name="adjustmentType" label="调整类型" rules={[{ required: true }]} initialValue='Absolute'>
            <Radio.Group
              options={[{ label: '绝对调整', value: 'Absolute' },
              { label: '数量加减', value: 'Related' },]}
              optionType="button"
              buttonStyle="solid"
            />
          </Form.Item>
        </Col>}
        {entity.id && <Col span="1"></Col>}
        <Col span="7">
          <ProFormDependency name={['adjustmentType']}>
            {({ adjustmentType }) =>
              adjustmentType === 'Related' ?
                <ProFormDigit
                  name="quantityModify"
                  label={'可售库存加减'}
                  rules={[{ required: true }]}
                  initialValue={0}
                  fieldProps={{
                    onChange: (e: any) => {
                      (entity.quantity && e) && setQuantityModify(e + entity.quantity)
                    }
                  }}
                  min={-1000000}
                  extra={'修改后库存：' + quantityModify}
                /> :
                <ProFormDigit
                  name="quantity"
                  label="可售库存"
                  rules={[{ required: true }]}
                  min={-1000000}
                />
            }
          </ProFormDependency>
        </Col>
        <Col span="7" style={{ 'display': "none" }}>
          <ProFormText
            name={['shopId']}
            label="shopId"
            width="sm"
          />
        </Col>
        <Col span={1}></Col>
      </Row>

    </EditPage>);
};

const mapStateToProps = ({ stock }: RootState) => ({
  entity: stock.entity,
  updateSuccess: stock.updateSuccess,
  updating: stock.updating,
  createSuccess: stock.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StockEdit);
