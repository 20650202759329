import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import FlowTemplate from './flowTemplate';
import BankStatement from './bankStatement';
import Fund from './fund'
import './index.less'

const Financial: React.FC<RouteComponentProps> = () => (
  <Router>
    <FlowTemplate path="flowTemplate/*" />
    <BankStatement path="bankStatement/*" />
    <Fund path="fund/*" />
  </Router>
);
export default Financial;
