import React, { FC, ReactNode, useEffect, useState } from "react";
import { Button, Col, Form, Input, Row } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./tag-details";
import { deleteEntity, getEntities, getEntity, createEntitys } from "./tag.reducer";
import { RouteComponentProps } from "@reach/router";
import TagEdit from './tag-edit';
import { Tag } from "model/customerCore/tag.model";
import { ModalForm, ProFormDependency, ProFormText } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import { fetchCustomerGroupList } from "../group/group.reducer";
import Title from "antd/lib/typography/Title";
import CustomerSelectionForm, { columns as selectColumns } from "../customer/customer-select-form";
import { Customer } from "model/customerCore/customer.model";
import { fetchCustomerList } from "../customer/customer.reducer";
import { PlusOutlined } from "@ant-design/icons";
import ImportDataDialog from "upload/import-data-dialog";

const uploadColumns: ProColumns<any>[] = [{
  title: '客户userId',
  dataIndex: 'userId',
}];

export interface TagListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const TagList: FC<TagListProps> = (props) => {
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [visibleVal, setVisibleVal] = useState<boolean>(false);
  const [pagination, setPagination] = useState<{ page: number, size: number }>({
    page: 0,
    size: 20,
  });
  const [dataSource, setDataSource] = useState<{ total: number, data: Customer[] }>({ total: 0, data: [] });
  const [form] = Form.useForm<Tag>();
  const [whetherRequest, setWhetherRequest] = useState(true);

  useEffect(() => {
    fetchCustomerList({
      page: pagination?.page || 0,
      size: pagination?.size || 20,
      sort: 'lastModifiedDate,desc',
      'id.in': selectedKeys
    }).then(result => {
      setDataSource({
        total: result.headers?.['x-total-count'],
        data: result.data,
      })
    });
  }, [selectedKeys, pagination]);

  useEffect(() => {
    setSelectedKeys([]);
    setPagination({
      page: 0,
      size: 20,
    });
  }, [props]);

  const columns: ProColumns<Tag>[] = [{
    dataIndex: ['customer', 'id'],
    title: '客户id',
    width: 60,
  }, {
    dataIndex: ['customer', 'name'],
    title: '客户名称',
  }, {
    dataIndex: ['group', 'name'],
    title: '标签组名称',
  }]

  const searchFields: SearchField[] = [{
    label: '客户名称',
    name: 'customerName.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <Form.Item name={'tagGroupId.equals'}>
          <SelectSearch required={true} name={['groupId']} width="sm" placeholder="客户标签组" defaultParams='id.equals' fetchFunc={fetchCustomerGroupList} searchParams='name.contains' />
        </Form.Item>
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const tableAlertOptionRender = (res: any) => {
    const Ids = res.selectedRowKeys;
    return <Button type="primary" onClick={async () => {
      const newSelectedKeys = selectedKeys.concat().filter(e => Ids.indexOf(e) === -1);
      setSelectedKeys(newSelectedKeys);
    }} >删除</Button>
  }

  const additionActions = [<ModalForm
    trigger={<Button onClick={() => setVisibleVal(true)} icon={<PlusOutlined />} type="primary">创建</Button>}
    title={'批量创建客户标签'}
    modalProps={{
      destroyOnClose: true,
    }}
    form={form}
    onFinish={async (e) => {
      await createEntitys(e).then(e => {
        props.getEntities({ sort: 'lastModifiedDate,desc' });
      });
      return true;
    }}
    onVisibleChange={(e) => setVisibleVal(e)}
    visible={visibleVal}
  >
    <Row>
      <Col>
        <Form.Item name={'groupId'}>
          <SelectSearch required={true} name={['groupId']} width="sm" label='客户标签组' defaultParams='id.equals' fetchFunc={fetchCustomerGroupList} searchParams='name.contains' />
        </Form.Item>
        <ProFormText
          name={['customerIds']}
          hidden
        />
      </Col>
      <Col span={1} />
      <Col span={24}>
        <CustomerSelectionForm
          trigger="批量添加客户"
          multiple={true}
          requestParams={{}}
          onFinish={(selectedRowKeys, selectedRows) => {
            const newArr = new Set(selectedKeys.concat(selectedRowKeys));
            setSelectedKeys(Array.from(newArr));
            form.setFieldsValue({
              customerIds: Array.from(newArr),
            });
          }}
        />
        <ProFormDependency name={['groupId']}>
          {(({ groupId }) => {
            return groupId && <ImportDataDialog columns={uploadColumns}
              title="批量导入客户"
              buttonType="default"
              uploadEntity={async (val: any) => {
                const arr = val.concat().map((e: any) => e.userId);
                await createEntitys({ groupId, userIds: arr }).then(e => {
                  props.getEntities({ sort: 'lastModifiedDate,desc' });
                });
                form.resetFields();
                setSelectedKeys([]);
                setPagination({
                  page: 0,
                  size: 20,
                });
                setVisibleVal(false);
                return true;
              }}
              uploadParams={{}}
              fieldListErrors={[]}
              ButtonStyle={{ marginLeft: '20px' }}
            />
          })}
        </ProFormDependency>
        {selectedKeys.length > 0 && <>
          <Title style={{ marginTop: '20px' }} level={4}>已选择客户</Title>
          <ProTable<Customer, Record<string, any>>
            rowKey="id"
            search={false}
            dateFormatter="string"
            columns={selectColumns}
            rowSelection={{
              type: 'checkbox',
            }}
            tableAlertOptionRender={tableAlertOptionRender}
            pagination={{
              showQuickJumper: true,
              total: dataSource.total,
              onChange: (page, size) => setPagination({ page: page - 1, size })
            }}
            dataSource={dataSource.data}
          /></>}
      </Col>
    </Row>
  </ModalForm>];

  return (
    <ListViewPage<Tag>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      hideEditButton
      mustFilter={true}
      mustFilterArr={['groupId']}
      hideCreateButton
      actions={additionActions}
      showDownload
      downloadColumns={columns}
      disableDefaultRequest={whetherRequest}
      changeRequestState={setWhetherRequest}
      editPage={(params) => <TagEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ tag }: RootState) => ({
  entity: tag.entity,
  entities: tag.entities,
  loading: tag.loading,
  updateSuccess: tag.updateSuccess,
  total: tag.total,
  createSuccess: tag.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(TagList)