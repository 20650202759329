import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { Client, defaultClient } from "model/system/client.model";

const entity = 'client';
const apiUrl = '/api/yumei-mall-auth/client-details';
const standardReducerImpl = new StandardReducerImpl<Client>(entity, defaultClient, apiUrl);
export type ClientState = StandardState<Client>;
export const client = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


