import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form, Row, Col } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./bankStatement.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { BankStatement } from "model/financial/bankStatement.model";
import { ProFormDateTimePicker, ProFormDigit, ProFormText } from "@ant-design/pro-form";
export interface BankStatementEditProps extends EditStateAndDispatchProps<BankStatement>, StateProps, DispatchProps, RouteComponentProps {
}

const BankStatementEdit: FC<BankStatementEditProps> = props => {
  const [form] = Form.useForm();
  // const { entity } = props;
  const {
    ...editProps
  } = props;
  // const [_, setEntityCopy] = useState<BankStatement>({});

  // useEffect(() => {
  //   if (entity.id) {
  //     const entityCopy = JSON.parse(JSON.stringify(entity));
  //     for (let key in entityCopy) {
  //       if (key !== 'id' && key !== 'bankName' && key !== 'arrivalDateFormat') {
  //         entityCopy[key] = JSON.stringify(entityCopy[key])
  //       }
  //     }
  //     setEntityCopy(entityCopy);
  //   }
  // }, [entity]);

  // 提交前处理数据
  // const UpdateEntity = (data?: any) => {
  //   return new Promise<BankStatement>((resolve, reject) => {
  //     if (data) {
  //       for (let key in data) {
  //         if (key !== 'id' && key !== 'bankName' && key !== 'arrivalDateFormat') {
  //           data[key] = JSON.parse(data[key])
  //         }
  //       }
  //       console.log(data)
  //       resolve(data)
  //     }
  //   })
  // }

  return (
    <EditPage<BankStatement>
      {...editProps}
      title="银行流水"
      form={form}
    >
      <Row>
        <Col span={7}>
          <ProFormText name='payerCode' label="付款码" placeholder='付款码'  rules={[{ required: true }]} />
        </Col>
  
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            name="bankName"
            width="md"
            label="银行名称"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            name="bankAccount"
            width="md"
            label="银行账号"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormDigit
            name="receiptAmount"
            width="md"
            label="收款金额"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormDateTimePicker
            label='到账日期'
            placeholder='输入日期'
            name="arrivalDate"
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            name="counterpart"
            width="md"
            label="交易对方"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            name="flowNo"
            width="md"
            label="单号"
            rules={[{ required: true }]}
          />
        </Col>

        <Col span={1} />
        <Col span={7}>
          <ProFormText
            name="useDescription"
            width="md"
            label="用途说明"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        
        <Col span={7}>
          <ProFormText
            name="remark"
            width="md"
            label="备注:"
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ bankStatement }: RootState) => ({
  entity: bankStatement.entity,
  updateSuccess: bankStatement.updateSuccess,
  updating: bankStatement.updating,
  createSuccess: bankStatement.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BankStatementEdit);
