import { FC, ReactNode, useEffect, useState } from "react";
import { connect } from "react-redux";
import { RootState } from "reducers";
import {
  deleteEntity,
  fetchRelationTree,
  getEntities,
  getEntity,
} from "./relationshipTree.reducer";
import { RouteComponentProps } from "@reach/router";
import { Card, Col, Form, Row, Tree } from "antd";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "../unit/unit.reducer";
import { internationalList } from "components/IntelFormText";

export interface LevelListProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  children?: ReactNode;
}

const LevelList: FC<LevelListProps> = (props) => {
  const { uri, getEntities, entities } = props;

  const [treeData, setTreeData] = useState<any>([]);
  const [userInfo, setUserInfo] = useState<any>({});
  const [storeId, setStoreId] = useState<any>(1);
  const [form] = Form.useForm();

  useEffect(() => {
    uri &&
      getEntities({
        storeId: "1",
        page: 0,
        size: 100,
        isRoot: true,
      });
    form &&
      form.setFieldsValue({
        storeId: 1,
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [uri]);

  useEffect(() => {
    entities &&
      setTreeData(
        entities.concat().map((e: any) => ({
          ...e,
          title:
            internationalList(e.name || "") +
            "--" +
            internationalList(e.levelName) +
            "--" +
            internationalList(e.authorizationCode),
          key: e.id,
        }))
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entities]);

  const updateTreeData = (
    list: any[],
    key: React.Key,
    children: any[]
  ): any[] =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });

  let timeout: any = null;
  const onLoadData = ({ key, children }: any) => {
    return new Promise<void>((resolve) => {
      if (children) {
        resolve();
        return;
      }

      const change = () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        const fake = async () => {
          fetchRelationTree(key, storeId).then((relationTreeArr) => {
            const newArr =
              relationTreeArr && relationTreeArr.length > 0
                ? relationTreeArr.concat().map((e: any) => ({
                    title:
                      internationalList(e.name) +
                      "--" +
                      internationalList(e.levelName) +
                      "--" +
                      internationalList(e.authorizationCode),
                    key: e.id,
                  }))
                : [];
            setTreeData((origin: any) => updateTreeData(origin, key, newArr));
            resolve();
          });
        };
        timeout = setTimeout(fake, 1000);
      };
      change();
    });
  };

  return (
    <div>
      <div className="selectBox">
        <Form form={form}>
          <SelectSearch
            onChange={(e) => {
              getEntities({
                storeId: e,
                page: 0,
                size: 100,
                isRoot: true,
              });
              setStoreId(e);
            }}
            required={false}
            name="storeId"
            placeholder="店铺"
            labelValue="name"
            defaultParams=""
            fetchFunc={fetchStoreList}
            searchParams="name.contains"
          />
        </Form>
      </div>
      <div
        style={{ padding: "20px", boxSizing: "border-box", background: "#fff" }}
      >
        <Row>
          <Col span={15}>
            <Card style={{ height: "90vh" }} title="结构树">
              <Tree
                loadData={onLoadData}
                treeData={treeData}
                onSelect={(key, dom) => {
                  setUserInfo(dom.node);
                }}
              />
            </Card>
          </Col>
          <Col span={8} style={{ marginLeft: "10px" }}>
            <Card style={{ height: "90vh" }} title="用户信息">
              <ul className="userInfoBox">
                <li>
                  <p>
                    <span>用户id</span>：{userInfo.key}
                  </p>
                  <p>
                    <span>用户名</span>：{userInfo.name}
                  </p>
                  <p>
                    <span>经销商等级</span>：{userInfo.levelName}
                  </p>
                  <p>
                    <span>身份标识编码</span>：{userInfo.authorizationCode}
                  </p>
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ relationshipTree }: RootState) => ({
  entity: relationshipTree.entity,
  entities: relationshipTree.entities,
  loading: relationshipTree.loading,
  updateSuccess: relationshipTree.updateSuccess,
  createSuccess: relationshipTree.createSuccess,
  total: relationshipTree.total,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelList);
