import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./retail.reducer";
import { Form } from "antd";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Retail } from "model/scanCodeOrder/retail.model";
;
export interface RetailEditProps extends EditStateAndDispatchProps<Retail>, StateProps, DispatchProps, RouteComponentProps {
}

const RetailEdit: FC<RetailEditProps> = props => {
  const {
    ...editProps
  } = props;

  const [form] = Form.useForm();


  return (
    <EditPage<Retail>
      title="发货订单"
      form={form}
      {...editProps}
    >
    </EditPage>);
};

const mapStateToProps = ({ retail }: RootState) => ({
  entity: retail.entity,
  updateSuccess: retail.updateSuccess,
  updating: retail.updating,
  createSuccess: retail.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RetailEdit);
