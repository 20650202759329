import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form} from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./authFlow.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { AuthFlow } from "model/contract/authFlow.model";
export interface AuthFlowEditProps extends EditStateAndDispatchProps<AuthFlow>, StateProps, DispatchProps, RouteComponentProps {
}

const AuthFlowEdit: FC<AuthFlowEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<AuthFlow>
      title="编辑"
      form={form}
      {...props}
    >
    </EditPage>);
};

const mapStateToProps = ({ authFlow }: RootState) => ({
  entity: authFlow.entity,
  updateSuccess: authFlow.updateSuccess,
  updating: authFlow.updating,
  createSuccess: authFlow.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuthFlowEdit);
