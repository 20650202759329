import { ProFormDigit, ProFormText } from "@ant-design/pro-form";
import { Space } from "antd";
import { useParamGeneral } from "components/useParamGenera";
import { FC } from "react";

interface EditableInputProps {
  onChange?: (value: number | string) => number;
  data?: number | string;
  valueType?: 'money' | 'digit' | 'text' | 'switch';
  digitSuffix?: number;
  disabled?: boolean;
}

const EditableInput: FC<EditableInputProps> = (props) => {
  const { data, onChange, valueType, disabled = false } = props;
  const {currency} = useParamGeneral();

  let timeout: any = null;
  const handleSearch = (value: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    };
    const fake = async () => {
      onChange?.(value)
    };
    timeout = setTimeout(fake, 0);
  }

  return <Space className="editable-input-box">
    {valueType === 'money' && currency && <ProFormDigit
      disabled={disabled}
      fieldProps={{
        value: data,
        style: { width: 150 },
        precision: 2,
        prefix:currency?.symbol,
        onChange: (e: any) => handleSearch(e || 0),
      }}
    />}

    {valueType === 'text' && <ProFormText
      disabled={disabled}
      fieldProps={{
        value: data,
        style: { width: 150 },
        onChange: e => handleSearch(e.target.value),
      }}
    />}

    {valueType === 'digit' && <ProFormDigit
      disabled={disabled}
      fieldProps={{
        value: data,
        precision: 2,
        style: { width: 150 },
        onChange: (e: any) =>  handleSearch(e || 0)
      }}
    />}
  </Space>
};

export default EditableInput;