import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form, Row, Col } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./level.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Level } from "model/dealer/level.model";
import { ProFormDigit, ProFormSwitch, ProFormSelect } from "@ant-design/pro-form";
import IntelFormText from "components/IntelFormText";
import { useEnumValue } from "common/enum/use-enum-value";
export interface LevelEditProps extends EditStateAndDispatchProps<Level>, StateProps, DispatchProps, RouteComponentProps {
}

const LevelEdit: FC<LevelEditProps> = props => {
  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();
  return (
    <EditPage<Level>
      title="级别"
      form={form}
      {...props}
    >
      <Row>
        <Col span={11}>
          <IntelFormText
            label={'等级名称'}
            name="levelName"
            required={true}
            form={form}
            fieldProps={{
              maxLength: 100
            }}
            value={props.entity.levelName || ''}
          />
        </Col>
        <Col span={1} />
        <Col span={11}>
          <ProFormDigit
            disabled={props.entity.id ? true : false}
            name="levelNo"
            label="等级编号"
          />
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <ProFormSelect
            label="等级类型"
            disabled={props.entity.id ? true : false}
            rules={[{ required: true }]}
            options={convertEnumToOptionType('brandLevelType')}
            name='levelType'
            placeholder="选择等级类型"
          />
        </Col>
        <Col span={1} />
        <Col span={5}>
          <ProFormSwitch
            name='canBusinessEnter'
            label="是否对外招商"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>

        <Col span={5}>
          <ProFormSwitch
            name='isCenter'
            label="是否为6S中心"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <IntelFormText
            label={'货款账户显示名称'}
            name="paymentType"
            required={true}
            form={form}
            fieldProps={{
              maxLength: 100
            }}
            value={props.entity.paymentType || ''}
          />
        </Col>
        <Col span={1} />
        <Col span={11}>
          <ProFormDigit
            name="levelSort"
            label="排序"
          /></Col></Row>
    </EditPage>);
};

const mapStateToProps = ({ dealerLevel }: RootState) => ({
  entity: dealerLevel.entity,
  updateSuccess: dealerLevel.updateSuccess,
  updating: dealerLevel.updating,
  createSuccess: dealerLevel.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelEdit);
