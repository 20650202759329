import React, { FC } from 'react'
import { Typography, Timeline, Descriptions } from 'antd';
import { AfterSales, RecordsItem } from 'model/procure/after-sales.model';
import { useEnumValue } from 'common/enum/use-enum-value';
const { Link } = Typography;

export interface OrderModalShipProps {
    entity: AfterSales,
    isTenantRole: boolean
}

// 协商经过
const AfterSalesNegotiate: FC<OrderModalShipProps> = (props) => {
  const { entity } = props;
  const { getEnumValueDesc } = useEnumValue();

  return (
    <>
      {
        entity.records && entity.records.map((item: RecordsItem) => {
          let afterImgs = item.operationContentJson
              && item.operationContentJson?.refundVoucher
              && item.operationContentJson?.refundVoucher.split(',');
          return (
              <div key={item.id}>
              {
                  item.operationType === 1 &&
                  <Timeline.Item dot={
                  <h3 className="order-after-process" style={{ background: `${props.isTenantRole ? '#b9b9b9' : '#8cc5ff'}` }}>
                      买
                  </h3>}>
                  <Descriptions title={`${item.operationTitle}`} column={1}>
                      <Descriptions.Item label="售后方式" >{getEnumValueDesc('afterSaleType', entity?.afterSaleType || '')}</Descriptions.Item>
                      {item.operationContentJson &&
                      (<>
                          {item.operationContentJson?.refundReason?.desc &&
                          <Descriptions.Item label="退款原因">{item.operationContentJson?.refundReason?.desc}</Descriptions.Item>}
                          {item.operationContentJson?.refundAmount &&
                          <Descriptions.Item label="退款金额">{item.operationContentJson?.refundAmount}</Descriptions.Item>}
                          {item.operationContentJson?.refundExplain &&
                          <Descriptions.Item label="退款说明">{item.operationContentJson?.refundExplain}</Descriptions.Item>}
                          {entity.contactMobile && <Descriptions.Item label="联系电话">{entity.contactMobile}</Descriptions.Item>}
                          {item.operationContentJson?.refundVoucher &&
                          <Descriptions.Item label="售后图片">
                              {
                              afterImgs && afterImgs.map((imgItem: any, index: number) => {
                                  return (
                                  <Link key={imgItem + index} href={imgItem} target='_blank'>
                                      <img alt={'售后图片' + index} style={{ width: '200px', height: 'auto' }} src={imgItem} />
                                  </Link>
                                  )
                              })
                              }
                          </Descriptions.Item>
                          }
                          {item.operationContentJson?.expressName &&
                          <Descriptions.Item label="快递公司">{item.operationContentJson?.expressName}</Descriptions.Item>}
                          {item.operationContentJson?.expressNo &&
                          <Descriptions.Item label="快递单号">{item.operationContentJson?.expressNo}</Descriptions.Item>}
                          {item.operationContentJson?.expressRemark &&
                          <Descriptions.Item label="备注信息">{item.operationContentJson?.expressRemark}</Descriptions.Item>}
                      </>)
                      }
                  </Descriptions>
                  </Timeline.Item>
              }
              {/* //  item.operationType=2时  平台：系；商家：卖  */}
              {
                  item.operationType === 2 &&
                  <Timeline.Item dot={
                  <h3 className="order-after-process" style={{ background: `${props.isTenantRole ? '#b9b9b9' : 'brown'}` }}>
                      卖
                  </h3>}>
                  <Descriptions title={`${item.operationTitle}`} column={1} extra={[]}>
                      {item.operationContentJson &&
                      (<>
                          {item.operationContentJson?.refuseReason?.desc && <Descriptions.Item label="拒绝原因">{item.operationContentJson?.refundReason?.desc}</Descriptions.Item>}
                          {item.operationContentJson?.consignee && <Descriptions.Item label="收件人">{item.operationContentJson?.consignee}</Descriptions.Item>}
                          {item.operationContentJson?.mobile && <Descriptions.Item label="手机号">{item.operationContentJson?.mobile}</Descriptions.Item>}
                          {item.operationContentJson?.mergeName && <Descriptions.Item label="详细地址">{item.operationContentJson?.mergeName} {item.operationContentJson?.address}</Descriptions.Item>}
                      </>)
                      }
                  </Descriptions>
                  </Timeline.Item>
              }
              </div>
          )
        })
      }
    </>
  )
}


export default AfterSalesNegotiate;