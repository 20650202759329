import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './assort.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Assort } from "model/dealer/assort.model";

export interface AssortDetailsProps extends DetailsStateAndDispatchProps<Assort,'articleStatus'>,
  RouteComponentProps, StateProps, DispatchProps {
}
const AssortDetails: FC<AssortDetailsProps> = (props) => {
  return (
    <DetailsPage<Assort,'articleStatus'>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Assort>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ assort }: RootState) => ({
  entity: assort.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AssortDetails);
