import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultRegionalRewards, regionalRewardsModel } from 'model/reward/regionalRewards.model';
import axios from 'axios';

const entity = 'modifyDealer';
const apiUrl = '/api/yumei-mall-region/regionalOwnershipResource/pageRecord';
const standardReducerImpl = new StandardReducerImpl<regionalRewardsModel>(entity, defaultRegionalRewards, apiUrl);
export type ModifyDealerState = StandardState<regionalRewardsModel>;
export const modifyDealer = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


export const addRegionOwnerRecordDto = async (data: any) => {
    return await axios.post<any>(`/api/yumei-mall-region/regionalOwnershipResource/addRegionOwnerRecordDto`, data);
}