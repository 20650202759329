import { FC, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, fetchOrderDetails, getEntity, reset, updateEntity } from "./return-package.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import ProForm, { ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { Col, Form, Input, message, Row } from "antd";
import { ReceivingLocation, ReturnPackage, ReturnPackageOrderType } from "model/procure/return-package.model";
import ReturnPackagePimDetails from "./return-package-pim-details";
import { useEnumValue } from "common/enum/use-enum-value";
import SelectSearch from "components/select-search";
import { fetchWarehouseList } from "../after-sales/after-sales.reducer";
export interface ReturnPackageEditProps extends EditStateAndDispatchProps<ReturnPackage>, StateProps, DispatchProps, RouteComponentProps {
}
const { Search } = Input;

const ReturnPackageEdit: FC<ReturnPackageEditProps> = props => {
  const { entity, text } = props;
  const [form] = Form.useForm<ReturnPackage>();
  const { convertEnumToOptionType } = useEnumValue();
  const [required, setRequired] = useState(true);
  const [disable, setDisable] = useState(false);

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      res.packageProductDetails = res.packageProductDetails.concat().map((e: any) => {
        const newId = e.id.split('_date').length === 1 ? e.id : null;
        let newReason = '';
        typeof (e.reason) === 'object' && e.reason.length > 0 && e.reason.forEach((e: string) => newReason = newReason + e + ',' );
        return {
          ...e,
          id: newId,
          reason: newReason
        }
      });
      resolve(res);
    })
  }

  return (
    <EditPage<ReturnPackage>
      {...props}
      title="退回包裹登记"
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
      width={1000}
    >
      <Row>
        <Col span={7}>
          <ProFormSelect
            label="单号类型"
            name="orderSnType"
            options={Object.entries(ReturnPackageOrderType).map(([key, value]) => ({ value: String(key), label: value }))}
          />
        </Col>
        <Col span='1' />
        <Col span={7}>
          <ProForm.Item label="退货原始单号" name="orderSn">
            <Search placeholder="请输入退货原始单号查询" disabled={entity?.id ? true : false} onSearch={(e: any) => {
              if (e) {
                fetchOrderDetails(e + '').then((e: any) => {
                  form.setFieldsValue({
                    belongTstCode: e.belongTstCode,
                    returnPackageName: e.address.consigneeName,
                    provinceName: e.address.provinceName,
                    cityName: e.address.cityName,
                    districtName: e.address.districtName,
                    address: e.address.address,
                    phone: e.address.mobile,
                  });
                  setDisable(true);
                  setRequired(true);
                  message.success('查询成功');
                }).catch(err => message.error('查询失败，请核对订单原始单号'))
              } else {
                message.error('请输入订单id')
              }
            }} enterButton="Check" />
          </ProForm.Item>
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormSwitch
            name='virtualPackage'
            label="虚拟包裹"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否',
              onChange: (e) => { setRequired(!e); setDisable(false); }
            }}
          />
        </Col>
        <Col span={7}>
          <ProFormText
            label="退货订单号"
            name="returnOrderSn"
            rules={[{ required: true }]}
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="code"
            disabled={disable}
            name="belongTstCode"
            rules={[{ required: required }]}
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="退货人姓名"
            disabled={disable}
            name="returnPackageName"
            rules={[{ required: required }]}
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="退货人电话"
            disabled={disable}
            name="phone"
            rules={[{ required: required }]}
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="省"
            disabled={disable}
            rules={[{ required: required }]}
            name="provinceName"
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="市"
            disabled={disable}
            rules={[{ required: required }]}
            name="cityName"
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="区"
            disabled={disable}
            rules={[{ required: required }]}
            name="districtName"
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="退货人地址"
            disabled={disable}
            rules={[{ required: required }]}
            name="address"
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProForm.Item label="所属仓库" name="warehouseCode" required={required} >
            <SelectSearch required={required} width={'sm'} name="warehouseCode" optionValue="code" placeholder="所属仓库" labelValue="name" defaultParams='' fetchFunc={fetchWarehouseList} searchParams='name' />
          </ProForm.Item>
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="物流方式"
            name="expressName"
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormText
            label="运单号"
            name="expressNo"
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormSelect
            label="收退换类型"
            rules={[{ required: required }]}
            name="receiveRefundExchangeType"
            options={convertEnumToOptionType('receiveRefundExchangeType')}
          />
        </Col><Col span='1' />
        <Col span={7}>
          <ProFormSelect
            label="收货地点"
            rules={[{ required: required }]}
            options={Object.entries(ReceivingLocation).map(([key, value]) => ({ value: String(key), label: value }))}
            name="receivingLocation"
          />
        </Col><Col span='1' />
        <Col span={24}>
          <ProFormTextArea
            label="订单备注"
            name="orderRemark"
          />
        </Col>
        <Col span={24}>
          <Form.Item name="packageProductDetails">
            <ReturnPackagePimDetails text={text} />
          </Form.Item>

        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ orderReturnPackage }: RootState) => ({
  entity: orderReturnPackage.entity,
  updateSuccess: orderReturnPackage.updateSuccess,
  updating: orderReturnPackage.updating,
  createSuccess: orderReturnPackage.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPackageEdit);
