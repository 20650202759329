import axios from 'axios';
import { MaterialDown, defaultMaterialDown } from 'model/product/materialDown.model';
import { CrudDeleteAction, CrudGetAction, CrudGetAllAction, CrudPostAction, CrudPutAction } from 'reducers/redux-action.type';
import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import { cleanEntity } from 'utils/entity-utils';
const entity = 'MaterialDown';
const apiUrl = '/api/yumei-mall-post/inventory';
const standardReducerImpl = new StandardReducerImpl<MaterialDown>(entity, defaultMaterialDown, apiUrl);
export type MaterialDownState = StandardState<MaterialDown>;
export const materialDown = standardReducerImpl.buildStandardReducer();

// export const getEntities = standardReducerImpl.getEntities;
// export const getEntity = standardReducerImpl.getEntity;
// export const createEntity = standardReducerImpl.createEntity;
// export const updateEntity = standardReducerImpl.updateEntity;
// export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


export const getEntities: CrudGetAllAction<any> = (params?: any) => {
    const requestUrl = `${apiUrl}`;
    return {
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST),
      payload: axios.get<any>(requestUrl,{
        params: params
      }),
    };
  };
  
  export const getEntity: CrudGetAction<MaterialDown> = id => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH),
      payload: axios.get<any>(requestUrl),
    };
  };
  
  export const createEntity: CrudPostAction<MaterialDown> = entity => async dispatch => {
    const requestUrl = `${apiUrl}`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
      payload: axios.post<any>(requestUrl, cleanEntity(entity)),
    });
  };
  
  export const updateEntity: CrudPutAction<MaterialDown> = entity => async dispatch => {
    const requestUrl = `${apiUrl}/${entity.id}`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.put<any>(requestUrl, entity),
    });
  };

  
  export const deleteEntity: CrudDeleteAction<MaterialDown> = (id, remove) => async dispatch => {
    const requestUrl = `${apiUrl}/${id}`;
    return {
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.delete(requestUrl),
    };
  };



export const showHideMeeting = async (id: any, hidden: boolean) => {
    return await axios.get<any>(`/api/yumei-mall-post/inventory/${id}/${hidden}`);
}






