import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './customer.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Customer } from "model/customerCore/customer.model";
import Title from "antd/lib/typography/Title";

export interface CustomerDetailsProps extends DetailsStateAndDispatchProps<Customer, 'credentialsType'>,
  RouteComponentProps, StateProps, DispatchProps {
}

const CustomerDetails: FC<CustomerDetailsProps> = (props: any) => {

  const { entity } = props;

  return (
    <DetailsPage<Customer, 'credentialsType'>
      title={'基础信息'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Customer, 'credentialsType'>
        column={2}
        columns={props.columns}
        dataSource={entity}
      >
      </ProDescriptions>
      <Title style={{ marginTop: '40px' }} level={5}>级别信息</Title>
      <div>
        {
          entity.relationshipList && entity.relationshipList.map((e: any) => {
            return <p>{e.storeName}：{e.levelName}</p>
          })
        }
      </div>
      <Title style={{ marginTop: '40px' }} level={5}>经营地址</Title>
      <p >{entity?.address?.provinceName + entity?.address?.cityName + entity?.address?.districtName + entity?.address?.address}</p>
    </DetailsPage>);
};

const mapStateToProps = ({ customer }: RootState) => ({
  entity: customer.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
