import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./resources.reducer";
import { Resources } from "model/system/resources.model";
import { ProFormList, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Col, Form, message, Row } from "antd";
import { fetchRoleList } from "../../user/role/role.reducer";
import { Role } from "model/system/role.model";
import { ProColumns } from "@ant-design/pro-table";
import EditPermissions from "../../user/permission/edit-permissions";
import { fetchModuleList } from "../power-module/power-module.reducer";
export interface ResourcesEditProps extends EditStateAndDispatchProps<Resources>, StateProps, DispatchProps, RouteComponentProps {
}

const ResourcesEdit: FC<ResourcesEditProps> = props => {
  const { entity, ...editProps } = props;
  const [form] = Form.useForm();

  const [roleList, setRoleList] = useState<any>([]); //角色option
  const [moduleList, setModuleList] = useState<any>([]); //模块
  const [entityCopy, setEntityCopy] = useState<Resources>({});

  useEffect(() => {
    requestRoleList({});
    requestModuleList({});
    setEntityCopy({});
  }, []);

  let timeout: any = null;
  const handleSearch = (e?: any, type?: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    };
    const fake = async () => {
      type === 'role' && requestRoleList({ 'name.contains': e });
      type === 'module' && requestModuleList({ 'name.contains': e })
    };
    timeout = setTimeout(fake, 1000);
  };

  const requestRoleList = (params: any) => {
    fetchRoleList({ page: 0, size: 500, ...params }).then(e => {
      setRoleList(e.data.map((item: Role) => ({
        label: item.name,
        value: item.name + ',' + item.id + ',' + item.code
      })));
    });
  };

  const requestModuleList = (params: any) => {
    fetchModuleList({ page: 0, size: 500, ...params }).then(e => {
      setModuleList(e.map((item: any) => ({
        label: item.name,
        value: item.name + ',' + item.code,
      })));
    });
  };

  useEffect(() => {
    if (entity.id) {

      let copy = JSON.parse(JSON.stringify(entity));
      copy.permissions = copy.permissions?.map((e: any) => {

        e.read = [e.read ? 'read' : ''].filter(e => e !== '');
        e.write = [e.write ? 'write' : ''].filter(e => e !== '');
        e.delete = [e.delete ? 'delete' : ''].filter(e => e !== '');

        e.role.id = (e.role.name + ',' + e.role.id + ',' + e.role.code);
        return e
      }) || [];
      copy.module = {
        code: copy.module?.name + ',' + copy.module?.code,
        name: copy.module?.name
      };
      copy.urls = copy.urls.map((e: string) => ({ url: e }))
      setEntityCopy(copy);
    } else {
      setEntityCopy({});
    };
  }, [entity])

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        res.urls = res.urls.map((e: any) => e.url);
        res.module = {
          name: res.module.code.split(',')[0],
          code: res.module.code.split(',')[1]
        };
        res.permissions ? (res.permissions = res.permissions.map((e: any) => {
          const operation = {
            read: !e.read ? false : (e.read.length > 0 ? true : false),
            write: !e.write ? false : (e.write.length > 0 ? true : false),
            delete: !e.delete ? false : (e.delete.length > 0 ? true : false)
          };
          (e.index || e.index === 0) && delete e.id;
          return {
            ...e,
            role: {
              ...e.role,
              id: Number(e.role.id.split(',')[1]),
              code: e.role.id.split(',')[2],
            },
            ...operation
          };
        })) : (res.permissions = []);
        resolve(res);
      } else {
        message.info('请刷新页面后重试');
        reject();
      }

    })
  }

  const editableColumns: ProColumns<any>[] = [{
    title: '角色',
    dataIndex: ['role', 'id'],
    valueType: 'select',
    renderFormItem: (text, record, valueType) => {
      return <ProFormSelect
        label=" "
        options={roleList}
        showSearch
        rules={[{ required: true }]}
        fieldProps={{
          onSearch: (e) => handleSearch(e, 'role'),
          onChange: (val) => {
            const index = text.index;
            const roleIds = form.getFieldValue('permissions') ? form.getFieldValue('permissions').map((e: any) => e?.role?.id) : [];
            const filter = roleIds.filter((e: any) => e === val);
            ((index === roleIds.length && filter.length > 0) || filter.length > 1) && message.error('角色已存在，请重新选择角色');
          }
        }}
      />
    },
    render: (e, record) => <>{record.role.id.split(',')[0]}</>,
  }];

  return (
    <EditPage<Resources>
      title="资源"
      beforeUpdateEntity={beforeUpdateEntity}
      form={form}
      entity={entityCopy}
      {...editProps}
    >
      <Row>
        <Col span="7">
          <ProFormText
            name="code"
            label="资源编码"
            rules={[{
              required: true
            }]}
            disabled={entityCopy.code ? true : false}
          />
        </Col>
        <Col span={1}></Col>
        <Col span="7">
          <ProFormText
            name="description"
            label="资源名称"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={'7'}>
          <ProFormSelect
            label="模块"
            name={['module', 'code']}
            options={moduleList}
            rules={[{ required: true }]}
            showSearch
            fieldProps={{
              onSearch: (e) => handleSearch(e, 'module')
            }}
          />
        </Col>
        <Col span="24">
          <ProFormList
            name="urls"
            creatorButtonProps={{
              creatorButtonText: '添加资源路径',
            }}
            initialValue={[
              { url: '' },
            ]}
          >
            <Row style={{ width: '600px' }}>
              <Col span="22">
                <ProFormText
                  name={'url'}
                  label="资源路径"
                  //rules={[{ required: true }]}
                  tooltip={<>
                    <p>?  匹配任何单字符</p>
                    <p>*  匹配0或者任意数量的字符</p>
                    <p>** 匹配0或者更多的目录</p>
                    <p>例：/yumei-mall-auth/**</p>
                  </>}
                />
              </Col>
            </Row>
          </ProFormList>
        </Col>
        <Form.Item name="permissions">
          <EditPermissions bindKey="role" editableColumns={editableColumns} />
        </Form.Item>
        <Col span="24" style={{ display: 'none' }}>
          <ProFormSelect
            mode="multiple"
            //rules={[{ required: true }]}
            name="scopes"
            initialValue={["backend"]}
            options={[]}
            label="范围"
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ resources }: RootState) => ({
  entity: resources.entity,
  updateSuccess: resources.updateSuccess,
  updating: resources.updating,
  createSuccess: resources.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesEdit);
