import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import './index.less'
import SendOutGoods from './sendOutGoods';
import Retail from './retail';
import ExchangeGoods from './exchangeGoods';
import Exchange from './exchange';
import ReturnGoods from './returnGoods';
import ScanCodeTranSferGoods from './scanCodeTranSferGoods';
import Logisticscode from './logisticscode';

const ScanCodeOrder: React.FC<RouteComponentProps> = () => (
    <Router>
        <SendOutGoods path="sendOutGoods/*" />
        <Retail path="/retail" />
        <ScanCodeTranSferGoods path="/local"  />
        <ExchangeGoods path="exchangeGoods/*" />
        <ReturnGoods path="returnGoods/*" />
        <Exchange path="exchange/*" />
        <Logisticscode path="logisticscode/*" />
    </Router>
);
export default ScanCodeOrder;
