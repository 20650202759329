
import axios from 'axios';
import { defaultTagTask, TagTask } from 'model/customerCore/tagTask.model';
import { Payload, PayloadResult } from 'reducers/redux-action.type';
import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';

const entity = 'tag';
const apiUrl = '/api/yumei-mall-tag-task/tag/task';
const standardReducerImpl = new StandardReducerImpl<TagTask>(entity, defaultTagTask, apiUrl);

export type TagTaskState = StandardState<TagTask>;
export const tagTask = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const activate = (id: string): Payload<TagTask> | PayloadResult<TagTask> => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/activate`;
  return await dispatch({
    type: `${entity}/${STANDARD_ACTION_TYPES.UPDATE}`,
    payload: axios.post(requestUrl),
  })
};

export const deactivate = (id: string): Payload<TagTask> | PayloadResult<TagTask> => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/stop`;
  return await dispatch({
    type: `${entity}/${STANDARD_ACTION_TYPES.UPDATE}`,
    payload: axios.post(requestUrl),
  })
};