import { FC, ReactNode } from "react";
import { Form, Tooltip } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListDetails from "./category-details";
import { deleteEntity, getEntities, getEntity, createEntity, reset } from "./category.reducer";
import { RouteComponentProps } from "@reach/router";
import { Category } from "model/product/category.model";
import Button from "antd/es/button";
import { ModalForm, ProFormDigit } from "@ant-design/pro-form";
import { PlusOutlined } from "@ant-design/icons";
import ListViewPage from "layouts/page-wraper/list-view-page";
import CategoryEdit from "./category-edit";
import IntelFormText, { internationalList } from "components/IntelFormText";
import Authorized from "common/auth/authorized";

export interface CategoryListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode; 
  isModalVisible?: boolean;
}

const columns: ProColumns<Category>[] = [{
  title: '类目名称',
  dataIndex: 'name',
  valueType: 'text',
  render: (dom, record) => <>{internationalList(record.name || '')}</>
}, {
  title: '所属层级',
  dataIndex: 'level',
  valueType: 'text',
}, {
  title: '类目id',
  dataIndex: 'id',
  valueType: 'text',
}, {
  title: '排序',
  dataIndex: 'sort'
}];


const CategoryList: FC<CategoryListProps> = (props) => {
  const [form] = Form.useForm();
  return (
    <ListViewPage<Category>
      columns={columns}
      rowKey='id'
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      // expandable={{
      //   childrenColumnName: 'children',
      //   expandedRowKeys: !!entities && Array.isArray(entities) && entities.map(e => e.id),
      //   expandIconColumnIndex: -1,
      // }}
      rowActions={[
        (dom: ReactNode, record: Category) => record.level === 1 && <Authorized authority='addSubclass'><ModalForm
          title={internationalList(record.name || '')}
          trigger={<Tooltip title={'新增子类'} key="AuditReject"><Button type="link" size="small" icon={<PlusOutlined />} /></Tooltip>}
          modalProps={{
            destroyOnClose: true,
          }}
          form={form}
          preserve={false}
          onFinish={async (data) => {
            const qualifications = data.qualifications?.map((e: string) => ({ id: e })) || [];
            props.createEntity({ ...data, parentId: String(record.id), level: 2, pathIds: String(record.id), qualifications });
            return true;
          }}
        >
          <IntelFormText
            label={'子分类名称'}
            name="name"
            required={true}
            form={form}
            fieldProps={{
              maxLength: 100
            }}
            value={props.entity.name || ''}
          />
          <ProFormDigit
            name="sort"
            label="排序"
            rules={[{ required: true }]}
            min={0}
          />
        </ModalForm></Authorized>
      ]}
      editPage={(params: any) => <CategoryEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ category }: RootState) => ({
  entity: category.entity,
  entities: category.entities,
  loading: category.loading,
  updateSuccess: category.updateSuccess,
  total: category.total,
  createSuccess: category.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  createEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(CategoryList)