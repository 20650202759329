import axios from "axios";
import { defaultFund, Fund } from "model/financial/fund.model";
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'logisticscode';
const apiUrl = '/api/yumei-mall-shipment/barcode-change-record/getBarcodeChangeRecord';
const standardReducerImpl = new StandardReducerImpl<Fund>(entity, defaultFund, apiUrl);
export type LogisticscodeState = StandardState<Fund>;
export const logisticscode = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 根据物流码查询商品
export const getGoodByLogisticsCode = async (data:any): Promise<any> => {
    const result = await axios.get(`/api/yumei-mall-shipment/barcode-change-record/getGoodByLogisticsCode`, {params: {...data}});
    return result
}

// 新增物流码
export const addBarcodeChangeRecord = async (params: any) => {
  const requestUrl = `/api/yumei-mall-shipment/barcode-change-record/addBarcodeChangeRecord`;
  return await axios.post(requestUrl, { ...params })
}


export const fetchCustomerList = async (params?: any): Promise<any[]> => {
    const paramsData = {
      page: 0,
      size: 10000,
      sort: 'lastModifiedDate,desc',
      ...params
    };
    const axiosResponse = await axios.get('/api/yumei-mall-customer/customer/list', { params: { ...paramsData } });
    const data = axiosResponse.data.map((re: any) => ({
      ...re,
      label: re.name + '-' + re.account
    }));
  
    return data || [];
  }
