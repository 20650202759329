import axios from 'axios';
import { defaultUnit, Unit } from 'model/dealer/unit.model';
import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import { CrudPutAction, QueryParameter } from 'reducers/redux-action.type';
import { internationalList } from 'components/IntelFormText';
const entity = 'unit';
const toExamineapiUrl = '/api/yumei-mall-pim//brand/authorization/application';
const apiUrl = '/api/yumei-mall-pim/brand/authorization/application';
const standardReducerImpl = new StandardReducerImpl<Unit>(entity, defaultUnit, apiUrl);
export type UnitState = StandardState<Unit>;
export const unit = standardReducerImpl.buildStandardReducer();
export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

//库存单位
export const fetchUnitList = async (params?: any) => {
  const requestUrl = `/api/yumei-mall-pim/brand/authorization/application`;
  const axiosResponse = await axios.get<any>(requestUrl, { params: { ...params, size: 100 } });
  return axiosResponse.data;
}

// 审核详情
export const auditDetail = async (id: any) => {
  const requestUrl = `/api/yumei-mall-pim/brand/authorization/application/${id}/audit-detail`;
  const axiosResponse = await axios.post(requestUrl)
  return axiosResponse.data || []
};

// 城市地址
export const queryBladeSystemSelect = async (code: string | number) => {
  const requestUrl = `/api/yumei-mall-region/region?parentCode.equals=${code}&size=100`;
  const res = await axios.get(requestUrl);
  return res.data;
}

// 查询所有等级
export const fetchLevelList = async (params?: any) => {
  const requestUrl = `/api/yumei-mall-pim/brand/level/list`;
  const axiosResponse = await axios.get<any>(requestUrl, { params:{...params} });
  return axiosResponse.data;
}



export const fetchCustomerList = async (params?: any) => {
  return await axios.get<any>('/api/yumei-mall-customer/customer', { params:{...params} });
}

export const fetchCustomerListSelect = async (params?: any) => {
   const data = await axios.get<any>('/api/yumei-mall-customer/customer', { params:{...params} });
   return data?.data || []
}


export const fetchCustomerListSelect1 = async (params?: any) => {
  const data = await axios.get<any>('/api/yumei-mall-customer/customer', { params:{...params} });
  let list = data?.data
  list.map((item:any)=> {
    return item.textname = item.account + '-' + item.phone + '-' + item.name
  })
  return list || []
}




// 审批
export const auditRefuse = async (entity:any) => {
  const requestUrl = `${toExamineapiUrl}/${entity.brandAuthorizationApplication.id}/audit`;
  const axiosResponse = await axios.post<any>(requestUrl, entity);
  return axiosResponse;
}


// 充值
export const recharge: CrudPutAction<any> = entity => async dispatch => {
  const requestUrl = `/api/yumei-mall-pim/brand/authorization/application/recharge`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, entity),
  });
};

// 充值
export const recharge1 = async (params: any) => {
  const requestUrl = `/api/yumei-mall-pim/brand/authorization/application/recharge`;
  const axiosResponse = await axios.post(requestUrl, params)
  return axiosResponse.data || []
};

// 邀请人列表
export const searchCustomerList = async (params: any) => {
  const requestUrl = `/api/yumei-mall-customer/customer`;
  const axiosResponse = await axios.get<any>(requestUrl, { params:{...params} });
  return axiosResponse.data || []
}

// 查询款项类型列表
export const rechargeTypeList = async (params?: any) : Promise<any[]> => {
  const axiosResponse = await axios.get<any[]>(`/api/yumei-mall-pim/meta-data/enums`, {
      params: params
  });
  return axiosResponse.data || [];
}

// 充值时可选店铺列表
export const rechargeList = async (params?: any) : Promise<any[]> => {
  const axiosResponse = await axios.get<any[]>(`/api/yumei-mall-store/seller/recharge`, {
      params
  });
  return axiosResponse.data || [];
}

// 充值时可选店铺列表
export const nearestList = async (params?: any) : Promise<any[]> => {
  const axiosResponse = await axios.get<any[]>(`/api/yumei-mall-store/seller/recharge/nearest-seller`, {
      params
  });
  return axiosResponse.data || [];
}

// 查询店铺名称
export const fetchStoreList = async (params?: QueryParameter) : Promise<any[]> => {
  const paramsData = {
    'selfSeller.equals':true,
    'isCenter.equals':false,
     ...params
  };
  const axiosResponse = await axios.get<any[]>(`${'/api/yumei-mall-store/seller'}`, {
      params: paramsData
  });

  axiosResponse.data.map((item:any)=> {
    item.label = item.name
    item.value = parseInt(item.id)
    return item
  })
  return axiosResponse.data || [];
}

export const fetchMessageTemplateMList = async (params?: QueryParameter) : Promise<any[]> => {
  const axiosResponse = await axios.get<any[]>(`${'/api/yumei-mall-message/message/template'}`, {
    
      params:{
        'type.equals':9,
        ...params
      }
  });

  axiosResponse.data.map((item:any)=> {
    item.label = item.title
    item.value = parseInt(item.id)
    return item
  })
  return axiosResponse.data || [];
}

// 获取所有品牌
export const getBrandAll = async() => {
  const requestUrl = `/api/yumei-mall-pim/brand/all`;
   let axiosResponse = await axios.get(requestUrl)
   const data = axiosResponse.data.map((re: any) => ({
    ...re,
    brandName: internationalList(re?.brandName || ''),
    brandId: re.id,
    label: internationalList(re?.brandName || ''),
    value: re.id
  }));
  return data || [];
}


