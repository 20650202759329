import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { Button, Form, Input, Tooltip, message } from "antd";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity, unBind, updateCustomer, updateTags, editePwd, updateAuthorityFunc } from "./customer.reducer";
import { RouteComponentProps } from "@reach/router";
import { Customer } from "model/customerCore/customer.model";
import { useState } from "react";
import CustomerEdit from './customer-edit';
import { ModalForm, ProFormText } from "@ant-design/pro-form";
import CustomerDetails from './customer-details'
import { fetchCustomerGroupList } from "../group/group.reducer";
import EditePwdDialog from './editePwdDialog'
import SelectSearch from "components/select-search";
import DecryptText from "pages/dealer/customer/detailtables/decryptText";

export interface CustomerListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}


const CustomerList: FC<CustomerListProps> = (props) => {
  const [form] = Form.useForm<{}>();
  let [key, setKey] = useState<any>(0);
  let [tagDialog, setTagDialog] = useState<any>(false);
  let [record, setRecord] = useState<any>({});
  let [editePwdDialogOpen, setEditePwdDialogOpen] = useState<any>(false);
  const [refreshFlag, setRefreshFlag] = useState(0);

  const columns: ProColumns<Customer, 'credentialsType'>[] = [
    {
      title: '用户ID',
      dataIndex: 'userId',
      valueType: 'text'
    }, {
      title: '授权码',
      dataIndex: 'account',
      valueType: 'text'
    }, {
      title: '经销商名称',
      dataIndex: 'name',
      valueType: 'text'
    }, {
      title: '生日',
      dataIndex: 'birthday',
      valueType: 'text',
      hideInTable: true
    }, {
      title: '登录账号',
      dataIndex: 'userAccount',
      valueType: 'text'
    }, {
      title: '手机号',
      dataIndex: 'phone',
      valueType: 'text',
      hideInDescriptions:true,
      render: (dom: any, record: any) => {
        return record.phoneRegion + ' ' + record.phone
      }
    },{
      title: '手机号',
      dataIndex: 'phone',
      hideInTable:true,
      render:(text,record) => {
        return <div style={{display:'flex'}}>
        ******<DecryptText title='手机号' id={record.id} data={record.phone} />
        </div>
      }
    },
    {
      title: '证件号码',
      dataIndex: 'credentialsNo',
      valueType: 'text',
      hideInDescriptions:true,
    },{
      title: '证件号码',
      dataIndex: 'credentialsNo',
      hideInTable:true,
      render:(text,record) => {
        return <div style={{display:'flex'}}>
        ******<DecryptText title='证件号码' id={record.id} data={record.credentialsNo} />
        </div>
      }
    },
    {
      title: '证件类型',
      dataIndex: 'credentialsType',
      valueType: 'credentialsType'
    },
    {
      title: '历史授权码',
      dataIndex: 'relationshiplist',
      hideInDescriptions: true,
      render: (text: any, row: any) => {
        return row.relationshipList && row.relationshipList.map((item: any) => {
          return <p>{item.storeName}: {item.code}</p>
        })

      }
    },
    {
      title: '当前下单权限',
      dataIndex: 'authorizedStoresName',
      hideInDescriptions: true,
      render: (text: any, row: any) => row.authorizedStoresName && row.authorizedStoresName.map((item: any) => <p>{item}</p>)
    },
    {
      title: '未授权下单品牌',
      dataIndex: 'notAuthorizedBrandName',
      hideInDescriptions: true,
      render: (text: any, row: any) => row.notAuthorizedBrandName && row.notAuthorizedBrandName.map((item: any) => <p>{item}</p>)
    },
    {
      title: '首次邀请人ID',
      dataIndex: 'parentCustomerId',
      valueType: 'text',
      hideInTable: true,
      hideInDescriptions: true,
    }, {
      title: '邀请人授权码',
      dataIndex: '',
      valueType: 'text',
      hideInTable: true,
    }, {
      title: '邀请人姓名',
      dataIndex: '',
      valueType: 'text',
      hideInTable: true,
    }, {
      title: '经营地址',
      dataIndex: '',
      valueType: 'text',
      hideInTable: true,
      hideInDescriptions: true,
      render: (dom: any, record: any) => {
        return record?.address?.provinceName + record?.address?.cityName + record?.address?.districtName + record?.address?.address
      }
    },
  ];

  const searchFields: SearchField[] = [{
    label: '用户ID',
    name: 'userId.equals',
    dataType: 'number'
  }, {
    label: '姓名',
    name: 'name.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>

        <ProFormText
          name="account.equals"
          placeholder="申请授权码"
        />
        <div className="wd10" />
        <ProFormText
          name="phone.equals"
          placeholder="手机号"
        />
        <div className="wd10" />
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  // 审核
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 19 },
    },
  };

  // 打开标签
  const openTag = (record: any) => {
    let tagList: any = []
    if (record.tags?.length) {
      record.tags.forEach((item: any) => {
        tagList.push(item.id + '')
      })
      form.setFieldsValue({
        tangIds: tagList
      })
    } else {
      form.setFieldsValue({
        tangIds: []
      })
    }
    setRecord(record)
    setTagDialog(true)
  }

  const editePwdButtonRender: any = (dom: ReactNode, record: any) => {
    return <Tooltip title="修改密码">
      <Button type="link" size="small" onClick={() => {
        setRecord(record)
        setEditePwdDialogOpen(true)
      }}>修改密码</Button>
    </Tooltip>
  };


  const activateButtonRender: any = (dom: ReactNode, record: any) => {
    return <Tooltip title="标签">
      <Button type="link" size="small" onClick={() => openTag(record)}>标签</Button>
    </Tooltip>
  };

  const updateAuthority: any = (dom: ReactNode, record: any) => {
    return <Tooltip title="更新下单权限">
      <Button type="link" size="small" onClick={() => {
        updateAuthorityFunc(record.id).then(e => {
          setRefreshFlag(new Date().getTime());
          message.success('操作成功');
        }).catch((error: any) => {
          message.error(error?.response?.data?.detail);
        })
      }}>更新</Button>
    </Tooltip>
  };


  return (
    <>
      <ListViewPage<Customer, 'credentialsType'>
        columns={columns}
        key={key}
        refreshFlag={refreshFlag}
        quickFilterPanel={quickFilterPanel}
        hideCreateButton
        hideDeleteButton
        rowActions={[editePwdButtonRender, activateButtonRender, updateAuthority]}
        editPage={(params) => <CustomerEdit id={params.id} text={params.text} />}
        detailPage={<CustomerDetails navigate={props.navigate} columns={columns} />}
        showSearchButton
        detailsIndex={0}
        {...props}
      >
        {props.children}
      </ListViewPage>

      <ModalForm
        title="标签"
        width={600}
        layout='horizontal'
        form={form}
        {...formItemLayout}
        visible={tagDialog}
        onVisibleChange={(e) => {
          if (!e) {
            setTagDialog(false)
          }
        }}
        preserve={false}
        onFinish={async (data: any) => {
          const obj = {
            id: record.id,
            data: data.tangIds
          };
          updateTags(obj).then((e: any) => {
            setKey(++key)
            setTagDialog(false)
            return true;
          }).catch((e) => {
            message.error(e.response.data.message);
            return false
          })
        }}
      >

        <div style={{ marginLeft: '5px' }}>
          <SelectSearch name="tangIds" width='lg' required={false} defaultParams="" mode="multiple" label='标签' fetchFunc={fetchCustomerGroupList} labelValue="name" searchParams='name.contains' />
        </div>
      </ModalForm>

      {/* 修改账号密码 */}
      <EditePwdDialog visible={editePwdDialogOpen} record={record} onCancel={() => setEditePwdDialogOpen(false)} onRecharge={(data: any) => {
        editePwd(data).then((e: any) => {
          message.success('修改成功')
          setKey(++key)
          setEditePwdDialogOpen(false);
        }).catch((e: any) => {
          message.error(e.response.data.error || '系统异常')
        })

      }} />
    </>
  );
};

const mapStateToProps = ({ customer }: RootState) => ({
  entity: customer.entity,
  entities: customer.entities,
  loading: customer.loading,
  updateSuccess: customer.updateSuccess,
  total: customer.total,
  createSuccess: customer.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  updateCustomer,
  unBind,
  editePwd
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(CustomerList)