import axios from 'axios';
import { Express, defaultExpress } from 'model/system/express';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'express';
const apiUrl = '/api/yumei-mall-logistics/express';
const standardReducerImpl = new StandardReducerImpl<Express>(entity, defaultExpress, apiUrl);
export type ExpressState = StandardState<Express>;
export const express = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 查询快递公司列表
export const fetchExpressNameList = async (params?: any): Promise<any[]> => {
  const axiosResponse = await axios.get<any[]>(`/api/yumei-mall-logistics/refund_express/list`, { params: { ...params } });
  let data = axiosResponse.data
  return data || [];
}

// 上传物流
export const uploadLogistics = async (data: any, id: any): Promise<any[]> => {
  const axiosResponse = await axios.post<any[]>(`/api/yumei-mall-purchase/exchange-order/${id}/logistics `, data);
  let obj = axiosResponse.data
  return obj || {};
}