import { FC, ReactNode, useState, } from "react";
import { Form, Input, Image, message, Popconfirm } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import { deleteEntity, getEntities, getEntity, showHideMeeting } from "./materialDown.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import TrainListEdit from "./materialDown-edit";
import { TrainList } from "model/train/trainList.model";
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import moment from "moment";

export interface MaterialDownProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const MaterialDown: FC<MaterialDownProps> = (props) => {
  const [refreshFlag,setRefreshFlag] = useState(0);

  const columns: ProColumns<TrainList, 'trainType'>[] = [{
    title: '序号',
    dataIndex: 'id',
    width: 60,
    hideInDescriptions: true,
  }, {
    title: '物料名称',
    dataIndex: 'title',
    width: 100,
  }, {
    title: '物料图',
    dataIndex: 'pictureUrl',
    width: 100,
    hideInDescriptions: true,
    render: (dom, record:any) => {
      return <Image style={{ width: '80px' }} src={record.pictureUrl} />
    }
  }, {
    title: '状态',
    dataIndex: 'status',
    width: 100,
    render: (dom, record:any) => {
      return record.status === 'Online' ? '上架' : '下架'
    }
  }, {
    title: '发布人',
    dataIndex: 'createdBy',
    width: 100,
  }, {
    title: '发布时间',
    dataIndex: 'date',
    width: 150,
    render: (dom, record:any) => moment(record.date).format('YYYY-MM-DD HH:mm:ss'),
  }];
  const searchFields = [{
    label: '物料名称',
    name: 'title.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
      <SearchBox
          fields={searchFields}
        />
        <ProFormSelect
          width={140}
          name="status.equals"
          options={[{
            label: '已上架',
            value: 'Online'
          },{
            label: '已下架',
            value: 'Offline'
          }]}
          placeholder="物料状态"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="date.greaterThanOrEqual"
          placeholder="发布开始时间"
        />
        <ProFormDateTimePicker
          name="date.lessThanOrEqual"
          placeholder="发布结束时间"
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions: any = [(dom: any, record: any) => {
    return <Popconfirm
      title={`你确定要解绑“${ record.status === 'Online'  ? '下架' : '上架' }”吗？`}
      onConfirm={async () => {
        showHideMeeting(record.id, record.status === 'Online'  ?  false : true).then((e: any) => {
          message.success('操作成功');
        }).catch((error: any) => {
          message.error(error?.response?.data?.title);
        });
        setRefreshFlag(new Date().getTime())
        return true;
      }}
    >
      <div className="onoff" style={{'cursor': 'pointer'}}>{record.status === 'Online'  ? '下架' : '上架'}</div>
    </Popconfirm>
  }]

  return (
    <ListViewPage<TrainList, 'trainType'>
      columns={columns}
      actionColumnWidth={100}
      quickFilterPanel={quickFilterPanel}
      detailsIndex={0}
      sort={''}
      refreshFlag={refreshFlag}
      rowActions={rowActions}
      editPage={(params: any) => <TrainListEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ materialDown }: RootState) => ({
  entity: materialDown.entity,
  entities: materialDown.entities,
  loading: materialDown.loading,
  updateSuccess: materialDown.updateSuccess,
  createSuccess: materialDown.createSuccess,
  total: materialDown.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaterialDown);