import axios from 'axios';
import { Refund, defaultRefund } from 'model/procure/refund.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'Refund';
const apiUrl = '/api/yumei-mall-purchase/refund-application';
const standardReducerImpl = new StandardReducerImpl<Refund>(entity, defaultRefund, apiUrl);
export type RefundState = StandardState<Refund>;
export const refund = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const refundOrder = async (data?: any) => {
  const axiosResponse = await axios.get(`/api/yumei-mall-purchase/refund-application/refund/${data.id}`, data);
  return axiosResponse.data;
}

export const manualRefund = async (data?: any) => {
  const axiosResponse = await axios.post(`/api/yumei-mall-purchase/refund-application/hand-refund/${data.id}`, data);
  return axiosResponse.data;
}

