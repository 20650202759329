import { UnorderedListOutlined } from "@ant-design/icons";
import { ModalForm } from "@ant-design/pro-form";
import ProTable from "@ant-design/pro-table";
import { Button } from "antd";
import { AuditColumns } from "./audit-list";
import { fetchAuditList } from "./audit.reducer";
import { getDate } from "components/tool";

interface AuditModalFormProps {
  rowId: string;
  className: string;
  buttonType?: "text" | "link" | "ghost" | "default" | "primary" | "dashed";
};

const AuditModalForm = (props: AuditModalFormProps) => {

  const { className, rowId, buttonType } = props;

  return <ModalForm<{ postage: number }>
    title='操作记录'
    trigger={<Button type={buttonType || 'default'} icon={<UnorderedListOutlined />}>操作记录</Button>}
    width={1200}
    onFinish={async (res) => {
      return true
    }}
  >
    <ProTable<any>
      search={false}
      toolBarRender={false}
      columns={AuditColumns}
      scroll={{ x: 1200 }}
      className='product-skuItem'
      params={{ id: rowId }}
      request={async (params) => {
        const result = await fetchAuditList({
          "from": getDate(-90),
          "to": getDate(0),
          className,
          referenceId: params.id,
          limit: 500
        });
        return {
          success: true,
          data: result || [],
        };
      }}
      pagination={false}
    />
  </ModalForm>
}

export default AuditModalForm;