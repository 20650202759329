import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { Assort, defaultAssort } from 'model/dealer/assort.model';
import axios from 'axios';

const entity = 'Assort';
const apiUrl = '/api/yumei-mall-post/article-category';
const standardReducerImpl = new StandardReducerImpl<Assort>(entity, defaultAssort, apiUrl);
export type AssortState = StandardState<Assort>;
export const assort = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 获取所有分类
export const fetchArticleCategorySelectList = async (params: any) => {
    let axiosResponse = await axios.get(apiUrl,{params});
    return axiosResponse.data || [];
}
