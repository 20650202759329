import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './store.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import { Seller } from "model/store/seller.model";
import ProDescriptions from "@ant-design/pro-descriptions";
export interface StoreDetailsProps extends DetailsStateAndDispatchProps<Seller, 'reviewerStatus'>,
  RouteComponentProps, StateProps, DispatchProps {
}

const StoreDetails: FC<StoreDetailsProps> = (props) => {

  return (
    <DetailsPage<Seller, 'reviewerStatus'>
      title="店铺详情"
      hideEdit={true}
      className="store-details"
      {...props}
    >
      <ProDescriptions<Seller, 'reviewerStatus'>
        column={2}
        columns={props.columns as any}
        dataSource={props.entity}
      ></ProDescriptions>
      
    </DetailsPage>);
};

const mapStateToProps = ({ store }: RootState) => ({
  entity: store.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StoreDetails);