import { FC, ReactNode } from "react";
import { Button, Form, Input, Popconfirm, Tooltip } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import DeliveryDetails from "./delivery-details";
import { activate, deleteEntity, getEntities, getEntity } from "./delivery.reducer";
import { RouteComponentProps } from "@reach/router";
import { Delivery, PriceModel } from "model/product/delivery.model";
import { ProFormSelect } from "@ant-design/pro-form";
import DeliveryEdit from "./delivery-edit";
import ListViewPage from "layouts/page-wraper/list-view-page";
import Authorized from "common/auth/authorized";


export interface DeliveryListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const columns: ProColumns<Delivery>[] = [{
  title: 'id',
  dataIndex: 'id',
  valueType: 'text',
}, {
  title: '商家名称',
  dataIndex: 'storeName',
  valueType: 'text',
}, {
  title: '模板名称',
  dataIndex: 'name',
  valueType: 'text',
}, {
  title: '定价模式',
  dataIndex: 'priceModel',
  valueEnum: PriceModel,
}, {
  title: '状态',
  dataIndex: 'status',
  render: (dom, record) => dom ? '启用' : '禁用'
}];

const DeliveryList: FC<DeliveryListProps> = (props) => {
  const { activate } = props;

  const searchFields: SearchField[] = [{
    label: '模板名称',
    name: 'name.contains',
  }, {
    label: '模板id',
    name: 'id.equals',
    dataType: 'number'
  }];

  const rowActions = [
    (dom: ReactNode, record: Delivery) => {
      return  <Authorized authority='disableEnable'><Popconfirm
        key="audit"
        placement="topRight"
        title={record.status ? '你确定要禁用吗？' : '商家仅可一个运费模版为启用状态，启用当前将禁用其他运费模版，确定启用吗？'}
        onConfirm={() => {
          record.status ? activate({ id: record.id, type: 'deactivate' }) : activate({ id: record.id, type: 'activate' });
        }}
      >
        <Tooltip title={record.status ? '立即禁用' : '立即启用'} key={record.id}>
          <Button type="link" size="small">{record.status ? '禁用' : '启用'}</Button>
        </Tooltip>
      </Popconfirm></Authorized>
    }
  ]

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          width="sm"
          name="status.equals"
          placeholder={'模版状态'}
          options={[{ value: 'true', label: '启用' }, { value: 'false', label: '禁用' }]}
        />
        <ProFormSelect
          width="sm"
          name="priceModel.equals"
          placeholder={'定价模式'}
          options={Object.entries<string>(PriceModel).map(([key, value]) => ({ value: Number.parseInt(key), label: value }))}
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Delivery>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      rowActions={rowActions} 
      detailPage={<DeliveryDetails navigate={props.navigate} columns={columns} />}
      editPage={(params: any) => <DeliveryEdit id={params.id} text={params.text} />}
      detailsIndex={1}
      canDelete={(row) => !row.status}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ delivery }: RootState) => ({
  entity: delivery.entity,
  entities: delivery.entities,
  loading: delivery.loading,
  updateSuccess: delivery.updateSuccess,
  total: delivery.total,
  createSuccess: delivery.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  activate
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryList);