import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDependency, ProFormRadio, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./topic.reducer";
import { Post } from "model/video/post.model";
import { Col, Form, message, Row } from 'antd';
import UploadImgComplex from "components/upload-img/upload-img-complex";
import { uploadImage } from "utils/file-utils";
import { fetchLevelList } from "pages/dealer/unit/unit.reducer";
import { internationalList } from "components/IntelFormText";


export interface TopicEditProps extends EditStateAndDispatchProps<any>, StateProps, DispatchProps, RouteComponentProps {
}
const PostEdit: FC<TopicEditProps> = (props) => {
  const { entity } = props;
  const [picturesArr, setPicturesArr] = useState([]);
  const [coverArr, setCoverArr] = useState([]);
  const [entityData, setEntityData] = useState<any>();
  const [form] = Form.useForm();

  useEffect(() => {
    // 初始化数据
    setEntityData({
      publicityType: 'coverImg',
      videoUploadMethod: 'upload'
    })
    if (entity.id) {
      const entityCopy = JSON.parse(JSON.stringify(entity));
      entityCopy.publicityType = entityCopy.coverImg ? 'coverImg' : 'Video'
      entityCopy.switchOn = entityCopy.switchOn === 0 ? false : true
      entityCopy.levelId = entityCopy.levelId.split(',').map(Number)

      var picArray = entity.introductionPictureUrl.split(',').map((item: any) => {
        return {
          pictureUrl: item,
          id: Math.random()
        }
      })
      setPicturesArr(picArray);


      var coverArray = entity.coverImg.split(',').map((item: any) => {
        return {
          pictureUrl: item,
          id: Math.random()
        }
      })
      setCoverArr(coverArray);

      setEntityData(entityCopy);
    }
  }, [entity]);



  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        res.levelId = res.levelId instanceof Array ? res.levelId.join(',') : res.levelId
        res.lessonId = res.lessonId instanceof Array ? res.lessonId.join(',') : res.lessonId
        res.switchOn = res.switchOn ? 1 : 0


         // 分享图片
         const p4 = new Promise((resolve, reject) => {
          const coverData: any = res.sharePictureUrl?.concat();
          if (coverData && coverData.length > 0) {
            let sharePictureData: any = '', successList: any = [];
            // 视频已更新或需上传 递归解决上传
            const uploadCoverUrl = () => {
              const newDate = new Date().getTime();
              if (coverData[successList.length].originFileObj) {
                console.log('缉拿啊11')
                console.log(coverData[successList.length].originFileObj)
                uploadImage(coverData[successList.length].originFileObj, 'material/img-' + newDate + '.jpg').then((imageName: any) => {
                  successList.push(1);
                  sharePictureData = imageName
                  if (successList.length === coverData.length) {
                    res.sharePictureUrl = sharePictureData;
                    resolve(true)
                  } else {
                    uploadCoverUrl();
                  }
                }).catch(() => { message.error('分享图片上传失败'); reject() });
              } else {
                res.sharePictureUrl = coverData[0].url;
                resolve(true)
              }
            }; uploadCoverUrl();
          } else {
            // 无封面
            res.sharePictureUrl = '';
            resolve(true)
          }
        })



        // 专题封面
        const p3 = new Promise((resolve, reject) => {
          const coverData: any = res.coverImg?.concat();
          if (coverData && coverData.length > 0) {
            let picturesText: any = [], successList: any = [];
            // 配图已更新或需上传 递归解决上传
            const uploadPictures = () => {
              const newDate = new Date().getTime();
              if (coverData[successList.length].originFileObj) {
                uploadImage(coverData[successList.length].originFileObj, 'material/img-' + newDate).then(imageName => {
                  successList.push(1);
                  picturesText.push({ pictureUrl: imageName, type: 'picture' });
                  if (successList.length === coverData.length) {
                    res.coverImg = picturesText;
                    resolve(true)
                  } else {
                    uploadPictures();
                  }
                }).catch(() => { message.error('图片上传失败'); reject() });
              } else {
                successList.push(1);
                const id = (coverArr.filter((e: any) => e.pictureUrl === coverData[successList.length - 1].url)[0] as any).id;
                picturesText.push({ pictureUrl: coverData[successList.length - 1].url, type: 'picture', id });
                if (successList.length === coverData.length) {
                  res.coverImg = picturesText;
                  resolve(true)
                } else {
                  uploadPictures();
                }
              }
            }; uploadPictures();
          } else {
            res.coverImg = [];
            resolve(true)
          }
        })


        //封面视频上传
        const p1 = new Promise((resolve, reject) => {
          const videosData: any = res.introductionVideoUrl?.concat();
          if (videosData && videosData.length > 0 && res.publicityType === "Video" && res.videoUploadMethod === 'upload') {
            let videosText: any = [], successList: any = [];
            // 视频已更新或需上传 递归解决上传
            const uploadVideos = () => {
              const newDate = new Date().getTime();
              if (videosData[successList.length].originFileObj) {
                uploadImage(videosData[successList.length].originFileObj, 'material/video-' + newDate + '.mp4').then((imageName: any) => {
                  successList.push(1);
                  videosText = imageName
                  if (successList.length === videosData.length) {
                    res.introductionVideoUrl = videosText;
                    resolve(true)
                  } else {
                    uploadVideos();
                  }
                }).catch(() => { message.error('视频上传失败,请压缩后再上传'); reject() });
              } else {
                res.introductionVideoUrl = videosText;
                resolve(true)
              }
            }; uploadVideos();
          } else if (res.publicityType === "Video" && res.videoUploadMethod === 'videolink' && res.videolink) {
            res.introductionVideoUrl = res.videolink
            resolve(true)
          } else {
            // 无视频
            res.introductionVideoUrl = '';
            resolve(true)
          }
        })


        // 详细介绍图片
        const p2 = new Promise((resolve, reject) => {
          const picturesData: any = res.introductionPictureUrl?.concat();
          if (picturesData && picturesData.length > 0) {
            let picturesText: any = [], successList: any = [];
            // 配图已更新或需上传 递归解决上传
            const uploadPictures = () => {
              const newDate = new Date().getTime();
              if (picturesData[successList.length].originFileObj) {
                uploadImage(picturesData[successList.length].originFileObj, 'material/img-' + newDate).then(imageName => {
                  successList.push(1);
                  picturesText.push({ pictureUrl: imageName, type: 'picture' });
                  if (successList.length === picturesData.length) {
                    res.introductionPictureUrl = picturesText;
                    resolve(true)
                  } else {
                    uploadPictures();
                  }
                }).catch(() => { message.error('图片上传失败'); reject() });
              } else {
                successList.push(1);
                const id = (picturesArr.filter((e: any) => e.pictureUrl === picturesData[successList.length - 1].url)[0] as any).id;
                picturesText.push({ pictureUrl: picturesData[successList.length - 1].url, type: 'picture', id });
                if (successList.length === picturesData.length) {
                  res.introductionPictureUrl = picturesText;
                  resolve(true)
                } else {
                  uploadPictures();
                }
              }
            }; uploadPictures();
          } else {
            res.introductionPictureUrl = [];
            resolve(true)
          }
        })

        Promise.all([p1, p2, p3, p4])
          .then(result => {
            let data = { ...res }
            let introductionPictureUrl = data.introductionPictureUrl.map((item: any) => {
              return item.pictureUrl || item.url
            })
            data.introductionPictureUrl = introductionPictureUrl.join(',')
            
            let coverPictureUrl = data.coverImg.map((item: any) => {
              return item.pictureUrl || item.url
            })
            data.coverImg = coverPictureUrl.join(',')

            if (data.publicityType === 'coverImg') {
              data.introductionVideoUrl = ''
            }
            resolve(data)
          })
          .catch(e => {
            console.log(e)
            reject()
          })
      };
    })
  };

  return (
    <EditPage<Post>
      {...props}
      entity={{
        ...entityData,
      }}
      title={'专题'}
      width={700}
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row justify="start">
        <Col span={11}>
          <ProFormText
            rules={[{ required: true }]}
            name="lessonName"
            label="专题名称"
          />
        </Col>
        <Col span={1} />
        <Col span={12}>
          <ProFormText
            rules={[{ required: true }]}
            name="introductionTxt"
            label="专题描述"
          />
        </Col>
      </Row>

      <Row>
        <ProFormRadio.Group
          label="内容类型"
          name={'publicityType'}
          width='md'
          rules={[{ required: true }]}
          options={[
            {
              label: '图片',
              value: 'coverImg',
            }, {
              label: '视频',
              value: 'Video',
            }
          ]}
        />
      </Row>
      <Row>
        <ProFormDependency name={['publicityType']}>
          {({ publicityType }) => {
            return publicityType === 'coverImg' ?
              <>
                <Col span={24} className="scmb0">
                  <Form.Item name="coverImg" label="图片封面" validateTrigger='onBlur' rules={[{ required: publicityType === 'coverImg' ? true : false }]}>
                    <UploadImgComplex
                      listType="picture-card"
                      extra="最多上传9张,支持扩展名: jpg、jpeg、png"
                      title="上传"
                      maxCount={9}
                    />
                  </Form.Item>
                </Col>
              </>
              : <>
                <Row style={{ width: '100%' }}>
                <Col span={24} className="scmb0">
                  <Form.Item name="coverImg" label="视频封面" validateTrigger='onBlur' rules={[{ required: publicityType === 'Video' ? true : false }]}>
                    <UploadImgComplex
                      listType="picture-card"
                      extra="最多上传1张,支持扩展名: jpg、jpeg、png"
                      title="上传"
                      maxCount={1}
                    />
                  </Form.Item>
                </Col>
                  <ProFormRadio.Group
                    label="视频上传方式"
                    name={'videoUploadMethod'}
                    width='md'
                    rules={[{ required: true }]}
                    options={[
                      {
                        label: '上传',
                        value: 'upload',
                      }, {
                        label: '封面视频链接',
                        value: 'videolink',
                      }
                    ]}
                  />
                </Row>
                <Row>
                  <Col span={24} className="scmb0">
                    <ProFormDependency name={['videoUploadMethod']}>
                      {({ videoUploadMethod }) => {
                        return videoUploadMethod === 'upload' ?
                          <Row>
                            <Col span={24} className="scmb0">
                              <Form.Item name="introductionVideoUrl" label="封面视频" validateTrigger='onBlur' rules={[{ required: publicityType === 'introductionVideoUrl' ? true : false }]} >
                                <UploadImgComplex
                                  extra="最多上传1个视频，视频限制最大200MB"
                                  listType="text"
                                  title="上传"
                                  fileType='video'
                                  maxCount={1}
                                />
                              </Form.Item>
                            </Col> </Row> : <ProFormText
                            width={'lg'}
                            label="封面视频链接"
                            name="introductionVideoUrl"
                            placeholder="视频链接"
                          />
                      }}</ProFormDependency>
                  </Col>
                </Row>
              </>

          }}
        </ProFormDependency>

      </Row>

      <Row>
        <Col span={24}>
          <Form.Item name="introductionPictureUrl" label="专题详情" validateTrigger='onBlur'>
            <UploadImgComplex
              extra="最多上传9张"
              listType="picture-card"
              title="上传"
              maxCount={9}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row className="mb20" style={{ marginBottom: '10px' }}>
        <Form.Item name="sharePictureUrl" label="分享图片"  rules={[{ required: true }]}>
          <UploadImgComplex
            listType="picture-card"
            extra="最多上传1张,支持扩展名: jpg、jpeg、png"
            title="上传"
            maxCount={1}
          />
        </Form.Item>
      </Row>
      <Row className="mb20" style={{ marginBottom: '10px' }}>
        <Col span={12}>
          <ProFormSelect
            mode="multiple"
            rules={[{ required: true }]}
            width="md"
            request={async () => {
              const unitRequest: any = await fetchLevelList();
              form.setFieldsValue({
                'levelId': unitRequest.map((item: any) => item.id )
              })
              return unitRequest.map((item: any) => ({
                value: item.id,
                label: internationalList(item.levelName)
              }))
            }}
            name="levelId"
            label="可使用等级"
            placeholder="可使用等级"
          />
        </Col>
        <Col span={1} />
        <Col span={11}>
          <ProFormSwitch
            name={'switchOn'}
            label="是否开启"
            initialValue={true}
            fieldProps={{
              checkedChildren: '开启',
              unCheckedChildren: '禁用'
            }}
          />
        </Col>
      </Row>
    </EditPage >);
};

const mapStateToProps = ({ examtopic }: RootState) => ({
  entity: examtopic.entity,
  updateSuccess: examtopic.updateSuccess,
  updating: examtopic.updating,
  createSuccess: examtopic.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostEdit);
