import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import GroupList from './group-list';
import GroupDetails from "./group-details";
import GroupEdit from "./group-edit";

const Group: React.FC<RouteComponentProps> = () => (
  <Router>
    <GroupList path="/" />
    <GroupDetails path=":id" />
    <GroupEdit path=":id/edit" />
    <GroupEdit path="new" />
  </Router>
);
export default Group;
