import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, message, Tooltip } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import LifePavilionDetails from "./trainingList-details";
import { auditRefuse, deleteEntity, getEntities, getEntity, statusMap } from "./trainingList.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ToExamineDialog from '../toExamineDialog'
import { statusList } from "../excellentStore/excellentStore.reducer";
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { fetchStoreList } from "pages/store/store/store.reducer";
import { TrainingList } from "model/dealerStore/trainingList.model";

export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const columns: ProColumns<TrainingList>[] = [{
  title: 'ID',
  dataIndex: 'id',
  width: 50
}, {
  title: '姓名',
  dataIndex: 'userName',
  width: 100,
  ellipsis: true
},
{
  title: '电话号码',
  dataIndex: 'phone',
  width: 100
},
{
  title: '地址',
  dataIndex: 'address',
  ellipsis: true,
  width: 160,
  render: (text, record: any) => {
    return record.address?.provinceName + record.address?.cityName + record.address?.districtName + record.address?.address
  }

},
{
  title: '培训目的',
  dataIndex: 'purpose',
  width: 100,
  ellipsis: true
},
{
  title: '备注',
  dataIndex: 'remark',
  width: 100,
  ellipsis: true
},
{
  title: '培训时间',
  dataIndex: 'times',
  ellipsis: true,
  width: 500,
  render: ((res, record) => {
    return record.times?.length > 0 && record.times.map((item: any, index: any) => {
      return <div className="rowWidth"> <div className="row-item">{(index + 1)}、开始时间: {item.startTime}  结束时间: {item.endTime} <br /> </div></div>
    })
  })
},
{
  title: '申请时间',
  dataIndex: 'applyTime',
  width: 100,
  ellipsis: true
},
{
  title: '处理状态',
  dataIndex: 'status',
  width: 100,
  render: ((res: any) => {
    return statusMap[res]
  })
},
{
  title: '处理人',
  dataIndex: 'auditUserName',
  ellipsis: true,
  width: 100,
},
{
  title: '处理时间',
  dataIndex: 'auditTime',
  width: 130,
  ellipsis: true
}, {
  title: '处理备注',
  dataIndex: 'auditComments',
  width: 120,
  ellipsis: true
}
];


const LevelList: FC<LevelListProps> = (props) => {
  
  let [key, setKey] = useState<any>(0);

  const searchFields: SearchField[] = [{
    label: '用户名',
    name: 'userName',
    operator: 'equals',
  }, {
    label: '手机号',
    name: 'phone',
    operator: 'equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          request={async () => {
            const data: any = await fetchStoreList();
            return data.map((item: any) => ({
              value: item.id,
              label: item.name
            }))
          }}
          name={['storeId.equals']}
          placeholder="店铺"
        />
        <div className="wd10" />
        <ProFormSelect
          name="status.equals"
          options={statusList}
          placeholder="状态"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="applyTime.greaterThanOrEqual"
          placeholder="申请时间开始时间"
        />
        <ProFormDateTimePicker
          name="applyTime.lessThanOrEqual"
          placeholder="申请时间结束时间"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="auditTime.greaterThanOrEqual"
          placeholder="审核开始时间"
        />
        <ProFormDateTimePicker
          name="auditTime.lessThanOrEqual"
          placeholder="审核结束时间"
        />
        <div className="wd10"></div>
        <SearchBox fields={searchFields} />
      </Input.Group>
    </Form.Item>
  );

  const [open, setOpen] = useState(false);
  const [record, setRecord]: any = useState({});

  //打开审核弹框
  const showModal = async (record: any) => {
    setRecord({ ...record })
    setOpen(true);
  };

  //审核
  const toExamineButtonRender: any = (dom: ReactNode, record: any): ReactNode => {
    return (
      record.status === 'Created' || record.status === 'Checked' ? <Tooltip title="审核">
        <Button size="small" type='link' onClick={() => showModal(record)} >审核</Button>
      </Tooltip> : ''
    )
  };

  //关闭审核弹框
  const hideModal = () => {
    setOpen(false);
    setRecord({})
  };


  return (<>
    <ListViewPage<TrainingList>
      scrollConfig={{ x: 1200 }}
      columns={columns}
      hideDeleteButton
      permanentParams={{
        'applyType.equals': 'LifePavilion'
      }}
      key={key}
      rowActions={[toExamineButtonRender]}
      hideCreateButton
      hideEditButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<LifePavilionDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      {...props}
    >
      {props.children}
    </ListViewPage>


    {/* 审核 */}
    <ToExamineDialog visible={open} record={record} entity={record} columns={columns} onCancel={() => {
      hideModal();
    }} onRecharge={(data: any) => {
      auditRefuse(data).then((e: any) => {
        message.success('操作成功');
        setKey(++key)
        hideModal();
        return true;
      }).catch((e: any) => {
        message.error(e.response.data.message);
        return false
      })
    }} />
  </>
  );
};

const mapStateToProps = ({ trainingList }: RootState) => ({
  entity: trainingList.entity,
  entities: trainingList.entities,
  loading: trainingList.loading,
  updateSuccess: trainingList.updateSuccess,
  createSuccess: trainingList.createSuccess,
  total: trainingList.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelList);