import { FC, ReactNode } from "react";
import { Button, Form, Input, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ContractSignDetails from "./sign-details";
import { beSign, deleteEntity, getEntities, getEntity } from "./sign.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ContractSignEdit from "./sign-edit";
import { ContractSign, signListStatus, signStatus } from "model/contract/sign.model";
import { ProFormSelect } from "@ant-design/pro-form";
import DecryptText from "pages/dealer/customer/detailtables/decryptText";

export interface ContractSignListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const ContractSignList: FC<ContractSignListProps> = (props) => {

  const columns: ProColumns<ContractSign>[] = [{
    title: 'id',
    dataIndex: 'id',
    width: 100,
  },{
    title: '合同名称',
    dataIndex: 'contractName',
    width: 100,
  },{
    title: '合同模板ID',
    dataIndex: 'docTemplateId',
    width: 100,
  },{
    title: '平台所属店铺ID',
    dataIndex: 'storeId',
    width: 100,
  },{
    title: '平台所属店铺名称',
    dataIndex: 'storeName',
    width: 100,
  },{
    title: '姓名',
    dataIndex: 'name',
    width: 100,
  },{
    title: '手机号',
    dataIndex: 'phone',
    width: 100,
    render: (dom:any, record:any) => {
      return  <div> ******
      <DecryptText title='手机号' id={record.id} data={record?.phone} /></div>
    }
  },{
    title: '授权码',
    dataIndex: 'account',
    width: 100,
  },{
    title: '合同状态',
    dataIndex: 'signStatus',
    valueEnum:signStatus,
    width: 100,
  },{
    title: '发起时间',
    dataIndex: 'startTime',
    width: 100,
  },{
    title: '到期时间',
    dataIndex: 'endTime',
    width: 100,
  },{
    title: '发起人id',
    dataIndex: 'initiatorId',
    width: 100,
  },{
    title: '甲方',
    dataIndex: 'initiatorName',
    width: 100,
  },{
    title: '经销商等级ID',
    dataIndex: 'levelId',
    width: 100,
  },{
    title: '经销商等级编号',
    dataIndex: 'levelNo',
    width: 100,
  },{
    title: '填充后生成的文件ID',
    dataIndex: 'fileId',
    width: 100,
  },{
    title: '签署流程ID',
    dataIndex: 'signFlowId',
    width: 100,
  },{
    title: '状态',
    dataIndex: 'status',
    valueEnum: signListStatus,
    width: 100,
  },{
    title: '用户id',
    dataIndex: 'userId',
    width: 100,
  },]
  const searchFields = [{
    label: '姓名',
    name: 'name.equals',
    width: 100,
  },{
    label: '手机号',
    name: 'phone.equals',
    width: 100
  },{
    label: '授权码',
    name: 'account.equals',
    width: 100,
  },{
    label: '合同名称',
    name: 'contractName.equals',
    width: 100,
  },{
    label: '平台所属店铺名称',
    name: 'storeName.equals',
    width: 100,
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
        <ProFormSelect
          options={Object.entries<string>(signStatus).map(([value, label]) => ({ value: value, label }))}
          name={'signStatus.equals'}
          placeholder="合同状态"
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: any, record: any) => {
    return <Button onClick={() => {
      beSign({
        signFlowId:record.signFlowId,
      }).then((e:any) => {
        // message.success('即将跳转');
        window.open(e, '_blank');
      }).catch((error:any) => {
        message.error(error?.response?.data?.detail);
      })
    }} type="link" size="small" >下载签署文件</Button>
  }]

  
  return (
    <ListViewPage<ContractSign>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ContractSignDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      hideDeleteButton
      hideEditButton
      hideCreateButton
      sort={''}
      rowActions={rowActions}
      scroll={{x:1500}}
      editPage={(params: any) => <ContractSignEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ contractSign }: RootState) => ({
  entity: contractSign.entity,
  entities: contractSign.entities,
  loading: contractSign.loading,
  updateSuccess: contractSign.updateSuccess,
  createSuccess: contractSign.createSuccess,
  total: contractSign.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractSignList);