import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./tag.reducer";
import { Tag } from "model/customerCore/tag.model";

export interface TagEditProps extends EditStateAndDispatchProps<Tag>, StateProps, DispatchProps, RouteComponentProps {
}

const TagEdit: FC<TagEditProps> = (props) => {

  

  return (
    <EditPage<Tag>
      title="客户标签"
      {...props}
    >
      
    </EditPage>);
};

const mapStateToProps = ({ tag }: RootState) => ({
  entity: tag.entity,
  updateSuccess: tag.updateSuccess,
  updating: tag.updating,
  createSuccess: tag.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TagEdit);
