import React, { FC, useImperativeHandle, useState, memo, useEffect, useRef, useContext } from 'react';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Modal, Image, Tag } from 'antd';
import { updatePageConfig, updateInsertPositionId, updateMoreState, deleteModule, insertModule } from '../platform/platform-edit.reducer'
import type { ProColumns } from '@ant-design/pro-table';
import ProTable, { } from '@ant-design/pro-table';
import { getProductList, getTellingProductList } from '../api';
import './common.less';
import { getGlobalKeyValue } from 'utils/utils';
import { SkytoneCardContext } from './modules-setting';


interface LinkSelectionProps extends StateProps, DispatchProps {
  show: boolean
  setShowProductSelectionPrivate: (show: any, returnData?: any) => void
  defaultTab?: string
  language?: string
}

const ProductList: FC<{ onRef?: any, setLinkInfo: (data: any) => void, language?: string, show: boolean, SkytoneCard?: boolean }> = memo(({ setLinkInfo, onRef, language, show, SkytoneCard }) => {
  const ref: any = useRef();
  useEffect(() => {
    //页面每次展示 数据刷新
    ref?.current?.reload();
  }, [show]);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const clear = () => {
    setSelectedRowKeys([])
  }
  useImperativeHandle(onRef, () => {
    return {
      clear
    }
  })
  type TableListItem = {
    key: number;
    name: string;
    status: string;
    updatedAt: number;
    createdAt: number;
    money: number;
  };

  const columns: ProColumns<TableListItem, 'productStatus'>[] = [
    {
      title: '商品主图',
      dataIndex: 'mainPicture',
      key: 'name',
      align: 'center',
      width: '120px',
      render: (e: any) => {
        return <div className="img" style={
          {
            width: '80px',
            height: '80px'
          }
        }>
          <Image src={getGlobalKeyValue(e, language) + '?imageView2/2/w/400'} preview={false}></Image>
        </div>
      },
    },
    {
      title: '商品名称',
      dataIndex: 'name',
      // ellipsis: true,
      align: 'left',
      width: '600px',
      render: (e: any) => {
        return <p>{getGlobalKeyValue(e, language)}</p>
      },
    },
    {
      title: '商品状态',
      dataIndex: 'status',
      valueType: 'productStatus',
      hideInTable: SkytoneCard ? true : false,
      render: (dom, record) => <Tag color={record.status === 'SELLING' ? 'success' : 'error'}>{dom}</Tag>
    },
    {
      title: '商品状态',
      dataIndex: 'onlineStatus',
      hideInTable: !SkytoneCard ? true : false,
      render: (dom, record: any) => <Tag color={record.onlineStatus === 1 ? 'success' : 'error'}>{record.onlineStatus === 1 ? '上线' : '下线'}</Tag>
    },

  ];

  // useEffect(
  //     () => {
  //         console.log('selectedRecord')
  //         if(selectedRecord.length > 0 && selectionType === 'radio'){
  //             setLinkInfo({
  //                 path:'/pages-native/product-detail/product-detail?id='+selectedRecord[0].id,
  //                 openType:'navigateTo',

  //             })
  //         }
  //     },
  //     [selectedRecord]
  // )
  return (
    <ProTable<TableListItem, { keyWord?: string }, 'productStatus'>
      columns={columns}
      actionRef={ref}
      rowSelection={{
        // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
        // 注释该行则默认不显示下拉选项
        // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
        // type:'checkout',
        selectedRowKeys,
        onChange: (selectedRowKeys: any, selectedRows: any) => {
          setLinkInfo(selectedRows)
          setSelectedRowKeys(selectedRowKeys)
        },

      }}
      request={async (params) => {
        let res = !SkytoneCard ? await getProductList({
          ...params,
          'status.in': [25, 30, 40]
        }) : await getTellingProductList({
          ...params,
        });
        if (res.status !== 200) {
          return Promise.resolve({
            data: [],
            success: false,
          });
        } else {
          return Promise.resolve({
            data: res.data,
            success: true,
            total: parseInt(res.headers['x-total-count'], 10),
          });
        }

      }}
      options={{
        search: true,
      }}

      rowKey="id"
      search={false}
      dateFormatter="string"
    //   headerTitle=" "
    />
  );
})

const LinkSelection: FC<LinkSelectionProps> = memo(({ show, defaultTab, setShowProductSelectionPrivate, language }) => {
  const [showContainer, setShowContainer] = useState(show);
  const [linkInfo, setLinkInfo] = useState();
  const { SkytoneCard } = useContext(SkytoneCardContext);//取是否天音卡商城
  let refs: any = {
    product: useRef(),
  };
  //更新显示状态
  useEffect(() => {
    setShowContainer(show)
  }, [show, setShowContainer])

  //更新tab选中
  // useEffect(() => {
  //     setDefaultActiveKey(defaultTab+'')
  // },[defaultTab])

  useEffect(
    () => {
    },
    [linkInfo]
  )
  // 清除选中
  const clearSelect = () => {
    setLinkInfo(undefined)
    for (let k in refs) {
      let ref = refs[k];
      if (ref && ref.current && ref.current.clear) {
        ref.current.clear()
      }
    }
  }

  return <div className="">
    <Modal
      title="选择商品"
      centered
      visible={showContainer}
      onOk={() => {
        setShowProductSelectionPrivate(false, linkInfo)
        clearSelect()

      }}
      onCancel={() => {
        setShowProductSelectionPrivate(false)
        clearSelect()

      }}
      width={1000}
      bodyStyle={{
        height: '60vh',
        overflowY: 'scroll',
        padding: '0px 20px 20px 20px'
      }}
    >
      <div className="section-container">
        <ProductList language={language} SkytoneCard={SkytoneCard || false} show={show || false} onRef={refs.product} setLinkInfo={setLinkInfo} />
      </div>
    </Modal>
  </div>
})
const mapStateToProps = ({ decorateEdit }: RootState) => ({
  pageConfig: decorateEdit.pageConfig,
  pageData: decorateEdit.pageData,
  insertPositionId: decorateEdit.insertPositionId,
  settingModuleId: decorateEdit.settingModuleId,
});
const mapDispatchToProps = {
  updatePageConfig,
  updateMoreState,
  updateInsertPositionId,
  deleteModule,
  insertModule
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(LinkSelection);
