import { FC, ReactNode, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import UserDetails from "./user-details";
import { deleteEntity, getEntities, getEntity, resetPassword, cancellationBackend } from "./user.reducer";
import { RouteComponentProps } from "@reach/router";
import { User } from "model/system/user.model";
import { Button, Form, Input, message, Modal, Popconfirm, Tag, Tooltip } from "antd";
import SearchBox from "components/search-box";
import { RedoOutlined } from "@ant-design/icons";
import Authorized from "common/auth/authorized";
import UserEdit, { loginType } from "./user-edit";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { ProFormSelect } from "@ant-design/pro-form";

export interface UserListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const UserList: FC<UserListProps> = (props) => {
  const { cancellationBackend, getEntities } = props;
  const [isModalVisible, setIsModalVisible] = useState<any>({
    id: '',
    value: false
  });

  const columns: ProColumns<User>[] = [{
    title: '登录账号',
    dataIndex: 'account',
    valueType: 'text',
    width: 150,
  }, {
    title: '用户名',
    dataIndex: 'realName',
    valueType: 'text',
    width: 150,
  }, {
    title: '所属角色',
    dataIndex: 'roleNames',
    valueType: 'text',
    width: 200,
    render: (dom, recode) => <>{
      recode.roleNames &&
      (recode.roleNames.map((value: any) => <Tag style={{ margin: '2px' }} color="#87d068">{value}</Tag>))}
    </>
  }, {
    title: '所属部门',
    dataIndex: 'depts',
    valueType: 'text',
    width: 200,
    render: (dom, recode: User) => <>{
      recode.depts &&
      recode.depts.map(item => <Tag color="#108ee9">{item.deptName}</Tag>)
    }</>
  }, {
    title: '所属应用',
    dataIndex: 'bgList',
    valueType: 'text',
    width: 200,
    render: (dom, recode: User) => <>{
      recode.bgList &&
      recode.bgList.map((item:any) => <Tag color="#108ee9">{item}</Tag>)
    }</>
  },{
    title: '是否删除',
    dataIndex: 'deleted',
    valueType: 'text',
    width: 200,
    render: (dom, recode) => recode.deleted ? '是' : '否'
  },{
    title: '登陆方式',
    dataIndex: 'loginType',
    valueType: 'text',
    width: 200,
    render: (dom, recode) => recode.loginType && recode.loginType.length > 0 && recode.loginType.map(e=> loginType.filter(i=> i.value === e)?.[0]?.label + '、')
  }
  ];

  const searchFields = [{
    label: '登录账号',
    name: 'account',
  }, {
    label: '手机号码',
    name: 'phone',
  }, {
    label: '用户姓名',
    name: 'realName.contains',
  }];

  const rowActions = [(dom: ReactNode, record: any) => {
    return <Authorized authority='resetPassword'>
      <Popconfirm
        key="audit"
        placement="topRight"
        title={'你确定要重置密码吗？'}
        onConfirm={async () => {
          const res = await resetPassword(record.id);
          res && message.success('默认密码已重置为 123456');
        }}
      >
        <Tooltip title={'重置密码'} key="AuditReject">
          <Button type="link" icon={<RedoOutlined />} />
        </Tooltip>
      </Popconfirm>
    </Authorized>
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          placeholder={'是否删除'}
          name='deleted.equals'
          options={[{ label: '是', value: 'true' }, { label: '否', value: 'false' }]}
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<User>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      rowActions={rowActions}
      detailPage={<UserDetails navigate={props.navigate} columns={columns} />}
      editPage={(params: any) => <UserEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
      <Modal title="注销确认" visible={isModalVisible.value}
        onOk={() => {
          cancellationBackend({ id: isModalVisible.id, scope: 'backend' });
          getEntities();
          setIsModalVisible({
            id: '',
            value: false
          });
        }}
        onCancel={() => setIsModalVisible({
          id: '',
          value: false
        })}>
        <p>注销后将不可登录后台，且无法撤销，请谨慎操作！</p>
      </Modal>
    </ListViewPage>
  );
};

const mapStateToProps = ({ user }: RootState) => ({
  entity: user.entity,
  entities: user.entities,
  loading: user.loading,
  updateSuccess: user.updateSuccess,
  createSuccess: user.createSuccess,
  total: user.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  cancellationBackend
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserList);