import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./equity.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Equity } from "model/customerCore/equity.model";
import ProForm, { ProFormDigit, ProFormList, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { Form, message } from "antd";
import UploadImgOne from "components/upload-img/upload-img-one";
import { fetchLevelList } from "../level/level.reducer";
import { uploadImage } from "utils/file-utils";
import SelectSearch from "components/select-search";
export interface EquityEditProps extends EditStateAndDispatchProps<Equity>, StateProps, DispatchProps, RouteComponentProps {
}

const EquityEdit: FC<EquityEditProps> = props => {
  const {
    ...editProps
  } = props;

  const uploadImg = (res?: Equity) => {
    return new Promise<Equity>((resolve, reject) => {
      if (res) {

        const rightGrayLogo = (rightGrayLogoData: any) => {
          const GrayLogo: any = rightGrayLogoData && rightGrayLogoData.rightGrayLogo?.concat();
          if (res.rightGrayLogo && GrayLogo.length > 0) {
            if (GrayLogo[0].originFileObj) {
              //logo需上传
              const newDate = new Date().getTime();
              uploadImage(GrayLogo[0].originFileObj, 'product/img-' + newDate).then(async imageName => {
                res.rightGrayLogo = imageName;
                resolve(res);
              }).catch(() => { message.error('图片上传失败'); reject() });
            } else {
              // logo已上传且未修改
              res.rightGrayLogo = GrayLogo[0].url;
              resolve(res)
            }
          } else {
            //未上传logo
            res.rightGrayLogo = '';
            resolve(res)
          }
        };

        const logo: any = res && res.rightLogo?.concat();
        if (res.rightLogo && logo.length > 0) {
          if (logo[0].originFileObj) {
            //logo需上传
            const newDate = new Date().getTime();
            uploadImage(logo[0].originFileObj, 'product/img-' + newDate).then(async imageName => {
              res.rightLogo = imageName;
              rightGrayLogo(res);
            }).catch(() => { message.error('图片上传失败'); reject() });
          } else {
            // logo已上传且未修改
            res.rightLogo = logo[0].url;
            rightGrayLogo(res)
          }
        } else {
          //未上传logo
          res.rightLogo = '';
          rightGrayLogo(res)
        };
      }
    })
  }

  return (
    <EditPage<Equity>
      title="会员权益"
      beforeUpdateEntity={uploadImg}
      {...editProps}
    >

      <ProFormText
        name="rightTitle"
        label="权益标题"
      />
      <Form.Item name="rightLogo" label="权益LOGO（彩色）" >
        <UploadImgOne
          listType="picture-card"
          extra="建议尺寸：800*800像素, 最多上传一张, 不超过2M."
          title="上传"
        />
      </Form.Item>
      <Form.Item name="rightGrayLogo" label="权益LOGO（灰色）" >
        <UploadImgOne
          listType="picture-card"
          extra="建议尺寸：800*800像素, 最多上传一张, 不超过2M."
          title="上传"
        />
      </Form.Item>
      <ProFormList
        label="权益列表"
        name={['customerRightDetails']}
        creatorButtonProps={{
          creatorButtonText: '添加权益',
        }}
        initialValue={[{}]}
        actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
      >
        {(meta, itemIndex, action) => <ProForm.Group size={10}>
          <Form.Item label="会员级别" name={['customerLevel', 'id']}>
            <SelectSearch valueType="number" placeholder='会员级别' required={false} defaultParams={'id.equals'} name={['customerLevel', 'id']} width={200} labelValue="levelName" fetchFunc={fetchLevelList} searchParams='levelName.contains' />
          </Form.Item>
          <ProFormTextArea
            name="rightDetail"
            label="权益明细"
            rules={[{ required: true }]}
            width={300}
          />
        </ProForm.Group>}
      </ProFormList>
      <ProFormDigit
        name="sortNo"
        label="排序"
      />
    </EditPage>);
};

const mapStateToProps = ({ equity }: RootState) => ({
  entity: equity.entity,
  updateSuccess: equity.updateSuccess,
  updating: equity.updating,
  createSuccess: equity.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EquityEdit);
