import { Button } from 'antd';
import QRCode from 'qrcode.react'
interface QRCodeControlProps {
    QRCodeValue: string;
    downloadFileName: string;
}

const QRCodeControl = (props: QRCodeControlProps) => {
    const { QRCodeValue, downloadFileName } = props;
    //base64转文件
    const dataURLtoBlob = (dataUrl: any) => {
        let arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new Blob([u8arr], { type: mime });
    };

    //图片下载
    const downloadFile = (url: any, name: any) => {
        //创建a标签用于下载
        let a = document.createElement('a');
        a.setAttribute('href', url);
        a.setAttribute('download', name);
        a.setAttribute('target', '_blank');
        let clickEvent = document.createEvent('MouseEvents');
        clickEvent.initEvent('click', true, true); //模拟点击
        a.dispatchEvent(clickEvent);
    };

    //下载二维码
    const handleDownLoadQRCode = () => {
        //先获取要下载的二维码
        let Qr: any = document.getElementById('qrCode'); 
        //把canvas的数据改成base64的格式
        let canvasUrl = Qr.toDataURL('image/png'); 
        let myBlob = dataURLtoBlob(canvasUrl);
        let myUrl = URL.createObjectURL(myBlob) //创建blob下载地址 
        downloadFile(myUrl, downloadFileName)
    };

    return <div>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <QRCode
                id="qrCode"
                value={QRCodeValue}
                size={200}
            />
        </div>
        <div style={{ display: 'flex', justifyContent: 'center', marginTop:'20px' }}>
            <Button style={{width: '200px'}} type="primary" onClick={() => handleDownLoadQRCode()}>下载二维码</Button>
        </div>
    </div>
};

export default QRCodeControl;