import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, fetchMenuList, getEntity, reset, updateEntity } from "./role.reducer";
import { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Role } from "model/system/role.model";
import { Col, Form, Row } from "antd";
import MenuPanel from "./menuPanel";
export interface RoleEditProps extends EditStateAndDispatchProps<Role>, StateProps, DispatchProps, RouteComponentProps {
}

const RoleEdit: FC<RoleEditProps> = props => {
  const { entity } = props;
  const [form] = Form.useForm();
  const [clientList, setClientList] = useState<any>([]);
  const [entityCopy, setEntityCopy] = useState<any>({});
  const [menuList, setMenuList] = useState([]);

  useEffect(() => {
    const clientArr = ['wholesale', 'tinsecret', 'sword', 'saber', 'app', 'wechat', 'xianboss-v6'];
    setClientList(clientArr.map(e => ({ label: e, value: e })));
  }, []);

  useEffect(() => {
    setEntityCopy({});
    if (entity.id) {
      let entityData = JSON.parse(JSON.stringify(entity));
      entityData.actionsObj = {};
      fetchMenuList(entity.id).then((res: any) => {
        const arr = res.data || []
        setMenuList(arr);
        arr.forEach((e:any) => {
          e.children.forEach((i:any) => {
            const objArr =i.actions.concat().map((obj:any) => {
              return !!obj.hasPermisson ? obj.id : ''
            }).filter((s:any) => s !== '')
            entityData.actionsObj[i.id] = objArr;
          })
        });
        setEntityCopy(entityData);
      })
    }else{
      fetchMenuList().then((res: any) => {
        const arr = res.data || []
        setMenuList(arr);
        setEntityCopy({});
      })
    }
  }, [entity]);

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      const arr = Object.keys(res.actionsObj);
      let ids: any = [];
      arr.forEach((e: any, index) => {
        const newArr = res.actionsObj[e].map((id: any) => ({ id }));
        ids = ids.concat(newArr);
      });
      res.actions = ids;
      resolve(res);
    })
  }

  return (
    <EditPage<Role>
      title="角色"
      {...props}
      form={form}
      entity={entityCopy}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row>
        <Col span={11}>
          <ProFormText
            name="code"
            label="角色编码"
            width="md"
            rules={[{
              required: true,
              pattern: RegExp(/^[a-zA-Z0-9_]+$/i),
              message: '仅限输入英文数字下划线，不可有空格及符号'
            }]}
            disabled={props.entity.code ? true : false}
          />
          <Col span={1}></Col>
        </Col>
        <Col span={11}>
          <ProFormText
            name="name"
            label="角色名称"
            width="md"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={11}>
          <ProFormSelect
            options={clientList}
            rules={[{ required: true }]}
            label="访问终端"
            width={'md'}
            name="clients"
            mode="multiple"
          />
        </Col>
        <Col span={24}>
          <MenuPanel menuList={menuList} id={props.entity.id || ''} />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ role }: RootState) => ({
  entity: role.entity,
  updateSuccess: role.updateSuccess,
  updating: role.updating,
  createSuccess: role.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RoleEdit);
