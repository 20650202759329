import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import CategoryList from './category-list';
import CategoryDetails from "./category-details";
import CategoryEdit from "./category-edit";

const Category: React.FC<RouteComponentProps> = () => (
  <Router>
    <CategoryList path="/" />
    <CategoryDetails path=":id" />
    <CategoryEdit path=":id/edit" />
    <CategoryEdit path="new" />
  </Router>
);
export default Category;
