import { BaseModel } from "../base.model";

export interface DealerInfo extends BaseModel {
    customerId?: any;
    idCardFrontUrl?: any;
    idCardBackUrl?: any;
    creditCodeUrl?: any;
    code?: any;
    customerIdCardNum?: string;
    mobile?: number;
}

export const dealerInfoOpenAccountStatus:any = {
    0: '未激活(未开户成功)', '1': '激活申请中', '3': '激活失败（开户失败）', "4": '已激活（开户成功）'
}

export const defaultDealerInfo: Readonly<DealerInfo> = {};