import Title from "antd/lib/typography/Title";
import { Col, Divider, Row } from "antd";
import { ProFormCheckbox } from "@ant-design/pro-form";

interface MenuPanelProps {
    id?: any;
    menuList?: any;
}
const MenuPanel = (props: MenuPanelProps) => {
    const { menuList } = props;
    return <>
        <Divider>权限相关</Divider>
        {menuList.map((item: any) => {
            return <>
                <Title level={5}>{item.menuName}</Title>
                <Row>
                    <Col>
                        {item.children.map((children: any) => {
                            return <ProFormCheckbox.Group
                                name={['actionsObj',children.id]}
                                layout="horizontal"
                                label={children.name}
                                options={children.actions.concat().map((i: any) => {
                                    return {
                                        label: i.name,
                                        value: i.id + '',
                                    }
                                })}
                            />
                        })}
                    </Col>
                </Row>
            </>
        })}
    </>
}

export default MenuPanel;