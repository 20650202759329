import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import Activity from './activity';
import FullGift from './fullGift';
import './index.less'

const Promotion: React.FC<RouteComponentProps> = () => (
  <Router>
    <Activity path="activity/*" />
    <FullGift path="fullGift/*" />
  </Router>
);
export default Promotion;
