import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './trainingList.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { TrainingList } from "model/dealerStore/trainingList.model";

export interface TrainingListProps extends DetailsStateAndDispatchProps<TrainingList>,
  RouteComponentProps, StateProps, DispatchProps {
}
const TrainingListDetails: FC<TrainingListProps> = (props) => {
  return (
    <DetailsPage<TrainingList>
      title={'申请培训详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<TrainingList>
        columns={props.columns as any}
        dataSource={props.entity}
        column={1}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ trainingList }: RootState) => ({
  entity: trainingList.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TrainingListDetails);
