import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import Payorder from "./payorder";

const PayManage: React.FC<RouteComponentProps> = () => (
  <Router>
    <Payorder path="payorder/*" />
  </Router>
);
export default PayManage;
