import { FC, useEffect, useState } from 'react';
import { Collapse, Space, } from 'antd';
import { queryParamGroup } from './param-group.reducer';
import { RouteComponentProps } from "@reach/router";
import ParamGroupForm from './param-group-form';
import { ParamGroups } from 'model/system/param.model';

const { Panel } = Collapse;

interface ParamGroupProps extends RouteComponentProps {
}

const ParamGroup: FC<ParamGroupProps> = props => {

	const [paramGroup, setParamGroup] = useState([]);

	useEffect(() => {
		const getParamGroup = async () => {
			const res = await queryParamGroup();
			res && setParamGroup(res);
		}
		getParamGroup();
	}, [])

	return (
		<>
			<Space className='param-group'>
				<h3>参数配置</h3>
			</Space>
			<Collapse accordion defaultActiveKey={['1']}>
				{
					paramGroup && paramGroup.map((item: ParamGroups) =>
						<>{
							item.id &&
							<Panel header={item.name} key={item.id}>
								<ParamGroupForm id={item.id} />
							</Panel>
						}</>
					)
				}
			</Collapse>
		</>
	);
};

export default ParamGroup;
