import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { CancelAccount, credentialsTypeMap, fetchLLBalance, fetchPicture, getEntity, orgTypeMap, typeMap } from './dealerInfo.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { DealerInfo } from 'model/dealer/dealerInfo.model';
import Title from "antd/lib/typography/Title";
import { Button, Popconfirm, message, Image, Row, Col, Spin } from "antd";
import Authorized from "common/auth/authorized";
import { EditOutlined, WarningOutlined } from "@ant-design/icons";
import { ProColumns } from "@ant-design/pro-table";
import DecryptText, { searchSafeCode } from "../customer/detailtables/decryptText";
import AccountinfoEdite from "./accountinfo-edite";

export interface DealerInfoDetailsProps extends DetailsStateAndDispatchProps<DealerInfo>,
  RouteComponentProps, StateProps, DispatchProps {
}
const DealerInfoDetails: FC<DealerInfoDetailsProps> = (props) => {
  const [balance, setBalance] = useState(0);
  const [idCardFrontUrl, setIdCardFrontUrl] = useState<any>('');
  const [idCardBackUrl, setIdCardBackUrl] = useState<any>('');
  const [creditCodeUrl, setCreditCodeUrl] = useState<any>('');
  const [customerIdCardNum, setCustomerIdCardNum] = useState<any>('');
  const [accountinfoEditeShow, setAccountinfoEditeShow] = useState<boolean>(false);
  const [mobile, setMobile] = useState<any>('');
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    setLoading(true)
    setTimeout(()=> {
      setLoading(false)
    }, 1800)
}, [props]);
  

  const columns: ProColumns<any>[] = [{
    title: '经销商授权码',
    dataIndex: 'code',
    width: 100
  },
  {
    title: '开户类型',
    dataIndex: 'type',
    width: 100,
    valueEnum: typeMap,
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    width: 100,
    render: (text, record) => {
      return <div style={{ display: 'flex' }}>
        ******<DecryptText title='手机号' id={record.id} data={record.mobile} />
      </div>
    }
  },
  {
    title: '邮箱',
    dataIndex: 'email',
    hideInTable: true,
    width: 140,
  },
  {
    title: '真实姓名',
    dataIndex: 'realName',
    width: 100
  },
  {
    title: '证件类型',
    dataIndex: 'credentialsType',
    width: 100,
    valueEnum: credentialsTypeMap,
  },
  {
    title: '身份证号码',
    dataIndex: 'customerIdCardNum',
    width: 130,
    render: (text, record) => {
      return <div style={{ display: 'flex' }}>
        ******<DecryptText title='身份证号码' id={record.id} data={record.customerIdCardNum} />
      </div>
    }
  },
  {
    title: '身份证到期日期',
    dataIndex: 'idCardExpiredDate',
    hideInTable: true,
    width: 120
  },
  {
    title: '经营类型',
    dataIndex: 'orgType',
    width: 100,
    valueEnum: orgTypeMap,
  },
  {
    title: '企业名称',
    dataIndex: 'orgName',
    width: 140,
  },
  {
    title: '社会统一信用代码',
    dataIndex: 'creditCode',
    hideInTable: true,
    width: 140,
  },
  {
    title: '营业期限到期时间',
    dataIndex: 'tradeExpiredDate',
    hideInTable: true,
  },
  ];

  const defaultCardBinding = [
    {
      title: '开户行编码',
      dataIndex: 'bankCode',
      hideInTable: true,
      width: 140,
    },
    {
      title: '开户行名称',
      dataIndex: 'bankName',
      hideInTable: true,
      width: 140,
    },
    {
      title: '银行卡号',
      dataIndex: 'bankCardNum',
      hideInTable: true,
    },
    {
      title: '大额行号',
      dataIndex: 'cnapsCode',
      hideInTable: true,
    },
    {
      title: '开户支行名称',
      dataIndex: 'braBankName',
      hideInTable: true,
    },
  ];

  const state = [
    //   {
    //   title: '开户状态',
    //   dataIndex: 'openAccountStatus',
    //   width: 80,
    //   valueEnum: dealerInfoOpenAccountStatus,
    //   render: (dom:any, record:any) => {
    //     return dealerInfoOpenAccountStatus[record?.openAccountStatus ? (record?.openAccountStatus + '') : '']
    //   }
    // }, 
    {
      title: '原因',
      dataIndex: 'silentOpenRemark',
      ellipsis: true,
      width: 100,
    }]



  useEffect(() => {
    props.entity.customerId && fetchLLBalance(props.entity.id).then(e => {
      setBalance(e);
    }).catch(error => {
      setBalance(0);
    });

    props.entity.customerIdCardNum && searchSafeCode(props.entity.id || '', props.entity.customerIdCardNum || '').then(e => {
      setCustomerIdCardNum(e);
    });

    props.entity.mobile && searchSafeCode(props.entity.id || '', props.entity.mobile || '').then(e => {
      setMobile(e);
    });

    props.entity.idCardFrontUrl && fetchPicture(props.entity.idCardFrontUrl).then(e => {
      setIdCardFrontUrl(e);
    }).catch(error => {
      message.error(error?.response?.data?.title || 'error');
    });
    props.entity.idCardBackUrl && fetchPicture(props.entity.idCardBackUrl).then(e => {
      setIdCardBackUrl(e);
    }).catch(error => {
      message.error(error?.response?.data?.title || 'error');
    });
    props.entity.creditCodeUrl && fetchPicture(props.entity.creditCodeUrl).then(e => {
      setCreditCodeUrl(e);
    }).catch(error => {
      message.error(error?.response?.data?.title || 'error');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.entity.id]);

  const logOff = () => {
    props.entity.customerId && CancelAccount(props.entity.id).then(e => {
      message.success('注销成功');
    }).catch(error => {
      message.error(error?.response?.data?.title || 'error');
    });
  }

  return (
    <DetailsPage<DealerInfo>
      title={'账户资料'}
      hideEdit={true}
      {...props}
    >
      <Spin spinning={loading} delay={500}>
        <Button onClick={() => setAccountinfoEditeShow(true)} type="primary" className="dealerEdite" icon={<EditOutlined />}  >编辑</Button>
        <ProDescriptions<DealerInfo>
          columns={columns as any}
          dataSource={props.entity}
          column={4}
        />
        <Title style={{ marginTop: '20px' }} level={4}>默认绑卡</Title>
        <ProDescriptions<DealerInfo>
          columns={defaultCardBinding as any}
          dataSource={props.entity}
          column={4}
        />
        <Title style={{ marginTop: '20px' }} level={4}>证照资料</Title>
        <Row>
          <Col span='7'>
            <Title style={{ fontSize: '14px' }} level={5}>身份证正面照</Title>
            <Image alt='' style={{ width: '100px', height: 'auto' }} src={idCardFrontUrl} />
          </Col><Col span={1} />
          <Col span='7'>
            <Title style={{ fontSize: '14px' }} level={5}>身份证反面照</Title>
            <Image alt='' style={{ width: '100px', height: 'auto' }} src={idCardBackUrl} />
          </Col><Col span={1} />
          <Col span='7'>
            <Title style={{ fontSize: '14px' }} level={5}>营业执照</Title>
            <Image alt='' style={{ width: '100px', height: 'auto' }} src={creditCodeUrl} />
          </Col><Col span={1} />
        </Row>
        <Title style={{ marginTop: '20px' }} level={4}>开户状态原因</Title>
        <ProDescriptions<DealerInfo>
          columns={state as any}
          dataSource={props.entity}
          column={1}
        />
        <div className="LLBox">
          <Title level={4}>连连余额：{balance}</Title>
          <Authorized authority='R_cancel_account'>
            <Popconfirm
              title="你确定要销户吗？"
              icon={<WarningOutlined />}
              disabled={balance > 0 ? true : false}
              onConfirm={() => logOff()}
            >
              <Button type="primary" danger disabled={balance > 0 ? true : false}>销户申请</Button>
            </Popconfirm>
            {<div className="cancel_account_text">
              <p>销户注意事项！！！</p>
              <p>1. 连连有余额销户联系管理员</p>
              <p>2. 个人转企业，企业转个人销户联系管理员</p>
            </div>}
          </Authorized>

          {accountinfoEditeShow &&
            <AccountinfoEdite reload={() => {
              getEntity(props.entity.id)
            }} accountinfoEditeShow={accountinfoEditeShow} setAccountinfoEditeShow={setAccountinfoEditeShow} entity={{
              ...props.entity,
              idCardFrontUrl: idCardFrontUrl,
              idCardBackUrl: idCardBackUrl,
              creditCodeUrl: creditCodeUrl,
              customerIdCardNum: customerIdCardNum,
              mobile: mobile
            }} />
          }
        </div>

      </Spin>
    </DetailsPage>);
};

const mapStateToProps = ({ dealerInfo }: RootState) => ({
  entity: dealerInfo.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DealerInfoDetails);
