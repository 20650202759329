import { BaseModel } from "../base.model";
import { Product, Sku } from "./product.model";

export interface Stock extends BaseModel {
    quantity?: number;
    sellerId?: string | number;
    sellerName?: string;
    shopId?: string | number;
    sku?: Sku;
    product?: Product;
}
export const defaultStock: Readonly<Stock> = {};