import { FC, ReactNode } from "react";
import { Form, Input, Image } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import EquityDetails from "./equity-details";
import { deleteEntity, getEntities, getEntity } from "./equity.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import EquityEdit from "./equity-edit";
import { Equity } from "model/customerCore/equity.model";


export interface EquityListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Equity>[] = [{
  title: '权益Logo（彩色）',
  dataIndex: 'rightLogo',
  render: (text, record) => <Image width={80} src={record.rightLogo} />,
  width: 150,
}, {
  title: '权益标题',
  dataIndex: 'rightTitle',
  width: 150,
}, {
  title: '权益排序',
  dataIndex: 'sortNo',
}];


const EquityList: FC<EquityListProps> = (props) => {
  const searchFields = [{
    label: '权益标题',
    name: 'rightTitle.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Equity>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<EquityDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      editPage={(params: any) => <EquityEdit id={params.id} text={params.text} />}
      sort="sortNo"
      hideDeleteButton
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ equity }: RootState) => ({
  entity: equity.entity,
  entities: equity.entities,
  loading: equity.loading,
  updateSuccess: equity.updateSuccess,
  createSuccess: equity.createSuccess,
  total: equity.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EquityList);