import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormText } from '@ant-design/pro-form';
import { createEntity, getEntity, reset, updateEntity } from "./category.reducer";
import { Col, Form, Row } from "antd";
import { VideoCategory } from "model/video/video-category";
import IntelFormText from "components/IntelFormText";

export interface VideoCategoryEditProps extends EditStateAndDispatchProps<VideoCategory>, StateProps, DispatchProps, RouteComponentProps {
}

const VideoCategoryEdit: FC<VideoCategoryEditProps> = (props) => {
  const [form] = Form.useForm();
  return (
    <EditPage<VideoCategory>
      title="分类"
      form={form}
      {...props}
    >
      <Row>
        <Col span={11}>
          <IntelFormText
            label='分类名称'
            name="name"
            required={true}
            form={form}
            fieldProps={{
              maxLength: 50
            }}
            value={props.entity.name || ''}
          />
        </Col>
        <Col span={1} />
        <Col span={11}>
          <ProFormText
            placeholder="请输入排序"
            name="sort"
            label="排序"
            rules={[{ required: true }]}
          />
        </Col>

        <Col span={11}>
          <ProFormText
            hidden
            name="level"
            label="级别"
            initialValue={1}
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ videoCategory }: RootState) => ({
  entity: videoCategory.entity,
  updateSuccess: videoCategory.updateSuccess,
  updating: videoCategory.updating,
  createSuccess: videoCategory.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoCategoryEdit);
