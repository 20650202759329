import React, { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './delivery.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Delivery } from "model/product/delivery.model";
import ProTable from "@ant-design/pro-table";
import { getRegions } from "../region/region.reducer";
import { Tag } from "antd";
import Title from "antd/lib/typography/Title";
import { Storage } from 'utils/storage-util';
import { regionObj } from "./delivery-edit";

export interface DeliveryDetailsProps extends DetailsStateAndDispatchProps<Delivery>,
  RouteComponentProps, StateProps, DispatchProps {
}
const DeliveryDetails: FC<DeliveryDetailsProps> = (props) => {
  const [treeOptions, setTreeOptions] = useState<any>([]);

  useEffect(() => {
    const xTenant = Storage.session.get("x-tenant");
    const regionKey: 'tmm' | 'ttw' | 'tml' | 'tsg' = regionObj[xTenant] ? xTenant : 'tmm';
    getRegions(regionObj[regionKey][0]).then(e => { setTreeOptions(e) });
  }, []);

  const fixedColumns = [{
    title: '固定价格(元)',
    dataIndex: ['defaultCharge', 'fixedPrice'],
    hideInTable: true
  }, {
    title: '区域',
    dataIndex: ['regions'],
    hideInDescriptions: true,
    render: (dom: any) => <>{treeOptions.filter((e: any) => dom.indexOf(e.value) > -1).map((e: any) => <Tag style={{ marginBottom: '6px' }}>{e.label}</Tag>)}</>
  }, {
    title: '固定价格(元)',
    dataIndex: ['shipCharge', 'fixedPrice'],
    width: 100,
    hideInDescriptions: true,
  }];

  const weightColumns = [{
    title: '首重(Kg)',
    dataIndex: ['defaultCharge', 'firstWeight'],
    hideInTable: true
  }, {
    title: '首重价格(元)',
    dataIndex: ['defaultCharge', 'firstWeightPrice'],
    hideInTable: true
  }, {
    title: '续重(Kg)',
    dataIndex: ['defaultCharge', 'additionalWeight'],
    hideInTable: true
  }, {
    title: '续重价格(元)',
    dataIndex: ['defaultCharge', 'additionalWeightPrice'],
    hideInTable: true
  }, {
    title: '区域',
    dataIndex: ['regions'],
    hideInDescriptions: true,
    render: (dom: any) => <>{treeOptions.filter((e: any) => dom.indexOf(e.value) > -1).map((e: any) => <Tag style={{ marginBottom: '6px' }}>{e.label}</Tag>)}</>
  }, {
    title: '首重(Kg)',
    dataIndex: ['shipCharge', 'firstWeight'],
    hideInDescriptions: true
  }, {
    title: '首重价格(元)',
    dataIndex: ['shipCharge', 'firstWeightPrice'],
    hideInDescriptions: true
  }, {
    title: '续重(Kg)',
    dataIndex: ['shipCharge', 'additionalWeight'],
    hideInDescriptions: true
  }, {
    title: '续重价格(元)',
    dataIndex: ['shipCharge', 'additionalWeightPrice'],
    hideInDescriptions: true
  },];

  return (
    <DetailsPage<Delivery>
      title={'运费模版详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Delivery>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      />

      {props.entity.priceModel === 1 && <><ProDescriptions<Delivery>
        columns={fixedColumns}
        dataSource={props.entity}
        column={1}
      />
        <Title style={{ marginTop: '20px' }} level={5}>按区域定义运费</Title>
        <ProTable
          search={false}
          toolBarRender={false}
          pagination={false}
          dataSource={props.entity.regionCharges}
          columns={fixedColumns}
        /></>}


      {props.entity.priceModel === 2 && <><ProDescriptions<Delivery>
        columns={weightColumns}
        dataSource={props.entity}
        column={2}
      />
        <Title style={{ marginTop: '20px' }} level={5}>按区域定义运费</Title>
        <ProTable
          search={false}
          toolBarRender={false}
          pagination={false}
          dataSource={props.entity.regionCharges}
          columns={weightColumns}
        /></>}
    </DetailsPage>);
};

const mapStateToProps = ({ delivery }: RootState) => ({
  entity: delivery.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryDetails);
