import React, { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity } from "./express.reducer";
import { RouteComponentProps } from "@reach/router";
import { Form, Input } from "antd";
import TenantEdit from "./express-edit";
import TenantDetails from "./express-details";
import SearchBox from "components/search-box";
import { Express } from "model/system/express";

export interface TenantListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

export const columns: ProColumns<Express>[] = [{
  title: '快递公司编码',
  dataIndex: 'expressCode',
}, {
  title: '快递公司名称',
  dataIndex: 'expressName',
}, {
  title: '中台快递名称',
  dataIndex: 'externalExpressName'
}, {
  title: '中台系统快递编码',
  dataIndex: 'externalExpressCode',
 
},{
  title: '排序',
  dataIndex: 'sort'
}];

const searchFields = [{
  label: '快递公司编码',
  name: 'expressCode.equals',
}, {
  label: '快递公式名称',
  name: 'expressName.equals',
}];

// 搜索条件
const quickFilterPanel = (
  <Form.Item>
    <Input.Group compact>
      <SearchBox
        fields={searchFields}
      />
    </Input.Group>
  </Form.Item>
);



const TenantList: FC<TenantListProps> = (props) => {
  return (
    <ListViewPage<Express>
    quickFilterPanel={quickFilterPanel}
      columns={columns} 
      editPage={(params: any) => <TenantEdit id={params.id} text={params.text} />}
      detailPage={<TenantDetails navigate={props.navigate} columns= {columns} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};



const mapStateToProps = ({ express }: RootState) => ({
  entity: express.entity,
  entities: express.entities,
  loading: express.loading,
  updateSuccess: express.updateSuccess,
  total: express.total,
  createSuccess: express.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TenantList);