import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./materialDown.reducer";
import { ProFormDateTimePicker, ProFormText } from '@ant-design/pro-form';
import { Form, message } from 'antd';
import { MaterialDown } from 'model/product/materialDown.model';
import UploadImgOne from "components/upload-img/upload-img-one";
import { uploadImage } from "utils/file-utils";
export interface trainListProps extends EditStateAndDispatchProps<MaterialDown>, StateProps, DispatchProps, RouteComponentProps {
}

const MaterialDownEdite: FC<trainListProps> = props => {
  const { entity } = props;
  const [form] = Form.useForm();

  const uploadImg = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        const pictureUrl: any = res && res.pictureUrl?.concat();
        if (res.pictureUrl && pictureUrl.length > 0) {
          if (pictureUrl[0].originFileObj) {
            //logo需上传
            const newDate = new Date().getTime();
            uploadImage(pictureUrl[0].originFileObj, 'Inventory/img-' + newDate).then(async imageName => {
              res.pictureUrl = imageName;
              resolve(res);
            }).catch(() => { message.error('图片上传失败'); reject() });
          } else {
            // logo已上传且未修改
            res.pictureUrl = pictureUrl[0].url;
            resolve(res)
          }
        } else {
          //未上传logo
          res.pictureUrl = '';
          resolve(res)
        }
      }
    })
  }

  return (
    <EditPage<MaterialDown>
      width={600}
      beforeUpdateEntity={uploadImg}
      title="物料"
      form={form}
      {...props}
      entity={entity}
    >

      <ProFormText
        name="title"
        colon={true}
        labelAlign='left'
        label="物料名称"
        rules={[{ required: true }]}
      />
      <ProFormText
        name="code"
        label="提取码"
        rules={[{ required: true }]}
      />
      <ProFormDateTimePicker
        name={"date"}
        placeholder="发布时间"
        label='发布时间'
      />
      <ProFormText
        name="baiduLink"
        label="下载链接"
        rules={[{ required: true }]}
      />
      <Form.Item name="pictureUrl" label="物料图" >
        <UploadImgOne
          listType="picture-card"
          extra="建议尺寸：200*200像素, 最多上传一张, 不超过2M."
          title="上传"
        />
      </Form.Item>
    </EditPage>);
};

const mapStateToProps = ({ materialDown }: RootState) => ({
  entity: materialDown.entity,
  updateSuccess: materialDown.updateSuccess,
  updating: materialDown.updating,
  createSuccess: materialDown.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MaterialDownEdite);
