import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import CouponDetails from './coupon-details';
import CouponEdit from './coupon-edit';
import CouponList from './coupon-list';

const Coupon: React.FC<RouteComponentProps> = () => (
  <Router>
    <CouponList path="/" />
    <CouponDetails path=":id" />
    <CouponEdit path=":id/edit" />
    <CouponEdit path="new" />
  </Router>
);
export default Coupon;
