import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import FullGiftDetails from './fullGift-details';
import FullGiftEdit from './fullGift-edit';
import FullGiftList from './fullGift-list';

const FullGift: React.FC<RouteComponentProps> = () => (
  <Router>
    <FullGiftList path="/" />
    <FullGiftDetails path=":id" />
    <FullGiftEdit path=":id/edit" />
    <FullGiftEdit path="new" />
  </Router>
);
export default FullGift;
