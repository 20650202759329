import { FC, ReactNode, useState, useRef } from "react";
import { Button, Form, Input, message, Tooltip, Col, Row } from "antd";
import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./wallet-details";
import { deleteEntity, distributeWallet, fetchWalletDetailed, getEntities, getEntity, walletAccountTypeList, adjustApi } from './wallet.reducer';
import { RouteComponentProps } from "@reach/router";
import WalletEdit from './wallet-edit';
import { Wallet } from "model/customerCore/wallet.model";
import { ModalForm, ProFormDigit, ProFormGroup, ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import { fetchSearchCustomerList } from "../customer/customer.reducer";


export interface WalletListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const WalletList: FC<WalletListProps> = (props) => {

  const { convertEnumToOptionType } = useEnumValue();
  const [extraUserId, setExtraUserId] = useState('');

  const detailedColumns: ProColumns<Wallet, 'walletAccountType' | 'walletTransactionType' | 'operationType'>[] = [{
    dataIndex: 'id',
    title: 'id',
    width: 80,
    hideInSearch: true,
  }, {
    dataIndex: 'userId',
    title: '用户id',
    width: 100,
    hideInSearch: true,
  }, {
    dataIndex: 'accountType',
    valueType: 'walletAccountType',
    title: '账户类型',
    width: 100,
    hideInSearch: true,
  }, {
    dataIndex: 'transactionType',
    title: '交易类型',
    valueType: 'walletTransactionType',
    width: 100,
    hideInSearch: true,
  }, {
    dataIndex: 'amount',
    title: '账户余额',
    width: 100,
    hideInSearch: true,
  }, {
    dataIndex: 'operationAmount',
    title: '操作金额',
    width: 100,
    hideInSearch: true,
  }, {
    dataIndex: 'transactionTime',
    title: '交易时间',
    width: 100,
    hideInSearch: true,
  }, {
    dataIndex: 'transactionTypeName',
    title: '交易类型',
    width: 100,
    hideInSearch: true,
  }, {
    dataIndex: 'transactionType.equals',
    width: 100,
    hideInTable: true,
    valueType: 'select',
    renderFormItem: (text, record, valueType) => {
      return <ProFormSelect
        name="transactionType.equals"
        options={convertEnumToOptionType('walletTransactionType')}
        placeholder="交易类型"
      />
    },
  }, {
    dataIndex: 'operationType',
    title: '操作类型',
    valueType: 'operationType',
    width: 100,
    hideInSearch: true,
  }, {
    dataIndex: 'operationType.equals',
    width: 100,
    hideInTable: true,
    valueType: 'select',
    renderFormItem: (text, record, valueType) => {
      return <ProFormSelect
        name="operationType.equals"
        options={convertEnumToOptionType('operationType')}
        placeholder="操作类型"
      />
    },
  },];

  const columns: ProColumns<Wallet, 'walletAccountType' | 'walletStatus'>[] = [{
    dataIndex: 'userId',
    title: '用户id',
    width: 100
  }, {
    dataIndex: 'customerId',
    title: '会员id',
    width: 100
  }, {
    dataIndex: 'accountType',
    valueType: 'walletAccountType',
    title: '钱包类型',
    width: 100
  }, {
    dataIndex: 'amount',
    title: '金额',
    width: 100
  }]

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const actionRef = useRef<ActionType>();
  // 充值
  const adjustRender: any = (dom: ReactNode, record: any): ReactNode => (
    <ModalForm<{ auditNote?: string, auditStatus?: boolean }>
      title="调整"
      width={600}
      {...formItemLayout}
      layout='horizontal'
      preserve={false}
      trigger={
        <Tooltip title="调整">
          <Button type="link" size="small">调整</Button>
        </Tooltip>
      }
      onFinish={async (data: any) => {
        let newData = {}
        adjustApi(newData)
        actionRef.current?.reload();
        return true;
      }}
    >

      <Row>
        <Col span={7}>
          用户Id: {record.userId}
        </Col>
        <Col span={1} />
        <Col span={7}>
          会员Id: {record.userId}
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          <ProFormSelect
            label="钱包类型"
            rules={[{ required: true }]}
            name={'accountType'}
            request={async () => {
              const unitRequest: any = await walletAccountTypeList();
              return unitRequest.walletAccountType.map((item: any) => ({
                value: item?.name,
                label: item?.desc,
              }))
            }}
          />

        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText name='amount' label="金额" placeholder='金额' rules={[{ required: true }]} />
        </Col>
      </Row>
    </ModalForm>
  );

  // 钱包明细
  const walletDetailRender: any = (dom: ReactNode, record: any): ReactNode => (
    <ModalForm
      title="钱包明细"
      width={1000}
      trigger={<Button type="link" size="small" icon={<></>}>明细</Button>}
      modalProps={{ destroyOnClose: true, }}
      onFinish={async (values) => {
        distributeWallet(values).then(e => {
          message.success('发放成功');
        }).catch(e => {
          message.error(e.response.data.title);
        })
        return true;
      }}
    >

      <ProTable<Wallet, Record<string, any>, 'walletAccountType' | 'walletTransactionType' | 'operationType'>
        className="express-table"
        columns={detailedColumns}
        rowKey="id"
        params={{ userId: record?.userId }}
        request={async (params) => {
          let res = await fetchWalletDetailed({
            sort: 'lastModifiedDate,desc',
            'userId.equals': params.userId,
            page: (params.current && params.current - 1) || 0,
            size: params.pageSize || 20,
            'operationType.equals': params['operationType.equals'] || null,
            'transactionType.equals': params['transactionType.equals'] || null,
          });
          return Promise.resolve({
            data: res?.data || [],
            success: true,
            total: res.headers['x-total-count'] || 0
          });
        }}
        options={false}
      />
    </ModalForm>
  )



  const searchFields: SearchField[] = [{
    label: '用户id',
    name: 'userId.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          name="accountType.equals"
          options={convertEnumToOptionType('walletAccountType')}
          placeholder="钱包类型"
        />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const additionActions = [
    <ModalForm
      title="发放积分"
      trigger={<Button type="primary" icon={<></>}>发放积分</Button>}
      modalProps={{ destroyOnClose: true, }}
      onFinish={async (values) => {
        distributeWallet(values).then(e => {
          props.getEntities();
          message.success('发放成功');
        }).catch(e => {
          message.error(e.response.data.title);
        })
        return true;
      }}
    >
      <ProFormGroup size="middle">
        {/* <SelectSearch width={'md'} required={false} name="userId" label="选择用户" optionValue="userId" placeholder="选择用户" labelValue="name" defaultParams='' fetchFunc={fetchSearchCustomerList} searchParams='name.contains' /> */}
        <ProFormText
          name="userId"
          label="用户id"
          placeholder={'请输入用户id'}
          width={'sm'}
          fieldProps={{
            onBlur: (e) => {
              const val = e.target.value;
              fetchSearchCustomerList({ 'userId.equals': val }).then(e => {
                e.length > 0 ? setExtraUserId(e[0]?.name || '') : setExtraUserId('');
              }).catch(() => setExtraUserId(''));
            },
          }}
          extra={extraUserId}
        />
        <ProFormDigit
          name="amount"
          label="积分数量"
          width={'sm'}
          min={-10000000}
        />
      </ProFormGroup>
      <ProFormTextArea
        name="reason"
        label="原因"
      />
    </ModalForm>
  ];

  return (
    <ListViewPage<Wallet, 'walletAccountType' | 'walletStatus'>
      columns={columns}
      quickFilterPanel={convertEnumToOptionType('walletAccountType').length > 0 && quickFilterPanel}
      hideCreateButton
      hideDeleteButton
      rowActions={[walletDetailRender, adjustRender]}
      actions={additionActions}
      hideEditButton
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      sort={''}
      editPage={(params: any) => <WalletEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ wallet }: RootState) => ({
  entity: wallet.entity,
  entities: wallet.entities,
  loading: wallet.loading,
  updateSuccess: wallet.updateSuccess,
  total: wallet.total,
  createSuccess: wallet.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(WalletList)