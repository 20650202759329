import { Form, Input } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { priceRulesModel } from "model/product/pricingRules";
import { FC, ReactNode, useEffect, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import PricingRulesDetails from "./pricingRules-details";
import { deleteEntity, getEntities, getEntity } from "./pricingRules.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import PricingRulesEdit from "./pricingRules-edit";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer"; import SelectSearch from "components/select-search";
;
export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const PricingRulesList: FC<LevelListProps> = (props) => {

  const [storeList, setStoreList] = useState([]);

  // 获取店铺列表
  const getStoreList = () => {
    fetchStoreList().then((e: any) => {
      setStoreList(e)
    })
  }

  useEffect(() => {
    getStoreList()
  }, [])

  const columns: ProColumns<priceRulesModel>[] = [{
    title: '规则名称',
    dataIndex: 'name',
    width: 350
  },
  {
    title: '取整方式',
    dataIndex: 'roundModeName'
  },
  {
    title: '店铺',
    dataIndex: 'storeId',
    width: 250,
    render: (dom) => {
      let data: any = storeList.find((item: any) => item.value === dom)
      return data?.label
    }
  },
  {
    title: '是否尾数处理',
    dataIndex: 'isRound',
    width: 250,
    render: (dom, record: any) => {
      return record.isRound === true ? '是' : '否'
    }
  },
  {
    title: '创建时间',
    dataIndex: 'startTime',
    width: 250
  }];

  const searchFields: SearchField[] = [
    {
      label: '规则名称',
      name: 'name.equals',
    }
  ];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch required={false} name="storeId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );


  return (
    <ListViewPage<priceRulesModel>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<PricingRulesDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      editPage={(params: any) => <PricingRulesEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ pricingRules }: RootState) => ({
  entity: pricingRules.entity,
  entities: pricingRules.entities,
  loading: pricingRules.loading,
  updateSuccess: pricingRules.updateSuccess,
  createSuccess: pricingRules.createSuccess,
  total: pricingRules.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PricingRulesList);