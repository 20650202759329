import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ClientDetails from "./client-details";
import { deleteEntity, getEntities, getEntity } from "./client.reducer";
import { RouteComponentProps } from "@reach/router";
import { Client } from "model/system/client.model";
import ClientEdit from "./client-edit";

export interface ClientListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Client>[] = [{
  title: '应用ID',
  dataIndex: 'clientId',
  valueType: 'text',
}, {
  title: '应用密钥',
  dataIndex: 'clientSecret',
  valueType: 'text',
// }, {
//   title: '授权类型',
//   dataIndex: 'operationMethod',
}, {
  title: '授权范围',
  dataIndex: 'scope',
  valueType: 'text',
  ellipsis: true,
  renderText: (text: []) => {
    return text && text.join(',');
  }
}, {
  title: '令牌秒数',
  dataIndex: 'accessTokenValidity',
  valueType: 'text',
}];

// const searchFields = [{
//   label: '应用ID',
//   name: 'clientId.equals',
// }, {
//   label: '应用密钥',
//   name: 'clientSecret.equals',
// }];

const ClientList: FC<ClientListProps> = (props) => {
  // const quickFilterPanel = (
  //   <Form.Item>
  //     <Input.Group compact>
  //       <SearchBox
  //         label='应用ID、应用密钥'
  //         fields={searchFields}
  //       />
  //     </Input.Group>
  //   </Form.Item>
  // );

  return (
    <ListViewPage<Client>
      columns={columns}
      // quickFilterPanel={quickFilterPanel}
      detailsIndex={1}
      detailPage={<ClientDetails navigate={props.navigate} columns={columns} />}
      editPage={(params) => <ClientEdit id={params.id} text={params.text}/>} 
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ client }: RootState) => ({
  entity: client.entity,
  entities: client.entities,
  loading: client.loading,
  updateSuccess: client.updateSuccess,
  createSuccess: client.createSuccess,
  total: client.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientList);