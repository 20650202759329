import axios from 'axios';
import { defaultVideoCategory, VideoCategory } from 'model/video/video-category';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'VideoCategory';
const apiUrl = '/api/yumei-mall-post/material-category';
const standardReducerImpl = new StandardReducerImpl<VideoCategory>(entity, defaultVideoCategory, apiUrl);
export type VideoCategoryState = StandardState<VideoCategory>
export const videoCategory = standardReducerImpl.buildStandardReducer();
export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntityLevelChange;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchCategoryList = async (params?: any): Promise<VideoCategory[]> => {
  const axiosResponse = await axios.get(`${apiUrl}`, { params });
  return axiosResponse.data || [];
}

export {
    apiUrl,
};
