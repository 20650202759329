import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import FullGiftDetails from "./fullGift-details";
import { deleteEntity, fullGiftEnableDisable, getEntities, getEntity, getPromotionStatistics } from "./fullGift.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import FullGiftEdit from "./fullGift-edit";
import { FullGift } from "model/promotion/fullGift.model";
import { useEnumValue } from "common/enum/use-enum-value";
import Popconfirm from "antd/es/popconfirm";
import { ModalForm, ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";

export interface FullGiftListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}



const FullGiftList: FC<FullGiftListProps> = (props) => {

  const { getEnumValueDesc,convertEnumToOptionType } = useEnumValue();
  const [refreshTag, setRefreshTag] = useState(0);
  const [fullGiftTJ,setFullGiftTJ] = useState<any>({});

  const columns: ProColumns<FullGift, 'promotionStatus' | 'orderActivityType' | 'orderRestrictionType'>[] = [{
    title: 'id',
    dataIndex: 'id',
  }, {
    title: '活动名称',
    dataIndex: 'name',
  }, {
    title: '活动编码',
    dataIndex: 'code',
  }, {
    title: '活动方式',
    dataIndex: 'items',
    render: (record: any, text) => {
      return <>{getEnumValueDesc('orderActivityType', record?.[0]?.orderRestriction?.activityType || '')}</>
    }
  }, {
    title: '优惠方式',
    dataIndex: 'items',
    render: (record: any, text) => {
      console.log(record)
      return <>{getEnumValueDesc('orderRestrictionType', record?.[0]?.orderRestriction?.restrictionType || '')}</>
    }
  }, {
    title: '促销活动统计',
    dataIndex: '',
    render: (text, record) => {
      return <ModalForm
        title={'促销活动统计'}
        width={500}
        trigger={<Button type="link">
          查看
        </Button>}
        onVisibleChange={(e)=> {
          if(e){
            getPromotionStatistics(record.items[0].id).then(e => {
              setFullGiftTJ(e.data);
            })
          }else{
            setFullGiftTJ({});
          }
        }}
      >
        <p>下单人数：{fullGiftTJ.customerNum}</p>
        <p>下单金额：{fullGiftTJ.totalAmount}</p>
        <p>订单数：{fullGiftTJ.orderNum}</p>
      </ModalForm>
    }
  }, {
    title: '创建时间',
    dataIndex: 'createdDate',
  }, {
    title: '开始时间',
    dataIndex: 'startTime',
  }, {
    title: '结束时间',
    dataIndex: 'endTime',
  }, {
    title: '活动状态',
    dataIndex: 'status',
    valueType: 'promotionStatus',
  }];

  const searchFields = [{
    label: '活动编码',
    name: 'code.equals',
  }, {
    label: '活动名称',
    name: 'name.equals',
  }, {
    label: '创建人',
    name: 'createdBy.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <ProFormSelect
          width={'md'} 
          name={'activityType.equals'}
          options={convertEnumToOptionType('orderActivityType')}
          placeholder="活动方式"
        />
        <ProFormSelect
          width={'md'} 
          name={'status.equals'}
          options={convertEnumToOptionType('promotionStatus')}
          placeholder="状态"
        />
        <ProFormDateTimePicker
          name="startTime.greaterThanOrEqual"
          placeholder="活动开始时间"
        />
        <ProFormDateTimePicker
          name="endTime.lessThanOrEqual"
          placeholder="活动结束时间"
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: ReactNode, record: any): ReactNode => (
    record.id && ['Active', 'NO_Active'].indexOf(record.status) > -1 &&
    <Popconfirm title={"你确定要" + (record.status === 'Active' ? '下架' : '上架') + "这个满赠活动吗？"} key="activate" onConfirm={() => {
      fullGiftEnableDisable({ id: record.id, enable: record.status === 'Active' ? false : true }).then((e: any) => {
        message.success('操作成功');
        setRefreshTag(new Date().getTime());
      }).catch((error: any) => {
        message.error(error?.response?.data?.detail);
      });
    }}>
      <Button type="link" size="small" >{record.status === 'Active' ? '下架' : '上架'}</Button>
    </Popconfirm>
  )];

  return (
    <ListViewPage<FullGift, 'promotionStatus' | 'orderActivityType' | 'orderRestrictionType'>
      columns={columns}
      hideDeleteButton
      rowActions={rowActions}
      quickFilterPanel={quickFilterPanel}
      detailPage={<FullGiftDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      refreshFlag={refreshTag}
      filters={{ 'promotionType.equals': 'FullGift' }}
      editPage={(params: any) => <FullGiftEdit id={params?.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ fullGift }: RootState) => ({
  entity: fullGift.entity,
  entities: fullGift.entities,
  loading: fullGift.loading,
  updateSuccess: fullGift.updateSuccess,
  createSuccess: fullGift.createSuccess,
  total: fullGift.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FullGiftList);