import { FC, ReactNode } from "react";
import { Form, Input, Image } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import EvaluationDetails from "./evaluation-details";
import { deleteEntity, getEntities, getEntity } from "./evaluation.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import EvaluationEdit from "./evaluation-edit";
import { Evaluation } from "model/dealer/evaluation.model";
import {
  ProFormDateTimePicker,
  ProFormSelect,
} from "@ant-design/pro-form/lib/components";
import DecryptText from "../customer/detailtables/decryptText";

export interface EvaluationListProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps {
  children?: ReactNode;
}

const EvaluationList: FC<EvaluationListProps> = (props) => {
  const columns: ProColumns<Evaluation>[] = [
    {
      title: "我的授权号",
      dataIndex: "visitCustomNo",
      width: 100,
    },
    {
      title: "我的姓名",
      dataIndex: "visitName",
      width: 100,
    },
    {
      title: "我的手机号",
      dataIndex: "visitPhone",
      width: 100,
      render:(text,record) => {
        return <>
        ******<DecryptText title='手机号' id={record.id} data={record.visitPhone} />
        </>
      }
    },
    {
      title: "所属商贸公司",
      dataIndex: "visitAffiliatedCompany",
      width: 120,
      render: (text, record) => {
        const arr = record.visitAffiliatedCompany
          ? record.visitAffiliatedCompany.split(",")
          : [];
        return (
          <>
            {arr.map((e) => (
              <p style={{ marginBottom: "10px" }}>{e}</p>
            ))}
          </>
        );
      },
    },
    {
      title: "对方授权号",
      dataIndex: "visitedCustomNo",
      width: 100,
    },
    {
      title: "对方姓名",
      dataIndex: "visitedName",
      width: 100,
    },
    {
      title: "对方手机号",
      dataIndex: "visitedPhone",
      width: 100,
      render:(text,record) => {
        return <>
        ******<DecryptText title='手机号' id={record.id} data={record.visitedPhone} />
        </>
      }
    },
    {
      title: "对方所属商贸公司",
      dataIndex: "visitedAffiliatedCompany",
      width: 130,
      render: (text, record) => {
        const arr = record.visitedAffiliatedCompany
          ? record.visitedAffiliatedCompany.split(",")
          : [];
        return (
          <>
            {arr.map((e) => (
              <p style={{ marginBottom: "10px" }}>{e}</p>
            ))}
          </>
        );
      },
    },
    {
      title: "对方是否有无门店",
      dataIndex: "isVisitedStore",
      width: 120,
      render: (text, record) => {
        return record.isVisitedStore === "0" ? "否" : "是";
      },
    },
    {
      title: "拜访地址",
      dataIndex: "access",
      width: 100,
    },
    {
      title: "拜访总结",
      dataIndex: "remark",
      ellipsis: true,
      width: 100,
    },
    {
      title: "门头照",
      dataIndex: "visitedImg",
      width: 100,
      render: (text, record) => {
        return <Image src={record.visitedImg} width={80} />;
      },
    },
    {
      title: "拜访时间",
      dataIndex: "visitTime",
      width: 100,
    },
  ];

  const searchFields = [
    {
      label: "我的授权号",
      name: "visitCustomNo.equals",
    },
    // {
    //   label: '我的姓名',
    //   name: 'visitName.equals',
    // },
    // {
    //   label: '我的手机号',
    //   name: 'visitPhone.equals',
    // },
    {
      label: "拜访人授权号",
      name: "visitedCustomNo.equals",
    },
    // {
    //   label: '拜访人姓名',
    //   name: 'visitedName.equals',
    // },
    // {
    //   label: '对方手机号',
    //   name: 'visitedPhone.equals',
    // }
  ];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          name="isVisitedStore.equals"
          options={[
            {
              value: "0",
              label: "否",
            },
            {
              value: "1",
              label: "是",
            },
          ]}
          placeholder="对方是否有无门店"
        />
        <div className="wd10"></div>
        <SearchBox fields={searchFields} />
        <ProFormDateTimePicker
          name={"visitTime.greaterThanOrEqual"}
          placeholder="拜访开始时间"
        />
        <ProFormDateTimePicker
          name={"visitTime.lessThanOrEqual"}
          placeholder="拜访结束时间"
        />
      </Input.Group>
    </Form.Item>
  );

  const exportData = (data: Evaluation[]) => {
    let entities: Evaluation[] = [];
    data.forEach((e) => {
      let arr = {
        ...e,
        isVisitedStore: e.isVisitedStore === "0" ? "否" : "是",
      };
      entities.push(arr);
    });
    return entities;
  };

  return (
    <ListViewPage<Evaluation>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={
        <EvaluationDetails navigate={props.navigate} columns={columns} />
      }
      detailsIndex={false}
      hideActionColumn
      hideCreateButton
      showDownload
      downloadAllEntities={exportData}
      editPage={(params: any) => (
        <EvaluationEdit id={params.id} text={params.text} />
      )}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ evaluation }: RootState) => ({
  entity: evaluation.entity,
  entities: evaluation.entities,
  loading: evaluation.loading,
  updateSuccess: evaluation.updateSuccess,
  createSuccess: evaluation.createSuccess,
  total: evaluation.total,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationList);
