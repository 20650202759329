import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity, fundingList, exchangeAmount, fetchOverseasInfo } from './customer.reducer';
import { connect } from 'react-redux';
import { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import { Customer } from "model/dealer/customer.model";
import { Col, Row, Tabs, message, Switch, Spin } from 'antd';
import AuthCertificate from './detailtables/authCertificate'
import Userinfo from './detailtables/userinfo'
import SigntheContract from './detailtables/signtheContract'
import { Image } from "antd";
import { ProFormSelect } from "@ant-design/pro-form";
import { useParamGeneral } from "components/useParamGenera";
import { addAccount, cancelAccount, getPaymentAuthWhitelist } from "../dealerInfo/dealerInfo.reducer";
import DecryptText from "./detailtables/decryptText";
import { fetchLevelList } from "../unit/unit.reducer";

export interface CustomerDetailsProps extends DetailsStateAndDispatchProps<Customer>,
  RouteComponentProps, StateProps, DispatchProps {
  row?: any;
}

const CustomerDetails: FC<CustomerDetailsProps> = (props: any) => {
  const { entity, getEntity } = props;
  let [detailData, setDetailData] = useState<any>({});
  let [exchangeAmountObj, setExchangeAmountObj] = useState<any>({});
  let [stockRotation, setStockRotation] = useState<any>('1');
  let [relationshipList, setRelationshipList] = useState<any>([]);
  let [tabIndex, setTabIndex] = useState<any>(1);
  let [fundingData, setFundingData] = useState<any>([]);
  let [compulsoryCertification, setCompulsoryCertification] = useState<any>(false);
  let [levelList, setLevelList] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);
  const { currency } = useParamGeneral();

  useEffect(() => {
    if (!!entity.id && entity.account) {
      setExchangeAmountObj({});
      setStockRotation('1');
      getLevelList()
      exchangeAmount({
        account: entity.account,
        storeId: '1',
      }).then((e: any) => {
        console.log(e)
        setExchangeAmountObj(e);
      }).catch((error: any) => {
        message.error(error?.response?.data?.detail);
      });
    };
  }, [entity]);

    // 获取等级列表
    const getLevelList = async () => {
      const unitRequest = await fetchLevelList();
      setLevelList(unitRequest)
    }

  const getOverseasInfo = () => {
    fetchOverseasInfo({
      id: entity.id,
      isOverseas: !entity.isOverseas,
    }).then(e => {
      message.success('操作成功')
      getEntity(entity.id);
    })
  }

  const [tabs] = useState<any>([{
    key: 1,
    label: '用户信息',
  }, 
  {
    key: 2,
    label: '签约合同',
  }, {
    key: 3,
    label: '授权证书',
  }
])
  // 切换
  const onChange = (data: any) => {
    setTabIndex(data)
  }

  useEffect(() => {
    if (props.entity.id) {
      console.log(props.entity)
      setDetailData(props.entity)
      setRelationshipList(props.entity?.relationshipList)
      getFundingList(props.entity.userId)
      getBrandList(props.entity.account)
    }
  }, [props]);

  


  // 获取客户资金列表
  const getFundingList = (userId: any) => {
    fundingList({
      userId: userId
    }).then((e: any) => {
      console.log(e)
      setFundingData(e)
    })
  }


  const renderFn = () => {
    if (tabIndex === 1 && detailData) {
      return <Userinfo data={detailData} />
    } 
    else if (tabIndex === 2 && detailData) {
      return <SigntheContract data={detailData} levelList={levelList} key={Math.random()} />
    }else if (tabIndex === 3 && detailData) {
      return <AuthCertificate data={detailData}  key={Math.random()}  />
    }
  }

  // 获取品牌列表
  const getBrandList = (account: any) => {
    setLoading(true)
    getPaymentAuthWhitelist({
      id: account
    }).then((e) => {
      let array = e
      var typeList = Array.from(array, ({ type }) => type);
      if (typeList.length > 0) {
        setCompulsoryCertification(typeList.includes('3'))
        setLoading(false)
      }
    }).catch((e) => {
      setLoading(false)
    }).finally (() => {
      setLoading(false)
    })
  }

  //是否开启6s强制认证
  const chagessq = (caozuo: any) => {
    if (caozuo) {
      addAccount({
        code: detailData?.account,
        type: 3
      }).then((e) => {
        message.success(`6s强制认证已开启`);
      }).catch((e) => {
        message.error(e);
      })
    } else {
      cancelAccount({
        code: detailData?.account,
        type: 3
      }).then((e) => {
        message.error(`6s强制认证已关闭`);
      }).catch((e) => {

      })
    }
  }



  return (
    <Spin spinning={loading} delay={300}>
      <Row className="mbtm20">
        <Col span='24' className="data-row">
          <Image src={detailData.avatar ? detailData.avatar : 'https://images-test.merrige.cn/public/avatar/default.png'} fallback='https://images-test.merrige.cn/public/avatar/default.png' alt="" className="avator"></Image>
          <div className="row-column">
            <div className="row-section">
              <div className="row" style={{ fontWeight: 600 }}>{detailData?.name}</div>
              <div className="row">
                <div className="leveBox">
                  {
                    relationshipList?.map((item: any) => {
                      return <div className="leveItem" style={{ backgroundColor: item.storeShortName === '黑' ? 'rgb(60, 61, 60)' : item.storeShortName === '美' ? 'rgb(197, 76, 59)' : item.storeShortName === '朱' ? 'rgb(230, 213, 176)' : item.storeShortName === '康' ? 'rgb(146, 166, 200)' : 'rgb(60, 61, 60)' }}>
                        <div className="levelName">
                          {item.storeShortName === '美' ?
                            <img alt='' className="leveImg" width={'12px'} src="https://images.merrige.cn/public/log/merrige.png"></img> :
                            item.storeShortName === '黑' ? <img alt='' className="leveImg" width={'12px'} src="https://images.merrige.cn/public/log/beswan.png"></img> :
                              item.storeShortName === '朱' ? <img alt='' className="leveImg" width={'12px'} src="https://images.merrige.cn/public/log/bq.png"></img> :
                                item.storeShortName === '康' ? <img alt='' className="leveImg" width={'12px'} src="https://images.merrige.cn/public/log/zhongkang.png"></img> : ''
                          }
                          <text style={{ marginLeft: '6px' }}>{item.levelShortName}</text>
                        </div>
                      </div>
                    })
                  }
                </div>

              </div>
              <div className="isqz">
                <div className="title-name">海外</div>
                <Switch checked={entity.isOverseas}
                  onChange={(e) => {
                    getOverseasInfo();
                  }}
                  checkedChildren={'是'}
                  unCheckedChildren={'否'}
                />
              </div>
              <div className="isqz">
                <div className="title-name">6s强制认证(白名单)</div>
                <Switch checked={compulsoryCertification}
                  onChange={(e) => {
                    setCompulsoryCertification(e)
                    chagessq(e)
                  }}
                  checkedChildren={'关闭'}
                  unCheckedChildren={'打开'}
                />
              </div>
            </div>
            <div className="row">授权号： {detailData?.account} <span style={{ marginLeft: '6px' }}>手机号：******<DecryptText title='手机号' id={detailData.id} data={detailData.phone} /></span> <span style={{ marginLeft: '6px' }}>ID: {detailData.id}</span></div>
          </div>
        </Col>
      </Row>
      <Row className="data-statistics">
        {
          fundingData?.map((item: any) => {
            return <div className="item">
              <div className="data-name">{item.accountTypeName}</div>
              <div className="data-count">{currency?.symbol} {item.availableAmount || 0}</div>
            </div>
          })
        }
      </Row>
      {entity.account && <><ProFormSelect
        width={300}
        options={[{
          label: '美人计换货额度明细',
          value: '1',
        }, {
          label: '黑天鹅换货额度明细',
          value: '2',
        }]}
        label="换货额度"
        placeholder="换货额度"
        fieldProps={{
          value: stockRotation,
          onChange: (e) => {
            if (e) {
              exchangeAmount({
                account: entity.account,
                storeId: e,
              }).then((data: any) => {
                setExchangeAmountObj(data);
                setStockRotation(e);
                message.success('查询成功');
              }).catch((error: any) => {
                message.error(error?.response?.data?.detail);
              });
            } else {
              message.info('请选择品牌');
            }
          }
        }}
      />
        <Row className="data-statistics">
          <div className="item">
            <div className="data-name">新系统换货额度</div>
            <div className="data-count">{currency?.symbol} {exchangeAmountObj.orderAmount || 0}</div>
          </div>
          <div className="item">
            <div className="data-name">老系统结余额度</div>
            <div className="data-count">{currency?.symbol} {exchangeAmountObj.surplusAmount || 0}</div>
          </div>
          <div className="item">
            <div className="data-name">已换货额度</div>
            <div className="data-count">{currency?.symbol} {exchangeAmountObj.exchangeOrderAmount || 0}</div>
          </div>
          <div className="item">
            <div className="data-name">目前结余额度</div>
            <div className="data-count">{currency?.symbol} {exchangeAmountObj.exchangeAmount || 0}</div>
          </div>
        </Row>
      </>}
      <Tabs defaultActiveKey={tabIndex} items={tabs} onChange={onChange} />
      {
        renderFn()
      }
    </Spin>)
};

const mapStateToProps = ({ dealerCustomer }: RootState) => ({
  entity: dealerCustomer.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomerDetails);
