import axios from 'axios';
import { TrainList, defaultTrainList } from 'model/train/trainList.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'TrainList';
const apiUrl = '/api/yumei-mall-train/meet';
const standardReducerImpl = new StandardReducerImpl<TrainList>(entity, defaultTrainList, apiUrl);
export type TrainListState = StandardState<TrainList>;
export const trainList = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


// 编辑房间号
export const editeRoomNos = async(data:any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-train/check-room/room-no`, data);
    return axiosResponse.data || [];
}


// 查询培训会列表
export const fetchTrainList = async (params?: any) => {
    const requestUrl = apiUrl;
    const axiosResponse = await axios.get<any>(requestUrl, { params: { ...params } });
    return axiosResponse.data;
}

export const fetchTrainListDetailsList = async (params: any) => {
    return await axios.get<any>(`/api/yumei-mall-train/involved-detail`, { params });
}

export const editParticipation = async (data: any) => {
    return await axios.put<any>(`/api/yumei-mall-train/involved-detail/${data.id}`, data);
}

export const addParticipation = async (data: any) => {
    delete data.id;
    return await axios.post<any>(`/api/yumei-mall-train/involved-detail`, data);
}

export const deleteParticipation = async (id: any) => {
    return await axios.delete<any>(`/api/yumei-mall-train/involved-detail/${id}`);
}

export const fetchRoomsList = async (params: any, id: any) => {
    return await axios.get<any>(`/api/yumei-mall-train/meet/room-usage/${id}`, { params });
}


export const meetingDeduction = async (data: any) => {
    return await axios.post<any>(`/api/yumei-mall-train/involved-detail/charge`, data);
}

export const getBalance = async (customerId: any) => {
    return await axios.get<any>(`/api/yumei-mall-train/involved-detail/train-amount/${customerId}`);
}

export const showHideMeeting = async (id: any, hidden: boolean) => {
    return await axios.get<any>(`/api/yumei-mall-train/meet/is-hidden/${id}?hidden=${hidden}`);
}

export const statisticsTrain = async (id: any) => {
    return await axios.get<any>(`/api/yumei-mall-train/meet/stat/${id}`);
}







