import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import TrainListEdit from './materialDown-edit';
import TrainListList from './materialDown-list';

const TrainList: React.FC<RouteComponentProps> = () => (
  <Router>
    <TrainListList path="/" />
    <TrainListEdit path=":id/edit" />
    <TrainListEdit path="new" />
  </Router>
);
export default TrainList;
