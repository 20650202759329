import axios from 'axios';
import { defaultFullGift, FullGift } from 'model/promotion/fullGift.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'FullGift';
const apiUrl = '/api/yumei-mall-promotion/promotion';
const standardReducerImpl = new StandardReducerImpl<FullGift>(entity, defaultFullGift, apiUrl);
export type FullGiftState = StandardState<FullGift>;
export const fullGift = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


export const fullGiftEnableDisable = async (params: any) => {
    return await axios.put<any>(`/api/yumei-mall-promotion/promotion/enable/${params.id}?enable=${params.enable}`);
};
export const getPromotionStatistics = async (activityId: any) => {
    return await axios.get<any>(`/api/yumei-mall-purchase/purchase-order/promotion-statistics/${activityId}`);
};

