import { CarryOutOutlined, DashOutlined } from '@ant-design/icons';
import { Tree, Dropdown, Menu, Modal, message } from 'antd';
import { useState } from 'react';
import OperationGroupDialog from './operationGroupDialog';
import { editePwd } from 'pages/customerCore/customer/customer.reducer';
const { confirm } = Modal;

const TreeMenu = () => {
    const [selectNode, setSelectNode]: any = useState();
    const [operationType, setOperationType]: any = useState();
    let [editePwdDialogOpen, setEditePwdDialogOpen] = useState<any>(false);

    // tree数据
    const treeData: any[] = [
        {
            key: 1,
            title: '节点1',
            icon: <CarryOutOutlined />,
            type: 1,
            children: [
                { key: 2, title: '节点1-1', icon: <CarryOutOutlined />, },
                { key: 3, title: '节点1-2', icon: <CarryOutOutlined />, },
            ],
        },
        {
            key: 4,
            title: '节点2',
            icon: <CarryOutOutlined />,
            type: 1
        },
    ];



    // 点击菜单
    const onSelectMenu = (item: any) => {
        console.log(item)
        console.log(selectNode)
        if (item.key === 'delete') {
            confirm({
                title: `确定删除${selectNode.title}吗？`,
                onOk() {

                }
            });
        } else {
            setOperationType(item.key)
            setEditePwdDialogOpen(true);
        }
    }

    // 一级操作
    const parentMenu = (
        <Menu
            onClick={onSelectMenu}
            items={[
                {
                    key: 'add',
                    label: '添加分组',
                },
                {
                    key: 'update',
                    label: <span>编辑分组</span>,
                },
                {
                    key: 'delete',
                    label: <span>删除</span>,
                }
            ]}
        />
    );

    // 二级操作
    const menu = (
        <Menu
            onClick={onSelectMenu}
            items={[
                {
                    key: 'update',
                    label: <span>编辑分组</span>,
                },
                {
                    key: 'delete',
                    label: <span>删除</span>,
                },

            ]}
        />
    );

    const titleRender = (nodeData: any) => {
        return (
            <div className='node-row'>
                <div>{nodeData.title}</div>
                <Dropdown overlay={nodeData.type === 1 ? parentMenu : menu} trigger={['contextMenu']} onOpenChange={() => setSelectNode(nodeData)}>
                    <DashOutlined />
                </Dropdown >
            </div>
        );
    };

    return (
        <div className='treeMenu-wrapper'>
            <Tree
                showIcon={true}
                rootStyle={{ fontSize: '16px', padding: '0px 20px 0px 20px' }}
                autoExpandParent={true}
                defaultExpandAll
                titleRender={titleRender}
                treeData={treeData}
                itemHeight={60}
            />
            <div className='menufooter'>
                <div className='addBtn' onClick={()=>{
                    setEditePwdDialogOpen(true)
                    setOperationType('add')
                }} >添加分组</div>
            </div>

            {/* 分组新增和编辑 */}
            <OperationGroupDialog visible={editePwdDialogOpen} operationType={operationType} treeData={treeData} record={selectNode} onCancel={() => setEditePwdDialogOpen(false)} onRecharge={(data: any) => {
                editePwd(data).then((e: any) => {
                    message.success(operationType === 'add' ? '添加成功' : '修改成功')
                    setEditePwdDialogOpen(false);
                }).catch((e: any) => {
                    message.error(e.response.data.error || '系统异常')
                })

            }} />
        </div>
    );
}

export default TreeMenu;