import React, { FC, useState, useEffect } from 'react';
import { AfterSales } from 'model/procure/after-sales.model';
import { RouteComponentProps } from '@reach/router';
import { getEntity, updateAgree, updateRefuse, updateSign, updateRefund, buyerDelivery, revocationAfterOrder, associationReturnPackage, associationManualOrder, fetchAddressList } from './after-sales.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from 'layouts/page-wraper/details-page';
import { RootState } from 'reducers';
import { Button, Descriptions, Divider, Form, Input, Modal, Radio, Select, Space, Timeline, Typography, Image, Tooltip, message, Tag, Popconfirm } from 'antd';
import { useEnumValue } from 'common/enum/use-enum-value';
import { ModalForm, ProFormText } from '@ant-design/pro-form';
import AfterSalesNegotiate from '../components/after-sales-negotiate';
import AuthButton from 'common/auth/auth-button';
import { internationalList } from 'components/IntelFormText';
import { columns as ReturnColumns } from '../return-package/return-package-list';
import ManualOrder from '../components/manual-order';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { fetchManualOrderList, fetchReturnPackage, fetchReturnPackageList } from '../return-package/return-package.reducer';
import { columns as returnPackageColumns } from '../return-package/return-package-list';
import { ReturnPackagePimColumns } from '../return-package/return-package-pim-details';
import tstLogo from '../../../assets/logo-tst.png';
import { useParamGeneral } from 'components/useParamGenera';
import { Order, orderServiceState } from 'model/procure/order.model';
import { detailsAuditOrder } from '../purchase-order/order.reducer';
const { Title, Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

export interface AfterSalesDetailsProps extends DetailsStateAndDispatchProps<AfterSales>,
  RouteComponentProps, StateProps, DispatchProps {
  id?: string;
  detailText?: string;
}

const AfterSalesDetails: FC<AfterSalesDetailsProps> = (props) => {
  // buyerDelivery
  const { id, entity, getEntity, updateAgree, updateRefuse, updateSign, revocationAfterOrder, detailText } = props;
  const [isAgree, setIsAgree] = useState(false);
  const [isRefuse, setIsRefuse] = useState(false);
  const [isRefundPostage, setIsRefundPostage] = useState(false);
  const [addressData, setAddressData] = useState([]);
  const [formIsAgree] = Form.useForm();
  const [formRefuse] = Form.useForm();
  const { getEnumValueDesc, getEnumValueList } = useEnumValue();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [manualRowKeys, setManualRowKeys] = useState([]);
  const [manualOrderData, setManualOrderData] = useState([]);
  const { currency } = useParamGeneral();

  //列表头部
  const manualOrderColumns: ProColumns<Order, 'orderStatus' | 'delivery' | 'lineStatus'>[] = [{
    title: '商品',
    dataIndex: 'picture',
    valueType: 'avatar',
    render: (text, row, index) => {
      if (!row?.lines) {
        return {
          children: <div className='query-order-name'>
            <img alt='商品图片' style={{ width: '80px', height: 'auto', marginRight: '18px' }} src={!!row.picture ? internationalList(row.picture || '') : tstLogo} />
            <div>
              <p>{internationalList(row.productName || '')}</p>
              <p>SPU编码：{row.productCode}</p>
              <p>SKU编码：{row.skuCode}</p>
              {/* <p>商品规格：{row.skuId}</p> */}
              {row?.specName?.map(v => {
                return <><p>{v.k}: {v.v}</p></>
              })}
              {row.blueStarProduct && <Tag color="green">蓝星商品</Tag>}
            </div>
          </div>
        };
      }
      return {
        children:
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Space align='center' className={'query-order-id'}>
              <span><label>订单ID：</label>{row.id}</span>
              {row.originalOrderId && <span><label>原始订单ID：</label>{row.originalOrderId}</span>}
            </Space>
            <Space align='center' className={'query-order-id'}>
              <span><label>店铺：</label>{row.sellerName}</span>
              <span><label>订单号：</label>{row.orderNo}</span>


              <span>
                <label>订单总状态：</label>{getEnumValueDesc('orderStatusLabel', row.statusLabel || '')}{getEnumValueDesc('orderStatus', row.status || '')}
              </span>
              <span><label>订单类型：</label>{getEnumValueDesc('orderType', row.type || '')}</span>
            </Space>
            <Space align='center' className={'query-order-id'}>
              <span><label>实收总额：</label>{currency?.symbol} {row.payAmount || 0.00}</span>
              <span><label>获得庭豆：</label>{row.earnPoint}</span>
            </Space>
            <Space align='center' className={'query-order-id'}>
              <span><label>下单时间：</label>{row.bookTime}</span>
              <span><label>付款时间：</label>{row.payTime}</span>
            </Space>
            <Space align='center' className={'query-order-id'}>
              <p>
                <label>收货人信息：</label>
                {row.expressType === 3 && <>
                  <span style={{ marginRight: 0 }}><Tag color="#108ee9" >商超单</Tag></span>
                  <span>{row.address?.storeAddress}{row.address?.storeName}</span>
                </>}
                {row.expressType === 1 && <>
                  <span>{row.address?.provinceName + ' '}{row.address?.cityName + ' '}{row.address?.districtName + ' '}</span>
                  <span>{row.address?.address}</span>
                </>}
                <span>{row.address?.consigneeName}</span>
                <span>{row.address?.mobile}</span>
              </p>
              {row.address?.zipCode && <p><label>邮编：</label>{row.address?.zipCode}</p>}
              <p><label>商家备注：</label>{row.sellerMsg}</p>
            </Space>
            {row.type === 'WholesaleGroupon' && <Space align='center' className={'query-order-id'}>
              <p><label>奖金分配类别：</label><span>{row.bonusCategoryCode}</span><label>奖金分配比例编号：</label><span>{row.bonusRateCode}</span></p>
            </Space>}
          </div>,
        props: {
          colSpan: 6,
        },
      } as any;
    }
  }, {
    title: '单价(元)/数量',
    dataIndex: ['quantity'],
    width: 120,
    render: (text, row, index) => {
      const obj = {
        children: currency?.symbol + ' ' + row.originalPrice + '/' + row.quantity,
        props: {},
      };
      if (row?.lines) {
        obj.props = {
          colSpan: 0
        };
      }
      return obj;
    }
  }, {
    title: '订单行状态',
    width: 120,
    dataIndex: 'status',
    valueType: 'lineStatus',
    render: (e, row) => {
      const obj = {
        children: e,
        props: {},
      };
      if (row?.lines) {
        obj.props = {
          colSpan: 0
        };
      }
      return obj;
    }
  }, {
    title: '售后',
    width: 80,
    dataIndex: 'serviceState',
    // sorter: true,
    // defaultSortOrder: 'descend',
    valueEnum: orderServiceState,
    render: (value: any, row: any, index: number) => {
      const obj = {
        children: value,
        props: {},
      };
      if (row?.lines) {
        obj.props = {
          colSpan: 0
        };
      }
      return obj;
    }
  }, {
    title: '实收金额(元)',
    dataIndex: ['payAmount'],
    width: 100,
    render: (text, row) => {
      const obj = {
        children: currency?.symbol + ' ' + row.payAmount,
        props: {},
      };
      if (row?.lines) {
        obj.props = {
          colSpan: 0
        };
      }
      return obj;
    }
  },

  // {
  //   title: '订单庭豆获得数',
  //   dataIndex: 'earnPoint',
  //   width: 120,

  // },
  {
    title: '蓝星商品',
    dataIndex: 'blueStarProduct',
    width: 100,
    render: (text, row: any) => {
      const obj = {
        children: row.blueStarProduct ? '是' : '否',
        props: {},
      };
      if (row?.lines) {
        obj.props = {
          colSpan: 0
        };
      }
      return obj;
    }
  }
  ];


  useEffect(() => {
    id && getEntity(id);
  }, [id, getEntity, detailText]);

  useEffect(() => {
    const queryAddressList = async (sellerId: number | string) => {
      setAddressData(await fetchAddressList(sellerId))
    }
    entity.sellerId && queryAddressList(entity.sellerId)
  }, [entity])

  //卖家同意
  const getAgree = async (value: any) => {
    const params = {
      ...entity,
      ...value
    }
    updateAgree(params);
    setIsAgree(false);
  }

  //卖家拒绝
  const getRefuse = (value: any) => {
    const params = {
      ...entity,
      ...value
    }
    updateRefuse(params);
    setIsRefuse(false);
  }

  // 仅退款
  // const getRefund = async() => {
  //    updateRefund(entity);
  //    updateSuccess && setIsSign(false);
  // }

  const titleStatus = getEnumValueDesc('afterSaleOrderStatus', entity.status || '');
  const afterSaleType = getEnumValueDesc('afterSaleType', entity.afterSaleType || '');

  const extraList = [
    !entity.manualOrderId && entity?.status === 'SIGN_DISTRIBUTE' && <ManualOrder type="afterSales" manualOrderGetEntity={props.getEntity} afterSaleOrderId={entity.id} entity={entity.order as any} />,
    [1, 4].indexOf(entity?.serviceState as number) > -1 &&
    <Space>
      <AuthButton authority='W_after_sale_order_audit' type='primary' onClick={() => setIsAgree(true)}>同意</AuthButton>
      <AuthButton authority='W_after_sale_order_audit' type='primary' onClick={() => setIsRefuse(true)}>拒绝</AuthButton>
    </Space>,
    // 签收确认
    entity?.serviceState === 6 &&
    <ModalForm
      width='460px'
      trigger={<AuthButton authority='W_after_sale_order_audit' type='primary'>签收确认</AuthButton>}
      onFinish={async () => {
        updateSign(entity);
        return true
      }}
    >
      确定签收售后编号为：<Text type='danger'>{entity.serviceNo}</Text> 的订单吗？<br />
      <ProFormText hidden={true} />
    </ModalForm>,
    // 售后撤销-退款成功/商家撤销
    [22, 100, 40].indexOf(entity?.serviceState as number) === -1 &&
    <ModalForm
      width='460px'
      trigger={<AuthButton authority='W_after_sale_order_revoke' type='primary'>售后撤销</AuthButton>}
      onFinish={async () => {
        revocationAfterOrder(entity);
        return true
      }}
    >
      确定售后撤销编号为：<Text type='danger'>{entity.serviceNo}</Text> 的订单吗？<br />
      <Text type='warning'>注意：撤销成功后，对应的退款申请单也会终止</Text>
      <ProFormText hidden={true} />
    </ModalForm>,
    [100, 102, 40].indexOf(entity?.serviceState || 0) === -1 && <ModalForm
      width='1000px'
      title="关联退回包裹登记单"
      trigger={<AuthButton authority='W_after_sale_order_revoke' type='primary'>关联退回包裹登记单</AuthButton>}
      onFinish={async () => {
        return new Promise<any>((resolve, reject) => {
          associationReturnPackage(entity.id || '', selectedRowKeys).then(e => {
            if (e === true) {
              message.success('关联成功');
              props.getEntity(entity.id);
              resolve(true);
            } else {
              message.error('操作失败');
              reject();
            }
          }).catch((e: any) => {
            message.error(e?.response?.data?.message || '操作失败');
            reject();
          });
        })
      }}
    >
      <ProTable
        className="express-table"
        columns={ReturnColumns.concat([{
          title: '操作',
          render: (text, record) => {
            return <ModalForm
              title="商品明细"
              width={1000}
              trigger={<Tooltip title={'商品明细'} key="sku"><Button type="link" size="small">明细</Button></Tooltip>}
              preserve={false}
              onFinish={async (data) => {
                return true;
              }}
            >
              <ProTable<any>
                className="express-table"
                columns={ReturnPackagePimColumns}
                rowKey="id"
                dataSource={record.packageProductDetails}
                pagination={false}
                options={false}
                search={false}
              />
            </ModalForm>
          }
        }]).map(e => ({ ...e, search: false }) as any).concat([{
          title: '退货原始单号',
          dataIndex: 'orderSn.equals',
          hideInTable: true,
        }, {
          title: '运单号',
          dataIndex: 'expressNo.equals',
          hideInTable: true,
        }] as any)}
        rowKey="id"
        rowSelection={{
          type: 'checkbox'
        }}
        scroll={{ x: 1000 }}
        tableAlertOptionRender={(res: any) => {
          setSelectedRowKeys(res.selectedRowKeys);
          return [];
        }}
        request={async (params) => {
          let res = await fetchReturnPackageList({
            sort: 'lastModifiedDate,desc',
            'orderSn.equals': entity.orderNo,
            'cancel.equals': false,
            ...params,
            page: params.current ? params.current - 1 : 0
          });
          return {
            success: true,
            total: res.headers['x-total-count'],
            data: res.data || [],
          };
        }}
        options={false}
        search={{
          filterType: 'light',
        }}
      />
    </ModalForm>,
    !entity.manualOrderId && entity?.status === 'SIGN_DISTRIBUTE' && ['RETRUN_MONEY', 'RETURN_GOODS_AND_MONEY'].indexOf(entity?.afterSaleType || '') === -1 && <ModalForm
      width='1000px'
      title="关联手工单"
      trigger={<AuthButton authority='W_after_sale_order_revoke' type='primary'>关联手工单</AuthButton>}
      onFinish={async () => {
        return new Promise<any>((resolve, reject) => {
          associationManualOrder(entity.id || '', manualRowKeys[0]).then(e => {
            if (e === true) {
              message.success('关联成功');
              resolve(true);
              props.getEntity(entity.id);
            } else {
              message.error('操作失败');
              reject();
            }
          }).catch((e: any) => {
            message.error(e?.response?.data?.message || '操作失败');
            reject();
          });
        })
      }}
    >
      <ProTable
        className="express-table"
        columns={manualOrderColumns.map(e => ({ ...e, search: false }) as any).concat([{
          title: '订单号',
          dataIndex: 'orderNo.equals',
          hideInTable: true,
        }, {
          title: '订单id',
          dataIndex: 'id.equals',
          hideInTable: true,
        }] as any)}
        rowKey="id"
        rowSelection={{
          type: 'radio',
        }}
        tableAlertOptionRender={(res: any) => { 
          setManualRowKeys(res.selectedRowKeys);
          return [];
        }}
        request={async (params) => {
          let res = await fetchManualOrderList({
            sort: 'lastModifiedDate,desc',
            ...params,
            page: params.current ? params.current - 1 : 0,
            'originalOrderId.equals': entity?.order?.id || '',
            'orderState.notEquals': '99',
          });
          setManualOrderData(res.data || []);
          return {
            success: true,
            total: res.headers['x-total-count'],
            data: res.data || [],
          };
        }}
        expandable={{
          // expandedRowClassName: 'order-lines',
          childrenColumnName: 'lines',
          expandedRowKeys: manualOrderData.map((e: any) => e.id),
          expandIconColumnIndex: -1,
        }}
        options={false}
        search={{
          filterType: 'light',
        }}
      />
    </ModalForm>,
    entity.manualOrderId && ['RETRUN_MONEY', 'RETURN_GOODS_AND_MONEY'].indexOf(entity?.afterSaleType || '') === -1 && 
    ['SELLER_REVOKE'].indexOf(entity?.status || '') === -1
    && entity.manualOrderState === 30 && <Popconfirm title="你确定要审核吗？" key="activate"
      onConfirm={() => {
        detailsAuditOrder({ Ids: [entity.manualOrderId], orderState: 'SHIPPING' }).then((e) => {
          message.success('审核成功');
          props.getEntity(entity.id);
        }).catch(e => {
          message.error(e.response.data.message);
        });
      }}
    >
      <Button type='primary'>手工单审核</Button>
    </Popconfirm>


    // 退款
    // [2, 7, 21].indexOf(entity?.serviceState || 0) > -1 &&
    //   <ModalForm
    //     width='460px'
    //     trigger={<AuthButton authority='W_after_sale_order_refund' type='primary'>退款</AuthButton>}
    //     onFinish={async (val) => {
    //       props.updateRefund({ ...entity, ...val });
    //       return true
    //     }}
    //   >
    //     <ProFormSwitch name="offLineRefund"
    //       label="是否线下退款"
    //       width='sm'
    //       initialValue={false}
    //       rules={[{ required: true }]}
    //       fieldProps={{
    //         checkedChildren: '是',
    //         unCheckedChildren: '否'
    //       }}
    //     />
    //     确定退款售后编号为：<Text type='danger'>{entity.serviceNo}</Text> 的订单吗？
    //   </ModalForm>
  ]

  return (
    <DetailsPage<AfterSales>
      title='售后详情'
      hideEdit={true}
      extra={entity && extraList}
      {...props}
    >
      <Descriptions column={1}>
        <Descriptions.Item>
          {/* 商家显示 平台需要判断 */}
          <Title level={4}><Text type="warning">{titleStatus.replace("{}", afterSaleType)}</Text></Title>
        </Descriptions.Item>
      </Descriptions>
      <Descriptions column={3}>
        <Descriptions.Item label="售后编号"><Text type='danger'>{entity.serviceNo}</Text></Descriptions.Item>
        {['CHANGE_GOODS', 'MODIFY_GOODS'].indexOf(entity.afterSaleType || '') === -1 && <Descriptions.Item label="退款金额"><Text type='danger'>{entity.refundAmount}</Text></Descriptions.Item>}
        <Descriptions.Item label="退积分"><Text type='danger'>{entity.refundPoint}</Text></Descriptions.Item>
        <Descriptions.Item label="买家ID"><Text>{entity.order?.buyerId}</Text></Descriptions.Item>
        <Descriptions.Item label="售后方式"><Text>{afterSaleType}</Text></Descriptions.Item>
        <Descriptions.Item label="申请时间"><Text>{entity.initiationTime}</Text></Descriptions.Item>
        <Descriptions.Item label="退款件数"><Text>{entity.quantity}</Text></Descriptions.Item>
        <Descriptions.Item label="退款方式"><Text>{entity.offLineRefund ? '线下' : '线上'}</Text></Descriptions.Item>
        <Descriptions.Item label="联系方式">{entity.contactMobile ? entity.contactMobile : entity.order?.address?.mobile}</Descriptions.Item>
        <Descriptions.Item label="退款原因">{entity.refundReasonDesc}</Descriptions.Item>
        <Descriptions.Item label="退款说明">{entity.refundExplain}</Descriptions.Item>
        {entity.manualOrderId && <Descriptions.Item label="手工单ID">
          <ModalForm
            width='1000px'
            title="手工单详情"
            trigger={<Button style={{ 'marginTop': '-5px' }} size="small" type='link'>{entity.manualOrderId}</Button>}
            onFinish={async () => {
              return true
            }}
          >
            <ProTable
              className="express-table"
              columns={manualOrderColumns.map(e => ({ ...e, search: false }) as any)}
              rowKey="id"
              request={async (params) => {
                let res = await fetchManualOrderList({
                  sort: 'lastModifiedDate,desc',
                  'id.equals': entity.manualOrderId,
                  ...params,
                  page: params.current ? params.current - 1 : 0
                });
                setManualOrderData(res.data || []);
                return {
                  success: true,
                  total: res.headers['x-total-count'],
                  data: res.data || [],
                };
              }}
              options={false}
              search={false}
              expandable={{
                // expandedRowClassName: 'order-lines',
                childrenColumnName: 'lines',
                expandedRowKeys: manualOrderData.map((e: any) => e.id),
                expandIconColumnIndex: -1,
              }}
            />
          </ModalForm>
        </Descriptions.Item>}
      </Descriptions>
      <p>
        <ModalForm
          width='1000px'
          title="包裹登记单"
          trigger={<Button style={{ 'marginTop': '-5px' }} >已关联包裹登记单</Button>}
          onFinish={async () => {
            return true
          }}
        >
          <ProTable
            className="express-table"
            columns={returnPackageColumns.concat([{
              title: '操作',
              width: 100,
              render: (text, record) => {
                return <ModalForm
                  title="商品明细"
                  width={1000}
                  trigger={<Tooltip title={'商品明细'} key="sku"><Button type="link" size="small">明细</Button></Tooltip>}
                  preserve={false}
                  onFinish={async (data) => {
                    return true;
                  }}
                >
                  <ProTable<any>
                    className="express-table"
                    columns={ReturnPackagePimColumns}
                    rowKey="id"
                    dataSource={record.packageProductDetails}
                    pagination={false}
                    options={false}
                    search={false}
                  />
                </ModalForm>
              }
            }])}
            rowKey="id"
            request={async (params) => { 
              let res = await fetchReturnPackage({
                sort: 'lastModifiedDate,desc',
                ...params,
                'id': entity.id,
                page: params.current ? params.current - 1 : 0
              }); 
              setManualOrderData(res.data || []);
              return {
                success: true,
                total: res.headers['x-total-count'],
                data: res.data || [],
              };
            }}
            scroll={{ x: 1000 }}
            options={false}
            search={false}
            expandable={{
              // expandedRowClassName: 'order-lines',
              childrenColumnName: 'lines',
              expandedRowKeys: manualOrderData.map((e: any) => e.id),
              expandIconColumnIndex: -1,
            }}
          />
        </ModalForm>
      </p>
      {/*  平台端显示提醒内容 */}
      {
        props.isTenantRole &&
        <Space direction='vertical'>
          <Text type='secondary'>如同意退款，退款金额原路返回</Text>
        </Space>
      }
      <Divider />
      <Space direction='vertical' align='start'>
        <Descriptions title="售后商品" column={1}>
          <Descriptions.Item>
            <div className='After-sale-goods'>
              <Image alt='图片' style={{ width: '80px' }} src={internationalList(entity.orderLine?.picture || '')} />
              <span className='After-sale-goods-name'>{internationalList(entity.orderLine?.productName || '')}</span>
            </div>
          </Descriptions.Item>
        </Descriptions>
        <Descriptions title="购买信息" column={3}>
          <Descriptions.Item label="订单ID"><Text type='warning'>{entity.order?.id}</Text></Descriptions.Item>
          <Descriptions.Item label="订单编号"><Text type='warning'>{entity.order?.orderNo}</Text></Descriptions.Item>
          <Descriptions.Item label="商品单价"><Text type='danger'>{entity.orderLine?.price}</Text></Descriptions.Item>
          <Descriptions.Item label="实付金额"><Text type='danger'>{entity.orderLine?.payAmount}</Text></Descriptions.Item>
          <Descriptions.Item label="购买件数">{entity.orderLine?.quantity}</Descriptions.Item>
          <Descriptions.Item label="发货状态">{entity.status && getEnumValueList('afterSaleOrderStatus').filter(e => e.name === entity.status)[0]?.desc}</Descriptions.Item>
          <Descriptions.Item label="网店">{entity.order?.sellerName}</Descriptions.Item>
        </Descriptions>
      </Space>
      <Divider />
      <Space direction='vertical' size='large'>
        <Space direction='vertical' size='large'>
          <p style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Title level={3}>协商经过</Title>
            {/* {(entity?.status && ['AGREE_EXCHANGE', 'AGREE_REPAIR', 'AGREE_DISTRIBUTE'].indexOf(entity.status) !== -1) && entity.afterSaleType !== 'CHANGE_GOODS' &&
              // "商家同意换货待买家发货"  "商家同意维修待买家发货"  "商家同意退款退货待买家发货"
              <ModalForm
                title={'买家发货信息'}
                trigger={<Button icon={<EditOutlined />}>买家发货信息</Button>}
                modalProps={{
                  destroyOnClose: true,
                }}
                preserve={false}
                // onVisibleChange={visible => console.log()}
                onFinish={async (data) => {
                  buyerDelivery({ id: entity?.id, customerId: entity?.order?.id, ...data });
                  return true;
                }}
              >
                <ProFormText
                  name="expressName"
                  label="物流公司"
                  rules={[{ required: true }]}
                />
                <ProFormText
                  name="expressNo"
                  label="物流单号"
                  rules={[{ required: true }]}
                />
                <ProFormTextArea
                  name="expressRemark"
                  label="物流说明"
                />
              </ModalForm>
            } */}
          </p>
          <Timeline mode='left'>
            <AfterSalesNegotiate entity={entity} isTenantRole={props.isTenantRole || false} />
          </Timeline>
        </Space>
      </Space>
      {/*同意 */}
      <Modal
        title='商家同意'
        visible={isAgree}
        onCancel={() => setIsAgree(false)}
        onOk={() => {
          formIsAgree.validateFields().then(values => {
            formIsAgree.resetFields();
            getAgree(values);
          })
            .catch(info => {
              // console.log('Failed:', info);
            });
        }}
      >
        <Form
          labelAlign="right"
          form={formIsAgree}
          name="form_agree_modal"
          labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}
        >
          <Form.Item label='售后编号'>
            <Text type='danger'>{entity.serviceNo}</Text>
          </Form.Item>
          <Form.Item
            label='退货地址'
            name='refundAddressId'
            rules={[{ required: !(entity?.afterSaleType === 'RETRUN_MONEY') }]}>
            <Select placeholder="请选择退货地址">
              {
                addressData && addressData.map((item: any) => {
                  return (
                    <Option value={item.id}>{item.mergeName} {item.address}</Option>
                  )
                })
              }
            </Select>
          </Form.Item>
          <Form.Item
            label='退运费'
            name='refundFreight'
            initialValue={false}
            rules={[{ required: true }]}>
            <Radio.Group onChange={(val) => setIsRefundPostage(val.target.value)}>
              <Radio value={false}>否</Radio>
              <Radio value={true}>是</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label='运费金额'
            name='refundFreightAmount'
            hidden={!isRefundPostage}
            rules={[{ required: isRefundPostage }]}>
            <Input placeholder='请输入运费金额' />
          </Form.Item>
        </Form>
      </Modal>
      {/* 拒绝 */}
      <Modal
        title='商家拒绝'
        visible={isRefuse}
        onCancel={() => setIsRefuse(false)}
        onOk={() => {
          formRefuse.validateFields().then(values => {
            formRefuse.resetFields();
            getRefuse(values);
          })
            .catch(info => {
              // console.log('Failed:', info);
            });
        }}
      >
        <Form
          labelAlign="right"
          form={formRefuse}
          name="form_refuse_modal"
          labelCol={{ span: 4 }} wrapperCol={{ span: 18 }}
        >
          <Form.Item
            label='拒绝原因'
            name='refuseReason'
            rules={[{ required: true }]}>
            <TextArea rows={4} placeholder='请输入拒绝原因' />
          </Form.Item>
        </Form>
      </Modal>
    </DetailsPage>
  );
};

const mapStateToProps = ({ afterSales, authentication }: RootState) => ({
  entity: afterSales.entity,
  updateSuccess: afterSales.updateSuccess,
  isTenantRole: authentication.account.isTenantRole
});

const mapDispatchToProps = {
  getEntity,
  updateAgree,
  updateRefuse,
  updateSign,
  updateRefund,
  buyerDelivery,
  revocationAfterOrder
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AfterSalesDetails);
