import axios from 'axios';
import { defaultWholesaleProduct, WholesaleProduct } from 'model/product/wholesale-product.model';
import { CrudUploadAction } from 'reducers/redux-action.type';
import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';

const entity = 'wholesale';
const apiUrl = '/api/yumei-mall-pim/shop/product';
const standardReducerImpl = new StandardReducerImpl<WholesaleProduct>(entity, defaultWholesaleProduct, apiUrl);
export type WholesaleProductState = StandardState<WholesaleProduct>;
export const wholesaleProduct = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

//批量新增
export const batchProduct: CrudUploadAction<WholesaleProduct> = (data: any) => async dispatch => {
    const requestUrl = `/api/yumei-mall-pim/shop/product/batch`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.post(requestUrl, data),
    });
};