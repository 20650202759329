import { FC, ReactNode, useEffect, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity } from "./marginRules.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import MarginRulesEdit from "./marginRules-edit";
import { marginRulesModel } from "model/store/marginRules.model";
import { fetchStoreList } from "pages/store/store/store.reducer";
import { Tag } from "antd";

export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const MarginRulesList: FC<LevelListProps> = (props) => {

  const [storeList, setStoreList] = useState([]);

  // 获取店铺列表
  const getStoreList = () => {
    fetchStoreList().then((e: any) => {
      setStoreList(e)
    })
  }

  useEffect(() => {
    getStoreList()
  }, [])

  const columns: ProColumns<marginRulesModel>[] = [{
    title: '规则名称',
    dataIndex: 'name'
  },
  {
    title: '店铺',
    dataIndex: 'storeId',
    width: 250,
    render: (dom, record: any) => {
      let list: any = []
      record.stores.forEach((item: any) => {
        storeList.forEach((sonItem: any) => {
          if (item === sonItem.value) {
            list.push(sonItem?.label)
          }
        })
      })
      return list?.length > 0 && list.map((item: any) => {
        return <Tag color="#1890FF" style={{ marginBottom: '2px' }}>
         {item}
        </Tag>
      })
    }
  }
];


  return (
    <ListViewPage<marginRulesModel>
      columns={columns}
      detailsIndex={false}
      editPage={(params: any) => <MarginRulesEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ marginRules }: RootState) => ({
  entity: marginRules.entity,
  entities: marginRules.entities,
  loading: marginRules.loading,
  updateSuccess: marginRules.updateSuccess,
  createSuccess: marginRules.createSuccess,
  total: marginRules.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MarginRulesList);