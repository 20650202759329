import { BaseModel } from 'model/base.model';


/**
 * 订单信息
 */
export interface Order extends BaseModel, Omit<LinesItem, 'id'> {
  acceptTime?: string;
  refundTime?: string;
  expressType?: number;
  manualOrderId?: string;
  statusLabel?: string;
  adjustment?: number;
  bonusRateCode?: string;
  bonusCategoryCode?: string;
  originalOrderId?: number;
  manualOrderStatus?: number;
  discountByMember?: number;
  orderType?: string;
  manualOrderType?: string;
  type?: string;
  belongTstId?: string;
  belongUserId?: string;
  originalPrice?: number;
  address?: ConsigneeAddress;
  adjustmentByCashCoupon?: number;
  adjustmentByCoupon?: number;
  adjustmentByPromotion?: number;
  adminMsg?: string;
  afterSaleExpireTime?: string;
  anchorId?: string;
  autoHandleTime?: string;
  bookTime?: string;
  buyerId?: number;
  buyerUserId?: string | number;
  buyerUserCode?: string;
  buyerMsg?: string;
  closeReason?: string;
  closeReasonDesc?: string;
  closeTime?: string;
  commented?: boolean;
  consigneeUpdate?: number;
  deliveryTime?: string;
  goodsAmount?: number;
  hasChild?: boolean;
  inviterId?: number;
  lines?: LinesItem[];
  masterOrderId?: number;
  masterOrderNo?: string;
  orderNo?: string;
  orderState?: number;
  payAmount?: number;
  payInfo?: PayInfo;
  payState?: number;
  payStatus?: string;
  payTime?: string;
  postage?: number;
  receiveTime?: string;
  refundAmount?: number;
  refundContactPeople?: string;
  refundContactWay?: string;
  refundPostage?: number;
  refundState?: number;
  refundStatus?: string;
  roomId?: string;
  sellerId?: number;
  sellerMsg?: string;
  sellerName?: string;
  sharerId?: string;
  shortVideoOwnerId?: number;
  signTime?: string;
  status?: string;
  totalAmount?: number;
  tstPend?: number;
  tstPendToNormal?: number;
  unionAnchorId?: number;
  selfSeller?: boolean;
  belongTstCode?: string;
  belongTstName?: string;
  shopId?: number | string;
  shopName?: string;
  earnPoint?: number;
  packageInfos?: PackageList[];
  buyer?: any;
  seller?: any,
  payDetails?: []
}

export interface LinesItem extends BaseModel {
  actualPrice?: number;
  earnPoint?: number;
  amount?: number;
  status?: string;
  bonusName?: string;
  commented?: boolean;
  cosPrice?: number;
  discount?: number;
  discountByCashCoupon?: number;
  discountByCoupon?: number;
  discountByPromotion?: number;
  discountByPostage?: number;
  manualOrderType?: string;
  freeGift?: boolean;
  memberPrice?: number;
  payAmount?: number;
  originalPrice?: number;
  discountByMember?: number;
  picture?: string;
  postage?: number;
  price?: number;
  productCode?: string;
  productId?: string;
  productName?: string;
  quantity?: number;
  refundAmount?: number;
  refundQuantity?: number;
  returnable?: boolean;
  rewardDetails?: string;
  roomId?: string;
  serviceState?: number;
  shipped?: boolean;
  skuCode?: string;
  skuId?: number;
  specName?: SpecName[];
  subtotal?: number;
  blueStarProduct?: boolean;
}

export interface SpecName {
  k_id?: number,
  k?: string,
  v_id?: number,
  v?: string
}

export interface ConsigneeAddress {
  address?: string;
  city?: number;
  cityName?: string;
  consigneeName?: string;
  country?: number;
  countryName?: string;
  district?: number;
  districtName?: string;
  mobile?: string;
  province?: number;
  provinceName?: string;
  zipcode?: string;
  storeAddress?: string;
  storeName?: string;
  zipCode?: string;
}

export interface PayInfo {
  payCode?: string;
  payName?: string;
  payNo?: string;
  payOrderNo?: string;
  payResult?: string;
  payTypeId?: number;
  payOrderSn?: string;
}

export interface PackageList {
  edit?: boolean; // 是否编辑 0 否 1是
  expressCode?: string; // 快递code
  expressName?: string; // 快递名称
  expressNo?: string;  // 快递单号
  expressTime?: string;  // 发货时间
  expressType?: string; // 配送方式 1 快递 2 同城配送 3门店自提
  finished?: number;   // 是否完成 0否 1是
  id?: number;   // 操作记录Id
  orderNo?: string; // 订单号
  quantity?: number;   // 商品总件数
  sort?: number;   // 排序
  tstPackageInfo?: tstPackageInfo;
  suborderCode?: string; //子订单号
}

interface tstPackageInfo {
  dts?: TstPackageItem[];
  carrierCode?: string;
  deliveryDate?: string;
  deliveryStatusModifyDate?: string;
  expressNo?: string;
  id?: string;
  orderCode?: string;
  status?: string;
  suborderCode?: string;
}

interface TstPackageItem {
  qty?: string;
  skuName?: string;
  skuCode?: string;
}

export interface ProvinceCode {
  code: string;
  name?: string;
  id?: string | number;
}

/**
 * 配送方式
 */
export const expressType = {
  1: '快递',
  2: '同城配送',
  3: '门店自提',
};

// 订单类型
export const orderType = {
  0: '普通订单',
  1: '抢购',
  2: '团购',
  3: '优惠',
  4: '预售',
  5: '虚拟',
  6: '拼团'
};

// 订单状态
export const orderState = {
  1: '待付款',        //TO_BE_PAID
  4: '待发货',        //OUT_OF_STOCK
  6: '发货中',        //SHIPPING
  20: '出库中',       //EXPORTED
  5: '配送中',        //DELIVERING
  7: '已收货待评价',   //RECEIVED
  8: '已收货已评价',   //COMMENTED
  9: '已发货',        //SHIPPED
  10: '售后完成',     //RETURN_AND_REFUND
  11: '待成团',       //UN_GROUP
  97: '推送中台失败',       //PUSH_SHIP_FAILED
  98: '商家不能发货',  //UNSHIPPED
  99: '订单取消',     //CANCEL
  100: '交易完成',    //FINISHED
  101: '已过期',      //OVERDUE
}

//售后状态
export const orderServiceState = {
  0: '未售后',
  1: '售后中',
  2: '售后结束',
}

export const defaultOrder: Readonly<Order> = {
};