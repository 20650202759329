import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';
import { defaultPayeeEnterprise, PayeeEnterprise } from 'model/system/payee-enterprise.model';

const entity = 'payee-enterprise';
const apiUrl = '/api/yumei-mall-pay/payee/enterprise';
const standardReducerImpl = new StandardReducerImpl<PayeeEnterprise>(entity, defaultPayeeEnterprise, apiUrl);
export type payeeEnterpriseState = StandardState<PayeeEnterprise>;
export const payeeEnterprise = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchPayeeList = async (params: any) => {
    const Data = await axios.get(apiUrl, { params });
    return Data.data || [];
};