import { initialState, STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { FAILURE, REQUEST, SUCCESS } from "../../../reducers/action-type.util";
import {
  CrudDeleteAction,
  CrudGetAction,
  CrudGetAllAction,
  CrudPostAction,
  CrudPutAction,
  QueryParameter
} from "reducers/redux-action.type";
import axios from "axios";
import { cleanEntity } from "../../../utils/entity-utils";
import { Decorate, defaultDecorate } from 'model/decorate/examdecorate.model';

const entity = 'decorate';
const apiUrl = '/api/yumei-mall-content';
const standardReducerImpl = new StandardReducerImpl<Decorate>(entity, defaultDecorate, apiUrl);
export type ExamDecorateState = StandardState<Decorate>;
export const examDecorate = (state: ExamDecorateState = initialState(), action: any): ExamDecorateState => {
  switch (action.type) {
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH)):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE)):
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE)):
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.DELETE)):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH)):
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE)):
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE)):
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.DELETE)):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
      return {
        ...state,
        loading: false,
        entities: action.payload.data.filter((v:any) => v),
        total: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH)):
      // console.log('STANDARD_ACTION_TYPES.FETCH',STANDARD_ACTION_TYPES.FETCH)
      return {
        ...state,
        loading: false,
        entity: action.payload.data.data || {},
      };
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE)):
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE)): {
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
      };
    }
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.DELETE)): {
      const id = action.payload.config.params.id;
      const entities = state.entities.filter(value => value.id?.toString() !== id.toString());
      const total = state.total - (state.entities.length - entities.length);
      return {
        ...state,
        entities: entities,
        total: total,
        updating: false,
        updateSuccess: true,
        entity: {} as Decorate,
      };
    }
    case standardReducerImpl.actionType(STANDARD_ACTION_TYPES.RESET):
      // console.log('----reset', action.payload.entity);
      return {
        ...state,
        ...action.payload || defaultDecorate || {},
      };
    default:
      return state;
  }
};

export const getEntities: CrudGetAllAction<Decorate> = (params?: QueryParameter) => dispatch => {
  const {
    page = params?.page,
    size = params?.size,
    ...rest
  } = params || {};
  const requestUrl = `${apiUrl}/page-configuration/findPageConfigurationByPage`;
  return dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST),
    payload: axios.get<any>(requestUrl, {
      params: {
        page: page,
        size,
        sort:'lastModifiedDate,desc',
        ...rest
      }
    })
  });
};


// 获取全部考试内容
export const getAllExam = async (): Promise<any[]> => {
  const axiosResponse = await axios.get(`${apiUrl}/page-configuration/findPageConfigurationByPage`, { params: { page: 0, size: 10000 } });
  return axiosResponse.data || [];
}



export const getEntity: CrudGetAction<Decorate> =  (id)   => {
  const requestUrl = `${apiUrl}/miroPage/detail?id=${id}`;
  return  ({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH),
    payload: axios.get<any>(requestUrl),
  })
};

export const createEntity: CrudPostAction<Decorate> = entity => async dispatch => {
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
    payload: axios.post(apiUrl, cleanEntity(entity)),
  });
};

export const updateEntity: CrudPutAction<Decorate> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/${entity.id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.put(requestUrl, cleanEntity(entity)),
  });
};

export const deleteEntity: CrudDeleteAction<Decorate> = (id) => async dispatch => {
  const requestUrl = `${apiUrl}/page-configuration/delExamPageConfig`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.put(requestUrl, {
      id
    }),
  });
};

export const reset = (entity?: Decorate) => (dispatch: any) => {
  return dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.RESET),
    payload: {
      updating: false,
      updateSuccess: false,
      entity: {
        ...defaultDecorate,
        ...entity
      }
    }
  });
};