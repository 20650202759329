import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./point.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Point } from "model/product/point.model";
import { ProFormDigit, ProFormText } from "@ant-design/pro-form";
export interface PointEditProps extends EditStateAndDispatchProps<Point>, StateProps, DispatchProps, RouteComponentProps {
}

const PointEdit: FC<PointEditProps> = props => {

  return (
    <EditPage<Point>
      title="积分配置"
      {...props}
    >
      <ProFormText name="name" label="名称" />
      <ProFormDigit name="point" label="积分" />
    </EditPage>);
};

const mapStateToProps = ({ point }: RootState) => ({
  entity: point.entity,
  updateSuccess: point.updateSuccess,
  updating: point.updating,
  createSuccess: point.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PointEdit);
