import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./client.reducer";
import { ProFormDependency, ProFormDigit, ProFormGroup, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { Client } from "model/system/client.model";
import { fetchRoleList } from "../role/role.reducer";
import { Role } from "model/system/role.model";
export interface ClientEditProps extends EditStateAndDispatchProps<Client>, StateProps, DispatchProps, RouteComponentProps {
}

const ClientEdit: FC<ClientEditProps> = props => {
  const {
    ...editProps
  } = props;

  const clientUpdataEntity = async (res?: Client) => {
    return new Promise<Client>((resolve, reject) => {
      if (res) {
        if (res.scope && typeof res.scope === 'string') res.scope = res.scope.split(',');
        resolve(res);
      }
    })
  }

  return (
    <EditPage<Client>
      title="客户端信息"
      beforeUpdateEntity={clientUpdataEntity}
      {...editProps}
    >
      <ProFormGroup>
        <ProFormText
          width='md'
          name="clientId"
          label="应用ID"
          rules={[{ required: true }]}
        />
        <ProFormText
          width='md'
          name="clientSecret"
          label="应用密钥"
          rules={[{ required: true }]}
        />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormText
          width='md'
          name="scope"
          label="授权范围"
          rules={[{ required: true }]}
          extra='多个范围需要用‘ , ‘隔开'
        />
        <ProFormText
          width='md'
          name="accessTokenValidity"
          label="令牌秒数"
          rules={[{ required: true }]}
        />
      </ProFormGroup>
      <ProFormGroup>
        <ProFormText
          width='md'
          name="refreshTokenValidity"
          label="刷新秒数"
          rules={[{ required: true }]}
        />
        <ProFormSelect
          name='authorities'
          mode='multiple'
          width='md'
          label="角色"
          showSearch
          request={async () => {
            const rolelist = await fetchRoleList({ page: 0, size: 500 });
            return rolelist && rolelist.data.map((item: Role) => ({
              label: item.name,
              value: item.id
            }))
          }}
        />
        <ProFormSwitch
          name={'passwordExpireEnable'}
          label="有效期"
          initialValue={false}
          fieldProps={{
            checkedChildren: '开启',
            unCheckedChildren: '禁用'
          }}
        />
        <ProFormDependency name={['passwordExpireEnable']}>
          {({ passwordExpireEnable }) => {
            return passwordExpireEnable &&
              <ProFormDigit
                width='md'
                name="passwordExpire"
                label="有效期时长（天）"
                rules={[{ required: true }]}
              />;
          }}
        </ProFormDependency>
      </ProFormGroup>
    </EditPage>);
};

const mapStateToProps = ({ client }: RootState) => ({
  entity: client.entity,
  updateSuccess: client.updateSuccess,
  updating: client.updating,
  createSuccess: client.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClientEdit);
