import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './certificate.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Post } from "model/video/post.model";
import { Col, Row } from "antd";

export interface PostDetailsProps extends DetailsStateAndDispatchProps<any>,
  RouteComponentProps, StateProps, DispatchProps {
}

const PostDetails: FC<PostDetailsProps> = (props: any) => {

  return (
    <DetailsPage<Post>
      title='课程详情'
      hideEdit={true}
      {...props}
    >
      <Row>
        <Col span={12}>
          <ProDescriptions<Post>
            column={1}
            columns={props.columns}
            dataSource={props.entity}
          >
          </ProDescriptions>
        </Col>
      </Row>
    </DetailsPage>);
};

const mapStateToProps = ({ examtopic }: RootState) => ({
  entity: examtopic.entity,
});

const mapDispatchToProps = {
  getEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostDetails);
