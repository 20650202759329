
import { Rate, defaultRate } from 'model/system/rate.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'rate';
const apiUrl = '/api/yumei-mall-logistics/rate';
const standardReducerImpl = new StandardReducerImpl<Rate>(entity, defaultRate, apiUrl);
export type RateState = StandardState<Rate>;
export const rate = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;