import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './power-module.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { PowerModule } from "model/system/power-module.model";

export interface ModuleDetailsProps extends DetailsStateAndDispatchProps<PowerModule>,
  RouteComponentProps, StateProps, DispatchProps {
}
const ModuleDetails: FC<ModuleDetailsProps> = (props) => {
  return (
    <DetailsPage<PowerModule>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<PowerModule>
        columns={props.columns  as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ powerModule }: RootState) => ({
  entity: powerModule.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ModuleDetails);
