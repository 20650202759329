import { defaultLevel, Level } from 'model/dealer/level.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';

const entity = 'level';
const apiUrl = '/api/yumei-mall-pim/brand/level';
const standardReducerImpl = new StandardReducerImpl<Level>(entity, defaultLevel, apiUrl);
export type DealerLevelState = StandardState<Level>;
export const dealerLevel = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 查询所有等级
export const fetchLevelList = async (params?:any) => {
  const requestUrl = `/api/yumei-mall-pim/brand/level`;
  const axiosResponse = await axios.get<any>(requestUrl, {params});
  return axiosResponse.data;
}

