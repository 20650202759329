import { BaseModel, DisableModel } from 'model/base.model';

// export interface Customer extends BaseModel, DisableModel {
//   account?: string;
//   avatar?: string;
//   birthday?: string;
//   invitorId?: number;
//   invitorSource?: string;
//   name?: string;
//   phone?: string;
//   remarks?: string;
//   sex?: number;
//   sourceId?: number;
//   tstCustomer?: TstCustomer;
//   tstUid?: number;
//   userId?: number;
// }

export interface Customer extends BaseModel, DisableModel {
  storeId?: any;
  customer?: any;
  name?: string;
  wechatId?: number;
  phoneRegion?: string;
  phone?: string;
  credentialsNo?: string;
  invitorId?: any;
  sourceAuthorizationCode?: string;
  brandName?: string;
  applyTime?: string;
  address?: any;
  avatar?: any;
  brandIds?: any;
  authorizationCode?: string;
  auditDetails?: any;
  brandDetails?: any;
  rechargeMap?: any;
  levelId?: number;
  levelType?: string;
  account?: string;
  birthday?: any;
  userId?: string
}

export interface TstCustomer {
  account?: string;
  code?: string;
  grade?: number;
  id?: number;
  tbtActivated?: boolean;
  tbtInvitorId?: number;
  tstId?: number;
  tstName?: string;
}

export const RelationshipState  = {
  'Active': "激活",
  'NotActive': "未激活",
  'DropOut': "退出"
}

export const sex = {
  1: '男',
  2: '女',
};

export const grade = {
  0: '普通卡',
  1: '蓝卡',
  2: '红卡',
};

export const invitorSource = {
  'qrcode_user': '二维码',
  'wxapp_message': '微信分享',
  'qrcode_product': '商品海报',
};

export const defaultCustomer: Readonly<Customer> = {
};
