export interface PaginationState {
  current?: number;
  // activePage: number;
  pageSize?: number;
  sort?: string;
  order?: 'descend' | 'ascend';
  total?: number;
};

export interface Pagination {
  page?: number;
  size?: number;
  sort?: string;
};

export interface FilterCriteria {
  [name: string]: any;
};

export const defaultPaginationState : PaginationState = {
  current: 1,
  pageSize: 20,
  sort: 'createdDate',
  order: 'descend'
};

export const defaultPageSize = 20;
