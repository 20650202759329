import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';
import { ActiveUser, defaultActiveUser } from 'model/customerCore/active-user.model';

const entity = 'active-user';
const apiUrl = '/api/yumei-mall-customer/active/user';
const standardReducerImpl = new StandardReducerImpl<ActiveUser>(entity, defaultActiveUser, apiUrl);
export type ActiveUserState = StandardState<ActiveUser>;
export const activeUser = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchActiveUserList = async(params:any) => {
  const res = await axios.get(apiUrl, { ...params });
  return res.data;
}