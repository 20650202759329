import { ModalForm, ProFormRadio, ProFormTextArea } from "@ant-design/pro-form";
import { useState } from "react";
import { Divider, Form } from "antd";
import ProDescriptions from "@ant-design/pro-descriptions";
import DetailsPage from "layouts/page-wraper/details-page";

const RechargeModel = (props: any) => {
    let { visible, record, onCancel, onRecharge } = props
    const [form] = Form.useForm();
    let [okText, setOkText] = useState<any>('确认');

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    return (
        <ModalForm
            className="toExamineDialog"
            title="审核"
            visible={visible}
            onVisibleChange={(e) => !e && onCancel()}
            width={600}
            form={form}
            modalProps={{
                'okText': okText
            }}
            layout='horizontal'
            preserve={false}
            onFinish={async (data: any) => {
                let newData = {
                    id: record.id,
                    isPass: data.isPass,
                    comments: data.comments,

                };
                onRecharge(newData);
                form.resetFields();
            }}
            {...formItemLayout}
        >
            <DetailsPage<any>
                style={{ marginTop: '-20px' }}
                title={false}
                hideEdit={true}
                {...record}
            >
                <ProDescriptions<any>
                    columns={props.columns}
                    dataSource={props.entity}
                    column={2}
                >
                </ProDescriptions>
            </DetailsPage>
            <Divider />
            <ProFormRadio.Group
                label="处理状态"
                name={'isPass'}
                width='md'
                fieldProps={{
                    onChange: (e: any) => {
                        setOkText(e.target.label)
                    }
                }}
                rules={[{ required: true }]}
                options={[
                    {
                        label: '审核通过',
                        value: true
                    }, {
                        label: '审核拒绝',
                        value: false
                    }
                ]}
            />

            <ProFormTextArea
                name="comments"
                allowClear={true}
                label="审核备注"
            />
        </ModalForm>
    )
}

export default RechargeModel