import {BaseModel} from "../base.model";

export interface SecondReview extends BaseModel {

}

export const secondReviewStatus = {
    1:'待审核',
    2:'审核通过',
    3:'审核拒绝'
}

export const isRelatives ={
    '1':'直接',
    '2': '非直接'
}

export const defaultSecondReview:  Readonly<SecondReview> = {};