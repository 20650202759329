import React, { FC, useState, memo, useEffect } from 'react';
import { Form, Radio, Slider, Image, Upload, Input, Tag, Tooltip, Select, Button, message } from 'antd';
import { PlusOutlined, FormOutlined } from '@ant-design/icons';
import '../style/popup.less';
import holderImg from '../../../../assets/images/public/holder.jpg';
import { uploadImage } from "utils/file-utils";
import { ProFormDependency } from '@ant-design/pro-form';
import SelectSearch from 'components/select-search';
import { fetchCustomerGroupList } from 'pages/customerCore/group/group.reducer';
const { Option } = Select;
export interface Props {
  dataField: {
    nav: any[]
    [key: string]: any
  }
  index: number
}
interface SetProps {
  dataField?: any
  id?: string
  methods?: any
  selectedInfo?: any
  otherInfo?: any
}

const FloatingFrame: FC<Props> = ({ dataField }) => {
  return (
    (dataField?.img && dataField.img !== '') ? <img style={{ width: '100%' }} src={dataField.img} alt="" />
      : <div className={`popup-container ${dataField.preview ? 'show' : ''}`} >
        <div className="title">
          <Tag color="success">浮标</Tag>
          {dataField.name || '浮标'}

        </div>
      </div>
  )
}

export const FloatingFrameSet: FC<SetProps> = memo(({ dataField, id, methods, selectedInfo, otherInfo }) => {
  const [identity] = useState([
    {
      label: '所有用户',
      value: 'all',
    },
    {
      label: '未登录用户',
      value: 'nologin',
    },
    {
      label: '已登录用户',
      value: 'login',
    },
    {
      label: '指定用户',
      value: 'userGroup',
    }
  ])
  const [platform] = useState([
    {
      label: '所有平台',
      value: 'all',
    },
    {
      label: '仅APP',
      value: 'app',
    },
    {
      label: '仅小程序',
      value: 'wxapp',
    },
  ])
  // all  nologin 未登录用户 logined 所有登录用户 blueCard 蓝卡及以上 redCard 红卡
  const uploadProps = {
    multiple: false,
    showUploadList: false,
    customRequest(e: any) {
      let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`;
      uploadImage(e.file, 'minipage/' + imageName).then(async res => {
        // updateModuleDataPrivate({
        //     ...data,
        //     'img':res
        // }, index, '')
        methods.updateModuleData({
          ...dataField,
          img: res
        }, id)
      }).catch(() => { message.error('图片上传失败') });
    },
  };
  const OpenType: FC<{ defaultValue: string }> = ({ defaultValue }) => {
    return (
      <Select defaultValue={defaultValue} onChange={
        (e) => {
          dataField.openType = e
          methods.updateModuleData(dataField, id)
        }
      } className="select-after">
        <Option value="navigateTo">新窗口</Option>
        <Option value="switchTab">TAB切换</Option>
        <Option value="redirectTo">重定向</Option>
        <Option value="back">返回</Option>
      </Select>
    );
  }
  useEffect(
    () => {
      // console.log('selectInfo Change popup', selectedInfo,)
      if (!selectedInfo || !selectedInfo.path || otherInfo.settingModuleId !== id) return
      methods.updateModuleData({
        ...dataField,
        openType: selectedInfo.openType,
        link: selectedInfo.path,
      }, id)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedInfo]
  )
  return (
    <div className="popup-set" >
      {
        dataField.img && <div className="img">
          <Upload {...uploadProps}>
            <div className="change-img" >
              <Tooltip title="更换">
                <FormOutlined />
              </Tooltip>
            </div>
          </Upload>
          <Button className="preview" type="primary" onClick={
            (e) => {
              e.preventDefault()
              e.stopPropagation()
              methods.updateMoreState({
                popupData: {
                  ...dataField,
                  type: 'floating_frame'
                }
              })
            }
          } style={
            {
              marginLeft: '10px'
            }
          }>查看效果</Button>
          <Image src={dataField.img ? dataField.img + '?imageView2/2/w/600' : holderImg} />
        </div>
      }

      {
        !dataField.img && <div className="dragger-con">
          <Upload {...uploadProps}>

            <div className="add-area">
              <p className="desc">
                <PlusOutlined className="icon" />
              </p>
              <p className="desc">点击选择图片</p>
            </div>
          </Upload>
        </div>
      }

      <Form className="custom-form" initialValues={
        {
          ...dataField
        }
      } onValuesChange={
        (change: any, all: any) => {
          methods.updateModuleData({
            ...dataField,
            ...all
          }, id)
        }
      }>
        <Form.Item label="浮标名称" name="name">
          <Input placeholder="请输入浮标名称" />
        </Form.Item>
        <Form.Item label="链接地址">
          <Input.Group compact className="input-item">
            <Button type="primary" onClick={
              () => {
                methods.setShowLinkSelectionPrivate(true,)
              }
            }>选择</Button>
            <Input addonAfter={<OpenType defaultValue={dataField.openType || 'navigateTo'} />} placeholder="请输入/选择链接地址" onChange={(e) => {
              dataField.link = e.target.value;
              methods.updateModuleData(dataField, id)
            }} value={dataField.link} />

          </Input.Group>
        </Form.Item>
        <Form.Item label="显示宽度(px)" name="width">
          <Slider min={30} max={375} step={10} />
        </Form.Item>
        <Form.Item label="距底距离（px）" name="bottom">
          <Slider min={0} max={1000} step={1} />
        </Form.Item>
        <Form.Item label="距右侧距离（px）" name="right">
          <Slider min={0} max={500} step={1} />
        </Form.Item>
        <Form.Item label="可见用户" name="identity">
          <Radio.Group value={dataField.identity}>
            {
              identity.map((v: any, i: number) => {
                return <Radio value={v.value} key={i}>{v.label}</Radio>
              })
            }
          </Radio.Group>
        </Form.Item>
        <ProFormDependency name={['identity']}>
          {({ identity }) => {
            return identity === 'userGroup' &&
              <Form.Item name="groupId">
                <SelectSearch required={false} name={"groupId"} width="sm" label='用户组' defaultParams='id.in' fetchFunc={fetchCustomerGroupList} searchParams='name.contains' />
              </Form.Item>
          }}
        </ProFormDependency>

        <Form.Item label="可见平台" name="platform">

          <Radio.Group value={dataField.platform}>
            {
              platform.map((v: any, i: number) => {
                return <Radio value={v.value} key={i}>{v.label}</Radio>
              })
            }
          </Radio.Group>
        </Form.Item>

      </Form>



    </div>
  )
})

export default FloatingFrame