import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import RetailDetails from "./retail-details";
import { deleteEntity, getEntities, getEntity } from "./retail.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import RetailEdit from "./retail-edit";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import { Retail } from "model/scanCodeOrder/retail.model";
import { sendOutGoodsRowActions } from "../sendOutGoods/sendOutGoods-list";
import { ProFormDateTimePicker } from "@ant-design/pro-form";

export interface RetailListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const RetailList: FC<RetailListProps> = (props) => {

  const { entities } = props;

  const columns: ProColumns<Retail>[] = [{
    title: '订单号',
    dataIndex: 'shipmentNo',
    width: 120,
  }, {
    title: '发货人姓名',
    dataIndex: 'shipNodeName',
    width: 120,
  }, {
    title: '发货人授权码',
    dataIndex: 'shipNodeCode',
    width: 120,
  }, {
    title: '发货人手机号',
    dataIndex: 'shipMobile',
    width: 120,
  }, {
    title: '收货人姓名',
    dataIndex: 'receiveNodeName',
    width: 120,
  }, {
    title: '收货人授权码',
    dataIndex: 'receiveNodeCode',
    width: 120,
  }, {
    title: '收货人手机号',
    dataIndex: 'receiveMobile',
    width: 120,
  }, {
    title: '操作人姓名',
    dataIndex: 'createdBy',
    width: 120,
  }, {
    title: '下单时间',
    dataIndex: 'shipTime',
    width: 120,
  }, {
    title: '物流码',
    dataIndex: 'scode',
    width: 120,
    render: (dom, record) => {
      return <>{record.shipmentLine.concat().map((e: any) => {
        return <>{e.itemUniqueNo.map((item: any) => {
          return <p>{item.scode}</p>
        })}</>
      })}</>
    },
  },]



  const searchFields = [{
    label: '订单号',
    name: 'shipmentNo.equals',
  }, {
    label: '发货人姓名',
    name: 'shipNodeName.equals',
  }, {
    label: '发货人授权码',
    name: 'shipNodeCode.equals',
  }, {
    label: '收货人姓名',
    name: 'receiveNodeName.equals',
  }, {
    label: '收货人授权码',
    name: 'receiveNodeCode.equals',
  }, {
    label: '物流码',
    name: 'text.contains',
  },];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch placeholder='店铺' required={false} name="storeId.equals" width="md" labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
        <ProFormDateTimePicker
          name="shipTime.greaterThanOrEqual"
          placeholder="下单开始时间"
        />
        <ProFormDateTimePicker
          name="shipTime.lessThanOrEqual"
          placeholder="下单结束时间"
        />
      </Input.Group>
    </Form.Item>
  );
  const exportData = (data: Retail[]) => {
    let entities: Retail[] = [];
    data.forEach((record: any) => {
      let scode: any = '';
      record.shipmentLine && record.shipmentLine.forEach((e: any) => {
        e.itemUniqueNo && e.itemUniqueNo.forEach((item: any) => {
          scode += item.scode + '；'
        })
      })
      let arr = {
        ...record,
        scode,
      };
      entities.push(arr);
    });
    return entities;
  };
  return (
    <ListViewPage<Retail>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<RetailDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      togetherParams={{ 'shipmentType.equals': 'Local' }}
      hideCreateButton
      hideDeleteButton
      hideEditButton
      showDownload
      downloadEntities={exportData(entities)}
      downloadAllEntities={exportData}
      rowActions={sendOutGoodsRowActions}
      editPage={(params: any) => <RetailEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ retail }: RootState) => ({
  entity: retail.entity,
  entities: retail.entities,
  loading: retail.loading,
  updateSuccess: retail.updateSuccess,
  createSuccess: retail.createSuccess,
  total: retail.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RetailList);