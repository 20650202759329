import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import SecondReviewDetails from './secondReview-details';
import SecondReviewEdit from './secondReview-edit';
import SecondReviewList from './secondReview-list';

const SecondReview: React.FC<RouteComponentProps> = () => (
  <Router>
    <SecondReviewList path="/" />
    <SecondReviewDetails path=":id" />
    <SecondReviewEdit path=":id/edit" />
    <SecondReviewEdit path="new" />
  </Router>
);
export default SecondReview;
