import axios from 'axios';
import { internationalList } from 'components/IntelFormText';
import { defaultLevel, Level } from 'model/customerCore/level.model';
import { QueryParameter } from 'reducers/redux-action.type';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'level';
const apiUrl = '/api/yumei-mall-customer/customer/level';
const standardReducerImpl = new StandardReducerImpl<Level>(entity, defaultLevel, apiUrl);
export type LevelState = StandardState<Level>;
export const level = standardReducerImpl.buildStandardReducer();
export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 查询会员级别
export const fetchLevelList = async (params?: QueryParameter) : Promise<Level[]> => {
  const axiosResponse = await axios.get<Level[]>(`/api/yumei-mall-pim/brand/level/list`);
  let data = axiosResponse.data
  data.map((item:any)=> {
    item.label = internationalList(item.levelName)
    item.value = item.id
    item.levelName = internationalList(item.levelName)
    return item
  })
  return data || [];
}

// 查询钱包账户
export const walletAccountTypeList = async (params?: QueryParameter) : Promise<Level[]> => {
  const axiosResponse = await axios.get<Level[]>(`/api/yumei-mall-customer/meta-data/enums`, {
      params: params
  });
  return axiosResponse.data || [];
}