import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import axios from 'axios';
import { Payload, PayloadResult } from 'reducers/redux-action.type';
import { Tenant, defaultTenant } from 'model/system/tenant';

const entity = 'tenant';
const apiUrl = '/api/yumei-mall-tenant/tenant';
const standardReducerImpl = new StandardReducerImpl<Tenant>(entity, defaultTenant, apiUrl);
export type TenantState = StandardState<Tenant>;
export const tenant = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchTenantList = async () => {
    const res = await axios.get(apiUrl);
    return res.data;
  }

// 初始化
export const tenantInitialize = (id: string | number): Payload<Tenant> | PayloadResult<Tenant> => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/initialize?force=true`;
  return await dispatch({
      type: `${entity}/${STANDARD_ACTION_TYPES.UPDATE}`,
      payload: axios.post(requestUrl),
  })
};
