import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import {
  CrudGetAllAction,
  QueryParameter
} from "reducers/redux-action.type";
import axios from "axios";
import { Category, defaultCategory } from 'model/product/category.model';
import { RootObject } from '../../../model/response.model';
const entity = 'category';
const apiUrl = '/api/yumei-mall-pim/product/category';
const standardReducerImpl = new StandardReducerImpl<Category>(entity, defaultCategory, apiUrl);
export type CategoryState = StandardState<Category>;
export const category = standardReducerImpl.buildStandardReducer();

// export const getEntities = standardReducerImpl.getEntities;
export const getEntities: CrudGetAllAction<Category> = (params?: QueryParameter) => dispatch => {
  const paramsData = {
    current: params && Number(params.page) + 1,
    ...params
  };
  const requestUrl = `/api/yumei-mall-pim/product/category/tree`;
  return dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST),
    payload: axios.get<any>(requestUrl, {
      params: paramsData
    })
  });
};
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntityLevelChange;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchStoreCategoryList = async (storeId?: any): Promise<Category[]> => {
  const axiosResponse = await axios.get(`/api/yumei-mall-pim/product/category/store/${storeId}/tree`, { params: { size: 500 } });
  return axiosResponse.data || [];
}

export const fetchCategoryTreeList = async (storeId?: any): Promise<Category[]> => {
  const axiosResponse = await axios.get(`/api/yumei-mall-pim/product/category/tree`, { params: { size: 500 } });
  return axiosResponse.data || [];
}

export const increaseStoreCategory = async(params:any) => {
  const requestUrl = `/api/yumei-mall-pim/product/category/store`;
  return await axios.post(requestUrl, { ...params })
}

export const searchCategory = async (name?: string): Promise<Category[]> => {
  const paramsData = {
    categoryName: name
  };
  const requestUrl = `${apiUrl}/keywordSearch`;
  const axiosResponse = await axios.get<RootObject<Category[]>>(requestUrl, {
    params: paramsData
  });
  return axiosResponse?.data?.data || [];
}

export const fetchFirstCategoryList = async (name?: string): Promise<Category[]> => {
  const paramsData = {
    categoryName: name
  };
  const requestUrl = `${apiUrl}/categoryOneLevelList`;
  const axiosResponse = await axios.get<RootObject<Category[]>>(requestUrl, {
    params: paramsData
  });
  return axiosResponse?.data?.data || [];
}

export const fetchCategoryChildren = async (parentId?: string): Promise<Category[]> => {
  const paramsData = {
    parentId
  };
  const requestUrl = `${apiUrl}/categoryList`;
  const axiosResponse = await axios.get<RootObject<Category[]>>(requestUrl, {
    params: paramsData
  });
  return axiosResponse?.data?.data || [];
}