import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import PermissionDetails from './permission-details';
import PermissionEdit from './permission-edit';
import PermissionList from './permission-list';

const Permission: React.FC<RouteComponentProps> = () => (
  <Router>
    <PermissionList path="/" />
    <PermissionDetails path=":id" />
    <PermissionEdit path=":id/edit" />
    <PermissionEdit path="new" />
  </Router>
);
export default Permission;
