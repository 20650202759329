import { PlusOutlined } from "@ant-design/icons";
import ProForm, { ModalForm, ProFormDependency, ProFormDigit, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { EditableProTable, ProColumns } from "@ant-design/pro-table";
import { Button, Form, Input, message, Row, Col, Tooltip, Typography } from "antd";
import { Content } from "antd/lib/layout/layout";
import { useEnumValue } from "common/enum/use-enum-value";
import { internationalList } from "components/IntelFormText";
import { Order } from "model/procure/order.model";
import ManualSkuSelectionForm from "pages/pim/product/manual-sku-selection-form";
import { searchManualProductSku } from "pages/pim/product/product.reducer";
import { useEffect, useRef, useState } from "react";
import { manualOrder, searchBelongTstCode, searchOrder } from "../purchase-order/order.reducer";
import OrderDetailsUpdateMsg from "./order-details-update-msg";
import { Storage } from 'utils/storage-util';
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import { fetchWarehouseList } from "../after-sales/after-sales.reducer";
const { Search } = Input;

const departmentUserOptions = [
  {
    "id": 2,
    "name": "董事长"
  },
  {
    "id": 3,
    "name": "总经理"
  },
  {
    "id": 4,
    "name": "总经办"
  },
  {
    "id": 5,
    "name": "行政管理部"
  },
  {
    "id": 6,
    "name": "人力资源部"
  },
  {
    "id": 7,
    "name": "财务部"
  },
  {
    "id": 8,
    "name": "法务部"
  },
  {
    "id": 9,
    "name": "市场部"
  },
  {
    "id": 10,
    "name": "企划部"
  },
  {
    "id": 11,
    "name": "安全部"
  },
  {
    "id": 12,
    "name": "微商线上运营部"
  },
  {
    "id": 13,
    "name": "线下运营部"
  },
  {
    "id": 14,
    "name": "专家培训组"
  },
  {
    "id": 15,
    "name": "徐汇店"
  },
  {
    "id": 16,
    "name": "客服部"
  },
  {
    "id": 17,
    "name": "仓储部"
  },
  {
    "id": 18,
    "name": "供应链部"
  },
  {
    "id": 19,
    "name": "系统信息部"
  },
  {
    "id": 20,
    "name": "公关部"
  },
  {
    "id": 21,
    "name": "产品研发部"
  },
  {
    "id": 22,
    "name": "海外事业部"
  },
  {
    "id": 23,
    "name": "稽核部"
  },
  {
    "id": 24,
    "name": "大区财务"
  },
  {
    "id": 25,
    "name": "庭爽GO事业部"
  },
  {
    "id": 26,
    "name": "庭嗨购事业部"
  },
  {
    "id": 27,
    "name": "运营部"
  },
  {
    "id": 28,
    "name": "促销活动部"
  },
  {
    "id": 29,
    "name": "人事行政部"
  },
  {
    "id": 30,
    "name": "APP运营部"
  },
  {
    "id": 31,
    "name": "览海国际"
  },
  {
    "id": 32,
    "name": "玉美事业部"
  },
  {
    "id": 33,
    "name": "采购部"
  },
  {
    "id": 34,
    "name": "奖金核算组"
  },
  {
    "id": 35,
    "name": "系统开发部"
  },
  {
    "id": 36,
    "name": "策划部"
  },
  {
    "id": 37,
    "name": "庭生活馆"
  },
  {
    "id": 38,
    "name": "海外运营部"
  },
  {
    "id": 39,
    "name": "战略管理部"
  },
  {
    "id": 40,
    "name": "展示设计部"
  },
  {
    "id": 41,
    "name": "电商运营部"
  },
  {
    "id": 42,
    "name": "o2o事业部"
  },
  {
    "id": 43,
    "name": "物流部"
  },
  {
    "id": 44,
    "name": "会员奖金部"
  },
  {
    "id": 45,
    "name": "信息管理部"
  },
  {
    "id": 46,
    "name": "品牌运营部"
  },
  {
    "id": 47,
    "name": "董事办"
  },
  {
    "id": 48,
    "name": "产品项目管理部"
  },
  {
    "id": 49,
    "name": "产品开发部"
  },
  {
    "id": 50,
    "name": "渠道管理部"
  },
  {
    "id": 51,
    "name": "礼品采购部"
  },
  {
    "id": 52,
    "name": "整合营销部"
  },
  {
    "id": 53,
    "name": "酙玖事业部"
  },
  {
    "id": 54,
    "name": "大健康事业部"
  },
  {
    "id": 55,
    "name": "青浦小店"
  },
  {
    "id": 56,
    "name": "平台运营中心"
  },
  {
    "id": 57,
    "name": "产品运营中心"
  },
  {
    "id": 58,
    "name": "资产运营部"
  },
  {
    "id": 59,
    "name": "产品运营部"
  },
  {
    "id": 60,
    "name": "战略中心"
  },
  {
    "id": 61,
    "name": "电商事业部"
  },
  {
    "id": 62,
    "name": "陶不庭招商部"
  },
  {
    "id": 63,
    "name": "投融资管理部"
  },
  {
    "id": 64,
    "name": "市场运营部"
  },
  {
    "id": 65,
    "name": "大健康培训部"
  },
  {
    "id": 66,
    "name": "信息系统中心"
  },
  {
    "id": 67,
    "name": "市场开发部"
  },
  {
    "id": 68,
    "name": "产品管理部"
  },
  {
    "id": 69,
    "name": "采购一部"
  },
  {
    "id": 70,
    "name": "采购二部"
  },
  {
    "id": 71,
    "name": "玉美运营部"
  },
  {
    "id": 72,
    "name": "集团资金账务部"
  },
  {
    "id": 73,
    "name": "产品培训部"
  },
  {
    "id": 74,
    "name": "直播部"
  },
  {
    "id": 75,
    "name": "行政管理中心"
  },
  {
    "id": 76,
    "name": "资产管理部"
  },
  {
    "id": 77,
    "name": "美人计"
  },
  {
    "id": 78,
    "name": "MCN"
  },
  {
    "id": 79,
    "name": "财务管理中心"
  },
  {
    "id": 80,
    "name": "元宇宙"
  },
  {
    "id": 81,
    "name": "成本公共中心"
  },
  {
    "id": 82,
    "name": "利润公共中心"
  }
];

const XJPOptions = [{
  label: '东马来',
  value: 'EastMalaysia'
}, {
  label: '西马来',
  value: 'WestMalaysia'
}, {
  label: '新加坡',
  value: 'RepublicOfSingapore'
}];

const MLXYOptions = [{
  label: '东马来西亚',
  value: 'EastMalaysia'
}, {
  label: '菲律宾',
  value: 'Philippines'
}, {
  label: '新加坡共和国',
  value: 'RepublicOfSingapore'
}, {
  label: '泰国',
  value: 'Thailand'
}, {
  label: '西马来西亚',
  value: 'WestMalaysia'
}, {
  label: '印度尼西亚',
  value: 'Indonesia'
}, {
  label: '越南南部',
  value: 'SouthernVietnam'
}, {
  label: '纳闽',
  value: 'Labuan'
}];

export const ManualColumns: ProColumns<any>[] = [{
  title: '商品名称',
  dataIndex: 'itemName',
  width: 200,
  editable: false,
  render: (text, record) => <Tooltip title={internationalList(record.itemName)}>
    <p className="text_more_hidden2">{internationalList(record.itemName)}</p>
  </Tooltip>
}, {
  title: '商品编码',
  dataIndex: 'itemCode',
  width: 100,
  editable: false,
}, {
  title: '商品条码',
  dataIndex: 'barCode',
  width: 100,
  editable: false,
}, {
  title: '数量',
  dataIndex: 'quantity',
  width: 100,
  valueType: 'digit',
  fieldProps: {
    min: 1,
    max: 10000,
    controls: false,
    precision: 0,
  },
}, {
  title: '实付商品单价',
  dataIndex: 'salePriceAfterDiscount',
  width: 100,
  valueType: 'digit',
  fieldProps: {
    min: 0,
    controls: false,
    precision: 0,
  },
}];

interface ManualOrderProps {
  entity?: Order;
  getEntities?: any;
  type?: string;
  afterSaleOrderId?: string | number;
  manualOrderGetEntity?: any;
}

const ManualOrder = (props: ManualOrderProps) => {
  const { entity, getEntities, afterSaleOrderId, type, manualOrderGetEntity } = props;
  const [manualValues, SetManualValues] = useState<any>([]);
  const [manualEntity, SetManualEntity] = useState<any>({});
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const actionRef = useRef<any>();
  const [form] = Form.useForm<any>();
  const { convertEnumToOptionType } = useEnumValue();
  const xTenant = Storage.session.get("x-tenant");

  useEffect(() => {
    SetManualValues([]);
    SetManualEntity({});
    form.resetFields();
    if (entity?.id) {
      form.setFieldsValue({
        belongTstId: entity?.belongTstId,
        belongTstCode: entity?.belongTstCode,
        sellerId: entity?.sellerId,
        orderId: entity?.id,
      });
      SetManualEntity(entity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  return <ModalForm<{ code: string }>
    title='创建手工单'
    trigger={<Button icon={<PlusOutlined />}>创建手工单</Button>}
    onFinish={async (res: any) => {
      if (manualValues.length === 0) {
        message.error('请添加商品！');
        return false;
      } else {
        const consigneeAddress = {
          consigneeName: manualEntity?.address?.consigneeName,
          mobile: manualEntity?.address?.mobile,
          province: manualEntity?.address?.province,
          provinceName: manualEntity?.address?.provinceName,
          cityName: manualEntity?.address?.cityName,
          city: manualEntity?.address?.city,
          districtName: manualEntity?.address?.districtName,
          district: manualEntity?.address?.district,
          address: manualEntity?.address?.address,
        };
        let data: any = {};
        if (res.consigneeAddress) {
          data = {
            ...res,
            consigneeAddress: {
              ...consigneeAddress,
              ...res.consigneeAddress
            },
            lines: manualValues
          };
        } else {
          data = {
            ...res,
            consigneeAddress,
            lines: manualValues
          };
        };
        let success = true;
        afterSaleOrderId && (data.afterSaleOrderId = afterSaleOrderId);
        await manualOrder(data).then((e: any) => {
          form.resetFields();
          message.success('创建成功');
          !entity?.id && getEntities && getEntities();
          afterSaleOrderId && manualOrderGetEntity && manualOrderGetEntity(afterSaleOrderId);
        }).catch(error => {
          message.error(error?.response?.data?.title || 'error');
          success = false;
        });
        return success;
      };
    }}
    onVisibleChange={() => {
      form.resetFields();
      SetManualValues([]);
      SetManualEntity({});
      setEditableRowKeys([]);
      if (entity?.id) {
        form.setFieldsValue({
          belongTstId: entity?.belongTstId,
          belongTstCode: entity?.belongTstCode,
          sellerId: entity?.sellerId,
          orderId: entity?.id,
        });
        SetManualEntity(entity);
      }
    }}
    width={1000}
    form={form}
  >
    <Row>
      <Col span={7}>
        <ProFormSelect
          name="manualOrderType"
          label="手工单类型"
          placeholder="手工单类型"
          rules={[{ required: true }]}
          initialValue={'ExchangeGoods'}
          disabled={type === 'afterSales'}
          options={convertEnumToOptionType('ManualOrderType')}
        />
      </Col>
      <Col span={1} />
      <Col span={7}>
        <ProFormDependency name={['manualOrderType']}>
          {({ manualOrderType }) => {
            return <ProForm.Item label="订单id" name="orderId" rules={[{ required: manualOrderType !== 'Abnormal' }]}>
              <Search placeholder="请输入订单id查询" disabled={entity?.id ? true : false} onSearch={(e: any) => {
                if (e) {
                  searchOrder(e + '').then((e: any) => {
                    SetManualEntity(e);
                    form.setFieldsValue({
                      belongTstId: e?.belongTstId,
                      belongTstCode: e?.belongTstCode,
                      sellerId: e?.sellerId,
                    })
                    message.success('查询成功');
                  }).catch(err => message.error('查询失败，请核对订单原始单号'))
                } else {
                  message.error('请输入订单id')
                }
              }} enterButton="Check" />
            </ProForm.Item>
          }}
        </ProFormDependency>
      </Col>
      <Col span={1} />
      <Col span={7}>
        <ProForm.Item label="店铺" name="sellerId" required={true} >
          <SelectSearch disabled={(entity?.id || manualEntity?.id) ? true : false} required={true} width={'sm'} name="sellerId" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        </ProForm.Item>
      </Col>
      <Col span={1} />
      <Col span={7}>
        {/* <ProFormText
          name="belongTstCode"
          label="用户code"
          rules={[{ required: true }]}
        /> */}
        <ProForm.Item rules={[{ required: true }]} label="用户code" name="belongTstCode">
          <Search placeholder="请输入用户code查询" disabled={(entity?.id || manualEntity?.id) ? true : false} onSearch={(e: any) => searchBelongTstCode({ 'code.equals': e + '' }).then((e: any) => {
            if (e.length > 0) {
              form.setFieldsValue({
                belongTstId: e[0]?.userId,
              })
              message.success('查询成功');
            } else {
              message.error('数据为空，请核对用户code');
            }
          }).catch(err => message.error('查询失败，请核对用户code'))} enterButton="Check" />
        </ProForm.Item>
      </Col>
      <Col span={1} />
      <Col span={7}>
        <ProFormText
          name="belongTstId"
          label="用户id"
          disabled={true}
          rules={[{ required: true }]}
        />
      </Col>
      <Col span={1} />
      <Col span={7}>
        <ProForm.Item label="所属仓库" name="warehouseCode" required={true} >
          <SelectSearch required={true} width={'sm'} name="warehouseCode" optionValue="code" placeholder="所属仓库" labelValue="name" defaultParams='' fetchFunc={fetchWarehouseList} searchParams='name' />
        </ProForm.Item>
      </Col>
      <Col span={1} />
      <Col span={7}>
        <ProFormSelect
          name="reissueDept"
          label="补发部门"
          placeholder="补发部门"
          rules={[{ required: true }]}
          options={[{
            label: '电商部',
            value: '电商部'
          }, {
            label: '物流部',
            value: '物流部'
          }, {
            label: '客服部',
            value: '客服部'
          }, {
            label: '信息技术部',
            value: '信息技术部'
          }, {
            label: '欧希纳',
            value: '欧希纳'
          }]}
        />
      </Col><Col span={1} />
      <Col span={7}>
        <ProFormDigit
          name="amount"
          label="金额"
          initialValue={0}
        />
      </Col><Col span={1} />
      <Col span={7}>
        <ProFormDigit
          name="freight"
          label="运费"
          initialValue={0}
        />
      </Col><Col span={1} />
      <Col span={7}>
        <ProFormSelect
          name="departmentUser"
          label="领用部门"
          options={departmentUserOptions.map(e => ({ label: e.name, value: e.id }))}
        />
      </Col><Col span={1} />
      <Col span={7}>
        <ProFormSwitch
          label="强制提交(忽略原始订单ID判断)"
          name="ignoreValidteOriginOrder"
          width='sm'
          initialValue={0}
          getValueFromEvent={value => (value && 1) || 0}
          getValueProps={value => ({ checked: value === 1 ? true : false })}
          fieldProps={{
            checkedChildren: '忽略',
            unCheckedChildren: '不忽略'
          }}
        />
      </Col><Col span={1} />
      {
        xTenant === 'ttw' && <>
          <Col span={7}>
            <ProFormSelect
              name="deliveryType"
              label="台湾配送類別"
              placeholder="手工单类型"
              rules={[{ required: true }]}
              options={[{
                label: '宅配单',
                value: '0'
              }, {
                label: '商超单',
                value: '1'
              }, {
                label: '自提',
                value: '2'
              }]}
            />
          </Col><Col span={1} />
          <Col span={7}>
            <ProFormSwitch
              name={'needInvoice'}
              label="是否需要发票"
              fieldProps={{
                checkedChildren: '是',
                unCheckedChildren: '否',
              }}
            />
          </Col><Col span={1} />
        </>
      }
      {
        xTenant === 'tml' && <>
          <Col span={7}>
            <ProFormSelect
              name={['consigneeAddress', "countryName"]}
              label="国家"
              rules={[{ required: true }]}
              options={MLXYOptions}
            />
          </Col><Col span={1} />
          <Col span={7}>
            <ProFormText
              name={['consigneeAddress', "provinceName"]}
              label="省份"
              rules={[{ required: true }]}
            />
          </Col><Col span={1} />
          <Col span={7}>
            <ProFormText
              name={['consigneeAddress', "zipCode"]}
              label="邮编"
              rules={[{ required: true }]}
            />
          </Col><Col span={1} />
          <Col span={7}>
            <ProFormText
              name="amount"
              label="金额"
              initialValue={0}
              rules={[{ required: true }]}
            />
          </Col><Col span={1} />
          <Col span={7}>
            <ProFormDigit
              name="freight"
              label="运费"
              initialValue={0}
              rules={[{ required: true }]}
            />
          </Col><Col span={1} />
          <Col span={7}>
            <ProFormText
              name="tag"
              label="标记"
            />
          </Col><Col span={1} />
        </>
      }
      {
        xTenant === 'tsg' && <>
          <Col span={7}>
            <ProFormSelect
              name={['consigneeAddress', "countryName"]}
              label="国家"
              rules={[{ required: true }]}
              options={XJPOptions}
            />
          </Col><Col span={1} />
          <Col span={7}>
            <ProFormText
              name={['consigneeAddress', "zipCode"]}
              label="邮编"
              rules={[{ required: true }]}
            />
          </Col><Col span={1} />
        </>
      }
    </Row>
    <ProForm.Group>
      <ProFormTextArea width={'lg'} label="订单备注" name='sellerMsg' />
      <ProFormTextArea width={'lg'} label="商家备注" name='adminMsg' />
    </ProForm.Group>
    <Content className='order-info-user'>
      <h3>收货人信息
        {<OrderDetailsUpdateMsg entity={manualEntity || {}} updateConsignee={res => {
          SetManualEntity({
            ...manualEntity,
            address: {
              ...manualEntity.address,
              consigneeName: res?.address?.consigneeName,
              mobile: res?.address?.mobile,
              province: res?.address?.province,
              provinceName: res?.address?.provinceName,
              cityName: res?.address?.cityName,
              city: res?.address?.city,
              districtName: res?.address?.districtName,
              district: res?.address?.district,
              address: res?.address?.address,
            }
          });
        }} />}
      </h3>
      {/* <p>收货人：{manualEntity.address?.consigneeName}</p>
      <p>联系电话：{manualEntity.address?.mobile}</p>
      <p>省市区：{manualEntity.address?.provinceName}
        {manualEntity.address?.cityName}
        {manualEntity.address?.districtName}</p>
      <p>收货地址：{manualEntity.address?.address}</p> */}
      <p className="manual_order_address"><span>{manualEntity.address?.provinceName} {manualEntity.address?.cityName} {manualEntity.address?.districtName}</span>
        <span>{manualEntity.address?.address}</span>
        <br />
        <span>{manualEntity.address?.consigneeName}</span><span>{manualEntity.address?.mobile}</span>
      </p>
    </Content>
    <EditableProTable<any>
      value={manualValues}
      onChange={(e) => { }}
      headerTitle="添加商品"
      recordCreatorProps={false}
      actionRef={actionRef}
      rowKey="itemCode"
      columns={ManualColumns}
      search={false}
      cardBordered={true}
      rowSelection={{}}
      tableAlertOptionRender={({ selectedRowKeys, onCleanSelected }) =>
        <Button type="link" size="small"
          onClick={() => {
            const newData = (manualValues || []).filter((item: any) => selectedRowKeys?.indexOf(item.itemCode || '') < 0)
            SetManualValues(newData);
            onCleanSelected();
          }}>
          删除
        </Button>}
      toolBarRender={() => [
        <ManualSkuSelectionForm
          trigger="添加商品"
          onFinish={(skus) => {
            const newData = manualValues.concat(skus);
            const newIds = newData.concat().map((e: any) => e.itemCode);
            const filterIds = new Set(newIds);
            Array.from(filterIds).concat().map((e) => {
              const item = newData.concat().filter((i: any) => i.itemCode === e + '')[0];
              return {
                ...item,
                quantity: item.quantity || 1,
                skuCode: item.itemCode,
                salePriceAfterDiscount: item.salePriceAfterDiscount || 0,
              };
            }).forEach(e => {
              actionRef?.current?.addEditRecord(e, {
                newRecordType: 'dataSource',
              })
            });
          }}
        />, <ModalForm<{ productIds?: string }>
          title="批量输入商品ids"
          width={600}
          trigger={<Button>快速添加</Button>}
          modalProps={{
            destroyOnClose: true,
          }}
          preserve={false}
          onFinish={async (values) => {
            const newIds = (values.productIds || '').trim().split(',').map(item => item.trim()).filter(item => !!item);
            let arr = [];
            let dataList: any = [];
            const fetchData = async () => {
              const result = await searchManualProductSku({
                skuCode: newIds[arr.length]
              });
              const newList = result.data && result.data.concat().filter((e: any) => e.itemCode).map((e: any) => ({
                ...e,
                skuId: e.id,
                skuCode: e.itemCode,
                productName: e.itemName,
              }));

              dataList = dataList.concat(newList);
              arr.push(0);
              arr.length < newIds.length && await fetchData();
              if (arr.length === newIds.length) {
                const newData = manualValues.concat(dataList);
                const newIds = newData.concat().map((e: any) => e.itemCode);
                const filterIds = new Set(newIds);
                Array.from(filterIds).concat().map((e) => {
                  const item = newData.concat().filter((i: any) => i.itemCode === e + '')[0];
                  return {
                    ...item,
                    quantity: item.quantity || 1,
                    skuCode: item.itemCode,
                    salePriceAfterDiscount: item.salePriceAfterDiscount || 0,
                  };
                }).forEach(e => {
                  actionRef?.current?.addEditRecord(e, {
                    newRecordType: 'dataSource',
                  })
                });
              }
            }; newIds.length > 0 && await fetchData();
            return true;
          }}
        >
          <Typography.Text>批量粘贴商品Id到下面，以"，"分割，然后点击确定</Typography.Text>
          <Typography.Text italic>例如：1430438289763639297,1455712100170674178</Typography.Text>
          <ProFormTextArea
            name="productIds"
            allowClear={true}
            placeholder="1430438289763639297,1455712100170674178"
            rules={[{ pattern: RegExp('^\\d+(,(\\s)*\\d+)*$'), message: '数据格式不正确' }]}
          />
        </ModalForm>]
      }
      editable={{
        type: 'multiple',
        editableKeys,
        onChange: setEditableRowKeys,
        onValuesChange: (record, recordList) => {
          const newList = recordList.filter(e => e.itemCode).concat().map(e => ({
            ...e,
            skuId: e.id,
            skuCode: e.itemCode,
            productName: e.itemName,
          }))
          SetManualValues(newList);
        },
      }}
    />
  </ModalForm >
}

export default ManualOrder;