import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { Route, defaultRoute } from 'model/system/route.model';

const entity = 'payee-enterprise';
const apiUrl = '/api/yumei-mall-common/aisearch';
const standardReducerImpl = new StandardReducerImpl<Route>(entity, defaultRoute, apiUrl);
export type RouteState = StandardState<Route>;
export const route = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;