import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import {defaultAdvertisement, advertisementModel } from "model/market/advertisement.model";
import axios from 'axios';

const entity = 'level';
const apiUrl = '/api/yumei-mall-train/activity/signPage';
const standardReducerImpl = new StandardReducerImpl<advertisementModel>(entity, defaultAdvertisement, apiUrl);
export type VideoCheckInState = StandardState<advertisementModel>;
export const videoCheckIn = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;




// 审批
export const auditRefuse = async (params:any) => {
  const requestUrl = `/api/yumei-mall-train/activity/updateExamine`;
  const axiosResponse = await axios.put<any>(requestUrl, params);
  return axiosResponse;
}








