import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';
import { defaultRegionalRewards, regionalRewardsModel } from 'model/reward/regionalRewards.model';

const entity = 'regionalRewards';
const apiUrl = '/api/yumei-mall-customer/customer/list';
const standardReducerImpl = new StandardReducerImpl<regionalRewardsModel>(entity, defaultRegionalRewards, apiUrl);
export type RewardconfigState = StandardState<regionalRewardsModel>;
export const rewardconfig = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


// 查询区域列表
export const regionalOwnershipResource = async (params:any) => {
  const requestUrl = `/api/yumei-mall-region/regionalOwnershipResource`;
  const axiosResponse = await axios.get<any>(requestUrl, {params: {...params}});
  return axiosResponse.data;
}

// 根据条件查询区域列表
export const findByCondition = async (params:any) => {
  const requestUrl = `/api/yumei-mall-region/regionalOwnershipResource/findByCondition`;
  const axiosResponse = await axios.get<any>(requestUrl, {params: {...params}});
  return axiosResponse.data;
}


// 查询所有等级
export const fetchLevelList = async () => {
  const requestUrl = `/api/yumei-mall-pim/brand/level/list?sort=levelSort,asc`;
  const axiosResponse = await axios.get<any>(requestUrl, {});
  return axiosResponse.data;
}

// 经销商列表
export const dealerList = async (data:any): Promise<any> => {
  const result = await axios.get(`/api/yumei-mall-store/seller/bank-roll/transaction`, {params: {...data}});
  return result
}

export const fetchCustomerListSelect = async (params?: any) => {
  const data = await axios.get<any>('/api/yumei-mall-customer/customer', { params:{...params} });
  return data?.data || []
}

export const storeList = [
  {
    label: '美人计',
    value: 1
  }, {
    label: '黑天鹅',
    value: 2
  }, {
    label: '朱莉安娜女王',
    value: 3
  }, {
    label: '瘦金体',
    value: 8
  }, {
    label: '本草籍',
    value: 9
  }
]

export const storeListMap:any = {
  1:  '美人计',
  2:  '美人计',
  3:  '美人计',
  8:  '美人计',
  9:  '美人计',
}






