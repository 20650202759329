import { BaseModel } from "../base.model";
import { Achievement } from "./achievement.model";

//export interface ProductLabel {
//    id?: number;
//    labelName?: string;
//}


export interface Spec {
    k?: string;
    k_id?: string;
    v?: string;
    v_id?: string;
}

export interface Sku extends BaseModel {
    id?: number;
    product?: Product;
    quantity?: number;
    sellerId?: number;
    shopId?: number;
    sku?: SkuItem;
    specs?: Spec[];
    status?: number;
}

export interface SkuItem {
    id?: string | number;
    barcode?: string;
    code?: string;
    costPrice?: number;
    deliveryPrice?: number;
    maxPerOrder?: number;
    maxPerPerson?: number;
    originPrice?: number;
    preOccupiedStock?: number;
    purchasePrice?: number;
    retailPrice?: number;
    sellStockNum?: number;
    soldNum?: number;
    specs?: Spec[];
    status?: number;
    stockLocked?: number;
    weight?: number;
    maxPerPersonCode?: number;
}

export interface Brand {
    brandName?: string;
    id?: number;
    introduction?: string;
    level?: number;
    logo?: string;
    parentId?: number;
    status?: number;
    storeId?: number;
}

export interface Category {
    id?: number;
    level?: number;
    name?: string;
    parentId?: number;
    pathIds?: string;
    sellerId?: number;
    sort?: number;
    status?: number;
}

export interface Classify {
    children?: ClassifyChildren[];
    hasChild?: boolean;
    id?: number;
    name?: string;
    level?: number;
    parentId?: number;
    picture?: string;
    sort?: number;
    status?: number;
    storeId?: number;
    typeName?: string;
}

export interface ClassifyChildren {

}

export interface Item {
    minOrderQuantity?: number;//起订倍数
    barcode?: string; //条形码
    code?: string; //编码
    costPrice?: number; //成本价
    deliveryPrice?: number; //交货价格
    id?: string;
    maxPerOrder?: number; //每单限购
    pointPrice?: number; //兑换所需庭豆
    maxPerPersonMonth?: number; //每月最大领取数量
    maxPerPerson?: number; //每人限购
    originPrice?: number; //原价
    preOccupiedStock?: number; //
    purchasePrice?: number; //购买价格
    retailPrice?: number; //零售价
    sellStockNum?: number; //卖方股票编号
    soldNum?: number;//销量
    specs?: Spec[];//
    status?: number; //状态
    stockLocked?: number; //出售
    weight?: number; //重量
}

export interface RegionTemplate {
    id?: number | string;
    regionTemplateType?: string;
    regions?: string[];
    templateName?: string;
}

export interface SpecDefineTuple {
    id?: string;
    isShow?: boolean;
    specValueList?: SpecValueItem[];
    status?: number;
    storeId?: number;
    tenantId?: string;
    title?: string;
}

export interface SpecValueItem {
    createUser?: number;
    id?: number;
    imgUrl?: string;
    specId?: number;
    specValue?: string;
    status?: number;
    key?: any;
    tenantId?: string;
}

export interface classificationIds {
    id?: string;
    storeId?: number;
    typeName?: string;
    parentId?: number;
    level?: number;
    picture?: string;
    sort?: number;
    status?: number;
    hasChild?: boolean;
    children?: classificationIdItem[];
}

export interface classificationIdItem {
    id?: string;
    storeId?: number;
    name?: string;
    parentId?: number;
    level?: number;
    picture?: string;
    sort?: number;
    status?: number;
    children?: any;
}


export interface Product extends BaseModel {
    achievementTemplate?: Achievement;
    startSoldTime?: string;
    pricingRules?: any;
    tagGroupIds?: string[];
    platform?: string;
    splitOrderType?: {
      id?: string | number;
    }
    lastModifiedDate?: string;
    tags?: string[];
    customerLevelNo?: string;
    memberPriceAvailable?: boolean;
    installment?: boolean;
    blueStarProduct?: boolean;
    onScheduled?: string;
    offScheduled?: string;
    banner?: string;
    barcode?: string;
    barcodeList?: string;
    brand?: Brand;
    category?: Category;
    categoryIdPath?: any;
    classify?: Classify;
    classifyIdPath?: any;
    code?: string;
    deductStock?: number;
    deliverySetting?: string;
    deliveryTemplateId?: string;
    deliveryTemplateType?: string;
    deliveryType?: number;
    detail?: string;
    excludeFromPromotion?: boolean;
    feature?: string;
    hideStock?: boolean;
    id?: number;
    inputTaxRate?: number;
    items?: Item[];
    liveGoods?: boolean;
    liveInventory?: boolean;
    mainPicture?: any;
    multipleSpec?: boolean;
    name?: string;
    originPrice?: number;
    outputTaxRate?: number;
    pictures?: string;
    postage?: number;
    regionTemplate?: RegionTemplate;
    remark?: string;
    renewalCharge?: boolean;
    retailPrice?: number;
    returnable?: boolean;
    reviewState?: number;
    salesVolume?: number;
    sellerId?: string;
    sellerName?: string;
    specList?: SpecDefineTuple[];
    status?: string;
    subtitle?: string;
    unit?: string;
    unsaleable?: boolean;
    video?: string;
    virtualProduct?: boolean;
    warningDays?: number;
    onlineClassifyId?: string;
    freeShipping?: boolean;
    freeFreightOrderAmount?: number;
    product?: Product;
    cashCouponInfo?: {
        promotionId?: string | number;
        promotionName?: string;
    }
}

export interface LiveProduct extends Product {
    liveShelvesStatus?: boolean;
    liveSpuId?: string;
}

export interface AuditPim {
    status?: number;
    spuIds?: string[];
    auditReason?: string;
}

export interface UpUnderPim extends AuditPim {
    undercarriage?: string;
}

export const ProductStatus = {
    '0': '全部',
    '10': '待上架',
    '25': '定时上架中',
    '30': '出售中',
    '40': '已售罄',
    '50': '已下架',
};

export const ColumnsProductStatus = {
    'PENDING': '待上架',
    'UN_CHECK': '待审核',
    'TIMING_SELLING': '定时上架',
    'SELLING': '出售中',
    'SOLD_OUT': '已售罄',
    'SHELF_DOWN': '已下架',
    'DELETED': '已删除',
    'REJECT': '审核被拒',
};

export const DeductStock: { [index: number]: string } = {
    1: '拍下减库存',
    2: '付款减库存',
};

export const DeliverySetting: { [index: number]: string } = {
    '0': '快递发货',
    '1': '同城配送',
    '2': '到店自提',
};

export const LiveGoods: { [index: string]: string } = {
    'false': '否',
    'true': '是',
};

export const UploadLiveGoods: { [index: string]: boolean } = {
    '否': false,
    '是': true,
};

export const Returnable: { [index: string]: string } = {
    'false': '不支持',
    'true': '支持',
};



export const LiveInventory: { [index: string]: string } = {
    'false': '启用',
    'true': '禁用',
};



export const defaultProduct: Readonly<Product> = {
  freeFreightOrderAmount: 0,
};
export const defaultSku: Readonly<Sku> = {};
