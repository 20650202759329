import { ModalForm, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Form } from "antd";
import { useEffect, useState } from "react";

const RechargeModel = (props: any) => {
    let { visible, record, onCancel, onRecharge, treeData, operationType } = props
    let [parentList, setParentList] = useState<any>(false);


    useEffect(() => {
        console.log(treeData)
        let list = treeData.map((item: any) => {
            return {
                label: item.title,
                value: item.key
            }
        })
        setParentList(list)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])
    const [form] = Form.useForm();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        }
    };


    return (
        <ModalForm
            title={operationType === 'add' ? '添加分组' : '编辑分组'}
            visible={visible}
            onVisibleChange={(e) => !e && onCancel()}
            width={400}
            form={form}
            initialValues={operationType === 'add' ? {} : record}
            layout='horizontal'
            {...formItemLayout}
            preserve={false}
            className="editePwd-Layout"
            onFinish={async (data: any) => {
                let newData = {
                    id: record.userId,
                    ...data
                }
                onRecharge(newData)
                return false
            }}
        >
            <ProFormSelect
                placeholder='上级分组'
                name="key"
                options={parentList}
            />
            <ProFormText
                name="title"
                label="分组名称"
                placeholder='分组名称'
            />
        </ModalForm>
    )
}

export default RechargeModel