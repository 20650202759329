import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import DeliveryDetails from './delivery-details';
import DeliveryEdit from './delivery-edit';
import DeliveryList from './delivery-list';

const Delivery: React.FC<RouteComponentProps> = () => (
  <Router>
    <DeliveryList path="/" />
    <DeliveryDetails path=":id" />
    <DeliveryEdit path=":id/edit" />
    <DeliveryEdit path="new" />
  </Router>
);
export default Delivery;
