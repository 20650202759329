import { BaseModel } from "../base.model";

export interface Role extends BaseModel {
    name?: string;
    code?: string;
    permissions?: Permissions[];
}

interface Permissions {
    id?: number;
    restfulUrl?: any;
    rights?: number;
}

export const defaultRole: Readonly<Role> = {};