import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import AchievementDetails from "./achievement-details";
import { deleteEntity, getEntities, getEntity } from "./achievement.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import AchievementEdit from "./achievement-edit";
import { Achievement } from "model/product/achievement.model";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";


export interface AchievementListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Achievement>[] = [{
  title: 'id',
  dataIndex: 'id',
}, {
  title: '名称',
  dataIndex: 'name',
}, {
  title: '是否计算庭豆',
  dataIndex: 'calculatePoint',
  render: (dom, record) => record.calculatePoint ? '是' : '否'
}]
const AchievementList: FC<AchievementListProps> = (props) => {
  const searchFields = [{
    label: '业绩模版名称',
    name: 'name.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch placeholder='商家' required={false} name="storeId.equals" width="md" labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Achievement>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<AchievementDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      editPage={(params: any) => <AchievementEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ achievement }: RootState) => ({
  entity: achievement.entity,
  entities: achievement.entities,
  loading: achievement.loading,
  updateSuccess: achievement.updateSuccess,
  createSuccess: achievement.createSuccess,
  total: achievement.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AchievementList);