import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { Spec, defaultSpec } from 'model/product/spec.model';
import axios from 'axios';

const entity = 'Spec';
const apiUrl = '/api/yumei-mall-pim/product/spuSku';
const standardReducerImpl = new StandardReducerImpl<Spec>(entity, defaultSpec, apiUrl);
export type SpecState = StandardState<Spec>;
export const spec = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const editSpec = async (params: any) => {
    return await axios.put<any>(`/api/yumei-mall-pim/product/updateSpuSku`, params);
}


