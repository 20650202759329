import { BaseModel } from 'model/base.model';
import { DateFormat } from 'utils/utils';
export interface dataField {
  isUse?: number;
  isDefault?: number;
  bgColor?: string;
  description?: string;
  title?: string;
  type?: number;
  category?: string;
  gmtStart?: string;
  bg_color?: string;
  color?: string;
  font_size?: number;
  content?: string;
  imageList?: any[];
}


export interface Decorate extends BaseModel {
  id?: string;
  isDefault?: number;
  isUse?: number;
  sort?: number;
  storeId?: number;
  title?: string;
  type?: number;
  updateTime?: number;
  pageConfig?: { type: string, dataField: dataField }[]
}
export const tureOrFalse = {
  'false': '否',
  'true': '是'
}

//默认可以设置的组件列表
export const defalutModuleList: any = [
  'config',
  'notice',
  'popup',
  'floating_frame',
  'image_carousel',
  'image_carousel_fill',
  'tab_nav',
  'image_ad',
  'goods-cube',
  'goods-cube-fill',
  'image_area',
  'auxiliary_blank',
  'auxiliary_line',
  'title_text',
  'goods',
  'list_nav',
]

//新的页面类型定义
export const pageTypeMap: any = {
  '1': {
    title: '商城中心',
    modules: [
      ...defalutModuleList
    ],
  },
  '2': {
    title: '会员中心',
    modules: [
      'image_carousel_fill'
    ]
  },

  '6': {
    title: '开屏广告',
  },


  '999': {
    title: '自定义页',
    modules: [
      ...defalutModuleList,
      'tingmimi_hot',
      'main_meeting',
    ],
  },
}


//平台下可配置的页面类型
export const pageTypeForPlatform: any = {
  'taobuting': {
    name: '美人计',
    list: [
      '1',
      '2',
      '999',
      '21',
    ]
  },
  'tingmimi': {
    name: '玉美',
    list: [
      '1001',
      '1002',
      '1003',
      '1004',
      '1005',
      '1011',
      '1012',
      '1013',
      '1014',
      '999',
      '1015',
      '1016',
      '1017'
    ]
  },
  'tingmalaysia': {
    name: '庭马来',
    list: [
      '1001',
      '1002',
      '1003',
      '1011',
      '1012',
      '999',
    ]
  },
  'tingtaiwan': {
    name: '庭台湾',
    list: [
      '1001',
      '1002',
      '1003',
      '1011',
      '1012',
      '999',
    ]
  },
  'tingsingapore': {
    name: '庭新加坡',
    list: [
      '1001',
      '1002',
      '1003',
      '1011',
      '1012',
      '999',
    ]
  },
  'oceane-mall': {
    name: '欧希纳会员奢雅会',
    list: [
      '1',
      '2',
      '999',
      '21',
    ]
  },
}
export const TenantMap: any = {
  'tbt': 'taobuting',
  'tst': 'tingmimi',
  'tmm': 'tingmimi',
  'tml': 'tingmalaysia',
  'ttw': 'tingtaiwan',
  'tsg': 'tingsingapore',
  'oxn': 'oceane-mall',
}

type languageProps = {
  language: 'zh-CN' | 'zh-TW' | 'en';
  name: String;
}
//默认语言
export const defaultLanguageMap: { [Key: string]: (languageProps)[] } = {

  'taobuting': [
    {
      'language': 'zh-CN',
      'name': '简体中文',
    }
  ],
  'tingmimi': [
    {
      'language': 'zh-CN',
      'name': '简体中文',
    }
  ],
  'tingmalaysia': [
    {
      'language': 'en',
      'name': 'English',
    },
    {
      'language': 'zh-CN',
      'name': '简体中文',
    }
  ],
  'tingtaiwan': [
    {
      'language': 'zh-TW',
      'name': '繁体中文',
    }
  ],
  'tingsingapore': [
    {
      'language': 'en',
      'name': 'English',
    },
    {
      'language': 'zh-CN',
      'name': '简体中文',
    }
  ],
}
//默认平台
export const defaultPlatform = 'tingmalaysia'


export const pageTypeMapEnum: any = () => {
  const pageTypeMapEnum: any = {};
  for (let k in pageTypeMap) {
    pageTypeMapEnum[k] = pageTypeMap[k].title
  }
  return pageTypeMapEnum
}

export const typeMapEnumMin: any = {
  11: '玉美首页',
  1: '美人计首页',
  999: '自定义页',
  12: '主会场',
  2: '会员中心',
  21: '开屏广告',
  '1001': 'APP首页',
  '1002': '商城首页',
  '1003': '欧希纳首页',
  '1004': '主会场',
  '1011': '个人中心',
  '1012': '开屏广告',
  '1013': '庭豆首页',
  '1015': '天音卡商城',
  // 0: '页内商品组',
  // 3: '分销中心',
  // 4: '商品详情页',
  // 5: '积分商城首页',
  // 6: '公告',
  // 10: '购物车',
}

// 所有可用的组件
export const modulesList = [
  {
    type: 'config', // 组件名称标识
    classify: 'basis',//组件分类 basis基本组件、market营销组件
    title: '配置',
    isHide: true, // 是否隐藏选择
    isHeader: true, // 是否为头部
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计',
      style: 'light',//dark 页面风格
      startDate: '',
      endDate: ''
    }
  },
  {
    type: 'notice',
    classify: 'basis',
    title: '公告信息',
    defaultConfig: {
      "bg_color": "transparent",
      "color": "#666666",
      "font_size": 14,
      "padding": 10,
      "border_radius": 2,
      "list": [
        // {content:''}
      ],
      "iconStyle": "dark",
      "interval": 3000
    }
  },
  {
    type: 'popup',
    classify: 'basis',
    title: '弹窗',
    defaultConfig: {
      "name": "",//名称
      "img": "",//弹出图片
      "link": "",//链接
      "openType": "navigateTo",//打开方式
      "delay": 0.5,//延时s 页面加载完成后多久显示
      "duration": 0,//显示时间s 0代表，需手动关闭
      "width": 260,//宽度
      "nextInterval": 24,//弹出间隔时间 hour
      "top": 25,//距离顶部百分比
      "identity": "all",//身份 all  nologin 未登录用户 login 所有登录用户 blueCardOnly 仅蓝卡 blueCard 蓝卡及以上 redCard 红卡
      "platform": "all",//可见平台 all 全部 app APP wxapp 小程序
    }
  },

  {
    type: 'image_carousel',
    classify: 'basis',
    title: '图片轮播',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计',
      bgImage: '',
      margin_top: 10,
      margin_bottom: 10,
      height: 135,
      imageList: []
    }
  },

  {
    type: 'image_carousel_fill',
    classify: 'basis',
    title: '图片轮播(通屏)',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计',
      imageList: [],
      height: 135
    }
  },
  {
    type: 'tab_nav',
    classify: 'basis',
    title: '导航分类',
    defaultConfig: {
      "nav": [],
      // {"hint":"","icon":"","link":"type=hot","title":"推荐"}
      "set": { "bg_color": "#fff", "line_num": 3, "font_color": "#222", "type": 2 }
    }
  },

  {
    type: 'title_text',
    classify: 'basis',
    title: '标题文本',
    defaultConfig: {
      "bgColor": "#f9f9f9",
      "titleColor": { "a": 1, "r": 226, "b": 26, "g": 26 },
      "title": "",
      "descColor": '#1890ff',
      "desc": ""
    }
  },
  {
    type: 'goods',
    classify: 'basis',
    title: '商品组',
    showMask: false,
    defaultConfig: {
      "listStyle": {
        "selected": "card"
      },
      // "goodsStyle": { "name": [{ "value": "无底白边", "key": "white" }, { "value": "卡片投影", "key": "card-shadow" }, { "value": "描边白底", "key": "border-white" }, { "value": "无边透明底", "key": "transparent" }], "selected": "white" }, 
      // "textStyle": { "icon": "icon-text", "name": [{ "value": "常规体", "key": "normal" }, { "value": "加粗", "key": "bold" }], "selected": "normal" }, 
      "goodsIds": [],
    }
  },


  {
    type: 'image_ad',
    classify: 'basis',
    title: '图片广告',
    defaultConfig: {
      "nav": [],
      // {"img":"","hint":"","link":"","title":""}
      "set": { "bg_color": "#fff", "image_padding": 0, "margin_top_bottom": 0, "type": 1 }
    }
  },
  {
    type: 'goods-cube',
    classify: 'basis',
    title: '图片魔方',
    defaultConfig: {
      "boxIndex": 0,
      "nameIndex": 5,
      "imgSpacing": 0,
      "icon": "",
      // "name":[
      //   {"count":2,"icon":"icon-yihangliangge","tip":["450px*450px","450px*450px"],"value":"一行两个","key":"cube1-2"},
      //   {"count":3,"icon":"icon-yihangsange","tip":["300px*300px","300px*300px","300px*300px"],"value":"一行三个","key":"cube1-3"},
      //   {"count":4,"icon":"icon-yihangsige","tip":["225px*225px","225px*225px","225px*225px","225px*225px"],"value":"一行四个","key":"cube1-4"},
      //   {"count":2,"icon":"icon-a-yihangliangge2","tip":["450px*225px","450px*225px"],"value":"一行两个2","key":"cube1-2_2nd"},
      //   {"count":4,"icon":"icon-lianghangsige","tip":["450px*450px","450px*450px","450px*450px","450px*450px"],"value":"两左两右","key":"cube2-2"},
      //   {"count":3,"icon":"icon-zuoyiyouer","tip":["900px*450px","450px*450px","450px*450px"],"value":"一左两右","key":"cube-1-1-2"},
      //   {"count":4,"icon":"icon-zuoyiyousan","tip":["450px*450px","450px*450px","225px*450px","225px*450px"],"value":"一左三右","key":"cube-1-1-3"},
      //   {"count":4,"icon":"icon-zuoyiyousanchang","tip":["360px*450px","540px*225px","270px*225px","270px*225px"],"value":"一左三右2","key":"cube-1-1-3_2nd"},
      //   {"count":4,"icon":"icon-yihangliangge","tip":["450px*450px","450px*225px","225px*225px","225px*225px"],"value":"一左三右3","key":"cube-1-1-3_3rd"},
      //   {"count":3,"icon":"icon-shangyixiaer","tip":["900px*450px","450px*450px","450px*450px"],"value":"一上两下","key":"cube-2-1-2"},
      //   {"count":4,"icon":"icon-yishangsanxia","tip":["900px*180px","675px*300px","675px*300px","675px*300px"],"value":"一上三下","key":"cube1-3_2"}
      // ],
      "styleList": [
        {
          "type": "column",//vertical 纵向/左右结构  horizontal 横向/上下结构 column 
          "count": 2,
          "position": {
            "top": [
              {
                "width": 173,
                "height": 173,
              },
              {
                "width": 173,
                "height": 173,
              },
            ]
          },
          "name": "一行两个 1:1",
          "id": 'column-1-2'
        },
        {
          "type": "column",//vertical 纵向/左右结构  horizontal 横向/上下结构 column 
          "count": 2,
          "position": {
            "top": [
              {
                "width": 173,
                "height": 86,
              },
              {
                "width": 173,
                "height": 86,
              },
            ]
          },
          "name": "一行两个 2:1",
          "id": 'column-1-2_'
        },
        {
          "type": "column",//vertical 纵向/左右结构  horizontal 横向/上下结构 column 
          "count": 3,
          "position": {
            "top": [
              {
                "width": 113,
                "height": 113,
              },
              {
                "width": 113,
                "height": 113,
              },
              {
                "width": 113,
                "height": 113,
              },
            ]
          },
          "name": "一行三个",
          "id": 'column-1-3'
        },
        {
          "type": "horizontal",//vertical 纵向/上下结构  horizontal 横向/左右结构 column 
          "count": 3,
          "position": {
            "left": [
              {
                "width": 173,
                "height": 173,
              },
            ],
            "right": [
              {
                "width": 173,
                "height": 82,
              },
              {
                "width": 173,
                "height": 82,
              },
            ]
          },
          "name": "左一右二",
          "id": 'horizontal-1-2'
        },
        {
          "type": "horizontal",//vertical 纵向/上下结构  horizontal 横向/左右结构 column 
          "count": 4,
          "position": {
            "left": [
              {
                "width": 173,
                "height": 82,
              },
              {
                "width": 173,
                "height": 82,
              },
            ],
            "right": [
              {
                "width": 173,
                "height": 82,
              },
              {
                "width": 173,
                "height": 82,
              },
            ]
          },
          "name": "左二右二",
          "id": 'horizontal-2-2'
        },
        {
          "type": "vertical",//vertical 纵向/上下结构  horizontal 横向/左右结构 column 
          "count": 4,
          "position": {
            "top": [
              {
                "width": 355,
                "height": 113,
              },
            ],
            "bottom": [
              {
                "width": 113,
                "height": 113,
              },
              {
                "width": 113,
                "height": 113,
              },
              {
                "width": 113,
                "height": 113,
              },
            ]
          },
          "name": "上一下三",
          "id": 'vertical-1-3'
        }
      ],
      "pagePadding": 0,
      "selected": "column-1-2",
      "imgList": [
        // {"imgUrl":"http://yumei-mall-1304216166.cos.ap-shanghai.myqcloud.com/upload/20211221/2c1f97cf9dc7a1f5723bcace7fdc55cf.jpeg","linkUrl":"","title":""},{"imgUrl":"","linkUrl":"","title":""},{"imgUrl":"","linkUrl":"","title":""}
      ]
    }
  },

  {
    type: 'goods-cube-fill',
    classify: 'basis',
    title: '图片魔方(通屏)',
    defaultConfig: {
      "boxIndex": 0,
      "nameIndex": 5,
      "imgSpacing": 0,
      "icon": "",

      "styleList": [
        {
          "type": "column",//vertical 纵向/左右结构  horizontal 横向/上下结构 column 
          "count": 2,
          "position": {
            "top": [
              {
                "width": 187.5,
                "height": 187.5,
              },
              {
                "width": 187.5,
                "height": 187.5,
              },
            ]
          },
          "name": "一行两个 1:1",
          "id": 'column-1-2'
        },
        {
          "type": "column",//vertical 纵向/左右结构  horizontal 横向/上下结构 column 
          "count": 2,
          "position": {
            "top": [
              {
                "width": 187.5,
                "height": 93.75,
              },
              {
                "width": 187.5,
                "height": 93.75,
              },
            ]
          },
          "name": "一行两个 2:1",
          "id": 'column-1-2_'
        },
        {
          "type": "column",//vertical 纵向/左右结构  horizontal 横向/上下结构 column 
          "count": 3,
          "position": {
            "top": [
              {
                "width": 125,
                "height": 125,
              },
              {
                "width": 125,
                "height": 125,
              },
              {
                "width": 125,
                "height": 125,
              },
            ]
          },
          "name": "一行三个",
          "id": 'column-1-3'
        },
        {
          "type": "horizontal",//vertical 纵向/上下结构  horizontal 横向/左右结构 column 
          "count": 3,
          "position": {
            "left": [
              {
                "width": 187.5,
                "height": 187.5,
              },
            ],
            "right": [
              {
                "width": 187.5,
                "height": 93.75,
              },
              {
                "width": 187.5,
                "height": 93.75,
              },
            ]
          },
          "name": "左一右二",
          "id": 'horizontal-1-2'
        },
        {
          "type": "horizontal",//vertical 纵向/上下结构  horizontal 横向/左右结构 column 
          "count": 4,
          "position": {
            "left": [
              {
                "width": 187.5,
                "height": 93.75,
              },
              {
                "width": 187.5,
                "height": 93.75,
              },
            ],
            "right": [
              {
                "width": 187.5,
                "height": 93.75,
              },
              {
                "width": 187.5,
                "height": 93.75,
              },
            ]
          },
          "name": "左二右二",
          "id": 'horizontal-2-2'
        },
        {
          "type": "vertical",//vertical 纵向/上下结构  horizontal 横向/左右结构 column 
          "count": 4,
          "position": {
            "top": [
              {
                "width": 375,
                "height": 125,
              },
            ],
            "bottom": [
              {
                "width": 125,
                "height": 125,
              },
              {
                "width": 125,
                "height": 125,
              },
              {
                "width": 125,
                "height": 125,
              },
            ]
          },
          "name": "上一下三",
          "id": 'vertical-1-3'
        }
      ],
      "pagePadding": 0,
      "selected": "column-1-2",
      "imgList": [
        // {"imgUrl":"http://yumei-mall-1304216166.cos.ap-shanghai.myqcloud.com/upload/20211221/2c1f97cf9dc7a1f5723bcace7fdc55cf.jpeg","linkUrl":"","title":""},{"imgUrl":"","linkUrl":"","title":""},{"imgUrl":"","linkUrl":"","title":""}
      ]
    }
  },

  {
    type: 'image_area',
    classify: 'basis',
    title: '图片热区',
    showMask: false,
    defaultConfig: {
      "list": [
        // {
        //   "link":"",
        //   "openType":"",
        //   "position":{
        //     "width":0,
        //     "height":0,
        //     "left":0,
        //     "top":0
        //   }
        // }
      ],
      "img": ""
    }
  },
  {
    type: 'auxiliary_blank',
    classify: 'basis',
    title: '辅助空白',
    defaultConfig: {
      "bg_color": "transparent",
      "height": 10
    }
  },
  {
    type: 'auxiliary_line',
    classify: 'basis',
    title: '辅助线',
    defaultConfig: {
      "bg_color": "#f8f8f8",
      "line_color": "#000000",
      "line_style": "dashed",
      "line_height": 1,
      "line_margin": 15,
      // "height":20
    }
  },
  {
    type: 'list_nav',
    classify: 'basis',
    title: '列表导航',
    defaultConfig: {
      "nav": [
        { "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] },
        { "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] },
        { "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] },
        { "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] },
      ],
      // {"hint":"","icon":"","link":"","title":""}
      "set": { "bg_color": "#fff", "hint_color": "#000", "showTitle": false, "margin_top": 0, "title_color": "#000", "title": "" }
    }
  },
  {
    type: 'main_meeting',
    classify: 'basis',
    title: '活动日历',
    defaultConfig: {
      "start_time": "",
      "end_time": "",
      "identifier": 'tingmimihot',
      "listStyle": {
        "selected": 'list',
        // "list": [
        //   {
        //     "label": "大图",
        //     "value": "big-img"
        //   },
        //   {
        //     "label": "两列卡片",
        //     "value": "card"
        //   },
        //   {
        //     "label": "列表",
        //     "value": "list"
        //   },
        // ]
      },

    }
  },
  {
    type: 'tingmimi_hot',
    classify: 'basis',
    title: '热推活动',
    defaultConfig: {
      "nav": [
        {
          "name": "本月活动",
          "type": "month",
          "sort": 0,
          "start_time": "",
          "end_time": ""
        },
        {
          "name": "今日热推",
          "type": "today",
          "sort": 1,
        },
        {
          "name": "明日上新",
          "type": "tomorrow",
          "sort": 2,
        },
      ],
      "identifier": 'tingmimihot',
      "listStyle": {
        "selected": 'big-img',
        // "list": [
        //   {
        //     "label": "大图",
        //     "value": "big-img"
        //   },
        //   {
        //     "label": "两列卡片",
        //     "value": "card"
        //   },
        //   {
        //     "label": "列表",
        //     "value": "list"
        //   },
        // ]
      },
    }
  },
  {
    type: 'floating_frame',
    classify: 'basis',
    title: '浮标',
    defaultConfig: {
      "name": "",//名称
      "img": "",//弹出图片
      "link": "",//链接
      "openType": "navigateTo",//打开方式
      "width": 260,//宽度
      "nextInterval": 24,//弹出间隔时间 hour
      "top": 25,//距离顶部百分比
      "identity": "all",//身份 all  nologin 未登录用户 login 所有登录用户 blueCardOnly 仅蓝卡 blueCard 蓝卡及以上 redCard 红卡
      "platform": "all",//可见平台 all 全部 app APP wxapp 小程序
    }
  },
  {
    type: 'collage_list_nav',
    classify: 'basis',
    title: '拼团列表',
    defaultConfig: {
      "nav": [
        { "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] },
        { "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] },
        { "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] },
        { "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] },
      ],
      // {"hint":"","icon":"","link":"","title":""}
      "set": { "bg_color": "#fff", "hint_color": "#000", "showTitle": false, "margin_top": 0, "title_color": "#000", "title": "" }
    }
  },
  {
    type: 'custom_area',
    classify: 'basis',
    title: '自定义区',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计'
    }
  },
  {
    type: 'page_tab',
    classify: 'basis',
    title: '页面选项',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计'
    }
  },
  {
    type: 'column-nav',
    classify: 'basis',
    title: '栏目导航',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计'
    }
  },
  {
    type: 'active-seckill',
    classify: 'market',
    title: '秒杀组件',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计'
    }
  },
  {
    type: 'active-group',
    classify: 'market',
    title: '拼团组件',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计'
    }
  },
  {
    type: 'coupon-scroll',
    classify: 'market',
    title: '优惠券',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计'
    }
  },

  {
    type: 'recommendation_list',
    classify: 'basis',
    title: '推荐列表',
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计'
    }
  },
  // {// 公共广告头部配置
  //   type: 'config_common_ad',
  //   classify: 'basis',
  //   title: '配置',
  //   isHide: true, // 是否隐藏选择
  //   isHeader: true, // 是否为头部
  //   defaultConfig: {
  //     bgColor: '#f9f9f9',
  //     title: '美人计'
  //   }
  // },
  // {
  //   type: 'search',
  //   classify: 'basis',
  //   title: '搜索框',
  //   defaultConfig: {
  //     bgColor: '#f9f9f9',
  //     title: '美人计'
  //   }
  // },

  // {
  //   type: 'shop',
  //   classify: 'basis',
  //   title: '店铺列表',
  //   defaultConfig:{
  //     bgColor:'#f9f9f9',
  //     title:'美人计'
  //   }
  // },
  // {
  //   type: 'goods-scroll',
  //   classify: 'basis',
  //   title: '滚动商品',
  //   defaultConfig:{
  //     bgColor:'#f9f9f9',
  //     title:'美人计'
  //   }
  // },
  // {
  //   type: 'classification_list',
  //   classify: 'basis',
  //   title: '商品分类',
  //   isHide: true,
  //   defaultConfig: {
  //     bgColor: '#f9f9f9',
  //     title: '美人计'
  //   }
  // },

  // {
  //   type: 'user-info',
  //   classify: 'basis',
  //   title: '会员信息',
  //   defaultConfig:{
  //     bgColor:'#f9f9f9',
  //     title:'美人计'
  //   }
  // },
  // {
  //   type: 'order-nav',
  //   classify: 'basis',
  //   title: '订单导航',
  //   defaultConfig:{
  //     bgColor:'#f9f9f9',
  //     title:'美人计'
  //   }
  // },
  // {
  //   type: 'distribution-user-info',
  //   classify: 'basis',
  //   title: '分销会员信息',
  // },
  // {
  //   type: 'distribution-group-statistics',
  //   classify: 'basis',
  //   title: '分销会员团队',
  // },
  // {
  //   type: 'distribution-order-statistics',
  //   classify: 'basis',
  //   title: '分销会员订单',
  // },

  // {
  //   type: 'goods-image-carousel',
  //   classify: 'basis',
  //   title: '商品展示图片',
  // },
  // {
  //   type: 'goods-info',
  //   classify: 'basis',
  //   title: '商品基础信息',
  // },
  // {
  //   type: 'goods-serve',
  //   classify: 'basis',
  //   title: '商品服务',
  // },
  // {
  //   type: 'goods-grade',
  //   classify: 'basis',
  //   title: '商品评分',
  // },
  // {
  //   type: 'goods-introduce',
  //   classify: 'basis',
  //   title: '商品介绍',
  // },
  // {
  //   type: 'goods-evaluate',
  //   classify: 'basis',
  //   title: '商品评价',
  // },
  // {
  //   type: 'goods-group',
  //   classify: 'basis',
  //   title: '商品拼团',
  // },

  // {
  //   type: 'daily_recommendation',
  //   classify: 'basis',
  //   title: '今日推荐',
  // },
  // {
  //   type: 'deserve_look',
  //   classify: 'basis',
  //   title: '值得一看',
  // },

  // {
  //   type: 'recommendation_animation',
  //   classify: 'basis',
  //   title: '动效滚动',
  // },
]

export const deepCloneObject = (obj: { [key: string]: any }) => {
  let _return: any = {}
  for (let k in obj) {
    if (obj[k] instanceof Array) {
      _return[k] = obj[k].map((v: any) => {
        if (v instanceof Object) {
          return deepCloneObject(v)
        } else {
          return v
        }
      })
    } else if (obj[k] instanceof Object) {
      _return[k] = deepCloneObject(obj[k])
    } else {
      _return[k] = obj[k]
    }
  }
  return _return
}

//生产模块ID
export const createModuleId = () => {
  return DateFormat(new Date(), 'yyyyMMddhhmmss')

}

export const goodsStyleList = [
  {
    "label": "两列卡片",
    "value": "card"
  },
  {
    "label": "横向滚动",
    "value": "slide-x"
  },
  {
    "label": "大图",
    "value": "big-img"
  },
  {
    "label": "列表",
    "value": "list"
  },
]

export const collageList = [
  {
    "label": "拼团列表",
    "value": "collageList"
  }
]

export const goodsStyleListSimple = [
  {
    "label": "大图",
    "value": "big-img"
  },
  {
    "label": "列表",
    "value": "list"
  },
]
export const defaultDecorate: Readonly<Decorate> = {
};


