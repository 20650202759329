import { FC, useEffect, memo, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { getPageDetail, savePageData, updateMoreState, changeLanguage, changePlatform } from "./examplatform-edit.reducer";
import ModulesList from '../examcomponents/modules-list';
import PagePreview from '../examcomponents/page-preview';
import ModulesSetting from '../examcomponents/modules-setting';
import { defaultLanguageMap, TenantMap } from 'model/decorate/examdecorate.model';
import { examPageTypeList, updateUseState } from '../api'
import './platform-edit.less';
import { message, Spin, PageHeader, Tag, Button, Modal, Switch, Radio } from 'antd';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Storage } from 'utils/storage-util';

export interface DecorateEditProps extends StateProps, DispatchProps, RouteComponentProps {
  id?: string
}

const ExamDecorateEdit: FC<DecorateEditProps> = memo(({ id, getPageDetail, pageData, pageConfig, loading, savePageData, platform, pageLoading, updateMoreState, changeLanguage, language, pageConfigGlobal, changePlatform }) => {
  let [configModule, setConfigModule] = useState<{ [Key: string]: any }>({})
  const [sequence] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'])
  const [pageType, setPageType] = useState<any>('');

  // 获取页面类型
  const getpageType = async () => {
    const data: any = await examPageTypeList();
    let list = data.data.map((item: any) => ({
      value: item?.id,
      label: item?.name,
    }))
    if(list.length > 0) {
      let fond = list.find(((item:any)=> item.value=== pageData.type))

      setPageType(fond?.label || '')
    }
  }

  useEffect(() => {
    let Tenant: string = Storage.session.get('x-tenant') || 'tbt', newPlatform = TenantMap[Tenant] || 'taobuting';
    changePlatform(newPlatform)
    changeLanguage(defaultLanguageMap[newPlatform][0].language)
    if (!platform && !newPlatform) return
    if (!id) {
      getpageType()
      const title = '自定义页面-' + (+new Date()),
        pageConfig = [{
          type: 'config',
          id: 'config_0',
          dataField: {
            bgColor: '#f9f9f9',
            title
          }
        }]
      updateMoreState({
        pageConfig,
        pageData: {
          pageType: 999,
          title: title,
          pageConfig,
          isUse: '0'
        },
        pageConfigGlobal: {
          [defaultLanguageMap[platform || newPlatform][0].language]: pageConfig
        }
      })
      return
    }
    getPageDetail(id || '')



    return () => {
      updateMoreState({
        insertPositionId: 'none',
        pageConfig: [],
        pageConfigGlobal: {},
        pageData: { type: 0, pageConfig: [] },
        loading: false,
        pageLoading: false,
        settingModuleId: 'none',
        popupData: {},
        language: 'zh-CN',
        platform: ''
      })
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getPageDetail])
  useEffect(() => {
    let configModule: any = pageConfig.filter((v: any) => v.type === 'config')
    if (configModule && configModule[0]) {
      setConfigModule(configModule[0].dataField)
    }
  }, [pageConfig, updateMoreState])
  //改变页面使用状态
  const changeUseState = (params: { isUser: boolean, id: string | number }) => {
    Modal.confirm({
      title: '提示',
      content: `确认${params.isUser === true ? '使用' : '取消使用'}“${pageData.title}”页？`,
      onOk() {
        updateUseState({
          id: params.id,
          isUser: params.isUser
        }).then((res: any) => {
          if (res.data) {
            updateMoreState({
              pageData: {
                ...pageData,
                isUser: params.isUser,
              }
            })
            message.success(`页面${!params.isUser ? '取消使用' : '使用'}成功`);
          }
        })
      }
    });
  }

  //语言切换
  const handleLanguageChange = (e: any) => {
    changeLanguage(e.target.value)
  }
  //标签列表
  const tagsList = () => {
    let tags = [
      <Tag color="green">{pageType ? '' : '未知页面类型'}{pageType}</Tag>,
      pageData.isUser === true ? <Tag color="green">已使用</Tag> : <Tag color="red">未使用</Tag>,
      id ? <Switch checkedChildren="启用" onChange={
        (e) => {
          changeUseState({
            isUser: e,
            id: id
          })
        }
      } unCheckedChildren="关闭" checked={pageData.isUser} /> : <span></span>,
      <Radio.Group onChange={handleLanguageChange} value={language} style={{ marginLeft: 20 }}>
        {
          defaultLanguageMap[platform].length > 1 && defaultLanguageMap[platform].map((v: any) => {
            return <Radio.Button value={v.language}>{v.name}</Radio.Button>
          })
        }

      </Radio.Group>
    ]
    return tags
  }

  return (
    <div className="decorate-edit-container">
      {
        !loading && platform &&
        <PageHeader className="page-header"
          title={configModule && configModule.title && (configModule && configModule.title.length <= 18 ? configModule.title : configModule.title.slice(0, 18) + '...')}
          tags={configModule ? tagsList() : []}
          extra={configModule ? [
            id ? <Button key="2" onClick={
              () => {
                getPageDetail(pageData.id)
              }
            }>刷新</Button> : "",
            <Button key="1" type="primary" onClick={
              () => {
                let enrollTextList: any = []
                pageConfig.forEach((item: any, index: number) => {
                  if (item.type !== 'config') {
                    enrollTextList.push({
                      name: item.dataField.title,
                      type: item.type === 'single_choice_question' ? 6 : item.type === 'multiple_choice_questions' ? 7 : item.type === 'title_text' ? 1 : '',
                      fraction: item.dataField.questionScore,
                      sort: index + 1,
                      correct: (item.type === 'multiple_choice_questions' && item.dataField.answer instanceof Array) ? item.dataField.answer.join(',') : item.dataField.answer,
                      correctDesc: item.dataField.analysis,
                      examContentItemDtoList: item.dataField.testquestions.map((qitem: any, qindex: number) => {
                        return {
                          optionDescription: qitem,
                          option: sequence[qindex]
                        }
                      })
                    })
                  }
                });
                console.log(pageData)
                console.log('+++++++++++++++++++++++++')
                pageData.examItemDto = pageData.examItemDto || pageData.examItemVO
                pageData.title = configModule.title
                if (!pageData.examItemDto || !pageData.examItemDto.examTime || !pageData.examItemDto.isRetry || !pageData.examItemDto.retryNum || !pageData.type || !pageData.examItemDto.examLevel || !pageData.examItemDto.sortType) {
                  message.warn('页面设置信息请完善信息')
                  return false
                }

                console.log({
                  ...pageData,
                  enrollTextList: enrollTextList,
                })
                pageData.examItemDto.examLevel = pageData.examItemDto.examLevel.join(',')
                savePageData({
                  ...pageData,
                  enrollTextList: enrollTextList
                })
              }
            }>保存</Button>,
          ] : []}
        ></PageHeader>
      }
      {
        pageLoading && <div className="page-loading"><Spin size="large" /></div>
      }
      {loading ? <div className="loading"><Spin size="large" /></div>
        : (
          <DndProvider backend={HTML5Backend}><div className="decorate-container" >
            <ModulesList type="all" />
            <PagePreview />
            <ModulesSetting />
          </div></DndProvider>)}
    </div>);
});

const mapStateToProps = ({ examDecorateEdit }: RootState) => ({
  pageData: examDecorateEdit.pageData,
  pageConfig: examDecorateEdit.pageConfig,
  pageConfigGlobal: examDecorateEdit.pageConfigGlobal,
  language: examDecorateEdit.language,
  platform: examDecorateEdit.platform,
  pageLoading: examDecorateEdit.pageLoading,
  loading: examDecorateEdit.loading
});

const mapDispatchToProps = {
  getPageDetail,
  savePageData,
  updateMoreState,
  changeLanguage,
  changePlatform,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExamDecorateEdit);
