import React from 'react';
import { ProFormText } from '@ant-design/pro-form';

export interface SearchField {
  name: string;
  label: string;
  required?: boolean;
  // value: string;
  operator?: 'contains' | 'equals';
  dataType?: 'text' | 'number';
  often?: boolean;//常用
}

export interface SearchBoxProps {
  name?: string;
  label?: string;
  operator?: string;
  width?: string | number;
  fields?: SearchField[];
  onChange?: (value: any) => void;
}

const SearchBox: React.FC<SearchBoxProps> = (props) => {
  const {
    fields = [],
  } = props;

  return (<>
    {fields && fields.map((item) => {
      return <div>
        <ProFormText
          rules={[{ required: item.required || false }]}
          name={!!item.operator ? (item.name + '.' + item.operator) : item.name}
          placeholder={item.label}
        />
      </div>
    })}
  </>);
};

export default SearchBox;