import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import PointDetails from "./point-details";
import { deleteEntity, getEntities, getEntity } from "./point.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import PointEdit from "./point-edit";
import { Point } from "model/product/point.model";


export interface PointListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Point>[] = [{
  title: '名称',
  dataIndex: 'name',
}, {
  title: '积分',
  dataIndex: 'point',
}]
const PointList: FC<PointListProps> = (props) => {
  const searchFields = [{
    label: '名称',
    name: 'name.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Point>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<PointDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <PointEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ point }: RootState) => ({
  entity: point.entity,
  entities: point.entities,
  loading: point.loading,
  updateSuccess: point.updateSuccess,
  createSuccess: point.createSuccess,
  total: point.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PointList);