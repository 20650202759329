import React, { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ActiveUserDetails from "./user-details";
import { deleteEntity, getEntities, getEntity } from "./user.reducer";
import { RouteComponentProps } from "@reach/router";
import ActiveUserEdit from "./user-edit";
import { ActiveUser } from 'model/customerCore/active-user.model';

export interface ActiveUserListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const ActiveUserList: FC<ActiveUserListProps> = (props) => {

  const columns: ProColumns<ActiveUser>[] = [{
    title: '用户id',
    dataIndex: 'userId',
  }, {
    title: '用户名称',
    dataIndex: 'userName',
  }, {
    title: '是否会员',
    dataIndex: 'isMember',
    render: (text) => text ? '是' : '否'
  }, {
    title: '上级用户id',
    dataIndex: 'parentUserId',
  }, {
    title: '上级用户名称',
    dataIndex: 'parentUserName',
  }, {
    title: '最近登录时间',
    dataIndex: 'recentLoginTime',
  }];

  const searchFields = [{
    label: '用户id',
    name: 'userId.equals',
  }, {
    label: '上级用户id',
    name: 'parentUserId.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>);

  return (
    <ListViewPage<ActiveUser>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ActiveUserDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      hideCreateButton
      editPage={(params: any) => <ActiveUserEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ activeUser }: RootState) => ({
  entity: activeUser.entity,
  entities: activeUser.entities,
  loading: activeUser.loading,
  updateSuccess: activeUser.updateSuccess,
  total: activeUser.total,
  createSuccess: activeUser.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUserList);