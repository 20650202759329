import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './payee-enterprise.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { PayeeEnterprise } from "model/system/payee-enterprise.model";

export interface PayeeEnterpriseDetailsProps extends DetailsStateAndDispatchProps<PayeeEnterprise>,
  RouteComponentProps, StateProps, DispatchProps {
}
const PayeeEnterpriseDetails: FC<PayeeEnterpriseDetailsProps> = (props) => {
  return (
    <DetailsPage<PayeeEnterprise>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<PayeeEnterprise>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ payeeEnterprise }: RootState) => ({
  entity: payeeEnterprise.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PayeeEnterpriseDetails);
