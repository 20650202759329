import { AuthFlow, defaultAuthFlow } from 'model/contract/authFlow.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'AuthFlow';
const apiUrl = '/api/yumei-mall-customer/esign/auth-flow';
const standardReducerImpl = new StandardReducerImpl<AuthFlow>(entity, defaultAuthFlow, apiUrl);
export type AuthFlowState = StandardState<AuthFlow>;
export const authFlow = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

