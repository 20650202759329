import { FC } from "react"
import { Form, Row, Col, message } from "antd";
import { ModalForm, ProFormDatePicker, ProFormText } from "@ant-design/pro-form";
import UploadImgOne from "components/upload-img/upload-img-one";
import { modifylianlianAccount } from "./dealerInfo.reducer";

const AccountinfoEdite: FC<any> = props => {
    const { entity, accountinfoEditeShow, setAccountinfoEditeShow, reload } = props;
    const [form] = Form.useForm();
    return (
        <ModalForm
            title={'修改账户资料'}
            visible={accountinfoEditeShow}
            modalProps={{
                destroyOnClose: true,
            }}
            initialValues={entity}
            form={form}
            preserve={false}
            onFinish={async (data: any) => {
                return new Promise<boolean>((resolve, reject) => {
                    let entiyData = JSON.parse(JSON.stringify(data))
                if(entiyData.idCardFrontUrl) {
                    entiyData.idCardFrontUrl = entiyData.idCardFrontUrl.split('?')[0]
                }
                if(entiyData.idCardBackUrl) {
                    entiyData.idCardBackUrl = entiyData.idCardBackUrl.split('?')[0]
                }
                if(entiyData.creditCodeUrl) {
                    entiyData.creditCodeUrl = entiyData.creditCodeUrl.split('?')[0]
                }

                modifylianlianAccount({
                    ...entity,
                    ...entiyData
                }).then(e => {
                    message.success('修改成功');
                    setAccountinfoEditeShow(false)
                    reload()
                    resolve(true)
                  }).catch(error => {
                    resolve(false)
                    message.error(error?.response?.data?.message);
                  })
                });

               
            }}
            onVisibleChange={(e) => {
                setAccountinfoEditeShow(e)
            }}
        >
            {entity.type === 'ORG' &&
                <div>
                    <Row>
                        <Col span={11}>
                            <ProFormText
                                name="realName"
                                label="真实姓名"
                            />
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <ProFormText
                                name="mobile"
                                label="手机号"
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col span={11}>
                            <ProFormText
                                name="email"
                                label="邮箱"
                            />
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <ProFormText
                                name="customerIdCardNum"
                                label="身份证号"
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={11}>
                            <ProFormDatePicker
                                label='身份证到期日期'
                                placeholder='选择日期'
                                name='idCardExpiredDate'
                                width='md'
                            />
                        </Col>
                        <Col span={1} />
                        <Col span={11}>
                            <ProFormDatePicker
                                label='营业期限到期时间'
                                placeholder='选择日期'
                                name='tradeExpiredDate'
                                width='md'
                            />
                        </Col>
                    </Row>
                </div>
            }
            <Row>
                <Col span={11}>
                    <Form.Item name="idCardFrontUrl" label="身份证正面" >
                        <UploadImgOne
                         Immediate
                         category='private'
                         postfiximgpath={entity.code+'/id-card-face'}
                         requiredIdentifying={0}
                            listType="picture-card"
                            title="上传"
                        />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={11}>
                    <Form.Item name="idCardBackUrl" label="身份证反面" >
                        <UploadImgOne
                         category='private'
                         postfiximgpath={entity.code+'/id-card-back'}
                         Immediate
                            listType="picture-card"
                            title="上传"
                        />
                    </Form.Item>
                </Col>
            </Row>
            {entity.type === 'ORG' &&
                <Row>
                    <Col span={11}>
                        <Form.Item name="creditCodeUrl" label="营业执照" >
                            <UploadImgOne
                             category='private'
                             postfiximgpath={entity.code+'/credit-code'}
                             Immediate
                                listType="picture-card"
                                title="上传"
                            />
                        </Form.Item>
                    </Col>
                </Row>
            }
        </ModalForm>
    )
};

export default AccountinfoEdite

