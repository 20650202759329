import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, fetchCustomerGroupList, getEntity, reset, updateEntity } from "./group.reducer";
import { Group } from "model/customerCore/group.model";
import { ProFormDependency, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { Form } from "antd";
import SelectSearch from "components/select-search";

export interface GroupEditProps extends EditStateAndDispatchProps<Group>, StateProps, DispatchProps, RouteComponentProps {
}

const GroupEdit: FC<GroupEditProps> = (props) => {

  return (
    <EditPage<Group>
      title="客户组"
      {...props}
    >
      <ProFormText
        name="name"
        width="md"
        label="标签组名称"
        rules={[{ required: true }]}
      />
      <ProFormSwitch
        name={'reverseTag'}
        label="是否反向标签"
        initialValue={false}
        fieldProps={{
          checkedChildren: '是',
          unCheckedChildren: '否',
        }}
      />
      <ProFormDependency name={['reverseTag']}>
        {({ reverseTag }) => {
          return reverseTag &&
            <Form.Item name={'reverseTagId'}>
              <SelectSearch valueType="number" togetherParams={{'reverseTag.equals': false}} required={true} label="客户标签组" name={['reverseTagId']} width="sm" defaultParams='id.equals' fetchFunc={fetchCustomerGroupList} searchParams='name.contains' />
            </Form.Item>
        }
        }
      </ProFormDependency>
      <ProFormSwitch
        name={'enabled'}
        label="状态"
        initialValue={true}
        hidden
      />
    </EditPage>);
};

const mapStateToProps = ({ group }: RootState) => ({
  entity: group.entity,
  updateSuccess: group.updateSuccess,
  updating: group.updating,
  createSuccess: group.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GroupEdit);
