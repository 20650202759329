import { useState } from "react"
import { EditableProTable } from "@ant-design/pro-table";
import { ProColumns } from "@ant-design/pro-table";
import { Button, message, Popconfirm, Tag, Tooltip } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

export interface permissionsProps {
    onChange?: (value: any) => void;
    value?: any;
    editableColumns?: any;
    bindKey: string;
}

const EditPermissions = (props: permissionsProps) => {

    const { onChange, value, editableColumns, bindKey } = props;

    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);

    const readValueEnum: any = { read: { text: '读取', value: 'read' } };
    const writeValueEnum: any = { write: { text: '编辑', value: 'write' } };
    const deleteValueEnum: any = { delete: { text: '删除', value: 'delete' } };
    const operation: ProColumns<any>[] = [{
        title: '读取',
        valueType: 'checkbox',
        dataIndex: 'read',
        valueEnum: readValueEnum,
        render: (text, record) => (record.read && record.read.length > 0) ? <Tag color="green">允许</Tag> : <Tag color="red">禁止</Tag>
    }, {
        title: '编辑',
        valueType: 'checkbox',
        dataIndex: 'write',
        valueEnum: writeValueEnum,
        render: (text, record) => (record.write && record.write.length > 0) ? <Tag color="green">允许</Tag> : <Tag color="red">禁止</Tag>
    }, {
        title: '删除',
        valueType: 'checkbox',
        dataIndex: 'delete',
        valueEnum: deleteValueEnum,
        render: (text, record) => (record.delete && record.delete.length > 0) ? <Tag color="green">允许</Tag> : <Tag color="red">禁止</Tag>
    }, {
        title: '操作',
        valueType: 'option',
        render: (text, record, index, action) => {
            return <>
                <Tooltip title="编辑">
                    <Button type="link" onClick={() => {
                        action?.startEditable?.(record.id ? record.id : String(index));
                    }} icon={<EditOutlined />} />
                </Tooltip>
                <Popconfirm
                    title="确定要删除吗"
                    onConfirm={() => onChange?.(value.filter((item: any) => item.id !== record.id))}
                    okText="确定"
                    cancelText="取消"
                >
                    <Tooltip title="删除">
                        <Button danger type="link" icon={<DeleteOutlined />} />
                    </Tooltip>
                </Popconfirm>
            </>
        }
    }];

    return <EditableProTable<any>
        rowKey="id"
        headerTitle="权限列表"
        className="editable-proTable-table"
        columns={[...editableColumns, ...operation]}
        value={value}
        recordCreatorProps={{
            // 每次新增的时候需要Key
            record: () => ({ id: Date.now() }),
        }}
        editable={{
            type: 'multiple',
            editableKeys,
            onChange: setEditableRowKeys,
            onSave: (key: any, record: any, originRow: any,) => { 
                return new Promise<void>((resolve, reject) => {
                    const index = record.index;
                    record.key = Date.now();
                    const text = bindKey === 'role' ? '角色' : '资源';
                    if (record?.[bindKey]?.id) {
                        const roleIds = value.map((e: any) => e?.[bindKey]?.id);
                        const filter = roleIds.filter((e: any) => e === record?.[bindKey]?.id);
                        if ((index === roleIds.length && filter.length > 0) || filter.length > 1) {
                            message.error('角色已存在，请重新选择角色');
                            reject();
                        };
                        ((index === roleIds.length && filter.length === 0) || filter.length === 1) && resolve(record);
                    } else {
                        message.error(`请选择${text}`);
                        reject();
                    };
                })
            },
            onValuesChange: (record, recordList) => {
                const data = recordList.map((e, index) => {
                    !e.id && (e.id = new Date().getTime());
                    (e.id && (String(e.id).length !== String(new Date().getTime()).length)) && delete e.index;
                    return e;
                });
                onChange?.(data);
            },
            // actionRender: (row, config, defaultDom) => [defaultDom.save,defaultDom.delete],
        }}
    />
};
export default EditPermissions;