import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import LifePavilionDetails from './lifePavilion-details';
import LifePavilionList from './lifePavilion-list';

const LifePavilion: React.FC<RouteComponentProps> = () => (
  <Router>
    <LifePavilionList path="/" />
    <LifePavilionDetails path=":id" />
  </Router>
);
export default LifePavilion;
