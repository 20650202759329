import { Router, RouteComponentProps } from "@reach/router";
import AfterSalesList from './after-sales-list';
import React from 'react';
import AfterSalesDetails from './after-sales-details';

const AfterSales: React.FC<RouteComponentProps> = () => (
  <Router>
    <AfterSalesList path="/" />
    <AfterSalesDetails path=":id" />
  </Router>
);
export default AfterSales;
