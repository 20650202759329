import React from "react";
import {RouteComponentProps} from "@reach/router";
import {Button, Result} from "antd";

const NotFound: React.FC<RouteComponentProps> = (props) => {
  return (
    <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button type="primary">Back Home</Button>}
    />
  );
}

export default NotFound;
