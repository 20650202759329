import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import PublisherList from './publisher-list';
import PublisherEdit from "./publisher-edit";

const Publisher: React.FC<RouteComponentProps> = () => (
  <Router>
    <PublisherList path="/" />
    <PublisherEdit path=":id/edit" />
    <PublisherEdit path="new" />
  </Router>
);
export default Publisher;
