import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import DealerList from './dealer-list';
import RewardconfigList from './rewardconfig-list';
import './index.less'

const Rewardconfig: React.FC<RouteComponentProps> = () => (
  <Router>
    <RewardconfigList path="/" />
    <DealerList path="/dealerList" />
  </Router>
);
export default Rewardconfig;
