import { RouteComponentProps } from "@reach/router";
import { FC, ReactNode, useEffect } from "react";
import { connect } from "react-redux";
import { clearEntities } from "./clear-entities-reducer";

export interface clearEntitiesListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  disableDefaultRequest?: any;
  getEntities?: any;
}

const ClearEntitiesList: FC<clearEntitiesListProps> = (props) => {
  
  const { disableDefaultRequest, getEntities, clearEntities } = props;

  useEffect(() => {
    disableDefaultRequest && clearEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getEntities]);

  return <></>;
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  clearEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClearEntitiesList);