import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { SecondReview, defaultSecondReview } from 'model/dealer/secondReview.model';
import axios from 'axios';

const entity = 'SecondReview';
const apiUrl = '/api/yumei-mall-customer/customer/apply-two-Info';
const standardReducerImpl = new StandardReducerImpl<SecondReview>(entity, defaultSecondReview, apiUrl);
export type SecondReviewState = StandardState<SecondReview>;
export const secondReview = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


// 审核
export const examineSecond = async (data:any) => {
  const requestUrl = `/api/yumei-mall-customer/customer/apply-two-Info`;
  const axiosResponse = await axios.put<any>(requestUrl, data);
  return axiosResponse.data;
}

