import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import './index.less'
import ContractTemplate from './contractTemplate';

const ContractAdmin: React.FC<RouteComponentProps> = () => (
    <Router>
        <ContractTemplate path="contract-template/*" />
    </Router>
);
export default ContractAdmin;
