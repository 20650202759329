const handleTime = (e: number) => (e > 9 ? e : "0" + e);

export const filterTime = (time?: any) => {
  const date = new Date(time);
  const Y = date.getFullYear();
  const M =
    date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1;
  const D = date.getDate();

  const H = date.getHours(); // 小时
  const F = date.getMinutes(); // 分钟
  const S = date.getSeconds(); // 秒

  return `${handleTime(Y)}-${M}-${handleTime(D)} ${handleTime(H)}:${handleTime(
    F
  )}:${handleTime(S)}`;
};
export const getDate = (num: number) => {
  const date1 = new Date(); //time1表示当前时间
  const date2 = new Date(date1);
  date2.setDate(date1.getDate() + num);
  return (
    handleTime(date2.getFullYear()) +
    "-" +
    handleTime(date2.getMonth() + 1) +
    "-" +
    handleTime(date2.getDate()) +
    " 00:00:00"
  );
};
// excel导出
export const exportExcel = (arrayBuffer: any, fileName?: string) => {
  const elink = document.createElement("a");
  elink.download = (fileName || "file") + ".xlsx";
  elink.style.display = "none";
  elink.href = window.URL.createObjectURL(new Blob([arrayBuffer]));
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
};
