import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import TopicList from './topic-list';
import TopicDetails from "./topic-details";
import TopicEdit from "./topic-edit";
import './index.less'

const Course: React.FC<RouteComponentProps> = () => (
    <Router>
        <TopicList path = "/"/>
        <TopicDetails path = ":id"/>
        <TopicEdit path = ":id/edit"/>
        <TopicEdit path = "new"/>
    </Router>
);
export default Course;
