import { useEffect, useState } from "react";
import {Storage} from 'utils/storage-util';

interface currency {
  text?: string;
  symbol?: string
}

export const useParamGeneral = () => {
    const [paramGeneralData, setParamGeneralData] = useState([]);  //商城基础配置数据
    const [currency, setCurrency] = useState<currency>();//支持的货币
    const [generalLanguage, setGeneralLanguage] = useState<any[]>([]);    //默认语言的对象
    const [generalSupportLanguage, setGeneralSupportLanguage] = useState<any[]>([]); //支持语言的对象
    const [intlArr, setIntlArr] = useState<string[]>([]);  //支持的语言
    const [defaultLanguage, setDefaultLanguage] = useState('');

    useEffect(() => {
        const paramGeneral = Storage.session.get("param-general") || [];
        const currencyObj: any = {
          'MYR': 'RM',//tingmalaysia
          'TWD':  'NT$',
          'USD': '$',
          'CNY': '¥',
          'SGD': 'S$',
        };
        const currencyText: string = paramGeneral.filter((e:any) => e.paramName ==='商城货币')?.[0]?.paramValue || 'CNY';
        setCurrency({text: currencyText, symbol: currencyObj[currencyText] || '¥'});
        setParamGeneralData(paramGeneral);
        setGeneralLanguage(paramGeneral.filter((item:any) => item.paramKey === "general.language") || []);
        setGeneralSupportLanguage(paramGeneral.filter((item:any) => item.paramKey === "general.supported_languages") || []);
    }, [])

    useEffect(() => {
        //支持语言对象中paramValue为空，取默认语言对象-paramValue-defaultValue
        const supportParamValue = generalSupportLanguage[0] && generalSupportLanguage[0].paramValue;
        const languageParamValue = generalLanguage[0] && generalLanguage[0].paramValue;
        const intlValue = (supportParamValue && supportParamValue !== '')? supportParamValue 
                        : (languageParamValue? languageParamValue: generalLanguage[0] && generalLanguage[0].defaultValue);
        setIntlArr(!!intlValue? intlValue.split(',') : []);
    }, [generalSupportLanguage, generalLanguage])

    useEffect(() => {
        const lag = generalLanguage[0] && (generalLanguage[0].paramValue || generalLanguage[0].defaultValue)
        setDefaultLanguage(lag);
    }, [generalLanguage])
    
    return { paramGeneralData, generalLanguage, generalSupportLanguage, intlArr, defaultLanguage, currency}
}
