import { FC, useState, useEffect } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, Tooltip, InputNumber, Select, message } from "antd";
import { createEntity, getEntity, reset, updateEntity, fetchLevelList } from "./marginRules.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { marginRulesModel } from "model/store/marginRules.model";
import { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { internationalList } from "components/IntelFormText";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons";
import ProCard from "@ant-design/pro-card";
import { fetchStoreList } from "pages/store/store/store.reducer";
export interface MarginRulesEditProps extends EditStateAndDispatchProps<marginRulesModel>, StateProps, DispatchProps, RouteComponentProps {
}

const  MarginRulesEdit: FC<MarginRulesEditProps> = props => {
  const [levelData, setlevelData]: any = useState([]);
  const [levelList, setLevelList]: any = useState([]); // 等级列表
  const [form] = Form.useForm();

  useEffect(() => {
    let list: any = []
    let datas = props.entity
    if (datas.levelCashDeposit) {
      for (let i in datas.levelCashDeposit) {
        list.push({
          cashDeposit: datas.levelCashDeposit[i].cashDeposit,
          levelNo: i
        })
      }
    }
    setlevelData([...list])
    getLevelList()

    // 尾数处理默认增加一条
    const formdata = form.getFieldsValue()
    if (!formdata.mantissaMap) {
      let data = [{
        oldNum: 4,
        newNum: 6,
        isFiexd: true
      }]
      form.setFieldsValue({
        mantissaMap: data
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])


  const UpdateEntity = (data?: marginRulesModel) => {
    return new Promise<marginRulesModel>((resolve, reject) => {
      if (levelData.length === 0) {
        message.warning("保证金不能为空");
        reject()
        return false
      }
      let index = levelData.length - 1
      if (!levelData[index].cashDeposit || !levelData[index].levelNo) {
        message.warning("请填完整最后一行保证金");
        reject()
        return false
      }
      if (data) {
        let levelCashDeposit: any = {}
        if (levelData.length > 0) {
          levelData.foreach((item: any) => {
            levelCashDeposit[item.levelNo] = {
              cashDeposit: item.cashDeposit,
              levelName: item.levelName
            }
          });
        }
        data.levelCashDeposit = levelCashDeposit
        resolve(data)
      }
    })
  }

  // 添加等级规则
  const addLevel = () => {
    let index = levelData.length - 1
    if (levelData.length > 0) {
      if (!levelData[index].cashDeposit || !levelData[index].levelNo) {
        message.warning("请填完整最后一行保证金");
        return false
      }
    }
    levelData.push({
      cashDeposit: '',
      levelNo: ''
    })
    setlevelData([...levelData])
  }

  // 获取等级列表
  const getLevelList = async () => {
    const unitRequest = await fetchLevelList();
    let data = unitRequest.map((item: any) => ({
      value: item?.levelNo,
      label: internationalList(item?.levelName)
    }))
    setLevelList(data)
  }

  // 删除等级规则
  const delDataChange = (index: number) => {
    levelData.splice(index, 1)
    setlevelData([...levelData])
  }

  // 设置等级类型
  const setLevelNo = (val: string, option:any, index: number) => {
    let data = [...levelData]
    data[index].levelNo = val
    data[index].levelName = option.label
    setlevelData([...data])
  }
  // 设置所需保证金
  const setcashDeposit = (val: string, index: number) => {
    let data = [...levelData]
    data[index].cashDeposit = val
    setlevelData([...data])
  }

  return (
    <EditPage<marginRulesModel>
      title="保证金规则"
      form={form}
      beforeUpdateEntity={UpdateEntity}
      {...props}
    >
      <Row justify="start">
        <Col span='11'>
          <ProFormText
            rules={[{ required: true }]}
            name="name"
            label="规则名称"
            placeholder="请输入规则名称"
          />
        </Col>
        <Col span='1'></Col>
        <Col span={11}>
          <ProFormSelect label="店铺" rules={[{ required: true }]} mode="multiple" name="stores" placeholder="店铺" request={fetchStoreList} />
        </Col>
      </Row>
      <ProCard
        title="保证金规则信息"
        bordered
        headerBordered
        collapsible
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        {levelData.map((item: any, index: number) => {
          return (
            <Row justify="start" key={index}>
              <Col span='5'>
                <Form.Item label="等级名称" rules={[{ required: true }]} key={index}>
                  <Select
                    onChange={(val,option) => setLevelNo(val, option, index)}
                    defaultValue={item.levelNo ? item.levelNo : null}
                    placeholder='等级名称'
                    options={levelList}
                  />
                </Form.Item>
              </Col>
              <Col span='1'></Col>
              <Col span='5'>
                <Form.Item label="所需保证金" rules={[{ required: true }]}>
                  <InputNumber width='md' name='cashDeposit' className="width100" value={item.cashDeposit} placeholder='所需保证金' onChange={(val) => setcashDeposit(val, index)} />
                </Form.Item>
              </Col>
              <Col span='1'></Col>
              <Col span='3' className="delBtnCenter">
                <Tooltip title={'删除当前行数据'} key="AuditReject">
                  <Button type="link" size="small" danger
                    onClick={() => { delDataChange(index) }}
                    icon={<DeleteOutlined />}
                  />
                </Tooltip>
              </Col>
            </Row>)
        })}
        <Button style={{ marginBottom: '20px' }} type="dashed" icon={<PlusOutlined />} onClick={() => { addLevel() }} >保证金规则</Button>
      </ProCard>
    </EditPage>);
};

const mapStateToProps = ({ marginRules }: RootState) => ({
  entity: marginRules.entity,
  updateSuccess: marginRules.updateSuccess,
  updating: marginRules.updating,
  createSuccess: marginRules.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MarginRulesEdit);
