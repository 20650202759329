import React, { FC, } from 'react';
import '../style/temp.less';

export interface Props {
    dataField?: any
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const PageTab: FC<Props> = ({ dataField }) => {
    return (
        <div className="preview-container" >
            PageTab
        </div>
    )
}

export const PageTabSet: FC<SetProps> = ({ dataField, id }) => {

    return <div className="setting-container">
        PageTab
    </div>
}

export default PageTab