import { FC} from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./store.reducer";
import { Seller, storeTypeMapList } from "model/store/seller.model";
import { ProFormGroup, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { internationalList } from "components/IntelFormText";
import { fetchBrandList } from "pages/pim/brand/brand.reducer";
import { Form, message } from "antd";
import UploadImgOne from "components/upload-img/upload-img-one";
import { uploadImage } from "utils/file-utils";
import { fetchLevelList } from "../marginRules/marginRules.reducer";

export interface StoreCreateProps extends EditStateAndDispatchProps<Seller>, StateProps, DispatchProps, RouteComponentProps {
}

const StoreCreate: FC<StoreCreateProps> = props => {
  const { entity } = props;

  const uploadImg = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        const storeLogo: any = res && res.storeLogo?.concat();
        if (res.storeLogo && storeLogo.length > 0) {
          if (storeLogo[0].originFileObj) {
            //logo需上传
            const newDate = new Date().getTime();
            uploadImage(storeLogo[0].originFileObj, 'product/img-' + newDate).then(async imageName => {
              res.storeLogo = imageName;
              resolve(res);
            }).catch(() => { message.error('图片上传失败'); reject() });
          } else {
            // logo已上传且未修改
            res.storeLogo = storeLogo[0].url;
            resolve(res)
          }
        } else {
          //未上传logo
          res.storeLogo = '';
          resolve(res)
        }
      }
    })
  }

  return (
    <EditPage<Seller>
      beforeUpdateEntity={uploadImg}
      title="商家"
      {...props}
      entity={entity}
    >
      <>
        <Form.Item name="storeLogo" label="店铺LOGO" >
          <UploadImgOne
            listType="picture-card"
            extra="建议尺寸：800*800像素, 最多上传一张, 不超过2M."
            title="上传"
          />
        </Form.Item>
        <ProFormGroup>
          <ProFormText
            name="storeCode"
            label="店铺编码"
            rules={[{ required: true }]}
            width="md"
          />

          <ProFormSelect
            width="md"
            request={async () => {
              const unitRequest: any = await fetchLevelList();
              return unitRequest.map((item: any) => ({
                value: item.id,
                label: internationalList(item.levelName)
              }))
            }}
            name="levelId"
            label="品牌等级"
            placeholder="品牌等级"
          />

          <ProFormSelect
            width="md"
            mode='multiple'
            request={async (params) => {
              const unitRequest: any = await fetchBrandList();
              return unitRequest.map((item: any) => ({
                value: parseInt(item.id),
                label: item.brandName
              }))
            }}
            name="authorizedBrands"
            label="品牌"
            placeholder="品牌"
          />

          <ProFormText
            name="name"
            label="店铺名称"
            rules={[{ required: true }]}
            width="md"
          />

          <ProFormSelect
            width="md"
            options={storeTypeMapList}
            name="storeType"
            label="店铺类型"
            placeholder="店铺类型"
          />
          <ProFormSwitch
            name='isPointMall'
            label="是否积分商城店铺"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
          <ProFormText
            name="customerId"
            label="关联经销商授权码/关联UserID"
            rules={[{ required: true }]}
            width="md"
          />

          <ProFormText
            name="contactName"
            label="联系人姓名"
            rules={[{ required: true }]}
            width="md"
          />
          <ProFormText
            name="contactPhone"
            label="联系电话"
            rules={[{ required: true }]}
            width="md"
          />

          <ProFormText width="md" name='orderPrefix' label="订单前缀" extra={'前缀最长3位'} rules={[{ required: false, message: '前缀最长3位', pattern: /^(a-z|A-Z|0-9)*[^$%^&*;:,<>?()/""/']{1,3}$/ }]} />
          <ProFormSwitch
            name='isCenter'
            label="是否6s中心"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
          <ProFormText
            name="middleStoreCode"
            width='xs'
            label="中台店铺编码"
          />

          <ProFormSwitch
            name='selfSeller'
            label="是否自营"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
          {entity.id ?
            <>
              <ProFormGroup>
                <ProFormText
                  name={['company', 'companyName']}
                  label="企业名称"
                  disabled
                  placeholder=''
                  width="md"
                />
                <ProFormText
                  name={['company', 'companyIdCardNum']}
                  disabled
                  placeholder=''
                  label="社会统一信用代码"
                  width="md"
                />
              </ProFormGroup>
              <ProFormGroup>
                <ProFormText
                  name={['company', 'legalPerson']}
                  disabled
                  label="法人姓名"
                  placeholder=''
                  width="md"
                />
                <ProFormText
                  name={['company', 'legalIdCardNum']}
                  label="法人身份证号"
                  disabled
                  placeholder=''
                  width="md"
                />
              </ProFormGroup>
            </>
            : ''
          }


        </ProFormGroup>
      </>
    </EditPage>);
};

const mapStateToProps = ({ store }: RootState) => ({
  entity: store.entity,
  updateSuccess: store.updateSuccess,
  updating: store.updating,
  createSuccess: store.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StoreCreate);
