import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import TrainingListDetails from './trainingList-details';
import TrainingListList from './trainingList-list';

const TrainingList: React.FC<RouteComponentProps> = () => (
  <Router>
    <TrainingListList path="/" />
    <TrainingListDetails path=":id" />
  </Router>
);
export default TrainingList;
