import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import PhysicalStoresDetails from './physicalStores-details';
import PhysicalStoresList from './physicalStores-list';

const PhysicalStores: React.FC<RouteComponentProps> = () => (
  <Router>
    <PhysicalStoresList path="/" />
    <PhysicalStoresDetails path=":id" />
  </Router>
);
export default PhysicalStores;
