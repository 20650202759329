import { BaseModel, DisableModel } from 'model/base.model';

export interface ContractSign extends BaseModel, DisableModel {
  id?: number | string;
  phone?: number
}

export const signStatus = {
  1: '未签署', 2: '签署中', 3: '已签署', 4: '签署失败', 5: '豁免签署', 6: '强制签署', 7: '已作废(过期)'
}

export const signListStatus = {
  0: '删除',
  1: '正常'
}

export const defaultContractSign: Readonly<ContractSign> = {
};
