import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import FundList from './fund-list';

const Fund: React.FC<RouteComponentProps> = () => (
  <Router>
    <FundList path="/" />
  </Router>
);
export default Fund;
