import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import AchievementDetails from './achievement-details';
import AchievementEdit from './achievement-edit';
import AchievementList from './achievement-list';

const Achievement: React.FC<RouteComponentProps> = () => (
  <Router>
    <AchievementList path="/" />
    <AchievementDetails path=":id" />
    <AchievementEdit path=":id/edit" />
    <AchievementEdit path="new" />
  </Router>
);
export default Achievement;
