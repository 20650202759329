import { BaseModel } from 'model/base.model';


/**
 * 订单信息
 */
export interface OrderCancel extends BaseModel {
  code?: string,
  desc?: string,
}

export const defaultOrderCancel: Readonly<OrderCancel> = {
};