import axios from 'axios';
import { defaultFeedback, Feedback } from 'model/system/feedback.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'Feedback';
const apiUrl = '/api/yumei-mall-train/feedBack';
const standardReducerImpl = new StandardReducerImpl<Feedback>(entity, defaultFeedback, apiUrl);
export type FeedbackState = StandardState<Feedback>;
export const feedback = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchFeedBackProcessedById = async (params: any) => {
    const moduleData = await axios.get(`/api/yumei-mall-train/feedBack/getFeedBackProcessedById/${params.id}`, { params });
    return moduleData;
};

export const fetchMessageList = async (params: any) => {
    const moduleData = await axios.get(`/api/yumei-mall-message/message/record?type.equals=9&phone.equals=${params.phone}`);
    return moduleData;
};

export const updateExamine = async (data: any) => {
    const moduleData = await axios.post(`/api/yumei-mall-train/feedBack/updateExamine`, data);
    return moduleData.data || [];
};

export const sendMessage = async (data: any) => {
    const moduleData = await axios.put(`/api/yumei-mall-train/feedBack/sendMessage`, data);
    return moduleData.data || [];
};