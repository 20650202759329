import { BaseModel } from "model/base.model";

export interface Pay extends BaseModel {
    sellerId?: number;
    payCode?: string;
    payName?: string;
    strategyBeanId?: string;
    tradeType?: string;
    config?: Config;
    sort?: number;
    enabled?: boolean;
};

interface Config {
    payCode?: string;
    dev?: boolean;
    appId?: string;
    privateKey?: string;
    publicKey?: string;
    aliPayPublicKey?: string;
    signType?: string;
    notifyUrlPrefix?: string;
    pId?: string;
}

export const defaultPay: Readonly<Pay> = {};