import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import OrderCancelDetails from "./order-cancel-details";
import { deleteEntity, getEntities, getEntity } from "./order-cancel.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { OrderCancel } from "model/procure/order-cancel.model";
import OrderCancelEdit from "./order-cancel-edit";
import { internationalDetails, internationalList } from "components/IntelFormText";


export interface OrderCancelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<OrderCancel>[] = [{
  title: 'id',
  dataIndex: 'id',
}, {
  title: '编码',
  dataIndex: 'code',
}, {
  title: '订单取消原因',
  dataIndex: 'desc',
  hideInDescriptions: true,
  render: (dom, record) => <>{internationalList(record.desc || '')}</>
}, {
  title: '订单取消原因',
  dataIndex: 'desc',
  hideInTable: true,
  render: (dom, record) => <span className="product-name" dangerouslySetInnerHTML={{ __html: internationalDetails(record.desc || '') }}></span>
}];

const OrderCancelList: FC<OrderCancelListProps> = (props) => {

  return (
    <ListViewPage<OrderCancel>
      columns={columns}
      quickFilterPanel={false}
      detailPage={<OrderCancelDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      editPage={(params: any) => <OrderCancelEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ orderCancel }: RootState) => ({
  entity: orderCancel.entity,
  entities: orderCancel.entities,
  loading: orderCancel.loading,
  updateSuccess: orderCancel.updateSuccess,
  createSuccess: orderCancel.createSuccess,
  total: orderCancel.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderCancelList);