import { Form, Input, Space } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { priceRulesModel } from "model/product/pricingRules";
import { FC, ReactNode, useEffect, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity, storeListMap } from "./rewardconfig.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { fetchLevelList } from "pages/dealer/unit/unit.reducer";
import SelectSearch from "components/select-search";


export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const AdvertisementList: FC<LevelListProps> = (props: any) => {
  const [regionalHeadPeople, setRegionalHeadPeople]: any = useState(undefined);
  const [record, setRecord]: any = useState(undefined);
  useEffect(() => {
    var record:any = JSON.parse(localStorage.getItem('dealer') || '');
    setRecord(record)
    setRegionalHeadPeople(record?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);



  const additionActions = [
    <Space className="dealer-title">
       <p><span>{storeListMap?.[record?.bank]} </span>{record?.provinceName}-{record?.cityName}-{record?.districtName}</p>
    </Space>
  ];

  const columns: ProColumns<priceRulesModel>[] = [{
    title: '授权号',
    dataIndex: 'account'
  },{
    title: '历史授权号',
    dataIndex: 'relationshipList',
    valueType: 'text',
    width: 70,
    render: (text, row: any) => {
      return row.relationshipList?.map((item: any) => {
        return <p className="fontWeight">{item.storeShortName || '-'} : {item.code || '-'}</p>
      })
    }
  },
  {
    title: '姓名',
    dataIndex: 'name'
  },
  {
    title: '手机号',
    dataIndex: 'phone'
  },
  {
    title: '身份证号',
    dataIndex: 'credentialsNo'
  },  {
    title: '当前等级',
    dataIndex: 'relationshipList',
    valueType: 'text',
    width: 60,
    render: (text, row: any) => {
      return row.relationshipList?.map((item: any) => {
        return <p className="fontWeight">{item.storeShortName || '-'} : {item.levelShortName}</p>
      })
    }
  }, {
    title: '注册时间',
    dataIndex: 'registerTime'
  }];

  const searchFields: SearchField[] = [{
    label: '姓名',
    name: 'name.equals',
  }, {
    label: '手机号',
    name: 'phone.equals',
  }, {
    label: '身份证号',
    name: 'credentialsNo.equals',
  }, {
    label: '用户授权号',
    name: 'account.equals',
  }, {
    label: '历史授权号',
    name: 'code.equals',
  }];


  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch required={false} name="levelId.equals" placeholder="等级" labelValue="levelName" defaultParams='' fetchFunc={fetchLevelList} searchParams='levelName.contains' />
    
        <div className="wd10" />
        <SearchBox
          fields={searchFields}
          width={210}
        />

      </Input.Group>
    </Form.Item>
  );


  return (
    <>
      <ListViewPage<priceRulesModel>
        columns={columns}
        actions={additionActions}
        hideEditButton={true}
        hideActionColumn={true}
        hideCreateButton={true}
        hideDeleteButton={true}
        filters={{ 'regionalHeadPeople.in': regionalHeadPeople  }}
        quickFilterPanel={quickFilterPanel}
        detailsIndex={false}
        {...props}
      >
        {props.children}
      </ListViewPage>
    </>
  );
};

const mapStateToProps = ({ rewardconfig }: RootState) => ({
  entity: rewardconfig.entity,
  entities: rewardconfig.entities,
  loading: rewardconfig.loading,
  updateSuccess: rewardconfig.updateSuccess,
  createSuccess: rewardconfig.createSuccess,
  total: rewardconfig.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementList);