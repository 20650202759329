const config = {
  VERSION: process.env.VERSION,
};

export default config;

export const {SERVER_API_URL} = process.env;

export const AUTHORITIES = {
  ADMIN: 'ROLE_ADMIN',
  USER: 'ROLE_USER',
};

export const messages = {
  DATA_ERROR_ALERT: 'Internal Error',
};

export const APP_DATE_FORMAT = 'YYYY-MM-DD HH:mm';
export const APP_TIMESTAMP_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const APP_LOCAL_DATE_FORMAT = 'YYYY-MM-DD';
export const APP_LOCAL_YEAR_FORMAT = 'YYYY';
export const APP_LOCAL_MONTH_FORMAT = 'YYYY-MM';
export const APP_LOCAL_QUARTER_FORMAT = 'YYYY-Q';
export const APP_LOCAL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const APP_LOCAL_DATETIME_FORMAT_Z = 'YYYY-MM-DDTHH:mm Z';
export const APP_WHOLE_NUMBER_FORMAT = '0,0';
export const APP_LOCAL_TIME_FORMAT = 'HH:mm';
export const APP_TWO_DIGITS_AFTER_POINT_NUMBER_FORMAT = '0,0.[00]';
