import React, { FC, useCallback, useState, useEffect } from 'react';
import { Image, Upload, Input, Button, Tooltip, message } from 'antd';
import { PlusOutlined, ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
import '../style/temp.less';
import '../style/goods-scroll.less';
import holderImg from '../../../../assets/images/public/holder.jpg';
import { uploadImage } from "utils/file-utils";
import { useParamGeneral } from 'components/useParamGenera';

export interface Props {
    dataField?: any
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const GoodsScroll: FC<Props> = ({ dataField }) => {
    return (
        <div className="preview-container" >
            <div className="goods-scroll">
                <div className="goods">
                    <img src={holderImg} alt="" />
                    <div className="price">
                        <span className="l">{useParamGeneral()?.currency?.symbol}</span>
                        <span className="cen">520</span>
                        <span className="r">.00</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ImageItemProps {
    id: string
    index: number
    data?: any
    updateModuleDataPrivate: (dataField: any, index: number, type?: string) => void
    setShowLinkSelectionPrivate: (show: boolean) => void
}

const ImageItem: FC<ImageItemProps> = (({ data, id, index, updateModuleDataPrivate, setShowLinkSelectionPrivate }) => {
    return <div className={`image-item`} >
        <div className="img">
            <div className="operation">
                <div className="icon">{index + 1}</div>
                <div className="icon " onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'moveUp')
                    }
                }>
                    <Tooltip title="上移">
                        <ArrowUpOutlined />
                    </Tooltip>
                </div>
                <div className="icon" onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'moveDown')
                    }
                }>
                    <Tooltip title="下移">
                        <ArrowDownOutlined />
                    </Tooltip>
                </div>
                <div className="icon" onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'delete')
                    }
                }>
                    <Tooltip title="移除" >
                        <DeleteOutlined />
                    </Tooltip>
                </div>

            </div>
            <Image preview={true} src={data.url + '?imageView2/2/w/600'}></Image></div>
        <div className="info">
            <div className="input-group">
                <Input.Group compact className="input-item">
                    <Button type="primary" onClick={
                        () => {
                            setShowLinkSelectionPrivate(true)
                        }
                    }>选择</Button>
                    <Input placeholder="请输入/选择链接地址" onChange={(e) => {
                        data.link = e.target.value;
                        updateModuleDataPrivate(data, index)
                    }} defaultValue={data.link} />
                </Input.Group>
            </div>
        </div>
    </div>
})

export const GoodsScrollSet: FC<SetProps> = ({ dataField, id, methods }) => {
    const { Dragger } = Upload;
    const [dataFieldPrivate, setDataFieldPrivate] = useState(dataField)
    const updateModuleDataPrivate = useCallback(
        (item:any, index:any, type:any) => {//type delete up down
            let newDataFieldPrivate = dataFieldPrivate
            if (!type) {
                newDataFieldPrivate.goodsDetail[index] = item
            } else if (type === 'delete') {
                delete newDataFieldPrivate.goodsDetail[index]
                newDataFieldPrivate.goodsDetail = newDataFieldPrivate.goodsDetail.filter((v: any) => v)
                newDataFieldPrivate = Object.assign({}, newDataFieldPrivate)
            } else if (type === 'moveDown') {
                let nextIndex = index + 1, length = newDataFieldPrivate.goodsDetail.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.goodsDetail);
                if (nextIndex > length) {
                    nextIndex = 0
                }
                nextItem = newDataFieldPrivate.goodsDetail[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.goodsDetail = newImageList
                newDataFieldPrivate = Object.assign({}, newDataFieldPrivate)
            } else if (type === 'moveUp') {
                let nextIndex = index - 1, length = newDataFieldPrivate.goodsDetail.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.goodsDetail);
                if (nextIndex < 0) {
                    nextIndex = length - 1
                }
                nextItem = newDataFieldPrivate.goodsDetail[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.goodsDetail = newImageList
                newDataFieldPrivate = Object.assign({}, newDataFieldPrivate)
            } else if (type === 'add') {
                newDataFieldPrivate.goodsDetail.unshift(item)
                newDataFieldPrivate.goodsDetail = [].concat(newDataFieldPrivate.goodsDetail)
                newDataFieldPrivate = Object.assign({}, newDataFieldPrivate)
            }
            setDataFieldPrivate(newDataFieldPrivate)
            methods.updateModuleData(newDataFieldPrivate, id)
            // updateModuleData,
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [dataFieldPrivate, setDataFieldPrivate, methods]
    )

    useEffect(() => {
        setDataFieldPrivate(dataField)
    }, [dataField])
    const DraggerUploadProps = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        customRequest(e: any) {
            let name = e.file.name, profix = name.match(/\.(png|jpg|jpeg|PNG|JPG|JPEG|gif|GIF)/g)
            // console.log((profix && profix[0]) )
            // console.log('customRequest',e)
            uploadImage(e.file, 'minipage/img-' + (+new Date()) + (profix && profix[0])).then(async res => {
                let imageItem = {
                    link: "",
                    title: "",
                    type: "",
                    url: res
                }
                updateModuleDataPrivate(imageItem, 0, 'add')
            }).catch(() => {message.error('图片上传失败')});
        },
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e: any) {
        },
    };

    return <div className="setting-container">
        <div className="dragger-con">
            <Dragger {...DraggerUploadProps} className="add-area">
                <p className="desc">
                    <PlusOutlined className="icon" />
                </p>
                <p className="desc">点击/拖放以新增图片</p>
            </Dragger>
        </div>
        <div className="image-list">
            {dataFieldPrivate?.goodsDetail.map((v: any, i: number) => {
                return v.type !== 'config' && <ImageItem
                    key={i}
                    id={v.id}
                    index={i}
                    data={v}
                    updateModuleDataPrivate={updateModuleDataPrivate}
                    setShowLinkSelectionPrivate={methods.setShowLinkSelectionPrivate}
                />

            })}
        </div>
    </div>
}

export default GoodsScroll