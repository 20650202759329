import React, { FC } from 'react';
import './app.less';
import { Router } from '@reach/router';
import BasicLayout from './layouts/basic-layout';
import NotFound from 'pages/error/not-found';
import EnumValueProvider from './common/enum/enum-value-provider';
import ExtraProProvider from './common/extra-pro-provider';
import AuthorizedRoute from './common/auth/authorized-route';
import ExtraI18nProvider from './common/extra-i18n-provider';
import HomePage from 'pages/home/home';
import Login from './pages/login/login';
import LoginCode from './pages/login/login-code';
import Dealer from 'pages/dealer';
import Bonus from 'pages/bonus';
import CustomerCore from 'pages/customerCore';
import Pim from 'pages/pim';
import Procure from 'pages/procure';
import Financial from 'pages/financial';
import StoreManagement from 'pages/store';
import DealerStore from 'pages/dealerStore';
import ContractAdmin from 'pages/contractAdmin';
import Train from 'pages/train';
import ScanCodeOrder from 'pages/scanCodeOrder';
import System from 'pages/system';
import UserManage from 'pages/user';
import Decorate from 'pages/decorate';
import ContractManage from 'pages/contract';
import Market from 'pages/market';
import RewardMng from 'pages/rewardMng';
import PayMng from 'pages/paymng';
import Video from 'pages/video';
import ExamMng from 'pages/examMng';
import Promotion from 'pages/promotion';
export interface AppProps {
}


const App: FC<AppProps> = () => {
  return (
    <EnumValueProvider>
      <ExtraProProvider>
        <ExtraI18nProvider>
          <Router>
            <AuthorizedRoute path="/">
              <BasicLayout path="/">
                <HomePage path="/" />
                <Video path="video/*" />
                <Dealer path="dealer/*" />
                <Bonus path="bonus/*" />
                <CustomerCore path="customerCore/*" />
                <Pim path="pim/*" />
                <Procure path="procure/*" />
                <Financial path="financial/*" />
                <StoreManagement path="store/*" />
                <DealerStore path="dealerStore/*" />
                <ContractAdmin path="contract-admin/*" />
                <Train path="train/*" />
                <ScanCodeOrder path="scanCodeOrder/*" />
                <System path="system/*" />
                <UserManage path="user/*" />
                <Decorate path="decorate/*" />
                <ExamMng path='examMng/*' />
                <ContractManage path="contract/*" />
                <Market path="market/*" />
                <RewardMng path="rewardMng/*" />
                <PayMng path="paymng/*" />
                <Promotion path="promotion/*" />
              </BasicLayout>
            </AuthorizedRoute>
            <NotFound default />
            <Login path="/login" />
            <LoginCode path="/loginCode" />
            {/*<LoginRedirect path='/oauth2/authorization/oidc'/>*/}
          </Router>
        </ExtraI18nProvider>
      </ExtraProProvider>
    </EnumValueProvider>);
}

export default App;
