import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './brand.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Brand } from "model/product/brand.model";

export interface BrandDetailsProps extends DetailsStateAndDispatchProps<Brand>,
  RouteComponentProps, StateProps, DispatchProps {
}
const BrandDetails: FC<BrandDetailsProps> = (props) => {
  return (
    <DetailsPage<Brand>
      title={'品牌详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Brand>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ brand }: RootState) => ({
  entity: brand.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrandDetails);
