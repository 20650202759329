import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./permission.reducer";
import { Permission } from "model/system/permission.model";
import { Col, message, Row } from "antd";
import { ProFormSelect, ProFormSwitch } from "@ant-design/pro-form";
import { fetchResourcesList } from "../../user/resources/resources.reducer";
import { Resources } from "model/system/resources.model";
import { fetchRoleList } from "../role/role.reducer";
import { Role } from "model/system/role.model";
export interface PermissionEditProps extends EditStateAndDispatchProps<Permission>, StateProps, DispatchProps, RouteComponentProps {
}

const PermissionEdit: FC<PermissionEditProps> = props => {
  const { entity } = props;

  const [resourcesList, setResourcesList] = useState<any>([]); //资源option
  const [roleList, setRoleList] = useState<any>([]); //角色option
  const [entityCopy, setEntityCopy] = useState<Permission>({});

  useEffect(() => {
    setEntityCopy({});
    requestResourcesList({});
    requestRoleList({});
  }, []);

  let timeout: any = null;
  const handleSearch = (e?: any, type?:string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    };
    const fake = async () => {
      type === 'role' && requestRoleList({'name.contains': e});
      type === 'restfulUrl' && requestResourcesList({'description.contains': e})
    };
    timeout = setTimeout(fake, 1000);
  };

  const requestRoleList = (params:any) => {
    fetchRoleList({ page: 0, size: 500, ...params }).then(e => {
      setRoleList(e.data.map((item: Role) => ({
        label: item.name,
        value: item.name + ',' + item.id + ',' + item.code
      })));
    });
  };

  const requestResourcesList = (params:any) => {
    fetchResourcesList({ page: 0, size: 500, ...params }).then(e => {
      setResourcesList(e.data.map((item: Resources) => ({
        label: item.description,
        value: item.description + ',' + item.id + ',' + item.code
      })));
    });
  };

  useEffect(() => {
    if (entity.id) {
      let copy = JSON.parse(JSON.stringify(entity));
      copy.restfulUrl = {
        ...copy.restfulUrl,
        id: copy.restfulUrl.description + ',' + copy.restfulUrl.id + ',' + copy.restfulUrl.code,
      };
      copy.role = {
        ...copy.role,
        id: copy.role.name + ',' + copy.role.id + ',' + copy.role.code,
      };
      setEntityCopy(copy);
    } else {
      setEntityCopy({});
    };
  }, [entity]);


  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        res.restfulUrl = {
          ...entityCopy.restfulUrl,
          id: res.restfulUrl.id.split(',')[1],
          code: res.restfulUrl.id.split(',')[2],
          
        };
        res.role = {
          ...entityCopy.role,
          id: res.role.id.split(',')[1],
          code: res.role.id.split(',')[2],
        };
        resolve(res);
      } else {
        message.info('请刷新页面后重试');
        reject();
      }

    })
  }


  return (
    <EditPage<Permission>
      title="权限"
      {...props}
      entity={entityCopy}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row>
        <Col span="6">
          <ProFormSelect
            label="资源"
            name={['restfulUrl', 'id']}
            options={resourcesList}
            showSearch
            fieldProps={{
              onSearch:(e) => handleSearch(e,'restfulUrl')
            }}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={6}>
          <ProFormSelect
            name={['role', 'id']}
            label="角色"
            options={roleList}
            showSearch
            fieldProps={{
              onSearch:(e) => handleSearch(e,'role')
            }}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={2}>
          <ProFormSwitch name="read"
            label="读取"
            width='sm'
            initialValue={true}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
        <Col span={2}>
          <ProFormSwitch name="write"
            label="编辑"
            width='sm'
            initialValue={true}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
        <Col span={2}>
          <ProFormSwitch name="delete"
            label="删除"
            width='sm'
            initialValue={true}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ permission }: RootState) => ({
  entity: permission.entity,
  updateSuccess: permission.updateSuccess,
  updating: permission.updating,
  createSuccess: permission.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PermissionEdit);
