import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./order-after-sales.reducer";
import { Col, Form, Row } from "antd";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import IntelFormText from "components/IntelFormText";
import { ProFormText } from "@ant-design/pro-form";
import { OrderAfterSales } from "model/procure/order-after-sales.model";
export interface OrderAfterSalesEditProps extends EditStateAndDispatchProps<OrderAfterSales>, StateProps, DispatchProps, RouteComponentProps {
}

const OrderAfterSalesEdit: FC<OrderAfterSalesEditProps> = props => {
  const { entity } = props;

  const [form] = Form.useForm();
  return (
    <EditPage<OrderAfterSales>
      title="售后退款原因"
      form={form}
      {...props}
    >
      <Row>
        <Col span={5}>
          <ProFormText
            label={'编码'}
            name="code"
            rules={[{
              required: true,
              pattern: RegExp(/^[a-zA-Z0-9_]+$/i),
              message: '仅限输入英文数字下划线，不可有空格及符号'
            }]}
            width={'xl'}
          />
        </Col>
        <Col span={1} />
        <Col span={5}>
          <IntelFormText
            label={'售后退款原因'}
            name="desc"
            required={true}
            form={form}
            fieldProps={{
              maxLength: 100
            }}
            value={entity.desc || ''}
          />
        </Col>
      </Row>


    </EditPage>);
};

const mapStateToProps = ({ orderAfterSales }: RootState) => ({
  entity: orderAfterSales.entity,
  updateSuccess: orderAfterSales.updateSuccess,
  updating: orderAfterSales.updating,
  createSuccess: orderAfterSales.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderAfterSalesEdit);
