import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { ExchangeGoods, defaultExchangeGoods } from 'model/product/exchangeGoods.model';
import axios from 'axios';

const entity = 'ExchangeGoods';
const apiUrl = '/api/yumei-mall-pim/product';
const standardReducerImpl = new StandardReducerImpl<ExchangeGoods>(entity, defaultExchangeGoods, apiUrl);
export type ExchangeGoodsState = StandardState<ExchangeGoods>;
export const exchangeGoods = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const modifyExchangeGoods = async (isExhange:any, data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-pim/product/exchange-product?isExhange=${isExhange}`, data);
    return axiosResponse.data;
}


