import { defaultFund, Fund } from "model/financial/fund.model";
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'flowDetails';
const apiUrl = '/api/yumei-mall-store/seller/bank-roll/transaction';
const standardReducerImpl = new StandardReducerImpl<Fund>(entity, defaultFund, apiUrl);
export type FlowDetailsState = StandardState<Fund>;
export const flowDetails = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

