import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './bonus.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { BonusList } from "model/bonus/bonusList.model";

export interface BonusListDetailsProps extends DetailsStateAndDispatchProps<BonusList>,
  RouteComponentProps, StateProps, DispatchProps {
}
const BonusListDetails: FC<BonusListDetailsProps> = (props) => {
  return (
    <DetailsPage<BonusList>
      title={'品牌详情'}
      {...props}
    >
      <ProDescriptions<BonusList>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ bonusList }: RootState) => ({
  entity: bonusList.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BonusListDetails);
