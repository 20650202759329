import { initialState, StandardState } from 'reducers/standard.reducer';
import { FAILURE, REQUEST, SUCCESS } from "../../../reducers/action-type.util";
import {
  CrudGetAllAction,
  QueryParameter
} from "reducers/redux-action.type";
import axios from "axios";
import { Audit, defaultAudit } from 'model/system/audit.model';

const apiUrl = '/api/yumei-mall-audit/audit/search';

interface newStandardState extends StandardState<Audit> {
  more?: boolean;
  context?: string;
};

const ACTION_TYPE = {
  FETCH_AUDIT_LIST: 'FETCH_AUDIT_LIST22222',
  AUDIT_RESET: 'AUDIT_RESET',
};

export type AuditState = newStandardState;
export const audit = (state: AuditState = initialState(), action: any): AuditState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPE.FETCH_AUDIT_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case FAILURE(ACTION_TYPE.FETCH_AUDIT_LIST):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPE.FETCH_AUDIT_LIST):
      return {
        ...state,
        loading: false,
        entities: action?.payload?.config?.params?.context ? state.entities.concat(action.payload.data.records) : action.payload.data.records,
        more: action.payload.data.hasNext || false,
        context: action.payload.data.request.context || ''
      };
    default:
      return state;
  }
};

export const getEntities: CrudGetAllAction<Audit> = (params?: QueryParameter) => dispatch => {
  return dispatch({
    type: ACTION_TYPE.FETCH_AUDIT_LIST,
    payload: axios.get<any>(apiUrl, {
      params: {
        limit: 100,
        ...params
      }
    })
  });
};

export const fetchAuditList = async (params: any) => {
  const res = await axios.get(apiUrl, { params });
  return res.data.records;
};

export const reset = (entity?: Audit) => (dispatch: any) => {
  return dispatch({
    type: ACTION_TYPE.FETCH_AUDIT_LIST,
    payload: {
      updating: false,
      updateSuccess: false,
      entity: {
        ...defaultAudit,
        ...entity
      }
    }
  });
};

export {
  apiUrl,
};
