import { StandardReducerImpl, StandardState } from "reducers/standard.reducer";
import {
  defaultRelationshipTree,
  RelationshipTree,
} from "model/dealer/relationshipTree.model";
import axios from "axios";

const entity = "RelationshipTree";
const apiUrl = "/api/yumei-mall-customer/customer/relation/tree";
const standardReducerImpl = new StandardReducerImpl<RelationshipTree>(
  entity,
  defaultRelationshipTree,
  apiUrl
);
export type RelationshipTreeState = StandardState<RelationshipTree>;
export const relationshipTree = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 审核
export const fetchRelationTree = async (
  parentCustomerId: any,
  storeId: any
) => {
  const requestUrl = `/api/yumei-mall-customer/customer/relation/tree?storeId=${storeId}&page=0&size=100&isRoot=false&parentCustomerId=${parentCustomerId}`;
  const axiosResponse = await axios.get<any>(requestUrl);
  return axiosResponse.data;
};
