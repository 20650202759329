import { BaseModel } from "../base.model";
export interface Category extends BaseModel {
  createUser?: string;
  createDept?: string;
  createTime?: string;
  updateUser?: string;
  updateTime?: string;
  status?: number;
  isDeleted?: number;
  storeId?: number;
  parentId?: string;
  level?: number;
  name?: string;
  sort?: number;
  pathIds?: string;
  commissionRate?: string;
  hasChild?: boolean;
  children?: Category[];
  parameterList?: any;
  goodsNum?: number;
  commissionRateName?: string;
  qualifications?: any[]
}

export const defaultCategory: Readonly<Category> = {};