import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import PayorderList from './salonApplication-list';
import './index.less'

const payorder: React.FC<RouteComponentProps> = () => (
  <Router>
    <PayorderList path="/" />
  </Router>
);
export default payorder;
