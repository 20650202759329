import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import FeedbackDetails from './feedback-details';
import FeedbackEdit from './feedback-edit';
import FeedbackList from './feedback-list';

const Feedback: React.FC<RouteComponentProps> = () => (
  <Router>
    <FeedbackList path="/" />
    <FeedbackDetails path=":id" />
    <FeedbackEdit path=":id/edit" />
    <FeedbackEdit path="new" />
  </Router>
);
export default Feedback;
