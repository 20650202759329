import { Router, RouteComponentProps } from "@reach/router";
import PurchaseOrderList from './purchase-order-list';
import React from 'react';

const PurchaseOrder: React.FC<RouteComponentProps> = () => (
  <Router>
    <PurchaseOrderList path="/" />
  </Router>
);
export default PurchaseOrder;
