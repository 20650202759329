import { OptionsType } from "components/model/form.model";
import { EnumValue } from "model/base.model";
import { createContext } from "react";

export interface EnumValueContextProps {
  enumTypeNames: string[];
  getEnumValueList: (enumType: string) => EnumValue[];
  getEnumValueDesc: (enumType: string, value: string) => string;
  convertEnumToOptionType: (enumType: string) => OptionsType;
}

export const EnumValueContext = createContext<EnumValueContextProps>({
  enumTypeNames: [],
  getEnumValueList: enumType => [],
  getEnumValueDesc: (enumType, value) => '',
  convertEnumToOptionType: enumType => [],
});
