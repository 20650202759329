import React, { FC, ReactNode } from "react";
import { Form, Input, Image, Switch } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./topic-details";
import { changeEnabled, deleteEntity, getEntities, getEntity, updateEntity } from "./topic.reducer";
import { RouteComponentProps } from "@reach/router";
import { Topic } from "model/video/topic.model";
import TopicEdit from './topic-edit';

export interface TopicListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const TopicList: FC<TopicListProps> = (props) => {
  const { changeEnabled } = props;

  const columns: ProColumns<Topic, 'materialResourceLimitType'>[] = [{
    title: '话题图片',
    dataIndex: 'pic',
    width: 80,
    render: (dom, record) => record.pic && <Image width={80} src={record.pic} />
  }, {
    title: '话题名称',
    dataIndex: 'name',
    valueType: 'text',
  }, {
    title: '话题引导语',
    dataIndex: 'content',
    valueType: 'text',
  }, {
    title: '排序',
    dataIndex: 'sort',
    valueType: 'text',
  }, {
    title: '浏览量',
    dataIndex: 'pageViews',
    valueType: 'text',
  }, {
    title: '话题参与人数',
    dataIndex: 'totalPerson',
    valueType: 'text',
  }, {
    title: '话题参与总篇数',
    dataIndex: 'totalPost',
    valueType: 'text',
  }, {
    title: '话题发布类型',
    dataIndex: 'type',
    valueType: 'materialResourceLimitType',
  },
  // {
  //   title: '是否热门',
  //   dataIndex: 'isHot',
  //   render: (text) => text ? '是' : '否'
  // }, 
  {
    title: '状态',
    dataIndex: 'isEnable',
    render: (text, record) =>
      <>
        <Switch checkedChildren="启用" unCheckedChildren="禁用" defaultChecked={text ? true : false} onChange={
          (e) => changeEnabled({ id: record.id, isEnable: e })
        } />
      </>
  }];

  const searchFields = [{
    label: '话题名称',
    name: 'name.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>)

  return (
    <ListViewPage<Topic, 'materialResourceLimitType'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      editPage={(params) => <TopicEdit id={params.id} text={params.text} />} 
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ topic, authentication }: RootState) => ({
  entity: topic.entity,
  entities: topic.entities,
  loading: topic.loading,
  updateSuccess: topic.updateSuccess,
  total: topic.total,
  createSuccess: topic.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  updateEntity,
  changeEnabled
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TopicList);