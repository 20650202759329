import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import LevelDetails from './level-details';
import LevelEdit from './level-edit';
import LevelList from './level-list';

const DealerLevel: React.FC<RouteComponentProps> = () => (
  <Router>
    <LevelList path="/" />
    <LevelDetails path=":id" />
    <LevelEdit path=":id/edit" />
    <LevelEdit path="new" />
  </Router>
);
export default DealerLevel;
