import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import WithdrawalList from './withdrawal-list';

const Withdrawal: React.FC<RouteComponentProps> = () => (
  <Router>
    <WithdrawalList path="/" />
  </Router>
);
export default Withdrawal;
