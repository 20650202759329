import { BaseModel } from "../base.model"; 

export interface Express extends BaseModel {
    expressCode?: string
    expressName?: string
    externalExpressCode?: string
    externalExpressName?: string
    sort?: number
}

export const defaultExpress: Readonly<Express> = {};