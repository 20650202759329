import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import TagList from './tag-list';
import TagDetails from "./tag-details";
import TagEdit from "./tag-edit";

const Tag: React.FC<RouteComponentProps> = () => (
  <Router>
    <TagList path="/" />
    <TagDetails path=":id" />
    <TagEdit path=":id/edit" />
    <TagEdit path="new" />
  </Router>
);
export default Tag;
