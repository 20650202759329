import { FC, ReactNode, useEffect, useState } from "react";
import { Form, Input, Image } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import BrandDetails from "./brand-details";
import { deleteEntity, getEntities, getEntity } from "./brand.reducer";
import { RouteComponentProps } from "@reach/router";
import { Brand } from "model/product/brand.model";
import ListViewPage from "layouts/page-wraper/list-view-page";
import BrandEdit from "./brand-edit";
import { internationalDetails, internationalList } from "components/IntelFormText";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import TestTable from "./test";

export interface BrandListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const BrandList: FC<BrandListProps> = (props) => {
  const [storeList, setStoreList] = useState([]);

  // 获取店铺列表
  const getStoreList = () => {
    fetchStoreList().then((e: any) => {
      setStoreList(e)
    })
  }

  useEffect(() => {
    getStoreList()
  }, [])

  const columns: ProColumns<Brand>[] = [{
    title: '品牌Logo',
    dataIndex: 'logo',
    valueType: 'image',
    width: 200,
    render: (dom, record) => <Image width={40} src={record.logo} />
  }, {
    title: '品牌编码',
    dataIndex: 'brandCode'
  }, {
    title: '品牌名称',
    dataIndex: 'brandName',
    render: (dom, record) => internationalList(record.brandName || '')
  }, {
    title: '店铺',
    dataIndex: 'storeId',
    render: (dom) => {
      let data: any = storeList.find((item: any) => item.value === dom)
      return data?.label
    }
  }, {
    title: '品牌介绍',
    dataIndex: 'introduction',
    valueType: 'text',
    align: 'left',
    ellipsis: true,
    render: (dom, record) => <span dangerouslySetInnerHTML={{ __html: internationalDetails(record.introduction || '') }}></span>
  }]
  const searchFields = [{
    label: '品牌名称',
    name: 'name.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch placeholder='店铺' required={false} name="storeId.equals" width="md" labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (<>
    <ListViewPage<Brand>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<BrandDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <BrandEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
    <TestTable />
  </>
  );
};

const mapStateToProps = ({ brand }: RootState) => ({
  entity: brand.entity,
  entities: brand.entities,
  loading: brand.loading,
  updateSuccess: brand.updateSuccess,
  createSuccess: brand.createSuccess,
  total: brand.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrandList);