import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./trainList.reducer";
import { Button, Col, Form, Row, Tooltip, TreeSelect, message } from "antd";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { TrainList } from "model/train/trainList.model";
import { ModalForm, ProFormDateTimePicker, ProFormDependency, ProFormDigit, ProFormRadio, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import UploadImgOne from "components/upload-img/upload-img-one";
import { fetchLevelList, fetchStoreList } from "pages/dealer/unit/unit.reducer";
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'
import { uploadImage } from "utils/file-utils";
import TencentMap from "./tencentMap";
export interface TrainListEditProps extends EditStateAndDispatchProps<TrainList>, StateProps, DispatchProps, RouteComponentProps {
}

const TrainListEdit: FC<TrainListEditProps> = props => {
  const {
    entity,
    text,
  } = props;

  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();
  const [treeData, setTreeData] = useState<[]>();
  const [value1, setValue1] = useState<string>();
  const [valueNodes1, setValueNodes1] = useState<any[]>([]);
  const [userCodes, setUserCodes] = useState<any>('');

  const [editor, setEditor] = useState<IDomEditor | null>(null);
  // 编辑器内容
  const [html, setHtml] = useState('<></>');
  // 工具栏配置
  const toolbarConfig: Partial<IToolbarConfig> = {};
  const editorConfig: Partial<IEditorConfig> = {
    placeholder: '请输入内容...',
    MENU_CONF: {
      'uploadImage': {
        // 自定义上传
        async customUpload(file: File, insertFn: any) {  // TS 语法
          // file 即选中的文件
          // 自己实现上传，并得到图片 url alt href
          // 最后插入图片
          const newDate = new Date().getTime();
          uploadImage(file, 'product/img-' + newDate + '.png').then(imageName => {
            message.success('图片上传成功');
            insertFn(imageName);
          }).catch(() => { message.error('图片上传失败'); });
        }
      }
    }
  };

  useEffect(() => {
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
    }
  }, [editor]);

  const onChange = (value: any, label: any, extra: any) => {
    setValue1(value);
    const newValueNodes = extra.allCheckedNodes.concat().map((e: any) => {
      const obj = e.node?.props || e.props;
      return ({
        storeId: obj.children.length === 0 ? obj.parentValue : obj.value,
        storeName: obj.children.length === 0 ? obj.parentTitle : obj.title,
        involvedBrandLevels: obj.children.length > 0 ? obj.children.concat().map((item: any) => {
          const itemObj = item.props;
          return {
            levelId: itemObj.originalValue,
            levelName: itemObj.originalTitle,
            levelNo: itemObj.levelNo,
          }
        }) : [{
          levelId: obj.originalValue,
          levelName: obj.originalTitle,
          levelNo: obj.levelNo,
        }]
      })
    });

    let newData: any = [];
    let num = [];
    const funC = () => {
      const e = newValueNodes[num.length];
      (newData.length === 0) || (newData.filter((item: any) => item.storeId === e.storeId).length === 0)
        ? newData.push(e)
        : ((newData = newData.map((s: any) => {
          return ({
            ...s,
            involvedBrandLevels: s.storeId === e.storeId ? s.involvedBrandLevels.concat(e.involvedBrandLevels) : s.involvedBrandLevels
          })
        })));
      num.push(0);
      num.length === newValueNodes.length ? setValueNodes1(newData) : funC();
    }; funC();
  };

  useEffect(() => {
    entity.meetDescribe && setHtml(entity.meetDescribe);
    fetchStoreList().then((res: any) => {
      fetchLevelList().then((data: any) => {
        const newTreeData = res.concat().map((e: any) => ({
          value: e.id,
          title: e.name,
          key: e.signAppKey,
          children: data.concat().map((item: any) => ({
            value: e.id + '-' + item.id,
            title: e.name + '-' + item.levelName,
            originalTitle: item.levelName,
            originalValue: item.id,
            levelNo: item.levelNo,
            parentValue: e.id,
            parentTitle: e.name,
            key: item.id,
          }))
        }));
        setTreeData(newTreeData);
      });
    });
    const newInvolvedPlatforms1: any = [];
    entity?.showUser?.storeRanges && entity.showUser.storeRanges.concat().forEach((e: any) => {
      e.involvedBrandLevels && e.involvedBrandLevels.forEach((element: any) => {
        newInvolvedPlatforms1.push(e.storeId + '-' + element.levelId)
      });
    });
    if (entity.showType === 'MAN' && entity.showUser?.userCodes && entity.showUser.userCodes.length > 0) {
      setUserCodes(entity.showUser.userCodes.join(','))
    };

    form.setFieldsValue({
      showUser: {
        ...entity.showUser || {},
        storeRanges: newInvolvedPlatforms1,
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, text]);

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        console.log(res)
        !entity.id && (res.isHidden = false);
        editor && (res.meetDescribe = editor.getHtml());
        console.log('0000000',valueNodes1)
        if(res.showType === 'LEVEL'){
          if (valueNodes1.length > 0) {
            res.showUser.storeRanges = valueNodes1;
          }else{
            res.showUser.storeRanges = entity?.showUser?.storeRanges || [];
          }
        }else if(res.showType === 'MAN' && res.showUser.userCodes){
          res.showUser.userCodes = res.showUser.userCodes.split(',');
        }

        // res.location = markerPosition.longitude + ',' + markerPosition.latitude 
        resolve(res);
        // console.log(res)
        // reject();
      }
    })
  };

  return (
    <EditPage<TrainList>
      title="培训会"
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
      {...props}
    >
      <Row>
        <Col span='7'>
          <ProFormSelect
            name='meetType'
            label='会议类型'
            width="sm"
            rules={[{ required: true }]}
            options={convertEnumToOptionType('trainType')}
          />
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormText
            name={'name'}
            label="会议名称"
            rules={[{ required: true }]}
            extra="最多30个字符"
            fieldProps={{
              maxLength: 30
            }}
          />
        </Col><Col span={9} />
        <Col span={7}>
          <Form.Item rules={[{ required: true }]} name="coverPicUrl" label="会议封面">
            <UploadImgOne
              listType="picture-card"
              Immediate
              requiredIdentifying={0}
              extra="建议尺寸：750*422px 或 16:9，小于5M，支持 jpg、png、jpeg 格式图片"
              title="上传"
            />
          </Form.Item>
        </Col><Col span={1} />
        <Col span={7}>
          <Form.Item name="videoUrl" label="展示视频">
            <UploadImgOne
              upType="video"
              listType="picture-card"
              Immediate
              extra="建议尺寸：1920*1080 16:9，时长≥10s，小于50M，默认优先展示主图视频"
              title="上传"
            />
          </Form.Item>
        </Col><Col span={9} />
        <Col span='7'>
          <ProFormDateTimePicker
            name='startTime'
            label='报名开始时间'
            width="sm"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormDateTimePicker
            name='endTime'
            label='报名结束时间'
            width="sm"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormDigit
            name='participantNumber'
            label='总参会人数'
            width="sm"
            fieldProps={{
              precision: 0
            }}
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span='7'>
          <div style={{ display: 'flex' }}>
            <ProFormDateTimePicker
              name='signTime'
              label='签到开始时间'
              width="sm"
              rules={[{ required: true }]}
            />
            <ProFormText
              name={'signAddress'}
              label="地址"
              hidden
            />
            <ProFormText
              name={'location'}
              label="坐标"
              hidden
            />
            <ModalForm
              title="地址选择"
              width={'1000px'}
              trigger={
                <Tooltip title="地址选择">
                  <Button style={{ marginTop: '30px' }} type="primary">地址选择</Button>
                </Tooltip>
              }
              preserve={false}
              onFinish={async (data: any) => {
                return true;
              }}
            >
              <TencentMap
                onChange={(data: any) => {
                  form.setFieldsValue({
                    signAddress: data.signAddress,
                    location: data.useCenter[0] + ',' + data.useCenter[1]
                  })
                }}
                data={{
                  signAddress: entity.signAddress,
                  location: entity.location ? (entity.location?.split(',').length === 2 ? [Number(entity.location?.split(',')[0]), Number(entity.location?.split(',')[1])] : [0]) : [0]
                }}
              />
            </ModalForm>
          </div>
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormDateTimePicker
            name='signEndTime'
            label='签到结束时间'
            width="sm"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormDigit
            name='customerChargeAmount'
            label='会议本人扣费'
            width="sm"
            fieldProps={{
              precision: 2
            }}
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormTextArea
            name='address'
            label='详细地址'
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormSwitch
            name={'isCarry'}
            label="是否允许携带人员"
            initialValue={true}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否',
            }}
          />
        </Col><Col span={1} />
        <ProFormDependency name={['isCarry']}>
          {({ isCarry }) => {
            return isCarry && <>
              <Col span='7'>
                <ProFormDigit
                  name='carryNumber'
                  label='允许携带人数'
                  width="sm"
                  rules={[{ required: true }]}
                />
              </Col><Col span={1} />
              <Col span='7'>
                <ProFormDigit
                  name='carryChargeAmount'
                  label='会议随员每人扣费（元）'
                  width="sm"
                  fieldProps={{
                    precision: 2
                  }}
                  rules={[{ required: true }]}
                />
              </Col><Col span={1} />
            </>
          }
          }
        </ProFormDependency>
        <Col span='24'>
          <ProFormRadio.Group
            label="显示类型"
            name={['showType']}
            width='md'
            rules={[{ required: true }]}
            options={convertEnumToOptionType('userShowType')}
          />
        </Col>
        <ProFormDependency name={['showType']}>
          {({ showType }) => {
            if (showType === 'LEVEL') {
              return <Col span={24}>
                <Form.Item rules={[{ required: true }]} name={['showUser', 'storeRanges']} label='指定等级'>
                  <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    value={value1}
                    placeholder="指定等级"
                    allowClear
                    multiple
                    onChange={onChange}
                    treeData={treeData}
                    treeCheckable
                    showCheckedStrategy={'SHOW_PARENT'}
                  />
                </Form.Item>
              </Col>
            } else if (showType === 'MAN') {
              return <Col span={24}>
                <ProFormTextArea
                  extra='批量粘贴授权码到下面，以“,“分隔，然后点击确定例如：M10007,M10008'
                  rules={[{ required: true }]}
                  name={["showUser", "userCodes"]}
                  initialValue={userCodes}
                  label='指定人员显示'
                  placeholder='批量粘贴授权码，以逗号分割'
                />
              </Col>
            }
          }}
        </ProFormDependency>
        <Col span='7'>
          <ProFormDigit
            name={'cancelTimeDay'}
            rules={[{ required: true }]}
            label="申请取消时效（天）"
            initialValue={1}
            fieldProps={{
              precision: 0
            }}
          />
        </Col><Col span={1} />
        <Col span='24'>
          <div className="editor-box--1">
            <Toolbar
              editor={editor}
              defaultConfig={toolbarConfig}
              mode="default"
              style={{ borderBottom: '1px solid #ccc' }}
            />
            <Editor
              defaultConfig={editorConfig}
              value={html}
              onCreated={setEditor}
              onChange={editor => setHtml(editor.getHtml())}
              mode="default"
              style={{ height: '500px', overflowY: 'hidden' }}
            />
          </div>
        </Col>

      </Row>

    </EditPage>);
};

const mapStateToProps = ({ trainList }: RootState) => ({
  entity: trainList.entity,
  updateSuccess: trainList.updateSuccess,
  updating: trainList.updating,
  createSuccess: trainList.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TrainListEdit);
