import axios from 'axios';
import { REQUEST, SUCCESS } from 'reducers/action-type.util';
import { StandardState, initialState } from 'reducers/standard.reducer';
import { Dashboard } from 'model/home/dashboard.model';
import { CrudGetAction, CrudGetIdAllAction } from 'reducers/redux-action.type';
const customActionType = {
  BASE: "DASHBOARD_BASE",
  CUSTOMER: "DASHBOARD_CUSTOMER",
  ORDERCOUNT: "DASHBOARD_ORDERCOUNT",
  SALES: "DASHBOARD_SALES",
  PIM: "DASHBOARD_PIM",
};

export type DashboardState = StandardState<Dashboard>;

export const dashboard = (state: DashboardState = initialState(), action: any): DashboardState => {
  switch (action.type) {
    case REQUEST(customActionType.BASE):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      }
    case SUCCESS(customActionType.BASE):
      return {
        ...state,
        entity: {
          ...state.entity,
          base: action.payload.data
        }
      }
    case SUCCESS(customActionType.CUSTOMER):
      return {
        ...state,
        entity: {
          ...state.entity,
          customer: action.payload.data
        }
      }
    case SUCCESS(customActionType.PIM):
      return {
        ...state,
        entity: {
          ...state.entity,
          pim: action.payload.data,
        }
      }
    case SUCCESS(customActionType.ORDERCOUNT):
      return {
        ...state,
        entity: {
          ...state.entity,
          orderCount: action.payload.data
        }
      }
    case SUCCESS(customActionType.SALES):
      const salesList = action.payload.data.map((v: any) => {
        return {
          ...v,
          salesAmount: parseFloat(v.salesAmount)
        }
      })

      return {
        ...state,
        loading: false,
        entity: {
          ...state.entity,
          salesStatistics: salesList
        }
      }
    default:
      return state;
  }
}

//获取基础信息
export const getDashboardBase: CrudGetAction<Dashboard> = () => {
  const requestUrls = `api/tbt-mall-order/order/base/info`;
  return {
    type: customActionType.BASE,
    payload: axios.get(requestUrls)
  }
}

//获取会员信息
export const getDashboardCustomer: CrudGetAction<Dashboard> = () => {
  const requestUrls = `api/tbt-mall-customer/customer/trend/analysis`;
  return {
    type: customActionType.CUSTOMER,
    payload: axios.get(requestUrls)
  }
}


//商品相关
export const getDashboardPIM: CrudGetAction<Dashboard> = () => {
  const requestUrls = `api/tbt-mall-pim/product/base/info`;
  return {
    type: customActionType.PIM,
    payload: axios.get(requestUrls)
  }
}

//获取订单信息
export const getDashboardOrderCount: CrudGetIdAllAction<Dashboard> = (id, params) => {
  const requestUrls = `/api/tbt-mall-order/order/trend/list/${params?.timeType || '1'}`;
  return {
    type: customActionType.ORDERCOUNT,
    payload: axios.get(requestUrls)
  }
}
//获取销售信息
export const getDashboardSale: CrudGetIdAllAction<Dashboard> = (id, params) => {
  const requestUrls = `/api/tbt-mall-order/order/sales/statistics/list/${params?.timeType || '1'}`;
  return {
    type: customActionType.SALES,
    payload: axios.get(requestUrls)
  }
}
