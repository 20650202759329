import {BaseModel} from "../base.model";

export interface BonusList extends BaseModel {

}

export const bonusListStates  = {
    '1':'待审核',
    '2':'财务审核通过',
    '3':'确认发放',
    '4':'提现成功',
    '5':'审核拒绝',
    '6':'转货款待审核',
    '7':'确认转货款',
    '8':'转货款成功',
    '9':'转货款拒绝'
  }

export const defaultBonusList:  Readonly<BonusList> = {};