import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, message, Popconfirm, Tooltip } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import DealerInfoDetail from "./dealerInfo-details";
import { credentialsTypeMap, deleteEntity, getEntities, getEntity, orgTypeMap, typeMap, silentOpenMap, getPaymentAccountBankcardReginfo, isVerification } from "./dealerInfo.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import DealerInfoEdit from "./dealerInfo-edite";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import { dealerInfoOpenAccountStatus } from "model/dealer/dealerInfo.model";
import DecryptText from "../customer/detailtables/decryptText";

export interface dealerInfoListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<any>[] = [{
  title: '经销商授权码',
  dataIndex: 'code',
  width: 100
},
{
  title: '真实姓名',
  dataIndex: 'realName',
  width: 100
},
{
  title: '证件类型',
  dataIndex: 'credentialsType',
  width: 100,
  render: ((res: any) => {
    return credentialsTypeMap[res]
  })
},
{
  title: '身份证号码',
  dataIndex: 'customerIdCardNum',
  width: 130,
  render:(dom,record) => {
    return <>******<DecryptText title='身份证号码' id={record.id} data={record?.customerIdCardNum} /></>
  }
},
{
  title: '手机号',
  dataIndex: 'mobile',
  width: 100,
  render:(dom,record) => {
    return <>******<DecryptText title='手机号' id={record.id} data={record?.mobile} /></>
  }
}, {
  title: '资料类型',
  dataIndex: 'type',
  width: 100,
  valueEnum: typeMap,
}, {
  title: '经营类型',
  dataIndex: 'orgType',
  width: 100,
  render: ((res: any, record: any) => {
    return orgTypeMap[record.orgType]
  })
}, {
  title: '企业名称',
  dataIndex: 'orgName',
  width: 140,
}, {
  title: '连连审核状态',
  dataIndex: 'silentOpen',
  width: 80,
  valueEnum: silentOpenMap,
}, {
  title: '是否签署免密授权',
  dataIndex: 'isFreeSecretPay',
  width: 140,
  render:(dom,record) => {
    return record.isFreeSecretPay ? '是' : '否'
  }
}, {
  title: '开户状态',
  dataIndex: 'openAccountStatus',
  width: 140,
  render: (dom, record) => {
    return dealerInfoOpenAccountStatus[record?.openAccountStatus ? (record?.openAccountStatus + '') : '0']
  }
}, {
  title: '原因',
  dataIndex: 'silentOpenRemark',
  ellipsis: true,
  width: 100,
}];

const DealerInfoList: FC<dealerInfoListProps> = (props) => {
  let [key, setKey] = useState(0);
  const searchFields = [{
    label: '经销商授权码',
    name: 'code.equals',
  }, {
    label: '真实姓名',
    name: 'realName.equals',
  }, {
    label: '手机号',
    name: 'mobile.equals',
  }];



  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>

        <SearchBox
          fields={searchFields}
          width='550px'
        />
        <ProFormSelect
          options={Object.entries<string>(typeMap).map(([value, label]) => ({ value: value, label }))}
          name={'type.equals'}
          placeholder="资料类型"
        />
        <ProFormSelect
          options={Object.entries<string>(silentOpenMap).map(([value, label]) => ({ value: Number(value), label }))}
          name={'silentOpen.equals'}
          placeholder="开户状态"
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );


  const closureRecordsColumns: ProColumns<any>[] = [{
    title: '授权码',
    dataIndex: 'customNo',
    width: 70,
    ellipsis: true,
  }, {
    title: '连连的uuid',
    dataIndex: 'accountNo',
    width: 80,
    ellipsis: true,
  }, {
    title: '申请信息',
    dataIndex: 'changeText',
    width: 70,
    ellipsis: true,
  }, {
    title: '申请时间',
    dataIndex: 'applyTime',
    width: 110,
    ellipsis: true,
  }, {
    title: '成功或者失败信息',
    dataIndex: 'message',
    width: 100,
    ellipsis: true,
  }, {
    title: '申请状态',
    dataIndex: 'status',
    width: 80,
    ellipsis: true,
    render: (dom, record) => {
      return record.status === 1 ? '申请中' : record.status === 2 ? '成功' : record.status === 3 ? '失败' : ''
    }
  }, {
    title: '核验状态',
    dataIndex: 'isVerification',
    width: 80,
    ellipsis: true,
    render: (dom, record) => {
      return record.isVerification === 1 ? '待核验' : record.isVerification === 2 ? '核验成功' : ''
    }
  }, {
    title: '最终状态',
    dataIndex: 'isVerification',
    width: 80,
    ellipsis: true,
    render: (dom, record) => {
      return record.isVerification === 2 && record.status === 2 ? '通过' : '未通过'
    }
  }, {
    title: '操作',
    dataIndex: 'applyTime',
    width: 90,
    render: (dom, record) => {
      return record.isVerification === 1  ? <Popconfirm
        title="确定核验吗"
        onConfirm={()=>confirmVerification(record.id)}
        okText="确定"
        cancelText="取消"
      >
        <Button disabled={record.status !== 2} type="link">待核验(金风)</Button>
      </Popconfirm> :  record.isVerification === 2 ? '已核验':''
    }
  }]

  
   // 确定核验
   const confirmVerification=(id:any)=> {
    isVerification({
      id: id
    }).then(()=> {
      setKey(++key)
      message.success('核验成功');
    })
  }

  const rowActions = [(dom: any, record: any) => {
 
    return <ModalForm
      title={'变更卡记录'}
      width={1100}
      trigger={<Tooltip title={'变更卡记录'} key="AuditReject"><Button type="link" size="small" >变更卡记录</Button></Tooltip>}
      modalProps={{
        footer: false,
        wrapClassName: 'accountcancellation'
      }}
    >
      <ProTable
        key={key}
        className="payTable"
        bordered
        scroll={{ y: 600 }}
        options={false}
        search={false}
        columns={closureRecordsColumns}
        request={async (params) => {
          const result = await getPaymentAccountBankcardReginfo({
            code: record.code
          });

          return {
            success: true,
            data: result.data,
          };
        }}
        pagination={false}
      />
    </ModalForm>
  }]



  return (
    <ListViewPage<any>
      columns={columns}
      hideCreateButton
      actionColumnWidth={150}
      hideDeleteButton
      rowActions={rowActions}
      scroll={{ x: 1500 }}
      quickFilterPanel={quickFilterPanel}
      detailPage={<DealerInfoDetail navigate={props.navigate} columns={columns} />}
      editPage={(params: any) => <DealerInfoEdit id={params.id} text={params.text} />}
      detailsIndex={0}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ dealerInfo }: RootState) => ({
  entity: dealerInfo.entity,
  entities: dealerInfo.entities,
  loading: dealerInfo.loading,
  updateSuccess: dealerInfo.updateSuccess,
  createSuccess: dealerInfo.createSuccess,
  total: dealerInfo.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DealerInfoList);