import { FC, ReactNode } from "react";
import { Form, Input, Image } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./certificate-details";
import { deleteEntity, getEntities, getEntity } from "./certificate.reducer";
import { RouteComponentProps } from "@reach/router";
import { ProFormText } from "@ant-design/pro-form";
import PostEdit from "./certificate-edit";
import './index.less'

export interface PostListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

export const columns: ProColumns[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 60
  },
  {
    title: '表彰名称',
    dataIndex: 'title',
    width: 100,
    valueType: 'text',
    ellipsis: true
  },
  {
    title: '证书文案',
    dataIndex: 'issueText',
    width: 160,
    valueType: 'text',
    ellipsis: true,
    render: (dom, record) => {
      return record.issueText || ''
    }
  }, {
    title: '证书背景图',
    dataIndex: 'text',
    width: 50,
    render: ((res, record) => {
      const picture = record.text && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.text} />;
      return picture
    })
  }];


const PostList: FC<PostListProps> = (props) => {
  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormText
          name={['title.contains']}
          placeholder="表彰名称"
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage
      key={'id'}
      actionColumnWidth={60}
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      showResetButton={false}
      sort='lastModifiedDate,desc'
      createButtonText='添加证书'
      editPage={(params) => <PostEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ examtopic }: RootState) => ({
  entity: examtopic.entity,
  entities: examtopic.entities,
  loading: examtopic.loading,
  updateSuccess: examtopic.updateSuccess,
  createSuccess: examtopic.createSuccess,
  total: examtopic.total
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostList);