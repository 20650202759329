import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import Customer from './customer';
import './index.less'
import Wallet from './wallet';
import Group from './group';
import Tag from './tag';
import Level from './level';
import Equity from './equity';
import ActiveUser from './user';
import TagTask from './tag-task';
import Wechatbind from './wechatbind';

const CustomerCore: React.FC<RouteComponentProps> = () => (
  <Router>
    <Customer path="customer/*" />
    <Wallet path="wallet/*" />
    <Group path="group/*" />
    <Tag path="tag/*" />
    <TagTask path="tagTask/*" />
    <Level path="level/*" />
    <Equity path="equity/*" />
    <ActiveUser path = "active-user/*"/>
    <Wechatbind path = "wechatbind/*"/>
  </Router>
);
export default CustomerCore;
