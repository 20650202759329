import { Button, Space } from 'antd';
import React from 'react';
import Avatar from './avatar-dropdown';
import styles from './index.less';
import defaultSettings from '../default-settings';
import { Storage } from 'utils/storage-util';

const GlobalHeaderRight: React.FC<{}> = () => {

  const SystemSwitch = () => {
    return (
      <div className='system-switch'>
        <Button className='item' type="primary" onClick={() => {
          const token = Storage.session.get('authentication-token');
          if (process.env.NODE_ENV === 'development') {
            console.log('当前环境是开发版本');
            // 在开发版本中执行的逻辑
            window.open(`https://middle-console-test.merrige.cn?token=${token}`, '_blank')
          } else {
            console.log('当前环境是生产版本');
            // 在生产版本中执行的逻辑
            window.open(`https://middle-console-web.merrige.cn?token=${token}`, '_blank')
          }
        }}>玉美中台 </Button> 
      </div>
    )
  }

  const { navTheme, layout } = defaultSettings;
  let className = styles.right;

  if ((navTheme === 'dark' && layout === 'top') || layout === 'mix') {
    className = `${styles.right} container ${styles.dark}`;
  }
  return (
    <div className="right-content">
      <Space className={className}>
        <SystemSwitch />
        <Avatar menu={true} />
      </Space>
    </div>

  );
};
export default GlobalHeaderRight;
