import { Drawer, Form, Input } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { FC, ReactNode, useState, useEffect } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity, fetchPayList } from "./payorder.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { useEnumValue } from "common/enum/use-enum-value";
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import PayDetail from './pay-detail'

export interface PayorderListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const PayorderList: FC<PayorderListProps> = (props) => {
  const { getEnumValueList } = useEnumValue();
  const [fetchPayData, setFetchPayData] = useState([]);
  const OrderStausMap: any = getEnumValueList('rechargeStatus')
  // 详情弹框
  const [detailShow, SetDetailShow] = useState(false);
  // 行
  const [detailRow, SetDetailRow] = useState<string>("");
  // 行id
  const [detailId, SetDetailId] = useState<string>("");

  const columns: ProColumns<any>[] = [{
    title: '授权码',
    dataIndex: 'code',
    width: 60
  }, {
    title: '付款人姓名',
    dataIndex: 'payerName',
    width: 70
  },
  {
    title: '交易单号',
    dataIndex: 'payOrderSn',
  }, {
    title: '支付单号',
    dataIndex: 'payNo',
  }, {
    title: '订单ID',
    dataIndex: 'orderId',
  }, {
    title: '金额',
    dataIndex: 'payAmount',
    width: 50
  },
  {
    title: '支付方式',
    dataIndex: 'payName',
  }, {
    title: '类型',
    dataIndex: 'type',
    width: 50,
    render: (dom, record: any) => {
      return record.type === 'Recharge' ? '充值' : record.type === 'Consume' ? '消费' : ''
    }
  }, {
    title: '支付时间',
    dataIndex: 'payTime',
    width: 110
  },
  {
    title: '状态',
    dataIndex: 'payStatus',
    width: 50,
    render: (dom, record: any) => {
      return OrderStausMap.find((item: any) => item.name === record.payStatus)?.desc || ''
    }
  }
  ];

  const searchFields: SearchField[] = [
    {
      label: '授权号',
      name: 'code.equals'
    }, {
      label: '支付单号',
      name: 'payNo.equals',
    }, {
      label: '交易单号',
      name: 'payOrderSn.equals',
    }
  ];

  // 查看订单详情
  const lookOrderButtonRender: any = (dom: ReactNode, record: any) => {
    return record.type === 'Consume' && <span onClick={() => {
      SetDetailShow(true);
      SetDetailRow(record);
      record.orderId && SetDetailId(String(record.orderId));
    }}>订单详情</span>
  };

  useEffect(() => {
    fetchPayListFn()
  }, []);

  const fetchPayListFn = () => {
    fetchPayList().then((e) => {
      setFetchPayData(e.data || [])
    })
  }


  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={fetchPayData.map((e: any) => ({
            value: e.payCode,
            label: e.payName
          }))}
          name="payTypeCode.equals"
          label='payTypeCode'
          placeholder='支付方式'
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={getEnumValueList('payOrderType').map((e: any) => ({
            label: e.desc,
            value: e.name
          }))}
          name="payOrderType.equals"
          label='订单类型'
          placeholder='订单类型'
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={getEnumValueList('rechargeStatus').map((e: any) => ({
            label: e.desc,
            value: e.name
          }))}
          name="payStatus.equals"
          label='payStatus'
          placeholder='订单状态'
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="payTime.greaterThanOrEqual"
          placeholder="支付开始时间"
        />
        <ProFormDateTimePicker
          name="payTime.lessThanOrEqual"
          placeholder="支付结束时间"
        />
      </Input.Group>
    </Form.Item>
  );

  //导出
  const exportData = (resData?: any) => {
    let orderData: any[] = [];
    const exportOrderData = resData ? resData : props.entities;
    exportOrderData.forEach((res: any) => {
      let arr: any = {
        ...res,
        payStatus: OrderStausMap.find((item: any) => item.name === res.payStatus)?.desc || '',
        type: res.type === 'Recharge' ? '充值' : res.type === 'Consume' ? '消费' : ''
      };
      orderData.push(arr);
    });
    return orderData;
  }


  return (
    <div className="payOrder">
      <ListViewPage
        actionColumnWidth={60}
        columns={columns}
        hideEditButton
        downloadEntities={exportData()}
        downloadAllEntities={exportData}
        showDownload
        hideDeleteButton
        hideCreateButton
        quickFilterPanel={quickFilterPanel}
        rowActions={[lookOrderButtonRender]}
        detailsIndex={false}
        {...props}
      >
        {props.children}
      </ListViewPage>

      <Drawer
        visible={detailShow}
        onClose={() => SetDetailShow(false)}
        width={"84vw"}
      >
        <PayDetail detailRow={detailRow} id={detailId} listType={"order"} />
      </Drawer>
    </div>
  );
};

const mapStateToProps = ({ payorder }: RootState) => ({
  entity: payorder.entity,
  entities: payorder.entities,
  loading: payorder.loading,
  updateSuccess: payorder.updateSuccess,
  createSuccess: payorder.createSuccess,
  total: payorder.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PayorderList);