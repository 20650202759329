import React, { FC, Key, ReactElement, useState } from 'react'
import { searchManualProductSku } from './product.reducer';
import { SkuItem } from 'model/product/product.model';
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, Tooltip } from 'antd';
import { ModalForm, ProFormSelect } from '@ant-design/pro-form';
import { internationalList } from 'components/IntelFormText';

const ManualSkuColumns: ProColumns<any>[] = [{
  title: '商品名称',
  dataIndex: 'itemName',
  width: 100,
  hideInSearch: true,
  render: (text, record) => <Tooltip title={internationalList(record.itemName || '')}>
    <p className="text_more_hidden2">{internationalList(record.itemName || '')}</p>
  </Tooltip>
}, {
  title: '商品名称',
  dataIndex: 'skuName',
  hideInTable: true,
}, {
  title: '商品编码',
  dataIndex: 'itemCode',
  hideInSearch: true,
  width: 100
}, {
  title: '商品编码',
  dataIndex: 'skuCode',
  hideInTable: true,
}, {
  title: '商品条码',
  dataIndex: 'barCode',
  width: 100
}, {
  title: '套组',
  dataIndex: 'combo',
  hideInTable: true,
  valueType: 'select',
  renderFormItem: (text, record, valueType) => {
    return <ProFormSelect
      name="combo"
      options={[{
        label: '是',
        value: 'true',
      }, {
        label: '否',
        value: 'false',
      }]}
      placeholder="套组"
    />
  },
}];

export interface SkuSelectionFormProps {
  trigger?: string;
  triggerRender?: ReactElement;
  productIds?: string[];
  onFinish: (skus: SkuItem[]) => void;
}

const ManualSkuSelectionForm: FC<SkuSelectionFormProps> = (props) => {
  const {
    onFinish,
    trigger,
  } = props;
  const [selectedRows, setSelectedRows] = useState<SkuItem[]>([]);

  return (
    <ModalForm
      width={1000}
      trigger={<Button size="middle" >{trigger ? trigger : '添加SKU'}</Button>}
      title={trigger ? trigger : '添加SKU'}
      onFinish={async () => {
        onFinish?.(selectedRows);
        return true;
      }}
      modalProps={{
        destroyOnClose: true,
      }}
    >
      <ProTable
        rowKey="itemCode"
        dateFormatter="string"
        columns={ManualSkuColumns}
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: Key[], selectedRows) => {
            setSelectedRows(selectedRows);
          },
          getCheckboxProps: (record: any) => ({
            disabled: record.status === 0, // Column configuration not to be checked
          }),
        }}
        pagination={{
          showQuickJumper: true,
        }}
        search={{
          filterType: 'light',
        }}
        request={async (params) => {
          const result = await searchManualProductSku({
            ...params
          });
          return {
            success: true,
            total: result.total,
            data: result.data,
          };
        }}
      />
    </ModalForm>
  );
};

export default ManualSkuSelectionForm;