import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import SalonReimbursmtList from './salonReimbursmt-list';
import './index.less'

const payorder: React.FC<RouteComponentProps> = () => (
  <Router>
    <SalonReimbursmtList path="/" />
  </Router>
);
export default payorder;
