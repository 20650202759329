import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';
import { LifePavilion, defaultLifePavilion } from 'model/dealerStore/lifePavilion.model';

const entity = 'physicalStores';
const apiUrl = '/api/yumei-mall-store/life-pavilion-apply';
const standardReducerImpl = new StandardReducerImpl<LifePavilion>(entity, defaultLifePavilion, apiUrl);
export type LifePavilionState = StandardState<LifePavilion>;
export const lifePavilion = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const LifeStatus = {
  'Examine': '审核阶段',
  'Check': '验收阶段'
};

// 审核
export const auditRefuse = async (entity: any) => {
  const stage = entity.stage === 'Examine' ? 'audit' : 'check'
  const requestUrl = `${apiUrl}/${entity.id}/${stage}`;
  const axiosResponse = await axios.post<any>(requestUrl, entity);
  return axiosResponse;
}

// 店铺类型
export const storeTypeMap: any = {
  PhysicalStores: '实体店',
  ExcellentPhysicalStore: "优秀实体店",
  LifePavilion: "生活馆"
}

