import { ModalForm } from "@ant-design/pro-form";
import { useEffect, useState } from "react";
import { Row } from "antd";
import { useEnumValue } from "common/enum/use-enum-value";
import { flowDistributionDtail } from './bankStatement.reducer'
import ProTable, { ProColumns } from "@ant-design/pro-table";

const RechargeModel = (props: any) => {
  let { visible, record, onCancel, onSubmit } = props
  const [detailData, setDetailData] = useState<any>([]);
  const { convertEnumToOptionType } = useEnumValue();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 5 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };

  useEffect(() => {
    getFlowDistribution();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])


  // 获取流水分配详情
  const getFlowDistribution = () => {
    if (record.id) {
      flowDistributionDtail(record.id).then((e: any) => {
        let list: any = []
        let rechargeTypeList = convertEnumToOptionType('rechargeType')
        rechargeTypeList.forEach((item, index) => {
          if (e.allotDetailMap[item.value]?.amount) {
            list.push({
              name: item.label,
              ...e.allotDetailMap[item.value]
            })
          }
        })
        setDetailData(list)
      })
    }
  }

  const columns: ProColumns<any>[] = [{
    title: '款项名称',
    dataIndex: 'name'
  },  {
    title: '金额',
    dataIndex: 'amount',
  },{
    title: '付款人授权码',
    dataIndex: 'payerCode',
  }, {
    title: '收款人类型',
    dataIndex: 'payeeType',
    render: (text: any, record) => { 
      return record.payeeType === 'Platform' ? '店铺' : record.payeeType === 'Distributor' ? '经销商' : record.payeeType === 'PlatformCenterSeller' ? '平台6S' : ''
    }
  }, {
    title: '收款人授权码',
    dataIndex: 'payeeCode',
  }, {
    title: '收款人名称',
    dataIndex: 'toStoreName',
  }, {
    title: '企业名称',
    dataIndex: 'companyName',
  }, {
    title: '操作人',
    dataIndex: 'operatorName',
  }, {
    title: '操作时间',
    dataIndex: 'operateTime',
  }]


  return (
    <ModalForm
      title="流水复核"
      visible={visible}
      onVisibleChange={(e) => !e && onCancel()}
      width={1000}
      modalProps={{ okText: '确认复核' }}
      {...formItemLayout}
      layout='horizontal'
      className={[record.allotStatus === 'Checked' ? 'Checked' : '', 'fhdetail'].join(' ')}
      preserve={false}
      onFinish={async () => {
        onSubmit(record.id)
      }}
    >
      <Row>
        <div className="pay-info">
          <p>收款总金额 : {record.receiptAmount || 0} , <span className="ml10">所属6S商贸公司 : {record.belongStoreName || '-'} </span>,   <span className="ml10">付款人授权码 : {record.payerCode || '-'} </span>,  <span className="ml10">用途说明 : {record.useDescription || '-'} </span> </p>
          <p> 到账日期 : {record.arrivalDate || '-'}  , <span className="ml10"> 是否连连支付 : {record.online === true ? '是':'否'} </span>,  <span className="ml10">商户交易流水号 : {record.flowNo || '-'} </span> , <span className="ml10">备注 : {record.remark || '-'} </span>  </p>
        </div>
      </Row>
      <ProTable
        options={false}
        search={false}
        pagination={false}
        columns={columns}
        dataSource={detailData}
      />



    </ModalForm>
  )
}

export default RechargeModel