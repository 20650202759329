import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import OnlineDetails from "./online-details";
import { deleteEntity, getEntities, getEntity, createEntity } from "./online.reducer";
import { RouteComponentProps } from "@reach/router";
import { OnlineClassification } from "model/product/online-classification.model";
import { Button, Form, Tooltip } from "antd";
import ListViewPage from "layouts/page-wraper/list-view-page";
import OnlineEdit from "./online-edit";
import Authorized from "common/auth/authorized";
import { PlusOutlined } from "@ant-design/icons";
import { ModalForm, ProFormDigit } from "@ant-design/pro-form";
import IntelFormText, { internationalList } from "components/IntelFormText";

export interface OnlineListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<OnlineClassification>[] = [ {
  title: '分类名称',
  dataIndex: 'name',
  render: (dom, record) => <>{internationalList(record.name || '')}</>
},{
  title: '所属层级',
  dataIndex: 'level',
  valueType: 'text',
}, {
  title: '类目id',
  dataIndex: 'id',
  valueType: 'text',
},{
  title: '排序',
  dataIndex: 'sort'
}]
const OnlineList: FC<OnlineListProps> = (props) => {
  const [form] = Form.useForm();
  return (
    <ListViewPage<OnlineClassification>
      columns={columns}
      quickFilterPanel={false}
      detailPage={<OnlineDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      rowActions={[
        (dom: ReactNode, record: any) => record.level === 1 &&  <Authorized authority="addSubclass"><ModalForm
          title={internationalList(record.name || '')}
          form={form}
          trigger={<Tooltip title={'新增子类'} key="AuditReject"><Button type="link" size="small" icon={<PlusOutlined />} /></Tooltip>}
          modalProps={{
            destroyOnClose: true,
          }}
          preserve={false}
          onFinish={async (data) => {
            props.createEntity({ ...data, parentId: String(record.id), level: 2, pathIds: String(record.id) });
            return true;
          }}
        >
          <IntelFormText
            label={'子分类名称'}
            name="name"
            form={form}
            required={true}
            fieldProps={{
              maxLength: 100
            }}
            value={props.entity.name || ''}
          />
          <ProFormDigit
            name="sort"
            label="排序"
            rules={[{ required: true }]}
            min={0}
          />
        </ModalForm></Authorized>,
      ]}
      editPage={(params: any) => <OnlineEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ onlineClassification }: RootState) => ({
  entity: onlineClassification.entity,
  entities: onlineClassification.entities,
  loading: onlineClassification.loading,
  updateSuccess: onlineClassification.updateSuccess,
  total: onlineClassification.total,
  createSuccess: onlineClassification.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  createEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OnlineList);