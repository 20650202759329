import Mantissa from "./mantissa";
import { ProColumns } from "@ant-design/pro-table";
import { priceRulesModel } from "model/product/pricingRules";
import { FC, useState, useEffect } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form, Row, Col, InputNumber, Select, message } from "antd";
import { createEntity, getEntity, reset, updateEntity, fetchLevelList } from "./pricingRules.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDependency, ProFormDigit, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { internationalList } from "components/IntelFormText";
import ProCard from "@ant-design/pro-card";
import { useEnumValue } from "common/enum/use-enum-value";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
export interface LevelEditProps extends EditStateAndDispatchProps<priceRulesModel>, StateProps, DispatchProps, RouteComponentProps {
}

const PricingRulesEdit: FC<LevelEditProps> = props => {
  const { id } = props;
  const [levelData, setlevelData]: any = useState([]);
  const [levelList, setLevelList]: any = useState([]); // 等级列表
  const [ruleType, setRuleType]: any = useState([]);//规则类型
  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();

  useEffect(() => {
    let list: any = []
    let datas = props.entity
    if (datas.ruleDetailMap) {
      for (let i in datas.ruleDetailMap) {
        list.push({
          discount: datas.ruleDetailMap[i].discount,
          remark: datas.ruleDetailMap[i].remark,
          levelNo: i
        })
      }
    }
    setlevelData([...list])
    getLevelList()

    // 尾数处理默认增加一条
    const formdata = form.getFieldsValue()
    if (!formdata.mantissaMap) {
      let data = [{
        oldNum: 4,
        newNum: 6,
        isFiexd: true
      }]
      form.setFieldsValue({
        mantissaMap: data
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props])

  useEffect(() => {
    let arr: any = [];
    levelList && levelList.forEach((e: any) => {
      arr.push({
        discount: 0,
        remark: '',
        levelNo: e.value
      })
    });
    !id && setlevelData(arr);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [levelList])


  const UpdateEntity = (data?: priceRulesModel) => {
    return new Promise<priceRulesModel>((resolve, reject) => {
      if (levelData.length === 0) {
        message.warning("等级折扣不能为空");
        reject()
        return false
      }
      let index = levelData.length - 1
      if (!levelData[index].discount || !levelData[index].levelNo) {
        message.warning("请填完整最后一行等级折扣");
        reject()
        return false
      }
      if (data) {
        let ruleDetailMap: any = {}
        if (levelData.length > 0) {
          levelData.map((item: any) => {
            ruleDetailMap[item.levelNo] = {
              discount: item.discount,
              remark: item.remark
            }
            return item
          });
        }
        data.ruleDetailMap = ruleDetailMap
        resolve(data)
      }
    })
  }

  // 获取等级列表
  const getLevelList = async () => {
    const unitRequest = await fetchLevelList();
    let data = unitRequest.map((item: any) => ({
      value: item?.levelNo,
      label: internationalList(item?.levelName)
    }))
    setLevelList(data)
  }

  // 设置等级类型
  const setLevelNo = (val: string, index: number) => {
    let data = [...levelData]
    data[index].levelNo = val
    setlevelData([...data])
  }
  // 设置折扣
  const setDiscount = (val: string, index: number) => {
    let data = [...levelData]
    data[index].discount = val
    setlevelData([...data])
  }
  // 设置备注
  const setRemark = (val: string, index: number) => {
    let data = [...levelData]
    data[index].remark = val
    setlevelData([...data])
  }

  const editableColumns: ProColumns<any>[] = [{
    title: '尾数',
    dataIndex: 'oldNum',
    renderFormItem: () => {
      return <ProFormText
        rules={[{ required: true }]}
        name="oldNum"
        placeholder="请输入尾数"
      />
    },
    render: (e, record) => <>{record.oldNum}</>
  }, {
    title: '处理后数字',
    dataIndex: 'newNum',
    renderFormItem: () => {
      return <ProFormText
        rules={[{ required: true }]}
        name="newNum"
        placeholder="请输入处理后数字"
      />
    },
    render: (e, record) => <>{record.newNum}</>
  }];

  return (
    <EditPage<priceRulesModel>
      title="定价规则"
      form={form}
      beforeUpdateEntity={UpdateEntity}
      {...props}
    >
      <Row justify="start">
        <Col span='7'>
          <ProFormText
            rules={[{ required: true }]}
            name="name"
            label="规则名称"
            placeholder="请输入规则名称"
          />
        </Col>
        <Col span='1'></Col>
        <Col span={7}>
          <ProFormSelect label="店铺" rules={[{ required: true }]} name="storeId" placeholder="店铺" request={fetchStoreList} />
        </Col>
        <Col span='1'></Col>
        <Col span='7'>
          <ProFormSelect
            rules={[{ required: true }]}
            options={convertEnumToOptionType('pricingRuleType') || []}
            name="ruleType"
            label='规则类型'
            placeholder='规则类型'
          />
        </Col>
        <Col span='1'></Col>

        <ProFormDependency name={['ruleType']}>
          {({ ruleType }) => {
            setRuleType(ruleType);
            return ruleType === 'fixedDiscount' && <>
              <Col span='7'>
                <ProFormSelect
                  rules={[{ required: true }]}
                  options={convertEnumToOptionType('roundModeType') || []}
                  name="roundMode"
                  label='取整方式'
                  placeholder='取整方式'
                />
              </Col>
              <Col span='1'></Col>
              <Col span='7'>
                <ProFormDigit
                  rules={[{ required: true }]}
                  name="scala"
                  label="保留小数位数"
                  placeholder="保留小数位数"
                />
              </Col>
              <Col span='1'></Col>
              <Col span={7}>
                <ProFormSwitch
                  name='isRound'
                  label="是否尾数处理"
                  initialValue={false}
                  fieldProps={{
                    checkedChildren: '是',
                    unCheckedChildren: '否'
                  }}
                />
              </Col>
            </>
          }}
        </ProFormDependency>
      </Row>
      <ProCard
        title="等级折扣信息"
        bordered
        headerBordered
        collapsible
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        {levelData.map((item: any, index: number) => {
          return (
            <Row justify="start" key={index}>
              <Col span='5'>
                <Form.Item label="等级名称" rules={[{ required: true }]} key={index}>
                  <Select
                    onChange={(val) => setLevelNo(val, index)}
                    defaultValue={item.levelNo ? item.levelNo : null}
                    placeholder='等级名称'
                    options={levelList}
                  />
                </Form.Item>
              </Col>
              <Col span='1'></Col>
              <Col span='5'>
                <Form.Item label={ruleType === 'fixedPrice' ? "价格" : '折扣率'} rules={[{ required: true }]}>
                  <InputNumber width='md' name='discount' className="width100" value={item.discount} placeholder={ruleType === 'fixedPrice' ? "价格" : '折扣率'} onChange={(val) => setDiscount(val, index)} />
                </Form.Item>
              </Col>
              <Col span='1'></Col>
              <Col span='8'>
                <ProFormText
                  name="remark"
                  label="备注"
                  placeholder="请输入备注"
                  fieldProps={{
                    value: item.remark || '',
                    onChange: (val) => setRemark(val?.target?.value || '', index)
                  }}
                />
              </Col>
            </Row>)
        })}
      </ProCard>
      <Form.Item name="mantissaMap" className="mantissa">
        <Mantissa editableColumns={editableColumns} />
      </Form.Item>
    </EditPage>);
};

const mapStateToProps = ({ pricingRules }: RootState) => ({
  entity: pricingRules.entity,
  updateSuccess: pricingRules.updateSuccess,
  updating: pricingRules.updating,
  createSuccess: pricingRules.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PricingRulesEdit);
