import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import PostList from './post-list';
import PostDetails from "./post-details";
import PostEdit from "./post-edit";
import './index.less'

const Post: React.FC<RouteComponentProps> = () => (
    <Router>
        <PostList path = "/"/>
        <PostDetails path = ":id"/>
        <PostEdit path = ":id/edit"/>
        <PostEdit path = "new"/>
    </Router>
);
export default Post;
