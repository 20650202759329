import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./reward.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { RewardList } from "model/bonus/rewardList.model";
export interface RewardListEditProps extends EditStateAndDispatchProps<RewardList>, StateProps, DispatchProps, RouteComponentProps {
}

const RewardListEdit: FC<RewardListEditProps> = props => {

  return (
    <EditPage<RewardList>
      title=""
      {...props}
    >

    </EditPage>);
};

const mapStateToProps = ({ rewardList }: RootState) => ({
  entity: rewardList.entity,
  updateSuccess: rewardList.updateSuccess,
  updating: rewardList.updating,
  createSuccess: rewardList.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RewardListEdit);
