import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import CourseList from './course-list';
import CourseDetails from "./course-details";
import CourseEdit from "./course-edit";
import './index.less'

const Course: React.FC<RouteComponentProps> = () => (
    <Router>
        <CourseList path = "/"/>
        <CourseDetails path = ":id"/>
        <CourseEdit path = ":id/edit"/>
        <CourseEdit path = "new"/>
    </Router>
);
export default Course;
