import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form} from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./sealAuth.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { SealAuth } from "model/contract/sealAuth.model";
export interface SealAuthEditProps extends EditStateAndDispatchProps<SealAuth>, StateProps, DispatchProps, RouteComponentProps {
}

const SealAuthEdit: FC<SealAuthEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<SealAuth>
      title="编辑"
      form={form}
      {...props}
    >
    </EditPage>);
};

const mapStateToProps = ({ sealAuth }: RootState) => ({
  entity: sealAuth.entity,
  updateSuccess: sealAuth.updateSuccess,
  updating: sealAuth.updating,
  createSuccess: sealAuth.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SealAuthEdit);
