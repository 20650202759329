import React, {CSSProperties, FC, ReactNode} from 'react';
import { Button } from 'antd';
import Authorized from './authorized';

export interface AuthorizedProps {
  children?: ReactNode;
  authority: string;
  type?: "default" | "primary" | "ghost" | "dashed" |  "link" | "text";
  style?: CSSProperties | undefined;
  size?: 'small' | 'middle' | 'large' | undefined;
  icon?: React.ReactNode;
  danger?: boolean;
  disabled?: boolean | undefined;
  loading?: boolean | {
    delay?: number;
};
  onClick?: () => void;
}

const AuthButton: FC<AuthorizedProps> = props => {
  const {authority, type, style, size, icon, danger, disabled, loading,onClick} = props;

  return (
    <Authorized authority={authority}>
      <Button 
        size={size} 
        style={style} 
        type={type} 
        icon={icon} 
        danger={danger}
        disabled={disabled}
        loading={loading}
        onClick={onClick}>
        {props.children}
      </Button>
    </Authorized>
  )
};

export default AuthButton;
