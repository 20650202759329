import React, { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity, getRegions } from './region.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Region } from "model/product/region.model";
import { Card, TreeSelect } from "antd";
import Title from "antd/lib/typography/Title";
import { Storage } from 'utils/storage-util';
import { regionObj } from "../delivery/delivery-edit";

export interface RegionDetailsProps extends DetailsStateAndDispatchProps<Region>,
  RouteComponentProps, StateProps, DispatchProps {
}
const RegionDetails: FC<RegionDetailsProps> = (props) => {
  const [treeData, setTreeData] = useState([]);

  useEffect(() => {
    const xTenant = Storage.session.get("x-tenant");
    const regionKey: 'tmm' | 'ttw' | 'tml' | 'tsg' = regionObj[xTenant] ? xTenant : 'tmm';
    getRegions(regionObj[regionKey][0]).then(res => setTreeData(res))
  }, []);

  return (
    <DetailsPage<Region>
      title={'区域模版详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Region>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>
      <Card className="region-details-regions" title={<Title level={5}>{props.entity.regionTemplateType === 'INCLUDE' ? '销售区域' : '禁售区域'}</Title>}>
        <TreeSelect
          showSearch
          multiple
          value={props.entity.regions ? props.entity.regions : []}
          treeData={treeData}
          treeCheckable
          // disabled
          showCheckedStrategy="SHOW_PARENT"
          // onChange={(res) => console.log(res)}
        />
      </Card>
    </DetailsPage>);
};

const mapStateToProps = ({ region }: RootState) => ({
  entity: region.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegionDetails);
