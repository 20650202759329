import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import WholesaleDetails from './wholesale-details';
import WholesaleEdit from './wholesale-edit';
import WholesaleList from './wholesale-list';

const Wholesale: React.FC<RouteComponentProps> = () => (
  <Router>
    <WholesaleList path="/" />
    <WholesaleDetails path=":id" />
    <WholesaleEdit path="new" />
  </Router>
);
export default Wholesale;
