import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { AfterSales, defaultAfterSales } from 'model/procure/after-sales.model';
import { CrudPostAction, QueryParameter } from "reducers/redux-action.type";
import axios from "axios";
import { Store } from 'redux';

const entity = 'afterSales';

const apiUrl = '/api/yumei-mall-order/after-sale-order';
const standardReducerImpl = new StandardReducerImpl<AfterSales>(entity, defaultAfterSales, apiUrl);

export type AfterSalesState = StandardState<AfterSales>;
export const afterSales = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 同意申请
export const updateAgree: CrudPostAction<AfterSales> = (entity) => async dispatch => {
  const requestUrl = `${apiUrl}/agree`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, { ...entity }),
  });
};

// 拒绝申请
export const updateRefuse: CrudPostAction<AfterSales> = (entity) => async dispatch => {
  const requestUrl = `${apiUrl}/refuse`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, { ...entity }),
  });
};

export const createBatchEntity: CrudPostAction<AfterSales> = (entity) => async dispatch => {
  const requestUrl = `${apiUrl}/batch`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
    payload: axios.post(requestUrl, { ...entity }),
  });
};

// 已收到退货-签收
export const updateSign: CrudPostAction<AfterSales> = (entity) => async dispatch => {
  const requestUrl = `${apiUrl}/sign/${entity.id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl),
  });
};

// 售后退款
export const updateRefund: CrudPostAction<AfterSales> = (entity: AfterSales) => async dispatch => {
  const requestUrl = `${apiUrl}/refund/${entity.id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, {}, { params: { offLineRefund: entity.offLineRefund } }),
  });
};

// 买家发货信息填写
export const buyerDelivery: CrudPostAction<any> = (entity: any) => async dispatch => {
  const requestUrl = `${apiUrl}/buyer-express/${entity.id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, { ...entity }, { params: { customerId: entity.customerId } }),
  });
};

//售后撤销
export const revocationAfterOrder: CrudPostAction<AfterSales> = (entity: AfterSales) => async dispatch => {
  const requestUrl = `${apiUrl}/operation/revocation/${entity.id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl),
  });
};

//申请退款的订单行信息 
export const queryOrderSale = async (id: any) => {
  const requestUrl = `${apiUrl}/order/${id}/info`;
  const res = await axios.get(requestUrl);
  return res.data;
}

export {
  apiUrl,
};

//申请退款的订单行信息 
export const associationReturnPackage = async (afterSaleId: string | number, data: any) => {
  const requestUrl = `/api/yumei-mall-order/after_sale_return_package/association/${afterSaleId}`;
  const res = await axios.post(requestUrl, data);
  return res.data;
}

//申请退款的订单行信息 
export const associationManualOrder = async (afterSaleOrderId: string | number, manualOrderId: string | number) => {
  const requestUrl = `/api/yumei-mall-order/after-sale-order/${afterSaleOrderId}/association/${manualOrderId}`;
  const res = await axios.post(requestUrl);
  return res.data;
}

//退货地址
export const fetchAddressList= async (sellerId: number | string) => {
  const requestUrl = `/api/yumei-mall-store/seller/address?sellerId.equals=${sellerId}`;
  const res = await axios.get(requestUrl);
  return res.data;
}

// 查询仓库
export const fetchWarehouseList = async (params?: QueryParameter) : Promise<Store[]> => {
  const axiosResponse = await axios.get<Store[]>(`/api/yumei-mall-order/order/warehouse/0`, {
      params
  });
  return axiosResponse.data || [];
}
