import React, { FC, useEffect, useState } from 'react'
import { Input, Form, Select, Alert, Button } from 'antd';
import { Order, ProvinceCode } from 'model/procure/order.model';
import { queryBladeSystemSelect } from '../purchase-order/order.reducer';
import { ModalForm, ProFormDependency, ProFormSwitch, ProFormText } from '@ant-design/pro-form';
import { Payload, PayloadResult } from 'reducers/redux-action.type';
import { Storage } from 'utils/storage-util';
const { Option } = Select;

export interface OrderDetailsUpdateMsgProps {
  entity: Order,
  updateConsignee: (params: any) => Payload<Order> | PayloadResult<Order> | any
  Encrypt?: boolean;
}

const OrderDetailsUpdateMsg: FC<OrderDetailsUpdateMsgProps> = (props) => {
  const { entity, updateConsignee, Encrypt = false, } = props;
  const [form] = Form.useForm();
  const [provinceSelectData, setProvinceSelectData] = useState<ProvinceCode[]>([]);
  const [citySelectData, setCitySelectData] = useState<ProvinceCode[]>([]);
  const [districtSelectData, setDistrictSelectData] = useState<ProvinceCode[]>([]);

  // 获取表单初始值
  useEffect(() => {
    form.setFieldsValue({
      address: {
        ...entity?.address,
        province: entity.address?.provinceName,
        city: entity.address?.cityName,
        district: entity.address?.districtName
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  //联系地址
  const getBladeSystemSelect = async (code: string | number) => {
    const res = await queryBladeSystemSelect(code);
    if (res) return res;
  }

  const setDataFun = async () => {
    const xTenant = Storage.session.get("x-tenant");
    const regionObj: any = {
      tmm: ['00', '11', '1101'],
      ttw: ['01', '0101', '010101'],//台湾
      tml: ['N3', 'N301', 'N30101'],//马来西亚
      tsg: ['02', '0201', '020101'],//新加坡
    };
    const regionKey: 'tmm' | 'ttw' | 'tml' | 'tsg' = regionObj[xTenant] ? xTenant : 'tmm';
    setProvinceSelectData(await getBladeSystemSelect(regionObj[regionKey][0]));
    setCitySelectData(await getBladeSystemSelect(regionObj[regionKey][1]));
    setDistrictSelectData(await getBladeSystemSelect(regionObj[regionKey][2]));
  };

  const handleProvinceChange = async (value: any, option: any) => {
    form.setFieldsValue({ address: { city: '', district: '', provinceName: option['children'] } });
    setCitySelectData(await getBladeSystemSelect(value));
    setDistrictSelectData([]);
  };

  const handleCityChange = async (value: any, option: any) => {
    form.setFieldsValue({ address: { district: '', cityName: option['children'] } });
    setDistrictSelectData(await getBladeSystemSelect(value));
  };

  const getConsigneeMsgOk = async (value: any) => {
    if (value.address.province === entity?.address?.provinceName) value.address.province = entity?.address?.province;
    if (value.address.city === entity?.address?.cityName) value.address.city = entity?.address?.city;
    if (value.address.district === entity?.address?.districtName) value.address.district = entity?.address?.district;
    const params = {
      address: { ...entity?.address, ...value.address },
      id: entity?.id,
    }
    updateConsignee(params);
    // setIsConsigneeMsg(false);
  };

  return (
    <ModalForm
      title='修改收货人信息'
      width={500}
      // trigger={<AuthButton authority='W_order_address' style={{ padding: '0 0 0 8px' }} size="small" type='link' onClick={() => setDataFun()} >
      //   修改<EditOutlined style={{ marginLeft: '2px' }} />
      // </AuthButton>}
      trigger={<Button size="small" type='link' onClick={() => setDataFun()} >修改地址</Button>}
      onFinish={async (res) => {
        form.validateFields().then(values => {
          form.resetFields();
          getConsigneeMsgOk(values);
        })
          .catch(info => {
            // console.log('Failed:', info);
          });
        return true
      }}
    >
      {/* 是否修改发货信息 */}
      <Alert message='请务必在买家知情且同意下修改收货信息' type='warning' showIcon />
      <br />
      <Form
        labelAlign='right'
        form={form}
        name='form_in_modal'
        labelCol={{ span: 4 }} wrapperCol={{ span: 16 }}
      >
        <Form.Item
          label='收货人'
          name={['address', 'consigneeName']}
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        {!Encrypt && <Form.Item
          label='联系电话'
          name={['address', 'mobile']}
          rules={[{ required: true }]}>
          <Input maxLength={11} />
        </Form.Item>}
        {Encrypt && <><ProFormDependency name={['address']}>
          {({ address }) => <ProFormText
            label="收货人手机号"
            name={['address', 'mobile']}
            disabled={!address?.notEncrypt || address?.notEncrypt === false}
            rules={[{ required: true }]}
            width={266}
          />
          }
        </ProFormDependency>
          <ProFormSwitch
            name={['address', 'notEncrypt']}
            label="更换收货人手机号"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否',
            }}
          /></>}
        <Form.Item label='联系地址'>
          <Input.Group compact>
            <Form.Item name={['address', 'province']} noStyle rules={[{ required: false }]}>
              <Select style={{ width: 100 }} onChange={handleProvinceChange} >
                {provinceSelectData && provinceSelectData.map((province: ProvinceCode) => (
                  <Option key={province.code} value={String(province.code)}>{province.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['address', 'city']} noStyle rules={[{ required: false }]}>
              <Select style={{ width: 100 }} onChange={handleCityChange} >
                {citySelectData && citySelectData.map((city: ProvinceCode) => (
                  <Option disabled={city.name === '石家庄市'} key={city.code} value={String(city.code)}>{city.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item name={['address', 'district']} noStyle rules={[{ required: false }]}>
              <Select style={{ width: 100 }}
                onChange={(value, option: any) => form.setFieldsValue({ address: { districtName: option['children'] } })}>
                {districtSelectData && districtSelectData.map((district: ProvinceCode) => (
                  <Option key={district.code} value={String(district.code)}>{district.name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Input.Group>
        </Form.Item>
        <Form.Item name={['address', 'provinceName']} hidden><Input /></Form.Item>
        <Form.Item name={['address', 'cityName']} hidden><Input /></Form.Item>
        <Form.Item name={['address', 'districtName']} hidden><Input /></Form.Item>
        <Form.Item
          label='详细地址'
          name={['address', 'address']}
          rules={[{ required: true }]}>
          <Input />
        </Form.Item>
      </Form>
    </ModalForm>
  );
};

export default OrderDetailsUpdateMsg;
