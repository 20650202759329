import React, { FC, useCallback, useState, memo, useEffect } from 'react';
import { Tabs, Image, Button, Input, Empty, Tooltip, Select, Spin, Form, Drawer, Space, Radio } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
import '../style/list_nav.less';
import { getGoodsDetailByIds, getCollageNavProductList } from '../../api';
import { collageList } from '../../../../model/decorate/decorate.model';
import GoodsList from '../goods-list';
import Qs from 'qs';
import { getGlobalKeyValue } from 'utils/utils';
import { useParamGeneral } from 'components/useParamGenera';

const { Option } = Select;
const { TabPane } = Tabs;
export interface Props {
  dataField: {
    nav: any[]
    [key: string]: any
  }
  index: number
  config?: any
}
interface SetProps {
  dataField?: any
  id?: string
  methods?: any
  selectedInfo?: any
  otherInfo?: any
}

const RenderGoodsList: FC<{ data: { [key: string]: any } }> = memo(({ data }) => {
  const getQueryData = (link: string) => {
    let _return = Qs.parse(link);
    // for (let k in _return) {
    //   _return[k + '.in'] = _return[k]
    //   delete _return[k]
    // }
    return _return
  }
  const [params, setParams] = useState({
    current: 1,
    pageSize: 10,
    ...getQueryData(data.link)
  } as { [key: string]: any })
  const [list, setList] = useState([])
  const [state, setState] = useState({
    noData: false,
    noMore: false,
    loading: data.data_type === 'filter' ? true : false
  })
  useEffect(() => {
    setParams({
      ...params,
      current: 0,
      ...getQueryData(data.link)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  useEffect(() => {
    if (data.data_type !== 'filter') {
      if (data.goodsIds && data.goodsIds.length > 0) {
        getGoodsDetailByIds(data.goodsIds, data.SkytoneCard || false)
          .then((res: any) => {
            if (res && res.data.length > 0) {

              setList(res.data)
            }
          })
      } else {
        setList([])
      }
      return
    }
    getCollageNavProductList(params).then((res: any) => {
      let records = res.data, current = params.current;
      if (current === 0) {
        if (records.length > 0) {
          setList(records)
          setState({
            loading: false,
            noMore: false,
            noData: false,
          })
        } else {
          setList([])
          setState({
            loading: false,
            noMore: false,
            noData: true,
          })
        }
      } else {
        if (records.length > 0) {
          setList(list.concat(records))
          setState({
            loading: false,
            noMore: false,
            noData: false,
          })
        } else {
          setState({
            loading: false,
            noMore: true,
            noData: false,
          })
        }
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, setParams])
  return <div className="goods-list-container" onScroll={
    (e: any) => {
      if (data.data_type !== 'filter') return
      let scrollTop = e.target.scrollTop
      // console.log('scrollTop',scrollTop,'scrollHeight',e.target.scrollHeight,'offsetHeight',e.target.offsetHeight)
      if ((e.target.scrollHeight - e.target.offsetHeight) > scrollTop - 100) {
        if (state.loading || state.noData || state.noMore) return
        setState({
          loading: true,
          noMore: state.noMore,
          noData: state.noData,
        })
        setParams({
          ...params,
          current: params.current + 1
        })

      }
    }
  }>
    <GoodsList dataField={{ SkytoneCard: data.SkytoneCard }} list={list} type={'collageList'} />
    {
      state.noData && <Empty />
    }
    {
      state.noMore && <div className="no-more">没有更多商品了</div>
    }
    {
      state.loading && <div className="loading-con" style={
        {
          paddingTop: `${params.current === 1 ? 80 : 20}px`
        }
      }><Spin /></div>
    }
  </div>
})

const CollageListNav: FC<Props> = (({ dataField, config }) => {
  const [tabIndex, setTabIndex] = useState('0');
  return (
    <div className={`list-nav-container ${dataField.pageStyle}`} style={
      {
      }
    }>
      <Tabs activeKey={tabIndex} className="list-nav-tabs" onChange={
        (e) => {
          setTabIndex(e)
        }
      }>
        {
          (dataField?.nav).map((v: any, i: number) => {
            return <TabPane tab={`${v.title}`} key={i + ''} >
              {
                tabIndex === `${i}` && <RenderGoodsList data={{ ...v, ...dataField, SkytoneCard: config.SkytoneCard || false }} />
              }
            </TabPane>
          })
        }

      </Tabs>

    </div>
  )
})

interface ImageItemProps {
  id: string
  index: number
  data?: any
  updateModuleDataPrivate: (dataField: any, index: number, type?: string) => void
  setShowLinkSelectionPrivate: (show: boolean) => void
  setCallerIndex: (value: any) => void
  setShowDrawer: (value: boolean) => void
  SkytoneCard?: boolean
}

const DrawerGoodsList: FC<{ language?: string, visible: boolean, methods: { [key: string]: any }, navData: any, setShowDrawer: (value: boolean) => void, updateItemGoodsList: (list: any[]) => void, SkytoneCard: boolean }> = ({ language, visible, setShowDrawer, methods, navData, updateItemGoodsList, SkytoneCard }) => {
  const retailPriceKey: string = SkytoneCard ? 'salePrice' : 'retailPrice';
  const [goodsList, setGoodsList] = useState([] as any);
  const { currency } = useParamGeneral();
  const [goodsIds, setGoodsIds] = useState(navData.goodsIds);
  const updateGoodsListPrivate = (index: number, type: string) => {
    let selectedItem = goodsIds[index];
    if (type === 'delete') {
      delete goodsIds[index]
      updateItemGoodsList(goodsIds.filter((v: any) => v))
    } else if (type === 'moveDown') {
      let nextIndex = index + 1, length = goodsIds.length,
        nextItem, newImageList: any[] = [].concat(goodsIds);
      if (nextIndex > length - 1) {
        nextIndex = 0
      }
      nextItem = goodsIds[nextIndex];
      newImageList[nextIndex] = selectedItem
      newImageList[index] = nextItem
      updateItemGoodsList(newImageList)
    } else if (type === 'moveUp') {
      let nextIndex = index - 1, length = goodsIds.length,
        nextItem, newImageList: any[] = [].concat(goodsIds);
      if (nextIndex < 0) {
        nextIndex = length - 1
      }
      nextItem = goodsIds[nextIndex];
      newImageList[nextIndex] = selectedItem
      newImageList[index] = nextItem
      updateItemGoodsList(newImageList)
    }
  }
  useEffect(() => {
    if (navData.data_type !== 'list') return;
    setGoodsIds(navData.goodsIds)
    if (navData.goodsIds && navData.goodsIds.length > 0) {
      // SkytoneCard
      getGoodsDetailByIds(navData.goodsIds, SkytoneCard)
        .then((res: any) => {
          if (res && res.data.length > 0) {
            setGoodsList(res.data)
          }
        })
    } else {
      setGoodsList([])
    }
  }, [navData.goodsIds, navData.data_type, SkytoneCard])
  return <Drawer
    title="查看商品"
    placement="right"
    className="drawer-container"
    width={500}
    onClose={
      () => {
        setShowDrawer(false)
      }
    }
    visible={visible}
    extra={
      <Space>
        <Button type="primary" onClick={
          () => {
            methods.setShowProductSelectionPrivate(true)
          }
        }>新增</Button>
      </Space>
    }
  >
    <div className="goods-list-con">
      {
        goodsList.length > 0 && goodsList.map((v: any, i: any) => {
          return <div className="goods-item" key={v.id}>
            <div className="img">
              <div className="number">{i + 1}</div>
              <Image preview={false} src={getGlobalKeyValue(v.mainPicture, language).indexOf('http') === 0 ? getGlobalKeyValue(v.mainPicture, language) + '?imageView2/2/w/400' : getGlobalKeyValue(v.mainPicture, language)}></Image>
            </div>
            <div className="info">
              <p className="name text-2">{getGlobalKeyValue(v.name, language)}</p>
              <p className="price">{currency?.symbol}{v.items && v.items.length > 0 ? v.items[0][retailPriceKey] : v[retailPriceKey]}</p>
            </div>
            <div className="operation">
              <div className="icon " onClick={
                () => {
                  updateGoodsListPrivate(i, 'moveUp')
                }
              }>
                <Tooltip title="上移">
                  <ArrowUpOutlined />
                </Tooltip>
              </div>
              <div className="icon" onClick={
                () => {
                  updateGoodsListPrivate(i, 'moveDown')
                }
              }>
                <Tooltip title="下移">
                  <ArrowDownOutlined />
                </Tooltip>
              </div>
              <div className="icon" onClick={
                () => {
                  updateGoodsListPrivate(i, 'delete')
                }
              }>
                <Tooltip title="移除" >
                  <DeleteOutlined />
                </Tooltip>
              </div>

            </div>
          </div>
        })
      }
      {
        (!goodsList || goodsList.length === 0) && <Empty description="暂无商品" style={
          {
            paddingTop: 100
          }
        } />
      }
    </div>
  </Drawer>
}

const ImageItem: FC<ImageItemProps> = (({ data, id, index, updateModuleDataPrivate, setShowDrawer, setCallerIndex, SkytoneCard }) => {
  const [goodsList, setGoodsList] = useState([] as any);

  useEffect(() => {
    if (data.data_type !== 'list') return;
    if (data.goodsIds && data.goodsIds.length > 0) {
      getGoodsDetailByIds(data.goodsIds, SkytoneCard)
        .then((res: any) => {
          if (res && res.data.length > 0) {
            setGoodsList(res.data)
          }
        })
    } else {
      setGoodsList([])
    }
  }, [data.goodsIds, data.data_type, SkytoneCard])
  return <div className={`image-item`} >

    <div className="form-con" >
      <Form.Item label="导航标题" >
        <Input placeholder="请输入导航标题" value={data.title} onChange={(e) => {
          data.title = e.target.value;
          updateModuleDataPrivate(data, index, '')
        }} />
      </Form.Item>
      <Form.Item label="数据来源">
        <Select
          placeholder="请选择商品数据来源"
          onChange={
            (e) => {
              data.data_type = e
              updateModuleDataPrivate(data, index, '')
            }
          }
          value={data.data_type}
        >
          <Option value="filter">指定查询条件</Option>
          <Option value="list">商品集合</Option>
        </Select>
      </Form.Item>
      {
        data.data_type === 'filter' && <Form.Item label="查询参数" extra="分类参数示例：categoryId=000" >
          <Input placeholder="请输入商品查询参数" value={data.link} onChange={(e) => {
            data.link = e.target.value;
            updateModuleDataPrivate(data, index, '')
          }} />
        </Form.Item>
      }
      {
        data.data_type === 'list' && <Form.Item label="商品集合" >
          <div className="desc">
            {/* <p className="l">已选0个商品</p> */}
            <Button type="primary" onClick={
              () => {
                setCallerIndex(index)
                setShowDrawer(true)
              }
            }>编辑 (已选{(goodsList.length) || 0})</Button>
          </div>
        </Form.Item>
      }


    </div>


    <div className="operation">
      {/* <div className="icon">{index + 1}</div> */}
      <div className="icon " onClick={
        () => {
          updateModuleDataPrivate(data, index, 'moveUp')
        }
      }>
        <Tooltip title="上移">
          <ArrowUpOutlined />
        </Tooltip>
      </div>

      <div className="icon" onClick={
        () => {
          updateModuleDataPrivate(data, index, 'moveDown')
        }
      }>
        <Tooltip title="下移">
          <ArrowDownOutlined />
        </Tooltip>
      </div>
      <div className="icon" onClick={
        () => {
          updateModuleDataPrivate(data, index, 'delete')
        }
      }>
        <Tooltip title="移除" >
          <DeleteOutlined />
        </Tooltip>
      </div>

    </div>
  </div>
})
export const CollageListNavSet: FC<SetProps> = (({ dataField, id, methods, selectedInfo, otherInfo }) => {
  const [dataFieldPrivate, setDataFieldPrivate] = useState(dataField)
  const [callerIndex, setCallerIndex] = useState(-1);
  const [showDrawer, setShowDrawer] = useState(false)
  const updateModuleDataPrivate = useCallback(
    (item:any, index:any, type:any) => {//type delete up down
      let newDataFieldPrivate = dataFieldPrivate;
      if (!type) {
        newDataFieldPrivate.nav[index] = item
      } else if (type === 'delete') {
        delete newDataFieldPrivate.nav[index]
        newDataFieldPrivate.nav = newDataFieldPrivate.nav.filter((v: any) => v)
      } else if (type === 'moveDown') {
        let nextIndex = index + 1, length = newDataFieldPrivate.nav.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.nav);
        if (nextIndex > length - 1) {
          nextIndex = 0
        }
        nextItem = newDataFieldPrivate.nav[nextIndex];
        newImageList[nextIndex] = item
        newImageList[index] = nextItem
        newDataFieldPrivate.nav = newImageList
      } else if (type === 'moveUp') {
        let nextIndex = index - 1, length = newDataFieldPrivate.nav.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.nav);
        if (nextIndex < 0) {
          nextIndex = length - 1
        }
        nextItem = newDataFieldPrivate.nav[nextIndex];
        newImageList[nextIndex] = item
        newImageList[index] = nextItem
        newDataFieldPrivate.nav = newImageList
      } else if (type === 'add') {
        if (item instanceof Array) {
          newDataFieldPrivate.nav = [...item, ...newDataFieldPrivate.nav]
        } else {
          newDataFieldPrivate.nav.unshift(item)
          newDataFieldPrivate.nav = [].concat(newDataFieldPrivate.nav)
        }
      }
      // newDataFieldPrivate
      // console.log('newDataFieldPrivate',newDataFieldPrivate)
      methods.updateModuleData(newDataFieldPrivate, id)
    },
    [dataFieldPrivate, methods, id]
  )
  const updateItemGoodsList = useCallback(
    (newList:any) => {
      let callerItem = { ...dataFieldPrivate.nav[callerIndex] }
      callerItem.goodsIds = newList
      updateModuleDataPrivate({
        ...callerItem,
      }, callerIndex, '')
    },
    [callerIndex, dataFieldPrivate, updateModuleDataPrivate]
  )

  useEffect(
    () => {
      // console.log('selectInfo Change ListNav', selectedInfo, callerIndex)
      if (selectedInfo && (selectedInfo instanceof Array) && selectedInfo.length > 0) {
        let callerItem = { ...dataFieldPrivate.nav[callerIndex] }
        callerItem.goodsIds = [...selectedInfo.map((v: any) => {
          return v.id
        })].concat(callerItem.goodsIds)
        callerItem.goodsIds = callerItem.goodsIds.filter((v: any) => v)
        updateModuleDataPrivate({
          ...callerItem,
        }, callerIndex, '')
        return
      }
      if (!selectedInfo || !selectedInfo.path) return
      let callItem = { ...dataFieldPrivate.nav[callerIndex] }
      callItem.link = selectedInfo.path
      callItem.type = selectedInfo.openType
      updateModuleDataPrivate(callItem, callerIndex, '')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedInfo]
  )
  useEffect(() => {
    setDataFieldPrivate({ listStyle: { selected: 'card' }, ...dataField })
  }, [dataField])

  return (
    <div className="list-nav-set" >
      <div className="set-con" key="1">
        <Form.Item label="列表类型" >
          {dataFieldPrivate?.listStyle?.selected && <Radio.Group value={'collageList'} onChange={
            (e) => {
              let value = e.target.value;
              if (value !== dataFieldPrivate.listStyle.selected) {
                methods.updateModuleData({
                  ...dataFieldPrivate,
                  listStyle: {
                    ...dataFieldPrivate.listStyle,
                    selected: value
                  }
                }, id)
              }
            }
          }>
            {
              collageList.map((v: any, i: number) => <Radio.Button value={v.value} key={i}>{v.label}</Radio.Button>)
            }
          </Radio.Group>}
        </Form.Item>
      </div>
      <div className="goods-set-title">
        <p className="left">共{dataFieldPrivate?.nav.length || 0}</p>
        <Button className="add" onClick={
          () => {
            // methods.setShowProductSelectionPrivate(true)
            updateModuleDataPrivate({ "hint": "", "icon": "", "link": "", "title": "标题", "data_type": "filter", "goodsIds": [] }, 0, 'add')
          }
        } type="primary">新增</Button>
      </div>
      <div className="image-list">
        {dataFieldPrivate?.nav.map((v: any, i: number) => {
          return <ImageItem
            key={v.id}
            id={v.id}
            index={i}
            data={v}
            updateModuleDataPrivate={updateModuleDataPrivate}
            setShowLinkSelectionPrivate={methods.setShowLinkSelectionPrivate}
            setCallerIndex={setCallerIndex}
            setShowDrawer={setShowDrawer}
            SkytoneCard={dataField?.SkytoneCard || false}
          />

        })}
      </div>
      <DrawerGoodsList SkytoneCard={dataField?.SkytoneCard || false} language={otherInfo.language} visible={showDrawer} updateItemGoodsList={updateItemGoodsList} navData={callerIndex !== -1 ? dataField.nav[callerIndex] : []} setShowDrawer={setShowDrawer} methods={methods} />

    </div>
  )
})

export default CollageListNav