import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import DirectAscentDetails from './directAscent-details';
import DirectAscentEdit from './directAscent-edit';
import DirectAscentList from './directAscent-list';

const DirectAscent: React.FC<RouteComponentProps> = () => (
  <Router>
    <DirectAscentList path="/" />
    <DirectAscentDetails path=":id" />
    <DirectAscentEdit path=":id/edit" />
    <DirectAscentEdit path="new" />
  </Router>
);
export default DirectAscent;
