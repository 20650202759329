import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import WechatbindList from './wechatbind-list';

const Wechatbind: React.FC<RouteComponentProps> = () => (
  <Router>
    <WechatbindList path="/" />
  </Router>
);
export default Wechatbind;
