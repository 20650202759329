import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './summary.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { SummaryList } from "model/bonus/summaryList.model";

export interface SummaryListDetailsProps extends DetailsStateAndDispatchProps<SummaryList>,
  RouteComponentProps, StateProps, DispatchProps {
}
const SummaryListDetails: FC<SummaryListDetailsProps> = (props) => {
  return (
    <DetailsPage<SummaryList>
      title={'汇总'}
      {...props}
    >
      <ProDescriptions<SummaryList>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ summaryList }: RootState) => ({
  entity: summaryList.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SummaryListDetails);
