import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './split-order-type.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { SplitOrderType } from "model/product/split-order-type.model";

export interface SplitOrderTypeDetailsProps extends DetailsStateAndDispatchProps<SplitOrderType>,
  RouteComponentProps, StateProps, DispatchProps {
}
const SplitOrderTypeDetails: FC<SplitOrderTypeDetailsProps> = (props) => {
  return (
    <DetailsPage<SplitOrderType>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<SplitOrderType>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ splitOrderType }: RootState) => ({
  entity: splitOrderType.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SplitOrderTypeDetails);
