import { BaseModel } from 'model/base.model';

export interface ReturnPackage extends BaseModel {
  virtualPackage?: boolean;
  association?: boolean;
  cancel?: boolean;
  belongTstCode?: string;
  returnPackageName?: string;
  provinceName?: string;
  cityName?: string;
  districtName?: string;
  address?: string;
  phone?: string;
  packageProductDetails?: PackageProductDetailsItem[]
}

interface PackageProductDetailsItem {

};

export const ReceivingLocation = {
  '本仓': '本仓',
  '奉贤仓': '奉贤仓',
  '不寄回': '不寄回'
};

export const ReturnPackageOrderType = {
  '1': '退货原始单号',
  '2': '退货申请单号',
};

export const validityType = {
  '0': '无限期',
  '1': '有效期'
};

export const reasonOption = [{
  top: '料体问题',
  bottom: ['料体异常', '料体颜色异常', '料体有异物', '料体异味', '料体结块', '膏体断裂', '膏体瑕疵', '膏体融化', '膏体褪色', '膏体旋转不出', '口感异常', '其他（需备注）',]
}, {
  top: '瓶器问题',
  bottom: ['无法按压', '破损', '漏液', '空瓶', '其他（需备注）',]
}, {
  top: '包装问题',
  bottom: ['包装破损', '包装挤压变形', '包装发霉', '包装掉色', '供应商漏装', '包装与内件产品不符', '包装未见生产日期', '其他（需备注）',]
}, {
  top: '仪器问题',
  bottom: ['无法蓄电', '无法充电', '无法开机', '无法正常使用', '外观问题', '其他（需备注）',]
}, {
  top: '7天无理由',
  bottom: ['无理由',]
}, {
  top: '其它问题',
  bottom: ['大健康客诉', '美容客诉']
}]

export const defaultReturnPackage: Readonly<ReturnPackage> = {
};