import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form, Row, Col } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./flowTemplate.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { FlowTemplate } from "model/financial/flowTemplate.model";
import { ProFormText } from "@ant-design/pro-form";
export interface FlowTemplateEditProps extends EditStateAndDispatchProps<FlowTemplate>, StateProps, DispatchProps, RouteComponentProps {
}

const LevelEdit: FC<FlowTemplateEditProps> = props => {
  const [form] = Form.useForm();
  const { entity } = props;
  const {
    ...editProps
  } = props;
  const [entityCopy, setEntityCopy] = useState<FlowTemplate>({});

  useEffect(() => {
    if (entity.id) {
      const entityCopy = JSON.parse(JSON.stringify(entity));
      for (let key in entityCopy) {
        if (key !== 'id' && key !== 'bankName' && key !== 'arrivalDateFormat') {
          entityCopy[key] = JSON.stringify(entityCopy[key])
        }
      }
      setEntityCopy(entityCopy);
    } else {
      setEntityCopy({})
    }
  }, [entity]);

  // 提交前处理数据
  const UpdateEntity = (data?: any) => {
   
    return new Promise<any>((resolve, reject) => {
      if (data) {
        for (let key in data) {
          if (key !== 'id' && key !== 'bankName' && key !== 'arrivalDateFormat') {
            if (data[key].length > 0) {
              data[key] = JSON.parse(data[key])
            } else {
              data[key] = []
            }
            //data[key] = data[key].length > 0 ? JSON.parse(data[key]) : null
          }

          resolve(data)
        }
      }
    })
  }
  // 输入框格式验证
  const validator = [
    {
      required: false,
    },
    () => ({
      validator(_: any, value: any) {
        if (value && typeof JSON?.parse(value) === "object") {
          return Promise.resolve();
        } else if (value && typeof JSON?.parse(value) != "object") {
          return Promise.reject(new Error('格式错误'));
        } else {
          return Promise.resolve();
        }
      },
    }),
  ]

  // 输入框格式验证
  const validatorfalse = [
    {
      required: false,
    },
    () => ({
      validator(_: any, value: any) {
        if (value && typeof JSON?.parse(value) === "object") {
          return Promise.resolve();
        } else if (value && typeof JSON?.parse(value) != "object") {
          return Promise.reject(new Error('格式错误'));
        } else {
          return Promise.resolve();
        }
      },
    }),
  ]

  return (
    <EditPage<FlowTemplate>
      title="流水模板"
      beforeUpdateEntity={UpdateEntity}
      form={form}
      {...editProps}
      entity={entityCopy}
    >
      <Row>
        <Col span={23}>
          <ProFormText
            name="bankName"
            label="银行名称"
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
      <Row>
        <Col span={7}>
          <ProFormText
            extra='银行名称举例: [1]'
            name="bankNameIndex"
            width="md"
            label="银行名称"
            rules={validator}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            extra='银行账号举例: [1]'
            name="bankAccountIndex"
            width="md"
            label="银行账号"
            rules={validator}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            extra='收款金额举例: [1]'
            name="receiptAmountIndex"
            width="md"
            label="收款金额"
            rules={validator}
          />
        </Col>
      </Row>
      <Row>
        <Col span={11}>
          <ProFormText
            extra='到账日期举例: [1]'
            name="arrivalDateIndex"
            label="到账日期"
            rules={validator}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={11}>
          <ProFormText
            rules={[{ required: false }]}
            extra='举例 年-月-日 时:分:秒 日期格式：yyyy-MM-dd HH:mm:ss'
            name="arrivalDateFormat"
            label="到账日期格式"
          />
        </Col>
      </Row>

      <Row>

        <Col span={7}>
          <ProFormText
            extra='交易对方举例: [1]'
            name="counterpartIndex"
            width="md"
            label="交易对方"
            rules={validator}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            extra='单号举例: [1]'
            name="flowNoIndex"
            width="md"
            label="单号"
            rules={validator}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            extra='用途说明举例: [1]'
            name="useDescriptionIndex"
            width="md"
            label="用途说明"
            rules={validator}
          />
        </Col>
      </Row>

      <Row>
        <Col span={7}>
          <ProFormText
            extra='是否处理举例: [1]'
            name="hasDealWithIndex"
            width="md"
            label="是否处理"
            rules={validatorfalse}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            extra='备注举例: [1]'
            name="remarkIndex"
            width="md"
            label="备注:"
            rules={validator}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            extra='对方银行举例: [1]'
            name="otherBankIndex"
            width="md"
            label="对方银行"
            rules={validator}
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ flowTemplate }: RootState) => ({
  entity: flowTemplate.entity,
  updateSuccess: flowTemplate.updateSuccess,
  updating: flowTemplate.updating,
  createSuccess: flowTemplate.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelEdit);
