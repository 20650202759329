import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form} from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./secondSettled.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { SecondSettled } from "model/dealer/secondSettled.model";
export interface SecondSettledEditProps extends EditStateAndDispatchProps<SecondSettled>, StateProps, DispatchProps, RouteComponentProps {
}

const SecondSettledEdit: FC<SecondSettledEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<SecondSettled>
      title="级别"
      form={form}
      {...props}
    >
    </EditPage>);
};

const mapStateToProps = ({ secondSettled }: RootState) => ({
  entity: secondSettled.entity,
  updateSuccess: secondSettled.updateSuccess,
  updating: secondSettled.updating,
  createSuccess: secondSettled.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SecondSettledEdit);
