import {BaseModel} from "../base.model";
export interface Classification extends BaseModel {
    createUser?: string;
    createDept?: string;
    createTime?: string;
    updateUser?: string;
    updateTime?: string;
    status?: number;
    isDeleted?: number;
    storeId?: number;
    name?: string;
    parentId?: string;
    level?: number;
    picture?: string | any;
    sort?: number;
    childClassification?: Classification[];
    children?: Classification[];
    storeName?: string;
    classifyNameVOS?: any;
}

export const defaultClassification: Readonly<Classification> = {};