import { useState } from "react"
import { EditableProTable } from "@ant-design/pro-table";

export interface permissionsProps {
    onChange?: (value: any) => void;
    value?: any;
    editableColumns?: any;
}

const EditPermissions = (props: permissionsProps) => {

    const { onChange, value, editableColumns } = props;
    const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);

    return <EditableProTable<any>
        rowKey="id"
        maxLength={100}
        recordCreatorProps = {false}
        headerTitle="尾数处理"
        className="editable-proTable-table"
        columns={[...editableColumns]}
        value={value}
        editable={{
            type: 'multiple',
            editableKeys,
            onChange: setEditableRowKeys,
            onSave: (_, record: any) => {
                return new Promise<void>((resolve) => {
                    resolve(record);
                })
            },
            onValuesChange: (_, recordList) => {
                const data = recordList.map((e) => {
                    !e.id && (e.id = new Date().getTime());
                    (e.id && (String(e.id).length !== String(new Date().getTime()).length)) && delete e.index;
                    return e;
                });
                onChange?.(data);
            },
        }}
    />
};
export default EditPermissions;