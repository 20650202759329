import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import SpecDetails from './spec-details';
import SpecEdit from './spec-edit';
import SpecList from './spec-list';

const Spec: React.FC<RouteComponentProps> = () => (
  <Router>
    <SpecList path="/" />
    <SpecDetails path=":id" />
    <SpecEdit path=":id/edit" />
    <SpecEdit path="new" />
  </Router>
);
export default Spec;
