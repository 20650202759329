import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './user.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { User } from "model/system/user.model";
import ProCard from "@ant-design/pro-card";
import { ProColumns } from "@ant-design/pro-table";
import { Tag } from "antd";

export interface UserDetailsProps extends DetailsStateAndDispatchProps<User>,
  RouteComponentProps, StateProps, DispatchProps {
}

const basicsColumns: ProColumns<User>[] = [{
  title: '登录账号',
  dataIndex: 'account',
}];

const detailsColumns: ProColumns<User>[] = [{
  title: '用户昵称',
  dataIndex: 'name',
}, {
  title: '用户姓名',
  dataIndex: 'realName',
}, {
  title: '手机号码',
  dataIndex: 'phone',
}, {
  title: '电子邮箱',
  dataIndex: 'email',
}, {
  title: '用户性别',
  dataIndex: 'sex',
}, {
  title: '用户生日',
  dataIndex: 'birthday',
}, {
  title: '创建人',
  dataIndex: 'createUser',
}, {
  title: '创建时间',
  dataIndex: 'createTime',
}, {
  title: '创建部门',
  dataIndex: 'createDept',
}, {
  title: '更新用户',
  dataIndex: 'updateUser',
}, {
  title: '更新时间',
  dataIndex: 'updateTime',
}];

const dutyColumns: ProColumns<User>[] = [{
  title: '用户编号',
  dataIndex: 'code',
}, {
  title: '所属角色',
  dataIndex: 'roleNames',
  render: (dom, recode) => <>{recode.roleNames && (recode.roleNames.map((e: any) => <Tag style={{margin:'2px'}} color="#87d068">{e}</Tag>))}</>
}, {
  title: '所属部门',
  dataIndex: 'depts',
  render: (dom, recode) => <>{ 
    recode.depts && 
    recode.depts.map(item => <Tag color="#108ee9">{item.deptName}</Tag>)}</>
}, {
  title: '所属岗位',
  dataIndex: 'postNames',
  // render: (dom, recode) => <>{recode.postNames && recode.postNames.map(e => <Tag color="#108ee9">{e}</Tag>)}</>
}];

const UserDetails: FC<UserDetailsProps> = (props) => {
  return (
    <DetailsPage<User>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProCard
        title="基础信息" bordered headerBordered
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        <ProDescriptions<User>
          columns={basicsColumns as any}
          dataSource={props.entity}
          column={2}
        />
      </ProCard>
      <ProCard
        title="详细信息" bordered headerBordered
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        <ProDescriptions<User>
          columns={detailsColumns as any}
          dataSource={props.entity}
          column={3}
        />
      </ProCard>
      <ProCard
        title="基础信息" bordered headerBordered
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        <ProDescriptions<User>
          columns={dutyColumns as any}
          dataSource={props.entity}
          column={1}
        />
      </ProCard>

    </DetailsPage>);
};

const mapStateToProps = ({ user }: RootState) => ({
  entity: user.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
