import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import PricingRulesEdit from './marginRules-edit';
import PricingRulesList from './marginRules-list';
import './index.less'

const PricingRules: React.FC<RouteComponentProps> = () => (
  <Router>
    <PricingRulesList path="/" />
    <PricingRulesEdit path=":id/edit" />
    <PricingRulesEdit path="new" />
  </Router>
);
export default PricingRules;
