import axios from 'axios';
import { Base64 } from 'js-base64';

export const fetchCaptcha = (): Promise<any> => {
  return axios.get('/api/tbt-mall-auth/auth/captcha').then(response => response.data);
};

export const fetchWecom = () => {
  let str = window.location.origin;
  // let str = 'https://console-test.merrige.cn';
  return axios.get('/api/yumei-mall-common/wecom/by-host?host=' + str);
};

export const fetchWecomInfo = (data: any) => {
  return axios.post('/api/yumei-mall-auth/oauth2/token', data, {
    headers: {
      'Authorization': `Basic ${Base64.encode(`saber:saber_secret`)}`,
    },
    params: {
      ...data,
    }
  });
};