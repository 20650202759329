import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './menuList.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { MenuList } from "model/system/menuList.model";
import ProTable from "@ant-design/pro-table";

export interface MenuListDetailsProps extends DetailsStateAndDispatchProps<MenuList>,
  RouteComponentProps, StateProps, DispatchProps {
}
const MenuListDetails: FC<MenuListDetailsProps> = (props) => {

  const menuColumns = [{
    title:'id',
    dataIndex:'id',
  },{
    title:'名称',
    dataIndex:'name',
  },{
    title:'编码',
    dataIndex:'code',
  },{
    title:'请求方式',
    dataIndex:'httpMethod',
  },{
    title:'名称',
    dataIndex:'name',
  },{
    title:'请求地址',
    dataIndex:'url',
  }]

  return (
    <DetailsPage<MenuList>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<MenuList>
        columns={props.columns as any}
        dataSource={props.entity}
        column={3}
      />
      <ProTable<any>
        columns={menuColumns}
        rowKey="id"
        dataSource={props.entity?.actions || []}
        options={false}
        search={false}
        pagination={false}
      />
    </DetailsPage>);
};

const mapStateToProps = ({ menuList }: RootState) => ({
  entity: menuList.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MenuListDetails);
