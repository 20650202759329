import { BaseModel } from '../base.model';

export interface Seller extends BaseModel {
    additionalInfo?: any;
    memberAccount?: string;
    storeLogo?: any;
    name?: string;
    settleCycle?: number;
    tenantId?: string;
    validPeriodEnd?: string;
    validPeriodStart?: string;
    contractInfos?: ContractInfos[];
    company?: Company;
    customerId?: string | number;
    installment?: boolean;
    selfSeller?: boolean;
    status?: string;
    wholesalePromotion?: boolean;
    authorizedBrands?: any
    self?: boolean
}

export interface ContractInfos extends BaseModel {
    address?: string; 	 //合同联系人地址
    bizId?: string; 	 //业务id
    contractId?: string; //合同id
    endTime?: string;
    expireTime?: string;
    offline?: boolean; 	 //线上/线下	
    remark?: string; 	 //契约锁返回备注	
    signStatus?: string;
    storeId?: number | string; 	//店铺id
    subject?: string;           //合同主题	
    type?: ContractInfosType; 	//合约类型	
}

interface ContractInfosType extends BaseModel {
    categoryId?: string; 	//契约锁业务分类id	
    categoryName?: string;  //契约锁业务分类名称
    company?: Company; 	    //企业信息		
    description?: string;   //协议描述	
    title?: string;	        //合约名称
}

interface Company extends BaseModel {
    availableChange?: boolean;
    applicantName?: string;         //申请人姓名
    applicantPhone?: string;        //申请人号码
    basicReason?: string;            //	基本信息不通过原因
    basicStatus?: string;            //基础信息审核状态
    certificationStatus?: string;   //	认证状态
    companyName?: string;           //公司名称
    contactAddress?: string;       //联系人地址
    legalPerson?: string;          //法人姓名
    licenceCosFilePath?: string;   //营业执照COS存储地址
    operAuthorizationReason?: string;	//盖章授权审核失败原因
    registerNo?: string; //工商注册号
}

// 店铺类型列表
export const storeTypeMapList: any = [
    {
        label: '实体店',
        value: 'PhysicalStores'
    }, {
        label: '优秀实体店',
        value: 'ExcellentPhysicalStore'
    }, {
        label: '生活馆',
        value: 'LifePavilion'
    }, {
        label: '积分商城店铺',
        value: 'PointMallStore'
    }
];

export const platform = {
    'tingmimi': '玉美',
    'newtingtaiwan': '台湾',
    'ttwdiscount': '台湾折扣',
    'oceane': '欧希纳',
    'tingo2o': 'o2o',
    'tingsingapore': '新加坡',
    'tinggold': '庭豆商城',
    'tbtapp': '美人计',
    'yhby': '一呼百应',
    'newtingmalaysia': '马来西亚',
    'oceane-mall': '欧希纳会员奢雅会',
    'wholesale': '批发商城',
    'mlprefecture': '阿拉专区',
};


export const defaultSeller: Readonly<Seller> = {};