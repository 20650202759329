import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './pay.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Pay } from "model/system/pay.model";
import Title from "antd/lib/typography/Title";

export interface PayDetailsProps extends DetailsStateAndDispatchProps<Pay>,
  RouteComponentProps, StateProps, DispatchProps {
}

const aliConfigColumns = [{
  title: '支付宝公钥',
  dataIndex: 'aliPayPublicKey',
  width: '100px',
  copyable: true,
  ellipsis: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '支付宝应用id',
  dataIndex: 'appId',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: 'pId',
  dataIndex: 'pId',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '开发环境',
  dataIndex: 'dev',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => dom ? '启用' : '禁用'
}, {
  title: '通知地址前缀',
  dataIndex: 'notifyUrlPrefix',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '支付编码',
  dataIndex: 'payCode',
  ellipsis: true,
  copyable: true
}, {
  title: '应用私钥',
  dataIndex: 'privateKey',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '应用公钥',
  dataIndex: 'publicKey',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '签名算法',
  dataIndex: 'signType',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}];

const wechatConfigColumns = [{
  title: '应用ID',
  dataIndex: 'appId',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '应用密钥',
  dataIndex: 'appSecret',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '开发环境',
  dataIndex: 'dev',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => dom ? '启用' : '禁用'
}, {
  title: '商户证书路径',
  dataIndex: 'keyPath',
  ellipsis: true,
  copyable: true,
}, {
  title: '商户ID',
  dataIndex: 'mchId',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '商户密钥',
  dataIndex: 'mchKey',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '通知地址前缀',
  dataIndex: 'notifyUrlPrefix',
  ellipsis: true,
  copyable: true,
  render: (dom: any) => <div className="text-ellipsis">{dom}</div>
}, {
  title: '支付编码',
  dataIndex: 'payCode',
  ellipsis: true,
  copyable: true,
}];

const PayDetails: FC<PayDetailsProps> = (props) => {

  return (
    <DetailsPage<Pay>
      title={'支付方式'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Pay>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      />
      <Title level={5}>Config配置</Title>
      {/*AliPayConfig 支付宝
      // WechatPayConfig 微信支付
      // UnionPayConfig 银联支付（暂无所谓） */}
      {props.entity.config && props.entity.config.payCode === 'AliPayConfig' && <ProDescriptions<Pay>
        columns={aliConfigColumns}
        dataSource={props.entity.config}
        column={2}
      />}
      {props.entity.config && props.entity.config.payCode === 'WechatPayConfig' && <ProDescriptions<Pay>
        columns={wechatConfigColumns}
        dataSource={props.entity.config}
        column={2}
      />}
    </DetailsPage>);
};

const mapStateToProps = ({ pay }: RootState) => ({
  entity: pay.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PayDetails);
