import React, { FC, } from 'react';
import { Form, Slider, Popover } from 'antd';
import { SketchPicker } from 'react-color';
import '../style/auxiliary_blank.less';

export interface Props {
    dataField?: any
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const AuxiliaryBlank: FC<Props> = ({ dataField }) => {
    return (

        <div className="auxiliary-blank-container" >
            <div className="blank-" style={
                {
                    height: `${dataField.height}px `,
                    backgroundColor: `${dataField.bg_color} `,
                }
            }></div>
        </div>
    )
}

export const AuxiliaryBlankSet: FC<SetProps> = ({ dataField, id, methods }) => {

    return <div className="auxiliary-blank-setting">
        <div className="set-form">
            <Form
                initialValues={
                    dataField
                }
                onValuesChange={
                    (changeItem, allProps) => {
                        methods.updateModuleData({
                            ...dataField,
                            ...allProps
                        }, id)
                    }
                }
            >
                <Form.Item label="高度" name="height">
                    <Slider min={1} max={100} />
                </Form.Item>

                <Form.Item label="背景色" >
                    {/* <Slider max={10} /> */}
                    <Popover content={
                        <SketchPicker
                            color={dataField.bg_color}
                            onChange={
                                (e: any) => {
                                    methods.updateModuleData({
                                        ...dataField,
                                        bg_color: e.hex
                                    }, id)
                                }
                            }
                        />
                    } title="" trigger="click">
                        <p className="set-color-hover" style={
                            {
                                backgroundColor: dataField.bg_color
                            }
                        }></p>
                    </Popover>

                </Form.Item>

            </Form>
        </div>
    </div>
}

export default AuxiliaryBlank