import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { SummaryList, defaultSummaryList } from 'model/bonus/summaryList.model';

const entity = 'Summary';
const apiUrl = '/api/yumei-mall-finance/customer/finance/reward/query';
const standardReducerImpl = new StandardReducerImpl<SummaryList>(entity, defaultSummaryList, apiUrl);
export type SummaryListState = StandardState<SummaryList>;
export const summaryList = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;