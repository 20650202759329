import { ProFormGroup, ProFormSelect } from "@ant-design/pro-form";
import { Select } from "antd";
import { strParseJson } from "components/IntelFormText";
import { useParamGeneral } from "components/useParamGenera";
import { useEffect, useState } from "react";
import { FilterCriteria } from "./model/share.model";
const { Option } = Select;

export interface SelectSearchProps {
  onChange?: (value: any) => void;
  value?: any;
  parentValve?: string | boolean | any;
  defaultParams: string;
  togetherParams?: any;
  searchParams: string;
  fetchFunc: (params: any) => any;
  label?: string;
  labelValue?: string;
  mode?: 'multiple' | 'tags';
  disabled?: boolean;
  width?: number | "sm" | "md" | "xl" | "xs" | "lg";
  placeholder?: string;
  optionValue?: string;
  name?: string | string[];
  required?: boolean;
  international?: boolean;
  internationalChange?: any;
  optionKey?: string;
  fetchOptionKey?: any;
  fetchOptionObj?: any;
  bindEvent?: any;
  extra?: string;
  valueType?: string | 'number' | 'string';
  initialValue?: any;
  labelValueObj?: any;
}
const SelectSearch = (props: SelectSearchProps) => {
  const {
    value,
    onChange,
    labelValueObj,
    defaultParams,
    searchParams,
    fetchFunc,
    label,
    labelValue,
    parentValve,
    togetherParams,
    mode,
    disabled,
    width,
    placeholder,
    optionValue,
    name,
    required = true,
    international = false,
    internationalChange,
    fetchOptionKey,
    fetchOptionObj,
    optionKey,
    bindEvent,
    extra,
    valueType,
    initialValue,
  } = props;
  const [optionList, setOptionList] = useState<any>([]);
  const [interKey, setInterKey] = useState("zh-CN");
  const [requestData, setRequestData] = useState([]);
  const { intlArr, generalLanguage, defaultLanguage } = useParamGeneral();

  useEffect(() => {
    value ? (parentValve ? fetchData({ [defaultParams]: parentValve }) : fetchData({ [defaultParams]: value })) : fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    setInterKey(defaultLanguage);
  }, [defaultLanguage])

  const getOptionValue = (res: any) => {
    return res.concat().map((item: any, index: number) => {
      const internationalVal = (labelValue && (strParseJson(item[labelValue]) ? JSON.parse(item[labelValue])[interKey] : item[labelValue])) || '';
      console.log(internationalVal)
      // const newLabelValue = (labelValue && (international ? internationalVal : item[labelValue])) || '';
      const labelValueObjText = labelValueObj ? labelValueObj(item) : ''
      const newOption: any = {
        value: optionValue ? (valueType === 'number' ? Number(item[optionValue]) : String(item[optionValue])) : (valueType === 'number' ? Number(item?.id) : String(item?.id)),
        // label: labelValueObj ? labelValueObjText : (labelValue ? newLabelValue : item?.name),
        label: labelValueObj ? labelValueObjText : (labelValue ? internationalVal : item?.name),
        key: optionValue ? (valueType === 'number' ? Number(item[optionValue]) : String(item[optionValue])) : (valueType === 'number' ? Number(item?.id) : String(item?.id)),
        ...item,
      };
      optionKey && optionKey !== 'label' && (newOption[optionKey] = item[optionKey]);
      return newOption;
    });
  }

  const fetchData = async (params?: any, searchType?: string) => {
    const filterObj = { ...params, ...togetherParams };
    const newFilterObj: FilterCriteria = {};
    const objArr = Object.keys(filterObj);
    objArr.forEach((key, index) => {
      const val = filterObj[key];
      if (val !== '' && key !== 'searchText') {
        newFilterObj[key] = val;
      };
      if (index === objArr.length - 1) {
        fetchFunc(newFilterObj).then((newRequestData: any) => {
          setRequestData(newRequestData);
          setOptionList(getOptionValue(newRequestData));
        })

      }
    });

  };

  useEffect(() => {
    setOptionList(getOptionValue(requestData));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interKey]);

  let timeout: any = null;
  const handleSearch = (e: any, searchType?: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    };
    const fake = async () => {
      fetchData({ [searchParams]: e || '' }, searchType)
    };
    timeout = setTimeout(fake, 500);
  };

  return <ProFormGroup size={0}>
    <ProFormSelect
      options={optionList}
      rules={[{ required: required }]}
      fieldProps={{
        onSearch: (e) => e && handleSearch(e),
        onChange: (e: any, option: any) => {
          onChange?.(e || (mode === 'multiple' ? [] : undefined));
          option && optionKey && fetchOptionKey && fetchOptionKey(option[optionKey]);
          option && fetchOptionObj && fetchOptionObj(option)
          bindEvent && (!e ? bindEvent('empty') : bindEvent(e));
        },
        onFocus: () => handleSearch('', 'focus'),
        onClear: () => {
          onChange?.(undefined);
          bindEvent && bindEvent('empty')
        },
        autoClearSearchValue: true
      }}
      width={width}
      placeholder={'请选择' + (placeholder ? placeholder : label)}
      disabled={disabled}
      label={label}
      name={name}
      showSearch
      extra={extra}
      mode={mode || 'single'}
      initialValue={initialValue}
    />
    {international && intlArr.length > 1 && interKey && <Select style={{ width: '100px' }} className="international-select" defaultValue={interKey} bordered onChange={(e) => {
      setInterKey(e);
      internationalChange && internationalChange(e);
    }}>
      {
        generalLanguage[0] && generalLanguage[0].allowedValues.map((item: any) =>
          intlArr.includes(item.value) && <Option key={item.value} value={item.value}>{item.label}</Option>)
      }
    </Select>}
  </ProFormGroup>
};

export default SelectSearch;