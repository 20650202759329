import { BaseModel } from 'model/base.model';
import { DateFormat } from 'utils/utils';
export interface dataField {
  isUse?: number;
  isDefault?: number;
  bgColor?: string;
  description?: string;
  title?: string;
  type?: number;
  category?: string;
  gmtStart?: string;
  bg_color?: string;
  color?: string;
  font_size?: number;
  content?: string;
  imageList?: any[];
}


export interface Decorate extends BaseModel {
  id?: string;
  isDefault?: number;
  isUse?: number;
  sort?: number;
  storeId?: number;
  title?: string;
  type?: number;
  updateTime?: number;
  pageConfig?: { type: string, dataField: dataField }[]
  pageData?: any
}
export const tureOrFalse = {
  'false': '否',
  'true': '是'
}

//默认可以设置的组件列表
export const defalutModuleList: any = [
  'config',
  'title_text',
  'single_choice_question',
  'multiple_choice_questions'
]

//新的页面类型定义
export const pageTypeMap: any = {
  '1': {
    title: '考试问卷',
    modules: [
      ...defalutModuleList
    ],
  },
  '2': {
    title: '调研问卷',
    modules: [
      ...defalutModuleList
    ]
  }
}


//平台下可配置的页面类型
export const pageTypeForPlatform: any = {
  'taobuting': {
    name: '美人计',
    list: [
      '1',
      '2',
      '999',
      '21',
    ]
  },
  'tingmimi': {
    name: '玉美',
    list: [
      '1001',
      '1002',
      '1003',
      '1004',
      '1005',
      '1011',
      '1012',
      '1013',
      '1014',
      '999',
      '1015',
      '1016',
      '1017'
    ]
  },
  'tingmalaysia': {
    name: '庭马来',
    list: [
      '1001',
      '1002',
      '1003',
      '1011',
      '1012',
      '999',
    ]
  },
  'tingtaiwan': {
    name: '庭台湾',
    list: [
      '1001',
      '1002',
      '1003',
      '1011',
      '1012',
      '999',
    ]
  },
  'tingsingapore': {
    name: '庭新加坡',
    list: [
      '1001',
      '1002',
      '1003',
      '1011',
      '1012',
      '999',
    ]
  },
  'oceane-mall': {
    name: '欧希纳会员奢雅会',
    list: [
      '1',
      '2',
      '999',
      '21',
    ]
  },
}
export const TenantMap: any = {
  'tbt': 'taobuting',
  'tst': 'tingmimi',
  'tmm': 'tingmimi',
  'tml': 'tingmalaysia',
  'ttw': 'tingtaiwan',
  'tsg': 'tingsingapore',
  'oxn': 'oceane-mall',
}

type languageProps = {
  language: 'zh-CN' | 'zh-TW' | 'en';
  name: String;
}
//默认语言
export const defaultLanguageMap: { [Key: string]: (languageProps)[] } = {

  'taobuting': [
    {
      'language': 'zh-CN',
      'name': '简体中文',
    }
  ],
  'tingmimi': [
    {
      'language': 'zh-CN',
      'name': '简体中文',
    }
  ],
  'tingmalaysia': [
    {
      'language': 'en',
      'name': 'English',
    },
    {
      'language': 'zh-CN',
      'name': '简体中文',
    }
  ],
  'tingtaiwan': [
    {
      'language': 'zh-TW',
      'name': '繁体中文',
    }
  ],
  'tingsingapore': [
    {
      'language': 'en',
      'name': 'English',
    },
    {
      'language': 'zh-CN',
      'name': '简体中文',
    }
  ],
}
//默认平台
export const defaultPlatform = 'tingmalaysia'


export const pageTypeMapEnum: any = () => {
  const pageTypeMapEnum: any = {};
  for (let k in pageTypeMap) {
    pageTypeMapEnum[k] = pageTypeMap[k].title
  }
  return pageTypeMapEnum
}

export const typeMapEnumMin: any = {
  11: '玉美首页',
  1: '美人计首页',
  999: '自定义页',
  12: '主会场',
  2: '会员中心',
  21: '开屏广告',
  '1001': 'APP首页',
  '1002': '商城首页',
  '1003': '欧希纳首页',
  '1004': '主会场',
  '1011': '个人中心',
  '1012': '开屏广告',
  '1013': '庭豆首页',
  '1015': '天音卡商城',
  // 0: '页内商品组',
  // 3: '分销中心',
  // 4: '商品详情页',
  // 5: '积分商城首页',
  // 6: '公告',
  // 10: '购物车',
}

// 所有可用的组件
export const modulesList = [
  {
    type: 'config', // 组件名称标识
    classify: 'basis',//组件分类 basis基本组件、market营销组件
    title: '配置',
    isHide: true, // 是否隐藏选择
    isHeader: true, // 是否为头部
    defaultConfig: {
      bgColor: '#f9f9f9',
      title: '美人计',
      style: 'light',//dark 页面风格
      startDate: '',
      endDate: ''
    }
  },
  {
    type: 'title_text',
    classify: 'basis',
    title: '标题文本',
    defaultConfig: {
      "bgColor": "#f9f9f9",
      "title": "",
      "titleColor": '#000',
      "fontSize": 16,
      "textAlign": 'left'
    }
  },
  {
    type: 'single_choice_question',
    classify: 'basis',
    title: '单选题',
    defaultConfig: {
      "bgColor": "#f9f9f9",
      "titleColor": { "a": 1, "r": 226, "b": 26, "g": 26 },
      "title": "",
      "questionScore":"",
      "answer": "",
      "testquestions": [''],
      "analysis": ""
    }
  },
  {
    type: 'multiple_choice_questions',
    classify: 'basis',
    title: '多选题',
    defaultConfig: {
      "bgColor": "#f9f9f9",
      "titleColor": { "a": 1, "r": 226, "b": 26, "g": 26 },
      "title": "",
      "questionScore":"",
      "answer": "",
      "testquestions": [''],
      "analysis": ""
    }
  }
]

export const deepCloneObject = (obj: { [key: string]: any }) => {
  let _return: any = {}
  for (let k in obj) {
    if (obj[k] instanceof Array) {
      _return[k] = obj[k].map((v: any) => {
        if (v instanceof Object) {
          return deepCloneObject(v)
        } else {
          return v
        }
      })
    } else if (obj[k] instanceof Object) {
      _return[k] = deepCloneObject(obj[k])
    } else {
      _return[k] = obj[k]
    }
  }
  return _return
}

//生产模块ID
export const createModuleId = () => {
  return DateFormat(new Date(), 'yyyyMMddhhmmss')

}

export const goodsStyleList = [
  {
    "label": "两列卡片",
    "value": "card"
  },
  {
    "label": "横向滚动",
    "value": "slide-x"
  },
  {
    "label": "大图",
    "value": "big-img"
  },
  {
    "label": "列表",
    "value": "list"
  },
]

export const collageList = [
  {
    "label": "拼团列表",
    "value": "collageList"
  }
]

export const goodsStyleListSimple = [
  {
    "label": "大图",
    "value": "big-img"
  },
  {
    "label": "列表",
    "value": "list"
  },
]
export const defaultDecorate: Readonly<Decorate> = {
};


