import axios from 'axios';
import { PhysicalStores, defaultPhysicalStores } from 'model/dealerStore/physicalStores.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'physicalStores';
const apiUrl = '/api/yumei-mall-store/physical-store-apply';
const standardReducerImpl = new StandardReducerImpl<PhysicalStores>(entity, defaultPhysicalStores, apiUrl);
export type PhysicalStoresState = StandardState<PhysicalStores>;
export const physicalStores = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


// 审核
export const auditRefuse = async (entity: any) => {
  const requestUrl = `${apiUrl}/${entity.id}/audit`;
  const axiosResponse = await axios.post<any>(requestUrl, entity);
  return axiosResponse;
}

// 店铺类型
export const storeTypeMap: any = {
  PhysicalStores: '实体店',
  ExcellentPhysicalStore: "优秀实体店",
  LifePavilion: "生活馆"
}

// 店铺类型列表
export const storeTypeMapList: any = [
  {
    label: '实体店',
    value: 'PhysicalStores'
  }, {
    label: '优秀实体店',
    value: 'ExcellentPhysicalStore'
  }, {
    label: '生活馆',
    value: 'LifePavilion'
  }, {
    label: '积分商城店铺',
    value: 'PointMallStore'
  }]


