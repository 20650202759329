import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, Tooltip, message } from 'antd';
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity } from "./logisticscode.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { Fund } from "model/financial/fund.model";
import { ProFormDateTimePicker, ProFormText } from "@ant-design/pro-form";
import ChangeOwnership from './changeOwnership'

export interface FundListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const FundList: FC<FundListProps> = (props) => {
  let [key, setkey] = useState(0)

  const columns: ProColumns<any>[] = [{
    title: '扫描物流码',
    dataIndex: 'barcode'
  },{
    title: '物流码',
    dataIndex: 'scode'
  },
  // {
  //   title: '大码',
  //   dataIndex: 'fromTypeName'
  // },
  {
    title: '归属授权号',
    dataIndex: 'belongToCode'
  },
  {
    title: '归属姓名',
    dataIndex: 'belongToName'
  },
  {
    title: '变更人授权号',
    dataIndex: 'changeCode',
  },
  {
    title: '变更人姓名',
    dataIndex: 'changeName'
  },
  {
    title: '变更时间',
    dataIndex: 'changeTime'
  }];

  const [openAccount, setOpenAccount] = useState(false);

  // 新增物流码变更
  const ChangeOwnershipButtonRender = [
    <Tooltip title="新增物流码变更">
      <Button type='primary' onClick={() => {
        setOpenAccount(true)
      }}>新增物流码变更</Button>
    </Tooltip>
  ]


  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormText
          width={150}
          name="barcode.contains"
          placeholder="扫描物流码"
        />
        {/* <div className="wd10"></div>
        <ProFormText
          name="payeeCode.equals"
          placeholder="商品名称"
        /> */}
         <div className="wd10"></div>
        <ProFormText
          width={150}
          name="belongToCode.contains"
          placeholder="归属授权码"
        />
         <div className="wd10"></div>
        <ProFormText
          name="belongToName.contains"
          placeholder="归属姓名"
        />
         <div className="wd10"></div>
        <ProFormText
          name="changeCode.contains"
          placeholder="变更人授权号"
        />
         <div className="wd10"></div>
        <ProFormText
          name="changeName.contains"
          placeholder="变更人姓名"
        />
         <div className="wd10"></div>
         <ProFormDateTimePicker
          name="changeTime.greaterThanOrEqual"
          placeholder="变更开始时间"
        />
        <ProFormDateTimePicker
          name="changeTime.lessThanOrEqual"
          placeholder="变更结束时间"
        />
      </Input.Group>
    </Form.Item>
  );

   //导出
   const exportData = (resData?: any) => {
    const exportOrderData = resData ? resData : props.entities;
    return exportOrderData;
  }

  return (<>
    <ListViewPage<Fund>
      columns={columns}
      sort='changeTime,desc'
      actionColumnWidth={140}
      key={key}
      actions={ChangeOwnershipButtonRender}
      showDownload
      downloadEntities={props.entities}
      downloadAllEntities={exportData}
      hideDeleteButton
      hideEditButton
      hideActionColumn
      hideCreateButton
      quickFilterPanel={quickFilterPanel}
      detailsIndex={false}
      {...props}
    >
      {props.children}
    </ListViewPage>

    {/* 变更成功 */}
    <ChangeOwnership visible={openAccount} onCancel={() => {
      setOpenAccount(false)
    }} onRecharge={() => {
      setOpenAccount(false)
      setkey(++key);
      message.success('变更成功')
    }} />
  </>
  );
};

const mapStateToProps = ({ logisticscode }: RootState) => ({
  entity: logisticscode.entity,
  entities: logisticscode.entities,
  loading: logisticscode.loading,
  updateSuccess: logisticscode.updateSuccess,
  createSuccess: logisticscode.createSuccess,
  total: logisticscode.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FundList);