import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import RouteDetails from "./route-details";
import { deleteEntity, getEntities, getEntity } from "./route.reducer";
import { RouteComponentProps } from "@reach/router";
import { Form, Input } from "antd";
import SearchBox from "components/search-box";
import RouteEdit from "./route-edit";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { Route } from "model/system/route.model";


export interface RouteListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
export const columns: ProColumns<Route, 'keyDataType'>[] = [{
  title: 'id',
  dataIndex: 'id',
  valueType: 'text',
}, {
  title: '键名',
  dataIndex: 'keyName',
  valueType: 'text',
}, {
  title: '键类型',
  dataIndex: 'keyType',
  valueType: 'keyDataType',
}, {
  title: '是否原生页面',
  dataIndex: 'nativePage',
  valueType: 'text',
  render: (text, render) => render.nativePage ? '是' : '否'
}, {
  title: '页面地址',
  dataIndex: 'pageUrl',
  valueType: 'text',
}]

const RouteList: FC<RouteListProps> = (props) => {
  const searchFields = [{
    label: '键名',
    name: 'keyName.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage<Route, 'keyDataType'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<RouteDetails navigate={props.navigate} columns={columns} />}
      editPage={(params: any) => <RouteEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ route }: RootState) => ({
  entity: route.entity,
  entities: route.entities,
  loading: route.loading,
  updateSuccess: route.updateSuccess,
  createSuccess: route.createSuccess,
  total: route.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RouteList);