import { BaseModel } from "../base.model"; 
import { Resources } from "./resources.model";
import { Role } from "./role.model";

export interface Permission extends BaseModel {
    delete?: boolean;
    write?: boolean;
    read?: boolean;
    restfulUrl?: Resources;
    role?: Role;
}

export const defaultPermission: Readonly<Permission> = {};