import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./bonus.reducer";
import { BonusList } from "model/bonus/bonusList.model";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
export interface BonusListEditProps extends EditStateAndDispatchProps<BonusList>, StateProps, DispatchProps, RouteComponentProps {
}

const BonusListEdit: FC<BonusListEditProps> = props => {

  return (
    <EditPage<BonusList>
      title="品牌"
      {...props}
    >

    </EditPage>);
};

const mapStateToProps = ({ bonusList }: RootState) => ({
  entity: bonusList.entity,
  updateSuccess: bonusList.updateSuccess,
  updating: bonusList.updating,
  createSuccess: bonusList.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BonusListEdit);
