import React, { FC, ReactNode, useState } from 'react'
import { connect } from 'react-redux';
import { getEntities, getEntity, deleteEntity } from './after-sales.reducer';
import { RootState } from 'reducers';
import { RouteComponentProps } from '@reach/router';
import ListViewPage from 'layouts/page-wraper/list-view-page';
import { ProColumns } from '@ant-design/pro-table';
import { Drawer, Form, Input } from 'antd';
import SearchBox from 'components/search-box';
import { ProFormDateTimePicker, ProFormSelect } from '@ant-design/pro-form';
import { useEnumValue } from 'common/enum/use-enum-value';
import AfterSalesDetails from './after-sales-details';
import { internationalList } from 'components/IntelFormText';
import { AfterSales, expressState } from 'model/procure/after-sales.model';
import SelectSearch from 'components/select-search';
import { fetchStoreList } from 'pages/dealer/unit/unit.reducer';

export interface AfterSalesListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const AfterSalesList: FC<AfterSalesListProps> = (props) => {
  const { entities, ...rest } = props;
  const { getEnumValueDesc, convertEnumToOptionType, getEnumValueList } = useEnumValue();
  const [detailShow, SetDetailShow] = useState(false);
  const [detailId, SetDetailId] = useState('');
  const [whetherRequest, setWhetherRequest] = useState(true);
  const [detailText, SetDetailText] = useState('');

  const downloadColumns = [{
    title: '商品名称',
    dataIndex: 'productName',
  }, {
    title: '售后编号',
    dataIndex: 'serviceNo',
  }, {
    title: '主订单编号',
    dataIndex: 'masterOrderNo',
  }, {
    title: '订单编号',
    dataIndex: 'orderNo',
  }, {
    title: '订单ID',
    dataIndex: 'id',
  }, {
    title: '店铺',
    dataIndex: 'sellerName',
  }, {
    title: '单价(元)/数量',
    dataIndex: 'price',
  }, {
    title: '退款金额(元)',
    dataIndex: 'refundAmount',
  }, {
    title: '退积分',
    dataIndex: 'refundPoint',
  }, {
    title: '申请时间',
    dataIndex: 'initiationTime',
  }, {
    title: '超出时间',
    dataIndex: 'expireTime',
  }, {
    title: '发货状态',
    dataIndex: 'expressState',
  }, {
    title: '退款方式',
    dataIndex: 'afterSaleType',
  }, {
    title: '售后状态',
    dataIndex: 'status',
  }, {
    title: '线下退款',
    dataIndex: 'offLineRefund',
  }, {
    title: '归属人code',
    dataIndex: 'belongTstCode',
  }, {
    title: '支付编号',
    dataIndex: 'payNo',
  }, {
    title: '下单时间',
    dataIndex: 'bookTime',
  }, {
    title: '支付时间',
    dataIndex: 'payTime',
  }, {
    title: '发货时间',
    dataIndex: 'deliveryTime',
  }, {
    title: '交易完成时间',
    dataIndex: 'receiveTime',
  }, {
    title: '退款原因',
    dataIndex: 'refundReasonDesc',
  }, {
    title: '退款说明',
    dataIndex: 'refundExplain',
  }];

  const exportData = (data: AfterSales[]) => {
    let entities: AfterSales[] = [];
    data.forEach(e => {
      let arr = {
        productName: e?.orderLine?.productName,
        id: e?.order?.id,
        serviceNo: e?.serviceNo,
        sellerName: e?.order?.sellerName,
        price: e?.orderLine?.price + '/' + e?.orderLine?.quantity,
        refundAmount: e?.refundAmount,
        initiationTime: e?.initiationTime,
        expireTime: e?.expireTime,
        expressState: e?.expressState === 0 ? '未发货' : '已发货',
        afterSaleType: getEnumValueDesc('afterSaleType', String(e?.afterSaleType) || ''),
        status: getEnumValueDesc('afterSaleOrderStatus', String(e.status) || ''),
        offLineRefund: e?.offLineRefund ? '是' : '否',
        bookTime: e?.order?.bookTime,
        refundReasonDesc: e?.refundReasonDesc,
        payNo: e?.order?.payInfo?.payNo,
        belongTstCode: e?.order?.belongTstCode,
        masterOrderNo: e?.order?.masterOrderNo,
        orderNo: e?.order?.orderNo,
        payTime: e?.order?.payTime,
        deliveryTime: e?.order?.deliveryTime,
        receiveTime: e?.order?.receiveTime,
        refundExplain: e?.refundExplain,
      };
      entities.push(arr);
    });
    return entities;
  }

  //列表头部
  const columns: ProColumns<AfterSales, 'afterSaleOrderStatus' | 'afterSaleType'>[] = [{
    title: '售后商品',
    dataIndex: 'orderId',
    render: (text, row: AfterSales, index) => {
      return <div className='query-order-name'>
        <img alt='商品图片' style={{ width: '80px', height: 'auto', marginRight: '18px' }} src={internationalList(row?.orderLine?.picture || '')} />
        <div>
          <p style={{ cursor: 'pointer' }}>商品名称：<span style={{ color: '#1890ff' }} onClick={() => { SetDetailShow(true); SetDetailText(new Date() + ''); row.id && SetDetailId(String(row.id)); }}>{internationalList(row?.orderLine?.productName || '')}</span></p>
          <p>售后编号：{row.serviceNo}</p>
          <p>订单ID：{row?.order?.id}</p>
        </div>
      </div>
    }
  }, {
    title: '店铺',
    dataIndex: 'sellerName',
    render: (text, row, index) => {
      return row?.order?.sellerName
    }
  }, {
    title: '售后数量',
    dataIndex: 'quantity',
    valueType: 'text',
  }, {
    title: '售后金额',
    dataIndex: 'refundAmount',
  }, {
    title: '应退运费',
    dataIndex: 'refundFreightAmount',
  }, {
    title: '实退金额',
    dataIndex: 'actualRefundAmount',
  }, {
    title: '退积分',
    dataIndex: 'refundPoint',
  }, {
    title: '申请时间',
    dataIndex: 'initiationTime',
    sorter: true,
  }, {
    title: '超出时间',
    dataIndex: 'expireTime',
  }, {
    title: '发货状态',
    // width: 100,
    dataIndex: 'expressState',
    valueEnum: expressState,
    hideInTable: true,
  }, {
    title: '退款方式',
    // width: 100,
    dataIndex: 'afterSaleType',
    valueType: 'afterSaleType'
  }, {
    title: '售后状态',
    dataIndex: 'status',
    valueType: 'afterSaleOrderStatus',
  }, {
    title: '线下退款',
    dataIndex: 'offLineRefund',
    hideInTable: true,
    render: (text, row: any, index) => {
      return text ? '是' : '否'
    }
  }];

  // 筛选条件
  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormDateTimePicker
          name="orderTime.greaterThanOrEqual"
          placeholder="订单开始时间"
        />
        <ProFormDateTimePicker
          name="orderTime.lessThanOrEqual"
          placeholder="订单结束时间"
        />
        <SearchBox
          width={420}
          fields={[{
            label: '订单ID',
            name: 'orderId.equals',
            dataType: 'number'
          }, {
            label: '售后编号',
            name: 'serviceNo.equals',
          }, {
            label: '商品名称',
            name: 'productName.contains',
          }, {
            label: '收货人',
            name: 'contactName.contains',
          }, {
            label: '收货人手机号',
            name: 'contactMobile.equals',
          }]}
        />
      </Input.Group>
    </Form.Item>
  );

  const advanceFilterPanel = (
    <Form.Item style={{ width: '100%' }}>
      <Input.Group compact style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
        <ProFormSelect
          name='afterSaleType'
          placeholder='售后方式'
          fieldProps={{
            style: { minWidth: '100px', maxWidth: '100px' },
          }}
          options={convertEnumToOptionType('afterSaleType')}
        />
        <ProFormSelect
          name='expressState.equals'
          placeholder='发货状态'
          fieldProps={{
            style: { minWidth: '100px', maxWidth: '100px' },
          }}
          request={async () => {
            return Object.entries(expressState).map((item, index) => {
              return {
                value: item[0],
                label: item[1]
              }
            })
          }}
        />
        <ProFormSelect
          name='serviceState.equals'
          placeholder='售后单状态'
          options={getEnumValueList('afterSaleOrderStatus').map((e: any) => {
            return {
              label: e.desc,
              value: e.value
            }
          })}
        />
        <SelectSearch required={false} name="sellerId.in" mode="multiple" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <SelectSearch 
          required={false}
          name="sellerId.in"
          mode='multiple' 
          width={150}
          placeholder="批发商" 
          labelValue="name" 
          defaultParams='' 
          fetchFunc={fetchStoreList} 
          searchParams='name.contains' 
          togetherParams={{ "wholesale.in": true }}
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage<AfterSales, 'afterSaleOrderStatus' | 'afterSaleType'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      advanceFilterPanel={convertEnumToOptionType('afterSaleType').length > 0 && advanceFilterPanel}
      rowKey='id'
      entities={entities}
      downloadColumns={downloadColumns}
      downloadAllEntities={exportData}
      downloadEntities={exportData(entities)}
      showDownload
      detailsIndex={false}
      hideActionColumn={true}
      hideCreateButton={true}
      showSearchButton
      disableDefaultRequest={whetherRequest}
      changeRequestState={setWhetherRequest}
      drawerWidth={1000}
      sort='initiationTime,desc'
      {...rest}
    >
      <Drawer
        visible={detailShow}
        onClose={() => SetDetailShow(false)}
        width={1000}
      >
        <AfterSalesDetails detailText={detailText} id={detailId} />
      </Drawer>
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ afterSales }: RootState) => ({
  entity: afterSales.entity,
  entities: afterSales.entities,
  loading: afterSales.loading,
  updateSuccess: afterSales.updateSuccess,
  total: afterSales.total,
  createSuccess: afterSales.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AfterSalesList);


