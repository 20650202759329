import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import PermissionDetails from "./permission-details";
import { deleteEntity, getEntities, getEntity } from "./permission.reducer";
import { RouteComponentProps } from "@reach/router";
import { Permission } from "model/system/permission.model";
import { Form, Input } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import PermissionEdit from "./permission-edit";
import ListViewPage from "layouts/page-wraper/list-view-page";


export interface PermissionListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
export const columns: ProColumns<Permission>[] = [{
  title: '权限Id',
  dataIndex: 'id',
  valueType: 'text',
}, {
  title: '角色',
  dataIndex: ['role', 'name'],
  valueType: 'text',
},  {
  title: '资源',
  dataIndex: ['restfulUrl', 'description'],
  valueType: 'text',
}]

const PermissionList: FC<PermissionListProps> = (props) => {
  const searchFields: SearchField[] = [{
    label: '资源名称',
    name: 'restfulUrlName.contains',
  }, {
    label: '资源id',
    name: 'restfulUrlId.equals',
    dataType: 'number'
  }, {
    label: '角色id',
    name: 'roleId.equals',
    dataType: 'number'
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Permission>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<PermissionDetails navigate={props.navigate} columns={columns} />}
      sort="" 
      detailsIndex={false}
      editPage={(params: any) => <PermissionEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ permission }: RootState) => ({
  entity: permission.entity,
  entities: permission.entities,
  loading: permission.loading,
  updateSuccess: permission.updateSuccess,
  createSuccess: permission.createSuccess,
  total: permission.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PermissionList);