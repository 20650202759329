import axios from 'axios';
import { defaultPay, Pay } from 'model/system/pay.model';
import { CrudPutAction } from 'reducers/redux-action.type';
import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';

const entity = 'Pay';
const apiUrl = '/api/yumei-mall-pay/pay/type';
const standardReducerImpl = new StandardReducerImpl<Pay>(entity, defaultPay, apiUrl);
export type PayState = StandardState<Pay>;
export const pay = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

//启用禁用
export const activate: CrudPutAction<any> = entity => async dispatch => {
    const requestUrl = `${apiUrl}/${entity.id}/enabled/${entity.type}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.post(requestUrl),
    });
};