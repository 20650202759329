import axios from 'axios';
import { Achievement, defaultAchievement } from 'model/product/achievement.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
const entity = 'achievement';
const apiUrl = '/api/yumei-mall-pim/achievement/template';
const standardReducerImpl = new StandardReducerImpl<Achievement>(entity, defaultAchievement, apiUrl);
export type AchievementState = StandardState<Achievement>;
export const achievement = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

//商品业绩模版列表
export const fetchAchievementList = async (params?: any) => {
  const axiosResponse = await axios.get<any>(apiUrl, { params: { ...params, size: 20 } });
  return axiosResponse.data;
}