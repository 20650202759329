import { BaseModel } from "../base.model";

interface DefaultCharge {
    fixedPrice?: number;
    firstWeight?: number;
    firstWeightPrice?: number;
    additionalWeight?: number;
    additionalWeightPrice?: number;
}

interface RegionCharges {
    shipChargeTemplate?: any;
    regions?: string[];
    shipCharge?: DefaultCharge;
  }

export interface Delivery extends BaseModel {
    name?: string;
    storeId?: string;
    priceModel?: number;
    defaultCharge?: DefaultCharge;
    regionCharges?: RegionCharges[];
    status?: string;
}

export const PriceModel = {
    1: '固定价格',
    2: '按重量递增'
}

export const defaultDelivery: Readonly<Delivery> = {};