import React, { FC, useState, memo, useEffect, useCallback } from 'react';
import { Image, Upload, Input, Tooltip, Select, Button, message } from 'antd';
import { PlusOutlined, FormOutlined, DeleteOutlined } from '@ant-design/icons';

import '../style/image_area.less';
import holderImg from '../../../../assets/images/public/holder.jpg';
import { uploadImage } from "utils/file-utils";
const { Option } = Select;
export interface Props {
    dataField: {
        nav: any[]
        [key: string]: any
    }
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const ImageArea: FC<Props> = ({ dataField }) => {
    return (
        <div className={`image-area-container clearfix ${dataField.preview ? 'show' : ''}`} >
            <Image src={dataField.img || holderImg} preview={false}></Image>
        </div>
    )
}

const AreaItem: FC<{ data: any, isAdd?: boolean, index?: number, selectIndex?: number, setSelectIndex?: any, updateModuleDataPrivate?: any }> = ({ data, isAdd, index, selectIndex, setSelectIndex, updateModuleDataPrivate }) => {
    return data && <div className={`item ${selectIndex === index && !isAdd ? 'selected' : ''}`} style={
        {
            width: data.position ? data.position.width + 'px' : 0 + + 'px',
            height: data.position ? data.position.height + 'px' : 0 + + 'px',
            left: data.position ? data.position.left + 'px' : 0 + + 'px',
            top: data.position ? data.position.top + 'px' : 0 + + 'px'
        }
    } onMouseDown={

        (e) => {
            !isAdd && e.stopPropagation()
        }
    } onMouseUp={
        (e) => {
            !isAdd && e.stopPropagation()
            !isAdd && setSelectIndex(index)
        }
    }>
        {!isAdd && <DeleteOutlined onMouseUp={
            (e) => {
                !isAdd && e.stopPropagation()
                !isAdd && updateModuleDataPrivate(data, index, 'delete')
            }
        } className="delete" />}
    </div>
}
export const ImageAreaSet: FC<SetProps> = memo(({ dataField, id, methods, selectedInfo, otherInfo }) => {
    const defaultItem: any = {
        "link": "",
        "openType": "",
        "position": {
            "width": 0,
            "height": 0,
            "left": 0,
            "top": 0
        }
    }
    const [isDown, setIsDown] = useState(false);
    const [selectIndex, setSelectIndex] = useState(0);
    const [showReceivedOption, setShowReceivedOption] = useState(false);//是否显示领取后图片选择
    const [imgKey, setImgKey] = useState('img');//是否显示领取后图片选择
    const [currentItem, setCurrentItem] = useState({
        startClientX: 0,
        startClientY: 0,
        position: {
            width: 0,
            height: 0,
            left: 0,
            top: 0
        }
    });
    const onMouseDown = function (e: any) {
        setCurrentItem({
            startClientX: e.clientX,
            startClientY: e.clientY,
            position: {
                ...currentItem.position,
                top: e.nativeEvent.offsetY,
                left: e.nativeEvent.offsetX,
            }
        })
        setIsDown(true)
    }
    const onMouseMove = function (e: any) {
        if (isDown) {
            // console.log('onMouseMove',e)
            setCurrentItem({
                ...currentItem,
                position: {
                    ...currentItem.position,
                    width: e.clientX - currentItem.startClientX,
                    height: e.clientY - currentItem.startClientY,
                }
            })
        }
    }
    const onMouseUp = function (e: any) {
        setIsDown(false)
        if (currentItem.position.width > 10) {
            let newItem = { ...defaultItem }, newList = [...dataField.list]
            newItem.position = currentItem.position
            newList.push(newItem)

            methods.updateModuleData({
                ...dataField,
                list: newList
            }, id)
        }


        setCurrentItem({
            startClientX: 0,
            startClientY: 0,
            position: {
                width: 0,
                height: 0,
                left: 0,
                top: 0
            }
        })
    }
    // all  nologin 未登录用户 logined 所有登录用户 blueCard 蓝卡及以上 redCard 红卡
    const uploadProps = {
        multiple: false,
        showUploadList: false,
        customRequest(e: any) {
            let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`
            uploadImage(e.file, 'minipage/' + imageName).then(async res => {
                // updateModuleDataPrivate({
                //     ...data,
                //     'img':res
                // }, index, '')
                methods.updateModuleData({
                    ...dataField,
                    [imgKey]: res
                }, id)
                setImgKey('img')
            }).catch(() => {message.error('图片上传失败')});
        },
    };
    const OpenType: FC<{ defaultValue: string }> = ({ defaultValue }) => {
        return (
            <Select defaultValue={defaultValue} onChange={
                (e) => {
                    dataField.openType = e
                    methods.updateModuleData(dataField, id)
                }
            } className="select-after">
                <Option value="navigateTo">新窗口</Option>
                <Option value="switchTab">TAB切换</Option>
                <Option value="redirectTo">重定向</Option>
                <Option value="back">返回</Option>
            </Select>
        );
    }
    useEffect(
        () => {
            if (!selectedInfo || !selectedInfo.path || (otherInfo && otherInfo.settingModuleId !== id)) return
            methods.updateModuleData({
                ...dataField,
                openType: selectedInfo.openType,
                link: selectedInfo.path,
            }, id)
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedInfo, otherInfo]
    )


    const [dataFieldPrivate, setDataFieldPrivate] = useState(dataField)
    const updateModuleDataPrivate = useCallback(
        (item: any, index: any, type: any) => {//type delete up down
            let newDataFieldPrivate = dataFieldPrivate
            if (!type) {
                newDataFieldPrivate.list[index] = item
            } else if (type === 'delete') {
                delete newDataFieldPrivate.list[index]
                newDataFieldPrivate.list = newDataFieldPrivate.list.filter((v: any) => v)
            }
            // newDataFieldPrivate
            let showReceivedOptionPrivate = false;
            dataFieldPrivate.list.forEach((v: any) => {
                if (v && (v.link.indexOf('RECEIVECOUPON?') !== -1  || v.link.indexOf('TRIGGER_ACTIVITY?') !== -1)) {
                    showReceivedOptionPrivate = true
                }
            })
            setShowReceivedOption(showReceivedOptionPrivate)

            methods.updateModuleData(newDataFieldPrivate, id)
        },
        [dataFieldPrivate, methods, id]
    )
    useEffect(
        () => {
            if (!selectedInfo || !selectedInfo.path) return
            let callItem = { ...dataFieldPrivate.list[selectIndex] }
            callItem.link = selectedInfo.path
            callItem.openType = selectedInfo.openType
            updateModuleDataPrivate(callItem, selectIndex, '')
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedInfo]
    )
    useEffect(() => {
        let showReceivedOptionPrivate = false;
        dataField.list.forEach((v: any) => {
            if (v && (v.link.indexOf('RECEIVECOUPON?') !== -1 || v.link.indexOf('TRIGGER_ACTIVITY?') !== -1)) {
                showReceivedOptionPrivate = true
            }
        })
        setShowReceivedOption(showReceivedOptionPrivate)
        setDataFieldPrivate(dataField)
    }, [dataField])
    return (
        <div className="image-area-set" >
            {
                dataField.list[selectIndex] && <Input.Group compact className="input-item">
                    <Button type="primary" onClick={
                        () => {
                            methods.setShowLinkSelectionPrivate(true)
                        }
                    }>选择</Button>
                    <Input addonAfter={<OpenType defaultValue={dataField
                        .list[selectIndex].openType || 'navigateTo'} />} placeholder="请输入/选择链接地址" onChange={(e) => {

                            updateModuleDataPrivate({
                                ...dataFieldPrivate.list[selectIndex],
                                link: e.target.value
                            }, selectIndex, '')
                        }} value={dataFieldPrivate
                            .list[selectIndex] && dataFieldPrivate
                                .list[selectIndex].link} />

                </Input.Group>
            }
            {
                dataField.img && <div className="img">
                    <Upload {...uploadProps}>
                        <div className="change-img" >
                            <Tooltip title="更换">
                                <FormOutlined />
                            </Tooltip>
                        </div>
                    </Upload>

                    <Image preview={false} src={dataField.img ? dataField.img + '?imageView2/2/w/600' : holderImg} />
                    <div className="hot-area-list" onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp}>
                        {
                            dataField.list?.map((v: any, i: number) => {
                                return <AreaItem data={v} index={i} setSelectIndex={setSelectIndex} updateModuleDataPrivate={updateModuleDataPrivate} selectIndex={selectIndex} />
                            })
                        }
                        {
                            isDown && currentItem && <AreaItem data={currentItem} isAdd={true} />
                        }
                    </div>
                </div>
            }
            {
                !dataField.img && <div className="dragger-con">
                    <Upload {...uploadProps}>

                        <div className="add-area" >
                            <p className="desc">
                                <PlusOutlined className="icon" />
                            </p>
                            <p className="desc">点击选择图片</p>
                        </div>
                    </Upload>
                </div>
            }



            {
                showReceivedOption && dataField.received_img && <div className="img">

                    <Upload {...uploadProps}>
                        <div className="change-img" onClick={() => {
                            setImgKey('received_img')
                        }}>
                            <Tooltip title="更换">
                                <FormOutlined />
                            </Tooltip>
                        </div>
                    </Upload>
                    <Image preview={false} src={dataField.received_img ? dataField.received_img + '?imageView2/2/w/600' : holderImg} />

                </div>
            }
            {/* 领取后图片选择 */}
            {
                showReceivedOption && !dataField.received_img && <div className="dragger-con">
                    <Upload {...uploadProps}>

                        <div className="add-area" onClick={() => {
                            setImgKey('received_img')
                        }}>
                            <p className="desc">
                                <PlusOutlined className="icon" />
                            </p>
                            <p className="desc" >点击选择优惠券领取后的替代图片</p>
                        </div>
                    </Upload>
                </div>
            }
        </div>
    )
})

export default ImageArea