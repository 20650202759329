import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import Advertisement from './advertisement';
import './index.less'
import Coupon from './coupon';

const Market: React.FC<RouteComponentProps> = () => (
  <Router>
    <Advertisement path="advertisement/*" />
    <Coupon path="coupon/*" />
  </Router>
);
export default Market;
