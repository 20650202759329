import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import RegionDetails from './region-details';
import RegionEdit from './region-edit';
import RegionList from './region-list';

const Region: React.FC<RouteComponentProps> = () => (
  <Router>
    <RegionList path="/" />
    <RegionDetails path=":id" />
    <RegionEdit path=":id/edit" />
    <RegionEdit path="new" />
  </Router>
);
export default Region;
