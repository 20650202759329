import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import AppVersion from './app-version';
import Audit from "./audit";

import './index.less';

import Pay from "./pay";
import Tenant from "./tenant";
import ExpressCompany from "./express-company";
import ParamGroup from "./param-group/index"
import PayeeEnterprise from "./payee-enterprise";
import Rate from "./rate";
import UnusualMessage from "./message";
import Route from "./route";
import MenuList from "./menuList";

const System: React.FC<RouteComponentProps> = () => (
  <Router>
    <AppVersion path="app-version/*" />
    <Audit path="audit/*" />
    <PayeeEnterprise path="payee-enterprise/*" />
    <Route path="route/*" />
    <Pay path="pay/*" />
    <Tenant path="tenant/*" />
    <ExpressCompany path="expressCompany/*" />
    <ParamGroup path="param-group/*" />
    <UnusualMessage path="message/*" />
    <Rate path="rate/*"/>
    <MenuList path="menu/*"/>
  </Router>
);
export default System;
