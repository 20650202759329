import {BaseModel, DisableModel} from 'model/base.model';

export interface Group extends BaseModel, DisableModel {
  id?: number;
  enabled?: boolean;
  name?: string;
}

export const defaultGroup: Readonly<Group> = {
};
