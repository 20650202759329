import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ArticleDetails from "./article-details";
import { deleteEntity, getEntities, getEntity } from "./article.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ArticleEdit from "./article-edit";
import { Article,showPlace } from "model/dealer/article.model";
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import SelectSearch from "components/select-search";
import { fetchArticleCategorySelectList } from "../assort/assort.reducer";

export interface ArticleListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Article, 'articleStatus'>[] = [{
  title: 'id',
  dataIndex: 'id',
  width: 100,
}, {
  title: '文章分类',
  dataIndex: ['category', 'name'],
  width: 100,
},{
  title: '显示位置',
  dataIndex: ['showPlace'],
  width: 100,
  valueEnum:showPlace,
},  {
  title: '文章标题',
  dataIndex: 'title',
  width: 100,
  ellipsis: true,
}, {
  title: '排序值',
  dataIndex: 'sort',
  width: 100,
}, {
  title: '创建时间',
  dataIndex: 'createdDate',
  width: 100,
}, {
  title: '发布时间',
  dataIndex: 'publishTime',
  width: 100,
}, {
  title: '文章状态',
  dataIndex: 'status',
  valueType: 'articleStatus',
  width: 100,
},];


const ArticleList: FC<ArticleListProps> = (props) => {
  const { convertEnumToOptionType } = useEnumValue();
  const searchFields = [{
    label: '文章id',
    name: 'id.equals',
  }, {
    label: '文章标题',
    name: 'title.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          name="status.equals"
          options={convertEnumToOptionType('articleStatus')}
          placeholder="文章状态"
        />
        <SelectSearch required={false}  width={'md'} name={['categoryId.equals']} label="文章分类" placeholder="文章分类" labelValue="name" defaultParams={'id.equals'} fetchFunc={fetchArticleCategorySelectList} searchParams='name.contains' />
        <SearchBox
          fields={searchFields}
        />
        <ProFormDateTimePicker
          name="createTime.greaterThanOrEqual"
          placeholder="创建开始时间"
        />
        <ProFormDateTimePicker
          name="createTime.lessThanOrEqual"
          placeholder="创建结束时间"
        />
        <ProFormDateTimePicker
          name="publishTime.greaterThanOrEqual"
          placeholder="发布开始时间"
        />
        <ProFormDateTimePicker
          name="publishTime.lessThanOrEqual"
          placeholder="发布结束时间"
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Article, 'articleStatus'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ArticleDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      sort=''
      editPage={(params: any) => <ArticleEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ article }: RootState) => ({
  entity: article.entity,
  entities: article.entities,
  loading: article.loading,
  updateSuccess: article.updateSuccess,
  createSuccess: article.createSuccess,
  total: article.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ArticleList);