import { ProFormUploadButton } from "@ant-design/pro-form";
import { Tooltip, message } from "antd";
import React, { useCallback, useMemo } from "react";
import { FC, useEffect, useState } from "react";
import { DndProvider, useDrag, useDrop } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import update from 'immutability-helper';
import { Base64 } from "js-base64";
import { uploadImage } from "utils/file-utils";

export function deBounce(func: any, wait: any) {
  let timeOut: any = null;
  return function (...args: any) {
    clearTimeout(timeOut);//一定要清除定时器
    timeOut = setTimeout(() => {
      func(...args);
    }, wait);
  };
}

export interface UploadImgComplexProps {
  value?: string;
  onChange?: (data: any) => void;
  extra?: string;
  title?: string;
  listType: 'text' | 'picture' | 'picture-card';
  base64?: boolean;
  storageDetail?: any;
  className?: string;
  maxCount?: number;
  thumbUrl?: string[];
  fileType?: 'img' | 'video' | 'any';
  Immediate?: boolean; //是否立即上传
}

const UploadImgComplex = (props: UploadImgComplexProps) => {
  const { value, extra, title, listType = 'picture-card', fileType = 'img', Immediate = false, onChange, base64 = false, storageDetail, className = "pro-upload-picture-box", maxCount = 10, thumbUrl } = props;

  const [pictureData, setPictureData] = useState<any>([]);//主图渲染

  //图片拖拽排序
  const type = 'DragAbleUploadList';
  const DragAbleUploadListItem: FC<{ originNode: any, moveRow: any, file: any, fileList: any }> = ({ originNode, moveRow, file, fileList }) => {
    const ref = React.useRef();
    const index = fileList.indexOf(file);
    const [{ isOver, dropClassName }, drop] = useDrop({
      accept: type,
      collect: monitor => {
        const item: any = monitor.getItem() || {};
        if (item.index === index) {
          return {};
        }
        return {
          isOver: monitor.isOver(),
          dropClassName: item.index < index ? ' drop-over-downward' : ' drop-over-upward',
        };
      },
      drop: (item: any) => {
        moveRow(item.index, index);
      },
    });
    const [, drag] = useDrag({
      type,
      item: { index },
      collect: monitor => ({
        isDragging: monitor.isDragging(),
      }),
    });
    drop(drag(ref));
    const errorNode = <Tooltip title="Upload Error">{originNode.props.children}</Tooltip>;
    return (
      <div
        ref={ref as any}
        className={`ant-upload-draggable-list-item ${isOver ? dropClassName : ''}`}
        style={{ cursor: 'move' }}
      >
        {file.status === 'error' ? errorNode : originNode}
      </div>
    );
  };
  const moveRowPictures = useCallback(
    (dragIndex: any, hoverIndex: any) => {
      const dragRow = pictureData[dragIndex];
      const newPictureData = update(pictureData, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragRow],
        ],
      });
      change(newPictureData);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pictureData],
  );


  useEffect(() => {
    setPictureData([]);
  }, []);

  useEffect(() => {
    (value && value.indexOf('holder.jpg') === -1) && base64 ? showBase64(value) : showPicture(value);
    !value && base64 && showBase64(value);
    if (!value) {
      setPictureData([]);
      onChange?.([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const deBonceMessage = useMemo(() => {
    return deBounce(message.warn, 500)
  }, [])


  const handlePicture = (res: any) => {
    let { file } = res;
    var imageExtensions = ['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
    let isimg: any = imageExtensions.includes(file.type)
    if (fileType === 'img' && !isimg && file.type) {
      deBonceMessage('上传格式错误')
      return false
    }
    if (fileType === 'video' && isimg && file.type) {
      deBonceMessage('上传格式错误')
      return false
    }
    if (fileType === 'video' && file.type && file.size > 10 * 1024 * 1024) {
      deBonceMessage('上传视频过大，请压缩后再上传')
      return false
    }
    change(res.fileList)
  };

  const showPicture = (res: any) => {
    if ((res !== '')) {
      if (typeof (res) === 'string') {
        let picturesData: any = res && res.split(',').filter((res: string) => res !== '').map((item: any, index) => {
          return {
            uid: item,
            name: '文件',
            status: 'done',
            url: item,
            thumbUrl: thumbUrl ? thumbUrl[index] : '',
          }
        });
        change(picturesData);
      } else {
        change(res);
      }
    } else {
      change([]);
    }
  }
  let timeout: any = null;
  const change = (res: any) => {
    if (!!Immediate && res && res[res.length - 1]?.originFileObj) {
      const newDate = new Date().getTime();
      // 图片防抖处理
      const inputValueChange = () => {
        if (timeout) {
          clearTimeout(timeout);
          timeout = null;
        }
        const fake = async () => {
          funC();
        };
        timeout = setTimeout(fake, 1000);
      };
      inputValueChange();
      const funC = () => {

        if (res && res.length > 0 && res[res.length - 1]?.originFileObj) {
          const arr = res[res.length - 1].name.split('.');
          let suffix = '.' + arr[arr.length - 1];
          uploadImage(res[res.length - 1].originFileObj, ('product/img-' + newDate + suffix)).then(imageName => {
            message.success('上传成功');
            const pictureArray = {
              name: 'image.png',
              status: 'done',
              url: imageName,
            };
            setPictureData(pictureData.concat([pictureArray]));
            onChange?.(pictureData.concat([pictureArray]));
            storageDetail?.(pictureData.concat([pictureArray]));
          }).catch(() => { message.error('图片上传失败'); });
        };

      };
    } else {
      setPictureData(res);
      onChange?.(res);
      storageDetail?.(res);
    }

  };

  const showBase64 = (res: any) => {
    const detailsLast = [{
      name: 'image.png',
      status: 'done',
      url: 'https://tbt-mall-1304216166.cos.ap-shanghai.myqcloud.com/images/16270106706863.png',
    }];
    if (res !== '') {

      if (typeof (res) === 'string') {
        const isJSON = (str: string) => {
          try {
            JSON.parse(str)  // 如果抛出异常，则会从这条语句终止，被catch捕捉
            return true
          } catch (e) {
            return false
          }
        };
        if (!isJSON(res)) {
          const details: any = Base64.decode(res); // 解码
          let detailsArr = details.match(/<img.*?>/g);
          let imgSrc = (detailsArr && detailsArr.length > 0) ? detailsArr.map((url: any) => {
            return {
              name: 'image.png',
              status: 'done',
              url: url.match(/\ssrc=['"](.*?)['"]/)[1],
            }
          }) : [];
          if (imgSrc.length > 0) {
            change(imgSrc);
          } else {
            change(detailsLast);
          }
        };
      } else {
        res === undefined ? change(detailsLast) : change(res);
      }
    } else {
      change(detailsLast);
    }
  }

  return <DndProvider backend={HTML5Backend}>
    <ProFormUploadButton
      listType={listType}
      extra={extra}
      title={title}
      fileList={pictureData}
      onChange={handlePicture}
      fieldProps={{
        maxCount, className, multiple: true,
        beforeUpload: () => false,
        itemRender: (originNode, file, currFileList) =>
          <DragAbleUploadListItem
            originNode={originNode}
            file={file}
            fileList={currFileList}
            moveRow={moveRowPictures}
          />
      }}
    />
  </DndProvider>
}

export default UploadImgComplex;