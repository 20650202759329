import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import AuthFlowDetails from "./authFlow-details";
import { deleteEntity, getEntities, getEntity } from "./authFlow.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import AuthFlowEdit from "./authFlow-edit";
import { AuthorizedStatus, AuthFlow, AuthFlowOrgIdCardType, AuthLegalRepIdCardType, AuthType, AuthRealNameStatus } from "model/contract/authFlow.model";
import { SealAuthStatus } from "model/contract/sealAuth.model";

export interface AuthFlowListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<AuthFlow>[] = [{
  title: 'id',
  dataIndex: 'id',
  width: 80,
  ellipsis: true,
}, {
  title: '授权码',
  dataIndex: 'account',
  width: 100,
}, {
  title: '机构名称',
  dataIndex: 'orgName',
  width: 100,
}, {
  title: '机构账号ID',
  dataIndex: 'orgId',
  width: 100,
}, {
  title: '机构证件号',
  dataIndex: 'orgIdCardNum',
  width: 100,
}, {
  title: '机构证件类型',
  dataIndex: 'orgIdCardType',
  valueEnum: AuthFlowOrgIdCardType,
  width: 120,
}, {
  title: '机构印章名称',
  dataIndex: 'sealName',
  width: 120,
}, {
  title: '印章ID',
  dataIndex: 'sealId',
  width: 100,
}, {
  title: '认证流程id',
  dataIndex: 'authFlowId',
  width: 100,
}, {
  title: '认证授权主体类型',
  dataIndex: 'authType',
  valueEnum: AuthType,
  width: 120,
}, {
  title: '法人证件类型',
  dataIndex: 'legalRepIdCardType',
  valueEnum: AuthLegalRepIdCardType,
  width: 120,
}, {
  title: '法人证件号',
  dataIndex: 'legalRepIdCardNum',
  width: 100,
}, {
  title: '法人姓名',
  dataIndex: 'legalRepName',
  width: 100,
},  {
  title: '个人账号ID',
  dataIndex: 'psnId',
  width: 100,
}, {
  title: '个人姓名',
  dataIndex: 'psnName',
  width: 100,
}, {
  title: '手机号',
  dataIndex: 'accountMobile',
  width: 100,
}, {
  title: '邮箱',
  dataIndex: 'accountEmail',
  width: 100,
},{
  title: '授权码',
  dataIndex: 'account',
  width: 100,
}, {
  title: '开户行',
  dataIndex: 'bankName',
  width: 100,
}, {
  title: '开户账号',
  dataIndex: 'bankCardNum',
  width: 100,
}, {
  title: '创建时间',
  dataIndex: 'createTime',
  width: 100,
}, {
  title: '更新时间',
  dataIndex: 'updateTime',
  width: 100,
}, {
  title: '授权流程状态',
  dataIndex: 'authorizedStatus',
  valueEnum: AuthorizedStatus,
  fixed: 'right',
  width: 100,
}, {
  title: '认证流程状态',
  dataIndex: 'realNameStatus',
  valueEnum: AuthRealNameStatus,
  fixed: 'right',
  width: 100,
}, {
  title: '合同状态',
  dataIndex: 'status',
  fixed: 'right',
  valueEnum: SealAuthStatus,
  width: 100,
}];


const AuthFlowList: FC<AuthFlowListProps> = (props) => {
  const searchFields = [{
    label: 'id',
    name: 'id.equals',
  },{
    label: '授权码',
    name: 'account.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<AuthFlow>
      columns={columns}
      hideDeleteButton
      sort=''
      hideCreateButton
      hideEditButton
      hideActionColumn
      scroll={{ x: 1500 }}
      quickFilterPanel={quickFilterPanel}
      detailPage={<AuthFlowDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <AuthFlowEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ authFlow }: RootState) => ({
  entity: authFlow.entity,
  entities: authFlow.entities,
  loading: authFlow.loading,
  updateSuccess: authFlow.updateSuccess,
  createSuccess: authFlow.createSuccess,
  total: authFlow.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuthFlowList);