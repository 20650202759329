import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, message } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ExchangeDetails from "./exchange-details";
import { InspectionStorage, ReceivingSGoods, auditExchange, backHeYan, cancelOrderEXC, deleteEntity, editAddress, fetchVerificationList, getEntities, getEntity, queryPrices, sendOutGoodsFunC, warehousingAndShipping } from "./exchange.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ExchangeEdit from "./exchange-edit";
import { Exchange } from 'model/procure/exchange.model';
import OrderDetailsUpdateMsg from "../../procure/components/order-details-update-msg";
import ProForm, { DrawerForm, ModalForm, ProFormDateTimePicker, ProFormDependency, ProFormDigit, ProFormList, ProFormRadio, ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import { fetchExpressNameList, uploadLogistics } from "pages/system/express-company/express.reducer";
import { useEnumValue } from "common/enum/use-enum-value";
import ProductSelectionForm from "pages/pim/product/product-selection-form";
import { searchProductSku } from "pages/pim/product/product.reducer";
import { EditOutlined } from "@ant-design/icons";
import { fetchSearchBrandList } from "pages/pim/brand/brand.reducer";
import Title from "antd/lib/typography/Title";
import { LinesItem } from "model/procure/order.model";
import { internationalList } from "components/IntelFormText";
import ExportDataDialog from "export/export-data-dialog";
import { FilterCriteria } from "components/model/share.model";
import { useParamGeneral } from "components/useParamGenera";

export interface ExchangeListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Exchange, 'exchangeOrderStatus'>[] = [{
  title: '订单编号',
  dataIndex: 'orderNo',
  width: 130,
}, {
  title: '换货品牌',
  dataIndex: 'storeName',
  width: 80,
}, {
  title: '授权号',
  dataIndex: ['buyer', 'buyerCode'],
  width: 90,
}, {
  title: '姓名',
  dataIndex: ['buyer', 'buyerName'],
  width: 80,
  ellipsis: true,
}, {
  title: '退回的商品数',
  width: 80,
  dataIndex: 'refundGoodNum',
}, {
  title: '价格合计',
  width: 80,
  dataIndex: 'refundTotalAmount',
}, {
  title: '要换的商品数',
  width: 80,
  dataIndex: 'exchangeGoodNum',
}, {
  title: '价格合计',
  width: 80,
  dataIndex: 'exchangeTotalAmount',
}, {
  title: '产品差价',
  width: 80,
  dataIndex: 'differenceAmount',
}, {
  title: '包装费',
  width: 80,
  dataIndex: 'packageAmount',
}, {
  title: '申请时间',
  width: 130,
  dataIndex: 'bookTime',
}, {
  title: '物流信息',
  width: 80,
  dataIndex: 'refundExpress',
  render: (dom, record: any) => {
    return <ModalForm
      title={'物流信息'}
      trigger={<Button type="link" size="small" >查看</Button>}
      modalProps={{
        destroyOnClose: true,
        cancelText: '关闭'
      }}
      submitter={{
        submitButtonProps: {
          style: {
            display: 'none',
          },
        },
      }}
      preserve={false}
      onFinish={async (data: any) => {
        return true
      }}
    >
      <ProTable<any>
        columns={[{
          title: '快递名称',
          width: 100,
          dataIndex: 'expressName',
        }, {
          title: '快递单号',
          width: 100,
          dataIndex: 'expressNo',
        }]}
        rowKey="expressNo"
        dataSource={record?.refundExpress || []}
        options={false}
        search={false}
        pagination={false}
      />
    </ModalForm>
  }
}, {
  title: '状态',
  dataIndex: 'orderState',
  width: 100,
  valueType: 'exchangeOrderStatus'
},];

let newInspectionArr: any = [];
const ExchangeList: FC<ExchangeListProps> = (props) => {
  console.log(props)
  const { getEntities, total } = props;
  const [sendIds, setSendIds] = useState<any>([]);
  const [visible, setVisible] = useState(false);
  const [auditTag, setAuditTag] = useState(false);
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [inspectionObj, setInspectionObj] = useState<any>({});
  const [inspectionArr, setInspectionArr] = useState<any>([]);
  const [sendOutGoodsArr, setSendOutGoodsArr] = useState<any>([]);
  const [expressCode, setExpressCode] = useState('');
  const [expressNameArr, setExpressNameArr] = useState<any>([]);
  const [expressNoArr, setExpressNoArr] = useState<any>([]);
  const [reexaminePimPrice, setReexaminePimPrice] = useState<any>(0);
  const [filterParams, setFilterParams] = useState<any>({});
  const { currency } = useParamGeneral();

  const { convertEnumToOptionType, getEnumValueDesc } = useEnumValue();

  const pimColumns: any = [{
    title: '商品名称',
    dataIndex: 'productName',
    width: 150,
  }, {
    title: 'SPU编码',
    dataIndex: 'productCode',
    width: 100,
  }, {
    title: 'SKU编码',
    dataIndex: 'skuCode',
    width: 100,
  }, {
    title: '规格',
    dataIndex: '',
    width: 150,
    render: (text: any, record: any) => {
      return !auditTag ? <>
        {record.specs && record.specs.map((tag: any) => <span>{tag.v},</span>)}
        <ProductSelectionForm
          hideSearch={true}
          triggerRender={<EditOutlined style={{ color: "#1890ff" }} type="link" />}
          multiple={false}
          requestParams={{
            'sellerId.equals': record?.lines?.[0]?.storeId || 1,
            'name.contains': record.productName
          }}
          level="sku"
          editSku={true}
          fetchFunc={searchProductSku}
          onFinish={(productIds, selectedRows) => {
            return new Promise<boolean>((resolve, reject) => {
              newInspectionArr = newInspectionArr.map((e: any) => {
                const obj = selectedRows[0];
                const objProduct: any = selectedRows[0].product;
                return e.id === record.id ? {
                  ...obj,
                  purchasePrice: obj.retailPrice,
                  totalPrice: obj.retailPrice,
                  skuId: obj.id,
                  productId: objProduct?.id,
                  productCode: objProduct?.code,
                  productName: objProduct?.name,
                  quantity: 1,
                  id: String(new Date().getTime()),
                  code: e.code,
                } : {
                  ...e,
                }
              });
              setInspectionArr(newInspectionArr);
              resolve(true);
            });
          }}
        />
      </> : <>{record.specName && record.specName.map((tag: any) => <span>{tag.v},</span>)}</>
    },
  }, {
    title: '单价',
    dataIndex: 'purchasePrice',
    width: 60,
  }];

  // 退回商品信息columns
  const refundPimColumns: ProColumns<LinesItem, 'purchaseOrderLineStatus'>[] = [{
    title: 'SPU编码',
    dataIndex: 'productCode',
    width: 70,
  }, {
    title: 'SKU编码',
    dataIndex: 'skuCode',
    width: 70,
  }, {
    title: '商品名称',
    dataIndex: '',
    width: 100,
    fixed: 'left',
    render: (text: any, row: any) => {
      return <p>{internationalList(row.productName || '')}</p>
    }
  }, {
    title: '规格',
    dataIndex: 'sonItem',
    width: 100,
    render: (text, row) => {
      return row?.specName?.map((v: any) => {
        return <><span>{v.v}，</span></>
      })
    }
  }, {
    title: '商品单价',
    width: 100,
    dataIndex: 'purchasePrice',
  }, {
    title: '包装费',
    width: 100,
    dataIndex: 'packageAmount',
  },
  {
    title: '数量',
    width: 100,
    dataIndex: 'quantity',
  }, {
    title: '行小计',
    width: 100,
    dataIndex: 'subtotal',
  }, {
    title: '发货状态',
    width: 100,
    dataIndex: 'status',
    render: (text, record: any) => {
      return getEnumValueDesc('exchangeOrderLineStatus', record.status || '')
    }
  }, {
    title: '物流码',
    dataIndex: 'barCode',
    width: 100,
  },];

  const InspectionColumnsRead = pimColumns.concat([{
    title: '数量',
    dataIndex: 'quantity',
    width: 60,
  }, {
    title: '小计金额',
    dataIndex: 'subtotal',
    width: 60,
  }, {
    title: '换货类型',
    dataIndex: 'exchangeType',
    width: 100,
    valueType: 'exchangeType',
  }, {
    title: '质量问题',
    dataIndex: 'qualityProblem',
    width: 100,
    valueType: 'qualityProblem',
  }, {
    title: '工厂',
    dataIndex: 'exchangeFactory',
    width: 100,
    valueType: 'exchangeFactory',
  }, {
    title: '标签',
    dataIndex: 'exchangeLabel',
    width: 100,
    valueType: 'exchangeLabel',
  }, {
    title: '备注',
    dataIndex: 'remark',
    width: 100,
  }]);

  const searchFields = [{
    label: '订单编号',
    name: 'orderNo.equals',
  }, {
    label: '快递单号',
    name: 'expressNo',
  }, {
    label: '授权号',
    name: 'buyerCode.equals',
  }, {
    label: '用户姓名',
    name: 'consigneeName.equals',
  }];


  const inspectionChange = (id: string, name: string, value: any) => {
    newInspectionArr = newInspectionArr.concat().map((e: any) => ({
      ...e,
      [name]: e.id === id ? value : e[name],
      totalPrice: (name === 'quantity' && id === e.id) ? (value * e.purchasePrice) : e.totalPrice,
      "orderNo": inspectionObj.orderNo,
      "orderId": inspectionObj.id,
    }));
    setInspectionArr(newInspectionArr);
  };

  const sendOutGoodsChange = (id: any, name: string, value: any) => {
    let newInspection = sendOutGoodsArr.concat();
    if (newInspection.length > 0) {
      const arr = newInspection.filter((e: any) => e.orderLineId === id);
      if (arr.length > 0) {
        newInspection = newInspection.map((e: any) => ({
          ...e,
          [name]: e.orderLineId === id ? value : e[name]
        }));
        setSendOutGoodsArr(newInspection)
      } else {
        newInspection.push({
          orderLineId: id,
          [name]: value,
        });
        setSendOutGoodsArr(newInspection)
      }
    } else {
      newInspection.push({
        orderLineId: id,
        [name]: value,
      });
      setSendOutGoodsArr(newInspection)
    };
  }

  const InspectionColumns = pimColumns.concat([{
    title: '数量',
    dataIndex: '',
    width: 100,
    render: (dom: any, record: any) => {
      return <ProFormDigit
        initialValue={1}
        fieldProps={{
          value: record.quantity,
          onChange: (e) => inspectionChange(record.id, 'quantity', e)
        }}
      />
    }
  }, {
    title: '总价',
    dataIndex: 'totalPrice',
    width: 60,
  }, {
    title: '换货类型',
    dataIndex: '',
    width: 100,
    render: (dom: any, record: any) => {
      return <ProFormSelect
        placeholder="换货类型"
        options={convertEnumToOptionType('exchangeType')}
        fieldProps={{
          value: record.exchangeType,
          onChange: (e) => inspectionChange(record.id, 'exchangeType', e)
        }}
      />
    }
  }, {
    title: '质量问题',
    dataIndex: '',
    width: 100,
    render: (dom: any, record: any) => {
      return <ProFormSelect
        placeholder="质量问题"
        options={convertEnumToOptionType('qualityProblem')}
        fieldProps={{
          value: record.qualityProblem,
          onChange: (e) => inspectionChange(record.id, 'qualityProblem', e)
        }}
      />
    }
  }, {
    title: '工厂',
    dataIndex: '',
    width: 100,
    render: (dom: any, record: any) => {
      return <ProFormSelect
        placeholder="工厂"
        options={convertEnumToOptionType('exchangeFactory')}
        fieldProps={{
          value: record.exchangeFactory,
          onChange: (e) => inspectionChange(record.id, 'exchangeFactory', e)
        }}
      />
    }
  }, {
    title: '标签',
    dataIndex: '',
    width: 100,
    render: (dom: any, record: any) => {
      return <ProFormSelect
        placeholder="标签"
        options={convertEnumToOptionType('exchangeLabel')}
        fieldProps={{
          value: record.exchangeLabel,
          onChange: (e) => inspectionChange(record.id, 'exchangeLabel', e)
        }}
      />
    }
  }, {
    title: '备注',
    dataIndex: '',
    width: 200,
    render: (dom: any, record: any) => {
      return <ProFormText
        placeholder="备注"
        fieldProps={{
          value: record.remark,
          onChange: (e) => inspectionChange(record.id, 'remark', e.target.value)
        }}
      />
    }
  }, {
    title: '操作',
    dataIndex: '',
    width: 80,
    render: (dom: any, record: any) => {
      return <Button type="link" danger onClick={() => {
        setInspectionArr(inspectionArr.filter((e: any) => String(e.id) !== String(record.id)))
        newInspectionArr = newInspectionArr.filter((e: any) => String(e.id) !== String(record.id))
      }}>删除</Button>
    }
  }]);

  const ReceivingColumns = [{
    title: '物流公司',
    dataIndex: 'expressName',
    width: 100
  }, {
    title: '物流单号',
    dataIndex: 'expressNo',
    width: 100
  }];

  const sendOutGoodsColumns = pimColumns.concat([{
    title: '发货数量',
    dataIndex: '',
    width: 100,
    render: (dom: any, record: any) => {
      return <ProFormDigit
        placeholder="发货数量"
        fieldProps={{
          onChange: (e) => sendOutGoodsChange(record.id, 'quantity', e || 0)
        }}
      />
    }
  }]);

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          options={convertEnumToOptionType('exchangeOrderStatus') || []}
          name="orderState.equals"
          placeholder={'状态'}
        />
        <Form.Item name={'storeId.equals'}>
          <SelectSearch width='md' required={false} name="storeId.equals" placeholder="品牌" optionValue='id' labelValue="brandName" defaultParams='id.equals' valueType="number" fetchFunc={fetchSearchBrandList} searchParams='name.contains' />
        </Form.Item>
        <SearchBox
          fields={searchFields}
        />
        <ProFormDateTimePicker
          name="bookTime.greaterThanOrEqual"
          placeholder="申请开始时间"
        />
        <ProFormDateTimePicker
          name="bookTime.lessThanOrEqual"
          placeholder="申请结束时间"
        />
        <ProFormDateTimePicker
          name="inspectionTime.greaterThanOrEqual"
          placeholder="验货开始时间"
        />
        <ProFormDateTimePicker
          name="inspectionTime.lessThanOrEqual"
          placeholder="验货结束时间"
        />
        <ProFormDateTimePicker
          name="receiveTime.greaterThanOrEqual"
          placeholder="订单完成开始时间"
        />
        <ProFormDateTimePicker
          name="receiveTime.lessThanOrEqual"
          placeholder="订单完成结束时间"
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: any, record: any) => {
    return ['TO_BE_PLATFORM_CHECK', 'TO_BE_PLATFORM_AUDIT', 'TO_BE_PLATFORM_RECEIVED', 'TO_BE_SEND_BACK', 'TO_BE_PAID', 'DRAFT'].indexOf(record.orderState) > -1 &&
      <OrderDetailsUpdateMsg entity={record} updateConsignee={(res) => {
        const obj = {
          ...res,
          ...res.address
        };
        return new Promise<boolean>((resolve, reject) => {
          editAddress(obj).then((e: any) => {
            message.success('操作成功');
            resolve(true);
            setRefreshFlag(new Date().getTime());
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }} />
  }, (dom: any, record: any) => {
    return record.orderState === 'TO_BE_PLATFORM_AUDIT' && <DrawerForm
      title={'核验复审'}
      trigger={<Button type="link" size="small" >核验复审</Button>}
      drawerProps={{
        destroyOnClose: true,
      }}
      width={1700}
      onVisibleChange={(e) => {
        setAuditTag(e);
        setReexaminePimPrice(0);
      }}
      preserve={false}
      onFinish={async (data: any) => {
        const params = {
          id: record.id,
          ...data,
        }
        return new Promise<boolean>((resolve, reject) => {
          resolve(false);
          auditExchange(params).then((e: any) => {
            message.success('审核成功');
            resolve(true);
            setRefreshFlag(new Date().getTime());
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <ProFormDependency name={['isAuditSuccess']}>
          {({ isAuditSuccess }) => {
            return isAuditSuccess && <ProFormDigit
              name="amount"
              label="补差金额"
              initialValue={record.lossAmount || 0}
              placeholder="补差金额"
              width={'sm'}
            />
          }}
        </ProFormDependency >
      </div>
      <p style={{ height: '20px' }}></p>
      <Title level={5}>实际寄回的商品信息</Title>
      <ProTable<any>
        columns={InspectionColumnsRead}
        rowKey="skuId"
        options={false}
        search={false}
        pagination={false}
        request={async (params) => {
          const result = await fetchVerificationList(record.id);
          setReexaminePimPrice(result.map((e: any) => e.subtotal).reduce((a: number, b: number) => a + b, 0))
          return {
            success: true,
            total: result.length || 0,
            data: result,
          };
        }}
      />
      <p className="totalPriceAll">商品总价：<span>{currency?.symbol}{reexaminePimPrice}</span></p>
      <ProFormRadio.Group
        name="isAuditSuccess"
        label="审核状态"
        initialValue={true}
        options={[
          {
            label: '通过',
            value: true,
          },
          {
            label: '退回验货确认',
            value: false,
          },
        ]}
      />
      <ProFormDependency name={['isAuditSuccess']}>
        {({ isAuditSuccess }) => {
          return !isAuditSuccess && <ProFormTextArea name="reason" label="退回验货确认原因" />
        }}
      </ProFormDependency >
      <Title level={5}>退回的商品信息</Title>
      <ProTable<LinesItem, Record<string, any>, 'purchaseOrderLineStatus'>
        columns={refundPimColumns}
        rowKey="id"
        dataSource={record?.lines ? record?.lines.filter((e: any) => !e.isExchange) : []}
        pagination={false}
        options={false}
        search={false}
      />
      <p className="totalPriceAll">商品总价：<span>¥{record?.refundTotalAmount}</span></p>
    </DrawerForm>
  }, (dom: any, record: any) => {
    return record.orderState === 'TO_BE_USER_DIFFERENTIAL' && <ModalForm
      title={'返回核验复审'}
      trigger={<Button type="link" size="small" >返回核验复审</Button>}
      modalProps={{
        destroyOnClose: true,
      }}
      width={400}
      onVisibleChange={(e) => {
        setAuditTag(e);
        setReexaminePimPrice(0);
      }}
      preserve={false}
      onFinish={async (data: any) => {
        const params = {
          id: record.id,
        }
        return new Promise<boolean>((resolve, reject) => {
          resolve(false);
          backHeYan(params).then((e: any) => {
            message.success('审核成功');
            resolve(true);
            setRefreshFlag(new Date().getTime());
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <p>确定将订单状态回退到核验复审吗？</p>
    </ModalForm>
  }, (dom: any, record: any) => {
    return record.orderState === 'TO_BE_PLATFORM_RECEIVED' && <ModalForm
      title={'确认收货产品'}
      trigger={<Button type="link" size="small" >确认收货</Button>}
      modalProps={{
        destroyOnClose: true,
      }}
      onVisibleChange={(e) => {

      }}
      preserve={false}
      onFinish={async (data: any) => {
        return new Promise<boolean>((resolve, reject) => {
          const arr = record.refundExpress.map((e: any) => {
            const isReceive = !!e.isReceive ? true : (expressNoArr.indexOf(e.expressNo) > -1 ? true : false)
            return {
              expressNo: e.expressNo,
              isReceive,
            }
          });
          ReceivingSGoods(record.id, arr).then((e: any) => {
            message.success('操作成功');
            resolve(true);
            setRefreshFlag(new Date().getTime());
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <ProTable<any>
        columns={ReceivingColumns}
        rowKey="expressNo"
        dataSource={record.refundExpress}
        options={false}
        search={false}
        rowSelection={{
          type: 'checkbox',
          getCheckboxProps: (record: any) => ({
            disabled: record.isReceive,
          }),
        }}
        tableAlertOptionRender={(res: any) => {
          const Ids = res.selectedRowKeys;
          setExpressNoArr(Ids);
          return <></>
        }}
        pagination={false}
      />
    </ModalForm>
  }, (dom: any, record: any) => {
    return ['TO_BE_PLATFORM_RECEIVED', 'TO_BE_PLATFORM_CHECK', 'TO_BE_PLATFORM_AUDIT'].indexOf(record.orderState) > -1 && <ModalForm
      title={'确认取消订单'}
      trigger={<Button type="link" size="small" >取消订单</Button>}
      modalProps={{
        destroyOnClose: true,
      }}
      onVisibleChange={(e) => {

      }}
      preserve={false}
      onFinish={async (data: any) => {
        return new Promise<boolean>((resolve, reject) => {
          cancelOrderEXC(record.id,data).then((e: any) => {
            message.success('操作成功');
            resolve(true);
            setRefreshFlag(new Date().getTime());
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <ProFormTextArea
        name="desc"
        label="取消原因"
      />
    </ModalForm>
  }, (dom: any, record: any) => {
    return record.orderState === 'TO_BE_PLATFORM_CONFIRM' && <ModalForm
      title={'您确认操作该订单发货吗？'}
      trigger={<Button type="link" size="small" >入库发货</Button>}
      modalProps={{
        destroyOnClose: true,
      }}
      preserve={false}
      onFinish={async (data: any) => {
        return new Promise<boolean>((resolve, reject) => {
          warehousingAndShipping(record.id).then((e: any) => {
            message.success('操作成功');
            setRefreshFlag(new Date().getTime());
            resolve(true);
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <p>确认后将同步仓库系统，创建入库订单和发货订单，确认后请您及时将用户退回商品存放至相关库位</p>
    </ModalForm>
  },
  (dom: any, record: any) => {
    return record.orderState === 'TO_BE_SEND_BACK' && <ModalForm
      title={'上传物流信息'}
      trigger={<Button type="link" size="small" >上传物流</Button>}
      modalProps={{
        destroyOnClose: true,
      }}
      onVisibleChange={(e) => {
        e && setSendOutGoodsArr([]);
        e && setInspectionObj(record);
      }}
      preserve={false}
      onFinish={async (data: any) => {
        console.log(data, expressNameArr)
        const newArr = data.arr.map((e: any, itemIndex: number) => {
          return {
            ...e,
            expressName: expressNameArr.filter((e: any) => e.index === itemIndex)?.[0]?.value || ''
          }
        })
        return new Promise<boolean>((resolve, reject) => {
          uploadLogistics(newArr, record.id).then((e: any) => {
            message.success('操作成功');
            resolve(true);
            setRefreshFlag(new Date().getTime());
          }).catch((error: any) => {
            message.error(error?.response?.data?.message);
            resolve(false);
          })
        });
      }}
    >
      <ProFormList
        label="物流信息"
        name={'arr'}
        creatorButtonProps={{
          creatorButtonText: '添加物流信息',
        }}
        actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
      >
        {(meta, itemIndex, action) => {

          return <ProForm.Group size={10}>
            <ProForm.Item label="快递公司" name={'expressCode'}>
              <SelectSearch optionKey="expressName"
                fetchOptionKey={(e: string) => {
                  const arr = expressNameArr.filter((s: any) => s.index === itemIndex);
                  // setExpressCodeArr(expressCodeArr.concat([]))
                  arr.length > 0 && setExpressNameArr(expressNameArr.map((item: any) => ({
                    ...item,
                    value: item.index === itemIndex ? e : item.value,
                  })));
                  arr.length === 0 && setExpressNameArr(expressNameArr.concat([{
                    value: e,
                    index: itemIndex
                  }]));
                }}
                placeholder='快递公司'
                required={true}
                optionValue='expressCode'
                defaultParams={''}
                name={'expressCode'}
                width={200}
                labelValue="expressName"
                fetchFunc={fetchExpressNameList}
                searchParams='expressName'
              />
            </ProForm.Item>
            <ProFormText
              width={'md'}
              name="expressNo"
              label="物流单号"
            />
          </ProForm.Group>
        }}
      </ProFormList>
    </ModalForm>
  },
  (dom: any, record: any) => {
    return record.orderState === 'TO_BE_PLATFORM_SHIPPED' && <ModalForm
      title={'确认发货商品'}
      trigger={<Button type="link" size="small" >订单发货</Button>}
      modalProps={{
        destroyOnClose: true,
      }}
      onVisibleChange={(e) => {
        e && setSendOutGoodsArr([]);
        e && setInspectionObj(record);
      }}
      preserve={false}
      onFinish={async (data: any) => {
        return new Promise<boolean>((resolve, reject) => {
          if (sendIds.length > 0) {
            const newSendOutGoodsArr = sendOutGoodsArr.concat().filter((e: any) => sendIds.indexOf(e.orderLineId) > -1);
            setSendOutGoodsArr(newSendOutGoodsArr);
            setVisible(true);
            resolve(true);
          } else {
            message.error('请选择需发货的行');
            resolve(false);
          }

        });
      }}
    >
      <ProTable<any>
        columns={sendOutGoodsColumns}
        rowKey="id"
        dataSource={record.lines.filter((e: any) => !!e.isExchange)}
        options={false}
        search={false}
        rowSelection={{
          type: 'checkbox',
        }}
        tableAlertOptionRender={(res: any) => {
          const Ids = res.selectedRowKeys;
          setSendIds(Ids);
          return <></>
        }}
        pagination={false}
      />
    </ModalForm>
  }, (dom: any, record: any) => {
    return record.orderState === 'TO_BE_PLATFORM_CHECK' && <DrawerForm
      title={'验货确认'}
      trigger={<Button type="link" size="small" >验货确认</Button>}
      drawerProps={{
        destroyOnClose: true,
      }}
      onVisibleChange={(e) => {
        setAuditTag(!e);
        setInspectionArr([]);
        newInspectionArr = [];
        if (e) {
          fetchVerificationList(record.id).then(arr => {
            const newArr = arr.length > 0 ? arr.map((e: any) => {
              return {
                ...e,
                specs: e.specName,
                totalPrice: e.quantity * e.purchasePrice
              }
            }) : [];
            setInspectionArr(newArr);
            newInspectionArr = newArr;
          });
          setInspectionObj(record);
        } else {
          setInspectionObj({});
        };

      }}
      width={1700}
      preserve={false}
      onFinish={async (data: any) => {
        return new Promise<boolean>((resolve, reject) => {
          const arr = newInspectionArr.map((e: any) => {
            e.id && String(e.id).split('--create').length > 1 && delete e.id;
            return e
          });
          InspectionStorage(record.id, {
            lossAmount: data.lossAmount,
            dtos: arr,
          }).then((e: any) => {
            message.success('核检入库成功');
            setRefreshFlag(new Date().getTime());
            resolve(true);
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div style={{ marginTop: '20px' }}>
          <ProductSelectionForm
            trigger="新增商品"
            multiple={true}
            requestParams={{ 'sellerId.equals': record?.lines?.[0]?.storeId || 1 }}
            level="sku"
            scrollConfig={{ y: 400 }}
            fetchFunc={searchProductSku}
            onFinish={(productIds, selectedRows) => {
              console.log(productIds, selectedRows)
              return new Promise<boolean>((resolve, reject) => {
                queryPrices({
                  "ids": productIds,
                  "levelNo": record?.buyer?.buyerLevelNo
                }).then((pricesData) => {
                  console.log(inspectionArr)
                  const newArr = inspectionArr.concat(selectedRows.map((e: any, index: any) => {
                    const purchasePrice = pricesData.filter((item: any) => String(item.id) === String(e.id))?.[0]?.purchasePrice || 0;
                    const obj: any = e.product;
                    obj.productId = obj?.id;
                    obj.productCode = obj?.code;
                    obj.productName = obj?.name
                    delete obj?.id;
                    return {
                      ...e,
                      ...obj,
                      skuId: e.id,
                      purchasePrice,
                      totalPrice: purchasePrice,
                      quantity: 1,
                      id: String(new Date().getTime() + index) + '--create',
                      skuCode: e.code,
                    }
                  }));
                  setInspectionArr(newArr);
                  newInspectionArr = newArr;
                  resolve(true);
                })
              });
            }}
          />
        </div>
        <ProFormDigit
          width="md"
          name="lossAmount"
          initialValue={0}
          label="补付差价"
          rules={[{ required: true }]}
        />
      </div>
      <p style={{ height: '20px' }}></p>
      <Title level={5}>实际寄回的商品信息</Title>
      <ProTable<any>
        columns={InspectionColumns}
        rowKey="id"
        dataSource={inspectionArr}
        options={false}
        search={false}
        pagination={false}
      />
      <p className="totalPriceAll">商品总价：<span>{currency?.symbol}{inspectionArr.map((e: any) => e.totalPrice).reduce((a: number, b: number) => a + b, 0)}</span></p>
      <Title level={5}>退回的商品信息</Title>
      <ProTable<LinesItem, Record<string, any>, 'purchaseOrderLineStatus'>
        columns={refundPimColumns}
        rowKey="id"
        dataSource={record?.lines ? record?.lines.filter((e: any) => !e.isExchange) : []}
        pagination={false}
        options={false}
        search={false}
      />
      <p className="totalPriceAll">商品总价：<span>¥{record?.refundTotalAmount}</span></p>
    </DrawerForm>
  }];

  const downloadColumns = [{
    title: '订单编号',
    dataIndex: 'orderNo',
  }, {
    title: '验货日期',
    dataIndex: 'date',
  }, {
    title: '验货员',
    dataIndex: 'createdBy',
  }, {
    title: '授权号',
    dataIndex: 'buyerCode',
  }, {
    title: '姓名',
    dataIndex: 'buyerName',
  }, {
    title: '商品编码',
    dataIndex: 'productCode',
  }, {
    title: '商品名称',
    dataIndex: 'productName',
  }, {
    title: '规格',
    dataIndex: 'specName',
  }, {
    title: '单价',
    dataIndex: 'purchasePrice',
  }, {
    title: '数量',
    dataIndex: 'quantity',
  }, {
    title: '总价',
    dataIndex: 'totalPrice',
  }, {
    title: '换货类型',
    dataIndex: 'exchangeType',
  }, {
    title: '质量问题',
    dataIndex: 'qualityProblem',
  }, {
    title: '工厂',
    dataIndex: 'exchangeFactory',
  }, {
    title: '标签',
    dataIndex: 'exchangeLabel',
  }, {
    title: '备注',
    dataIndex: 'remark',
  }];

  //导出
  const exportData = (resData?: any) => {
    let orderData: any[] = [];
    const exportOrderData = resData ? resData : props.entities;
    exportOrderData.forEach((res: any) => {
      res?.exchangeProductInspectionDtoList?.forEach((item: any) => {
        let specName = '';
        item.specName.forEach((element: any) => {
          specName += element.v + '；'
        });
        let arr: any = {
          orderNo: res.orderNo,
          date: item.createdDate,//改
          buyerCode: res?.buyer?.buyerCode || '',
          buyerName: res?.buyer?.buyerName || '',
          createdBy: item.createdBy,//改
          productName: item.productName,
          productCode: item.productCode,
          specName,
          purchasePrice: item.purchasePrice,
          quantity: item.quantity,
          totalPrice: item.subtotal,
          exchangeType: getEnumValueDesc('exchangeType', item.exchangeType || ''),
          qualityProblem: getEnumValueDesc('qualityProblem', item.qualityProblem || ''),
          exchangeFactory: getEnumValueDesc('exchangeFactory', item.exchangeFactory || ''),
          exchangeLabel: getEnumValueDesc('exchangeLabel', item.exchangeLabel || ''),
          remark: item.remark,
        };
        orderData.push(arr);
      })
    });
    return orderData;
  };

  //下载全部回调
  const exportDataAll = async (downLoadText?: string) => {
    let page = 0;
    let size = 100;
    let allData: any[] = [];

    do {
      const params: any = {
        ...filterParams,
        page,
        size
      };

      //去除值为空的key
      const newFilterObj: FilterCriteria = {};
      const objArr = Object.keys(params);
      objArr.forEach((key, index) => {
        const val = params[key];
        if (val !== '') {
          newFilterObj[key] = val;
        };
      });

      const getData: any = getEntities && await getEntities(newFilterObj, true);
      let newData = exportOrderData(getData.value.data);
      allData = allData.concat(newData);
      ++page;

    } while ((page - 1) * size < total);
    return allData
  }

  const exportOrderData = (resData?: any) => {
    let orderData: any[] = [];
    const exportOrderData = resData ? resData : props.entities;
    exportOrderData.forEach((res: any) => {
      let obj = {
        ...res,
        buyerCode: res.buyer.buyerCode,
        buyerName: res.buyer.buyerName,
      }
      orderData.push(obj)
    });
    return orderData;
  };

  const additionActions = [
    <ExportDataDialog
      columns={columns.map(e => {
        return {
          ...e,
          dataIndex: e.title === '授权号' ? 'buyerCode' : (e.title === '姓名' ? 'buyerName' : e.dataIndex),
        }
      }).filter(e => e.dataIndex !== 'refundExpress').concat([{
        title: '实付金额',
        dataIndex: 'payAmount',
      },{
        title: '完成时间',
        dataIndex: 'receiveTime',
      }])}
      dataSource={exportOrderData()}
      entityName='换货订单明细'
      exportData={exportDataAll}
      downloadText={'导出订单明细'}
    />
  ];

  return (
    <ListViewPage<Exchange, 'exchangeOrderStatus'>
      columns={columns}
      showDownload={true}
      downloadEntities={exportData()}
      fetchSearchParams={setFilterParams}
      downloadEntityName={'验货明细'}
      downloadAllEntities={exportData}
      downloadColumns={downloadColumns}
      downloadText={'导出验货明细'}
      hideDeleteButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<ExchangeDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      hideEditButton
      hideCreateButton
      refreshFlag={refreshFlag}
      actions={additionActions}
      rowActions={rowActions}
      editPage={(params: any) => <ExchangeEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
      <ModalForm
        title={'填写物流信息'}
        visible={visible}
        modalProps={{
          destroyOnClose: true,
        }}
        onVisibleChange={(e) => !e && setVisible(false)}
        preserve={false}
        onFinish={async (data: any) => {
          return new Promise<boolean>((resolve, reject) => {
            const params = {
              ...data,
              expressCode,
              orderLineShippedDtos: sendOutGoodsArr
            };
            sendOutGoodsFunC(inspectionObj.id, params).then((e: any) => {
              message.success('操作成功');
              setSendOutGoodsArr([]);
              setRefreshFlag(new Date().getTime());
              resolve(true);
            }).catch((error: any) => {
              message.error(error?.response?.data?.detail);
              resolve(false);
            })
          });
        }}
      >
        <Form.Item label="快递公司" name={'expressName'}>
          <SelectSearch optionKey="expressCode"
            fetchOptionKey={setExpressCode}
            placeholder='快递公司'
            required={true}
            optionValue='expressCode'
            defaultParams={''}
            name={'expressName'}
            width={200}
            labelValue="expressName"
            fetchFunc={fetchExpressNameList}
            searchParams='expressName.contains'
          />
        </Form.Item>
        <ProFormText
          width="md"
          name="expressNo"
          label="快递单号"
        />
      </ModalForm>
    </ListViewPage>
  );
};

const mapStateToProps = ({ exchange }: RootState) => ({
  entity: exchange.entity,
  entities: exchange.entities,
  loading: exchange.loading,
  updateSuccess: exchange.updateSuccess,
  createSuccess: exchange.createSuccess,
  total: exchange.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeList);