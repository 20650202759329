import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import CustomerList from './customer-list';
import CustomerDetails from "./customer-details";
import CustomerEdit from "./customer-edit";

const Customer: React.FC<RouteComponentProps> = () => (
  <Router>
    <CustomerList path="/" />
    <CustomerDetails path=":id" />
    <CustomerEdit path=":id/edit" />
    <CustomerEdit path="new" />
  </Router>
);
export default Customer;
