import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form} from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./exchangeGoods.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ExchangeGoods } from "model/product/exchangeGoods.model";
export interface ExchangeGoodsEditProps extends EditStateAndDispatchProps<ExchangeGoods>, StateProps, DispatchProps, RouteComponentProps {
}

const ExchangeGoodsEdit: FC<ExchangeGoodsEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<ExchangeGoods>
      title="编辑"
      form={form}
      {...props}
    >
    </EditPage>);
};

const mapStateToProps = ({ exchangeGoods }: RootState) => ({
  entity: exchangeGoods.entity,
  updateSuccess: exchangeGoods.updateSuccess,
  updating: exchangeGoods.updating,
  createSuccess: exchangeGoods.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeGoodsEdit);
