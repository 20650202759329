import { ProFormUploadButton } from "@ant-design/pro-form";
import { message } from "antd";
import { internationalList } from "components/IntelFormText";
import { fetchPicture } from "pages/dealer/dealerInfo/dealerInfo.reducer";
import { useEffect, useState } from "react";
import { uploadImage } from "utils/file-utils";

export interface UploadImgOneProps {
  upType?: 'video' | 'img' | 'any';
  value?: string;
  onChange?: (data?: any) => void;
  extra?: string;
  title?: string;
  listType: 'text' | 'picture' | 'picture-card';
  Immediate?: boolean; //是否立即上传
  thumbUrl?: string;
  requiredIdentifying?: number;
  category?: any,
  postfiximgpath?: any
}

const UploadImgOne = (props: UploadImgOneProps) => {
  const { value, extra, title, listType = 'picture-card', onChange, Immediate = false, upType = 'img', thumbUrl = '', requiredIdentifying, category = 'public', postfiximgpath } = props;

  const [pictureData, setPictureData] = useState<any>([]);//主图渲染

  useEffect(() => {
    setPictureData([]);
  }, [])

  useEffect(() => {
    value && value !== '' && showPicture(value);
    if (!value) {
      setPictureData([]);
      !!Immediate ? ((requiredIdentifying !== 0) && onChange?.('')) : onChange?.([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  let timeout: any = null;
  const handlePicture = (res: any) => {
    if (!!Immediate) {
      if (res.fileList.length > 0) {
        const newDate = new Date().getTime();
        // 图片防抖处理
        const inputValueChange = (type: string, msg?: string) => {
          if (timeout) {
            clearTimeout(timeout);
            timeout = null;
          }
          const fake = async () => {
            type === 'img' ? funC() : message.error(msg);
          };
          timeout = setTimeout(fake, 1000);
        };
        const funC = () => {
          const arr = res.fileList[res.fileList.length - 1].name.split('.');
          let suffix = '.' + arr[arr.length - 1];
          let imgPath = postfiximgpath ? postfiximgpath : 'product/img-' + newDate + suffix
          uploadImage(res.fileList[0].originFileObj, imgPath, category).then(imageName => {

            if (category === 'public') {
              const pictureArray = {
                name: imageName,
                status: 'done',
                url: imageName,
              };
              setPictureData([pictureArray]);
              onChange?.(imageName);
            } else {
              fetchPicture(imageName).then(e => {
                const pictureArray = {
                  name: imageName,
                  status: 'done',
                  url: e,
                };
                setPictureData([pictureArray]);
                onChange?.(e);
              }).catch(error => {
                message.error(error?.response?.data?.title || 'error');
              });
            }

          }).catch(() => { message.error('图片上传失败'); });
        };
        if (upType === 'video') {
          res.fileList[0].originFileObj.type === 'video/mp4' ? inputValueChange('img') : inputValueChange('message', '请上传视频');
        } else if (upType === 'img') {
          res.fileList[0].originFileObj.type.includes('image') ? inputValueChange('img') : inputValueChange('message', '请上传图片');
        } else if (upType === 'any') {
          inputValueChange('img');
        }
      } else {
        onChange?.('');
        setPictureData([]);
      };
    } else {
      setPictureData(res.fileList);
      onChange?.(res.fileList);
    };
  };

  const showPicture = (res: any) => {
    //主图展示
    if (typeof (res) === 'string') {
      const pictureArray = internationalList(res) ? {
        name: internationalList(res),
        status: 'done',
        url: internationalList(res),
        thumbUrl,
      } : false;
      setPictureData(pictureArray ? [pictureArray] : []);
      !!Immediate ? onChange?.(res) : onChange?.(pictureArray ? [pictureArray] : []);
    } else {
      setPictureData(res);
      onChange?.(res);
    }
  }

  return <ProFormUploadButton
    listType={listType}
    extra={extra}
    title={title}
    fileList={pictureData}
    onChange={handlePicture}
    fieldProps={{ maxCount: 1, className: "pro-upload-picture-box", beforeUpload: () => false }}
  />
}

export default UploadImgOne;