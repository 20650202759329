import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./power-module.reducer";
import { PowerModule } from "model/system/power-module.model";
import { Col, Row } from "antd";
import { ProFormText } from "@ant-design/pro-form";
export interface ModuleEditProps extends EditStateAndDispatchProps<PowerModule>, StateProps, DispatchProps, RouteComponentProps {
}

const ModuleEdit: FC<ModuleEditProps> = props => {
  return (
    <EditPage<PowerModule>
      title="模块"
      {...props}
      entity={{ 
        ...props.entity,
        id: props.entity.code
      }}
    >
      <Row>
        <Col span={11}>
          <ProFormText
            name="code"
            label="模块编码"
            width="md"
            rules={[{
              required: true,
            }]}
            disabled={props.entity.code ? true : false}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={11}>
          <ProFormText
            name="name"
            label="模块名称"
            width="md"
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ powerModule }: RootState) => ({
  entity: powerModule.entity,
  updateSuccess: powerModule.updateSuccess,
  updating: powerModule.updating,
  createSuccess: powerModule.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ModuleEdit);
