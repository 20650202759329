import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDigit } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./category.reducer";
import { Category } from "model/product/category.model";
import { Form } from "antd";
import IntelFormText from "components/IntelFormText";

export interface CategoryEditProps extends EditStateAndDispatchProps<Category>, StateProps, DispatchProps, RouteComponentProps {
}

const CategoryEdit: FC<CategoryEditProps> = (props) => {

  const { entity } = props;
  const [entityCopy, setEntityCopy] = useState({});
  const [form] = Form.useForm();

  useEffect(() => {
    if (entity.id) {
      const entityData = JSON.parse(JSON.stringify(entity))
      setEntityCopy(entityData)
    } else {
      setEntityCopy({});

    }
  }, [entity])

  return (
    <EditPage<Category>
      title="商品类目"
      form={form}
      {...props}
      entity={entityCopy}
    >
      <IntelFormText
        label={'类目名称'}
        name="name"
        required={true}
        form={form}
        fieldProps={{
          maxLength: 100
        }}
        value={props.entity.name || ''}
      />
      <ProFormDigit
        name="sort"
        label="排序"
        rules={[{ required: true }]}
        min={0}
      />
    </EditPage>);
};

const mapStateToProps = ({ category }: RootState) => ({
  entity: category.entity,
  updateSuccess: category.updateSuccess,
  updating: category.updating,
  createSuccess: category.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CategoryEdit);
