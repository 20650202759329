import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListDetails from "./classification-details";
import { deleteEntity, getEntities, getEntity } from "./classification.reducer";
import { RouteComponentProps } from "@reach/router";
import { Classification } from "model/product/classification.model";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ClassificationEdit from "./classification-edit";
import { internationalList } from "components/IntelFormText";
import { Image, Tag } from "antd";

export interface ClassificationListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const columns: ProColumns<Classification>[] = [{
  title: '分类',
  dataIndex: 'name',
  valueType: 'text',
  render: (dom, record) => internationalList(record.name || ''),
  width: 200,
}, {
  title: '主图',
  dataIndex: 'picture',
  valueType: 'image',
  width: 120,
  render: (dom, record) => <div className="classification-show-img"><Image src={internationalList(record.picture || '')} /></div>
}, {
  title: '分类id',
  dataIndex: 'id',
  valueType: 'text',
}, {
  title: '商家id',
  dataIndex: 'storeId',
  valueType: 'text',
},  {
  title: '排序',
  dataIndex: 'sort',
  valueType: 'text',
},  {
  title: '状态',
  dataIndex: 'status',
  valueType: 'text',
  render: (dom, record) => record.status === 0 ? <Tag color="error">禁用</Tag> : <Tag color="success">启用</Tag>
}]
const ClassificationList: FC<ClassificationListProps> = (props) => {

  return (
    <ListViewPage<Classification>
      columns={columns}
      rowKey='id'
      quickFilterPanel={false}
      hideDeleteButton
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <ClassificationEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ classification }: RootState) => ({
  entity: classification.entity,
  entities: classification.entities,
  loading: classification.loading,
  updateSuccess: classification.updateSuccess,
  total: classification.total,
  createSuccess: classification.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ClassificationList)