import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, Tooltip, message } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ContractDetails from "./list-details";
import { createUrl, deleteEntity, fetchDocTemplate, fetchParameter, getEntities, getEntity } from "./list.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ContractEdit from "./list-edit";
import { Contract } from "model/contract/contractList.model";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";

export interface ContractListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const ContractList: FC<ContractListProps> = (props) => {

  const [refreshTag, setRefreshTag] = useState(0);
  const { convertEnumToOptionType } = useEnumValue();

  const columns: ProColumns<Contract, 'templateType' |'templateProcess'>[] = [{
    title: 'id',
    dataIndex: 'id',
    width: 50,
  }, {
    title: '合同名称',
    dataIndex: 'contractName',
    ellipsis: true,
    width: 100,
  }, {
    title: '合同类型',
    dataIndex: 'type',
    valueType: 'templateType',
    width: 80,
  }, {
    title: '合同模板',
    dataIndex: 'docTemplateId',
    width: 80,
    render: (dom, record) => {
      return <Tooltip title={'查看合同模板'} key="sku"><Button onClick={() => {
        fetchDocTemplate(record.docTemplateId).then((data) => {
          window.open(data, "_blank");
        })
      }} type="link" size="small">查看</Button></Tooltip>
    }
  }, {
    title: '合同地址',
    dataIndex: 'fileUrl',
    ellipsis: true,
    width: 100,
  }, {
    title: '文件id',
    dataIndex: 'fileId',
    ellipsis: true,
    width: 100,
  }, {
    title: '店铺Id',
    dataIndex: 'storeId',
    width: 80,
  }, {
    title: '店铺名称（品牌）',
    dataIndex: 'storeName',
    width: 100,
  }, {
    title: '有效期',
    dataIndex: 'effectiveTime',
    width: 100,
  }, {
    title: '等级名称',
    dataIndex: 'levelNo',
    width: 100,
  }, {
    title: '等级id',
    dataIndex: 'levelId',
    width: 80,
  }, {
    title: '修改时间',
    dataIndex: 'updateTime',
    width: 100,
  }, {
    title: '状态',
    dataIndex: 'process',
    valueType:'templateProcess',
    width: 100,
  }]
  const searchFields = [{
    label: '合同名称',
    name: 'contractName.contains',
    width: 100,
  }];

  const parameterColumns = [{
    title: 'componentId',
    dataIndex: 'componentId',
    ellipsis: true,
    width: 100,
  }, {
    title: 'componentKey',
    dataIndex: 'componentKey',
    width: 100,
  }, {
    title: 'componentName',
    dataIndex: 'componentName',
    width: 100,
  }, {
    title: 'componentStr',
    dataIndex: 'componentStr',
    width: 100,
    ellipsis: true,
  }]

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          options={convertEnumToOptionType('templateType')}
          name="type.equals"
          placeholder="合同类型"
        />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );


  const rowActions = [(dom: any, record: any) => {
    return <ModalForm
      title="查看控件参数"
      width={1000}
      trigger={<Tooltip title={'查看控件参数'} key="sku"><Button type="link" size="small">查看控件参数</Button></Tooltip>}
      preserve={false}
      onVisibleChange={(e) => !e && setRefreshTag(new Date().getTime())}
      onFinish={async (data) => {
        return true;
      }}
    >
      <ProTable<any>
        className="express-table"
        columns={parameterColumns}
        rowKey="id"
        pagination={{
          showQuickJumper: true,
        }}
        options={false}
        search={false}
        params={{ refreshTag }}
        request={async (params) => {
          const result = await fetchParameter(record.id);
          return {
            success: true,
            total: result.length,
            data: result,
          };
        }}
      />
    </ModalForm>
  }, (dom: any, record: any) => {
    return <Button type="link" onClick={() => {
      createUrl(record.id).then((e: any) => {
        message.success('操作成功');
        setRefreshTag(new Date().getTime())
      }).catch((error: any) => {
        message.error(error?.response?.data?.detail);
      })
    }} size="small" disabled={record.process !== 'Upload'}>生成模板 </Button>
  }]
  return (
    <ListViewPage<Contract, 'templateType' | 'templateProcess'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ContractDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      rowActions={rowActions}
      refreshFlag={refreshTag}
      hideDeleteButton
      hideEditButton
      // hideCreateButton
      editPage={(params: any) => <ContractEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ contract }: RootState) => ({
  entity: contract.entity,
  entities: contract.entities,
  loading: contract.loading,
  updateSuccess: contract.updateSuccess,
  createSuccess: contract.createSuccess,
  total: contract.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractList);