import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import TagTaskList from './tagTask-list';
import TagTaskDetails from "./tagTask-details";
import TagTaskEdit from "./tagTask-edit";

const TagTask: React.FC<RouteComponentProps> = () => (
  <Router>
    <TagTaskList path="/" />
    <TagTaskDetails path=":id" />
    <TagTaskEdit path=":id/edit" />
    <TagTaskEdit path="new" />
  </Router>
);
export default TagTask;
