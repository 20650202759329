import { EyeOutlined } from "@ant-design/icons";
import { ModalForm } from "@ant-design/pro-form";
import { Button } from "antd";
import { safeCode } from "../customer.reducer";
import { useState } from "react";

export const searchSafeCode = (id: any, text: any) => {
    return new Promise<string>((resolve, reject) => {
        safeCode({
            customerId: id,
            param: text,
            remark: '',
        }).then(res => resolve(res.value));
    })
}

interface DecryptTextProps {
    title?: string,
    id?: any,
    data?: string,
}
const DecryptText:any = (props: DecryptTextProps) => {

    const { title, id, data } = props;
    const [decryptText, setDecryptText] = useState('');

    return <ModalForm
        title={'解密后数据'}
        width={300}
        trigger={<Button type="link" size="small" ><EyeOutlined /></Button>}
        modalProps={{
            destroyOnClose: true,
        }}
        onVisibleChange={e => {
            !!e && searchSafeCode(id || '', data || '').then(e => {
                setDecryptText(e)
            })
        }}
        preserve={false}
        onFinish={async (data: any) => true}
    >
        {title}：{decryptText}
    </ModalForm>
}
export default DecryptText;