import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './exchangeGoods.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { ExchangeGoods } from "model/product/exchangeGoods.model";

export interface ExchangeGoodsDetailsProps extends DetailsStateAndDispatchProps<ExchangeGoods, 'productStatus'>,
  RouteComponentProps, StateProps, DispatchProps {
}
const ExchangeGoodsDetails: FC<ExchangeGoodsDetailsProps> = (props) => {
  return (
    <DetailsPage<ExchangeGoods, 'productStatus'>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<ExchangeGoods, 'productStatus'>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ exchangeGoods }: RootState) => ({
  entity: exchangeGoods.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeGoodsDetails);
