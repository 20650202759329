import React, { FC, useCallback, useState, memo, useEffect } from 'react';
import { Form, Slider, Carousel, Image, Upload, Input, Button, Tooltip, Select, message, DatePicker } from 'antd';
import { PlusOutlined, FormOutlined, ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
import '../style/image_carousel.less';
import holderImg from '../../../../assets/images/public/holder.jpg';
import { uploadImage } from "utils/file-utils";
import moment from 'moment';
import { ProFormDependency, ProFormRadio, ProFormSwitch } from '@ant-design/pro-form';
const { Option } = Select;


export interface Props {
  dataField?: any
  index: number
  nextModule: any //下一模块数据
}
interface SetProps {
  dataField?: any
  id?: string
  selectedInfo?: any
  methods?: any
  otherInfo?: any
}

const ImageCarousel: FC<Props> = ({ dataField, nextModule }) => {
  if (dataField.imageList?.length > 1) {
    return (
      <div className="image-carousel-bg-con">
        {dataField?.bgImage && <div className="image-carousel-bg-image" ><img src={dataField?.bgImage} alt="" /></div>}
        <div className="image-carousel" style={{
          marginTop: dataField.margin_top || 0,
          marginBottom: dataField.margin_bottom || 0,
        }}>
          <Carousel autoplay={true} dots={{ className: 'dot-list' }}>
            {dataField.imageList.map((v: any, i: any) => {
              return <div className="img" key={i}><Image height={dataField.height || 135} preview={false} src={v?.url + '?imageView2/2/w/600'}></Image></div>
            })}
          </Carousel>
        </div>
      </div>
    )

  } else if (dataField.imageList?.length === 1) {
    return (
      <div className="image-carousel-bg-con">
        {dataField?.bgImage && <div className="image-carousel-bg-image" ><img src={dataField?.bgImage} alt="" /></div>}

        <div style={{
          marginTop: dataField.margin_top || 0,
          marginBottom: dataField.margin_bottom || 0,
        }} className={`image-carousel simple ${nextModule && nextModule.type === 'goods' && nextModule.dataField.listStyle.selected === 'slide-x' ? 'is-group' : ''}`} >
          <div className="img"><Image preview={false} src={dataField.imageList[0]?.url + '?imageView2/2/w/600'}></Image></div>
        </div>
      </div>
    )
  } else {
    return (
      <div className="image-carousel-bg-con">
        {dataField?.bgImage && <div className="image-carousel-bg-image" ><img src={dataField?.bgImage} alt="" /></div>}

        <div className="image-carousel simple holder" style={{
          marginTop: dataField.margin_top || 0,
          marginBottom: dataField.margin_bottom || 0,
        }}>
          <div className="img"><Image preview={false} src={holderImg}></Image></div>
        </div>
      </div>
    )
  }
}

interface ImageItemProps {
  id: string
  index: number
  data?: any
  updateModuleDataPrivate: (dataField: any, index: number, type?: string) => void
  setShowLinkSelectionPrivate: (show: boolean) => void
  setCallerIndex: (index: number) => void
}

const ImageItem: FC<ImageItemProps> = (({ data, id, index, updateModuleDataPrivate, setShowLinkSelectionPrivate, setCallerIndex }) => {
  const [platform] = useState([
    {
      label: '所有平台',
      value: 'all',
    },
    {
      label: '仅APP',
      value: 'app',
    },
    {
      label: '仅小程序',
      value: 'wxapp',
    },
  ]);
  const OpenType: FC<{ defaultValue: string }> = ({ defaultValue }) => {
    return (
      <Select defaultValue={defaultValue} onChange={
        (e) => {
          data.type = e
          updateModuleDataPrivate(data, index, '')
        }
      } className="select-after">
        <Option value="navigateTo">新窗口</Option>
        <Option value="switchTab">TAB切换</Option>
        <Option value="redirectTo">重定向</Option>
        <Option value="back">返回</Option>
      </Select>
    );
  }
  const uploadProps = {
    multiple: false,
    showUploadList: false,
    customRequest(e: any) {
      let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`
      uploadImage(e.file, 'minipage/' + imageName).then(async res => {
        updateModuleDataPrivate({
          ...data,
          'url': res
        }, index, '')
      }).catch(() => { message.error('图片上传失败') });
    },
  };
  return <div className={`image-item`} >
    <div className="img">
      <Upload {...uploadProps}>
        <div className="change-img" >
          <Tooltip title="更换">
            <FormOutlined />
          </Tooltip>
        </div>
      </Upload>
      <div className="operation">
        <div className="icon">{index + 1}</div>
        <div className="icon " onClick={
          () => {
            updateModuleDataPrivate(data, index, 'moveUp')
          }
        }>
          <Tooltip title="上移">
            <ArrowUpOutlined />
          </Tooltip>
        </div>
        <div className="icon" onClick={
          () => {
            updateModuleDataPrivate(data, index, 'moveDown')
          }
        }>
          <Tooltip title="下移">
            <ArrowDownOutlined />
          </Tooltip>
        </div>
        <div className="icon" onClick={
          () => {
            updateModuleDataPrivate(data, index, 'delete')
          }
        }>
          <Tooltip title="移除" >
            <DeleteOutlined />
          </Tooltip>
        </div>

      </div>
      <Image preview={true} src={data.url + '?imageView2/2/w/600'}></Image></div>
    <div className="info">
      <div className="input-group">
        <Form>
          <div className='timing' style={{ marginBottom: '-12px' }}>
            <ProFormSwitch name="timing"
              label="是否定时"
              initialValue={(data.start_time || data.end_time) ? true : false}
              fieldProps={{
                checkedChildren: '是',
                unCheckedChildren: '否',
                onChange: (e) => {
                  data.start_time = null;
                  data.end_time = null;
                  updateModuleDataPrivate(data, index, '');
                }
              }}
            />
          </div>
          <ProFormDependency name={['timing']}>
            {({ timing }) => timing && <>
              <Form.Item label="启用时间" style={{ marginBottom: '8px' }}>
                <DatePicker showTime placeholder='请选择启用时间'
                  value={data.start_time ? moment(data.start_time) : null}
                  style={{ width: '100%' }}
                  onChange={(val, dateString) => {
                    data.start_time = dateString;
                    updateModuleDataPrivate(data, index, '');
                  }}
                />
              </Form.Item>
              <Form.Item label="失效时间" style={{ marginBottom: '8px' }}>
                <DatePicker showTime placeholder='请选择失效时间'
                  value={data.end_time ? moment(data.end_time) : null}
                  style={{ width: '100%' }}
                  onChange={(val, dateString) => {
                    data.end_time = dateString;
                    updateModuleDataPrivate(data, index, '');
                  }}
                />
              </Form.Item>
            </>
            }
          </ProFormDependency>
          {platform && <ProFormRadio.Group
            label="可见平台"
            name="platform"
            initialValue={data.platform}
            options={platform}
            fieldProps={{
              onChange: (e) => {
                const val = e.target.value;
                data.platform = val;
                updateModuleDataPrivate(data, index, '');
              }
            }}
          />}
        </Form>
        <Input.Group compact className="input-item">
          <Button type="primary" onClick={
            () => {
              setCallerIndex(index)
              setShowLinkSelectionPrivate(true,)
            }
          }>选择</Button>
          <Input addonAfter={<OpenType defaultValue={data.type || 'navigateTo'} />} placeholder="请输入/选择链接地址" onChange={(e) => {
            data.link = e.target.value;
            updateModuleDataPrivate(data, index, '')
          }} value={data.link} />

        </Input.Group>
      </div>
    </div>
  </div>
})

const BgImageItem: FC<{ url: string, updateModuleData: any }> = (({ url, updateModuleData }) => {

  const uploadProps = {
    multiple: false,
    showUploadList: false,
    customRequest(e: any) {
      let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`
      uploadImage(e.file, 'minipage/' + imageName).then(async res => {
        // updateModuleDataPrivate({
        //     ...data,
        //     'url': res
        // }, index, '')
        updateModuleData({
          bgImage: res
        })
      }).catch(() => { message.error('图片上传失败') });
    },
  };
  return <div className={`image-item`} >
    <div className="img">
      <Upload {...uploadProps}>
        <div className="change-img" >
          <Tooltip title="更换">
            <FormOutlined />
          </Tooltip>
        </div>
      </Upload>

      <Image preview={true} src={url + '?imageView2/2/w/800'}></Image></div>

  </div>
})
export const ImageCarouselSet: FC<SetProps> = memo(({ dataField, id, methods, selectedInfo, }) => {

  const { Dragger } = Upload;
  const [dataFieldPrivate, setDataFieldPrivate] = useState(dataField)
  const [callerIndex, setCallerIndex] = useState(-1)
  const updateModuleDataPrivate = useCallback(
    (item:any, index:any, type:any) => {//type delete up down
      let newDataFieldPrivate = dataFieldPrivate
      if (!type) {
        newDataFieldPrivate.imageList[index] = item
      } else if (type === 'delete') {
        delete newDataFieldPrivate.imageList[index]
        newDataFieldPrivate.imageList = newDataFieldPrivate.imageList.filter((v: any) => v)
      } else if (type === 'moveDown') {
        let nextIndex = index + 1, length = newDataFieldPrivate.imageList.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.imageList);
        if (nextIndex > length - 1) {
          nextIndex = 0
        }
        nextItem = newDataFieldPrivate.imageList[nextIndex];
        newImageList[nextIndex] = item
        newImageList[index] = nextItem
        newDataFieldPrivate.imageList = newImageList
      } else if (type === 'moveUp') {
        let nextIndex = index - 1, length = newDataFieldPrivate.imageList.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.imageList);
        if (nextIndex < 0) {
          nextIndex = length - 1
        }
        nextItem = newDataFieldPrivate.imageList[nextIndex];
        newImageList[nextIndex] = item
        newImageList[index] = nextItem
        newDataFieldPrivate.imageList = newImageList
      } else if (type === 'add') {
        newDataFieldPrivate.imageList.unshift(item)
      }
      methods.updateModuleData(newDataFieldPrivate, id)
    },
    [dataFieldPrivate, methods, id]
  )
  useEffect(
    () => {
      // console.log('selectInfo Change ',selectedInfo)
      if (!selectedInfo || !selectedInfo.path) return
      let callItem = { ...dataFieldPrivate.imageList[callerIndex] }
      callItem.link = selectedInfo.path
      callItem.type = selectedInfo.openType
      updateModuleDataPrivate(callItem, callerIndex, '')
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedInfo]
  )
  useEffect(() => {
    setDataFieldPrivate(dataField)
  }, [dataField])
  const DraggerUploadProps = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    customRequest(e: any) {
      let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`

      uploadImage(e.file, 'minipage/' + imageName).then(async res => {
        let imageItem = {
          link: "",
          title: "",
          type: "",
          url: res
        }
        updateModuleDataPrivate(imageItem, 0, 'add')
      }).catch(() => { message.error('图片上传失败') });
    },
    onChange(info: any) {
      const { status } = info.file;
      if (status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }
      if (status === 'done') {
        // message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === 'error') {
        // message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e: any) {
    },
  };

  const DraggerUploadPropsBg = {
    name: 'file',
    multiple: true,
    showUploadList: false,
    customRequest(e: any) {
      let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`

      uploadImage(e.file, 'minipage/' + imageName).then(async res => {
        methods.updateModuleData({
          ...dataFieldPrivate,
          bgImage: res
        }, id)
      }).catch(() => { message.error('图片上传失败') });
    },
  };
  const updateModuleData = (params: any) => {
    methods.updateModuleData({
      ...dataFieldPrivate,
      ...params,
    }, id)
  }
  return (
    <div className="image-carousel-set" >

      <div className="bg-image-con" style={
        {
          paddingLeft: 15,
          paddingRight: 15,
        }
      } >
        <p className="title" style={{
          marginBottom: 10
        }}>轮播背景</p>
        {
          !dataFieldPrivate.bgImage && <div className="dragger-con">
            <Dragger {...DraggerUploadPropsBg} className="add-area">
              <p className="desc">
                <PlusOutlined className="icon" />
              </p>
              <p className="desc">点击/拖放以新增图片</p>
            </Dragger>
          </div>
        }

        {
          dataFieldPrivate.bgImage && <div className="image-list"><BgImageItem updateModuleData={updateModuleData} url={dataFieldPrivate.bgImage} /></div>
        }
      </div>
      <Form className="custom-form" style={
        {
          paddingLeft: 15,
          paddingRight: 15,
        }
      } initialValues={
        {
          ...dataFieldPrivate,
          margin_top: dataFieldPrivate.margin_top || 0,
          height: dataFieldPrivate.height || 135,
          margin_bottom: dataFieldPrivate.margin_bottom || 0
        }
      } onValuesChange={
        (change: any, all: any) => {
          methods.updateModuleData({
            ...dataFieldPrivate,
            ...all
          }, id)
        }
      }>
        <Form.Item label="上边距" name="margin_top">
          <Slider max={100} />
        </Form.Item>
        <Form.Item label="下边距" name="margin_bottom">
          <Slider max={100} />
        </Form.Item>
        <Form.Item label="高度" name="height">
          <Slider max={1000} />
        </Form.Item>
      </Form>
      <div className="dragger-con">
        <Dragger {...DraggerUploadProps} className="add-area">
          <p className="desc">
            <PlusOutlined className="icon" />
          </p>
          <p className="desc">点击/拖放以新增图片</p>
        </Dragger>
      </div>
      {/* <Button onClick={
                () => {
                    methods.setShowProductSelectionPrivate(true)
                }
            }>Click</Button> */}
      <div className="image-list">
        {dataFieldPrivate?.imageList.map((v: any, i: number) => {
          return v.type !== 'config' && <ImageItem
            key={i}
            id={v.id}
            index={i}
            data={v}
            updateModuleDataPrivate={updateModuleDataPrivate}
            setShowLinkSelectionPrivate={methods.setShowLinkSelectionPrivate}
            // selectedInfoPrivate={selectedInfoPrivate}
            // callerIndex={callerIndex}
            setCallerIndex={setCallerIndex}
          />
        })}
      </div>

    </div>
  )
})

export default ImageCarousel