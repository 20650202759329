import { FC, useEffect, memo, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { getPageDetail, savePageData, updateMoreState, changeLanguage, changePlatform } from "./platform-edit.reducer";
import ModulesList from '../components/modules-list';
import PagePreview from '../components/page-preview';
import ModulesSetting from '../components/modules-setting';
import { CopyOutlined } from '@ant-design/icons';
import { pageTypeMap, defaultLanguageMap, TenantMap } from 'model/decorate/decorate.model';

import { updateUseState } from '../api'

import './platform-edit.less';
import { message, Spin, PageHeader, Tag, Button, Tooltip, Modal, Switch, Radio } from 'antd';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Storage } from 'utils/storage-util';

export interface DecorateEditProps extends StateProps, DispatchProps, RouteComponentProps {
  id?: string
}

const DecorateEdit: FC<DecorateEditProps> = memo(({ id, getPageDetail, pageData, pageConfig, loading, savePageData, platform, pageLoading, updateMoreState, changeLanguage, language, pageConfigGlobal, changePlatform }) => {
  let [configModule, setConfigModule] = useState<{ [Key: string]: any }>({})
  // let [platform, setPlatform] = useState('taobuting');
  useEffect(() => {
    // let storagePlatform = platform.localStorage.getItem('platform'), platform;
    let Tenant: string = Storage.session.get('x-tenant') || 'tbt', newPlatform = TenantMap[Tenant] || 'taobuting';
    // setPlatform(TenantMap[Tenant] || 'taobuting')

    changePlatform(newPlatform)
    changeLanguage(defaultLanguageMap[newPlatform][0].language)
    if (!platform && !newPlatform) return
    if (!id) {
      const title = '自定义页面-' + (+new Date()),
        pageConfig = [{
          type: 'config',
          id: 'config_0',
          dataField: {
            bgColor: '#f9f9f9',
            title
          }
        }]
      updateMoreState({
        pageConfig,
        pageData: {
          pageType: 999,
          title: title,
          pageConfig,
          isUse: '0'
        },
        pageConfigGlobal: {
          [defaultLanguageMap[platform || newPlatform][0].language]: pageConfig
        }
      })
      return
    }
    getPageDetail(id || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps

    // eslint-disable-next-line react-hooks/exhaustive-deps
    return () => {
      updateMoreState({
        insertPositionId: 'none',
        pageConfig: [],
        pageConfigGlobal: {},
        pageData: { type: 0, pageConfig: [] },
        loading: false,
        pageLoading: false,
        settingModuleId: 'none',
        popupData: {},
        language: 'zh-CN',
        platform: ''
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getPageDetail])
  useEffect(() => {
    let configModule: any = pageConfig.filter((v: any) => v.type === 'config')
    if (configModule && configModule[0]) {
      setConfigModule(configModule[0].dataField)
    }
  }, [pageConfig, updateMoreState])
  //改变页面使用状态
  const changeUseState = (params: { used: boolean, id: string | number }) => {
    Modal.confirm({
      title: '提示',
      content: `确认${params.used === true ? '使用' : '取消使用'}“${pageData.title}”页？`,
      onOk() {
        // let newTitle = pageData.title+' Copy'
        // pageConfig.forEach((v:any) => {
        //   if(v.type === 'config'){
        //     v.dataField.title = newTitle
        //   }
        // })
        // savePageData({
        //   ...pageData,
        //   pageConfig:pageConfig,
        //   id:'',
        //   title:newTitle
        // })
        updateUseState({
          id: params.id,
          used: params.used
        }).then((res: any) => {
          if (res.data) {
            updateMoreState({
              pageData: {
                ...pageData,
                used: params.used,
              }
            })
            message.success(`页面${!params.used ? '取消使用' : '使用'}成功`);
          }
        })
      }
    });
  }
  //复制页面
  const copyPage = () => {

    Modal.confirm({
      title: '提示',
      content: `确认复制“${configModule.title}”页？`,
      onOk() {
        let newTitle = configModule.title + ' Copy'
        pageConfig.forEach((v: any) => {
          if (v.type === 'config') {
            v.dataField.title = newTitle
          }
        })

        // return
        savePageData({
          ...pageData,
          pageConfig: pageConfig,
          pageConfigGlobal,
          id: '',
          title: newTitle,
          platform:platform
        })
      }
    });
  }

  //语言切换
  const handleLanguageChange = (e: any) => {
    changeLanguage(e.target.value)
  }
  //标签列表
  const tagsList = () => {
    // console.log('pageData',pageData)
    let tags = [
      <Tag color="green">{pageTypeMap[pageData.pageType] ? pageTypeMap[pageData.pageType].title : '未知页面类型'}</Tag>,
      pageData.used === true ? <Tag color="green">已使用</Tag> : <Tag color="red">未使用</Tag>,
      id ? <Switch checkedChildren="启用" onChange={
        (e) => {
          changeUseState({
            used: e,
            id: id
          })
        }
      } unCheckedChildren="关闭" checked={pageData.used} /> : <span></span>,
      <Tooltip title="复制" >
        {/* <Button size="small" icon={} /> */}
        <CopyOutlined style={
          {
            marginLeft: '4px',
            marginTop: '4px',
            fontSize: '16px',
            color: '#444',
            cursor: 'pointer'
          }
        } onClick={copyPage} />
      </Tooltip>,
      <Radio.Group onChange={handleLanguageChange} value={language} style={{ marginLeft: 20 }}>
        {
          defaultLanguageMap[platform].length > 1 && defaultLanguageMap[platform].map((v: any) => {
            return <Radio.Button value={v.language}>{v.name}</Radio.Button>
          })
        }

      </Radio.Group>
    ]
    return tags
  }

  return (
    <div className="decorate-edit-container">
      {
        !loading && platform &&
        <PageHeader className="page-header"
          title={configModule && configModule.title && (configModule && configModule.title.length <= 18 ? configModule.title : configModule.title.slice(0, 18) + '...')}
          // subTitle="This is a subtitle"
          tags={configModule ? tagsList() : []}
          extra={configModule ? [
            id ? <Button key="2" onClick={
              () => {
                getPageDetail(pageData.id)
              }
            }>刷新</Button> : "",
            <Button key="1" type="primary" onClick={
              () => {
                savePageData({
                  ...pageData,
                  pageConfig: pageConfig,
                  pageConfigGlobal: pageConfigGlobal,
                  platform: platform
                })
              }
            }>保存</Button>,
          ] : []}
        ></PageHeader>
      }
      {
        pageLoading && <div className="page-loading"><Spin size="large" /></div>
      }
      {loading ? <div className="loading"><Spin size="large" /></div>
        : (
           <DndProvider backend={HTML5Backend}><div className="decorate-container" >
          <ModulesList type="all" />
          <PagePreview />
          <ModulesSetting />
        </div></DndProvider>)}
    </div>);
});

const mapStateToProps = ({ decorateEdit }: RootState) => ({
  pageData: decorateEdit.pageData,
  pageConfig: decorateEdit.pageConfig,
  pageConfigGlobal: decorateEdit.pageConfigGlobal,
  language: decorateEdit.language,
  platform: decorateEdit.platform,
  pageLoading: decorateEdit.pageLoading,
  loading: decorateEdit.loading
});

const mapDispatchToProps = {
  getPageDetail,
  savePageData,
  updateMoreState,
  changeLanguage,
  changePlatform,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DecorateEdit);
