import {BaseModel, DisableModel} from 'model/base.model';

export interface Level extends BaseModel, DisableModel {
  id?: number | string;
  levelNo?: number;
  levelName?: string;
  levelType?: string;
  canBusinessEnter?: boolean;
  isCenter?: boolean;
  paymentType?: string
}

export const defaultLevel: Readonly<Level> = {
};
