import { ModalForm, ProFormText } from "@ant-design/pro-form";
import { useState } from "react";
import { Col, Form, Row, message, Button, Table, Select } from "antd";
import SelectSearch from "components/select-search";
import {
  fetchCustomerList,
  getGoodByLogisticsCode,
  addBarcodeChangeRecord,
} from "./logisticscode.reducer";

const RechargeModel = (props: any) => {
  let { visible, onCancel, onRecharge } = props;
  const [form] = Form.useForm();
  let [codeTableList, setCodeTableList] = useState<any>([]);
  let [selectedCodeList, setSelectedCodeList] = useState<any>([]);
  let [changePersonVisible, setChangePersonVisible] = useState<any>(false);
  let [searchParams, setSearchParams] = useState<any>("account.equal");
  let [changePersonInfo, setChangePersonInfo] = useState<any>();

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 7 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 17 },
    },
  };

  // 确认正确 confirm
  const confirmCorrectness = () => {
    let fromData = form.getFieldsValue();
    if (!fromData.barcode) {
      message.error("请输入物流码");
      return false;
    }
    getGoodByLogisticsCode({
      barcode: fromData.barcode,
    })
      .then((e: any) => {
        let data = [...e.data];
        data = data.map((item: any) => {
          return {
            ...item,
            barcode: fromData.barcode,
          };
        });
        let arrObj: any = codeTableList.concat(data);
        const map = new Map();
        const newArr = arrObj.filter(
          (v: any) => !map.has(v.scode) && map.set(v.scode, 1)
        );
        setCodeTableList(newArr || []);
      })
      .catch((e: any) => {
        message.error(e.response.data.message);
      });
  };

  // 是否禁用
  const getDisabled = (scode: string) => {
    let isDisabled =
      selectedCodeList.findIndex((item: any) => item.scode === scode) > -1;
    return isDisabled;
  };

  const columns: any = [
    {
      title: "物流码",
      dataIndex: "scode",
    },
    {
      title: "归属授权码",
      dataIndex: "customerCode",
    },
    {
      title: "归属姓名",
      dataIndex: "customerName",
    },
    {
      title: "操作",
      dataIndex: "payeeCode",
      width: 140,
      render: (text: string, row: any) => {
        return (
          <Button
            type="primary"
            className="selectd-class"
            disabled={getDisabled(row.scode)}
            onClick={() => {
              let arr = Array.from(new Set([...selectedCodeList, row]));
              setSelectedCodeList(arr);
              setTimeout(() => {
                console.log(selectedCodeList);
              }, 1000);
            }}
          >
            选择
          </Button>
        );
      },
    },
  ];

  const selectedColumns: any = [
    {
      title: "物流码",
      dataIndex: "scode",
    },
    {
      title: "归属授权码",
      dataIndex: "customerCode",
    },
    {
      title: "归属姓名",
      dataIndex: "customerName",
    },
    
    {
      title: "操作",
      dataIndex: "payeeCode",
      width: 140,
      render: (text: string, row: any) => {
        return (
          <Button
            className="selectd-class"
            onClick={() => {
              let data = selectedCodeList.filter(
                (item: any) => item.scode !== row.scode
              );
              console.log(data);
              setSelectedCodeList(data);
            }}
          >
            删除
          </Button>
        );
      },
    },
  ];

  return (
    <>
      <ModalForm
        title="新增物流码变更"
        visible={visible}
        onVisibleChange={(e) => {
          if (!e) {
            setCodeTableList([]);
            setSelectedCodeList([]);
            onCancel();
            form.setFieldValue("barcode", "");
          }
        }}
        width={1000}
        form={form}
        modalProps={{
          okText: "选择变更人",
        }}
        {...formItemLayout}
        layout="horizontal"
        preserve={false}
        onFinish={async (data: any) => {
          console.log(setSelectedCodeList + "数量");
          if (selectedCodeList.length === 0) {
            message.error("请选择至少一项变更物流码");
            return false;
          } else {
            setChangePersonVisible(true);
          }
        }}
      >
        <Row>
          <ProFormText
            width={"sm"}
            required={true}
            name="barcode"
            label="请输入物流码"
            placeholder="请输入物流码"
          />
          <Button
            type="primary"
            style={{ marginLeft: "-90px" }}
            onClick={confirmCorrectness}
          >
            搜索
          </Button>
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Table
              scroll={{ y: 600 }}
              pagination={false}
              columns={columns}
              dataSource={codeTableList}
            />
          </Col>
        </Row>
        <Row style={{ marginBottom: "12px", fontWeight: "bold" }}>
          已选择物流码
        </Row>
        <Row style={{ marginBottom: "20px" }}>
          <Col span={24}>
            <Table
              scroll={{ y: 600 }}
              pagination={false}
              columns={selectedColumns}
              dataSource={selectedCodeList}
            />
          </Col>
        </Row>
      </ModalForm>

      <ModalForm
        title="选择变更人"
        form={form}
        width={500}
        onVisibleChange={(e) => !e && setChangePersonVisible(false)}
        visible={changePersonVisible}
        modalProps={{
          destroyOnClose: true,
        }}
        preserve={false}
        onFinish={async (data) => {
          console.log(selectedCodeList);
          let barcodeList = selectedCodeList.map((item: any) => {
            return {
              barcode: item.barcode,
              scode: item.scode,
              lcode: item.lcode,
              agent: item.agent,
            };
          });
          let parames = {
            itemCode: selectedCodeList[0].skuCode,
            belongToCode: selectedCodeList[0].customerCode,
            belongToName: selectedCodeList[0].customerName,
            changeCode: changePersonInfo.account,
            changeName: changePersonInfo.name,
            changeTime: new Date().toLocaleString(),
            barcodeList: barcodeList,
          };
          console.log(parames);
          console.log("哈哈哈");

          addBarcodeChangeRecord(parames)
            .then((e: any) => {
              // 变更成功
              onRecharge();
              setCodeTableList([]);
              setSelectedCodeList([]);
              setChangePersonVisible(false);
              return true;
            })
            .catch((e: any) => {
              message.error(e.response.data.message);
            });
        }}
      >
        <Row className="rowCenter">
          <Form.Item rules={[{ required: false }]}>
            <Select
              style={{ width: 120 }}
              options={[
                {
                  value: "account.equals",
                  label: "授权号搜索",
                },
                {
                  value: "name.contains",
                  label: "姓名搜索",
                },
              ]}
              onSelect={(e) => {
                console.log(e);
                setSearchParams(e);
              }}
              placeholder="请选择"
            />
          </Form.Item>
          <SelectSearch
            width={300}
            required={false}
            name="brandId.equals"
            fetchOptionObj={(option: any) => {
              console.log(option);
              setChangePersonInfo(option);
            }}
            placeholder="变更人"
            labelValue="label"
            defaultParams=""
            fetchFunc={fetchCustomerList}
            searchParams={searchParams}
          />
        </Row>
        <Row className="tip">确认变更后，商品归属权将调整为新的变更人</Row>
      </ModalForm>
    </>
  );
};

export default RechargeModel;
