import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./order-cancel.reducer";
import { Form } from "antd";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import IntelFormText from "components/IntelFormText";
import { OrderCancel } from "model/procure/order-cancel.model";
import { ProFormText } from "@ant-design/pro-form";
export interface OrderCancelEditProps extends EditStateAndDispatchProps<OrderCancel>, StateProps, DispatchProps, RouteComponentProps {
}

const OrderCancelEdit: FC<OrderCancelEditProps> = props => {
  const { entity } = props;

  const [form] = Form.useForm();
  return (
    <EditPage<OrderCancel>
      title="订单取消原因"
      form={form}
      {...props}
    >
      <ProFormText
        label={'编码'}
        name="code"
        rules={[{
          required: true,
          pattern: RegExp(/^[a-zA-Z0-9_]+$/i),
          message: '仅限输入英文数字下划线，不可有空格及符号'
        }]}
      />
      <IntelFormText
        label={'订单取消原因'}
        name="desc"
        required={true}
        form={form}
        fieldProps={{
          maxLength: 100
        }}
        value={entity.desc || ''}
      />
    </EditPage>);
};

const mapStateToProps = ({ orderCancel }: RootState) => ({
  entity: orderCancel.entity,
  updateSuccess: orderCancel.updateSuccess,
  updating: orderCancel.updating,
  createSuccess: orderCancel.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OrderCancelEdit);
