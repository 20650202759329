import { FC, ReactNode, useState } from "react";
import { Form, Input, Image, Tooltip, message, Button, Row, Col } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import ExcellentStoreDetails from "./excellentStore-details";
import { deleteEntity, downgrading, getEntities, getEntity, statusList, statusMap } from "./excellentStore.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ToExamineDialog from '../toExamineDialog'
import { auditRefuse } from ".//excellentStore.reducer";
import { ModalForm, ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { fetchStoreList } from "pages/store/store/store.reducer";
import { ExcellentStore } from "model/dealerStore/excellentStore.model";

export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const columns: ProColumns<ExcellentStore,'storeLable'>[] = [{
  title: 'ID',
  dataIndex: 'id',
  width: 100,
  ellipsis: true,
}, {
  title: '姓名',
  dataIndex: 'userName',
  width: 100
},
// {
//   title: '性别',
//   dataIndex: 'sex',
//   width: 50,
//   render: (text, record: any) => {
//     return record.sex === 1 ? '男' : '女'
//   }
// },
{
  title: '电话号码',
  dataIndex: 'phone',
  width: 100
},
{
  title: '授权码',
  dataIndex: 'authorizationCode',
  width: 100
},
{
  title: '降级标记',
  dataIndex: 'storeLabel',
  width: 100,
  valueType:'storeLable'
},
{
  title: '门店名称',
  dataIndex: 'storeName',
  width: 100
},
{
  title: '店铺地址',
  dataIndex: 'address',
  width: 120,
  render: (text, record: any) => {
    return record.address?.provinceName + record.address?.cityName + record.address?.districtName + record.address?.address
  }

},

{
  title: '门头照',
  dataIndex: 'doorHeadPictureUrl',
  width: 70,
  render: ((res, record) => {
    const picture = record.doorHeadPictureUrl ? <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.doorHeadPictureUrl} /> : '-';
    return picture
  })
},
{
  title: '店铺内部整体照',
  dataIndex: 'storePictureUrl',
  width: 120,
  render: ((res, record) => {
    const picture = record.storePictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.storePictureUrl} />;
    return picture || '-'
  })
},
{
  title: '店铺内展柜灯膜特写照（本人出镜）',
  dataIndex: 'myExitPictureUrl',
  hideInTable: true,
  render: ((res, record) => {
    const picture = record.myExitPictureUrl && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.myExitPictureUrl} />;
    return picture || '-'
  })
},
{
  title: '近半年组织的培训',
  dataIndex: 'trainRecords',
  hideInTable: true,
  ellipsis: true,
  render: ((res, record) => {
    return record.trainRecords?.length > 0 && record.trainRecords.map((item: any, index: any) => {
      return <div> <p>{(index + 1)}、培训方式: {item.way}  会议名称: {item.meetName} <br /> </p></div>
    })
  })
},
{
  title: '美人计下单额',
  dataIndex: 'mrjHalfYearPickupAmount',
  width: 120
},
{
  title: '黑天鹅下单额',
  dataIndex: 'hteHalfYearPickupAmount',
  width: 120
},
{
  title: '中康下单额',
  dataIndex: 'zkHalfYearPickupAmount',
  width: 120
},
{
  title: '朱莉安娜女王下单额',
  dataIndex: 'nwHalfYearPickupAmount',
  width: 120
},
{
  title: '会员商城下单额',
  dataIndex: 'mallHalfYearPickupAmount',
  width: 120
},
{
  title: '申请时间',
  dataIndex: 'applyTime',
  width: 120,
},
{
  title: '状态',
  dataIndex: 'status',
  width: 80,
  valueEnum: statusMap,
},
{
  title: '审核人',
  dataIndex: 'auditUserName',
  width: 100,
  ellipsis: true
},
{
  title: '审核时间',
  dataIndex: 'auditTime',
  width: 120,
}, {
  title: '审核备注',
  dataIndex: 'auditComments',
  width: 100,
  ellipsis: true
}
];


const LevelList: FC<LevelListProps> = (props) => {
  const [refreshFlag, setRefreshFlag] = useState(0)
  let [key, setKey] = useState<any>(0);
  const { entities } = props;
  const searchFields: SearchField[] = [{
    label: '用户名',
    name: 'userName',
    operator: 'equals',
  }, {
    label: '手机号',
    name: 'phone',
    operator: 'equals',
  }, {
    label: '授权码',
    name: 'authorizationCode',
    operator: 'equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          request={async () => {
            const data: any = await fetchStoreList();
            return data.map((item: any) => ({
              value: item.id,
              label: item.name
            }))
          }}
          name={['storeId.equals']}
          placeholder="店铺"
        />
        <div className="wd10" />
        <ProFormSelect
          name="status.equals"
          options={statusList}
          placeholder="状态"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="applyTime.greaterThanOrEqual"
          placeholder="申请时间开始时间"
        />
        <ProFormDateTimePicker
          name="applyTime.lessThanOrEqual"
          placeholder="申请时间结束时间"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="auditTime.greaterThanOrEqual"
          placeholder="审核开始时间"
        />
        <ProFormDateTimePicker
          name="auditTime.lessThanOrEqual"
          placeholder="审核结束时间"
        />
        <div className="wd10"></div>
        <SearchBox fields={searchFields} />
      </Input.Group>
    </Form.Item>
  );
  const [open, setOpen] = useState(false);
  const [record, setRecord]: any = useState({});

  //打开审核弹框
  const showModal = async (record: any) => {
    setRecord({ ...record })
    setOpen(true);
  };

  //审核
  const toExamineButtonRender: any = (dom: ReactNode, record: any): ReactNode => {
    return (
      record.status === 'Created' || record.status === 'Checked' ? <Tooltip title="审核">
        <Button size="small" type='link' onClick={() => showModal(record)} >审核</Button>
      </Tooltip> : ''
    )
  };

  //关闭审核弹框
  const hideModal = () => {
    setOpen(false);
    setRecord({})
  };

  const exportData = (data: ExcellentStore[]) => {
    let entities: ExcellentStore[] = [];
    data.forEach((e: any) => {
      const address = e.address?.provinceName + e.address?.cityName + e.address?.districtName + e.address?.address;
      let arr = {
        ...e,
        address,
      };
      entities.push(arr);
    });
    return entities;
  };

  const tableAlertOptionRender = (res: any) => {
    const ids = res.selectedRowKeys;
    return (
      <ModalForm
        trigger={<Button className="operation-btn" type="primary">添加降级标记</Button>}
        title='添加降级标记'
        modalProps={{
          destroyOnClose: true,
        }}
        onFinish={async (e) => {
          res.onCleanSelected();
          return new Promise<boolean>((resolve, reject) => {
            resolve(false)
            downgrading({
              isOut: e.isOut === 'true' ? true : false,
              ids,
              "storeType":"ExcellentPhysicalStore"
            },'excellent-physical-apply').then((data: any) => {
              message.success('操作成功');
              setRefreshFlag(new Date().getTime());
              resolve(true);
            }).catch(err => {
              message.error(err.response.data.title);
              reject(false);
            })
          });
        }}
      >
        <Row>
          <Col span="12">
            <ProFormSelect
              label="降级标记"
              rules={[{ required: true }]}
              name={'isOut'}
              options={[{
                label:'降级',
                value:'true'
              },{
                label:'无',
                value:'false'
              }]}
            />
          </Col>
        </Row>
      </ModalForm>
    );
  }

  return (
    <>
      <ListViewPage<ExcellentStore,'storeLable'>
        scrollConfig={{ x: 1500 }}
        columns={columns}
        refreshFlag={refreshFlag}
        key={key}
        hideDeleteButton
        permanentParams={{
          'applyType.equals': 'ExcellentPhysicalStore'
        }}
        showDownload
        downloadAllEntities={exportData}
        downloadColumns={columns.concat().map(e => {
          return {
            ...e,
            render: () => ''
          }
        })}
        tableAlertOptionRender={tableAlertOptionRender}
        rowSelection={{
          type: 'checkbox',
        }}
        downloadEntities={exportData(entities)}
        rowActions={[toExamineButtonRender]}
        hideCreateButton
        hideEditButton
        quickFilterPanel={quickFilterPanel}
        detailPage={<ExcellentStoreDetails navigate={props.navigate} columns={columns} />}
        detailsIndex={0}
        {...props}
      >
        {props.children}
      </ListViewPage>

      {/* 审核 */}
      <ToExamineDialog visible={open} record={record} navigate={props.navigate} entity={record} columns={columns} onCancel={() => {
        hideModal();
      }} onRecharge={(data: any) => {
        auditRefuse(data).then((e: any) => {
          message.success('操作成功');
          setKey(++key)
          hideModal();
          return true;
        }).catch((e: any) => {
          message.error(e.response.data.message);
          return false
        })
      }} />


    </>
  );
};

const mapStateToProps = ({ excellentStore }: RootState) => ({
  entity: excellentStore.entity,
  entities: excellentStore.entities,
  loading: excellentStore.loading,
  updateSuccess: excellentStore.updateSuccess,
  createSuccess: excellentStore.createSuccess,
  total: excellentStore.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelList);