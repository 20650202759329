import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { Exchange, defaultExchange } from 'model/procure/exchange.model';
import axios from 'axios';

const entity = 'Exchange';
const apiUrl = '/api/yumei-mall-purchase/exchange-order';
const standardReducerImpl = new StandardReducerImpl<Exchange>(entity, defaultExchange, apiUrl);
export type ExchangeState = StandardState<Exchange>;
export const exchange = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;



export const auditExchange = async (data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-purchase/exchange-order/${data.id}/audit`, data);
    return axiosResponse.data;
}

export const backHeYan = async (data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-purchase/exchange-order/${data.id}/toAudit`, data);
    return axiosResponse.data;
}

export const InspectionStorage = async (orderId: any, data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-purchase/exchange-product-inspection/inspection/${orderId}`, data);
    return axiosResponse.data;
}

export const ReceivingSGoods = async (orderId: any, data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-purchase/exchange-order/${orderId}/receive`, data);
    return axiosResponse.data;
}
export const cancelOrderEXC = async (orderId: any, data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-purchase/exchange-order/${orderId}/cancel`, data);
    return axiosResponse.data;
}

export const sendOutGoodsFunC = async (orderId: any, data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-purchase/exchange-order/${orderId}/shipped`, data);
    return axiosResponse.data;
}

export const editAddress = async (data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-purchase/exchange-order/update-address/${data.id}`, data);
    return axiosResponse.data;
}

export const fetchVerificationList = async (orderId?: any) => {
    const axiosResponse = await axios.get(`/api/yumei-mall-purchase/exchange-product-inspection/find-by-order-id/${orderId}`);
    return axiosResponse.data;
}

export const fetchLogList = async (params?: any) => {
    const axiosResponse = await axios.get(`/api/yumei-mall-purchase/exchange-order-log`,{params});
    return axiosResponse.data;
}

export const warehousingAndShipping = async (id?: any) => {
    const axiosResponse = await axios.get(`/api/yumei-mall-purchase/exchange-order/${id}/storage-ship`);
    return axiosResponse.data;
}


export const editLogistics = async (id: any, expressNo: any, data: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-purchase/exchange-order/update-logistics/${id}/${expressNo}`, data);
    return axiosResponse.data;
}

export const queryPrices = async (data: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-pim/purchase-product/sku/purchase-price/sku-by-ids`, data);
    return axiosResponse.data;
}


