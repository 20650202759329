import { ContractTemplate, defaultContractTemplate } from 'model/contractAdmi/contractTemplate.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'contractTemplate';
const apiUrl = '/api/yumei-mall-customer/contract-type';
const standardReducerImpl = new StandardReducerImpl<ContractTemplate>(entity, defaultContractTemplate, apiUrl);
export type ContractTemplateState = StandardState<ContractTemplate>;
export const contractTemplate = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;