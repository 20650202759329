import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './brandLevel.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { BrandLevel } from "model/dealer/brandLevel.model";

export interface BrandLevelDetailsProps extends DetailsStateAndDispatchProps<BrandLevel>,
  RouteComponentProps, StateProps, DispatchProps {
}
const BrandLevelDetails: FC<BrandLevelDetailsProps> = (props) => {
  return (
    <DetailsPage<BrandLevel>
      title={'级别详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<BrandLevel>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ dealerBrandLevel }: RootState) => ({
  entity: dealerBrandLevel.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrandLevelDetails);
