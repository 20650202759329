import { BaseModel } from "../base.model";
import { Role } from "./role.model";

export interface Resources extends BaseModel {
  code?: string;
  description?: string;
  id?: number;
  module?: Module;
  permissions?: Permissions[];
  scopes?: any;
  urls?: any;
}

interface Permissions {
  id?: number;
  restfulUrl?: any;
  rights?: number;
  role?: Role;
}

interface Module {
  code?: string;
  name?: string;
}



export const defaultResources: Readonly<Resources> = {
};