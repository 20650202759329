import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDateTimePicker, ProFormDependency, ProFormRadio, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity, getMaterialPublisher, getMaterialCategory } from "./post.reducer";
import { Post } from "model/video/post.model";
import { Button, Col, Form, message, Row, Cascader } from 'antd';
import UploadImgComplex from "components/upload-img/upload-img-complex";
import Title from "antd/lib/typography/Title";
import { uploadImage } from "utils/file-utils";
import IntelFormText, { internationalList } from "components/IntelFormText";
import { ProFormSwitch } from "@ant-design/pro-form";
import ProductListEditControl from "pages/pim/product/product-list-edit-control";
import { searchProductList } from "pages/pim/product/product.reducer";

export interface PostEditProps extends EditStateAndDispatchProps<Post>, StateProps, DispatchProps, RouteComponentProps {
}
let pubUser: any = {};
const PostEdit: FC<PostEditProps> = (props) => {
  const { entity } = props;
  const [picturesArr, setPicturesArr] = useState([]);
  const [videosArr, setVideosArr] = useState([]);
  const [entityData, setEntityData] = useState<any>();
  const [categoryList, setCategoryList] = useState<any>([]);
  const [pubUserList, setPubUserList] = useState<any>({});
  // const [pubUser, setPubUser] = useState<any>({});
  const [form] = Form.useForm();

  // 获取发布人列表
  const getMaterPublc = async () => {
    const data: any = await getMaterialPublisher();
    let rdata: any = []
    data.forEach((item: any) => (
      rdata.push({
        value: internationalList(item?.name) || '',
        label: internationalList(item?.name) || '',
        pubCode: item.code,
        pubNickName: internationalList(item?.name),
        pubAvatar: item.avatar
      })))
    setPubUserList(rdata)
  }


  // 重写素材分类
  const recategoryList = (chiden: any) => {
    if (chiden && chiden.length > 0) {
      chiden.forEach((item: any) => {
        item.name = internationalList(item?.name)
      });
      return chiden
    }
  }

  // 获取素材分类列表
  const getMaterialCategoryList = async () => {
    getMaterialCategory().then((data) => {
      let listData: any = []
      data.forEach((item: any) => (
        listData.push({
          ...item,
          value: item.id,
          label: internationalList(item?.name),
          name: internationalList(item?.name),
          children: recategoryList(item.children)
        })))
      setCategoryList(listData)
    })
  }

  // 回显素材分类
  const scCategoryList = (id: number) => {
    let one = ''
    categoryList.forEach((item: any) => {
      item?.children && item?.children.forEach((sonitem: any) => {
        if (sonitem.id === id) {
          one = item.id
        }
      })
    })
    return one ? [one, id] : [id]
  }

  useEffect(() => {
    pubUser = {};
    getMaterPublc();
    getMaterialCategoryList();
    // 初始化数据
    setEntityData({
      publicityType: 'Pictures',
      videoUploadMethod: 'upload'
    })
    if (entity.id) {
      setPicturesArr(entity.pictures);
      setVideosArr(entity.videos as any);
      const entityCopy = JSON.parse(JSON.stringify(entity));
      let pictures = '';
      let videos = '';
      if (entity.pictures.length > 0) {
        var picArray = entity.pictures.map((item: any) => {
          return item.pictureUrl;
        })
        pictures = picArray.join(',')
      }
      if (entity.videos.length > 0) {
        entityCopy.coverUrl = entity.videos[0].coverUrl
        var videoArray = entity.videos.map((item: any) => {
          return item.mediaUrl;
        })
        videos = videoArray.join(',')

      }
      entityCopy.products = entityCopy.products.concat().map((e: any) => {
        return e.productId
      })
      entityCopy.pictures = pictures;
      entityCopy.categoryList = scCategoryList(entityCopy.category.id)
      entityCopy.videos = videos;
      entityCopy.videolink = videos
      entityCopy.videoUploadMethod = 'upload'
      setEntityData(entityCopy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const downImages = () => {
    const imgArr = entityData.pictures.split(',');
    let arr = [];
    const funC = () => {
      const newDate = new Date().getTime();
      downPngLoadImage(imgArr[arr.length], newDate);
      arr.push(0);
      imgArr.length > arr.length && funC();
    }; funC();
  };

  const downPngLoadImage = (imgSrc: string, name: number) => {
    //下载图片地址和图片名
    let image = new Image();
    // 解决跨域 Canvas 污染问题
    image.setAttribute("crossOrigin", "anonymous");
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;
      let context: any = canvas.getContext("2d");
      context.drawImage(image, 0, 0, image.width, image.height);
      let url = canvas.toDataURL("image/png"); //得到图片的base64编码数据
      let a = document.createElement("a"); // 生成一个a元素
      let event = new MouseEvent("click"); // 创建一个单击事件
      a.download = "素材图片" + name; // 设置图片名称
      a.href = url; // 将生成的URL设置为a.href属性
      a.dispatchEvent(event); // 触发a的单击事件
    };
    image.src = imgSrc;
  };

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<Post>((resolve, reject) => {
      if (res) {
        res.type = 'material';
        if (pubUser.pubNickName) {
          res.pubCode = pubUser.pubCode;
          res.pubNickName = pubUser.pubNickName;
          res.pubAvatar = pubUser.pubAvatar;
        }
        res.products = res.products ? res.products.map((e: any) => {
          return {
            productId: e
          }
        }) : []
        res.category = {
          id: res.categoryList && res.categoryList?.length > 0 ? res.categoryList[res.categoryList.length - 1] : ''
        };
        if (res?.videos?.length === 0 && res?.pictures?.length === 0) {
          message.error('图片和视频必须上传一项！');
          reject();
        };

        //素材视频封面
        const p3 = new Promise((resolve, reject) => {
          const videosData: any = res.coverUrl?.concat();
          if (videosData && videosData.length > 0 && res.publicityType === "Video") {
            let coverUrlData: any = '', successList: any = [];
            // 视频已更新或需上传 递归解决上传
            const uploadCoverUrl = () => {
              const newDate = new Date().getTime();
              if (videosData[successList.length].originFileObj) {
                uploadImage(videosData[successList.length].originFileObj, 'material/img-' + newDate + '.jpg').then((imageName: any) => {
                  successList.push(1);
                  coverUrlData = imageName
                  if (successList.length === videosData.length) {
                    res.coverUrl = coverUrlData;
                    resolve(true)
                  } else {
                    uploadCoverUrl();
                  }
                }).catch(() => { message.error('视频上传失败'); reject() });
              } else {
                res.coverUrl = videosData[0].url;
                resolve(true)
              }
            }; uploadCoverUrl();
          } else {
            // 无封面
            res.coverUrl = '';
            resolve(true)
          }
        })


        //素材视频上传
        const p1 = new Promise((resolve, reject) => {
          const videosData: any = res.videos?.concat();
          if (videosData && videosData.length > 0 && res.publicityType === "Video" && res.videoUploadMethod === 'upload') {
            let videosText: any = [], successList: any = [];
            // 视频已更新或需上传 递归解决上传
            const uploadVideos = () => {
              const newDate = new Date().getTime();
              if (videosData[successList.length].originFileObj) {
                uploadImage(videosData[successList.length].originFileObj, 'material/video-' + newDate + '.mp4').then((imageName: any) => {
                  successList.push(1);
                  videosText.push({
                    mediaUrl: imageName,
                    type: 'video'
                  });
                  if (successList.length === videosData.length) {
                    res.videos = videosText;
                    resolve(true)
                  } else {
                    uploadVideos();
                  }
                }).catch(() => { message.error('视频上传失败,请压缩后再上传'); reject() });
              } else {
                successList.push(1);
                const id: any = (videosArr.filter((e: any) => e.mediaUrl === videosData[successList.length - 1].url)[0] as any).id;
                videosText.push({
                  mediaUrl: videosData[successList.length - 1].url,
                  type: 'video',
                  id
                });
                if (successList.length === videosData.length) {
                  res.videos = videosText;
                  resolve(true)
                } else {
                  uploadVideos();
                }
              }
            }; uploadVideos();
          } else if (res.publicityType === "Video" && res.videoUploadMethod === 'videolink' && res.videolink) {
            res.videos = [{
              mediaUrl: res.videolink,
              type: 'video',
            }]
            resolve(true)
          } else {
            // 无视频
            res.videos = [];
            resolve(true)
          }
        })


        //素材图片上传
        const p2 = new Promise((resolve, reject) => {
          const picturesData: any = res.pictures?.concat();
          if (picturesData && picturesData.length > 0 && res.publicityType === "Pictures") {
            let picturesText: any = [], successList: any = [];
            // 配图已更新或需上传 递归解决上传
            const uploadPictures = () => {
              const newDate = new Date().getTime();
              if (picturesData[successList.length].originFileObj) {
                uploadImage(picturesData[successList.length].originFileObj, 'material/img-' + newDate).then(imageName => {
                  successList.push(1);
                  picturesText.push({ pictureUrl: imageName, type: 'picture' });
                  if (successList.length === picturesData.length) {
                    res.pictures = picturesText;
                    resolve(true)
                  } else {
                    uploadPictures();
                  }
                }).catch(() => { message.error('图片上传失败'); reject() });
              } else {
                successList.push(1);
                const id = (picturesArr.filter((e: any) => e.pictureUrl === picturesData[successList.length - 1].url)[0] as any).id;
                picturesText.push({ pictureUrl: picturesData[successList.length - 1].url, type: 'picture', id });
                if (successList.length === picturesData.length) {
                  res.pictures = picturesText;
                  resolve(true)
                } else {
                  uploadPictures();
                }
              }
            }; uploadPictures();
          } else {
            // 无配图
            res.pictures = [];
            resolve(true)
          }
        })

        Promise.all([p1, p2, p3])
          .then(result => {
            let data = { ...res }
            if (data.coverUrl) {
              data.videos = [{
                ...data.videos[0],
                coverUrl: data.coverUrl
              }]
            }
            if (data.publicityType === 'Pictures') {
              data.videos = []
            } else if (data.publicityType === 'Video') {
              data.pictures = []
            }

            resolve(data)
          })
          .catch(e => {
            reject()
          })
      };
    })
  };

  return (
    <EditPage<Post>
      {...props}
      entity={{
        ...entityData,
      }}
      title={'素材'}
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row justify="start">
        <Col span={11}>
          <Form.Item
            label="素材分类"
            name={["categoryList"]}
            rules={[{ required: true, message: '请选择素材分类' }]}
          >
            <Cascader options={categoryList} placeholder="请选择素材分类" fieldNames={{ label: 'name', value: 'id' }} />
          </Form.Item>
        </Col>
        <Col span={1} />

        <Col span={12}>
          <ProFormSelect
            name='pubNickName'
            label="发布人"
            options={pubUserList}
            rules={[{ required: true }]}
            fieldProps={{
              onChange: (e) => {
                let data = pubUserList.concat().filter((item: any) => item.value === e)?.[0]
                pubUser = data;
                // setPubUser(data)
              }
            }
            }
          />
        </Col>
      </Row>
      <Row className="mb20" style={{ marginBottom: '10px' }}>
        {/* <ProFormGroup label="素材文案" extra={
          pictureInterKey && <IntelSelectVal generalLanguage={generalLanguage} intlArr={intlArr} defaultValue={pictureInterKey} onChange={(e: string) => setPictureInterKeyChange(e)} />
        }>
          <Col span={24}>
            <Form.Item name='content' >
              <TextArea rows={6} style={{ width: '100%' }} onChange={setEditorStateData} />
            </Form.Item>
          </Col>
        </ProFormGroup> */}
        <Col span={24}>
          <IntelFormText
            label="素材文案"
            name='content'
            type='textArea'
            value={entity.content || ''}
            form={form}
            width={1400}
            required={true}
          />
        </Col>
      </Row>
      <Row>
        <ProFormRadio.Group
          label="素材类型"
          name={'publicityType'}
          width='md'
          rules={[{ required: true }]}
          options={[
            {
              label: '图片',
              value: 'Pictures',
            }, {
              label: '视频',
              value: 'Video',
            }
          ]}
        />
      </Row>

      <Row>
        <ProFormDependency name={['publicityType']}>
          {({ publicityType }) => {
            return publicityType === 'Pictures' ?
              <>
                <Col span={24} className="scmb0">
                  <Form.Item name="pictures" label="素材图片" validateTrigger='onBlur' rules={[{ required: publicityType === 'Pictures' ? true : false }]}>
                    <UploadImgComplex
                      // className={entity.pictures?.length === 9 ? 'hideUpdate' : 'pro-upload-picture-box'}
                      listType="picture-card"
                      extra="最多上传9张,支持扩展名: jpg、jpeg、png"
                      title="上传"
                      maxCount={9}
                    />
                  </Form.Item>
                </Col>
                {entity.id && entity.pictures.length > 0 && <Col span={24} style={{ margin: '-40px 0 20px' }}>
                  <Button onClick={() => downImages()}>下载全部图片</Button>
                </Col>}</>
              : <>
                <Col span={24} className="scmb0">
                  <Form.Item name="coverUrl" label="视频封面" validateTrigger='onBlur' rules={[{ required: publicityType === 'Video' ? true : false }]}>
                    <UploadImgComplex
                      extra="最多上传1张"
                      listType="picture-card"
                      title="上传"
                      maxCount={1}
                    />
                  </Form.Item>
                </Col>

                <Row style={{ width: '100%' }}>

                  <ProFormRadio.Group
                    label="视频上传方式"
                    name={'videoUploadMethod'}
                    width='md'
                    rules={[{ required: true }]}
                    options={[
                      {
                        label: '上传',
                        value: 'upload',
                      }, {
                        label: '视频链接',
                        value: 'videolink',
                      }
                    ]}
                  />
                </Row>
                <Row>
                  <Col span={24} className="scmb0">
                    <ProFormDependency name={['videoUploadMethod']}>
                      {({ videoUploadMethod }) => {
                        return videoUploadMethod === 'upload' ?
                          <Row>
                            <Col span={24} className="scmb0">
                              <Form.Item name="videos" label="素材视频" validateTrigger='onBlur' rules={[{ required: publicityType === 'Video' ? true : false }]} >
                                <UploadImgComplex
                                  extra="最多上传1个视频，视频限制最大10MB"
                                  listType="text"
                                  title="上传"
                                  fileType='video'
                                  maxCount={1}
                                />
                              </Form.Item>
                            </Col> </Row> : <ProFormText
                            width={'lg'}
                            label="视频链接"
                            name="videolink"
                            placeholder="视频链接"
                          />
                      }}</ProFormDependency>
                  </Col>
                </Row>
              </>

          }}
        </ProFormDependency>

      </Row>

      <Row>
        <Col span={24} className="associated-goods">
          <Title level={5}>关联商品</Title>
          {/* <RecommendProductActionPanel nameKey={['products']} /> */}
          <Form.Item label="选择商品" name='products'>
            <ProductListEditControl fetchFunc={searchProductList} hideProductIds />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={4}>
          <ProFormSwitch
            name="isScheduled"
            label="是否定时发布"
            width='sm'
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
        <Col span={8}>
          <ProFormDependency name={['isScheduled']}>
            {({ isScheduled }) => {
              return isScheduled &&
                <ProFormDateTimePicker
                  label="发布时间"
                  name="cornPushTime"
                  placeholder="发布时间"
                />
            }}
          </ProFormDependency>
        </Col>
        <Col span={12}>
          <ProFormText
            label="排序"
            name="sort"
            placeholder="排序"
          />
        </Col>
      </Row>
    </EditPage >);
};

const mapStateToProps = ({ post }: RootState) => ({
  entity: post.entity,
  updateSuccess: post.updateSuccess,
  updating: post.updating,
  createSuccess: post.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostEdit);
