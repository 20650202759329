import { Form, Input, Image } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity } from "./videoCheckIn.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { ProFormDateTimePicker} from "@ant-design/pro-form";

export interface videoCheckInListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const VideoCheckInList: FC<videoCheckInListProps> = (props) => {
  const columns: ProColumns<any>[] = [
  {
    title: '授权号',
    dataIndex: 'customerNo',

  }, {
    title: '姓名',
    dataIndex: 'customerName',

  },
  {
    title: '打卡截图',
    dataIndex: 'signText',
    render: (text: any, row: any) => {
      if (row.signText) {
        let dataList = row.signText.split(',')
        return <div className='query-order-name'>
          {dataList.map((item: any) => {
            return <Image style={{ width: '30px', height: 'auto', marginRight: '10px' }} src={item || ''} ></Image>
          })}

        </div>

      }
    }
  },
 {
    title: '打卡时间',
    dataIndex: 'signTime',
    width: 80
  }
  ];

  const searchFields: SearchField[] = [
   {
      label: '授权号',
      name: 'customerNo.equals',
    }, {
      label: '姓名',
      name: 'name.equals',
    },
  ];

   //导出
   const exportData = (resData?: any) => {
    let orderData: any[] = [];
    const exportOrderData = resData ? resData : props.entities;
    exportOrderData.forEach((res: any) => {
      let arr: any = {
        ...res
      };
      orderData.push(arr);
    });
    return orderData;
  }

  // 搜索
  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="signTime.greaterThanOrEqual"
          placeholder="打卡开始时间"
        />
        <ProFormDateTimePicker
          name="signTime.lessThanOrEqual"
          placeholder="打卡结束时间"
        />
      </Input.Group>
    </Form.Item>
  );


  

  return (
    <div className="salonReimbursmt">
      <ListViewPage
        hideActionColumn
        actionColumnWidth={60}
        columns={columns}
        downloadEntities={exportData()}
        downloadAllEntities={exportData}
        showDownload
        hideEditButton
        permanentParams={{'activityId.equals': 1}}
        hideDeleteButton
        hideCreateButton
        quickFilterPanel={quickFilterPanel}
        detailsIndex={false}
        {...props}
      >
        {props.children}
      </ListViewPage>
    </div>
  );
};

const mapStateToProps = ({ videoCheckIn }: RootState) => ({
  entity: videoCheckIn.entity,
  entities: videoCheckIn.entities,
  loading: videoCheckIn.loading,
  updateSuccess: videoCheckIn.updateSuccess,
  createSuccess: videoCheckIn.createSuccess,
  total: videoCheckIn.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoCheckInList);