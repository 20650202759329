import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDatePicker, ProFormDigit, ProFormText } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./customer.reducer";
import { Customer } from "model/dealer/customer.model";
import { Col, Form, Row } from "antd";

export interface CustomerEditProps extends EditStateAndDispatchProps<Customer>, StateProps, DispatchProps, RouteComponentProps {
}

const CustomerEdit: FC<CustomerEditProps> = (props) => {
  const [form] = Form.useForm();
  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        const identifierNo = form.getFieldValue('identifierNo');
        const customerLevel = form.getFieldValue('customerLevel');
        const customerLevelStartDate = form.getFieldValue('customerLevelStartDate');
        const customerLevelEndDate = form.getFieldValue('customerLevelEndDate');
        !identifierNo && (delete res.identifierNo);
        !customerLevel?.id && (delete res.customerLevel);
        !customerLevelStartDate && (delete res.customerLevelStartDate);
        !customerLevelEndDate && (delete res.customerLevelEndDate);
        resolve(res);
      }
    })
  }


  return (
    <EditPage<Customer>
      title="经销商"
      beforeUpdateEntity={beforeUpdateEntity}
      form={form}
      {...props}
    >
      <Row justify="start">
        <Col span='7'>
          <ProFormText
            name={['customer', 'name']}
            width="md"
            label="姓名"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span='1'></Col>
        <Col span='7'>
          <ProFormDigit
            name={['customer', 'phone']}
            label="手机号"
            width="md"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span='1'></Col>
        <Col span='7'>
          <ProFormDatePicker
            name={['customer', 'birthday']}
            label="生日"
            width="md"
          />
        </Col>
        <Col span='1'></Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ dealerCustomer }: RootState) => ({
  entity: dealerCustomer.entity,
  updateSuccess: dealerCustomer.updateSuccess,
  updating: dealerCustomer.updating,
  createSuccess: dealerCustomer.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CustomerEdit);
