import { FC, useCallback, useState, memo, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { LeftOutlined, DeleteOutlined, CopyOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Image } from 'antd';
import './common.less';
import mobileBg from '../../../assets/images/mobile-bg.jpg'
import { updatePageConfig, updateInsertPositionId, updateMoreState, deleteModule, insertModule } from '../examDecorate/examplatform-edit.reducer'
import TitleText from './modules/title_text';
import SingleChoiceQuestion from './modules/single_choice_question';
import MultipleChoiceQuestions from './modules/multiple_choice_questions';
interface PagePreviewProps extends StateProps, DispatchProps { }

interface ModulesContainProps {
  module?: any
  onDrop: (item: any) => void
  id: string
  insertPositionId: string
  updatePageConfigPrivate: () => void
  findModulePrivate: (id: string) => { module: any, index: number }
  moveModulePrivate: (dragId: string, index: number) => void
  moveAtIndex?: number
  updateInsertPositionId: (id: string) => void
  updateMoreState: (props: { [key: string]: any }) => void
  settingModuleId?: string
  deleteModule: (id: any) => void
  copyModule: (id: any) => void
  moduleData: any
  type: string //当前模块类型
}

const onDrop = (moduleData: any, dragInfo: any) => {

}

const ModuleContain: FC<ModulesContainProps> = memo(({ module, onDrop, type, id, insertPositionId, updatePageConfigPrivate, findModulePrivate, moveModulePrivate, moveAtIndex, updateInsertPositionId, updateMoreState, settingModuleId, deleteModule, copyModule }) => {
  // const [isHover,setHoverState] = useState(false)
  const operationTopType = [
    'goods',
    'list_nav',
    'main_meeting',
    'tingmimi_hot',
    'image_area'
  ]
  const [{ isOver }, drop] = useDrop({
    accept: ['all'],
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: () => false,
    hover(props) {
      //组件位置调整
      if (props.id && props.id !== id) {
        const { index } = findModulePrivate(id);
        moveModulePrivate(props.id, index)
      }
      //新增组件
      if (props.title && props.defaultConfig && insertPositionId !== id) {
        updateInsertPositionId(id)
      }
    },
  }, [onDrop, id, findModulePrivate, moveModulePrivate])
  useEffect(() => {
    // console.log('isOver',isOver,id,insertPositionId)
    if (!isOver && id === insertPositionId) {
      updateInsertPositionId('-1')
    }
  }, [isOver, id, insertPositionId, updateInsertPositionId])



  const dataField = module?.props?.dataField;
  dataField && delete dataField.top;
  return <div className={`module-item  ${settingModuleId === id ? 'selected' : ''} ${insertPositionId === id ? 'hover' : ''}`} onClick={
    () => {
      updateMoreState({
        settingModuleId: (settingModuleId === id ? 'none' : id)
      })
    }
  } ref={(node) => (drop(node))}
    style={(type === 'floating_frame' && dataField && dataField.img) ? {
      ...dataField,
      position: 'absolute',
      'z-index': '1'
    } : {}}
  >
    <div className="hover-border"></div>

    {module}
    <div className={`operation-con ${operationTopType.indexOf(type) !== -1 ? 'top' : ''}`}>
      <Button className="copy icon-opera" onClick={(e) => {
        copyModule(id)
        e.stopPropagation();
      }} type="primary" shape="circle" icon={<CopyOutlined />} ></Button>

      <Button className="delete icon-opera" onClick={(e) => {
        deleteModule(id)
        e.stopPropagation();
      }} type="primary" shape="circle" icon={<DeleteOutlined />} ></Button>

    </div>
  </div>
})
const PagePreview: FC<PagePreviewProps> = memo(({ pageData, pageConfig, popupData, insertPositionId, updatePageConfig, updateInsertPositionId, updateMoreState, settingModuleId, deleteModule, insertModule }) => {
  const [pageConfigPrivate, setPageConfigPrivate] = useState(pageConfig)
  const [configDataField, setConfigDataField] = useState({} as any)
  const [moveAtIndex, setMoveMoveAtIndex] = useState(-1)
  const findModulePrivate = useCallback(
    (id: string) => {
      const module = pageConfigPrivate.filter((c: any) => `${c.id}` === id)[0]
      return {
        module,
        index: pageConfigPrivate.indexOf(module)
      }
    },
    [pageConfigPrivate],
  )
  const moveModulePrivate = useCallback(
    (dropId: string, index: number) => {
      const { module, index: originIndex } = findModulePrivate(dropId)
      setMoveMoveAtIndex(index)
      // console.log('index',index,'originIndex',originIndex)
      setPageConfigPrivate(
        update(pageConfigPrivate, {
          $splice: [
            [originIndex, 1,],
            [index, 0, module]
          ]
        })
      )
    },
    [pageConfigPrivate, findModulePrivate, setMoveMoveAtIndex, setPageConfigPrivate]
  )
  const copyModule = useCallback(
    (id: any) => {
      const { module } = findModulePrivate(id)

      updateInsertPositionId(id)
      insertModule({
        type: module.type,
        defaultConfig: module.dataField,
        isCopy: true
      })

    },
    [updateInsertPositionId, insertModule, findModulePrivate]
  );
  const getModules = (v: any, i: any) => {
    switch (v.type) {
      case 'title_text':
        return <TitleText dataField={v.dataField} index={i} key={i} />
      case 'single_choice_question':
        return <SingleChoiceQuestion dataField={v.dataField} index={i} key={i} />
      case 'multiple_choice_questions':
        return <MultipleChoiceQuestions dataField={v.dataField} index={i} key={i} />
      // default:
      //   return <div className="holder">Holder</div>
    }
  }
 
  // 获取索引
  // const getIndex=(dindex:any) => {
  //   let sindex = 0;
  //   let returnIndex:any = "";
  //   pageConfigPrivate.forEach((item:any, index:any) => {
  //     if (
  //       item.type === "single_choice_question" ||
  //       item.type === "multiple_choice_questions"
  //     ) {
  //       sindex++;
  //       if (dindex === index) {
  //         returnIndex = sindex;
  //       }
  //     }
  //   });
  //   return returnIndex;
  // }


  const updatePageConfigPrivate = useCallback(() => {
    updatePageConfig(pageConfigPrivate)
  }, [pageConfigPrivate, updatePageConfig])
  useEffect(() => {
    let configModule = pageConfig.filter((v: any) => v.type === 'config')
    if (configModule.length > 0) {
      setConfigDataField(configModule[0].dataField)
    }
    setPageConfigPrivate(pageConfig)

  }, [pageConfig, configDataField, setConfigDataField])
  const [, drop] = useDrop(() => ({ accept: 'all' }))
  return <div className="page-preview">
    <div className={`page-view `} >
      <img src={mobileBg} alt="" className="mobile-bg" />
      <div className="header">
        <div className="con">
          <LeftOutlined className="back" style={{ 'fontSize': '20px', 'color': '#888' }} />
          <p className="txt text-1">{configDataField.title}</p>
        </div>
      </div>
      <div className={`page-container ${popupData && popupData.img ? 'dis-scroll' : ''}`} style={
        {
          'backgroundColor': configDataField?.bgColor || '#f5f5f5'
        }
      } ref={drop}>
        {/* 弹窗预览 */}
        {
          popupData && popupData.img && !popupData.type && <div className="popup-preview">
            <div className="popup-mask" onClick={
              () => {
                updateMoreState({
                  popupData: {}
                })
              }
            }>
              <div className="img-con" style={
                {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  top: 0,
                  height: '100%',
                }
              } onClick={
                (e) => {
                  e.stopPropagation()
                }
              }>
                <div className="img" >
                  <Image preview={false} src={popupData.img + '?imageView2/2/w/600'} />
                </div>
                <div className="close" onClick={
                  () => {
                    updateMoreState({
                      popupData: {}
                    })
                  }
                }>
                  <CloseCircleOutlined className="icon" />
                </div>

              </div>
            </div>
          </div>
        }
        {/* 浮标预览 */}
        {
          popupData && popupData.img && popupData.type && <div className="popup-preview">
            <div className="popup-mask" onClick={
              () => {
                updateMoreState({
                  popupData: {}
                })
              }
            }>
              <div className="img-con" style={
                {
                  top: 'auto',
                  left: 'auto',
                  transform: 'none',
                  bottom: popupData.bottom + 'px',
                  width: popupData.width + 'px',
                  right: popupData.right + 'px',
                }
              } onClick={
                (e) => {
                  e.stopPropagation()
                }
              }>
                <div className="img" >
                  <Image preview={false} src={popupData.img + '?imageView2/2/w/600'} />
                </div>
              </div>
            </div>
          </div>
        }
        {/* 主要模块渲染 */}
        {

          pageConfigPrivate?.map((v: any, i: number) => {
            v.dataField.pageStyle = configDataField.style || ''
            return <ModuleContain
              moveAtIndex={moveAtIndex}
              updateInsertPositionId={updateInsertPositionId}
              findModulePrivate={findModulePrivate}
              moveModulePrivate={moveModulePrivate}
              insertPositionId={insertPositionId}
              key={v.id}
              type={v.type}
              updatePageConfigPrivate={updatePageConfigPrivate}
              updateMoreState={updateMoreState}
              id={v.id} module={getModules(v, i)}
              onDrop={(item) => onDrop(v, item)}
              settingModuleId={settingModuleId}
              moduleData={v}
              deleteModule={deleteModule}
              copyModule={copyModule}
            />
          })
        }
        {insertPositionId === '-1' && <div className="insert-box"></div>}
      </div>


    </div>

  </div>
})
const mapStateToProps = ({ examDecorateEdit }: RootState) => ({
  pageConfig: examDecorateEdit.pageConfig,
  pageData: examDecorateEdit.pageData,
  insertPositionId: examDecorateEdit.insertPositionId,
  settingModuleId: examDecorateEdit.settingModuleId,
  popupData: examDecorateEdit.popupData,

});
const mapDispatchToProps = {
  updatePageConfig,
  updateMoreState,
  updateInsertPositionId,
  deleteModule,
  insertModule
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(PagePreview);
