import Title from "antd/lib/typography/Title";
import { Storage } from 'utils/storage-util';
import { fetchLevelList } from "pages/customerCore/level/level.reducer";
import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./delivery.reducer";
import { ProFormDependency, ProFormDigit, ProFormList, ProFormRadio, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { Delivery, PriceModel } from "model/product/delivery.model";
import { Button, Col, Form, Row } from "antd";
import { getRegions } from "../region/region.reducer";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";import { useParamGeneral } from "components/useParamGenera";
;
export interface DeliveryEditProps extends EditStateAndDispatchProps<Delivery>, StateProps, DispatchProps, RouteComponentProps {
}

export const regionObj: any = {
  tmm: ['00', '11', '1101'],
  ttw: ['01', '0101', '010101'],//台湾
  tml: ['N3', 'N301', 'N30101'],//马来西亚
  tsg: ['02', '0201', '020101'],//新加坡
};

const DeliveryEdit: FC<DeliveryEditProps> = props => {
  const { entity, text } = props;
  const [form] = Form.useForm<{ regionCharges?: any }>();
  const [treeOptions, setTreeOptions] = useState<any>([]);
  const {currency} = useParamGeneral();
  console.log(currency)

  useEffect(() => {
    const xTenant = Storage.session.get("x-tenant");
    const regionKey: 'tmm' | 'ttw' | 'tml' | 'tsg' = regionObj[xTenant] ? xTenant : 'tmm';
    getRegions(regionObj[regionKey][0]).then(e => { setTreeOptions(e) });
  }, [entity]);

  useEffect(() => {
    priceModelChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  const selectChange = (e: any) => {
    treeOptions && treeOptions.length > 0 && setTreeOptions(treeOptions.map((i: any) => {
      return e.indexOf(i.value) === -1 ? i : {
        ...i,
        text: '1',//1禁用 0可选
      }
    }));
  };

  const deselectChange = (e: string) => {
    treeOptions && treeOptions.length > 0 && setTreeOptions(treeOptions.map((item: any) => {
      item.value === e && (item['text'] = '0');
      return item
    }));
  };

  const priceModelChange = () => {
    treeOptions && treeOptions.length > 0 && setTreeOptions(treeOptions.map((item: any) => {
      item['text'] = '0';
      return item;
    }))
  };

  const beforeUpdateEntity = (res?: Delivery) => {
    return new Promise<Delivery>((resolve, reject) => {
      if (res) {
        res.regionCharges = res.regionCharges ? res.regionCharges.filter(e => e.regions && e.regions.length > 0) : [];
        resolve(res);
      } else { reject(); }
    })
  };

  return (
    <EditPage<Delivery>
      title="运费模版"
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
      {...props}
    >
      <Row>
        <Col span={6}>
          <ProFormText
            width="md"
            name="name"
            label="模板名称"
            placeholder="请输入模板名称"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        <Col span={6}>
          <Form.Item name={'storeId'}>
            <SelectSearch name="storeId" label='商家' defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={6}>
          <ProFormRadio.Group
            radioType="button"
            label="定价模式"
            name="priceModel"
            rules={[{ required: true }]}
            disabled={entity.priceModel ? true : false}
            initialValue={1}
            fieldProps={{
              onChange: (e) => { priceModelChange(); form.setFieldsValue({ regionCharges: [] }) }
            }}
            options={Object.entries<string>(PriceModel).map(([key, value]) => ({ value: Number.parseInt(key), label: value }))}
          />
        </Col>
        <Col span='3'>
          <ProFormDigit
            width="md"
            name={"freeShippingAmount"}
            label="满 * 元包邮"
            fieldProps={{ min: 0,prefix:currency?.symbol, }}
          />
        </Col>
        <Col span={1} />
        <Col span='6'>
          <Form.Item label="会员等级包邮" name={['freeShippingLevelNo']}>
            <SelectSearch valueType="number" placeholder='会员等级包邮' required={false} optionValue="levelNo" defaultParams={'id.equals'} name={['freeShippingLevelNo']} width="lg" labelValue="levelName" fetchFunc={fetchLevelList} searchParams='levelName.contains' />
          </Form.Item>
        </Col>
        <Col span={1} />
        <ProFormDependency name={['priceModel']}>
          {({ priceModel }) => {
            return priceModel === 1 ? <>
              <Row style={{ width: '90%' }}>
                <Col span='8'>
                  <Title level={5}>默认运费</Title>
                </Col>
                <Col span='5'>
                  <ProFormDigit
                    width="md"
                    name={['defaultCharge', "fixedPrice"]}
                    label="固定价格(元)"
                    placeholder="请输入"
                    rules={[{ required: true }]}
                    fieldProps={{ prefix:currency?.symbol,min: 0 }}
                  />
                </Col>
                <Col span="1"></Col>
              </Row>
              <Col span={24} className="region-edit-box">
                <ProFormList
                  name="regionCharges"
                  label="按区域定义运费"
                  creatorButtonProps={{ creatorButtonText: '新增区域运费', }}
                  initialValue={[]}
                  actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
                >
                  <Row>
                    <Col span={6}>
                      <ProFormSelect
                        options={treeOptions}
                        name='regions'
                        mode="multiple"
                        showSearch
                        placeholder="请选择区域"
                        label="选择区域"
                        allowClear={false}
                        fieldProps={{
                          autoClearSearchValue: true,
                          onChange: (e) => selectChange(e),
                          optionItemRender: (dom: any) => {
                            const obj = treeOptions.filter((e: any) => e.value === dom.value)[0];
                            return <Button type="link" size="small" disabled={obj.text === '1'} style={{ width: '100%' }}>{dom.label}</Button>
                          },
                          onDeselect: (e, option) => deselectChange(e),
                          className: 'region-select'
                        }}
                      />
                    </Col>
                    <Col span={1} />
                    <Col span='3'>
                      <ProFormDigit
                        width={'lg'}
                        name={['shipCharge', "fixedPrice"]}
                        placeholder="固定金额"
                        label="固定金额"
                        fieldProps={{ prefix:currency?.symbol,min: 0 }}
                      />
                    </Col><Col span={1} />
                    <Col span='3'>
                      <ProFormDigit
                        width={'lg'}
                        name={["freeShippingAmount"]}
                        placeholder="免邮金额"
                        label="免邮金额"
                        fieldProps={{ prefix:currency?.symbol,min: 0 }}
                      />
                    </Col><Col span={1} />
                    <Col span='3'>
                      <ProFormDigit
                        width={'lg'}
                        name={["additionalAmount"]}
                        placeholder="额外金额"
                        label="额外金额"
                        fieldProps={{ prefix:currency?.symbol,min: 0 }}
                      />
                    </Col><Col span={1} />
                  </Row>
                </ProFormList>
              </Col>
            </>
              : <>
                <Row style={{ width: '90%' }}>
                  <Col span='8'>
                    <Title level={5}>默认运费</Title>
                  </Col>
                  <Col span='3'>
                    <ProFormDigit
                      width="md"
                      name={['defaultCharge', "firstWeight"]}
                      label="首重(Kg)"
                      placeholder="请输入"
                      rules={[{ required: true }]}
                      fieldProps={{ min: 0 }}
                    />
                  </Col>
                  <Col span="1"></Col>
                  <Col span='3'>
                    <ProFormDigit
                      width="md"
                      name={['defaultCharge', "firstWeightPrice"]}
                      label="首重价格(元)"
                      placeholder="请输入"
                      rules={[{ required: true }]}
                      fieldProps={{ prefix:currency?.symbol,min: 0 }}
                    />
                  </Col>
                  <Col span="1"></Col>
                  <Col span='3'>
                    <ProFormDigit
                      width="md"
                      name={['defaultCharge', "additionalWeight"]}
                      label="续重(Kg)"
                      placeholder="请输入"
                      rules={[{ required: true }]}
                      fieldProps={{ min: 0 }}
                    />
                  </Col>
                  <Col span="1"></Col>
                  <Col span='3'>
                    <ProFormDigit
                      width="md"
                      name={['defaultCharge', "additionalWeightPrice"]}
                      label="续重价格(元)"
                      placeholder="请输入"
                      rules={[{ required: true }]}
                      fieldProps={{ prefix:currency?.symbol,min: 0 }}
                    />
                  </Col>
                </Row>
                <Col span={24} className="region-edit-box">
                  <ProFormList
                    name="regionCharges"
                    label="按区域定义运费"
                    creatorButtonProps={{ creatorButtonText: '新增区域运费', }}
                    initialValue={[]}
                    actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
                  >
                    <Row>
                      <Col span={7}>
                        <ProFormSelect
                          options={treeOptions}
                          name='regions'
                          mode="multiple"
                          allowClear={false}
                          showSearch
                          placeholder="请选择区域"
                          label="选择区域"
                          fieldProps={{
                            autoClearSearchValue: true,
                            onChange: (e) => selectChange(e),
                            optionItemRender: (dom: any) => {
                              const obj = treeOptions.filter((e: any) => e.value === dom.value)[0];
                              return <Button type="link" size="small" disabled={obj.text === '1'} style={{ width: '100%' }}>{dom.label}</Button>
                            },
                            onDeselect: (e, option) => deselectChange(e)
                          }}
                        />
                      </Col>
                      <Col span={1}></Col>
                      <Col span='3'>
                        <ProFormDigit
                          width="md"
                          name={['shipCharge', "firstWeight"]}
                          placeholder="首重(Kg)"
                          label="首重(Kg)"
                          fieldProps={{ min: 0 }}
                        />
                      </Col>
                      <Col span="1"></Col>
                      <Col span='3'>
                        <ProFormDigit
                          width="md"
                          name={['shipCharge', "firstWeightPrice"]}
                          placeholder="首重价格(元)"
                          label="首重价格(元)"
                          fieldProps={{ prefix:currency?.symbol,min: 0 }}
                        />
                      </Col>
                      <Col span="1"></Col>
                      <Col span='3'>
                        <ProFormDigit
                          width="md"
                          name={['shipCharge', "additionalWeight"]}
                          placeholder="续重(Kg)"
                          label="续重(Kg)"
                          fieldProps={{ min: 0 }}
                        />
                      </Col>
                      <Col span="1"></Col>
                      <Col span='3'>
                        <ProFormDigit
                          width="md"
                          name={['shipCharge', "additionalWeightPrice"]}
                          placeholder="续重价格(元)"
                          label="续重价格(元)"
                          fieldProps={{ prefix:currency?.symbol,min: 0 }}
                        />
                      </Col>
                      <Col span='3'>
                        <ProFormDigit
                          width={'lg'}
                          name={["freeShippingAmount"]}
                          placeholder="免邮金额"
                          label="免邮金额"
                          fieldProps={{ prefix:currency?.symbol,min: 0 }}
                        />
                      </Col><Col span={1} />
                      <Col span='3'>
                        <ProFormDigit
                          width={'lg'}
                          name={["additionalAmount"]}
                          placeholder="额外金额"
                          label="额外金额"
                          fieldProps={{ prefix:currency?.symbol,min: 0 }}
                        />
                      </Col><Col span={1} />
                    </Row>
                  </ProFormList>
                </Col>
              </>
          }}
        </ProFormDependency>
        <Col span={24} style={{ display: 'none' }}>
          <ProFormSwitch
            name={'status'}
            label="状态"
            initialValue={false}
            fieldProps={{
              checkedChildren: '启用',
              unCheckedChildren: '禁用'
            }}
          />
        </Col>
      </Row>

    </EditPage>);
};

const mapStateToProps = ({ delivery }: RootState) => ({
  entity: delivery.entity,
  updateSuccess: delivery.updateSuccess,
  updating: delivery.updating,
  createSuccess: delivery.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DeliveryEdit);
