import {BaseModel} from "../base.model";

export interface Brand extends BaseModel {
  brandName?: string;
  introduction?: string;
  level?: number;
  logo?: string;
  parentId?: number;
  status?: number;
  storeId?: any;
  brandCode?: string;
  cashDeposit?: number
  levelCashDeposit?: any

}

export const defaultBrand: Readonly<Brand> = {};