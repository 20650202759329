import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { RewardList, defaultRewardList } from 'model/bonus/rewardList.model';

const entity = 'Reward';
const apiUrl = '/api/yumei-mall-finance/reward/result';
const standardReducerImpl = new StandardReducerImpl<RewardList>(entity, defaultRewardList, apiUrl);
export type RewardListState = StandardState<RewardList>;
export const rewardList = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;