import { useEffect, useState } from "react";
import ProTable from "@ant-design/pro-table";
import { Button, Form, Input, message, Modal } from "antd";
import { ModalForm } from "@ant-design/pro-form";
import { addExamPageType, examPageTypeList, delExamPageType } from '../api';
const { confirm } = Modal;


const Table = (props: any) => {
    const [form] = Form.useForm<{}>();
    let [tagDialog, setTagDialog] = useState<any>(false);
    const [tableData, setTableData]: any = useState([]);

    // tab数据
    const DataColumn: any[] = [
        {
            title: 'id',
            dataIndex: 'id'
        }, {
            title: '页面类型',
            dataIndex: 'name'
        },
        {
            title: '操作',
            dataIndex: 'relationshiplist',
            width: 160,
            render: (text: any, row: any) => {
                return <>
                    {/* <Button type="primary" onClick={() => {
                    setRecord(row)
                    setTagDialog(true)
                    form.setFieldsValue({
                        name: row.name
                    })
                }} icon={<></>}>编辑</Button> */}
                    <Button danger type="primary" icon={<></>} onClick={()=>delExamPageTypeFn(row.id)}>删除</Button></>


            }
        },
    ]


    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
        },
    };


    useEffect(() => {
        getExamPageTypeList()
    }, [props])

    // 获取列表 
    const getExamPageTypeList = () => {
        examPageTypeList().then((e: any) => {
            console.log('页面类型')
            console.log(e)
            setTableData(e.data || [])

        })
    }

    // 删除 
    const delExamPageTypeFn = (id:any) => {
        confirm({
            title: '确定删除吗？',
            onOk() {
                delExamPageType({
                    id: id
                }).then((e: any) => {
                    console.log('页面类型')
                    console.log(e)
                    message.success('删除成功')
                    getExamPageTypeList()
                }).catch(err => message.error('删除失败'));
            }
        });
       
    }

    return (<>
        <view className="operation">
            <Button onClick={() => {
                setTagDialog(true)
                form.setFieldsValue({
                    name: ''
                })
            }} type="primary" icon={<></>}>添加</Button>
        </view>
        <ProTable
            search={false}
            toolBarRender={false}
            pagination={false}
            dataSource={tableData}
            columns={DataColumn}
        />

        <ModalForm
            title="添加"
            width={400}
            layout='horizontal'
            form={form}
            {...formItemLayout}
            visible={tagDialog}
            onVisibleChange={(e) => {
                if (!e) {
                    setTagDialog(false)
                }
            }}
            preserve={false}
            onFinish={async (data: any) => {
                addExamPageType(data).then((e: any) => {
                    getExamPageTypeList()
                    setTagDialog(false)
                    message.success('添加成功')
                    return true;
                }).catch((e: any) => {
                    message.error(e.response.data.message);
                    return false
                })
            }}
        >

            <div style={{ marginLeft: '5px' }}>
                <Form.Item
                    label="页面类型"
                    name="name"
                    rules={[{ required: true }]}
                >
                    <Input placeholder="页面类型" />
                </Form.Item>
            </div>
        </ModalForm>
    </>
    )
}

export default Table