import { BaseModel } from "../base.model";

export interface Audit extends BaseModel {
  changeIndex?: number[];
  classFullPath?: string;
  className?: string;
  entityContent?: string;
  id?: number;
  oldStates?: string;
  operationDate?: string;
  operationMethod?: string;
  operatorId?: number;
  operatorName?: string;
  propertyNames?: string[];
  referenceId?: number;
  states?: string;
  diffStates?: diffStates[];
}

export interface diffStates {
  oldState?: string;
  propertyName?: string;
  state?: string;
}

export const operationMethod = {
  Insert: "插入",
  Update: "修改",
  Delete: "删除"
}

export const defaultAudit: Readonly<Audit> = {};