import { defaultFlowTemplate, FlowTemplate } from 'model/financial/flowTemplate.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'flowTemplate';
const apiUrl = '/api/yumei-mall-finance/bank-flow/template';
const standardReducerImpl = new StandardReducerImpl<FlowTemplate>(entity, defaultFlowTemplate, apiUrl);
export type FlowTemplateState = StandardState<FlowTemplate>;
export const flowTemplate = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

