import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import { Customer, defaultCustomer } from 'model/customerCore/customer.model';
import axios from 'axios';
import { CrudPutAction } from 'reducers/redux-action.type';
import { internationalList } from 'components/IntelFormText';
const entity = 'customer';
const apiUrl = '/api/yumei-mall-customer/customer';
const standardReducerImpl = new StandardReducerImpl<Customer>(entity, defaultCustomer, apiUrl);
export type CustomerState = StandardState<Customer>;
export const customer = standardReducerImpl.buildStandardReducer();
export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const searchCustomerList = async (params: any) => {
    const requestUrl = `/api/yumei-mall-customer/tst-customer`;
    return await axios.get<any>(requestUrl, { params });
}


// 查询会员级别
export const fetchShipLevel = async () => {
    const axiosResponse = await axios.get(`/api/yumei-mall-customer/customer/level?page=0&currentPage=1&sort=levelNo%2Casc`);
    let data = axiosResponse.data
    data.map((item:any)=> {
      item.levelName = internationalList(item.levelName)
      return item
    })
    return data || [];
  }


// 修改标签
export const updateTags = async (data: any) => {
    const requestUrl = `/api/yumei-mall-customer/customer/update-tags/${data.id}`;
    return await axios.post<any>(requestUrl, data.data);
}


// 修改密码
export const editePwd = async (data: any) => {
  const requestUrl = `/api/yumei-mall-customer/customer/user/change-password`;
  return await axios.post<any>(requestUrl, data);
}

export const updateAuthorityFunc = async (id: any) => {
  const requestUrl = `/api/yumei-mall-customer/customer/authority-by-id/${id}`;
  return await axios.post<any>(requestUrl);
}

export const fetchCustomerList = async (params?: any) => {
  return await axios.get<any>(apiUrl, { params:{...params} });
}

export const fetchSearchCustomerList = async (params?: any) => {
  const axiosResponse = await axios.get<any>(apiUrl, { params:{...params} });
  return axiosResponse.data || []
}

export const updateCustomer: CrudPutAction<any> = (entity) => async dispatch => {
    const requestUrl = `/api/yumei-mall-customer/tst-customer/${entity?.id}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.put(requestUrl, entity),
    });
};

export const unBind: CrudPutAction<any> = (id: string) => async dispatch => {
    const requestUrl = `${apiUrl}/link/unbind/${id}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.post(requestUrl),
    });
};