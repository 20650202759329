import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDependency, ProFormDigit, ProFormSelect, ProFormSwitch, ProFormText } from '@ant-design/pro-form';
import { createEntity, getEntity, reset, updateEntity } from "./topic.reducer";
import { Topic } from "model/video/topic.model";
import { Col, Form, message, Row } from "antd";
import UploadImgOne from "components/upload-img/upload-img-one";
import { uploadImage } from "utils/file-utils";
import { useEnumValue } from "common/enum/use-enum-value";

export interface TopicEditProps extends EditStateAndDispatchProps<Topic>, StateProps, DispatchProps, RouteComponentProps {
}

const TopicEdit: FC<TopicEditProps> = (props) => {

  const { entity } = props;
  const { getEnumValueList } = useEnumValue();

  const beforeUpdateEntity = (res?: Topic) => {
    return new Promise<Topic>((resolve, reject) => {
      //话题图片
      const picFun = (resPic: Topic) => {
        const mainPicture: any = resPic.pic?.concat();
        if (mainPicture && mainPicture.length > 0) {
          if (mainPicture[0].originFileObj) {
            const newDate = new Date().getTime();
            uploadImage(mainPicture[0].originFileObj, 'post-topic/img-' + newDate).then(imageName => {
              resPic.pic = imageName;
              resolve(resPic);
            }).catch(() => { message.error('图片上传失败'); reject() });
          } else {
            resPic.pic = mainPicture[0].url;
            resolve(resPic)
          }
        } else {
          message.error('请上传话题图片');
          reject('请上传话题图片');
        }
      };
      res ? picFun(res) : reject();
    })
  }

  return (
    <EditPage<Topic>
      title="话题"
      beforeUpdateEntity={beforeUpdateEntity}
      {...props}
    >
      <Row>
        <Col span={7}>
          <ProFormText
            name="name"
            label="话题名称"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormText
            name="content"
            label="话题引导语"
            extra="建议4-8字以内广告性短语"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormSelect
            name="type"
            label="发布类型"
            options={getEnumValueList('materialResourceLimitType').map((e: any) => ({
              ...e,
              label: e.desc,
              value: e.name
            }))}
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        <ProFormDependency name={['type']}>
          {({ type }) => {
            return type !== 'Picture' &&
              <>
                <Col span={7}>
                  <ProFormDigit
                    name="minDuration"
                    label="短视频最小时长(单位s)"
                    rules={[{ required: true }]}
                  />
                </Col><Col span={1} />
              </>
          }
          }
        </ProFormDependency>
        <Col span={7}>
          <ProFormText
            name="topicCode"
            label="话题编码"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span={1} />
        {/* {entity.id && <Col span={4}>
          <ProFormSwitch
            name={'isHot'}
            label="是否热门"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>} */}
        {entity.id && <Col span={3}>
          <ProFormDigit
            name="sort"
            label="排序"
          />
        </Col>}
        {entity.id && <Col span={1} />}
        {entity.id && <Col span={4}>
          <ProFormSwitch
            name={'isEnable'}
            label="状态"
            initialValue={false}
            fieldProps={{
              checkedChildren: '启用',
              unCheckedChildren: '禁用'
            }}
          />
        </Col>}
      </Row>
      <Form.Item name="pic" label="话题图片" rules={[{ required: true }]}>
        <UploadImgOne
          listType="picture-card"
          extra="建议尺寸：800*800像素, 最多上传一张, 不超过2M."
          title="上传"
        />
      </Form.Item>
      <Col span={24}>
        <ProFormText
          name="reference"
          label="活动id"
        />
      </Col>
    </EditPage>);
};

const mapStateToProps = ({ topic }: RootState) => ({
  entity: topic.entity,
  updateSuccess: topic.updateSuccess,
  updating: topic.updating,
  createSuccess: topic.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TopicEdit);
