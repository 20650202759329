import ProCard from "@ant-design/pro-card";
import {
  ProFormDependency,
  ProFormDigit,
  ProFormFieldSet,
  ProFormGroup,
  ProFormSelect,
  ProFormSwitch,
  ProFormText,
  ProFormRadio
} from '@ant-design/pro-form';
import { classificationIdItem, classificationIds } from 'model/product/product.model';
import { Col, Form, message, Row } from "antd";
import IntelFormText, { internationalList } from "components/IntelFormText";
import { DeductStock } from "model/product/product.model";
import { useEffect, useState } from "react";
import { fetchCategoryTreeList, fetchStoreCategoryList } from "../category/category.reducer";
import { getRegionsList, getRegionsOverallSituationList } from "../region/region.reducer";
import { Storage } from 'utils/storage-util';
import { fetchClassificationList, fetchStoreClassificationList } from "../classification/classification.reducer";
import { useEnumValue } from "common/enum/use-enum-value";
import { Category } from "model/product/category.model";
import { fetchSelectCouponCashList, fetchStoreBrandById } from "./product.reducer";
import { fetchLevelList } from "pages/customerCore/level/level.reducer";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import SelectSearch from "components/select-search";
import { fetchDeliveryList } from "../delivery/delivery.reducer";

interface ProductEditCardBasicProps {
  form: any;
  entity: any;
  setRegionTemplate: any;
  setDeliveryTemplateId: any;
  categoryIdPathMini: any;
  setCategoryIdPathMini: any;
  setPlatform: (val: string) => void;
  platform: string;
}

const ProductEditCardBasic = (props: ProductEditCardBasicProps) => {

  const {
    form,
    entity,
    setRegionTemplate,
    categoryIdPathMini,
    setCategoryIdPathMini,
    setPlatform,
    setDeliveryTemplateId,
  } = props;

  let timeoutChange: any = null;
  const handleChange = (entity: any) => {
    if (timeoutChange) {
      clearTimeout(timeoutChange);
      timeoutChange = null;
    };
    const fake = async () => {
      //是否虚拟商品
      !!entity?.virtualProduct ? setIsVirtualProduct(entity?.virtualProduct) : setIsVirtualProduct(false);
      setCategoryList([]);
      if (entity.name) {
        entity.sellerName && fetchSellerList('', (data: any) => {
          if (entity.sellerId) {
            let item: any = data.find(((item: any) => item.id === entity.sellerId))
            setIsPointMall(item?.isPointMall ? item?.isPointMall : false)
          }
        });
        entity.sellerId && fetchRegionData(entity.sellerId, entity.regionTemplate?.templateName || ''); //获取区域模版options 根据商家id获取



        //类目细分
        categoryList.length === 0 && entity.categoryIdPath?.[0] && getCategoryData(entity.sellerId, entity.categoryIdPath?.[0]);

        //平台商品分类细分
        if (classificationIds.length > 0) {
          entity.classifyIdPath && entity.classifyIdPath?.[0] && getMiniData(classificationIds, entity.classifyIdPath[0]);
        } else {
          //平台商品分类
          xTenant === 'tmm' ? fetchStoreClassificationList([entity.sellerId]).then(res => {
            const data = res.map((re: any) => {
              re.value = re?.id;
              re.label = internationalList(re?.name)
              return re
            });
            setClassificationIds(data);
            entity.classifyIdPath?.[0] && getMiniData(data, entity.classifyIdPath[0]);
          }) : fetchClassificationList().then(res => {
            const data = res.map((re: any) => {
              re.value = re?.id;
              re.label = internationalList(re?.name)
              return re
            });
            setClassificationIds(data);
            entity.classifyIdPath?.[0] && getMiniData(data, entity.classifyIdPath[0]);
          });
        };
        if (entity.sellerId) {
          getBrand(entity.sellerId);
        }
      } else {
      };
    };
    timeoutChange = setTimeout(fake, 500);
  };

  const [sellerList, setSellerList] = useState<any>([]);
  const [regionOption, setRegionOption] = useState<any>([]);//区域模版option
  const [allRegion, setAllRegion] = useState<any>([]);//全局区域
  const [categoryList, setCategoryList] = useState<any>([]);//商品类目 数据
  const [storeType, setStoreType] = useState<boolean>(false);
  const [classificationIds, setClassificationIds] = useState([]);//平台商品分类 数据
  const [miniData, setMiniData] = useState<any>([]);//平台商品分类细分 数据
  const xTenant = Storage.session.get("x-tenant");
  const { convertEnumToOptionType, getEnumValueList } = useEnumValue();
  const [brandList, setBrandList] = useState([]);
  const [isVirtualProduct, setIsVirtualProduct] = useState(false);
  const [isPointMall, setIsPointMall] = useState<boolean>(false);

  useEffect(() => {
    handleChange(entity)
    setStoreType(!!entity.sellerId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    fetchSellerList();
    getRegionsOverallSituationList().then(e => {
      e?.data?.id && setAllRegion([{
        value: e?.data?.id,
        label: e?.data?.templateName,
      }]);
    });
  }, [])

  const fetchSellerList = async (name?: string, callBack?: Function) => {
    const storeIdRequest = await fetchStoreList({ 'name.contains': name, 'selfSeller.equals': true });
    let list: any = []
    storeIdRequest.forEach((item: any, index: number) => {
      list.push({
        value: item?.id,
        label: item?.name,
        key: item?.id | index,
        selfSeller: item?.selfSeller,
        platform: item?.platform || '',
        isPointMall: item.isPointMall
      })
    })
    setSellerList(list);
    return storeIdRequest && callBack?.(storeIdRequest)
  }

  // 获取品牌列表
  const getBrand = async (sellerId: any) => {
    const data = await fetchStoreBrandById({
      storeId: sellerId
    });
    let list = data.map((item: any) => ({
      value: item?.id,
      label: internationalList(item?.brandName)
    }))
    setBrandList(list)
  }

  const classificationIdsData = async (sellerId: string | number) => {
    //平台商品分类
    const res = xTenant === 'tmm' ? await fetchStoreClassificationList([sellerId]) : await fetchClassificationList();
    const data = res.map((re: any) => {
      re.value = re?.id;
      re.label = internationalList(re?.name)
      return re
    });
    setClassificationIds(data);
  };

  const fetchRegionData = (sellerId: string | number, templateName?: string) => {
    getRegionsList(sellerId, templateName).then(res => {
      const regionOptionData = res?.data?.length > 0 ? res?.data?.map((item: any, index: number) => ({
        value: item?.id,
        label: item?.templateName,
        key: item?.id | index,
      })) : [];
      setRegionOption(regionOptionData.concat(allRegion));
    });
  };

  const tagsChange = (res: string, selfSeller: boolean) => {
    // const newTags = tags.concat();
    // const setTags = (type: string) => {
    //   tags.forEach((e: any, index: number) => {
    //     e.type === type && newTags.forEach((newT: any, index: number) => {
    //       e.label === newT?.label && newTags.splice(index, 1);
    //     });
    //     index === tags.length - 1 && setTagsOption(newTags);;
    //   });
    // };
    // selfSeller ? setTags('1') : setTags('2');
  };

  //商品类目
  const getCategoryData = async (sellerId: string, categoryIdPath?: any) => {
    const res = !xTenant ? await fetchStoreCategoryList([sellerId]) : await fetchCategoryTreeList();
    const data: any = res.map((re: any) => {
      const obj = {
        value: re?.id,
        label: internationalList(re?.name || ''),
        id: re?.id,
        childrenData: re.children
      };
      return obj
    });
    setCategoryList(data);
    categoryIdPath && handleCategoryIdPathMini(data, categoryIdPath);
  };

  let timeout: any = null;
  const handleSearch = (e: any, searchType: string, sellerId?: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    };
    const fake = async () => {
      searchType === 'regionTemplate' && sellerId && fetchRegionData(sellerId, e || '');
      searchType === 'sellerName' && fetchSellerList(e || '');
    };
    timeout = setTimeout(fake, 500);
  };

  const handClassifyIdPath = async (arr: string[]) => {
    if (!arr[0]) {
      arr = []
    }
    if (classificationIds.length > 0) {
      getMiniData(classificationIds, arr[0] || '')
    } else {
      const data = await fetchClassificationList();
      getMiniData(data, arr[0] || '')
    }
  };

  const getMiniData = async (all: classificationIds[], id: string) => {
    //平台商品分类细分 数据获取
    const data = all?.filter(res => res.id === id);
    if (data.length > 0) {
      const options = data[0].children?.map((item: classificationIdItem) => ({
        value: item?.id,
        label: internationalList(item?.name || '')
      }))
      setMiniData(options);
    } else {
      const options: any = [];
      setMiniData(options);
    }
  }

  const tagsOption: any = getEnumValueList('productTag').map(e => {
    return e.name === 'Returnable' ?
      {
        type: '1',
        value: e.name,
        label: e.desc,
        ...e
      } :
      (['Installment', 'BlueStarProduct', 'MemberPriceAvailable', 'CustomerOnly', 'CouponOnly', 'NotSalesAlone'].indexOf(e.name) !== -1 ?
        {
          type: '2',
          value: e.name,
          label: e.desc,
          ...e
        } :
        {
          type: '0',
          value: e.name,
          label: e.desc,
          ...e
        });
  });

  //类目细分处理
  const handleCategoryIdPathMini = (all: Category[], id: string) => {
    // setCategoryList(all);
    const data: any = all?.filter(res => res.id === id);
    if (data.length > 0) {
      const options = data[0].childrenData?.map((item: Category) => ({
        value: item?.id,
        label: internationalList(item?.name || '')
      }))
      setCategoryIdPathMini(options);
    } else {
      const options: any = [];
      setCategoryIdPathMini(options);
    }
  }

  const handleCategoryIdPath = async (arr: string[]) => {
    if (categoryList.length > 0) {
      handleCategoryIdPathMini(categoryList, arr[0])
    } else {
      const sellerId = form.getFieldValue('sellerId');
      const data = await fetchStoreCategoryList(sellerId);
      const interData = data.map((re: any) => {
        re.value = re?.id;
        re.label = internationalList(re?.name || '')
        return re
      });
      handleCategoryIdPathMini(interData, arr[0])
    }
  }


  return <ProCard
    title="商品基本信息"
    bordered
    headerBordered
    collapsible
    style={{
      marginBottom: 16,
      minWidth: 400,
      maxWidth: '100%',
    }}
  >
    <Row>
      <Col span={23}>
        <ProFormRadio.Group
          name="productType"
          label='商品类型'
          options={[{
            label: '正常商品',
            value: 'Normal'
          }, {
            label: '相关商品-辅料',
            value: 'Related'
          }]}
        />
      </Col>
    </Row>
    <Row>

      <Col span={23}>
        <IntelFormText
          label="商品名称"
          name='name'
          value={entity.name || ''}
          form={form}
          required={true}
          extra={'限100个字以内'}
          fieldProps={{
            maxLength: 100
          }}
        />
      </Col>


      <Col span={6}>
        <ProFormSelect
          options={sellerList}
          rules={[{ required: true }]}
          fieldProps={{
            onChange: (res, options: any) => {
              form.setFieldsValue({ regionTemplate: null, tags: [], deliveryTemplateId: '' });
              res && fetchRegionData(res);
              res && tagsChange(res, options.selfSeller);
              res && getCategoryData(res);
              res && classificationIdsData(res);
              res && setIsPointMall(options.isPointMall)
              res && setPlatform && setPlatform(options?.platform);
              res && getBrand(res)
              form.setFieldsValue({
                brand: {
                  id: ''
                },
                pricingRules: {
                  id: ''
                }
              })
            },
            onSearch: (e) => e && handleSearch(e, 'sellerName'),
            onFocus: (e) => e && handleSearch('', 'sellerName')
          }}
          width="sm"
          label="店铺"
          name="sellerId"
          showSearch
          disabled={storeType}
        />
      </Col>

      <ProFormDependency name={['sellerId']}>
        {({ sellerId }) => {
          return sellerId && <>
            <Col span={6}>
              <Form.Item name={["brand", "id"]}>
                <ProFormSelect
                  options={brandList}
                  rules={[{ required: true }]}
                  label='品牌'
                  name={["brand", "id"]}
                  width="sm"
                  placeholder="请选择"
                />

              </Form.Item>
            </Col>
          </>
        }}
      </ProFormDependency>

      <ProFormDependency name={['sellerId']}>
        {({ sellerId }) => {
          return sellerId && sellerId !== '' && <>
            <Col span={6}>
              <ProFormFieldSet
                rules={[{ required: true }]}
                label="商品类目"
                name={"categoryIdPath"}
                fieldProps={{
                  onChange: async (val: string[]) => val && handleCategoryIdPath(val),
                }}
              >
                <ProFormSelect
                  options={categoryList}
                  name='categoryIdPath0'
                  showSearch
                  fieldProps={{
                    onFocus: () => {
                      const sellerId = form.getFieldValue('sellerId');
                      sellerId ? getCategoryData(sellerId) : message.error('请先选择店铺');
                    }
                  }}
                />
                {categoryIdPathMini.length > 0 && <ProFormSelect
                  options={categoryIdPathMini}
                  name='categoryIdPath1'
                  showSearch
                />}
              </ProFormFieldSet>
            </Col>
            <Col span={6}>
              <ProFormFieldSet
                rules={[{ required: false }]}
                label="平台商品分类"
                name='classifyIdPath'
                fieldProps={{
                  onChange: (val: string[]) => val && handClassifyIdPath(val),
                }}
              >
                <ProFormSelect
                  options={classificationIds}
                  showSearch

                />
                {miniData.length > 0 ?
                  <ProFormSelect
                    options={miniData}
                    showSearch

                  /> : ''
                }
              </ProFormFieldSet>
            </Col>

            {/* <Col span={6}>
              <ProFormSelect
                width="sm"
                name='productType'
                label="商品类型"
                options={[{
                  label: '正常商品',
                  value: 'Normal'
                }, {
                  label: '相关商品-辅料',
                  value: 'Related'
                }]}
              />
            </Col> */}

            <Col span={6}>
              <ProFormSelect
                width="sm"
                name={["regionTemplate", 'id']}
                label="销售/禁售区域模版"
                showSearch
                fieldProps={{
                  onChange: (e) => { !e ? setRegionTemplate('empty') : setRegionTemplate(e) },
                  onSearch: (e) => handleSearch(e || '', 'regionTemplate', sellerId),
                  onFocus: (e) => handleSearch('', 'regionTemplate', sellerId),
                }}
                options={regionOption}
              />
            </Col>

            <Col span={6}>
              <Form.Item name={'deliveryTemplateId'}>
                <SelectSearch required={false} bindEvent={setDeliveryTemplateId} name={'deliveryTemplateId'} width="sm" togetherParams={{ "storeId.equals": sellerId }} label='运费模版' labelValue="name" defaultParams='id.equals' fetchFunc={fetchDeliveryList} searchParams='name.contains' />
              </Form.Item>
            </Col>

          </>
        }}
      </ProFormDependency>

      <Col span={6}>
        <ProFormText
          name="code"
          label="商品编码"
          width="sm"
          disabled={!!entity.id}
        />
      </Col>
      <Col span={6}>
        <Form.Item name={'unit'}>
          <ProFormText
            name="unit"
            label="库存单位"
            width="sm"
          />
        </Form.Item>
      </Col>

      {/* <Col span={6}>
        <ProFormText
          name="adjustPrice"
          label="价格尾数调整"
          width="sm"
        />
      </Col> */}



      {isPointMall && <>
        <Col span={6}>
          <Form.Item name={'pointPrice'}>
            <ProFormText
              rules={[{ required: true }]}
              name="pointPrice"
              label="兑换所需积分"
              width="sm"
            />
          </Form.Item>
        </Col>
      </>
      }

      <Col span={6}>
        <ProFormDigit
          name="autoCancelTime"
          label="订单未付款自动取消时间(秒)"
          width="sm"
          initialValue={0}
        />
      </Col>
      <Col span={6}>
        <ProFormSelect
          name='deliveryType'
          label='配送方式'
          width="sm"
          rules={[{ required: true }]}
          initialValue={1}
          options={getEnumValueList('DeliveryType').map((e: any) => ({
            label: e.desc,
            value: Number(e.code),
          }))}
        />
      </Col>
      <Col span={6}>
        <ProFormRadio.Group
          label="库存扣减方式"
          name="deductStock"
          initialValue={1}
          rules={[{ required: true }]}
          options={Object.entries<string>(DeductStock).map(([key, value]) => ({ value: Number.parseInt(key), label: value }))}
        />
      </Col>
      <Col span={6}>
        <div style={{ 'display': 'flex', justifyContent: 'space-between' }}>
          <div className="tags-box">
            <ProFormSelect
              label="标签"
              name="tags"
              width="sm"
              mode="multiple"
              options={tagsOption}
              fieldProps={{
                showArrow: true,
                optionItemRender(item) {
                  return <p>{item.label} <span style={{ color: '#999', fontSize: '12px' }}>- {item.comment}</span></p>;
                },
              }}
            />
          </div>
          <ProFormDependency name={['tags']}>
            {({ tags }) => {
              const show = tags ? tags.concat().filter((e: string) => e === 'BirthdayMonthPurchase') : [];
              return show.length > 0 && <div className="birthdayMonthPurchase">
                <Form.Item name={'customerLevelNo'}>
                  <SelectSearch placeholder='会员级别' valueType="number" label="会员级别" optionValue={'levelNo'} required={false} name={['customerLevelNo']} width={200} labelValue="levelName" defaultParams='levelNo.equals' fetchFunc={fetchLevelList} searchParams='levelName.contains' />
                </Form.Item>
              </div>
            }}
          </ProFormDependency>
        </div>
      </Col>
      {/* <Col span={1}></Col> */}

      <Col>
        <ProFormGroup>
          <ProFormSwitch
            name={'freeShipping'}
            label="是否包邮"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
          <ProFormSwitch
            name={'virtualProduct'}
            label="是否虚拟商品"
            fieldProps={{
              checked: isVirtualProduct,
              checkedChildren: '是',
              unCheckedChildren: '否',
              onChange: (val) => {
                setIsVirtualProduct(val);
              }
            }}
          />
          <ProFormDependency name={['virtualProduct']}>
            {({ virtualProduct }) => {
              return virtualProduct &&
                <ProFormSelect
                  name='virtualProductType'
                  label='虚拟类型'
                  rules={[{ required: true }]}
                  options={convertEnumToOptionType('virtualProductType')}
                  width={100}
                />
            }
            }
          </ProFormDependency>
          <ProFormDependency name={['virtualProduct', 'virtualProductType']}>
            {(({ virtualProduct, virtualProductType }) => {
              return virtualProduct &&
                virtualProductType === 'CashCoupon' &&
                <Form.Item name={'promotionId'}>
                  <SelectSearch required={false} name='promotionId' width="sm" label='代金券' labelValue="name" defaultParams='id.equals' fetchFunc={fetchSelectCouponCashList} searchParams='name.contains' />
                </Form.Item>
            })}
          </ProFormDependency>
        </ProFormGroup>
      </Col>
      <Col span={1}></Col>
      <Col span={3}>
        <ProFormSwitch
          name={'bundle'}
          label="是否组合商品"
          initialValue={false}
          fieldProps={{
            checkedChildren: '是',
            unCheckedChildren: '否',
          }}
        />
      </Col>

      <Col span={2}>
        <ProFormGroup>
          <ProFormDependency name={['freeShipping']}>
            {({ freeShipping }) => {
              return freeShipping &&
                <ProFormDigit
                  label="免邮金额"
                  name="freeFreightOrderAmount"
                  initialValue={0}
                  required={true}

                />;
            }}
          </ProFormDependency>
        </ProFormGroup>
      </Col>
      <Col span={1} />
      <Col span={2}>
        <ProFormDigit
          label="包装费"
          name="packageAmount"
          initialValue={0}
          required={true}
        />
      </Col><Col span={1} />
      <Col span={2}>
        <ProFormDigit
          label="排序"
          name="sort"
          initialValue={0}
          required={true}
        />
      </Col><Col span={1} />

      <Col span={24}></Col>
      <Col span={11}>
        <IntelFormText
          type='textArea'
          label="商品描述"
          name='subtitle'
          width={500}
          value={entity?.subtitle || ''}
          form={form}
          required={true}
        />
      </Col>
      <Col span={1}></Col>
      <Col span={11}>
        <IntelFormText
          type='textArea'
          width={500}
          label="商品卖点"
          name='feature'
          value={entity?.feature || ''}
          form={form}
          required={true}
        />
      </Col>
    </Row>
  </ProCard >
}

export default ProductEditCardBasic;