import { Activity,defaultActivity } from 'model/promotion/activity.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'activity';
const apiUrl = '/api/yumei-mall-promotion/promotion'
const standardReducerImpl = new StandardReducerImpl<Activity>(entity, defaultActivity, apiUrl);
export type ActivityState = StandardState<Activity>;
export const activity = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

