import { ProSettings } from "@ant-design/pro-layout/es/defaultSettings";
import logoImg from '../assets/Logo_1.png';

export default {
  navTheme: 'dark',
  // 拂晓蓝
  primaryColor: '#1890ff',
  layout: 'side',
  // splitMenus: true,
  contentWidth: 'Fluid',
  fixedHeader: true,
  autoHideHeader: false,
  fixSiderbar: true,
  colorWeak: false,
  menu: {
    locale: true,
  },
  logo:logoImg,
  title: '经销商',
  pwa: false,
  iconfontUrl: '',
} as ProSettings;
