import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ContractDetails from './list-details';
import ContractEdit from './list-edit';
import ContractList from './list-list';

const Contract: React.FC<RouteComponentProps> = () => (
  <Router>
    <ContractList path="/" />
    <ContractDetails path=":id" />
    <ContractEdit path=":id/edit" />
    <ContractEdit path="new" />
  </Router>
);
export default Contract;
