import { BaseModel } from "../base.model";

export interface PhysicalStores extends BaseModel {
    doorHeadPictureUrl?: string
    marriageCertificatePictureUrl?: string
    licPictureUrl?: string
    transferAccountPictureUrl?: string
    displayPictureUrl?: string
    planeSizePictureUrl?: string
    idCardFrontPictureUrl?: string
    idCardOppositePictureUrl?: string
    inStorePictureUrl?: string
    insideStorePictureUrl?: string
    storePictureUrl?: string
    myExitPictureUrl?: string
    trainRecords?: any
    times?: any
    authorizedStoresName?: any
    refundApplicationDetails?: any;
}


export const defaultPhysicalStores: Readonly<PhysicalStores> = {};