import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import LogisticscodeList from './logisticscode-list';

const LogisticsCode: React.FC<RouteComponentProps> = () => (
  <Router>
    <LogisticscodeList path="/" />
  </Router>
);
export default LogisticsCode;
