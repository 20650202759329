import React, { FC, ReactNode, useContext, useEffect, useState } from "react";
import ProProvider, { ProRenderFieldPropsType } from '@ant-design/pro-provider';
import {useEnumValue} from './enum/use-enum-value';

export const ExtraProProvider: FC<{children: ReactNode}> = ({children}) => {
    const values = useContext(ProProvider);
    const {enumTypeNames, getEnumValueDesc} = useEnumValue();
    const [valueTypeMap, setValueTypeMap] = useState<{ [index: string]: ProRenderFieldPropsType }>({});

    useEffect(() => {
        enumTypeNames.forEach(enumType => {
            valueTypeMap[enumType] = {
                render: (text: string) => {
                    return <>{getEnumValueDesc(enumType, text)}</>;
                }, //,
            }
        });

        setValueTypeMap(valueTypeMap);
        // eslint-disable-next-line
    }, [enumTypeNames, getEnumValueDesc]);

    return (
        <ProProvider.Provider
            value={{
                ...values,
                valueTypeMap,
            }}
        >
            {children}
        </ProProvider.Provider>);
};

export default ExtraProProvider;
