import {Spec, SpecDefineTuple} from '../../../model/product/product.model';
import {FC, useEffect, useState} from 'react';
import {Select} from 'antd';

export interface SkuSpecEditControlProps {
    onChange?: (value: Spec[]) => Spec[];
    value?: Spec[];
    specListData: SpecDefineTuple[];//specList 总数据
    data?: Spec[];
}

const SkuSpecEditControl: FC<SkuSpecEditControlProps> = (props) => {
    const {value, onChange, specListData, data} = props;
    const [specs, setSpecs] = useState<Spec[]>(data || []);

    useEffect(() => {
        const addNewSkuSpec = (specs: Spec[], spec: SpecDefineTuple) => {
            specs?.push({
                k: spec?.title,
                k_id: spec?.id,
                v: '',
                v_id: '',
            });
        }

        if (specListData.every(spec => !!spec.title)) {
            // 规则已经初始化完成。
            if (value?.length !== specListData.length) {
                // 调整item.specs。
                let index = 0;
                specListData.forEach(spec => {
                    if (specs?.length! <= index) {
                        // 新增
                        addNewSkuSpec(specs!, spec);
                    } else if (specs?.[index]?.k_id !== spec.id && specs?.[index].k !== spec.title) {
                        // 被删除。
                        specs?.splice(index, 1);

                        if (specs?.length! <= index) {
                            // 新增
                            addNewSkuSpec(specs!, spec);
                        }
                    }

                    index++;
                })
            }

            setSpecs(data || []);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [specListData]);

    const doms = specListData.map(spec =>
            <Select
                    key={`select_${spec.title}`}
                    value={specs?.filter(skuSpec => spec.title === skuSpec.k).map(skuSpec => skuSpec.v).shift() || ''}
                    options={spec.specValueList?.filter((e) => e.status !== 0)?.map(specValue => ({
                        label: specValue.specValue!,
                        value: specValue.specValue!,})) || []}
                    onChange={(val: string) => {
                        if(specs.length > 0 ){
                            const item = specs?.filter(skuSpec => spec.title === skuSpec.k).shift();
                            !!item && (item.v = val);
                            // console.log(specs)
                            onChange?.(specs);
                        }else{
                            
                        }
                        
                    }}
            />);
    return <>{doms}</>;
}

export default SkuSpecEditControl;