import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import VideoCategoryList from './category-list';
import VideoCategoryDetails from "./category-details";
import VideoCategoryEdit from "./category-edit";

const VideoCategory: React.FC<RouteComponentProps> = () => (
    <Router>
        <VideoCategoryList path = "/"/>
        <VideoCategoryDetails path = ":id"/>
        <VideoCategoryEdit path = ":id/edit"/>
        <VideoCategoryEdit path = "new"/>
    </Router>
);
export default VideoCategory;
