import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ContractTemplateDetails from './contractTemplate-details';
import ContractTemplateEdit from './contractTemplate-edit';
import ContractTemplateList from './contractTemplate-list';

const ContractTemplate: React.FC<RouteComponentProps> = () => (
  <Router>
    <ContractTemplateList path="/" />
    <ContractTemplateDetails path=":id" />
    <ContractTemplateEdit path=":id/edit" />
    <ContractTemplateEdit path="new" />
  </Router>
);
export default ContractTemplate;
