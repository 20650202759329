import { DownloadOutlined } from '@ant-design/icons/lib';
import { ProColumns } from '@ant-design/pro-table';
import { Button, Dropdown, Menu } from 'antd';
import { useEffect, useState } from "react";

import { Excel } from 'antd-table-saveas-excel';
import { IExcelColumn } from 'antd-table-saveas-excel/app';
import { useEnumValue } from 'common/enum/use-enum-value';

export interface ExportDataDialogProps<T, ValueType> {
  columns?: ProColumns<T, ValueType>[];
  dataSource?: T[];
  entityName?: string;
  exportData?: any;
  downloadBtnName?: string;
  isDownloadAll?: boolean;
  isDisabled?: boolean;
  onClickFun?: any;
  downloadAllBtnName?: string;
  hiddenDownloadCurrent?: boolean;
  downloadText?: string;

  //订单相关
  downMoreOperateColumns?: ProColumns<T, ValueType>[];
  downMoreOperateDataSource?: T[];
}


const isObject = (arg: any) => {
  return Object.prototype.toString.call(arg) === '[object Object]'
}

const ExportDataDialog = <T, ValueType>(props: ExportDataDialogProps<T, ValueType>) => {

  const { columns = [],downloadText='下载', hiddenDownloadCurrent = false, exportData, downloadBtnName, downloadAllBtnName, isDownloadAll, isDisabled, onClickFun,
    dataSource = [],
    entityName = window.document.title.replace(' - 高级生产排产与排程', ''),
    downMoreOperateDataSource,
    downMoreOperateColumns,
  } = props

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const { getEnumValueDesc } = useEnumValue();

  useEffect(() => {
    dataSource.length > 0 ? setButtonDisabled(false) : setButtonDisabled(true);
    hiddenDownloadCurrent && setButtonDisabled(false);
  }, [dataSource, hiddenDownloadCurrent])

  async function downloadFile(ALL: boolean = false) {
    let sheetDataRe:any;
    //下载全部
    if (ALL) {
      const dataSourceAll = await exportData();
      sheetDataRe = dataSourceAll.flatMap((d: any) => (d && d.children && [d, ...d.children]) || [d]);
    } else {
      let downDataSource: any = onClickFun ? await onClickFun() : dataSource;
      sheetDataRe = downDataSource ? downDataSource.flatMap((d: any) => (d && d.children && [d, ...d.children]) || [d]) : [];
    }
    const sheetColumns = columns.map(e => {
      e.title = (e as any).excelTitle || e.title;
      return e;
    });
    const sheetData = sheetDataRe.map((e: any) => {
      const items = e.items;
      if (isObject(items)) {
        for (const key in items) {
          if (Object.prototype.hasOwnProperty.call(items, key)) {
            //这个判断 放到外面excelRender里面了
            // if(isObject(items[key])){
            //   const element = items[key]['name'] || '';
            //   (e as any)['items.'+key] = element
            // }else{
            const element = items[key];
            e['items.' + key] = element
            // }
          }
        }
      };

      let keyList = Object.keys(e);
      keyList.forEach(i => {
        e[i] = e[i] + ''
      });

      return e;
    })

    const convertEnumValue = (data: any, valueEnum: any, dataIndex: string) => {
      // eslint-disable-next-line eqeqeq
      return Object.fromEntries(Object.entries<string>(valueEnum).filter(([value, label]) => String(data[dataIndex]) == String(value)))[data[dataIndex]];
    }

    const excel = new Excel();
    excel.addSheet(`${entityName || '数据'}`)
      .addColumns(sheetColumns.map((column) => {
        return {
          ...column,
          excelRender: column.valueEnum ?
            ((text: any, record: any, index: number) => convertEnumValue(record, column.valueEnum, String(column.dataIndex))) :
            (
              column.valueType && column.valueType !== 'text' ?
                (text: any, record: any, index: number) => getEnumValueDesc(column.valueType + '', text || '') :
                (text => (text && text + '' !== 'undefined') ? text + '' : '')
            )
        }
      }) as IExcelColumn[])
      .addDataSource(sheetData)
      .saveAs(`${entityName || '下载数据'}.xlsx`);
  }

  async function downloadFileOrder(ALL: boolean = false) {
    let sheetDataRe;
    //下载全部
    if (ALL) {
      const dataSourceAll = await exportData('order');;
      sheetDataRe = dataSourceAll.flatMap((d: any) => (d && d.children && [d, ...d.children]) || [d]);
    } else {
      let downDataSource: any = onClickFun ? await onClickFun() : downMoreOperateDataSource;
      sheetDataRe = downDataSource ? downDataSource.flatMap((d: any) => (d && d.children && [d, ...d.children]) || [d]) : [];
    }
    const sheetColumns = downMoreOperateColumns && downMoreOperateColumns.map(e => {
      e.title = (e as any).excelTitle || e.title;
      return e;
    });

    const sheetData = sheetDataRe.map((e: any) => {
      const items = e.items;
      if (isObject(items)) {
        for (const key in items) {
          if (Object.prototype.hasOwnProperty.call(items, key)) {
            //这个判断 放到外面excelRender里面了
            // if(isObject(items[key])){
            //   const element = items[key]['name'] || '';
            //   (e as any)['items.'+key] = element
            // }else{
            const element = items[key] + '';
            e['items.' + key] = element
            // }
          }
        }
      }
      return e;
    })

    const convertEnumValue = (data: any, valueEnum: any, dataIndex: string) => {
      // eslint-disable-next-line eqeqeq
      return Object.fromEntries(Object.entries<string>(valueEnum).filter(([value, label]) => String(data[dataIndex]) == String(value)))[data[dataIndex]];
    }

    const excel = new Excel();
    sheetColumns && excel.addSheet(`${entityName || '数据'}`)
      .addColumns(sheetColumns.map((column) => {
        return {
          ...column,
          excelRender: column.valueEnum ?
            ((text: any, record: any, index: number) => convertEnumValue(record, column.valueEnum, String(column.dataIndex))) :
            (
              column.valueType && column.valueType !== 'text' ?
                (text: any, record: any, index: number) => getEnumValueDesc(column.valueType + '', text || '') :
                (text => (text && text + '' !== 'undefined') ? text + '' : '')
            )
        }
      }) as IExcelColumn[])
      .addDataSource(sheetData)
      .saveAs(`${entityName || '下载数据'}.xlsx`);
  }

  const menu = (
    <Menu>
      {/* 导出订单 */}
      {downMoreOperateColumns && <>
        <Menu.Item key="operateCurrentPageOrder" >
          <Button
            type="text"
            icon={<DownloadOutlined />}
            disabled={buttonDisabled || isDisabled}
            onClick={() => downloadFileOrder()}
          >
            下载当前页
          </Button>
        </Menu.Item>
        <Menu.Item key="operateAllOrder" >
          <Button
            type="text"
            icon={<DownloadOutlined />}
            disabled={buttonDisabled}
            onClick={() => downloadFileOrder(true)}
          >
            下载全部
          </Button>
        </Menu.Item>
      </>}
      {!hiddenDownloadCurrent && <Menu.Item key="下载当前页" >
        <Button
          type="text"
          icon={<DownloadOutlined />}
          disabled={buttonDisabled || isDisabled}
          onClick={() => downloadFile()}>
          {downloadBtnName ? downloadBtnName : '下载当前页'}</Button>
      </Menu.Item>}
      {
        !isDownloadAll && <Menu.Item key="下载全部">
          <Button
            type="text"
            icon={<DownloadOutlined />}
            disabled={buttonDisabled}
            onClick={() => downloadFile(true)}
          >
            {downloadAllBtnName ? downloadAllBtnName : '下载全部'}
          </Button>
        </Menu.Item>
      }
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Button >
        {downloadText} <DownloadOutlined />
      </Button>
    </Dropdown>
  )
}

export default ExportDataDialog