import React from "react";
import { RouteComponentProps, Router } from "@reach/router";
import "./index.less";
import Unit from "./unit";
import DealerCustomer from "./customer";
import DealerLevel from "./level";
import DealerInfo from "./dealerInfo";
import RelationshipTree from "./relationshipTree";
// import RelationAdmin from "./relationAdmin";
import Evaluation from "./evaluation";
import SecondSettled from "./secondSettled";
import SecondReview from "./secondReview";
import DealerBrandLevel from "./brandLevel";
import Assort from "./assort";
import Article from "./article";
import Complaint from "./complaint";
import DirectAscent from "./directAscent";
const Dealer: React.FC<RouteComponentProps> = () => (
  <Router>
    <Unit path="unit/*" />
    <DealerCustomer path="customer/*" />
    <DealerLevel path="level/*" />
    <DealerBrandLevel path="brand-level/*" />
    <DealerInfo path="info/*" />
    <RelationshipTree path="relationshipTree/*" />
    {/* <RelationAdmin path="relationTree/*" /> */}
    <Evaluation path="evaluation/*" />
    <SecondSettled path="second-settled/*" />
    <SecondReview path="second-review/*" />
    <DirectAscent path="direct-ascent/*" />
    <Assort path="assort/*" />
    <Article path="article/*" />
    <Complaint path="complaint/*" />
  </Router>
);
export default Dealer;
