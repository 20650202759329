import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { ReturnGoods, defaultReturnGoods } from 'model/product/returnGoods.model';
import axios from 'axios';

const entity = 'ReturnGoods';
const apiUrl = '/api/yumei-mall-pim/product';
const standardReducerImpl = new StandardReducerImpl<ReturnGoods>(entity, defaultReturnGoods, apiUrl);
export type ReturnGoodsState = StandardState<ReturnGoods>;
export const returnGoods = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const modifyReturnGoods = async (isReturn:any, data?: any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-pim/product/return-product?isReturn=${isReturn}`, data);
    return axiosResponse.data;
}

export const modifyPackageAmount = async (id:any, params?: any) => {
    const axiosResponse = await axios.get(`/api/yumei-mall-pim/product/package-amount/${id}`, {params});
    return axiosResponse.data;
}


