import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./version.reducer";
import { ProFormDateTimePicker, ProFormDigit, ProFormSelect, ProFormSwitch, ProFormText, ProFormUploadButton } from "@ant-design/pro-form";
import { Platform, Version } from "model/system/version.model";
import { Col, Form, message, Row } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { uploadImage } from "utils/file-utils";
import { Storage } from 'utils/storage-util';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
export interface VersionEditProps extends EditStateAndDispatchProps<Version>, StateProps, DispatchProps, RouteComponentProps {
}

const VersionEdit: FC<VersionEditProps> = props => {
  const {
    entity,
  } = props;

  const [downloadList, setDownloadList] = useState<any>([]);
  const xTenant = Storage.session.get("x-tenant");

  const handleDownload = (res: any) => {
    setDownloadList(res.fileList);
  };

  useEffect(() => {
    if (entity.downloadUrl) {
      (entity.downloadUrl && props.entity.downloadUrl !== '') && showDownload(entity.downloadUrl);
    } else {
      (!entity.downloadUrl || entity.downloadUrl === undefined) && setDownloadList([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.id]);

  const showDownload = (res: any) => {
    if (downloadList.length === 0) {
      if (typeof (res) === 'string') {
        const logoArray = {
          name: '更新文件',
          status: 'done',
          url: res,
        };
        setDownloadList([logoArray]);
      } else {
        setDownloadList(res);
      }
    }
  }

  const uploadEntity = async (res?: Version) => {
    return new Promise<Version>((resolve, reject) => {
      if (res) {
        // res.disabled = true;
        if (!res.downloadUrl || typeof (res.downloadUrl) === 'string') {
          resolve(res);
        } else {
          const data: any = res.downloadUrl;
          uploadImage(data[0].originFileObj, 'app/app-' + xTenant + '-' + res.versionCode).then(async imageName => {
            res.downloadUrl = imageName;
            resolve(res);
          }).catch(() => { message.error('上传失败'); reject() });
        }
      }
    })
  }

  return (
    <EditPage<Version>
      title="App更新提醒"
      beforeUpdateEntity={uploadEntity}
      {...props}
    >
      <Row justify='space-between'>
        <Col span="7">
          <ProFormText
            name="versionName"
            label="版本名称"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span="7">
          <ProFormDigit
            name="versionCode"
            label="版本号"
            rules={[{ required: true }]}
            min={0}
          />
        </Col>
        <Col span="7">
          <ProFormDateTimePicker
            name="publishTime"
            label="发布时间"
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
      <Form.Item
        name="changeLog"
        label="更新内容"
        style={{ width: '550px' }}
        rules={[{ required: true }]}
      >
        <TextArea placeholder="请输入更新内容" allowClear rows={4}></TextArea>
      </Form.Item>
      <ProFormUploadButton
        listType="picture-card"
        label="上传文件"
        name="downloadUrl"
        title="上传"
        fileList={downloadList}
        onChange={handleDownload}
        fieldProps={{
          maxCount: 1,
          className: "pro-upload-picture-box"
        }}
      />
      <Row justify='space-between'>
        <Col span="7">
          <ProFormSelect
            rules={[{ required: true }]}
            name="platform"
            options={Object.entries<string>(Platform).map(([value, label]) => ({ value, label }))}
            label="app平台"
          />
        </Col>
        <Col span="7">
          <ProFormSwitch name="forceUpdate"
            label="是否强制更新"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
        <Col span="7">
          <ProFormSwitch name="disabled"
            label="是否禁用"
            initialValue={true}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
        <Col span="7"></Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ version }: RootState) => ({
  entity: version.entity,
  updateSuccess: version.updateSuccess,
  updating: version.updating,
  createSuccess: version.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VersionEdit);
