import React, { useEffect, useState } from 'react'
import { ProFormTextArea } from '@ant-design/pro-form'
import { FormInstance, Input, Select, Space } from 'antd';
import { Form } from 'antd';
import { useParamGeneral } from './useParamGenera';
import { Storage } from 'utils/storage-util';
const { Option } = Select;

export const strParseJson = (str: string) => {
  if (typeof str == 'string') {
    try {
      var obj = JSON.parse(str);
      if (typeof obj == 'object' && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
  return false;
}

// 详情
export const internationalDetails = (str: string, inter?: string) => {
  if (strParseJson(str)) {
    const obj = JSON.parse(str);
    let title = '';
    for (const key in obj) {
      title += `<p>${obj[key]}</p>`
    }
    return title
  } else {
    return str;
  }
}

//列表
export const internationalList = (str: string) => {
  const paramGeneral = Storage.session.get("param-general") || [];//商城基础配置数据
  const generalLanguage = paramGeneral.filter((item: any) => item.paramKey === "general.language") || [];//默认语言对象
  const lag = generalLanguage[0] && (generalLanguage[0].paramValue || generalLanguage[0].defaultValue)//默认语言
  // const lag = 'zh-CN'//默认语言
  if (strParseJson(str)) {
    const obj = JSON.parse(str);
    for (const key in obj) {
      return obj[lag]
    }
  } else {
    return str;
  }
}

export interface InterFormTextProps {
  type?: string
  value: string
  label: String
  name: string
  width?: number;
  form?: FormInstance;
  required?: boolean;
  fieldProps?: {
    maxLength?: number | undefined;
  }
  extra?: React.ReactNode;
  onChange?: any;
}

export const IntelSelectVal = (props: any) => {
  return <>
    {
      props.intlArr.length > 1 &&
      <Select value={props.defaultValue} bordered={!!props.bordered} onChange={props.onChange} onClick={(e: any) => e.stopPropagation()}>
        {
          props.generalLanguage[0] && props.generalLanguage[0].allowedValues.map((item: any) => <>
            {
              props.intlArr.includes(item.value) &&
              <Option key={item.value} value={item.value}>{item.label}</Option>
            }
          </>
          )
        }
      </Select>
    }
  </>
}

const IntelFormText = (props: InterFormTextProps) => {
  const { type, value, label, name, form, fieldProps, required, extra, width,onChange } = props;
  
  const { generalLanguage, intlArr, defaultLanguage } = useParamGeneral();
  const [textVal, setTextVal] = useState('');
  const [interKey, setInterKey] = useState<string>('');
  const [interVal, setInterVal] = useState('');

  useEffect(() => {
    setInterKey(defaultLanguage);
  }, [defaultLanguage])

  useEffect(() => {
    if (intlArr.length === 1) {
      let newTextVal = '';
      textVal && (strParseJson(textVal) ? (newTextVal = textVal) : (newTextVal = `{"${defaultLanguage}": "${textVal}"}`));
      const obj = newTextVal ? JSON.parse(newTextVal.replace(/\n/g, "\\n").replace(/\r/g, "\\r")) : { [defaultLanguage]: '' };
      defaultLanguage && form?.setFieldsValue({ [name]: obj[defaultLanguage] });
    } else {
      // form?.setFieldsValue({ [name]: textVal })
      if(textVal){
        if(onChange){
          onChange(textVal);
        }else{
          form?.setFieldsValue({ [name]: textVal })
        }
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textVal]);


  useEffect(() => {
    if (strParseJson(value)) {
      const obj = JSON.parse(value);
      setInterVal(obj[defaultLanguage]);
      setTextVal(value);
    } else {
      setInterVal(value);
      setTextVal(intlArr && intlArr.length > 1 ? `{"${defaultLanguage}": "${value}"}` : value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, intlArr])

  // 语言改变
  useEffect(() => {
    if (strParseJson(textVal)) {
      const obj = JSON.parse(textVal);
      setInterVal(obj[interKey]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interKey])

  //value改变 
  useEffect(() => {
    if (strParseJson(textVal)) {
      const obj = JSON.parse(textVal);
      obj[interKey] = interVal;
      setTextVal(JSON.stringify(obj));
    } else {
      setTextVal(interVal);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interVal])

  const SelectVal = () => {
    return <IntelSelectVal generalLanguage={generalLanguage} intlArr={intlArr}
      defaultValue={interKey} bordered={!!type} onChange={(e: string) => setInterKey(e)} />
  }

  return (
    <>
      <ProFormTextArea hidden={true} name={name} fieldProps={{ value: textVal }} />
      {
        type === 'textArea' ? <Space>
          <ProFormTextArea name={name} label={label} width={width}
            fieldProps={{
              value: !!interVal ? interVal : '',
              onChange: (e) => setInterVal(e.target.value)
            }}
          />
          <SelectVal key={name} />
        </Space> :
          <>
            <Form.Item style={{ width }} label={label} required={required} extra={extra}>
              <Input
                size={`${intlArr.length > 1 ? 'small' : 'middle'}` as any}
                maxLength={fieldProps?.maxLength || 10000}
                value={!!interVal ? interVal : ''}
                onChange={(e) => setInterVal(e.target.value)}
                suffix={<SelectVal key={name} />}
              />
            </Form.Item>
          </>
      }
    </>
  )
}

export default IntelFormText
