import React, { ReactNode, useEffect, useState } from "react"
import { Button, PageHeader, PageHeaderProps } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { RouteComponentProps } from '@reach/router';
import { BaseModel } from "model/base.model";
import { ProColumns } from "@ant-design/pro-table";
import { CrudGetAction } from "reducers/redux-action.type";

export interface DetailsStateAndDispatchProps<T extends BaseModel, ValueType = 'text'> {
    entity: T;
    columns?: ProColumns<T, ValueType>[];
}

export interface DetailsPageProps<T extends BaseModel, ValueType = 'text'> extends RouteComponentProps,
    DetailsStateAndDispatchProps<T, ValueType>,
    Omit<PageHeaderProps, 'breadcrumb' | 'breadcrumbRender'> {
    children?: ReactNode;
    hideEdit?: boolean;
    operation?: ReactNode[];
    editPage?: (params: any) => ReactNode;
    updateSuccess?: boolean;
    getEntity?: CrudGetAction<T>;
}

const DetailsPage = <T extends BaseModel, ValueType = 'text'>(props: DetailsPageProps<T, ValueType>) => {
    const {
        entity,
        operation = [],
        // children,
        updateSuccess,
        getEntity,
        editPage,
        ...pageHeaderProps
    } = props;
    const [editModal, setEditModal] = useState<any>({ text: '', id: 0 });
    const [isEditPage, setIsEditPage] = useState(true);

    useEffect(() => {
        getEntity && updateSuccess && entity?.id && getEntity(entity?.id);
        setIsEditPage(false);
    //   eslint-disable-next-line react-hooks/exhaustive-deps
      }, [updateSuccess, getEntity]);

    return (
        <>
            <PageHeader
                style={{ backgroundColor: 'white'}}
                extra={
                    <>{!props.hideEdit && (<Button
                        type="primary"
                        icon={<EditOutlined />}
                        onClick={() => {
                            setIsEditPage(true);
                            entity?.id && setEditModal({ text: new Date(), id: entity.id as any })
                        }}
                    >编辑</Button>)}
                        {operation}
                    </>}
                {...pageHeaderProps}
            >
            </PageHeader>
            {editPage && isEditPage && editPage(editModal)}
        </>
    );
};

export default DetailsPage;
