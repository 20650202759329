import { FC, ReactNode, useState } from "react";
import { Form, Input, Image, Button, message } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import FeedbackDetails from "./feedback-details";
import { deleteEntity, fetchFeedBackProcessedById, fetchMessageList, getEntities, getEntity, sendMessage, updateExamine } from "./feedback.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import FeedbackEdit from "./feedback-edit";
import { Feedback, FeedbackState, FeedbackType } from "model/system/feedback.model";
import { ModalForm, ProFormDateTimePicker, ProFormSelect, ProFormTextArea } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import { fetchMessageTemplateMList } from "pages/dealer/unit/unit.reducer";

export interface FeedbackListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Feedback>[] = [{
  title: 'id',
  dataIndex: 'id',
  ellipsis: true,
},  {
  title: '问题类别',
  dataIndex: 'type',
  valueEnum: FeedbackType
}, {
  title: '反馈问题类型',
  dataIndex: 'typeItem',
}, {
  title: '具体内容描述',
  dataIndex: 'feedBackItem',
}, {
  title: '反馈图片',
  dataIndex: 'feedBackImgs',
  width: 150,
  render: (text: any, record: any) => {
    console.log(record)
    return <>{record.feedBackImgs && record.feedBackImgs.split(',').map((e: any) => {
      return <Image src={e} width={80} />
    })}</>
  }
}, {
  title: '设备名称',
  dataIndex: 'deviceText',
}, {
  title: '授权号',
  dataIndex: 'submitCode',
}, {
  title: '姓名',
  dataIndex: 'submitName',
}, {
  title: '联系电话',
  dataIndex: 'submitPhone',
}, {
  title: '提交时间',
  dataIndex: 'submitTime',
}, {
  title: '处理状态',
  dataIndex: 'state',
  valueEnum: FeedbackState
}, {
  title: '处理详情',
  dataIndex: '',
}];


const FeedbackList: FC<FeedbackListProps> = (props) => {
  const [templateObj, setTemplateObj] = useState<any>({});
  const [refresh, setRefresh] = useState<number>(0);
  const searchFields = [{
    label: '联系电话',
    name: 'submitPhone.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          name="type.equals"
          label="问题类别"
          options={Object.entries<string>(FeedbackType).map(([value, label]) => ({ value: (value), label }))}
          width="md"
        />
        <ProFormSelect
          name="state.equals"
          label="处理状态"
          options={Object.entries<string>(FeedbackState).map(([value, label]) => ({ value: (value), label }))}
          width="md"
        />
        <ProFormDateTimePicker
          name={"submitTime.greaterThanOrEqual"}
          placeholder="提交开始时间"
        />
        <ProFormDateTimePicker
          name={"submitTime.lessThanOrEqual"}
          placeholder="提交结束时间"
        />
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const fetchFeedBackProcessedColumns = [{
    title: '处理人',
    dataIndex: 'processedName',
  }, {
    title: '处理时间',
    dataIndex: 'processedTime',
  }, {
    title: '处理状态',
    dataIndex: 'state',
    valueEnum: FeedbackState
  }, {
    title: '处理详情',
    dataIndex: 'processedText',
  }]

  const MessageListColumns = [{
    title: '推送人',
    dataIndex: 'createdBy',
    with:100,
  }, {
    title: '推送时间',
    with:100,
    dataIndex: 'submitTime',
  }, {
    title: '推送短信内容',
    with:300,
    dataIndex: 'content',
  }]

  const rowActions = [
    (dom: ReactNode, record: Feedback) => {
      return <ModalForm
        title={'用户反馈处理'}
        width={800}
        modalProps={{
          destroyOnClose: true,
        }}
        trigger={<Button type="link">处理</Button>}
        onVisibleChange={() => setRefresh(new Date().getTime())}
        onFinish={async (data) => {
          return new Promise<boolean>((resolve, reject) => {
            updateExamine({ ...data, id: record.id }).then(e => {
              message.success('处理成功');
              resolve(true);
            }).catch(error => {
              message.error(error?.response?.data?.detail);
              resolve(false);
            })
          })
        }}
      >
        <ProTable
          className="payTable"
          bordered
          options={false}
          search={false}
          columns={fetchFeedBackProcessedColumns}
          params={{ refresh: refresh }}
          request={async (params) => {
            const result = await fetchFeedBackProcessedById({
              page: (params?.current && params?.current - 1) || 0,
              size: params?.pageSize || 20,
              id: record.id,
              ...params
            });
            return {
              success: true,
              total: result.headers?.['x-total-count'],
              data: result.data || [],
            };
          }}
          pagination={false}
        />
        <ProFormSelect
          name="state"
          label="处理状态"
          options={Object.entries<string>(FeedbackState).map(([value, label]) => ({ value: Number(value), label }))}
          width="md"
        />
        <ProFormTextArea
          name="processedText"
          label="处理详情"
          width="md"
        />
      </ModalForm>
    }, (dom: ReactNode, record: any) => {
      return <ModalForm
        title={'推送短信记录'}
        width={800}
        modalProps={{
          destroyOnClose: true,
        }}
        trigger={<Button type="link">推送消息</Button>}
        onFinish={async (data) => {
          return new Promise<boolean>((resolve, reject) => {
            sendMessage({ ...data, feedBackId: record.id }).then(e => {
              message.success('推送成功');
              resolve(true);
            }).catch(error => {
              message.error(error?.response?.data?.detail);
              resolve(false);
            })
          })
        }}
      >
        <ProTable
          className="payTable"
          bordered
          options={false}
          search={false}
          columns={MessageListColumns}
          request={async (params) => {
            const result = await fetchMessageList({
              page: (params?.current && params?.current - 1) || 0,
              size: params?.pageSize || 20,
              phone: record.submitPhone,
              ...params
            });
            return {
              success: true,
              total: result.headers?.['x-total-count'],
              data: result.data || [],
            };
          }}
          pagination={false}
        />
        <Form.Item name="id" label="消息模板">
          <SelectSearch width={'md'} fetchOptionObj={(obj: any) => setTemplateObj(obj)} required={false} valueType="number" name="id" placeholder="消息模板" labelValue="name" defaultParams='' fetchFunc={fetchMessageTemplateMList} searchParams='name.contains' />
        </Form.Item>
        <ProFormTextArea
          name="processedText"
          label="短信内容"
          disabled
          fieldProps={{ value: templateObj.templateContent || '' }}
          width={700}
        />
      </ModalForm>
    }
  ]

  return (
    <ListViewPage<Feedback>
      columns={columns}
      hideDeleteButton
      hideCreateButton
      hideEditButton
      rowActions={rowActions}
      quickFilterPanel={quickFilterPanel}
      detailPage={<FeedbackDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      editPage={(params: any) => <FeedbackEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ feedback }: RootState) => ({
  entity: feedback.entity,
  entities: feedback.entities,
  loading: feedback.loading,
  updateSuccess: feedback.updateSuccess,
  createSuccess: feedback.createSuccess,
  total: feedback.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackList);