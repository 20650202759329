import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';
import { defaultPricingRules, priceRulesModel } from 'model/product/pricingRules';

const entity = 'level';
const apiUrl = '/api/yumei-mall-pim/pricing_rules';
const standardReducerImpl = new StandardReducerImpl<priceRulesModel>(entity, defaultPricingRules, apiUrl);
export type PricingRulesState = StandardState<priceRulesModel>;
export const pricingRules = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 查询所有等级
export const fetchLevelList = async () => {
    const requestUrl = `/api/yumei-mall-pim/brand/level/list?sort=levelSort,asc`;
    const axiosResponse = await axios.get<any>(requestUrl, {});
    return axiosResponse.data;
  }



  
  
