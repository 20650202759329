import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './equity.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Equity } from "model/customerCore/equity.model";
import ProTable from "@ant-design/pro-table";
import Title from "antd/lib/typography/Title";

export interface EquityDetailsProps extends DetailsStateAndDispatchProps<Equity>,
  RouteComponentProps, StateProps, DispatchProps {
}
const EquityDetails: FC<EquityDetailsProps> = (props) => {
  return (
    <DetailsPage<Equity>
      title={'权益详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Equity>
        columns={props.columns as any}
        dataSource={props.entity}
        column={1}
      >
      </ProDescriptions>
      <Title style={{marginTop: '20px'}} level={5}>权益列表</Title>
      <ProTable<any>
        columns={[{
          title: '会员级别',
          dataIndex: ['customerLevel', 'levelName'],
        }, {
          title: '权益明细',
          dataIndex: 'rightDetail',
        }]}
        className="details_tab"
        rowKey="id"
        dataSource={props.entity?.customerRightDetails || [] as any}
        options={false}
        pagination={false}
        search={false}
      />
    </DetailsPage>);
};

const mapStateToProps = ({ equity }: RootState) => ({
  entity: equity.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EquityDetails);
