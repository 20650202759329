import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import Role from "./role";
import User from "./user";
import Client from "./client";
import Permission from "./permission";
import Resources from "./resources";
import PowerModule from "./power-module";
import Feedback from "./feedback";

const UserManage: React.FC<RouteComponentProps> = () => (
  <Router>
    <Role path="role/*" />
    <Permission path="permission/*" />
    <User path="user/*" />
    <Client path="client/*" />
    <PowerModule path="module/*" />
    <Resources path="resources/*" />
    <Feedback path="feedback/*" />
  </Router>
);
export default UserManage;
