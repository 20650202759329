import React, { FC, useState, memo, useEffect } from 'react';
import { Tabs, Empty, Spin, Form, Radio, DatePicker } from 'antd';
import '../style/tingmimi_hot.less';
import { getActivityGoodsList, getGoodsDetailByIds } from '../../api';
import GoodsList from '../goods-list';
import moment from 'moment';
import { goodsStyleListSimple } from '../../../../model/decorate/decorate.model';
const { TabPane } = Tabs;
const { RangePicker } = DatePicker;

export interface Props {
    dataField: {
        nav: any[]
        [key: string]: any
    }
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const RenderGoodsList: FC<{ data: { [key: string]: any }, listStyle: string }> = memo(({ data, listStyle }) => {

    const [list, setList] = useState([])
    const [state, setState] = useState({
        noData: false,
        noMore: false,
        loading: true
    })


    useEffect(() => {
        let list: any = [];
        if (data.dayList.length === 0) {
            setState({
                loading: false,
                noMore: false,
                noData: true
            })
            return
        }
        data.dayList.forEach(async (v: any, i: number) => {
            if (!v.products) {
                setState({
                    loading: false,
                    noMore: true,
                    noData: false
                })
                return
            }
            let ids = v.products.map((v: any, i: number) => {
                return v.productId
            })
            let res: any = await getGoodsDetailByIds(ids, data.SkytoneCard)
            list[i] = {
                startDate: v.startDate,
                list: res.data
            }
            if (list.length === data.dayList.length) {
                setList(list)
                setState({
                    loading: false,
                    noMore: true,
                    noData: false
                })
            }
        })


    }, [data, setList,])
    return <div className="goods-list-container" onScroll={
        (e: any) => {

        }
    }>
        {
            list && list.length > 0 && list.map((v: any, i: number) => {
                return <div className="goods-list-item">
                    {
                        data.type === 'month' && <p className="title">{v.startDate}</p>
                    }
                    <GoodsList list={v.list} type={listStyle || 'big-img'} />
                </div>
            })
        }

        {
            state.noData && <div className="pt"><Empty description={''} /></div>
        }
        {
            state.noMore && <div className="no-more">没有更多商品了</div>
        }
        {
            state.loading && <div className="loading-con" style={
                {
                    paddingTop: `${80}px`
                }
            }><Spin /></div>
        }
    </div>
})
const TingmimiHot: FC<Props> = memo(({ dataField }) => {
    const [tabIndex, setTabIndex] = useState('0');
    const dateFormat = 'YYYY-MM-DD';
    const [navList, setNavList] = useState([] as any)
    useEffect(() => {
        let navList: any = [], currentDate = new Date();
        dataField.nav.forEach(async (v: any, i: number) => {
            let startDate: string = moment(currentDate).format(dateFormat), days: number = 1;
            if (v.type === 'month') {
                let startOf,
                    endOf

                if (v.start_time) {
                    startOf = moment(new Date(v.start_time.replace(/-/g, '/')), dateFormat)
                    endOf = moment(new Date(v.end_time.replace(/-/g, '/')), dateFormat)
                } else {
                    startOf = moment(currentDate, dateFormat).startOf('month')
                    endOf = moment(currentDate, dateFormat).endOf('month')
                }
                startDate = startOf.format(dateFormat)
                days = endOf.diff(startOf, 'days');
            } else if (v.type === 'today') {
                startDate = moment(currentDate).format(dateFormat)
                days = 1
            } else if (v.type === 'tomorrow') {
                startDate = moment(currentDate).add(1, 'day').format(dateFormat)
                days = 1
            }
            let res: any = await getActivityGoodsList({
                identifier: dataField.identifier,
                startDate: startDate,
                days: days,
                sellerId: '0'
            })
            let dayList = [], dayObj: any = {};
            if (res.data) {
                res.data.forEach((v: any) => {
                    if (v?.activityAction && v.activityAction?.items) {
                        let products = v.activityAction.items.map((v: any) => {
                            return v
                        })
                        if (dayObj[v.startDate]) {
                            dayObj[v.startDate].products.push(products)
                        } else {
                            dayObj[v.startDate] = {
                                products: products,
                                id: v.id,
                                startDate: moment(v.startDate).format('MM-DD')
                            }
                        }
                    }

                })
            }


            for (let k in dayObj) {
                dayList.push(dayObj[k])
            }
            dayList = dayList.reverse()
            navList.push({
                ...v,
                dayList
            })
            if (navList.length === dataField.nav.length) {
                navList = navList.sort((a: any, b: any) => a.sort - b.sort)
                setNavList(navList)
            }
        })
    }, [dataField.nav, dataField.identifier])
    return (
        <div className={`tingmimi-hot-container ${dataField.pageStyle}`} style={
            {
            }
        }>
            <Tabs activeKey={tabIndex} className="list-nav-tabs" onChange={
                (e) => {
                    setTabIndex(e)
                }
            }>
                {
                    navList && navList.length > 0 && navList?.map((v: any, i: number) => {
                        return <TabPane tab={`${v.name}`} key={i + ''} >
                            {
                                tabIndex === `${i}` && <RenderGoodsList listStyle={dataField.listStyle.selected} data={v} />
                            }
                        </TabPane>
                    })
                }


            </Tabs>

        </div>
    )
})

// interface ImageItemProps {
//     id: string
//     index: number
//     data?: any
//     updateModuleDataPrivate: (dataField: any, index: number, type?: string) => void
//     setShowLinkSelectionPrivate: (show: boolean) => void
//     setCallerIndex: (value: any) => void
//     setShowDrawer: (value: boolean) => void
// }


export const TingmimiHotSet: FC<SetProps> = (({ dataField, id, methods, selectedInfo, }) => {

    const dateFormat = 'YYYY-MM-DD';
    const defaultDateRange: any = [moment(dataField.nav[0].start_time || new Date(), dateFormat), moment(dataField.nav[0].end_time || new Date(), dateFormat)];
    if (!dataField.nav[0].start_time) {
        defaultDateRange[0] = defaultDateRange[0].startOf('month')
        defaultDateRange[1] = defaultDateRange[1].endOf('month')

        let nav = dataField.nav;
        nav[0] = {
            ...nav[0],
            start_time: defaultDateRange[0].format(dateFormat),
            end_time: defaultDateRange[1].format(dateFormat),
        }
        methods.updateModuleData({
            ...dataField,
            nav: nav
        }, id)
    }
    return (
        <div className="tingmimi-hot-set" >
            <Form.Item label="本月区间" >
                <RangePicker
                    onChange={
                        (e: any) => {
                            let nav = dataField.nav;
                            nav[0] = {
                                ...nav[0],
                                start_time: e[0].format(dateFormat),
                                end_time: e[1].format(dateFormat),
                            }
                            methods.updateModuleData({
                                ...dataField,
                                nav,
                            }, id)
                        }
                    }
                    defaultValue={defaultDateRange}
                />
            </Form.Item>
            <Form.Item label="列表风格" >
                <Radio.Group value={dataField.listStyle.selected} onChange={
                    (e) => {
                        methods.updateModuleData({
                            ...dataField,
                            listStyle: {
                                ...dataField.listStyle,
                                selected: e.target.value
                            },
                        }, id)
                    }
                }>
                    {
                        goodsStyleListSimple.map((v: any, i: number) => {
                            return <Radio.Button value={v.value} key={i}>{v.label}</Radio.Button>
                        })
                    }
                </Radio.Group>
            </Form.Item>
        </div>
    )
})

export default TingmimiHot