import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './feedback.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Feedback } from "model/system/feedback.model";

export interface FeedbackDetailsProps extends DetailsStateAndDispatchProps<Feedback>,
  RouteComponentProps, StateProps, DispatchProps {
}
const FeedbackDetails: FC<FeedbackDetailsProps> = (props) => {
  return (
    <DetailsPage<Feedback>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Feedback>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ feedback }: RootState) => ({
  entity: feedback.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackDetails);
