import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDigit, ProFormGroup, ProFormText } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./express.reducer";
import { Express } from "model/system/express";

export interface ExpressEditProps extends EditStateAndDispatchProps<Express>, StateProps, DispatchProps, RouteComponentProps {
}

const ExpressEdit: FC<ExpressEditProps> = (props) => {

    return (
        <EditPage<Express>
            title="快递公司"
            containIdCreat={props?.entity?.id ? false : true}
            {...props}
        >
            <ProFormGroup>
                <ProFormText
                    width='md'
                    name="expressCode"
                    label="快递公司编码"
                    rules={[{ required: true }]}
                />
                <ProFormText
                    width='md'
                    name="expressName"
                    label="快递公司名称"
                    rules={[{ required: true }]}
                />
                  <ProFormText
                    width='md'
                    name="externalExpressCode"
                    label="中台系统快递编码"
                    rules={[{ required: false }]}
                />
                <ProFormText
                    width='md'
                    name="externalExpressName"
                    label="中台快递名称"
                    rules={[{ required: false }]}
                />
              

                <ProFormDigit
                width='md'
                    name="sort"
                    label="排序"
                    rules={[{ required: true }]}
                    min={0}
                />

            </ProFormGroup>
        </EditPage>);
};

const mapStateToProps = ({ express }: RootState) => ({
    entity: express.entity,
    updateSuccess: express.updateSuccess,
    updating: express.updating,
    createSuccess: express.createSuccess,
});

const mapDispatchToProps = {
    getEntity,
    createEntity,
    updateEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExpressEdit);
