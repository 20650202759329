import { BaseModel } from "../base.model";

export interface Feedback extends BaseModel {

}

export const FeedbackState = {
    1: '待处理', 2: '无需处理', 3: '暂无法处理', 4: '处理完成'
}
export const FeedbackType = {
    1: '使用问题', 2: '功能建议'
}

export const defaultFeedback: Readonly<Feedback> = {};