import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './group.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Group } from "model/customerCore/group.model";

export interface GroupDetailsProps extends DetailsStateAndDispatchProps<Group>,
  RouteComponentProps, StateProps, DispatchProps {
}

const GroupDetails: FC<GroupDetailsProps> = (props: any) => {

  return (
    <DetailsPage<Group>
      title='会员详情'
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Group>
        column={2}
        columns={props.columns}
        dataSource={props.entity}
      />
    </DetailsPage>);
};

const mapStateToProps = ({ group }: RootState) => ({
  entity: group.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(GroupDetails);
