import { FC, ReactNode, useState } from "react";
import { Form, Input, Image, Tooltip, message, Tag, Button } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import PhysicalStoresDetails from "./physicalStores-details";
import { auditRefuse, deleteEntity, getEntities, getEntity, storeTypeMap } from "./physicalStores.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ToExamineDialog from '../toExamineDialog'
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { statusList, statusMap } from "../excellentStore/excellentStore.reducer";
import { internationalList } from "components/IntelFormText";
import { PhysicalStores } from "model/dealerStore/physicalStores.model";

export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}


const columns: ProColumns<PhysicalStores>[] = [{
  title: 'ID',
  dataIndex: 'id',
  width: 100
},
{
  title: '姓名',
  dataIndex: 'userName',
  width: 120
},
{
  title: '电话号码',
  dataIndex: 'storePhone',
  width: 120
},
{
  title: '授权码',
  dataIndex: 'authorizationCode',
  width: 120
},
{
  title: '门店类型',
  dataIndex: 'storeType',
  width: 100,
  valueEnum: storeTypeMap,
},
{
  title: '地址',
  dataIndex: 'address',
  width: 130,
  render: (text, record: any) => {
    return record.address?.provinceName + record.address?.cityName + record.address?.districtName + record.address?.address
  }
},
{
  title: '门店名称',
  dataIndex: 'storeName',
  width: 100
},
{
  title: '面积',
  dataIndex: 'storeArea',
  width: 60
},
{
  title: '开业时间',
  dataIndex: 'openingTime',
  width: 100
},
{
  title: '营业时间',
  dataIndex: 'businessHours',
  width: 100
},
{
  title: '授权品牌',
  dataIndex: 'authorizedStoresName',
  width: 140,
  render: ((dom, record) => {
    return record.authorizedStoresName && record.authorizedStoresName.map((item: any) => {
      return <Tag color='#1890ff' style={{ marginBottom: '2px' }}>{internationalList(item)}</Tag>
    })
  })
},
{
  title: '特色服务',
  dataIndex: 'featuredServices',
  ellipsis: true,
  width: 100
},
{
  title: '简要介绍',
  dataIndex: 'introduce',
  width: 140
},
{
  title: '备注',
  dataIndex: 'remark',
  width: 120,
  ellipsis: true
},
{
  title: '门头照',
  dataIndex: 'doorHeadPictureUrl',
  width: 70,
  render: ((res, record) => {
    const picture = record.doorHeadPictureUrl && <Image alt='' style={{ width: '50px', height: 'auto' }} src={record.doorHeadPictureUrl} />;
    return picture || '-'
  })
},
{
  title: '陈列照',
  dataIndex: 'displayPictureUrl',
  width: 70,
  render: ((res, record) => {
    const picture = record.displayPictureUrl && <Image alt='' style={{ width: '50px', height: 'auto' }} src={record.displayPictureUrl} />;
    return picture || '-'
  })
},
{
  title: '转账截图',
  dataIndex: 'transferAccountPictureUrl',
  width: 90,
  render: ((res, record) => {
    const picture = record.transferAccountPictureUrl && <Image alt='' style={{ width: '50px', height: 'auto' }} src={record.transferAccountPictureUrl} />;
    return picture || '-'
  })
},
{
  title: '营业执照',
  dataIndex: 'licPictureUrl',
  width: 80,
  render: ((res, record) => {
    const picture = record.licPictureUrl && <Image alt='' style={{ width: '50px', height: 'auto' }} src={record.licPictureUrl} />;
    return picture || '-'
  })
},
{
  title: '结婚证',
  dataIndex: 'marriageCertificatePictureUrl',
  width: 70,
  render: ((res, record) => {
    const picture = record.marriageCertificatePictureUrl && <Image alt='' style={{ width: '50px', height: 'auto' }} src={record.marriageCertificatePictureUrl} />;
    return picture || '-'
  })
}, {
  title: '申请时间',
  dataIndex: 'applyTime',
  width: 120,
},
{
  title: '状态',
  dataIndex: 'status',
  width: 100,
  valueEnum: statusMap,
},
{
  title: '审核人',
  dataIndex: 'auditUserName',
  width: 100
},
{
  title: '审核时间',
  dataIndex: 'auditTime',
  width: 120,
}, {
  title: '审核备注',
  dataIndex: 'auditComments',
  ellipsis: true,
  width: 100
}
];


const LevelList: FC<LevelListProps> = (props) => {
  const {entities} = props;
  let [key, setKey] = useState<any>(0);
  const searchFields: SearchField[] = [{
    label: '用户名',
    name: 'userName',
    operator: 'equals',
  }, {
    label: '手机号',
    name: 'phone',
    operator: 'equals',
  }, {
    label: '授权码',
    name: 'authorizationCode',
    operator: 'equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          name="status.equals"
          options={statusList}
          placeholder="状态"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="applyTime.greaterThanOrEqual"
          placeholder="申请时间开始时间"
        />
        <ProFormDateTimePicker
          name="applyTime.lessThanOrEqual"
          placeholder="申请时间结束时间"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="auditTime.greaterThanOrEqual"
          placeholder="审核开始时间"
        />
        <ProFormDateTimePicker
          name="auditTime.lessThanOrEqual"
          placeholder="审核结束时间"
        />
        <div className="wd10"></div>
        <SearchBox fields={searchFields} />
      </Input.Group>
    </Form.Item>
  );

  const [open, setOpen] = useState(false);
  const [record, setRecord]: any = useState({});

  //打开审核弹框
  const showModal = async (record: any) => {
    setRecord({ ...record })
    setOpen(true);
  };

  //审核
  const toExamineButtonRender: any = (dom: ReactNode, record: any): ReactNode => {
    return (
      record.status === 'Created' || record.status === 'Checked' ? <Tooltip title="审核">
        <Button size="small" type='link' onClick={() => showModal(record)} >审核</Button>
      </Tooltip> : ''
    )
  };

  //关闭审核弹框
  const hideModal = () => {
    setOpen(false);
    setRecord({})
  };

  const exportData = (data: PhysicalStores[]) => {
    let entities: PhysicalStores[] = [];
    data.forEach((e: any) => {
      let authorizedStoresName = '';
      e.authorizedStoresName && e.authorizedStoresName.forEach((element: any) => {
        authorizedStoresName += (element + ',')
      });
      const address = e.address?.provinceName + e.address?.cityName + e.address?.districtName + e.address?.address;
      let arr = {
        ...e,
        authorizedStoresName,
        address,
      };
      entities.push(arr);
    });
    return entities;
  };



  return (<>
    <ListViewPage<PhysicalStores>
      scrollConfig={{ x: 1200 }}
      columns={columns}
      hideDeleteButton
      rowActions={[toExamineButtonRender]}
      permanentParams={{
        'applyType.equals': 'PhysicalStores'
      }}
      showDownload
      downloadAllEntities={exportData}
      downloadColumns={columns.concat().map(e => {
        return {
          ...e,
          render: () => ''
        }
      })}
      downloadEntities={exportData(entities)}
      hideCreateButton
      hideEditButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<PhysicalStoresDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      {...props}
    >
      {props.children}
    </ListViewPage>
    {/* 审核 */}
    <ToExamineDialog visible={open} navigate={props.navigate} entity={record} columns={columns} record={record} onCancel={() => {
      hideModal();
    }} onRecharge={(data: any) => {
      auditRefuse(data).then((e: any) => {
        message.success('操作成功');
        setKey(++key)
        hideModal();
        return true;
      }).catch((e: any) => {
        message.error(e.response.data.message);
        return false
      })
    }} />
  </>
  );
};

const mapStateToProps = ({ physicalStores }: RootState) => ({
  entity: physicalStores.entity,
  entities: physicalStores.entities,
  loading: physicalStores.loading,
  updateSuccess: physicalStores.updateSuccess,
  createSuccess: physicalStores.createSuccess,
  total: physicalStores.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelList);