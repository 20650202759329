import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity } from "./resources.reducer";
import { RouteComponentProps } from "@reach/router";
import { Resources } from "model/system/resources.model";
import ResourcesDetails from './resources-details';
import ResourcesEdit from './resources-edit';
import { Form, Input } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { fetchModuleList } from "../power-module/power-module.reducer";
import SelectSearch from "components/select-search";

export interface ResourcesListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
export const columns: ProColumns<Resources>[] = [{
  title: 'id',
  dataIndex: 'id',
  valueType: 'text',
  width: 120
}, {
  title: '资源编码',
  dataIndex: 'code',
  valueType: 'text',
  width: 120
}, {
  title: '资源名称',
  dataIndex: 'description',
  valueType: 'text',
  width: 120
}, {
  title: '模块',
  dataIndex: ['module', 'name'],
  valueType: 'text',
  width: 120
}];

const searchFields: SearchField[] = [{
  label: '资源名称',
  name: 'description.contains',
}, {
  label: '资源编码',
  name: 'code.equals',
}, {
  label: '资源id',
  name: 'id.equals',
  // dataType: 'number'
}];

const quickFilterPanel = (
  <Form.Item>
    <Input.Group compact>
      <SelectSearch required={false} name="moduleName.contains" placeholder="模块" optionValue="name" defaultParams='' fetchFunc={fetchModuleList} searchParams='name.contains' />
      <SearchBox
        fields={searchFields}
      />
    </Input.Group>
  </Form.Item>
);



const ResourcesList: FC<ResourcesListProps> = (props) => {

  return (
    <ListViewPage<Resources>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ResourcesDetails navigate={props.navigate} columns={columns} />}
      editPage={(params) => <ResourcesEdit id={params.id} text={params.text} />} 
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ resources }: RootState) => ({
  entity: resources.entity,
  entities: resources.entities,
  loading: resources.loading,
  updateSuccess: resources.updateSuccess,
  createSuccess: resources.createSuccess,
  total: resources.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesList);