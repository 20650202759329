import React, {useEffect, useState} from 'react';
import {KeyOutlined, LogoutOutlined } from '@ant-design/icons';
import {Avatar, Form, Input, Menu, Modal, Typography, message} from 'antd';
import HeaderDropdown from '../header-dropdown';
import styles from './index.less';
import {UserOutlined} from '@ant-design/icons/lib';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { login, logout } from 'common/auth/authentication';
import { changePassword } from 'pages/user/user/user.reducer';
// import {changePassword} from 'pages/control-center/user/user.reducer';
export interface GlobalHeaderRightProps extends  StateProps, DispatchProps {
  menu?: boolean;
}

const AvatarDropdown: React.FC<GlobalHeaderRightProps> = (props) => {

  const { account, clientInfo, logout } = props;

  const { passwordStrength } = clientInfo;
  const [changePasswordDialogVisible, setChangePasswordDialogVisible] = useState(false);
  const openChangePasswordDialogVisible = () => setChangePasswordDialogVisible(true);
  const closeChangePasswordDialogVisible = () => setChangePasswordDialogVisible(false);
  const isText = passwordStrength !== undefined && !passwordStrength;

  useEffect(() => {
    passwordStrength !== undefined && !passwordStrength && openChangePasswordDialogVisible();
  }, [passwordStrength])

  // const authContext = useContext(AuthContext);

  const [form] = Form.useForm();

  const menuHeaderDropdown = (
    <Menu className={styles.menu} selectedKeys={[]}>
      <Menu.Item
        key="changePassword"
        icon={<KeyOutlined/>}
        onClick={() => openChangePasswordDialogVisible()}
      >
        修改密码
      </Menu.Item>
      <Menu.Divider/>
      <Menu.Item
        key="logout"
        icon={<LogoutOutlined/>}
        onClick={() => logout()}
      >
          退出登录
      </Menu.Item>
    </Menu>
  );

  const handleFormFinish = async (values: any) => {
    await 
    changePassword({...values, userId: account.userId}).then(e => {
      message.success('修改成功，即将退出登录！');
      closeChangePasswordDialogVisible();
      setTimeout(() => {
        logout();
      }, 2000);
    }).catch(e => {
      message.error(e.response.data.title);
    })
  }

  return (
    <>
      {/*{!(authContext?.authenticated) && loading}*/}
      {/*{authContext?.authenticated &&*/}
      <HeaderDropdown overlay={menuHeaderDropdown}>
      <div className={`${styles.action} ${styles.account}`}>
        <div className="user-info">
          {/*<Avatar size="small" className={styles.avatar} src={account.imageUrl} alt="avatar" icon={<UserOutlined />} />*/}
          <Avatar size="small"  alt="avatar" icon={<UserOutlined/>}/>
          {/*<span className={`${styles.name} anticon`}>{authContext?.profile?.username}</span>*/}
          <span className="name">{account.name}</span>
        </div>
        <Modal
          title="修改密码"
          destroyOnClose={true}
          visible={changePasswordDialogVisible}
          onOk={() => form.submit()}
          onCancel={() => closeChangePasswordDialogVisible()}
        >
        { isText && <Typography.Text type='warning' 
                                  style={{display:'inline-block', marginBottom:'15px'}}>
                                    密码强度偏弱，建议重置密码！
                                </Typography.Text>}
        <Form  form={form} onFinish={handleFormFinish}>
          <Form.Item
            label="旧密码"
            name="oldPassword"
            rules={[{required: true, message: '旧密码不能为空。'}]}
          >
            <Input.Password placeholder="旧密码" minLength={6} maxLength={15}/>
          </Form.Item>
          <Form.Item
            label="新密码"
            name="newPassword"
            rules={[{required: true}]}
          >
            <Input.Password placeholder="新密码" minLength={6} maxLength={15}/>
          </Form.Item>
          <Form.Item
            label="重复新密码"
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[{
              required: true, message: '请再输入一次新密码。'
            },
              ({getFieldValue}) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject('两次输入的密码不匹配');
                },
              })]}
          >
            <Input.Password placeholder="再次输入新密码" minLength={6} maxLength={15}/>
          </Form.Item>
        </Form>
      </Modal>
      </div>
      </HeaderDropdown>
      {/*}*/}
    </>
  );
};

const mapStateToProps = ({ authentication }: RootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  account: authentication.account,
  clientInfo: authentication.clientInfo
});

const mapDispatchToProps = {
  login,
  logout
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AvatarDropdown);