import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form, Row, Col } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./brandLevel.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { BrandLevel } from "model/dealer/brandLevel.model";
import { ProFormText } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import { fetchSearchBrandList } from "pages/pim/brand/brand.reducer";
import { fetchSearchCustomerList } from "pages/customerCore/customer/customer.reducer";
import { fetchLevelList } from "../level/level.reducer";
export interface BrandLevelEditProps extends EditStateAndDispatchProps<BrandLevel>, StateProps, DispatchProps, RouteComponentProps {
}

const BrandLevelEdit: FC<BrandLevelEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<BrandLevel>
      title="品牌等级"
      form={form}
      {...props}
    >
      <Row>
        <Col>
          <Form.Item name="brandId">
            <SelectSearch width='md' required={false} name="brandId" label="品牌" placeholder="品牌" optionValue='id' labelValue="brandName" defaultParams='id.equals' valueType="number"  fetchFunc={fetchSearchBrandList} searchParams='name.contains' />
          </Form.Item>
        </Col><Col span={1} />
        <Col>
         <ProFormText name="customerName" hidden />
         <ProFormText name="userId" hidden />
         <ProFormText name="code" hidden />
          <Form.Item name="customerId">
            <SelectSearch width='md' required={false} name="customerId" label="经销商" fetchOptionObj={(option: any) => {
              form.setFieldsValue({
                customerName:option.name,
                userId:option.userId,
                code: option.account,
              })
            }} placeholder="经销商" valueType="number" labelValue="name" defaultParams='id.equals' extra='请使用授权码进行搜索' labelValueObj={(option:any) => option.name + '--' + option.account} fetchFunc={fetchSearchCustomerList} searchParams='account.equals' />
          </Form.Item>
        </Col><Col span={1} />
        <Col>
         <ProFormText name="levelName" hidden />
          <Form.Item name="levelNo">
            <SelectSearch width='md' required={false} name="levelNo" label="等级" fetchOptionObj={(option: any) => {
              form.setFieldsValue({
                levelName:option.levelName,
              })
            }} placeholder="等级" optionValue="levelNo" labelValue="levelName" defaultParams='levelNo.equals' fetchFunc={fetchLevelList} searchParams='levelName.contains' />
          </Form.Item>
        </Col><Col span={1} />
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ dealerBrandLevel }: RootState) => ({
  entity: dealerBrandLevel.entity,
  updateSuccess: dealerBrandLevel.updateSuccess,
  updating: dealerBrandLevel.updating,
  createSuccess: dealerBrandLevel.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrandLevelEdit);
