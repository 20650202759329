import React, { FC, useCallback, useState, useEffect } from 'react';
import { Image, Form, Radio, Tabs, Button, Tooltip } from 'antd';
import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
import holderImg from '../../../../assets/images/public/holder.jpg';
import '../style/goods.less';
import GoodsList from '../goods-list';
import { getGoodsDetailByIds } from '../../api';
import { goodsStyleList } from '../../../../model/decorate/decorate.model';
import { useParamGeneral } from 'components/useParamGenera';
const { TabPane } = Tabs;

export interface Props {
    dataField?: any
    index: number
    preModule: any // 上一模块数据
}

const Goods: FC<Props> = (({ dataField, preModule }) => {
    let [goodsList, setGoodsList] = useState([] as any);
    let tempList = [
        {
            title: '商品名称占位',
            retailPrice: '0.00',
            mainPicture: holderImg,
            id: 'test'
        },
        {
            title: '商品名称占位',
            retailPrice: '0.00',
            mainPicture: holderImg,
            id: 'test'
        },
        {
            title: '商品名称占位',
            retailPrice: '0.00',
            mainPicture: holderImg,
            id: 'test'
        },
        {
            title: '商品名称占位',
            retailPrice: '0.00',
            mainPicture: holderImg,
            id: 'test'
        },
    ]
    useEffect(() => {
        if (dataField.goodsIds && dataField.goodsIds.length > 0) {
          console.log(dataField.goodsIds)
            getGoodsDetailByIds(dataField.goodsIds, dataField?.SkytoneCard || false)
                .then((res: any) => {
                    if (res && res.data.length > 0) {
                        setGoodsList(res.data)
                    }
                })
        } else {
            setGoodsList([])
        }
    }, [dataField.goodsIds, dataField?.SkytoneCard])
    if (goodsList.length > 0) {

        return <GoodsList borderRadius={
            `${preModule && preModule.type === 'image_carousel' && preModule.dataField.imageList.length === 1 ? '0 0 12px 12px' : ''}`
        } list={goodsList} dataField={dataField} type={dataField.listStyle.selected} />

    } else {
        return <GoodsList dataField={dataField} list={tempList} type={dataField.listStyle.selected} />

    }

})



interface GoodsSetItemProps {
    id: string
    index: number
    data?: any
    updateModuleDataPrivate: (dataField: any, index: number, type?: string) => void
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const GoodsSetItem: FC<GoodsSetItemProps> = (({ data, id, index, updateModuleDataPrivate }) => {
    return <div className={`goods-set-item`} >
        <div className="img">
            <div className="number">{index + 1}</div>
            <Image preview={false} src={data.mainPicture.indexOf('http') === 0 ? data.mainPicture + '?imageView2/2/w/400' : data.mainPicture}></Image>
        </div>
        <div className="info">
            <p className="name text-2">{data.name}</p>
            <p className="price">{useParamGeneral()?.currency?.symbol}{data.items && data.items.length > 0 ? data.items[0].retailPrice : data.retailPrice}</p>
        </div>
        <div className="operation">
            <div className="icon " onClick={
                () => {
                    updateModuleDataPrivate(data.id, index, 'moveUp')
                }
            }>
                <Tooltip title="上移">
                    <ArrowUpOutlined />
                </Tooltip>
            </div>
            <div className="icon" onClick={
                () => {
                    updateModuleDataPrivate(data.id, index, 'moveDown')
                }
            }>
                <Tooltip title="下移">
                    <ArrowDownOutlined />
                </Tooltip>
            </div>
            <div className="icon" onClick={
                () => {
                    updateModuleDataPrivate(data.id, index, 'delete')
                }
            }>
                <Tooltip title="移除" >
                    <DeleteOutlined />
                </Tooltip>
            </div>

        </div>
    </div>
})

export const GoodsSet: FC<SetProps> = ({ dataField, id, methods, selectedInfo, otherInfo }) => {
    const [dataFieldPrivate, setDataFieldPrivate] = useState(dataField)
    const [goodsList, setGoodsList] = useState([] as any)
    const updateModuleDataPrivate = useCallback(
        (item:any, index:any, type:any) => {//type delete up down
            let newDataFieldPrivate = dataFieldPrivate
            if (!type) {
                newDataFieldPrivate.goodsIds[index] = item
            } else if (type === 'delete') {
                delete newDataFieldPrivate.goodsIds[index]
                newDataFieldPrivate.goodsIds = newDataFieldPrivate.goodsIds.filter((v: any) => v)
                newDataFieldPrivate = Object.assign({}, newDataFieldPrivate)
            } else if (type === 'moveDown') {
                let nextIndex = index + 1, length = newDataFieldPrivate.goodsIds.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.goodsIds);
                if (nextIndex > length - 1) {
                    nextIndex = 0
                }
                nextItem = newDataFieldPrivate.goodsIds[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.goodsIds = newImageList
                newDataFieldPrivate = Object.assign({}, newDataFieldPrivate)
            } else if (type === 'moveUp') {
                let nextIndex = index - 1, length = newDataFieldPrivate.goodsIds.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.goodsIds);
                if (nextIndex < 0) {
                    nextIndex = length - 1
                }
                nextItem = newDataFieldPrivate.goodsIds[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.goodsIds = newImageList
            } else if (type === 'add') {
                if (item instanceof Array) {
                  newDataFieldPrivate.goodsIds = Array.from(new Set([...item.map((v: any) => {
                    return v.id
                  }), ...newDataFieldPrivate.goodsIds]));
                } else {
                    newDataFieldPrivate.goodsIds.unshift(item)
                }
            }
            // //去重
            // let goodsObj:any = {},newGoodsList = []
            // newDataFieldPrivate.goodsDetail.forEach((v:any) => {
            //     goodsObj[v.id] = v
            // })
            // for(let k in goodsObj){
            //     newGoodsList.push(goodsObj[k])
            // }
            // newDataFieldPrivate.goodsDetail = newGoodsList
            methods.updateModuleData(newDataFieldPrivate, id)
        },
        [dataFieldPrivate, methods, id]
    )

    useEffect(
        () => {
            if (!selectedInfo || selectedInfo.length === 0 || !(selectedInfo instanceof Array)) return
            updateModuleDataPrivate(selectedInfo, '', 'add')
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedInfo]
    )
    useEffect(() => {
        setDataFieldPrivate(dataField)
    }, [dataField])
    useEffect(() => {
        if (dataFieldPrivate.goodsIds && dataFieldPrivate.goodsIds.length > 0) {
            getGoodsDetailByIds(dataFieldPrivate.goodsIds, dataField?.SkytoneCard || false)
                .then((res: any) => {
                    if (res && res.data.length > 0) {

                        setGoodsList(res.data)
                    }
                })
        } else {
            setGoodsList([])
        }
    }, [dataFieldPrivate.goodsIds, dataField?.SkytoneCard])



    return <div className="setting-container">
        {/* <div className="dragger-con">
            <Dragger {...DraggerUploadProps} className="add-area">
                <p className="desc">
                    <PlusOutlined className="icon"/>
                </p>
                <p className="desc">点击/拖放以新增商品</p>
            </Dragger>
        </div> */}
        {/* <Input value="选择商品" bordered className="seletedGoods"  /> */}
        <Tabs className="goods-tabs" type="card">
            <TabPane tab="样式配置" key="1">
                <div className="set-con" key="1">
                    <Form.Item label="列表类型" >
                        <Radio.Group value={dataFieldPrivate.listStyle.selected} onChange={
                            (e) => {
                                let value = e.target.value;
                                if (value !== dataFieldPrivate.listStyle.selected) {
                                    methods.updateModuleData({
                                        ...dataFieldPrivate,
                                        listStyle: {
                                            ...dataFieldPrivate.listStyle,
                                            selected: value
                                        }
                                    }, id)
                                }
                            }
                        }>
                            {
                                goodsStyleList.map((v: any, i: number) => {
                                    return <Radio.Button value={v.value} key={i}>{v.label}</Radio.Button>
                                })
                            }

                        </Radio.Group>
                    </Form.Item>
                </div>
            </TabPane>
            <TabPane tab="商品列表" key="2">
                <div className="con" key="2">

                    <div className="goods-set-title" >
                        <p className="left">已选{goodsList.length || 0}件</p>
                        <Button className="add" onClick={
                            () => {
                                methods.setShowProductSelectionPrivate(true)
                            }
                        } type="primary">新增</Button>
                    </div>
                    <div className="goods-set-list">
                        {goodsList.map((v: any, i: number) => {
                            return v.type !== 'config' && <GoodsSetItem
                                key={i}
                                id={v.id}
                                index={i}
                                data={v}
                                updateModuleDataPrivate={updateModuleDataPrivate}
                            />

                        })}
                    </div>
                </div>
            </TabPane>

        </Tabs>



    </div>
}

export default Goods

