import { marginRulesModel, defaultMarginRules } from "model/store/marginRules.model";
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';

const entity = 'marginRules';
const apiUrl = '/api/yumei-mall-store/cash-deposit-rule';
const standardReducerImpl = new StandardReducerImpl<marginRulesModel>(entity, defaultMarginRules, apiUrl);
export type MarginRulesState = StandardState<marginRulesModel>;
export const marginRules = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 查询所有等级
export const fetchLevelList = async () => {
    const requestUrl = `/api/yumei-mall-pim/brand/level/list?sort=levelSort,asc`;
    const axiosResponse = await axios.get<any>(requestUrl, {});
    return axiosResponse.data;
  }



  
  
