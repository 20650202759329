import { advertisementModel } from "model/market/advertisement.model";
import { FC, useState, useEffect } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { fetchLevelList, fetchStoreList } from "pages/dealer/unit/unit.reducer";
import { Form, Row, Col, message, TreeSelect } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./advertisement.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDependency, ProFormDigit, ProFormRadio, ProFormSelect, ProFormText, ProFormTextArea, ProFormDateTimeRangePicker } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import UploadImgOne from "components/upload-img/upload-img-one"
import { uploadImage } from "utils/file-utils";
import { connect } from 'react-redux';

export interface LevelEditProps extends EditStateAndDispatchProps<advertisementModel>, StateProps, DispatchProps, RouteComponentProps { }

const AdvertisementEdit: FC<LevelEditProps> = props => {
  const { entity } = props;
  const [form] = Form.useForm();
  const { convertEnumToOptionType, getEnumValueList } = useEnumValue();
  const [jumpPlaceholder, setjumpPlaceholder] = useState('H5链接')
  const [treeData, setTreeData] = useState<[]>();
  const [value, setValue] = useState<string>();
  const [valueNodes, setValueNodes] = useState<any[]>([]);
  const [userCodes, setUserCodes] = useState<any>('');
  const [reEntity, setReEntity] = useState<any>({});

  useEffect(() => {
    if (entity.id) {
      if(entity.startDate && entity.endDate) {
        entity.rankTimes = [entity.startDate, entity.endDate]
      }
      let data: any = entity
      if (data.showType === 1 && data.showUserDto.userCodes.length > 0) {
        setUserCodes(data.showUserDto.userCodes.join(','))
      }
      setReEntity(data)
    }else {
      setReEntity({})
    }
    setTimeout(()=> {
      getLevelTree()
    },900)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  const getLevelTree = () => {
    fetchStoreList().then((res: any) => {
      fetchLevelList().then((data: any) => {
        const newTreeData = res.concat().map((e: any) => ({
          value: e.id,
          title: e.name,
          key: e.signAppKey,
          children: data.concat().map((item: any) => ({
            value: e.id + '-' + item.id,
            title: e.name + '-' + item.levelName,
            originalTitle: item.levelName,
            originalValue: item.id,
            levelNo: item.levelNo,
            parentValue: e.id,
            parentTitle: e.name,
            key: item.id,
          }))
        }));
        setTreeData(newTreeData);
      });
    });
    const newInvolvedPlatforms: any = [];
    entity.showUserDto?.storeRanges && entity.showUserDto?.storeRanges.concat().forEach((e: any) => {
      e.levels && e.levels.forEach((element: any) => {
        newInvolvedPlatforms.push(e.storeId + '-' + element.levelId)
      });
    })
    form.setFieldsValue({
      involvedPlatforms: newInvolvedPlatforms
    });
  }

  const onChange = (value: any, label: any, extra: any) => {
    setValue(value);
    const newValueNodes = extra.allCheckedNodes.concat().map((e: any) => {
      const obj = e.node?.props || e.props;
      return ({
        storeId: obj.children.length === 0 ? obj.parentValue : obj.value,
        storeName: obj.children.length === 0 ? obj.parentTitle : obj.title,
        levels: obj.children.length > 0 ? obj.children.concat().map((item: any) => {
          const itemObj = item.props;
          return {
            levelId: itemObj.originalValue,
            levelName: itemObj.originalTitle,
            levelNo: itemObj.levelNo,
          }
        }) : [{
          levelId: obj.originalValue,
          levelName: obj.originalTitle,
          levelNo: obj.levelNo,
        }]
      })
    });

    let newData: any = [];
    let num = [];
    const funC = () => {
      const e = newValueNodes[num.length];
      (newData.length === 0) || (newData.filter((item: any) => item.storeId === e.storeId).length === 0)
        ? newData.push(e)
        : ((newData = newData.map((s: any) => {
          return ({
            ...s,
            levels: s.storeId === e.storeId ? s.levels.concat(e.levels) : s.levels
          })
        })));
      num.push(0);
      num.length === newValueNodes.length ? setValueNodes(newData) : funC();
    }; funC();
  };
  const UpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        res.sort = res.sort ? res.sort : 0
        let showUserDto: any = {}
        if (res.showType === 2) {
          if (valueNodes.length > 0) {
            showUserDto.storeRanges = valueNodes;
          }

        } else if (res.showType === 1 && res.userCodes) {
          showUserDto.userCodes = res.userCodes.split(',')
        }
        res.showUserDto = showUserDto

        if(res.rankTimes.length > 0){
          res.startDate = res.rankTimes[0]
          res.endDate = res.rankTimes[1]
        }
        const picture: any = res && res.picture?.concat();
        if (res.picture && picture.length > 0) {
          if (picture[0].originFileObj) {
            //picture需上传
            const newDate = new Date().getTime();
            uploadImage(picture[0].originFileObj, 'product/img-' + newDate).then(async imageName => {
              res.picture = imageName;
              resolve(res);
            }).catch(() => { message.error('图片上传失败'); reject() });
          } else {
            // picture已上传且未修改
            res.picture = picture[0].url;
            resolve(res)
          }
        } else {
          //未上传picture
          res.picture = '';
          resolve(res)
        }

      }
    })
  }

  return (
    <EditPage
      title="广告位"
      beforeUpdateEntity={UpdateEntity}
      form={form}
      {...props}
      width={700}
      entity={reEntity}
    >
      <Row justify="start">
        <Col span='24'>
          <ProFormSelect
            rules={[{ required: true }]}
            options={convertEnumToOptionType('bannerShowPosition') || []}
            name="showPosition"
            label='广告位置'
            initialValue={'MY_CAPSULE'}
            placeholder='广告位置'
          />
        </Col>
        <Col span={24}>
          <ProFormText
            fieldProps={{
              maxLength: 20
            }}
            rules={[{ required: true }]}
            name="name"
            label="广告名称"
            placeholder="请输入广告名称"
          />
        </Col>

        <Col span={24}>
          <Form.Item name="picture" label="广告图片" rules={[{ required: true }]}>
            <UploadImgOne
              listType="picture-card"
              extra="建议尺寸：800*200, 最多上传一张, 不超过2M."
              title="上传"
            />
          </Form.Item>
        </Col>


        <Col span='24'>
          <ProFormRadio.Group
            label="链接类型"
            name='linkType'
            width='md'
            initialValue={1}
            fieldProps={{
              onChange: (e: any) => {
                let shil = e.target.value === 3 ? '(填写商品ID)' : ''
                setjumpPlaceholder(e.target.label + shil)
              }
            }}
            rules={[{ required: true }]}
            options={getEnumValueList('bannerLinkType').map((e: any) => ({
              label: e.desc,
              value: e.value
            }))}
          />
        </Col>

        <ProFormDependency name={['linkType']}>
          {({ linkType }) => {
            return linkType !== 6 && <>
              <Col span='24'>
                <ProFormText
                  rules={[{ required: true }]}
                  name="linkUrl"
                  label="跳转链接"
                  placeholder={'请输入' + jumpPlaceholder || '跳转链接'}
                />
              </Col>
            </>
          }}
        </ProFormDependency>

        <Col span='24'>
          <ProFormRadio.Group
            label="显示类型"
            name='showType'
            width='md'
            initialValue={0}
            rules={[{ required: true }]}
            options={getEnumValueList('bannerShowType').map((e: any) => ({
              label: e.desc,
              value: e.value
            }))}
          />
        </Col>
        <ProFormDependency name={['showType']}>
          {({ showType }) => {
            if (showType === 2) {
              return <Col span={24}>
                <Form.Item rules={[{ required: true }]} name='involvedPlatforms' label='指定等级'>
                  <TreeSelect
                    showSearch
                    style={{ width: '100%' }}
                    value={value}
                    placeholder="指定等级"
                    allowClear
                    multiple
                    onChange={onChange}
                    treeData={treeData}
                    treeCheckable
                    showCheckedStrategy={'SHOW_PARENT'}
                  />
                </Form.Item>
              </Col>
            } else if (showType === 1) {
              return <Col span={24}>
                <ProFormTextArea
                  extra='批量粘贴授权码到下面，以“，“分隔，然后点击确定例如：M10007，M10008'
                  rules={[{ required: true }]}
                  name="userCodes"
                  initialValue={userCodes}
                  label='指定人员显示'
                  placeholder='批量粘贴授权码，以逗号分割'
                />
              </Col>
            }
          }}
        </ProFormDependency>

        {/* <Col span={12}>
          <ProFormDateTimePicker
            rules={[{ required: true }]}
            className="wid100"
            label="展示开始时间 "
            name="startDate"
            placeholder="展示开始时间 "
          />
        </Col>
        <Col span={24}>
          <ProFormDateTimePicker
            rules={[{ required: true }]}
            className="wid300"
            label="展示结束时间 "
            name="endDate"
            placeholder="展示开始时间 "
          />
        </Col> */}
        <Col span={24}>
          <ProFormDateTimeRangePicker  className="wid100" rules={[{ required: true }]}   label="展示时间段 " name='rankTimes'  />
        </Col>
        <Col span={24}>
          <ProFormDigit
            name="sort"
            label="排序值"
            placeholder="排序值"
          />
        </Col>
      </Row>
    </EditPage>);
}
const mapStateToProps = ({ advertisement }: RootState) => ({
  entity: advertisement.entity,
  updateSuccess: advertisement.updateSuccess,
  updating: advertisement.updating,
  createSuccess: advertisement.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementEdit)
