import ListViewPage from "layouts/page-wraper/list-view-page";
import RegionEdit from "./region-edit";
import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import RegionDetails from "./region-details";
import { deleteEntity, getEntities, getEntity } from "./region.reducer";
import { RouteComponentProps } from "@reach/router";
import { Region, RegionDisabled, RegionTemplateType } from "model/product/region.model";
import { ProFormRadio } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";;

export interface RegionListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const RegionList: FC<RegionListProps> = (props) => {
  const columns: ProColumns<Region>[] = [{
    title: '模板名称',
    dataIndex: 'templateName',
    valueType: 'text',
  }, {
    title: 'id',
    dataIndex: 'id',
    valueType: 'text',
    hideInTable: true,
  }, {
    title: '商家名称',
    dataIndex: 'sellerName',
    valueType: 'text',
    render:(dom,record) => <>{record.overallSituation ? '' : dom}</>
  }, {
    title: '模版类型',
    dataIndex: 'regionTemplateType',
    valueType: 'text',
    valueEnum: RegionTemplateType,
    hideInDescriptions: true,
  }, {
    title: '是否全局',
    dataIndex: 'overallSituation',
    valueType: 'text',
    render: (text) => text === true ? '是' : '否',
  }, {
    title: '状态',
    dataIndex: 'disabled',
    valueType: 'text',
    render: (text) => text === false ? '启用' : '禁用',
  }, {
    title: '商家id',
    dataIndex: 'sellerId',
    valueType: 'text',
    hideInTable: true,
  }]

  const searchFields = [{
    label: '模板名称',
    name: 'templateName.contains',
  }];
  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormRadio.Group
          name="disabled.equals"
          radioType="button"
          options={Object.entries<string>(RegionDisabled).map(([value, label]) => ({ value, label }))}
        />
        <SelectSearch required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Region>
      columns={columns}
      quickFilterPanel={quickFilterPanel} 
      detailPage={<RegionDetails navigate={props.navigate} columns={columns} />}
      editPage={(params: any) => <RegionEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ region }: RootState) => ({
  entity: region.entity,
  entities: region.entities,
  loading: region.loading,
  updateSuccess: region.updateSuccess,
  total: region.total,
  createSuccess: region.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegionList);