import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import SummaryDetails from './summary-details';
import SummaryEdit from './summary-edit';
import SummaryList from './summary-list';

const Summary: React.FC<RouteComponentProps> = () => (
  <Router>
    <SummaryList path="/" />
    <SummaryDetails path=":id" />
    <SummaryEdit path=":id/edit" />
    <SummaryEdit path="new" />
  </Router>
);
export default Summary;
