import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import ClassificationList from './classification-list';
import ClassificationDetails from "./classification-details";
import ClassificationEdit from "./classification-edit";

const Classification: React.FC<RouteComponentProps> = () => (
  <Router>
    <ClassificationList path="/" />
    <ClassificationDetails path=":id" />
    <ClassificationEdit path=":id/edit" />
    <ClassificationEdit path="new" />
  </Router>
);
export default Classification;
