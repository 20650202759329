import { BaseModel } from "../base.model";

export interface Attachment {
  duration?: number;
  height?: number;
  url?: string;
  width?: number;
}

export interface Likes {
  likeNum?: number;
  liked?: boolean;
  postId?: number;
  uid?: number;
}

export interface Product {
  code?: string;
  id?: number | string;
  name?: string;
  price?: number;
  prodId?: number;
  sort?: number;
  url?: string;
}

interface postTopic {
  id?: number | string;
  name?: string;
}

export interface Post extends BaseModel {
  videoUploadMethod?:any,
  publicityType?: any,
  onreload?:any,
  coverUrl?: any;
  pubNickName?: string;
  attachments?: Attachment[];
  auditRejectReason?: string;
  pictures?: any;
  auditStatus?: string;
  auditTime?: Date;
  category?: any;
  commentCount?: number;
  content?: string;
  cover?: string;
  createdBy?: string;
  createdTime?: Date;
  fileId?: string;
  id?: number | string;
  likes?: Likes;
  products?: any[];
  pubAvatar?: string;
  pubCode?: string;
  pubId?: any;
  pubNickname?: string;
  sort?: number;
  topic?: string;
  topicType?: string;
  updatedBy?: string;
  updatedTime?: Date;
  reason?: string;
  postTopics?: postTopic[];
  videos?: any;
  type?: string;
  materialType?: number;
  categoryList?: any[]
  pictureStyle?: string
  title?: string;
}

export interface AuditRejectReason {
  auditRejectReason?: string;
  auditStatus?: number;
  auditTime?: string;
  createdBy?: string;
  createdTime?: string;
  id?: number | string;
  label?: string;
  postId?: number;
  type?: number;
}

//审核状态
export const auditStatus = {
  'not': '未审核',
  'pass': '审核通过',
  'reject': '审核拒绝'
};

export const aiContentReviewType = {
  'Porn': '画面鉴黄',
  'Terrorism': '画面鉴恐',
  'Political': '画面鉴政',
  'Political.Ocr': '文字鉴政',
  'Porn.Ocr': '文字鉴黄',
  'Porn.Asr': '语音鉴黄',
  'Political.Asr': '语音鉴政',
};

export const auditType = {
  'ShortVideoAudit': '短视频审核',
  'PictureAudit': '图片审核',
  'TextContentAudit': '文本审核'
}

export const defaultPost: Readonly<Post> = {};