import { Button, Form, Input, message } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { FC, ReactNode, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity, uploadImages } from "./salonApplication.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ProForm, { ModalForm, ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import UploadImgComplex from "components/upload-img/upload-img-complex";
import { getRegionalHeadList } from '../../dealer/customer/customer.reducer'

export interface salonApplicationListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const SalonApplicationList: FC<salonApplicationListProps> = (props) => {
  const [form] = Form.useForm();
  const [refreshFlag, setRefreshFlag] = useState(0);
  // 详情弹框
  const columns: ProColumns<any>[] = [{
    title: '会议编号',
    dataIndex: 'activityNo',
    width: 60
  }, {
    title: '姓名',
    dataIndex: 'createBy',
    width: 70
  },
  {
    title: '授权号',
    dataIndex: 'applyPeopleNo',
    width: 60
  }, {
    title: '会议类型',
    dataIndex: 'activityType',
    render: (dom, record: any) => {
      return record.activityType === 1 ? '沙龙会' : record.activityType === 2 ? '招商会' : ''
    }
  }, {
    title: '会议名称',
    dataIndex: 'activityTitle',
  }, {
    title: '签到时间',
    dataIndex: 'signStartTime',
    render: (dom, record: any) => {
      return record.signStartTime + '-' + record.signEndTime
    }
  }, {
    title: '开始时间',
    dataIndex: 'orderNo',
    render: (dom, record: any) => {
      return record.activityStartTime + '-' + record.activityEndTime
    }
  }, {
    title: '签到地点',
    dataIndex: 'signAddress',
    width: 50
  },
  {
    title: '报名人数',
    dataIndex: 'currentSignNum',
    render: (dom, record: any) => {
      return record.currentSignNum + '/' + record.signPeopleNum
    }
  },

  {
    title: '会议状态',
    dataIndex: 'activityExamineStatus',
    width: 50,
    render: (dom, record: any) => {
      return <>
        <view>{
          record.activityExamineStatus === 1
            ? "待审核"
            : record.activityExamineStatus === 2
              ? (record.status === 1 ? '未进行' : record.status === 2 ? '进行中' : record.status === 3 ? '已结束' : '')
              : record.activityExamineStatus === 3
                ? "审核拒绝"
                : ""
        }</view>
      </>
    }
  }, {
    title: '报销状态',
    dataIndex: 'reimbursementExamineStatus',
    width: 110,
    render: (dom, record: any) => {
      return record.reimbursementExamineStatus === 1
        ? "待审核"
        : record.reimbursementExamineStatus === 2
          ? "审核通过"
          : record.reimbursementExamineStatus === 3
            ? "审核失败"
            : "未报销"
    }
  },
  {
    title: '区域负责人',
    dataIndex: 'regionalHeadPeopleDto',
    width: 70,
    render: (dom, record: any) => {
      return record.regionalHeadPeopleDto?.regionalName + '-' + record.regionalHeadPeopleDto?.name + '-' + record.regionalHeadPeopleDto?.customerNo
    }
  }, {
    title: '创建时间',
    dataIndex: 'createdDate',
    width: 80
  }
  ];

  const searchFields: SearchField[] = [
    {
      label: '会议编号',
      name: 'activityNo'
    }, {
      label: '姓名',
      name: 'name',
    }, {
      label: '授权号',
      name: 'customerNo',
    }
  ];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={[{
            label: "沙龙会",
            value: 1,
          }, {
            label: "招商会",
            value: 2,
          }]}
          name="activityType"
          label='会议类型'
          placeholder='会议类型'
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={[{
            label: "待审核",
            value: 1,
          }, {
            label: "审核拒绝",
            value: 2,
          }, {
            label: "待开始",
            value: 3,
          }, {
            label: "进行中",
            value: 4,
          }, {
            label: "已结束",
            value: 5,
          }]}
          name="activityState"
          label='activityState'
          placeholder='会议状态'
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={[{
            label: "待审核",
            value: 1,
          }, {
            label: "审核通过",
            value: 2,
          }, {
            label: "审核拒绝",
            value: 3
          }]}
          name="reimbursementState"
          label='报销状态'
          placeholder='报销状态'
        />
        <div className="wd10"></div>
        <ProFormSelect
          width={'md'}
          style={{ 'width': '250px' }}
          name={'ids'}
          mode="multiple"
          request={async (params) => {
            const data: any = await getRegionalHeadList();
            return data.map((item: any) => ({
              value: item.id,
              label: item?.regionalName + '-' + item?.name + '-' + item?.customerNo
            }))
          }}
          placeholder="请选择区域负责人"
        />

<div className="wd10"></div>
         <ProFormDateTimePicker
          name="startTime"
          placeholder="创建开始时间"
        />
        <ProFormDateTimePicker
          name="endTime"
          placeholder="创建结束时间"
        />
      </Input.Group>

    </Form.Item>
  );


  const rowActions = [(dom: any, record: any) => {
    return record.activityExamineStatus === 2 && <ModalForm
      title={'素材图片'}
      trigger={<Button type="link" size="small" >素材图片</Button>}
      modalProps={{
        destroyOnClose: true,
      }}
      form={form}
      disabled={record.state === 2}
      preserve={false}
      onFinish={async (data: any) => {
        let imgList = data.activityImgs.concat().map((e: any) => e.url)
        const params = {
          id: record.id,
          imgs: imgList.join(',')
        };
        return new Promise<boolean>((resolve, reject) => {
          uploadImages(params).then(e => {
            console.log(e)
            message.success('上传成功');
            setRefreshFlag(new Date().getTime());
            resolve(true);
          }).catch(error => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
      onVisibleChange={(e) => {
        console.log(record, e)
        !!e && form.setFieldsValue({
          activityImgs: record.activityImgs
        });
        !e && form.resetFields();
      }}
    >
      <ProForm.Item name="activityImgs">
        <UploadImgComplex
          listType="picture-card"
          extra=""
          title="上传"
          Immediate={true}
          fileType='img'
          maxCount={30}
        />
      </ProForm.Item>
    </ModalForm>
  }]

  //导出
  const exportData = (resData?: any) => {
    let orderData: any[] = [];
    const exportOrderData = resData ? resData : props.entities;
    exportOrderData.forEach((res: any) => {
      let arr: any = {
        ...res,
        activityExamineStatus: res.activityExamineStatus === 1
          ? "待审核"
          : res.activityExamineStatus === 2
            ? (res.status === 1 ? '未进行' : res.status === 2 ? '进行中' : res.status === 3 ? '已结束' : '')
            : res.activityExamineStatus === 3
              ? "审核拒绝"
              : "",
        reimbursementExamineStatus: res.reimbursementExamineStatus === 1
          ? "待审核"
          : res.reimbursementExamineStatus === 2
            ? "审核通过"
            : res.reimbursementExamineStatus === 3
              ? "审核失败"
              : "未报销",
        regionalHeadPeopleDto: res.regionalHeadPeopleDto?.regionalName + '-' + res.regionalHeadPeopleDto?.name + '-' + res.regionalHeadPeopleDto?.customerNo
      };
      orderData.push(arr);
    });
    return orderData;
  }


  return (
    <div>
      <ListViewPage
        sort=""
        downloadEntities={exportData()}
        downloadAllEntities={exportData}
        showDownload
        refreshFlag={refreshFlag}
        key={'activityNo'}
        rowActions={rowActions}
        actionColumnWidth={60}
        columns={columns}
        hideEditButton
        hideDeleteButton
        hideCreateButton
        quickFilterPanel={quickFilterPanel}
        detailsIndex={false}
        {...props}
      >
        {props.children}
      </ListViewPage>

    </div>
  );
};

const mapStateToProps = ({ salonApplication }: RootState) => ({
  entity: salonApplication.entity,
  entities: salonApplication.entities,
  loading: salonApplication.loading,
  updateSuccess: salonApplication.updateSuccess,
  createSuccess: salonApplication.createSuccess,
  total: salonApplication.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SalonApplicationList);