import { Retail, defaultRetail } from 'model/scanCodeOrder/retail.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'Retail';
const apiUrl = '/api/yumei-mall-shipment/shipment/pageShipment';
const standardReducerImpl = new StandardReducerImpl<Retail>(entity, defaultRetail, apiUrl);
export type RetailState = StandardState<Retail>;
export const retail = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;