import ProTable from '@ant-design/pro-table';
import { Button, Col, Row, message } from "antd";
import { DrawerForm, ModalForm, ProFormDatePicker, ProFormSelect, ProFormText } from '@ant-design/pro-form';
import { delTestData, editTestData, fetchTestData } from './brand.reducer';
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'
import { useEffect, useState } from 'react';
import { uploadImage } from 'utils/file-utils';

const dataType = {
  1: '早上',
  2: '免费',
  3: '付费',
  4: '晚上',
}

const TestTable: any = (props: any) => {
  const [editor, setEditor] = useState<IDomEditor | null>(null);
  // 编辑器内容
  const [html, setHtml] = useState('<></>');
  // 工具栏配置
  const toolbarConfig: Partial<IToolbarConfig> = {};
  const editorConfig: Partial<IEditorConfig> = {
    placeholder: '请输入内容...',
    MENU_CONF: {
      'uploadImage': {
        // 自定义上传
        async customUpload(file: File, insertFn: any) {  // TS 语法
          // file 即选中的文件
          // 自己实现上传，并得到图片 url alt href
          // 最后插入图片
          const newDate = new Date().getTime();
          uploadImage(file, 'product/img-' + newDate + '.png').then(imageName => {
            message.success('图片上传成功');
            insertFn(imageName);
          }).catch(() => { message.error('图片上传失败'); });
        }
      }
    }
  };
  useEffect(() => {
    return () => {
      if (editor == null) return
      editor.destroy()
      setEditor(null)
    }
  }, [editor]);

  const testColumns: any = [{
    title: 'id',
    dataIndex: 'id',
    hideInSearch: true,
  }, {
    title: '标题',
    dataIndex: 'title',
  }, {
    title: '类型',
    dataIndex: 'type',
    valueEnum: dataType,
    hideInSearch: true,
  }, {
    title: '类型',
    dataIndex: 'type',
    hideInTable: true,
    renderFormItem: (text: any, record: any) => {
      return <ProFormSelect
        options={Object.entries<string>(dataType).map(([value, label]) => ({ value: (value), label }))}
        name={'type'}
        placeholder="投诉类型"
      />
    }
  }, {
    title: '时间',
    dataIndex: 'date_time',
    hideInSearch: true,
  }, {
    title: '时间',
    dataIndex: 'dateTime',
    hideInTable: true,
    renderFormItem: (text: any, record: any) => {
      return <ProFormDatePicker
        name={'dateTime'}
        placeholder="日期"
      />
    }
  }, {
    title: '操作',
    dataIndex: 'option',
    hideInSearch: true,
    render: (text: any, record: any) => {
      return <>{ActionButton(record)}|
        <Button type="link" onClick={() => {
          delTestData({ ids: record.id }).then((e: any) => {
            message.success('操作成功');
          }).catch((error: any) => {
            message.error('操作失败');
          })
        }} size="small">删除</Button>
      </>
    }
  }];

  const ActionButton = (record: any) => <ModalForm<any>
    title={!!record.id ? '编辑' : '创建'}
    //preserve={false}
    modalProps={{
      destroyOnClose: true,
    }}
    onVisibleChange={(e) => {
      e && record.content && setHtml(record.content);
      !e && setHtml('')
    }}
    trigger={<Button type={!!record.id ? 'link' : 'primary'} size={!!record.id ? 'small' : 'middle'}>{!!record.id ? '编辑' : '创建'}</Button>}
    width={'1600px'}
    onFinish={async (values) => {
      const obj = {
        ...values,
        id: record.id || '',
        content: html,
      };
      !obj.id && delete obj.id
      return new Promise<boolean>((resolve, reject) => {
        editTestData(obj).then((e: any) => {
          message.success('操作成功');
          resolve(true);
        }).catch((error: any) => {
          message.error('操作失败');
          resolve(false);
        })
      });
    }}
  >
    <Row>
      <Col span='7'>
        <ProFormText
          width="md"
          name="title"
          label="标题"
          initialValue={record.title}
        />
      </Col><Col span={1}></Col>
      <Col span='7'>
        <ProFormSelect
          options={Object.entries<string>(dataType).map(([value, label]) => ({ value: (value), label }))}
          name={'type'}
          label='类型'
          placeholder="类型"
          initialValue={record.type}
        />
      </Col><Col span={1}></Col>
      <Col span='7'>
        <ProFormText
          name={'date_time'}
          label='日期'
          placeholder="日期"
          initialValue={record.date_time}
        />
      </Col><Col span={1}></Col>
      <Col span={24}>
        <div className="editor-box--1">
          <Toolbar
            editor={editor}
            defaultConfig={toolbarConfig}
            mode="default"
            style={{ borderBottom: '1px solid #ccc' }}
          />
          <Editor
            defaultConfig={editorConfig}
            value={html}
            onCreated={setEditor}
            onChange={editor => setHtml(editor.getHtml())}
            mode="default"
            style={{ height: '500px', overflowY: 'hidden' }}
          />
        </div>
      </Col>
    </Row>
  </ModalForm>;

  return <DrawerForm<any>
    title={'测试'}
    //preserve={false}
    drawerProps={{
      destroyOnClose: true,
    }}
    trigger={<Button type="link" size="small">调整</Button>}
    width={'1600px'}
    onFinish={async values => {
      return true
    }}
  >
    {ActionButton({})}
    <ProTable<any>
      className="express-table"
      columns={testColumns}
      rowKey="id"
      request={async (params) => {
        let obj = { ...params };
        if (params.dateTime) {
          let dateTime = params.dateTime.split(' ')[0];
          dateTime = dateTime.replace(/-/gi, ".");
          obj = {
            ...obj,
            dateTime,
          }
        }


        let res = await fetchTestData({
          page: params.current || 1,
          page_num: params.pageSize || 20,
          ...obj,
        });
        console.log(res);
        return Promise.resolve({
          data: res?.data?.data?.data || [],
          success: true,
          total: res?.data?.data?.total || 0,
        });
      }}
      options={false}
    />
  </DrawerForm>
}

export default TestTable;