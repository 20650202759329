import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import ProForm, { ProFormDigit, ProFormRadio, ProFormSelect, ProFormSwitch } from "@ant-design/pro-form";
import { createEntity, getEntity, reset, updateEntity } from "./classification.reducer";
import { Classification } from "model/product/classification.model";
import { uploadImage } from "utils/file-utils";
import { Form, message } from "antd";
import UploadImgOne from "components/upload-img/upload-img-one"
import IntelFormText, { IntelSelectVal, internationalList, strParseJson } from "components/IntelFormText";
import ProCard from "@ant-design/pro-card";
import { useParamGeneral } from "components/useParamGenera";
import { PictureInternationObj } from "../product/product-edit";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";

export interface ClassificationEditProps extends EditStateAndDispatchProps<Classification>, StateProps, DispatchProps, RouteComponentProps {
}

const ClassificationEdit: FC<ClassificationEditProps> = props => {
  const { entity } = props;
  const [selectType, setSelectType] = useState<boolean>(false);
  const [name, setName] = useState<string>('分类名称');
  const [form] = Form.useForm();
  const [pictureInterKey, setPictureInterKey] = useState<string>('');
  const { generalLanguage, intlArr, defaultLanguage } = useParamGeneral();
  const [entityData, setEntityData] = useState<Classification>({});
  //图片国际化
  const [mainPicInternational, setMainPicInternational] = useState<any>({});//主图

  const internationalVal = (interObj: PictureInternationObj) => (intlArr.length === 1 && interObj[defaultLanguage]) || JSON.stringify(interObj);

  useEffect(() => {
    setPictureInterKey(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    const pictureObj = {
      picture: {
        data: mainPicInternational,
        action: setMainPicInternational
      },
    };
    const entityCopy = JSON.parse(JSON.stringify(entityData));
    pictureInternational(pictureInterKey, entityCopy, pictureObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureInterKey]);

  const pictureInternational = (type: string, data: any, pictureObj: any) => {
    const keyArr = Object.entries(pictureObj);
    const arr: any = [];
    const change = () => {
      const key = keyArr[arr.length][0];
      const newMainPic = {
        ...pictureObj[key].data,
        [type]: pictureObj[key].data[type] ? pictureObj[key].data[type] : '',
      };

      data[key] = newMainPic[type];
      arr.push('0');
      pictureObj[key].action(newMainPic);
      (keyArr.length === arr.length) ? setEntityData(data) : change();
    }; change();
  };

  const analysisPicture = (type: string, entityCopy: any) => {
    const picture = strParseJson(entityCopy.picture) ? JSON.parse(entityCopy.picture) : { [type]: entityCopy.picture };
    const newMainPic = {
      ...picture,
      ...mainPicInternational,
      [type]: (mainPicInternational[type] && mainPicInternational[type].length !== 0) ? mainPicInternational[type] : (picture[type] ? picture[type] : null),
    };
    setMainPicInternational(newMainPic);

    const pictureObj = {
      picture: {
        data: newMainPic,
        action: setMainPicInternational
      }
    };
    pictureInternational(type, entityCopy, pictureObj);
  };

  useEffect(() => {
    (entity && entity.level) && radioChange(entity.level);
    const entityCopy = JSON.parse(JSON.stringify(entity));
    entityCopy.picture = entity.picture ? entity.picture : null;
    setEntityData(entityCopy);
    setMainPicInternational({});
    setPictureInterKey(defaultLanguage);

  }, [entity, defaultLanguage]);

  useEffect(() => {
    entityData && analysisPicture(pictureInterKey, entityData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityData]);

  const uploadImg = (res?: Classification) => {
    return new Promise<Classification>((resolve, reject) => {
      if (res) {
        res.id = props.id;
        res.storeId = res.storeId ? res.storeId : 0;
        const mainArr = [];
        const mainObj: PictureInternationObj = {};
        const mainPictureFun = (resMain: Classification) => {
          const mainPicture: any = mainPicInternational[intlArr[mainArr.length]]?.concat();
          mainArr.push('0');
          if (mainPicture && mainPicture.length > 0 && typeof (mainPicture) !== 'string') {
            if (mainPicture[0].originFileObj) {
              //主图需上传
              const newDate = new Date().getTime();
              uploadImage(mainPicture[0].originFileObj, 'product/img-' + newDate).then(imageName => {
                mainObj[intlArr[mainArr.length - 1]] = imageName;
                if (mainArr.length === intlArr.length) {
                  resMain.picture = internationalVal(mainObj);
                  resolve(resMain);
                } else {
                  mainPictureFun(resMain);
                };
              }).catch(() => { message.error('图片上传失败'); reject(); })
            } else {
              //主图已上传且未修改
              mainObj[intlArr[mainArr.length - 1]] = mainPicture[0].url;
              if (mainArr.length === intlArr.length) {
                resMain.picture = internationalVal(mainObj);
                resolve(resMain);
              } else {
                mainPictureFun(resMain);
              };
            }
          } else {
            mainPicture && mainPicture.length > 0 && (mainObj[intlArr[mainArr.length - 1]] = mainPicture);
            if (mainPicture && mainPicture.length === 0) {
              resMain.picture = '';
            };
            if (mainArr.length === intlArr.length) {
              resMain.picture = internationalVal(mainObj);
              resolve(resMain);
            } else {
              mainPictureFun(resMain);
            };
          };
        };
        mainPictureFun(res);

      }
    })
  }

  const radioChange = (val: number) => {
    if (val === 1) {
      setSelectType(false);
      setName('分类名称');
    } else {
      setSelectType(true);
      setName('子类名称');
    }
  }

  return (
    <EditPage<Classification>
      title="分类"
      beforeUpdateEntity={uploadImg}
      form={form}
      {...props}
      entity={entityData}
    >
      <ProFormRadio.Group
        label="分类类型"
        name="level"
        disabled={entity.id ? true : false}
        initialValue={1}
        fieldProps={{
          onChange: e => radioChange(e.target.value),
        }}
        options={[{ value: 1, label: "大类" }, { value: 2, label: "子类" }]}
      />
      {
        selectType && <ProFormSelect
          width="md"
          name="parentId"
          label="选择分类名称"
          request={async () => {
            return props.entities.map(res => {
              return { value: res.id, label: internationalList(res.name || '') }
            })
          }}
        />
      }
      <IntelFormText
        label={name}
        name='name'
        form={form}
        required={true}
        extra={'限100个字以内'}
        fieldProps={{
          maxLength: 100
        }}
        value={entity.name || ''}
      />
      <Form.Item name={'storeId'}>
        <SelectSearch required={false} name="storeId" width="md" label='商家' labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
      </Form.Item>
      {entityData && <ProCard
        title="图片信息"
        bordered
        headerBordered
        collapsible
        extra={
          pictureInterKey && <IntelSelectVal generalLanguage={generalLanguage} intlArr={intlArr} defaultValue={pictureInterKey} onChange={(e: string) => setPictureInterKey(e)} />
        }
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        <ProForm.Item name={'picture'} label="主图">
          <UploadImgOne
            listType="picture-card"
            extra="建议尺寸：800*800像素, 最多上传一张, 不超过2M."
            title="上传"
            onChange={(val) => setMainPicInternational({
              ...mainPicInternational,
              [pictureInterKey]: val,
            })}
            value={entityData.picture}
          />
        </ProForm.Item>
      </ProCard>}
      <ProFormDigit width="xl" name="sort" label="排序" placeholder="正整数" extra="数字越小，展示时排的越靠前，默认在最上方" />
      <ProFormSwitch name="status"
        label="状态"
        width='sm'
        initialValue={0}
        getValueFromEvent={value => (value && 1) || 0}
        getValueProps={value => ({ checked: value === 1 ? true : false })}
        fieldProps={{
          checkedChildren: '启用',
          unCheckedChildren: '禁用'
        }}
      />
    </EditPage>);
};

const mapStateToProps = ({ classification }: RootState) => ({
  entity: classification.entity,
  entities: classification.entities,
  updateSuccess: classification.updateSuccess,
  updating: classification.updating,
  createSuccess: classification.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationEdit);
