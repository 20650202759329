import {
  STANDARD_ACTION_TYPES,
  StandardReducerImpl,
  StandardState,
} from "reducers/standard.reducer";
import { Order, defaultOrder } from "model/procure/order.model";
import {
  CrudUploadAction,
  Payload,
  PayloadResult,
} from "reducers/redux-action.type";
import axios from "axios";
import qs from "qs";
const entity = "order";

const apiUrl = "/api/yumei-mall-purchase/purchase-order";
const standardReducerImpl = new StandardReducerImpl<Order>(
  entity,
  defaultOrder,
  apiUrl
);

export type OrderState = StandardState<Order>;
export const order = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 取消订单
export const orderCancel = async (id: any, obj: any) => {
  const requestUrl = `${apiUrl}/${id}/un-shipped-cancel`;
  const axiosResponse = await axios.post<any>(requestUrl, obj);
  return axiosResponse;
};

// 拆单
export const splitOrder = async (orderLineId: any) => {
  const requestUrl = `${apiUrl}/disassembly/orderLine/${orderLineId}`;
  const axiosResponse = await axios.get<any>(requestUrl);
  return axiosResponse;
};


// 批量发货
export const batchShipment = async (data: any) => {
  const requestUrl = `${"/api/yumei-mall-purchase/purchase-order/ship/import"}`;
  const axiosResponse = await axios.post<any>(requestUrl, data);
  return axiosResponse;
};

// 推中台
export const pushCenter = async (data: any) => {
  const requestUrl = `/api/yumei-mall-purchase/purchase-order/${data.id}/send-oms/reprocess`;
  const axiosResponse = await axios.post<any>(requestUrl, data);
  return axiosResponse;
};

// 获取订单
export const purchaseOrder = async (data: any) => {
  let parm = "?";
  Object.keys(data).forEach((key) => {
    parm += `${key}=${data[key]}&`;
  });
  const requestUrl = `/api/yumei-mall-purchase/purchase-order`;
  const res = await axios.get(requestUrl + parm);
  return res;
};

// 获取关联订单
export const associateOrders = async (id: any) => {
  const requestUrl = `/api/yumei-mall-purchase/purchase-order/${id}/associate-orders`;
  const res = await axios.get(requestUrl);
  return res.data;
};

// 审核
export const auditPurchaseOrder = async (id: any) => {
  const requestUrl = `/api/yumei-mall-purchase/purchase-order/${id}/audit`;
  const res = await axios.post(requestUrl);
  return res.data;
};

// 发货
export const updateShip = async (data: any) => {
  const requestUrl = `${apiUrl}/ship`;
  const res = await axios.post(requestUrl, data);
  return res.data;
};

//快递
export const queryExpressList = async () => {
  const requestUrl = `/api/yumei-mall-logistics/express?page=0&size = 100`;
  const res = await axios.get(requestUrl);
  return res.data;
};

// 物流信息包裹
export const fetchPackage = async (params: any) => {
  const requestUrl = `/api/yumei-mall-purchase/purchase-order/${params?.id}/packages`;
  return await axios.get(requestUrl);
};

// 换货订单物流信息包裹
export const fetchExchangePackage = async (params: any) => {
  const requestUrl = `/api/yumei-mall-purchase/exchange-order/package/${params?.id}/packages`;
  return await axios.get(requestUrl);
};

// 以下废弃

//商家备注
export const updateRemark =
  (
    id: string | number,
    remark: string
  ): Payload<Order> | PayloadResult<Order> =>
  async (dispatch: any) => {
    const requestUrl = `${apiUrl}/${id}/remark`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl, remark),
    });
  };

// 修改收货信息
export const updateConsignee =
  (params: any): Payload<Order> | PayloadResult<Order> =>
  async (dispatch: any) => {
    const requestUrl = `${apiUrl}/${params.id}/address?source=service`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl, { ...params.address }),
    });
  };

// 审核订单
export const auditOrder =
  (params: any): Payload<Order> | PayloadResult<Order> =>
  async (dispatch: any) => {
    const requestUrl = `${apiUrl}/review?manualOrderStatus=${params.orderState}`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl, params.Ids),
    });
  };

// 审核订单
export const detailsAuditOrder = async (params: any) => {
  const requestUrl = `${apiUrl}/review?manualOrderStatus=${params.orderState}`;
  const res = await axios.post(requestUrl, params.Ids);
  return res.data;
};

// 订单行导入发货
export const importShip: CrudUploadAction<Order> =
  (data: any) => async (dispatch) => {
    const requestUrl = `${apiUrl}/ship/import`;
    const result = await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl, data),
    });
    return result;
  };

// 城市地址
export const queryBladeSystemSelect = async (code: string | number) => {
  const requestUrl = `/api/yumei-mall-region/region?parentCode.equals=${code}&size=100`;
  const res = await axios.get(requestUrl);
  return res.data;
};

// 搜索订单
export const searchOrder = async (id: string) => {
  const requestUrl = apiUrl + "/" + id;
  const res = await axios.get(requestUrl);
  return res.data;
};

// 搜索用户
export const searchBelongTstCode = async (params: any) => {
  const requestUrl = `/api/yumei-mall-customer/customer`;
  const res = await axios.get(requestUrl, {
    params,
  });
  return res.data;
};

// 拆单
export const requestSplitOrder = async (data: any) => {
  const requestUrl = `/api/yumei-mall-order/order/suborder/split`;
  const res = await axios.post(requestUrl, data);
  return res;
};

// 批量取消订单
export const batchCancelOrder = async (data: any) => {
  const requestUrl = `/api/yumei-mall-order/order/batch/cancel`;
  const res = await axios.post(requestUrl, { ids: data });
  return res;
};

//批量导入手工单
export const importManualOrderEntities: CrudUploadAction<Order> =
  (data: any) => async (dispatch) => {
    const requestUrl = `/api/yumei-mall-order/order/manual/import`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl, data),
    });
  };

// 创建手工单
export const manualOrder = async (data: any) => {
  const requestUrl = `/api/yumei-mall-order/order/manual`;
  const res = await axios.post(requestUrl, data);
  return res;
};

// 待发货/出库中-退款
export const updateApplyAfterSale =
  (params: any): Payload<Order> | PayloadResult<Order> =>
  async (dispatch: any) => {
    const requestUrl = `${apiUrl}/${params.id}/cancelAndRefund`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl, { ...params.value }),
    });
  };

// 发货 推中台
export const updateShipMiddleground =
  (id: string): Payload<Order> | PayloadResult<Order> =>
  async (dispatch: any) => {
    const requestUrl = `${apiUrl}/ship/${id}`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl),
    });
  };

interface closeData {
  code: string;
  desc: string;
}

//取消
export const closeOrder = async (
  orderId: number | string,
  data: closeData,
  force?: boolean
) => {
  const requestUrl = `${apiUrl}/${orderId}/cancel?force=${force || false}`;
  let res: any = {};
  await axios
    .post(requestUrl, {
      code: data.code,
      desc: data.desc,
      force: force || false,
    })
    .then((e) => {
      res = e;
    })
    .catch((e) => {
      res = e.response;
    });
  return res;
};

// 待发货订单导出
export const exportShipIds = async (ids: any[]) => {
  const requestUrl = `${apiUrl}/ship/export`;
  const res = await axios.post(requestUrl, ids);
  return res.data;
};

// 子单信息
export const fetchGetSubSheet = async (id?: string | number) => {
  const requestUrl = `${apiUrl}/suborders/${id}`;
  const res = await axios.get(requestUrl);
  return res.data;
};

export const seeLogistics = async (id: any) => {
  const requestUrl = `/api/yumei-mall-logistics/logistic/query/${id}`;
  const res = await axios.get(requestUrl);
  return res.data;
};

// 修改物流信息
export const updateLogistics = async (params: any) => {
  const requestUrl = `${apiUrl}/package/logistics`;
  const res = await axios.post(requestUrl, params);
  return res.data;
};

// 运费修改
export const updatePostage =
  (
    id: string | number,
    postage: number
  ): Payload<Order> | PayloadResult<Order> =>
  async (dispatch: any) => {
    const requestUrl = `${apiUrl}/${id}/postage/${postage}`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.put(requestUrl),
    });
  };

// 物流轨迹
export const queryLogistic = async (params: any) => {
  const requestUrl = `/api/yumei-mall-logistics/logistic/query-by-code-no`;
  const res = await axios.post(requestUrl, params);
  return res.data;
};

// 查询订单
export const fetchOrderList = async (params: any) => {
  const requestUrl = `${apiUrl}`;
  const res = await axios.get(requestUrl, {
    params,
  });
  return res;
};

// 查询订单
export const getOrderMoney = async (params: any) => {
  const requestUrl = `${apiUrl}/query/total`;
  const res = await axios.get(requestUrl, {
    params,
  });
  return res;
};

// 发货 推中台
export const confirmOrderReceive =
  (id: any): Payload<Order> | PayloadResult<Order> =>
  async (dispatch: any) => {
    const requestUrl = `/api/yumei-mall-order/order/${id}/receive`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl),
    });
  };

//废弃券
export const abandonedCoupon: CrudUploadAction<any> =
  (data, params: any) => async (dispatch) => {
    const requestUrl = `${apiUrl}/cancel/ids`;
    return await dispatch({
      type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
      payload: axios.post(requestUrl, params),
    });
  };

// 销售订单导出
export const exportOrder = async (params: any) => {
  const requestUrl = `${apiUrl}/export`;
  const res = await axios.get(
    requestUrl + "?" + qs.stringify(params, { indices: false }),
    {
      responseType: "blob",
    }
  );
  return res;
};

export { apiUrl };
