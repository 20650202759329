import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity } from "./publisher.reducer";
import { RouteComponentProps } from "@reach/router";
import CommentEdit from './publisher-edit';
import { Image } from "antd";
import { internationalList } from "components/IntelFormText";
import { Publisher } from "model/video/Publisher.model";

export interface CommentListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const CommentList: FC<CommentListProps> = (props) => {
  const columns: ProColumns<Publisher>[] = [{
    title: '头像',
    dataIndex: 'avatar',
    render: (res, record) => { return  <> <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.avatar} /> </> }
  }, 
  // {
  //   title: '用户编码',
  //   dataIndex: 'code'
  // },
   {
    title: '昵称',
    dataIndex: 'name',
    render: (dom, record) => internationalList(record.name || '')
  }];


  return (
    <ListViewPage<Publisher>
      columns={columns}
      detailsIndex={false}
      editPage={(params) => <CommentEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ publisher }: RootState) => ({
  entity: publisher.entity,
  entities: publisher.entities,
  loading: publisher.loading,
  updateSuccess: publisher.updateSuccess,
  total: publisher.total,
  createSuccess: publisher.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CommentList);