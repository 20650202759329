import ProCard from "@ant-design/pro-card";
import { ModalForm, ProFormUploadButton } from "@ant-design/pro-form";
import { Form, Space } from "antd";
import { IntelSelectVal, strParseJson } from "components/IntelFormText";
import UploadImgComplex from "components/upload-img/upload-img-complex";
import UploadImgOne from "components/upload-img/upload-img-one";
import { useParamGeneral } from "components/useParamGenera";
import { useEffect, useState } from "react";
import Link from "antd/lib/typography/Link";
import mobileBg from '../../../assets/images/mobile-bg.jpg'

interface ProductEditCardPictureProps {
  pictureObjData: any;
  setPictureObj: any;
  mainPicInternational: any;//主图
  setMainPicInternational: any;
  picInternational: any;//配图
  setPicInternational: any;
  bannerInternational: any;//banner图
  setBannerInternational: any;
  videoInternational: any;//视频
  setVideoInternational: any;
  detailInternational: any;//详情
  setDetailInternational: any;
  entity: any;
};
const ProductEditCardPicture = (props: ProductEditCardPictureProps) => {

  const {
    pictureObjData,
    setPictureObj,
    mainPicInternational,
    setMainPicInternational,
    picInternational,
    setPicInternational,
    bannerInternational,
    setBannerInternational,
    videoInternational,
    setVideoInternational,
    detailInternational,
    setDetailInternational,
    entity,
  } = props;

  const [pictureInterKey, setPictureInterKey] = useState<string>('');
  const { generalLanguage, intlArr, defaultLanguage } = useParamGeneral();
  const [detailData, setDetailData] = useState<any>([]);//详情数组

  useEffect(() => {
    setPictureInterKey(defaultLanguage);
  }, [defaultLanguage]);

  useEffect(() => {
    setPictureInterKey(defaultLanguage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  useEffect(() => {
    pictureObjData && analysisPicture(pictureInterKey, pictureObjData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureObjData]);

  useEffect(() => {
    const pictureObj = {
      mainPicture: {
        data: mainPicInternational,
        action: setMainPicInternational
      },
      pictures: {
        data: picInternational,
        action: setPicInternational
      },
      banner: {
        data: bannerInternational,
        action: setBannerInternational
      },
      video: {
        data: videoInternational,
        action: setVideoInternational
      },
      detail: {
        data: detailInternational,
        action: setDetailInternational
      },
    };
    const entityCopy = JSON.parse(JSON.stringify(pictureObjData));
    pictureInternational(pictureInterKey, entityCopy, pictureObj);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureInterKey]);

  const analysisPicture = (type: string, entityCopy: any) => {
    const mainPicture = strParseJson(entityCopy.mainPicture) ? JSON.parse(entityCopy.mainPicture) : { [type]: entityCopy.mainPicture };
    const newMainPic = {
      ...mainPicture,
      ...mainPicInternational,
      [type]: mainPicInternational[type] ? mainPicInternational[type] : (mainPicture[type] ? mainPicture[type] : null),
    };
    setMainPicInternational(newMainPic);

    const pictures = strParseJson(entityCopy.pictures) ? JSON.parse(entityCopy.pictures) : { [type]: entityCopy.pictures };
    const newPictures = {
      ...pictures,
      ...picInternational,
      [type]: picInternational[type] ? picInternational[type] : (pictures[type] ? pictures[type] : null),
    }
    setPicInternational(newPictures);

    const banner = strParseJson(entityCopy.banner) ? JSON.parse(entityCopy.banner) : { [type]: entityCopy.banner };
    const newBanner = {
      ...banner,
      ...bannerInternational,
      [type]: bannerInternational[type] ? bannerInternational[type] : (banner[type] ? banner[type] : null),
    }
    setBannerInternational(newBanner);

    const video = strParseJson(entityCopy.video) ? JSON.parse(entityCopy.video) : { [type]: entityCopy.video };
    const newVideo = {
      ...video,
      ...videoInternational,
      [type]: videoInternational[type] ? videoInternational[type] : (video[type] ? video[type] : null),
    }
    setVideoInternational(newVideo);

    const detail = strParseJson(entityCopy.detail) ? JSON.parse(entityCopy.detail) : { [type]: entityCopy.detail };
    const newDetail = {
      ...detail,
      ...detailInternational,
      [type]: detailInternational[type] ? detailInternational[type] : (detail[type] ? detail[type] : null),
    }
    setDetailInternational(newDetail);


    const pictureObj = {
      mainPicture: {
        data: newMainPic,
        action: setMainPicInternational
      },
      pictures: {
        data: newPictures,
        action: setPicInternational
      },
      banner: {
        data: newBanner,
        action: setBannerInternational
      },
      video: {
        data: newVideo,
        action: setVideoInternational
      },
      detail: {
        data: newDetail,
        action: setDetailInternational
      },
    };
    pictureInternational(type, entityCopy, pictureObj);

  };

  const pictureInternational = (type: string, data: any, pictureObj: any) => {
    const keyArr = Object.entries(pictureObj);
    const arr: any = [];
    const change = () => {
      const key = keyArr[arr.length][0];
      const newMainPic = {
        ...pictureObj[key].data,
        [type]: pictureObj[key].data[type] ? pictureObj[key].data[type] : '',
      };
      pictureObj[key].action(newMainPic);
      data[key] = newMainPic[type];
      arr.push('0');
      (keyArr.length === arr.length) ? setPictureObj(data) : change();
    }; change();
  };

  return pictureObjData && <ProCard
    title="商品图片信息"
    bordered
    headerBordered
    collapsible
    defaultCollapsed
    extra={
      pictureInterKey && <IntelSelectVal generalLanguage={generalLanguage} intlArr={intlArr} defaultValue={pictureInterKey} onChange={(e: string) => setPictureInterKey(e)} />
    }
    style={{
      marginBottom: 16,
      minWidth: 400,
      maxWidth: '100%',
    }}
  >
    <Form.Item name="mainPicture" label="商品主图" >
      <UploadImgOne
        listType="picture-card"
        extra="建议尺寸：800*800像素, 最多上传一张, 不超过2M."
        title="上传"
        onChange={(val) => setMainPicInternational({
          ...mainPicInternational,
          [pictureInterKey]: val,
        })}
        value={pictureObjData.mainPicture}
      />
    </Form.Item>
    <Form.Item name="banner" label="商品banner图" >
      <UploadImgOne
        listType="picture-card"
        extra="建议尺寸：800*200像素, 最多上传一张, 不超过2M."
        title="上传"
        onChange={(val) => setBannerInternational({
          ...bannerInternational,
          [pictureInterKey]: val,
        })}
      />
    </Form.Item>
    <Form.Item name="video" label="主图视频" >
      <UploadImgOne
        upType="video"
        listType="text"
        extra="上传视频建议为mp4格式, 最好不要超过5M"
        title="上传"
        onChange={(val) => setVideoInternational({
          ...videoInternational,
          [pictureInterKey]: val,
        })}
      />
    </Form.Item>
    <Form.Item name="pictures" label="商品配图" >
      <UploadImgComplex
        listType="picture-card"
        extra="建议尺寸：800*800像素，最多上传6张, 不超过2M."
        title="上传"
        fileType='img'
        maxCount={6}
        onChange={(val) => setPicInternational({
          ...picInternational,
          [pictureInterKey]: val,
        })}
      />
    </Form.Item>
    <Space className="pim-detail" align='start'>
      <Form.Item name="detail" label="商品详情" >
        <UploadImgComplex
          listType="picture-card"
          extra="不限数量, 建议单张大小不超过2M."
          title="上传"
          base64
          maxCount={1000}
          storageDetail={setDetailData}
          onChange={(val) => setDetailInternational({
            ...detailInternational,
            [pictureInterKey]: val,
          })}
        />
      </Form.Item>
      <ModalForm
        title="预览"
        trigger={<Link>预览</Link>}
        width="600px"
        onFinish={async () => true}
      >
        <Space className="pim-detail-show">
          <div className="details-detail-img-box">
            <img className="mobile-img" src={mobileBg} alt="" />
            <ProFormUploadButton
              fileList={detailData}
              fieldProps={{ maxCount: 15, className: "pro-upload-picture-box" }}
            />
          </div>
        </Space>
      </ModalForm>
    </Space>
  </ProCard>
}

export default ProductEditCardPicture;