import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity, handleComplaint, getDetail, getEntities } from './complaint.reducer';
import { Button, Card, Image, message } from "antd";
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Complaint } from "model/dealer/complaint.model";
import Title from "antd/lib/typography/Title";
import ProForm, { ModalForm, ProFormTextArea } from "@ant-design/pro-form";
import UploadImgComplex from "components/upload-img/upload-img-complex";

export interface ComplaintDetailsProps extends DetailsStateAndDispatchProps<Complaint>,
  RouteComponentProps, StateProps, DispatchProps {
  entityData?: any;
  detailsId?: any;
  parentMethod?: any
}
const ComplaintDetails: FC<ComplaintDetailsProps> = (props) => {
  const { detailsId, entityData, parentMethod } = props;
  const [newEntity, setNewEntity] = useState<Complaint>({});
  const [refreshFlag, setRefreshFlag] = useState(0);

  useEffect(() => {
    if (detailsId && entityData) {
      let data = entityData.concat().filter((e: any) => e.id === detailsId)?.[0]
      if (data) {
        setNewEntity(data)
        getDetailFn(data.complainNo)
      }

    }

  }, [detailsId, entityData])


  const complaintUsersColumns = [{
    title: '授权号',
    dataIndex: 'complainNo',
  }, {
    title: '用户姓名',
    dataIndex: 'customerName',
  }, {
    title: '联系电话',
    dataIndex: 'customerPhone',
  }];
  const complaintJXSColumns = [{
    title: '投诉单号',
    dataIndex: 'code',
  }, {
    title: '投诉时间',
    dataIndex: 'complainTime',
  }, {
    title: '投诉部门',
    dataIndex: 'department',
  }, {
    title: '投诉类型',
    dataIndex: 'type',
  }, {
    title: '投诉描述',
    dataIndex: 'complainText',
  }, {
    title: '投诉凭证',
    dataIndex: 'complainImgList',
    render: (dom: any, record: any) => {
      return record.complainImgList ? <div style={{ display: 'flex', flexWrap: 'wrap' }}>{record.complainImgList.map((e: any) => <Image style={{ padding: '5px', boxSizing: 'border-box' }} width={80} src={e} />)}</div> : <></>
    }
  },]

  const ProcessingResultsColumns = [{
    title: '处理人',
    dataIndex: 'examineTime',
  }, {
    title: '处理时间',
    dataIndex: 'examineTime',
  }, {
    title: '处理回复',
    dataIndex: 'examineText',
  }, {
    title: '投诉凭证',
    dataIndex: 'examineImgList',
    render: (dom: any, record: any) => {
      return record.examineImgList ? <>{record.examineImgList.map((e: any) => <Image width={80} src={e} />)}</> : <></>
    }
  }]

  const getDetailFn = (complainNo: any) => {
    getDetail({
      complainNo: complainNo
    }).then((e: any) => {
      if (e.length > 0) {
        setNewEntity(e[0])
        setRefreshFlag(new Date().getTime());
      }
    })

  }

  return (
    <DetailsPage<Complaint>
      title={'投诉详情'}
      key={refreshFlag}
      hideEdit={true}
      {...props}
    >
      <Title level={5} style={{ marginBottom: '40px' }}>投诉状态：{newEntity.state === 1 ? '待处理' : '已处理'}</Title>
      <Card style={{ marginBottom: '40px' }} title="投诉用户">
        <ProDescriptions<Complaint>
          columns={complaintUsersColumns as any}
          dataSource={newEntity}
          column={1}
        />
      </Card>
      <Card style={{ marginBottom: '40px' }} title="经销商投诉内容">
        <ProDescriptions<Complaint>
          columns={complaintJXSColumns as any}
          dataSource={newEntity}
          column={1}
        />
      </Card>

      <div className="flexright">
        {newEntity.state !== 2 && <ModalForm
          title={'处理'}
          trigger={<Button className="mbtm10" type="primary">处理</Button>}
          modalProps={{
            destroyOnClose: true,
          }}

          preserve={false}
          onFinish={async (data: any) => {
            if (!data.examineText) {
              message.error('处理回复不能为空');
              return false
            }

            const params = {
              ...data,
              id: newEntity.id,
              examineImg: data.examineImg.concat().map((e: any) => e.url),
            }
            return new Promise<boolean>((resolve, reject) => {
              handleComplaint(params).then(e => {
                message.success('处理成功');
                getDetailFn(newEntity.complainNo)
                parentMethod()
                resolve(true);
              }).catch(error => {
                message.error(error?.response?.data?.detail);
                resolve(false);
              })
            });
          }}
        >

          <ProFormTextArea
            width="md"
            name="examineText"
            label="处理回复"
          />
          <ProForm.Item name="examineImg" label="相关图片">
            <UploadImgComplex
              listType="picture-card"
              extra="建议尺寸：800*800像素，最多上传6张, 不超过2M."
              title="上传"
              Immediate={true}
              fileType='img'
              maxCount={30}
            />
          </ProForm.Item>
        </ModalForm>
        }
      </div>
      {newEntity.state === 2 &&
        <Card title="处理结果">
          <ProDescriptions<Complaint>
            columns={ProcessingResultsColumns as any}
            dataSource={newEntity}
            column={1}
          />
        </Card>
      }
    </DetailsPage>);

};

const mapStateToProps = ({ complaint }: RootState) => ({
  entity: complaint.entity,
});

const mapDispatchToProps = {
  getEntity,
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintDetails);
