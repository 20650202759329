import axios from 'axios';
import { Wechatbind, defaultWechatbind } from 'model/customerCore/wechatbind.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'wechatbind';
const apiUrl = '/api/yumei-mall-auth/user-oauth';
const standardReducerImpl = new StandardReducerImpl<Wechatbind>(entity, defaultWechatbind, apiUrl);
export type WechatbindState = StandardState<Wechatbind>;
export const wechatbind = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 解绑
export const unbindWechat = async(id:any) => {
    const axiosResponse = await axios.delete(`/api/yumei-mall-auth/user-oauth/${id}`);
    return axiosResponse.data || [];
}