import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity, walletAccountTypeList } from "./level.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Level } from "model/customerCore/level.model";
import { ProFormDigit, ProFormText, ProFormSelect } from "@ant-design/pro-form";
export interface LevelEditProps extends EditStateAndDispatchProps<Level>, StateProps, DispatchProps, RouteComponentProps {
}

const LevelEdit: FC<LevelEditProps> = props => {
  const { entity } = props;

  return (
    <EditPage<Level>
      title="级别"
      {...props}
    >
      <ProFormText
        name="levelName"
        label="级别名称"
      />
      <ProFormDigit
        name="levelNo"
        label="级别编号"
        disabled={(entity?.levelNo || entity?.levelNo === 0) ? true : false}
      />
      <ProFormSelect
        rules={[{ required: true }]}
        request={async (params) => {
          const unitRequest: any = await walletAccountTypeList();
          return unitRequest.walletAccountType.map((item: any) => ({
            value: item?.name,
            label: item?.desc,
          }))
        }}
        name='walletAccountType'
        placeholder="选择账户类型"
        label="账户类型"
      />
      <ProFormDigit
        name="levelSort"
        label="排序"
      />
    </EditPage>);
};

const mapStateToProps = ({ level }: RootState) => ({
  entity: level.entity,
  updateSuccess: level.updateSuccess,
  updating: level.updating,
  createSuccess: level.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelEdit);
