import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import RelationshipTreeDetails from './relationshipTree-details';
import RelationshipTreeEdit from './relationshipTree-edit';
import RelationshipTreeList from './relationshipTree-list';

const DealerRelationshipTree: React.FC<RouteComponentProps> = () => (
  <Router>
    <RelationshipTreeList path="/" />
    <RelationshipTreeDetails path=":id" />
    <RelationshipTreeEdit path=":id/edit" />
    <RelationshipTreeEdit path="new" />
  </Router>
);
export default DealerRelationshipTree;
