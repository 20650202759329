import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form} from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./secondReview.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { SecondReview } from "model/dealer/secondReview.model";
export interface SecondReviewEditProps extends EditStateAndDispatchProps<SecondReview>, StateProps, DispatchProps, RouteComponentProps {
}

const SecondReviewEdit: FC<SecondReviewEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<SecondReview>
      title=""
      form={form}
      {...props}
    >
    </EditPage>);
};

const mapStateToProps = ({ secondReview }: RootState) => ({
  entity: secondReview.entity,
  updateSuccess: secondReview.updateSuccess,
  updating: secondReview.updating,
  createSuccess: secondReview.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SecondReviewEdit);
