import React, { FC, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./tagTask.reducer";
import { ProFormDateTimePicker, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Col, Form, Row } from "antd";
import { useEnumValue } from "common/enum/use-enum-value";
import SelectSearch from "components/select-search";
import { TagTask } from "model/customerCore/tagTask.model";
import { fetchCustomerGroupList } from "../group/group.reducer";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";

export interface TagTaskEditProps extends EditStateAndDispatchProps<TagTask>, StateProps, DispatchProps, RouteComponentProps {
}

const TagTaskEdit: FC<TagTaskEditProps> = (props) => {

  const { convertEnumToOptionType } = useEnumValue();
  const [sellerName, setSellerName] = useState('');
  const [tagName, setTagName] = useState('');

  const beforeUpdateEntity= (res: any) => {
    return new Promise<TagTask>((resolve, reject) => {
      res.ruleConfig.sellerName = sellerName;
      res.tagName = tagName;
      resolve(res);
    })
  }

  return (
    <EditPage<TagTask>
      title="标签任务"
      {...props}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row>
        <Col span='7'>
          <ProFormText
            name="name"
            label="任务名称"
          />
        </Col><Col span='1' />
        <Col span='7'>
          <ProFormDateTimePicker
            name="startTime"
            label="开始时间"
          />
        </Col><Col span='1' />
        <Col span='7'>
          <ProFormDateTimePicker
            name="endTime"
            label="结束时间"
          />
        </Col><Col span='1' />
        <Col span='7'>
          <ProFormSelect
            options={convertEnumToOptionType('tagTaskExecutor')}
            name="executor"
            label="执行器"
          />
        </Col><Col span='1' />
        <Col span='7'>
          <Form.Item name={['ruleConfig', 'sellerId']}>
            <SelectSearch optionKey="name" width={'sm'} fetchOptionKey={setSellerName} label="店铺" required={false} name={['ruleConfig', 'sellerId']} placeholder="店铺" labelValue="name" defaultParams={'id.equals'} fetchFunc={fetchStoreList} searchParams='name.contains' />
          </Form.Item>
        </Col><Col span='1' />
        <Col span='7'>
          <ProFormDateTimePicker
            name={['ruleConfig', "orderDurationTime"]}
            label="多久时间内订单"
            extra="从哪个开始时间算"
          />
        </Col><Col span='1' />
        <Col span='7'>
          <ProFormSelect
            options={convertEnumToOptionType('taskRuleConfigType')}
            name={['ruleConfig', "configType"]}
            label="配置类型"
          />
        </Col><Col span='1' />
        <Col span='7'>
          <Form.Item name={['tagId']}>
            <SelectSearch optionKey="name" togetherParams={{'reverseTag.equals': false}} width={'sm'} fetchOptionKey={setTagName} label="标签" required={false} name={'tagId'} placeholder="标签" labelValue="name" defaultParams={'id.equals'} fetchFunc={fetchCustomerGroupList} searchParams='name.contains' />
          </Form.Item>
        </Col><Col span='1' />
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ tagTask }: RootState) => ({
  entity: tagTask.entity,
  updateSuccess: tagTask.updateSuccess,
  updating: tagTask.updating,
  createSuccess: tagTask.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TagTaskEdit);
