import {BaseModel} from "../base.model";

export interface Unit extends BaseModel {
    id?: number,
    storeName?: string,
    name?: string;
    wechatId?: number;
    phoneRegion?: string; 
    phone?: string; 
    credentialsNo?: string; 
    sourceName?: string;
    sourceAuthorizationCode?: string;
    brandName?: string;
    applyTime?: string;
    status?: string;  //审核状态
    addressApplication?: any;
    avatar?: any;
    brandIds?: any;
    authorizationCode?: string;
    auditDetails?: any;
    brandDetails?: any;
    rechargeMap?: any;
    levelName?: any;
    levelType?: string;
    customerId?: string;
    toStoreId?: string;
    toStoreName?: string
    storeId?: any
}

export const UnitStatus = {
    'Created': '待审核',
    'Recharged': '已充值待审核',
    'AuditSuccess': '审核通过授权中',
    'AuditFail': '审核失败',
    'Closed': '关闭',
    'Completed': '授权完成',
    '': '全部',
};


export const signStatusObj:any = {
    NotSigned:"未签署",
    Signing:"签署中",
    Rejected:"拒绝",
    Complete:"已完成",
    Expired:"过期",
    Invaliding:"作废中",
    Invalided:"已作废",
    Failed:"签署失败"
  }


export interface ProvinceCode {
    code: string;
    name?: string;
    id?: string | number;
  }

export const defaultUnit:  Readonly<Unit> = {};