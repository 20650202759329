import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultSecondSettled, SecondSettled } from 'model/dealer/secondSettled.model';

const entity = 'SecondSettled';
const apiUrl = '/api/yumei-mall-customer/customer/apply-two-Info/findByActionPage';
const standardReducerImpl = new StandardReducerImpl<SecondSettled>(entity, defaultSecondSettled, apiUrl);
export type SecondSettledState = StandardState<SecondSettled>;
export const secondSettled = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

