import { FC, ReactNode, useState } from "react";
import { Button, Drawer, Dropdown, Form, Input, InputNumber, Menu, Popconfirm, Space, Tabs, Tag, Tooltip, Image } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { batchProduct, deleteEntity, getEntities, getEntity } from "./wholesale.reducer";
import { RouteComponentProps } from "@reach/router";
import { Item, Product, ProductStatus } from "model/product/product.model";
import { useEnumValue } from "common/enum/use-enum-value";
import { ClockCircleOutlined, CloseOutlined, DownOutlined, ProfileOutlined } from "@ant-design/icons";
import { ModalForm } from "@ant-design/pro-form";
import { cancelStock, importStock, searchSkuList } from "pages/pim/stock/stock.reducer";
import { Storage } from 'utils/storage-util';
import { fetchBrandList } from "pages/pim/brand/brand.reducer";
import ProductListEditControl from "pages/pim/product/product-list-edit-control";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import ProductDetails from "pages/pim/product/product-details";
import { WholesaleProduct } from "model/product/wholesale-product.model";
import AuthButton from "common/auth/auth-button";
import Authorized from "common/auth/authorized";
import ImportDataDialog from "upload/import-data-dialog";
import { internationalList } from "components/IntelFormText";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import { searchProductPromotion } from "../product/product.reducer";
import SelectSearch from "components/select-search";


export interface WholesaleListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

interface skuList {
  total: number;
  data: never[];
}

const uploadColumns: ProColumns<WholesaleProduct>[] = [{
  title: '批发商id',
  dataIndex: 'shopId',
  valueType: 'text',
}, {
  title: '商品id',
  dataIndex: ['product', "id"],
  valueType: 'text',
}];

const WholesaleList: FC<WholesaleListProps> = (props) => {

  const { importStock, batchProduct, cancelStock } = props;

  const storeId = Storage.session.get('store-id');
  const [detailShow, SetDetailShow] = useState(false);
  const [detailId, SetDetailId] = useState('');
  const { convertEnumToOptionType } = useEnumValue();
  const [skuList, setSkuList] = useState<skuList>({ total: 0, data: [] });
  const [creatSku, setCreatSku] = useState<any[]>([]);
  const [status, setStatus] = useState<string>('');
  const [shopId, setShopId] = useState<string>('');

  const detailColumns: ProColumns<Product, 'productStatus'>[] = [{
    title: '商品信息',
    hideInDescriptions: true,
    render: ((dom: any, record: any) =>
      <Space className="product-list-picture">
        <Image width={80} src={internationalList(record.mainPicture || '')} />
        <ul>
          <li><span className="product-name" onClick={() => { SetDetailShow(true); SetDetailId(record.id); }}>{internationalList(record.name || '')}</span></li>
          {record?.tags && record?.tags.length > 0 && <li>{record.tags.map((e: string) => {
            const arr = convertEnumToOptionType('productTag').filter((t: any) => e === t.value);
            return arr.length > 0 ? <Tag key={arr[0].label} style={{ marginBottom: '4px' }} color="green">{arr[0].label}</Tag> : '';
          })}</li>}
        </ul>
      </Space>
    )
  }, {
    title: 'Id信息',
    hideInDescriptions: true,
    render: ((dom: any, record: any) =>
      <ul className="product-id-ul">
        <li>ID：<span>{record.id}</span></li>
        <li>编码：<span>{record.code}</span></li>
        {/* <li>外部编码：<span>{record.externalCode}</span></li> */}
      </ul>
    )
  }, {
    title: 'Sku编码',
    hideInDescriptions: true,
    render: ((dom: any, record: Product) =>
      <Tooltip placement="top" title={
        <ul className="product-id-ul-Tooltip">
          {record?.items && record.items.map((e: Item) => {
            return <li><span>{e?.code}</span></li>
          })}
        </ul>
      } key="SkuCode">
        <ul className="product-id-ul">
          {record?.items && record.items.map((e: Item, index: number) => {
            return index < 3 && <li><span>{e?.code}</span></li>
          })}
        </ul>
      </Tooltip>
    )
  }, {
    title: '商家',
    hideInDescriptions: true,
    dataIndex: 'sellerName',
    valueType: 'text',
    width: 100,
  }, {
    title: '商品品牌',
    hideInDescriptions: true,
    dataIndex: ['brand', 'brandName',],
    width: 100,
    render: (text, record) => internationalList(record?.brand?.brandName || '')
  }, {
    title: '区域模版',
    hideInTable: true,
    dataIndex: ['regionTemplate', 'templateName'],
  }, {
    title: '平台商品分类',
    dataIndex: ['classify', 'name'],
    hideInTable: true,
    width: 100,
    valueType: 'text',
    render: (text, record) => internationalList(record?.classify?.name || '')
  }, {
    title: '商品类目',
    dataIndex: ['category', 'name'],
    width: 100,
    valueType: 'text',
    render: (text, record) => internationalList(record?.category?.name || '')
  }, {
    title: '商品状态',
    dataIndex: 'status',
    valueType: 'productStatus',
    width: 100,
  }, {
    title: '上下架时间',
    valueType: 'text',
    width: 100,
    render: (text, record) => {
      const date = Number((new Date()).getTime());
      const onScheduledDate = record.onScheduled ? Number((new Date((record.onScheduled as unknown) as Date)).getTime()) : 0;
      const offScheduledDate = record.offScheduled ? Number((new Date((record.offScheduled as unknown) as Date)).getTime()) : 0;
      return <div>
        {record.onScheduled && <p>
          {date < onScheduledDate && <Button type="link" size="small" icon={<ClockCircleOutlined />} />}
          <Tooltip placement="top" title="上架时间" key="on">{record.onScheduled}</Tooltip>
        </p>}
        {record.offScheduled && <p>
          {date < offScheduledDate && <Button type="link" size="small" icon={<ClockCircleOutlined />} />}
          <Tooltip placement="bottom" title="下架时间" key="off">{record.offScheduled}</Tooltip>
        </p>}
      </div>
    }
  }];

  const searchFields = [{
    label: '商品名称',
    name: 'productName.contains',
  }, {
    label: '商品编码',
    name: 'code.equals',
  }];

  const columns: ProColumns<WholesaleProduct, 'productStatus'>[] = [{
    title: '商品信息',
    dataIndex: ['product', 'mainPicture'],
    valueType: 'image',
    hideInDescriptions: true,
    render: ((dom: any, record: any) =>
      <Space className="product-list-picture">
        <Image src={internationalList(record.product?.mainPicture || '')} />
        <ul>
          <li><span className="product-name" onClick={() => { SetDetailShow(true); SetDetailId(record?.product?.id); }}>{internationalList(record.product.name || '')}</span></li>
          {record?.product?.tags && record?.product?.tags.length > 0 && <li>{record?.product.tags.map((e: string) => {
            const arr = convertEnumToOptionType('productTag').filter((t: any) => e === t.value);
            return arr.length > 0 ? <Tag key={arr[0].label} style={{ marginBottom: '4px' }} color="green">{arr[0].label}</Tag> : '';
          })}</li>}
        </ul>
      </Space>
    )
  }, {
    title: 'Id信息',
    hideInDescriptions: true,
    render: ((dom: any, record: any) =>
      <ul className="product-id-ul">
        <li>ID：<span>{record?.product?.id}</span></li>
        <li>编码：<span>{record?.product?.code}</span></li>
        {/* <li>外部编码：<span>{record?.product?.externalCode}</span></li> */}
        <li>批发商id：<span>{record?.shopId}</span></li>
      </ul>
    )
  }, {
    title: 'Sku编码',
    hideInDescriptions: true,
    render: ((dom: any, record: any) =>
      <Tooltip placement="top" title={
        <ul className="product-id-ul-Tooltip">
          {record?.product?.items && record?.product.items.map((e: Item) => {
            return <li><span>{e?.code}</span></li>
          })}
        </ul>
      } key="SkuCode">
        <ul className="product-id-ul">
          {record?.product?.items && record?.product.items.map((e: Item, index: number) => {
            return index < 3 && <li><span>{e?.code}</span></li>
          })}
        </ul>
      </Tooltip>
    )
  }, {
    title: '商家',
    hideInDescriptions: true,
    dataIndex: ['product', 'sellerName'],
    valueType: 'text',
    width: 100,
  }, {
    title: '商品品牌',
    hideInDescriptions: true,
    dataIndex: ['product', 'brand', 'brandName',],
    width: 100,
    render: (dom, record) => <>{internationalList(record.product?.brand?.brandName || '')}</>
  }, {
    title: '区域模版',
    hideInTable: true,
    dataIndex: ['product', 'regionTemplate', 'templateName'],
  }, {
    title: '平台商品分类',
    dataIndex: ['product', 'classify', 'name'],
    hideInTable: true,
    width: 100,
    valueType: 'text',
  }, {
    title: '商品类目',
    dataIndex: ['product', 'category', 'name'],
    width: 100,
    valueType: 'text',
    render: (dom, record) => <>{internationalList(record.product?.category?.name || '')}</>
  }, {
    title: '商品状态',
    dataIndex: ['product', 'status'],
    valueType: 'productStatus',
    width: 100,
  }, {
    title: '上下架时间',
    valueType: 'text',
    width: 100,
    render: (text, record: any) => {
      const date = Number((new Date()).getTime());
      const onScheduledDate = record?.product?.onScheduled ? Number((new Date((record?.product?.onScheduled as unknown) as Date)).getTime()) : 0;
      const offScheduledDate = record?.product?.offScheduled ? Number((new Date((record?.product?.offScheduled as unknown) as Date)).getTime()) : 0;
      return <div>
        {record?.product?.onScheduled && <p>
          {date < onScheduledDate && <Button type="link" size="small" icon={<ClockCircleOutlined />} />}
          <Tooltip placement="top" title="上架时间" key="on">{record?.product?.onScheduled}</Tooltip>
        </p>}
        {record?.product?.offScheduled && <p>
          {date < offScheduledDate && <Button type="link" size="small" icon={<ClockCircleOutlined />} />}
          <Tooltip placement="bottom" title="下架时间" key="off">{record?.product?.offScheduled}</Tooltip>
        </p>}
      </div>
    }
  }];

  const editSkuColumns: ProColumns<Item>[] = [{
    //列表缺失 specList字段，图片暂时隐藏
    // {
    //   title: '图片',
    //   width: 80,
    //   render: (text, record) => {
    //     const title = spec.title || '';
    //     const v = record.specs?.filter(e => e.k === title)[0]?.v || '';
    //     const url = spec.specValueList?.filter(e => e.specValue === v)[0]?.imgUrl || '';
    //     return <Image width={80} src={url} />
    //   }
    // },
    title: '规格',
    valueType: 'text',
    render: (text, record) => {
      let specsText = ``;
      if (record?.specs?.length === 1) {
        specsText = record?.specs[0].k + `:` + record.specs[0].v;
      } else {
        record?.specs?.forEach((specs: any) => {
          specsText += specs?.k + `:` + specs?.v + `;`;
        })
      }
      return <>{specsText}</>
    }
  }, {
    title: '售价',
    dataIndex: 'retailPrice',
    valueType: 'text',
  }, {
    title: '库存',
    render: (text, record) => {
      const localId = record.id;
      let farArr: any = skuList.data.filter((e: any) => e?.sku?.id === localId);
      return <>
        {farArr.length > 0 && <InputNumber disabled={record.status === 0} min={0} value={farArr[0].quantity} onChange={(changeNum) => {
          // 修改
          const newSku = creatSku.concat();
          setCreatSku(newSku.map((e: any) => {
            e.skuId === localId && (e.quantity = changeNum);
            e.wholesaleShopId = shopId;
            return e
          }));
          farArr[0].quantity = changeNum;
        }} />}
        {farArr.length === 0 && <InputNumber min={0} disabled={record.status === 0} defaultValue={0} onChange={(changeNum) => {
          // 新建
          let newSku = creatSku.concat();
          newSku[0].skuId === '0' && (newSku = []);
          newSku.length > 0 ? (newSku.filter(e => e.skuId === localId).length > 0 ? setCreatSku(newSku.map((e: any) => {
            e.skuId === localId && (e.quantity = changeNum);
            e.wholesaleShopId = shopId;
            return e
          })) : setCreatSku(newSku.concat([{
            quantity: changeNum,
            skuId: localId,
            wholesaleShopId: shopId,
          }]))) : setCreatSku([{
            quantity: changeNum,
            skuId: localId,
            wholesaleShopId: shopId,
          }]);
        }} />}
      </>
    }
  }];

  const rowActions: any = [(dom: ReactNode, record: any) => {
    return <ModalForm
      title={record?.product?.name}
      trigger={<Tooltip title={'编辑批发商商品库存'} key={record.id}><AuthButton authority='W_stock' type="link" size="small" icon={<ProfileOutlined />} /></Tooltip>}
      modalProps={{
        destroyOnClose: true,
      }}
      preserve={false}
      onVisibleChange={visible => {
        if (visible) {
          setShopId(record.shopId);
          searchSkuList({ 'productId.equals': record?.product?.id, 'shopId.equals': record.shopId }).then((res: any) => {
            setSkuList({
              total: parseInt(res.headers['x-total-count'], 10),
              data: res.data,
            });
            res.data.length > 0 ? setCreatSku(res.data.map((e: any) => {
              return {
                quantity: e.quantity,
                skuId: e.sku.id,
                wholesaleShopId: record.shopId
              }
            })) : setCreatSku([{
              quantity: 0,
              skuId: '0',
              wholesaleShopId: record.shopId
            }]);
          });
          // setSpec(record?.specList[0]); //暂存spec 编辑库存展示图片用
        } else {
          setSkuList({ total: 0, data: [] });
          setCreatSku([]);
          setShopId('');
        }
      }}
      onFinish={async (data) => {
        importStock(creatSku, { adjustmentType: 'Absolute' });
        return true;
      }}
    >
      <ProTable
        search={false}
        toolBarRender={false}
        pagination={false}
        dataSource={record?.product?.items}
        className="product-skuItem"
        rowClassName={e => e.status === 0 ? 'disable' : ''}
        columns={editSkuColumns}
      />
    </ModalForm>
  }, (dom: ReactNode, record: any) => {
    return <Popconfirm
      key="audit"
      placement="topRight"
      title={'你确定要清空批发商商品库存吗？'}
      onConfirm={() => {
        const arr = record?.product?.items?.concat().map((e: any) => e.id) || [];
        cancelStock(arr, { wholesaleShopId: record.shopId });
      }}
    >
      <Tooltip title={'清空批发商商品库存'} key={record.id}><AuthButton authority='D_stock' type="link" size="small" icon={<CloseOutlined />} /></Tooltip>
    </Popconfirm>

  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        {storeId === '0' &&
          <SelectSearch required={false} name='shopId.equals' placeholder="批发商" labelValue="storeName" togetherParams={{ "wholesale.equals": true }} defaultParams='' fetchFunc={fetchStoreList} searchParams='storeName.contains' />
        }
        <SelectSearch required={false} name="brandId.equals" placeholder="品牌" labelValue="brandName" defaultParams='' fetchFunc={fetchBrandList} searchParams='name.contains' />
        <SearchBox
          fields={searchFields}
          width={300}
        />
      </Input.Group>
    </Form.Item>);

  const uploadMenu = (
    <Menu>
      <Menu.Item key="upload">
        <ImportDataDialog columns={uploadColumns}
          title="批量导入"
          uploadEntity={batchProduct}
          fieldListErrors={props.fieldListErrors?.parameters?.fieldListErrors}
        />
      </Menu.Item>
    </Menu>
  );

  const additionActions = [
    <Space className="status-filter">
      <Tabs defaultActiveKey={status} onChange={status => setStatus(status)} activeKey={status}>
        {Object.entries<string>(ProductStatus).map(([value, label]) => <TabPane tab={label} key={value}></TabPane>)}
      </Tabs>
    </Space>,
    <Authorized authority='batchImport'>
      <Dropdown overlay={uploadMenu}>
        <Button>
          批量导入 <DownOutlined />
        </Button>
      </Dropdown>
    </Authorized>,
    <ModalForm
      title="商品批量新增"
      trigger={<Tooltip title={'商品批量新增'} key="batch"><AuthButton authority='BatchAdd' type="primary" icon={<ProfileOutlined />} >批量新增</AuthButton></Tooltip>}
      modalProps={{
        destroyOnClose: true,
      }}
      preserve={false}
      onVisibleChange={visible => { }}
      onFinish={async (data) => {
        const ids = data.ids.concat().map((e: string) => {
          return {
            shopId: data.wholesaleShopId,
            product: { id: e }
          }
        });
        batchProduct(ids);
        return true;
      }}
    >
      {storeId === '0' &&
        <SelectSearch label="批发商" name='wholesaleShopId' placeholder="批发商" togetherParams={{ "wholesale.equals": true }} defaultParams='' fetchFunc={fetchStoreList} searchParams='storeName.contains' />
      }
      <Form.Item label="选择商品" name='ids'>
        <ProductListEditControl fetchFunc={searchProductPromotion} hideProductIds />
      </Form.Item>
    </ModalForm>];

  return (
    <ListViewPage<WholesaleProduct, 'productStatus'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailsIndex={false}
      rowActions={rowActions}
      actions={additionActions}
      hideEditButton
      hideCreateButton 
      filters={{ 'productStatus.equals': status ? (status === '' ? '' : status) : '10' }}
      {...props}
    >
      <Drawer
        visible={detailShow}
        onClose={() => SetDetailShow(false)}
        width={1000}
      >
        <ProductDetails hideOperation id={detailId} columns={detailColumns} />
      </Drawer>
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ wholesaleProduct }: RootState) => ({
  entity: wholesaleProduct.entity,
  entities: wholesaleProduct.entities,
  loading: wholesaleProduct.loading,
  updateSuccess: wholesaleProduct.updateSuccess,
  total: wholesaleProduct.total,
  createSuccess: wholesaleProduct.createSuccess,
  fieldListErrors: wholesaleProduct.fieldListErrors,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  importStock,
  batchProduct,
  cancelStock
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WholesaleList);