import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, message } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import CouponDetails from "./coupon-details";
import { cancelCoupon, cancelUserCoupon, deleteEntity, fetchCouponUserList, getEntities, getEntity, sendCoupon } from "./coupon.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import CouponEdit from "./coupon-edit";
import { Coupon, CouponShowStatus, couponUserListStatus } from "model/market/coupon.model";
import Popconfirm from "antd/es/popconfirm";
import { ModalForm, ProFormDateTimePicker, ProFormDigit, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import { fetchSearchCustomerList } from "pages/dealer/customer/customer.reducer";
import { useEnumValue } from "common/enum/use-enum-value";
import { TFModel } from "model/system/version.model";

export interface CouponListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}




const CouponList: FC<CouponListProps> = (props) => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const [refreshTag, setRefreshTag] = useState(0);
  const { convertEnumToOptionType } = useEnumValue();

  const couponUserListColumns = [{
    dataIndex: 'name.equals',
    hideInTable: true,
    title: '经销商名称',
    valueType: 'text',
    renderFormItem: () => {
      return <ProFormText name='name.equals' label="经销商名称" placeholder='经销商名称' />
    }
  }, {
    dataIndex: 'phone.equals',
    hideInTable: true,
    title: '手机号',
    renderFormItem: () => {
      return <ProFormText name='phone.equals' label="手机号" placeholder='手机号' />
    }
  }, {
    dataIndex: 'account.equals',
    hideInTable: true,
    title: '授权号',
    renderFormItem: () => {
      return <ProFormText name='account.equals' label="授权号" placeholder='授权号' />
    }
  }, {
    dataIndex: 'status.equals',
    hideInTable: true,
    valueType: 'select',
    width:'100',
    valueEnum:couponUserListStatus,
    title:'券使用状态',
    // renderFormItem: (text: any, record: any, valueType: any) => {
    //   return <ProFormSelect
    //     name="status.equals"
    //     options={Object.entries<string>(couponUserListStatus).map(([value, label]) => ({ value: (value), label }))}
    //     placeholder="状态"
    //     label='状态'
    //   />
    // },
  }, {
    title: '经销商名称',
    hideInSearch: true,
    dataIndex: 'customerName',
    width: 100,
  }, {
    title: '授权号',
    dataIndex: 'account',
    hideInSearch: true,
    width: 100,
  }, {
    title: '手机号',
    dataIndex: 'customerPhone',
    hideInSearch: true,
    width: 100,
  }, {
    title: '发券时间',
    dataIndex: 'grantTime',
    hideInSearch: true,
    width: 100,
  }, {
    title: '发券开始时间',
    dataIndex: 'grantTime.greaterThanOrEqual',
    hideInTable: true,
    valueType:'dateTime',
  }, {
    title: '发券结束时间',
    dataIndex: 'grantTime.lessThanOrEqual',
    valueType:'dateTime',
    hideInTable: true,
  }, {
    title: '领取时间',
    dataIndex: 'receiveTime',
    hideInSearch: true,
    width: 100,
  }, {
    title: '状态',
    dataIndex: 'status',
    valueEnum: couponUserListStatus,
    hideInSearch: true,
    width: 100,
  }, {
    title: '作废',
    dataIndex: '',
    hideInSearch: true,
    width: 100,
    render: (dom: any, record: any) => {
      return <Popconfirm disabled={record?.status !== 0} title="作废后不可恢复！确定要作废当前券吗？" key="abandon" onConfirm={() => {
        cancelUserCoupon(record.id as string).then(e => {
          message.success('操作成功');
          setRefreshTag(new Date().getTime());
        }).catch((e: any) => {
          message.error('操作失败，请联系管理员！');
        })
      }}>
        <Button disabled={record?.status !== 0} type="link" size="small">作废</Button>
      </Popconfirm>
    }
  }]

  const columns: ProColumns<Coupon, 'couponBreed' | 'couponType'>[] = [{
    title: '券ID',
    dataIndex: 'id',
    width: 100,
  }, {
    title: '券种类',
    dataIndex: 'couponBreed',
    valueType: 'couponBreed',
    width: 100,
  }, {
    title: '券名称',
    dataIndex: 'couponName',
    width: 100,
  }, {
    title: '券类型',
    dataIndex: 'couponType',
    valueType: 'couponType',
    width: 100,
  }, {
    title: '券面值',
    dataIndex: 'couponAmount',
    width: 100,
  }, {
    title: '使用门槛',
    dataIndex: 'hasCondition',
    valueEnum: TFModel,
    width: 100,
  }, {
    title: '门槛金额',
    dataIndex: 'minSpendAmount',
    width: 80,
  }, {
    title: '使用时间',
    dataIndex: 'useTime',
    width: 140,
    render: (text, record) => {
      return <>
        <p>类型：{record.useTime?.type === 'RANGE' ? '时间范围' : '固定天数'}</p>
        {record.useTime?.type === 'RANGE' ?
          <p>天数：{record?.useTime?.days}</p> :
          <>
            <p>开始：{record?.useTime?.startTime}</p>
            <p>结束：{record?.useTime?.endTime}</p>
          </>
        }
      </>
    }
  }, {
    title: '领取开始时间',
    dataIndex: 'receiveStartTime',
    width: 100,
  }, {
    title: '领取结束时间',
    dataIndex: 'receiveEndTime',
    width: 100,
  }, {
    title: '状态',
    dataIndex: 'showStatus',
    valueEnum: CouponShowStatus,
    width: 100,
  }, {
    title: '券总数',
    dataIndex: 'couponNumber',
    width: 80,
  }, {
    title: '已领取',
    dataIndex: 'receiveNumber',
    width: 80,
    render: (dom, record) => {
      return <><span>{record.receiveNumber || 0}</span><ModalForm
        title="领券用户列表"
        width={1300}
        trigger={<Button type="link" size="small" icon={<></>}>明细</Button>}
        modalProps={{ destroyOnClose: true, }}
        onFinish={async (values) => {
          return true;
        }}
      >

        <ProTable<any>
          className="express-table"
          columns={couponUserListColumns as any}
          rowKey="id"
          search={{
            filterType: 'light',
          }}
          params={{ id: record?.id, refreshTag }}
          request={async (params) => {
            let res = await fetchCouponUserList({
              ...params,
              id: record.id,
              page: (params?.current && params?.current - 1) || 0,
              size: params?.pageSize || 20,
            });
            console.log(res)
            return Promise.resolve({
              data: res?.data || [],
              success: true,
              total: res.headers['x-total-count'] || 0
            });
          }}
          options={false}
        />
      </ModalForm></>
    }
  }, {
    title: '已使用',
    dataIndex: 'useNumber',
    width: 80,
  }, {
    title: '创建时间',
    dataIndex: 'createdDate',
    width: 100,
  }];

  const searchFields = [{
    label: '券id',
    name: 'id.equals',
  }, {
    label: '券名称',
    name: 'couponName.equals',
  },];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          options={convertEnumToOptionType('couponBreed')}
          name="couponBreed.equals"
          label="券种类"
          rules={[{ required: true }]}
        />
        <ProFormSelect
          options={convertEnumToOptionType('couponType')}
          name="couponType.equals"
          label="券类型"
          rules={[{ required: true }]}
        />
        <ProFormSelect
          options={Object.entries<string>(CouponShowStatus).map(([value, label]) => ({ value: (value), label }))}
          name="showStatus"
          label="券状态"
          rules={[{ required: true }]}
        />
        <ProFormDateTimePicker
          name="createdDate.greaterThanOrEqual"
          placeholder="创建开始时间"
        />
        <ProFormDateTimePicker
          name="createdDate.lessThanOrEqual"
          placeholder="创建结束时间"
        />
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );
  const rowActions = [(dom: any, record: Coupon) => {
    return <Popconfirm disabled={record?.showStatus === 4} title="作废后不可恢复！确定要作废当前券吗？" key="abandon" onConfirm={() => {
      cancelCoupon(record.id as string).then(e => {
        message.success('操作成功');
        setRefreshFlag(new Date().getTime());
      }).catch((e: any) => {
        message.error('操作失败，请联系管理员！');
      })
    }}>
      <Button disabled={record?.showStatus === 4} type="link" size="small">作废</Button>
    </Popconfirm>
  }, (dom: any, record: Coupon) => {
    return <ModalForm
      title="发放优惠券"
      width={1000}
      trigger={<Button disabled={record?.showStatus === 4} type="link" size="small" icon={<></>}>发放</Button>}
      modalProps={{ destroyOnClose: true, }}
      disabled={record?.showStatus === 4}
      onFinish={async (data) => {
        return new Promise<boolean>((resolve, reject) => {
          sendCoupon(record.id || '0', data.customerId, data.grantNum).then(e => {
            console.log(e)
            message.success('操作成功');
            resolve(true);
            setRefreshFlag(new Date().getTime());
          }).catch((e: any) => {
            message.error('操作失败，请联系管理员！');
            resolve(false);
          })
        })
      }}
    >
      <Form.Item name="customerId" label="经销商">
        <SelectSearch mode='multiple' width={900} required={false} name="customerId" placeholder="经销商" optionValue='account' labelValue="name" extra='请使用授权码进行搜索' labelValueObj={(option: any) => option.name + '--' + option.account} defaultParams='' fetchFunc={fetchSearchCustomerList} searchParams='account.equals' />
      </Form.Item>
      <ProFormDigit
        rules={[{ required: true }]}
        name="grantNum"
        label="发放数量"
        initialValue={1}
        placeholder={'发放数量'}
      />
    </ModalForm>
  }]
  return (
    <ListViewPage<Coupon, 'couponBreed' | 'couponType'>
      columns={columns}
      rowActions={rowActions}
      hideDeleteButton
      refreshFlag={refreshFlag}
      sort=""
      quickFilterPanel={quickFilterPanel}
      detailPage={<CouponDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <CouponEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ coupon }: RootState) => ({
  entity: coupon.entity,
  entities: coupon.entities,
  loading: coupon.loading,
  updateSuccess: coupon.updateSuccess,
  createSuccess: coupon.createSuccess,
  total: coupon.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CouponList);