import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./split-order-type.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { SplitOrderType } from "model/product/split-order-type.model";
import { ProFormText } from "@ant-design/pro-form";
export interface SplitOrderTypeEditProps extends EditStateAndDispatchProps<SplitOrderType>, StateProps, DispatchProps, RouteComponentProps {
}

const SplitOrderTypeEdit: FC<SplitOrderTypeEditProps> = props => {

    return (
        <EditPage<SplitOrderType>
            title="拆单分类设置"
            {...props}
        >
            <ProFormText name="name" label="名称" />

        </EditPage>);
};

const mapStateToProps = ({ splitOrderType }: RootState) => ({
    entity: splitOrderType.entity,
    updateSuccess: splitOrderType.updateSuccess,
    updating: splitOrderType.updating,
    createSuccess: splitOrderType.createSuccess
});

const mapDispatchToProps = {
    getEntity,
    createEntity,
    updateEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SplitOrderTypeEdit);
