import React, { FC, ReactNode, } from "react";
import { Button, Form, Input, Popconfirm } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./tagTask-details";
import { activate, deactivate, deleteEntity, getEntities, getEntity } from "./tagTask.reducer";
import { RouteComponentProps } from "@reach/router";
import TagTaskEdit from './tagTask-edit';
import { TagTask } from "model/customerCore/tagTask.model";
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";

export interface TagTaskListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const TagTaskList: FC<TagTaskListProps> = (props) => {

  const { deactivate, activate } = props;
  const { convertEnumToOptionType } = useEnumValue();

  const columns: ProColumns<TagTask, 'taskRuleConfigType' | 'tagTaskExecutor' | 'customerTagTaskStatus'>[] = [{
    dataIndex: 'id',
    title: 'id',
    width: 60,
  }, {
    dataIndex: ['name'],
    title: '任务名称',
    width: 100,
  }, {
    dataIndex: ['startTime'],
    title: '开始时间',
  }, {
    dataIndex: ['endTime'],
    title: '结束时间',
  }, {
    dataIndex: ['executor'],
    valueType: 'tagTaskExecutor',
    title: '执行器',
  }, {
    dataIndex: ['ruleConfig', 'sellerName'],
    title: '店铺',
  }, {
    dataIndex: ['ruleConfig', "orderDurationTime"],
    title: '多久时间内订单',
  }, {
    dataIndex: ['ruleConfig', "configType"],
    title: '配置类型',
    valueType: 'taskRuleConfigType',
    width: 100,
  }, {
    dataIndex: ['status'],
    title: '状态',
    valueType: 'customerTagTaskStatus',
    width: 80,
  }]

  const searchFields: SearchField[] = [{
    label: '任务名称',
    name: 'name.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormDateTimePicker
          name="startTime.greaterThanOrEqual"
          placeholder="开始时间"
        />
        <ProFormDateTimePicker
          name="endTime.lessThanOrEqual"
          placeholder="结束时间"
        />
        <ProFormSelect
          options={convertEnumToOptionType('customerTagTaskStatus') || []}
          name="status.equals"
          placeholder={'订单类型'}
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const activateButtonRender = (dom: ReactNode, record: TagTask): ReactNode => (
    (record.status === 'INITIALIZING' || record.status === 'STOPPED') && record.id &&
    <Popconfirm title="你确定要启用这个礼品券吗？" key="activate" onConfirm={() => activate(record.id?.toString() || '')}>
      <Button type="link" size="small">激活</Button>
    </Popconfirm>
  );

  const deactivateButtonRender = (dom: ReactNode, record: TagTask): ReactNode => (
    record.status === 'RUNNING' && record.id &&
    <Popconfirm title="你确定要禁用这个礼品券吗？" key="deactivate" onConfirm={() => deactivate(record.id?.toString() || '')}>
      <Button type="link" size="small">禁用</Button>
    </Popconfirm>
  );


  return (
    <ListViewPage<TagTask, 'taskRuleConfigType' | 'tagTaskExecutor' | 'customerTagTaskStatus'>
      columns={columns}
      quickFilterPanel={convertEnumToOptionType('customerTagTaskStatus').length > 0 && quickFilterPanel}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      rowActions={[activateButtonRender, deactivateButtonRender]}
      editPage={(params) => <TagTaskEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ tagTask }: RootState) => ({
  entity: tagTask.entity,
  entities: tagTask.entities,
  loading: tagTask.loading,
  updateSuccess: tagTask.updateSuccess,
  total: tagTask.total,
  createSuccess: tagTask.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  deactivate,
  activate,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(TagTaskList)