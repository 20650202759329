
import { RouteComponentProps } from '@reach/router';
import { FC } from 'react';
import './index.less'

interface DashboardProps extends RouteComponentProps { };
const HomePage: FC<DashboardProps> = () => {
  return <div className="home-page-box">
    <h2>欢迎使用 经销商控制台</h2>
  </div>
};

export default HomePage;