import axios from 'axios';
import { ContractSign, defaultContractSign } from 'model/contract/sign.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'ContractSign';
const apiUrl = '/api/yumei-mall-customer/esign/contract/page';
const standardReducerImpl = new StandardReducerImpl<ContractSign>(entity, defaultContractSign, apiUrl);
export type ContractSignState = StandardState<ContractSign>;
export const contractSign = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchDocTemplate = async (id?: any) => {
    const axiosResponse = await axios.get<any>(`/api/yumei-mall-customer/esign/ContractSign/template/edit-url/${id}`);
    return axiosResponse.data;
};

export const beSign = async (params?: any) => {
    const axiosResponse = await axios.get<any>(`/api/yumei-mall-customer/esign/contract/file-download-url/${params.signFlowId}`);
    return axiosResponse.data;
};