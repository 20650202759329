import { BaseModel } from 'model/base.model';


/**
 * 订单信息
 */
export interface OrderAfterSales extends BaseModel {
  code?: string,
  desc?: string,
}

export const defaultOrderAfterSales: Readonly<OrderAfterSales> = {
};