import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import WalletList from './wallet-list';
import WalletDetails from "./wallet-details";
import WalletEdit from "./wallet-edit";

const Wallet: React.FC<RouteComponentProps> = () => (
  <Router>
    <WalletList path="/" />
    <WalletDetails path=":id" />
    <WalletEdit path=":id/edit" />
    <WalletEdit path="new" />
  </Router>
);
export default Wallet;
