import { ModalForm } from "@ant-design/pro-form";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Form, message, Tag } from "antd";
import BindcourseSelectionForm from "./bindcourse-selection-form";
import { addLessonSonMiddle, getLessonSonMiddle, delLessonSonMiddle } from "./topic.reducer";
import { useState, useEffect } from "react";

const RechargeModel = (props: any) => {
    let { visible, record, onCancel } = props
    const [form] = Form.useForm();
    let [key, setKey] = useState<any>(0);
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
        }
    };

    const columns: ProColumns[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 140
        },
        {
            title: '课程标题',
            dataIndex: 'title',
            valueType: 'text',
            width: 150,
            ellipsis: true
        },

        {
            title: '课程描述',
            dataIndex: 'content',
            valueType: 'text',
            width: 150,
            ellipsis: true
        },
        {
            title: '序号',
            dataIndex: 'sort',
            valueType: 'text',
            width: 70
        },
        // {
        //     title: '及格分数',
        //     dataIndex: 'passScore',
        //     valueType: 'text',
        //     width: 150,
        //     ellipsis: true
        // },
        // {
        //     title: '是否需要考试',
        //     dataIndex: 'isExamination',
        //     width: 120,
        //     render: (dom, record) => {
        //         return <Tag color={record.isExamination === 1 ? '#1890ff' : '#f50'}>{record.isExamination === 1 ? '需要' : '不需要'}</Tag>
        //     }
        // }, 
        {
            title: '状态',
            dataIndex: 'state',
            width: 120,
            render: (dom, record) => {
                return <Tag color={record.state === 1 ? '#1890ff' : '#f50'}>{record.state === 1 ? '使用中' : '闲置'}</Tag>
            }
        }, {
            title: '操作',
            dataIndex: 'state',
            width: 120,
            render: (dom, record: any) => {
                return <div style={{ color: 'rgb(24, 144, 255)', cursor: 'pointer' }} onClick={() => {
                    delLessonSonMiddle({
                        id: record.id
                    }).then((e: any) => {
                        message.success('删除成功')
                        setKey(++key)
                    })
                }} >删除</div>
            }
        }];

    useEffect(() => {
        setKey(++key);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])



    return (
        <ModalForm
            title="关联课程"
            visible={visible}
            onVisibleChange={(e) => !e && onCancel()}
            width={1100}
            form={form}
            modalProps={{
                footer: false
            }}
            layout='horizontal'
            {...formItemLayout}
            preserve={false}
            className="editePwd-Layout"
        >

            <ProTable<any>
                key={key}
                rowKey="id"
                columns={columns}
                options={false}
                search={false}
                rowSelection={false}
                toolBarRender={() => [
                    <BindcourseSelectionForm
                        trigger="添加"
                        onFinish={(data) => {
                            return new Promise<any>((resolve, reject) => {
                                console.log(data)
                                console.log('获取过来的制')
                                addLessonSonMiddle({
                                    lessonId: record.id,
                                    lessonSonMiddleDto: data
                                }).then((e) => {
                                    console.log(e)
                                    message.success('添加成功')
                                    setKey(++key)
                                    resolve(true)
                                }).catch((e) => {
                                    console.log(e.response.data.message)
                                    message.error(e.response.data.message)
                                    resolve(false)
                                })


                            })

                        }}
                    />,
                ]}
                request={async () => {
                    const products = await getLessonSonMiddle({
                        id: record.id
                    });
                    return {
                        data: products.content,
                        // success 请返回 true，
                        // 不然 table 会停止解析数据，即使有数据
                        success: true,
                        // 不传会使用 data 的长度，如果是分页一定要传
                    };
                }}
            />

        </ModalForm>
    )
}

export default RechargeModel