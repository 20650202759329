import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import MessageDetails from './message-details';
import MessageList from './message-list';

const UnusualMessage: React.FC<RouteComponentProps> = () => (
  <Router>
    <MessageList path="/" />
    <MessageDetails path=":id" />
  </Router>
);
export default UnusualMessage;
