import ProCard from "@ant-design/pro-card";
import { ProFormCheckbox} from "@ant-design/pro-form";
import { fetchActions, fetchRoleList } from "pages/user/role/role.reducer";
import { FC, useEffect, useState } from "react";

interface RolePanelProps {
    id: any,
    entity: any;
    form?: any;
    refreshTag?: number
}

const RolePanel: FC<RolePanelProps> = (props) => {
    const { id, entity, form, refreshTag } = props;
    const [arr, setArr] = useState([]);
    const [options, setOptions] = useState<{ label: '', value: '' }[]>([]);
    useEffect(() => {
        setArr([]);
        setOptions([]);
        if (entity && entity.actions && entity.actions.length > 0 && id) {
            let arr: any = entity.actions.concat().map((e: any, index: number) => ({
                label: e.name,
                value: e.id + '',
                code:e.code,
            }));
            setOptions(arr);

            fetchRoleList({
                size: 100,
                page: 0
            }).then((res: any) => {
                setArr(res.data.concat().filter((e:any) => !e.isAdmin));
    
                fetchActions({
                    menuId: id,
                }).then((e: any) => {
                    const data = e.data;
                    res.data.forEach((item: any) => {
                        
                        const obj = data.concat().filter((i: any) => i.code === item.code)?.[0];
                        form.setFieldsValue({
                            [item.id]: obj?.actions?.map((s: any) => s.id) || []
                        });
                    })
                });
            });

        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, refreshTag,entity]);
    return <>
        {arr.length > 0 && arr.map((e: any) => {
            return <ProCard
                title={e.name}
                bordered
                headerBordered
                collapsible
                defaultCollapsed
                style={{
                    marginBottom: 16,
                    minWidth: 400,
                    maxWidth: '100%',
                }}
            >
                <ProFormCheckbox.Group
                    name={e.id}
                    layout="horizontal"
                    label="操作权限"
                    options={options}
                />
            </ProCard>
        })}
    </>
};

export default RolePanel;