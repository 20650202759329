import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import StockDetails from "./stock-details";
import StockEdit from './stock-edit';
import StockList from './stock-list';

const Stock: React.FC<RouteComponentProps> = () => (
  <Router>
    <StockList path="/" />
    <StockDetails path=":id" />
    <StockEdit path=":id/edit" />
    <StockEdit path="new" />
  </Router>
);
export default Stock;
