import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Col, Form, Row } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./menuList.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { MenuList } from "model/system/menuList.model";
import ProForm, { ProFormList, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { fetchModuleList } from "pages/user/power-module/power-module.reducer";
export interface MenuListEditProps extends EditStateAndDispatchProps<MenuList>, StateProps, DispatchProps, RouteComponentProps {
}

const MenuListEdit: FC<MenuListEditProps> = props => {
  const {entity} = props;
  const [form] = Form.useForm();
  const [moduleName,setModuleName] = useState<any>('');
  const [moduleList, setModuleList] = useState<any>([]); //模块
  const requestModuleList = (params: any) => {
    fetchModuleList({ page: 0, size: 500, ...params }).then(e => {
      setModuleList(e.map((item: any) => ({
        label: item.name,
        value: item.code,
      })));
    });
  };

  let timeout: any = null;
  const handleSearch = (e?: any, type?: string) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    };
    const fake = async () => {
      type === 'module' && requestModuleList({ 'name.contains': e })
    };
    timeout = setTimeout(fake, 1000);
  };

  useEffect(() => {
    requestModuleList({});
  }, []);
  useEffect(() => {
    if(entity.id){
      setModuleName(entity?.module?.name || '')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.id]);

  const beforeUpdateEntity = (res:any) => {
    return new Promise<any>((resolve, reject) => {
      if(res){
        res.module.name = moduleName;
        resolve(res);
      }
    })
  }

  return (
    <EditPage<MenuList>
      title="编辑"
      form={form}
      {...props}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row>
        <Col span={7}>
          <ProFormText
            rules={[{ required: true }]}
            width="md"
            name="name"
            label="菜单名称"
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormText
            rules={[{ required: true }]}
            width="md"
            name="code"
            label="菜单编码"
          />
        </Col><Col span={1} />
        <Col span={'7'}>
          <ProFormSelect
            label="模块"
            name={['module', 'code']}
            options={moduleList}
            rules={[{ required: true }]}
            showSearch
            fieldProps={{
              onChange:(e,o:any) => setModuleName(o.label),
              onSearch: (e) => handleSearch(e, 'module')
            }}
          />
        </Col>
        <Col span={7}>
          <ProFormText
            rules={[{ required: true }]}
            width="md"
            name={['parent',"name"]}
            label="父级菜单名称"
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormText
            rules={[{ required: true }]}
            width="md"
            name={['parent',"code"]}
            label="父级菜单编码"
          />
        </Col><Col span={1} />
        <Col span={24}>
          <ProFormList
            label="操作"
            name={'actions'}
            creatorButtonProps={{
              creatorButtonText: '添加',
            }}
            initialValue={[{}]}
            // actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
          >
            {(meta, itemIndex, action) => <ProForm.Group size={10}>
              <ProFormText
                name="name"
                label="名称"
              />
              <ProFormText
                label="编码"
                name="code"
              />
              <ProFormText
                label="排序"
                name="sort"
              />
              <ProFormText
                label="请求方式"
                name="httpMethod"
              />
              <ProFormText
              width={400}
                label="请求地址"
                name="url"
              />
            </ProForm.Group>}
          </ProFormList>
        </Col>
        <Col span={24}>
        
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ menuList }: RootState) => ({
  entity: menuList.entity,
  updateSuccess: menuList.updateSuccess,
  updating: menuList.updating,
  createSuccess: menuList.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MenuListEdit);
